import React, { useEffect } from 'react'
import { useState } from 'react'
import { Group, Text } from '@mantine/core'
import Container from 'react-bootstrap/Container'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import Toggle from 'react-toggle'
import saleIcon from '../../../../resources/images/factura.png'
import Button from 'react-bootstrap/Button'
import SaleModal from './saleModal'
import { notify } from '../../../../services/utils'
import {
  Pagination,
  MenuItem,
  Select as SelectPages,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material'
import useStore from '../../../zustand'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { postBundledSaleApi } from '../../../../pages/api/bundledsale'
import {
  getClientSalesByThirdClientIdApi,
  getClientSalesByIdApi,
} from '../../../../pages/api/clientsale'
import { getThirdClientsByCompanyApi } from '../../../../pages/api/thirdClients'
import CustomSelect from '../../../custom/customSelect'

const BundledSalesModal = (props) => {
  const { tokenData, isDarkModeActive } = useStore()
  const { show, onClose } = props

  const formatSaleType = (saleType) => {
    switch (saleType) {
      case 1:
        return 'CONTADO'
      case 2:
        return 'CREDITO'
      default:
        break
    }
  }

  const setStateStyles = (saleState) => {
    let color = '#000000'
    switch (saleState) {
      case 1:
        color = 'rgb(33, 150, 243)'
        break
      case 2:
        color = '#66BB6A'
        break
      case 3:
        color = 'gray'
        break
      case 4:
        color = 'rgb(229, 57, 53)'
        break
      case 5:
        color = 'orange'
        break
      default:
        color = 'rgb(33, 150, 243)'
        break
    }

    return {
      borderRadius: '7%',
      backgroundColor: color,
      color: 'white',
      width: '150px',
      padding: '0px 3px',
      margin: '0 10px 0 10px',
      fontSize: '14px',
      textAlign: 'center',
    }
  }

  const setStateText = (saleState) => {
    switch (saleState) {
      case 1:
        return 'CREADA'
      case 2:
        return 'APROBADA'
      case 3:
        return 'ENVIADA DIAN'
      case 4:
        return 'DIAN RECHAZADA'
      case 5:
        return 'AGRUPADA'
      default:
        return 'CREADA'
    }
  }

  //to utils
  const tableStateStyle = (state) => {
    if (isDarkModeActive) return { backgroundColor: '#424242' }
    if (state == 1) return { backgroundColor: 'rgb(212, 234, 251)' } //sin aprobar
    if (state == 2) return { backgroundColor: 'rgb(243, 252, 232)' } //aprobada
    if (state == 3) return { backgroundColor: 'rgb(238, 238, 238)' } //dian
    if (state == 4) return { backgroundColor: 'rgb(255, 235, 238' } //rechazada
    if (state == 5) return { backgroundColor: 'rgb(255, 236, 179)' } //agrupada
  }

  //---------- bundled sales --------------

  const [groupSales, setGroupSales] = useState([])
  const [groupSalesIds, setGroupSalesIds] = useState([])

  const addToGroupSales = (sale) => {
    let group = [...groupSales]
    let ids = [...groupSalesIds]
    group.push({ saleId: sale.id, invoicenumber: sale.invoiceNumber })
    ids.push(sale.id)
    setGroupSales(group)
    setGroupSalesIds(ids)
  }

  const deleteFromGroupSales = (id) => {
    let group = [...groupSales]
    let ids = [...groupSalesIds]
    const index = ids.indexOf(id)
    if (index > -1) {
      group.splice(index, 1)
      ids.splice(index, 1)
    }
    setGroupSales(group)
    setGroupSalesIds(ids)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const sendBundledSale = async (thirdClient) => {
    if (selectedThirdClient.value == 0) {
      notify('warning', 'Seleccione una empresa.')
      return
    }
    if (groupSalesIds.length == 0) {
      notify('warning', 'Seleccione al menos 2 facturas para agrupar.')
      return
    }
    if (groupSalesIds.length > 1) {
      setIsCreateButtonDisabled(true)
      let bundledSale = {
        branchId: tokenData.branchId,
        companyId: tokenData.companyId,
        sales: JSON.stringify(groupSales),
        thirdClientId: thirdClient,
      }
      let result = await postBundledSaleApi(bundledSale)
      if (result.data == 'Sale is already verified') {
        notify('error', 'Una o más facturas ya se envío de forma individual.')
      } else {
        notify('success', 'Facturas agrupadas con éxito')
        onClose()
      }
    } else {
      notify('warning', 'Seleccione al menos 2 facturas para agrupar.')
    }
    setIsCreateButtonDisabled(false)
  }

  //--------------get sales --------------

  const [sales, setSales] = useState([])
  const [saleCount, setSaleCount] = useState(0)

  const getSales = async (branchId, thirdClient, offset, limit) => {
    try {
      let sales = []
      sales = await getClientSalesByThirdClientIdApi(
        thirdClient,
        branchId,
        offset,
        limit,
      )
      if (sales.data != null) {
        setSales(sales.data)
        setSaleCount(sales.count)
      } else {
        setSales([])
        setSaleCount(0)
        notify('warning', 'No hay ventas asociadas a esta empresa.')
      }
    } catch (error) {
      return error
    }
  }

  //-----------------Pagination-------------------
  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(10)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getSales(
      tokenData.branchId,
      selectedThirdClient.value,
      newOffset,
      rpg,
    )
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    await getSales(
      tokenData.branchId,
      selectedThirdClient.value,
      0,
      event.target.value,
    )
  }

  //--------------------Sale info detallada---------------

  const [saleInfo, setSaleInfo] = useState([])

  const [showSaleModal, setShowSaleModal] = useState(false)
  const handleShowSaleModal = () => setShowSaleModal(true)
  const handleCloseSale = () => setShowSaleModal(false)

  const showSale = async (saleId) => {
    let result = await getClientSalesByIdApi(saleId)
    if (result.data != null) {
      setSaleInfo(result.data)
      handleShowSaleModal()
    }
  }

  //--------select thirdclientclient -------
  const [selectedThirdClient, setSelectedThirdClient] = useState({
    value: 0,
    label: '',
  })

  const [thirdClientsOptions, setThirdClientsOptions] = useState([])

  const getThirdClients = async () => {
    let result = await getThirdClientsByCompanyApi(tokenData.companyId)
    if (result.data != null) {
      let clientSelect = []
      await result.data.map((x) => {
        clientSelect.push({
          value: x.id,
          label: x.thirdClientCompanyName,
          isRumbo: x.isRumbo,
        })
      })
      setThirdClientsOptions(clientSelect)
    }
  }

  const onThirdClientIdChange = async (thirdClient) => {
    setSelectedThirdClient(thirdClient)
    await getSales(tokenData.branchId, thirdClient.value, pg * rpg - rpg, rpg)
  }

  const tokenVerified = async () => {
    await getThirdClients()
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size="xl">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Agrupación de recibos de venta
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <Container>
              <Group grow mb="md" mt="md" style={{ display: 'inline' }}>
                <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                  <CustomSelect
                    title="A nombre de:"
                    placeholder="Cliente"
                    value={selectedThirdClient}
                    onChange={(e) => onThirdClientIdChange(e)}
                    options={thirdClientsOptions}
                    theme={isDarkModeActive ? 'black' : 'white'}
                    minWidth={300}
                  />
                </FormControl>

                <Container>
                  <MDBTable small className="salesTable">
                    <MDBTableHead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Estado</th>
                        <th style={{ textAlign: 'center' }}>Consecutivo</th>
                        <th style={{ textAlign: 'center' }}>Placa</th>
                        <th style={{ textAlign: 'center' }}>Método de venta</th>
                        <th style={{ textAlign: 'center' }}>
                          Galones vendidos
                        </th>
                        <th style={{ textAlign: 'center' }}>Empresa</th>
                        <th className="saleInfo">Información detallada</th>
                        <th style={{ textAlign: 'center' }}>Acciones</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody
                      style={{ color: color, isolation: 'isolate' }}
                    >
                      {sales.map((sale, index) => {
                        return (
                          <tr
                            key={index}
                            style={tableStateStyle(sale.saleState)}
                          >
                            <td>
                              <div style={setStateStyles(sale.saleState)}>
                                {setStateText(sale.saleState)}
                              </div>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {sale.posTerpelConsecutive}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {sale.busPlate}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {formatSaleType(sale.paymentMethod)}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {sale.gallonsSold}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {sale.thirdClientCompanyName}
                            </td>
                            <td
                              style={{
                                width: '100px',
                                margin: 'auto',
                                textAlign: 'center',
                              }}
                            >
                              <a
                                onClick={() => showSale(sale.id)}
                                style={{ cursor: 'pointer' }}
                              >
                                <img
                                  style={{
                                    float: 'right',
                                    float: 'inline-start',
                                    alignContent: 'center',
                                    cursor: 'pointer',
                                    width: '40px',
                                  }}
                                  src={saleIcon}
                                />
                              </a>
                            </td>
                            <td>
                              {sale.saleState == 5 ||
                              sale.saleState == 3 ||
                              sale.saleState == 4 ||
                              sale.saleState == 2 ? (
                                <Toggle
                                  className="custom-classname"
                                  disabled={true}
                                />
                              ) : (
                                <Toggle
                                  className="custom-classname"
                                  checked={groupSalesIds.includes(sale.id)}
                                  onChange={(e) => {
                                    e.currentTarget.checked
                                      ? addToGroupSales(sale)
                                      : deleteFromGroupSales(sale.id)
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </MDBTableBody>
                  </MDBTable>
                  <Container>
                    <FormControl variant="standard">
                      <InputLabel id="demo-simple-select-standard-label">
                        Filas
                      </InputLabel>
                      <SelectPages
                        labelId="demo-simple-select-standard-label"
                        autoWidth={true}
                        value={rpg}
                        onChange={handleChangeRowsPerPage}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </SelectPages>
                    </FormControl>
                    <Pagination
                      count={Math.ceil(saleCount / rpg)}
                      page={pg}
                      onChange={handleChangePage}
                      showFirstButton={true}
                      showLastButton={true}
                      style={{ display: 'inline-block' }}
                    />
                  </Container>
                </Container>
              </Group>
              {showSaleModal ? (
                <SaleModal
                  sales={saleInfo}
                  show={showSaleModal}
                  onClose={handleCloseSale}
                />
              ) : null}
            </Container>
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              disabled={isCreateButtonDisabled}
              style={{
                backgroundColor: 'green',
                borderRadius: '100px',
                isolation: 'isolate',
              }}
              onClick={() => sendBundledSale(selectedThirdClient.value)}
            >
              {isCreateButtonDisabled ? (
                <CircularProgress size={15} color="inherit" />
              ) : null}{' '}
              Agrupar
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cerrar
            </Button>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}

export default BundledSalesModal
