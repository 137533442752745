import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { ArrowLeft } from 'tabler-icons-react'

const Pdfviewer = ({ title, src, fileType, onClose }) => {
  const [href, setHref] = useState('')

  useEffect(() => {
    switch (fileType) {
      case 'base64':
        setHref(`data:application/pdf;base64,${src}`)
        break
      default:
        setHref(`${src}`)
        break
    }
  }, [])

  return (
    <div style={{ height: '100vh' }}>
      <Container style={{ height: 'calc(100% - 60px)' }}>
        <h4>{title}</h4>
        <div
          style={{
            margin: '10px',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            width: 'fit-content',
          }}
          onClick={onClose}
        >
          <ArrowLeft />
          Atrás
        </div>
        <div style={{ height: '100%' }}>
          <iframe src={href} width="100%" height="100%" title={title} />
        </div>
      </Container>
    </div>
  )
}

export default Pdfviewer
