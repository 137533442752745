import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import LoadingScreen from '../../components/main/loadingScreen'
import { isEmpty } from '../../services/utils'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import { getAllClientBranchesApi } from '../api/branches'
import { getClientUsersApi } from '../api/clientUsers'
import PayrollMenus from '../../components/client/shortcutMenus/payrollMenus'
import PayrollIntervals from '../../components/client/payroll/payrollIntervals'

export default function Settlement() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()
  //Clients Branches
  const [branches, setBranches] = useState([])

  //Clients Branch id
  const [searchBranchById, setSearchBranchById] = useState(0)
  const handleSearchBranchById = async (branchId) => {
    setSearchBranchById(branchId)
    await getClientUsers(tokenData.companyId, branchId)
  }

  const [clientUsers, setClientusers] = useState([])

  const getClientUsers = async (companyId, branchId) => {
    let result = await getClientUsersApi(companyId, branchId)
    if (result.data != null) setClientusers(result.data)
    else {
      notify('warning', 'No hay usuarios en esta sucursal')
      setClientusers([])
    }
  }

  //----------------------------------------------------------

  const tokenVerified = async () => {
    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await getClientUsers(tokenData.companyId, tokenData.branchId)
    await getClientBranches(tokenData.companyId)
  }

  const getClientBranches = async (companyId) => {
    let result = await getAllClientBranchesApi(companyId)
    if (result.data != null) setBranches(result.data)
    else notify('warning', 'No hay sucursales registradas')
  }

  //----------------------------------

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setSearchBranchById(tokenData.branchId)
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <PayrollMenus buttonId={1} />
          <br></br>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <FormControl className="branchSelect" variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Sucursal
                </InputLabel>
                <MuiSelect
                  label="Sucursal"
                  onChange={(e) => handleSearchBranchById(e.target.value)}
                  value={searchBranchById}
                >
                  {branches.map((branch, index) => {
                    return (
                      <MenuItem value={branch.id} key={index}>
                        {branch.name}
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
              </FormControl>
            </div>
            <PayrollIntervals
              clientUsers={clientUsers}
              branchId={searchBranchById}
              branches={branches}
              getClientUsers={getClientUsers}
            />
          </div>
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </div>
  )
}
