import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getClientSalesApi = async (companyId, branchId, offset, limit) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/${companyId}/${branchId}/${offset}/${limit}`,

      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas.')
  }
}

export const UpdateClientSaleStateApi = async (saleId, saleState) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale-state/update/${saleId}/${saleState}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el estado de la venta.')
  }
}

export const compareClientSalesApi = async (sales, companyId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-sale/compare/${companyId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sales),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al comparar las ventas.')
  }
}
export const getClientSalesByUserTodayApi = async (
  companyId,
  branchId,
  clientId,
  today,
  tomorrow,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/filter/user/${companyId}/${branchId}/${clientId}/${today}/${tomorrow}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas por usuario y día.')
  }
}

export const getUserTypeOfSalesByDayApi = async (clientId, today, tomorrow) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/user-credit-debit-total-by-client/${clientId}/${today}/${tomorrow}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result)
      return result // retorna {credit: 0, debit: 0}, no data:{credit: 0, debit: 0}
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el tipo de ventas por día.')
  }
}

export const getUserTotalSalesByDayApi = async (clientId, today, tomorrow) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/user-total-day/${clientId}/${today}/${tomorrow}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-6"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el conteo de ventas por día.')
  }
}

export const PostClientSaleApi = async (sale) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-sale`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sale),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-7"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear la venta.')
  }
}

export const PostBulkClientSalesApi = async (salesToUpload) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-sale/bulk`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(salesToUpload),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-8"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al subir las ventas.')
  }
}

export const getClientSalesReportsByDateApi = async (
  companyId,
  dateStart,
  dateEnd,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/report/${companyId}/${dateStart}/${dateEnd}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-9"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas por formato y fecha.')
  }
}

export const getClientSalesByIdApi = async (saleId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-sale/by-id/${saleId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-10"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener la venta por id.')
  }
}

export const getOneClientSaleByInvoiceNumberApi = async (
  companyId,
  branchId,
  invoiceNumber,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/one/${companyId}/${branchId}/${invoiceNumber}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-11"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas por invoiceNumber.')
  }
}

export const getClientSalesByInvoiceNumberApi = async (
  companyId,
  branchId,
  invoiceNumber,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/filter/invoice-number/${companyId}/${branchId}/${invoiceNumber}`,

      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-12"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas por número de factura.')
  }
}

export const getClientSalesBySaleStateApi = async (
  companyId,
  branchId,
  saleState,
  offset = 0,
  limit = 0,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/all/${companyId}/${branchId}/${saleState}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-13"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas por estado.')
  }
}

export const getFilteredClientSalesByDatesApi = async (
  companyId,
  branchId,
  thirdClientId,
  startDate,
  endDate,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/filter/${companyId}/${branchId}/${thirdClientId}/${startDate}/${endDate}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-14"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas por fecha.')
  }
}

export const getClientSalesByThirdClientIdApi = async (
  thirdClientId,
  branchId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/by-thirdclient/${thirdClientId}/${branchId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-15"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas por cliente.')
  }
}

export const getClientSalesByUserApi = async (
  companyId,
  branchId,
  clientId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/user-sales/${companyId}/${branchId}/${clientId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-16"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas por cliente y estado.')
  }
}

export const getAllUsersClientSalesByDayApi = async (
  companyId,
  branchId,
  today,
  tomorrow,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/users-total-today/${companyId}/${branchId}/${today}/${tomorrow}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-17"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify(
      'error',
      'Error al obtener las ventas del día de todos los usuarios.',
    )
  }
}

export const getUsersClientSalesTotalByDayApi = async (
  companyId,
  branchId,
  today,
  tomorrow,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-sale/total-today/${companyId}/${branchId}/${today}/${tomorrow}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-18"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el total de ventas del día por usuario.')
  }
}

export const UpdateClientSalesApi = async (data) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-sale/masive-sales-pmtd`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"SALES-19"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar las ventas.')
  }
}
