import React, { useEffect } from 'react'
import { useState } from 'react'
import { Group, Text } from '@mantine/core'
import Container from 'react-bootstrap/Container'
import {
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBModal,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit'
import saleIcon from '../../../resources/images/factura.png'
import Button from 'react-bootstrap/Button'
import SaleModal from '../sales/modals/saleModal'
import useStore from '../../zustand'
import { notify } from '../../../services/utils'
import { setDecimal } from '../../../services/utils'
import { getClientSalesByIdApi } from '../../../pages/api/clientsale'

const MoneyDeliverySalesModal = (props) => {
  const { show, onClose, delivery } = props
  const { isDarkModeActive } = useStore()

  //to utils
  const tableStateStyle = (state) => {
    if (isDarkModeActive) return { backgroundColor: '#424242' }
    if (state == 1) return { backgroundColor: 'rgb(212, 234, 251)' } //sin aprobar
    if (state == 2) return { backgroundColor: 'rgb(243, 252, 232)' } //aprobada
    if (state == 3) return { backgroundColor: 'rgb(238, 238, 238)' } //dian
    if (state == 4) return { backgroundColor: 'rgb(255, 235, 238' } //rechazada
    if (state == 5) return { backgroundColor: 'rgb(255, 236, 179)' } //agrupada
  }

  //----------delivery sales ----------------

  const [sales, setSales] = useState([])

  const getSales = async () => {
    let ids = delivery.salesId.split(',')
    let sales = []
    try {
      for (let i = 0; i < ids.length; i++) {
        let result = await getClientSalesByIdApi(ids[i])
        sales.push(result.data[0])
      }
      setSales(sales)
    } catch (error) {
      notify('error', 'Error al cargar las ventas')
      onClose()
    }
  }

  const getProductsTotalInitialPrice = (bill) => {
    let total = 0
    let products = JSON.parse(bill.products)
    products.map((product) => {
      total +=
        (product.price * product.quantity * (100 + parseInt(product.vat))) / 100
    })
    return total
  }

  const getTotalPriceSelectedSales = () => {
    let total = 0
    if (sales.length == 0) return setDecimal(total)
    sales.map((sale) => {
      total += getProductsTotalInitialPrice(sale)
    })
    return setDecimal(total)
  }
  //--------------------Sale info detallada---------------

  const [saleInfo, setSaleInfo] = useState([])

  const [showSaleModal, setShowSaleModal] = useState(false)
  const handleShowSaleModal = () => setShowSaleModal(true)
  const handleCloseSale = () => setShowSaleModal(false)

  const showSale = async (saleId) => {
    let result = await getClientSalesByIdApi(saleId)
    if (result.data != null) {
      setSaleInfo(result.data)
      handleShowSaleModal()
    }
  }

  const tokenVerified = async () => {
    await getSales()
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size="lg">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Mostrando recibos para la entrega #{delivery.id}
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <Container>
              <Group grow mb="md" mt="md" style={{ display: 'inline' }}>
                <MDBTable small className="salesTable">
                  <MDBTableHead style={{ color: color, isolation: 'isolate' }}>
                    <tr>
                      <th style={{ textAlign: 'center' }}>Número de factura</th>
                      <th style={{ textAlign: 'center' }}>Hora de venta</th>
                      <th style={{ textAlign: 'center' }}>Total</th>
                      <th className="saleInfo">Información detallada</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                    {sales.map((sale, index) => {
                      return (
                        <tr key={index} style={tableStateStyle(sale.saleState)}>
                          <td>{sale.invoiceNumber}</td>
                          <td>{sale.createdAt}</td>
                          <td>
                            ${setDecimal(getProductsTotalInitialPrice(sale))}
                          </td>
                          <td
                            style={{
                              width: '100px',
                              margin: 'auto',
                              textAlign: 'center',
                            }}
                          >
                            <a
                              onClick={() => showSale(sale.id)}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                style={{
                                  float: 'right',
                                  float: 'inline-start',
                                  alignContent: 'center',
                                  cursor: 'pointer',
                                  width: '40px',
                                }}
                                src={saleIcon}
                              />
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </MDBTableBody>
                </MDBTable>
              </Group>
              <Text>
                <b>Total:</b> ${getTotalPriceSelectedSales()}
              </Text>
              {showSaleModal ? (
                <SaleModal
                  sales={saleInfo}
                  show={showSaleModal}
                  onClose={handleCloseSale}
                />
              ) : null}
            </Container>
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cerrar
            </Button>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}

export default MoneyDeliverySalesModal
