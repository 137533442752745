import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const PostCreditNoteApi = async (creditNoteData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/credit-note`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(creditNoteData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CN-1"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear la nota crédito.')
  }
}

export const getCreditNotesApi = async (
  companyId,
  branchId,
  resolutionId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/credit-note/all/${companyId}/${branchId}/${resolutionId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CN-2"', rawResult.status, rawResult.statusText, result.error)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las notas crédito.')
  }
}

export const DeleteCreditNoteApi = async (
  creditNoteId,
  companyId,
  creditNoteNumeration,
  invoiceNumber,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/credit-note/${creditNoteId}/${companyId}/${creditNoteNumeration}/${invoiceNumber}`,
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CN-3"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar la nota crédito.')
  }
}
export const getFilteredCreditNotesByInvoiceApi = async (
  companyId,
  branchId,
  invoiceString,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/credit-note/filter/invoice-number/${companyId}/${branchId}/${invoiceString}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CN-4"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las notas crédito.')
  }
}

export const getFilteredCreditNotesByDateApi = async (
  companyId,
  branchId,
  thirdClientId,
  startDate,
  endDate,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/credit-note/filter/${companyId}/${branchId}/${thirdClientId}/${startDate}/${endDate}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CN-5"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las notas crédito.')
  }
}
