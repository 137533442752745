export const departmentsData = [
  { value: 5, label: 'ANTIOQUIA', code: '05' },
  { value: 8, label: 'ATLÁNTICO', code: '08' },
  { value: 11, label: 'BOGOTÁ, D.C.', code: '11' },
  { value: 13, label: 'BOLÍVAR', code: '13' },
  { value: 15, label: 'BOYACÁ', code: '15' },
  { value: 17, label: 'CALDAS', code: '17' },
  { value: 18, label: 'CAQUETÁ', code: '18' },
  { value: 19, label: 'CAUCA', code: '19' },
  { value: 20, label: 'CESAR', code: '20' },
  { value: 23, label: 'CÓRDOBA', code: '23' },
  { value: 25, label: 'CUNDINAMARCA', code: '25' },
  { value: 27, label: 'CHOCÓ', code: '27' },
  { value: 41, label: 'HUILA', code: '41' },
  { value: 44, label: 'LA GUAJIRA', code: '44' },
  { value: 47, label: 'MAGDALENA', code: '47' },
  { value: 50, label: 'META', code: '50' },
  { value: 52, label: 'NARIÑO', code: '52' },
  { value: 54, label: 'NORTE DE SANTANDER', code: '54' },
  { value: 63, label: 'QUINDIO', code: '63' },
  { value: 66, label: 'RISARALDA', code: '66' },
  { value: 68, label: 'SANTANDER', code: '68' },
  { value: 70, label: 'SUCRE', code: '70' },
  { value: 73, label: 'TOLIMA', code: '73' },
  { value: 76, label: 'VALLE DEL CAUCA', code: '76' },
  { value: 81, label: 'ARAUCA', code: '81' },
  { value: 85, label: 'CASANARE', code: '85' },
  { value: 86, label: 'PUTUMAYO', code: '86' },
  {
    value: 88,
    label: 'ARCHIPIÉLAGO DE SAN ANDRÉS, PROVIDENCIA Y SANTA CATALINA',
    code: '88',
  },
  { value: 91, label: 'AMAZONAS', code: '91' },
  { value: 94, label: 'GUAINÍA', code: '94' },
  { value: 95, label: 'GUAVIARE', code: '95' },
  { value: 97, label: 'VAUPÉS', code: '97' },
  { value: 99, label: 'VICHADA', code: '99' },
]

export const countriesData = [{ value: 1, label: 'Colombia', code: 57 }]
