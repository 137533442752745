import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import Container from 'react-bootstrap/Container'
import { notify, setDecimal } from '../../../../services/utils'
import { signatureUrl } from '../../../../services/url'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import SaleTicket from '../saleTicket'
import { useReactToPrint } from 'react-to-print'
import useStore from '../../../zustand'
import '../../../../styles/modal.module.css'

import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { getClientHosesApi } from '../../../../pages/api/clienthoses'
import { getCompanyByIdApi } from '../../../../pages/api/companies'
import { getDriverBusByPlateApi } from '../../../../pages/api/thirdClientsDriverBusses'
import { getWaysToPayApi } from '../../../../pages/api/wayToPay'

const SaleModal = (props) => {
  const { tokenData, isDarkModeActive } = useStore()

  const EnumPaymentMethods = {
    1: 'CONTADO',
    2: 'CRÉDITO',
  }

  const enumFuelType = {
    1: 'Diésel',
    2: 'Corriente',
    3: 'Extra',
    4: 'Premium',
    5: 'Urea',
    6: 'ACPM',
    7: 'Greenplus',
  }

  const EnumIva = {
    0: 'IVA 0% (Exento)',
    5: 'IVA 5%',
    16: 'IVA 16%',
    19: 'IVA 19%',
  }

  const [sales, setSales] = useState(props.sales)
  const [products, setProducts] = useState(JSON.parse(props.sales[0].products))

  const { show, onClose } = props

  //----------------------Bills methods---------------------------

  const getProductsTotalPrice = (products) => {
    let total = 0
    products.map((product) => {
      total += (product.price * product.quantity * (100 + product.vat)) / 100
    })
    return total
  }

  const getProductsSubtotalPrice = (products) => {
    let subtotal = 0
    products.map((product) => {
      let discount =
        product.discount_rate != undefined ? product.discount_rate : 0
      subtotal += product.price * (1 - discount / 100) * product.quantity
    })

    return subtotal
  }

  //----print
  const [print, setPrint] = useState(false)
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrint(true)
    },
    onAfterPrint: () => {
      setPrint(false)
      onClose()
    },
  })

  const [waysToPay, setWaysToPay] = useState([])
  const getWaysToPay = async () => {
    let result = await getWaysToPayApi()
    if (result.data != null) setWaysToPay(result.data)
    else notify('warning', 'No se encontraron tipos de pago')
  }

  const getWayToPayText = (wayToPay) => {
    let wayToPayText = waysToPay.find((x) => x.id === wayToPay)
    if (wayToPayText != null) return wayToPayText.waytopay
    else return 'No definida'
  }

  const setInitialVariables = async () => {
    await getWaysToPay()
    await getCompany()
    await getClientHoses(tokenData.companyId, tokenData.branchId)
    await getClientBusByPlate(props.sales[0].busPlate)
    setSales(props.sales)
  }

  const [company, setCompany] = useState([
    {
      name: '',
      logo: '',
      nit: '',
      department: '',
      municipalitie: '',
    },
  ])
  const [logoFile, setLogoFile] = useState(null)

  const getCompany = async () => {
    let result = await getCompanyByIdApi(tokenData.companyId)
    if (result.data != null) {
      setCompany(result.data)
      setLogoFile(result.data[0].logo)
    } else notify('error', 'Error al obtener la empresa.')
  }

  const [clientBusByPlate, setClientBusByPlate] = useState([
    {
      driverName: '',
      driverCc: '',
      discount: 0,
      thirdClientCompanyName: '',
      thirdClientsId: '',
      isGeneric: false,
    },
  ])
  const getClientBusByPlate = async (busPlate) => {
    let result = await getDriverBusByPlateApi(tokenData.companyId, busPlate)
    if (result.data != null) {
      setClientBusByPlate(result.data)
    } else notify('warning', 'No se encontró el bus.')
  }

  const getClientHoses = async (companyId, branchId) => {
    try {
      let result = await getClientHosesApi(companyId, branchId)
      if (result.data != null) setHoses(result.data)
      else setHoses([])
    } catch (error) {
      return false
    }
  }

  const [hoses, setHoses] = useState([])

  const getHoseById = (hoseId) => {
    if (hoses.length > 0) {
      let hoseData = hoses.find((x) => x.id === hoseId)
      if (hoseData == undefined) {
        return { tankName: '-', name: '-', islandName: '-' }
      }
      return hoseData
    } else {
      return { tankName: '-', name: '-', islandName: '-' }
    }
  }

  //-----------

  useEffect(() => {
    setInitialVariables()
  }, [])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size="lg">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Recibo #{sales[0].invoiceNumber}
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <Container>
              <h5>Información de la venta</h5>
              <hr></hr>
              <Group grow mb="md" mt="md" style={{ display: 'inline' }}>
                <MDBTable small style={{ color: color }}>
                  <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                    <tr>
                      <th>Empresa</th>
                      <td>{sales[0].thirdClientCompanyName}</td>
                    </tr>
                    <tr>
                      <th>Placa</th>
                      <td>{sales[0].busPlate}</td>
                    </tr>
                    <tr>
                      <th>Conductor</th>
                      <td>{sales[0].driverName}</td>
                    </tr>
                    <tr>
                      <th>Método de venta</th>
                      <td>{EnumPaymentMethods[sales[0].paymentMethod]}</td>
                    </tr>
                    <tr>
                      <th>Forma de Pago</th>
                      <td>{getWayToPayText(sales[0].wayToPay)}</td>
                    </tr>
                    {sales[0].transferCode != '' ? (
                      <tr>
                        <th>Código de Transferencia</th>
                        <td>{sales[0].transferCode}</td>
                      </tr>
                    ) : null}
                    {sales[0].fuelType != 10 ? (
                      <tr>
                        <th>Tipo de Combustible</th>
                        <td>{enumFuelType[sales[0].fuelType]}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <th>Islero Encargado</th>
                      <td>{sales[0].clientUserName}</td>
                    </tr>
                    <tr>
                      <th>Fecha de venta</th>
                      <td>{sales[0].createdAt}</td>
                    </tr>
                    <tr>
                      <th>Firma</th>
                      <td>
                        <img
                          src={signatureUrl + sales[0].signature}
                          width={100}
                        ></img>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </Group>
              <h5>Productos</h5>
              <hr></hr>
              <Group grow mb="md" mt="md" style={{ display: 'inline' }}>
                <MDBTable small style={{ color: color }}>
                  <MDBTableHead>
                    <tr>
                      <th>NOMBRE</th>
                      <th>CANTIDAD</th>
                      <th>DESCUENTO</th>
                      <th>IVA</th>
                      <th>SUBTOTAL</th>
                      <th>TOTAL</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                    {products.map((product, index) => {
                      return (
                        <tr key={index}>
                          <td>{product.name}</td>
                          <td>{product.quantity}</td>
                          <td>
                            {product.discount != undefined
                              ? product.discount
                              : 0}
                            %
                          </td>
                          <td>{EnumIva[product.vat]}</td>
                          <td>
                            ${setDecimal(product.price * product.quantity)}
                          </td>
                          <td>
                            $
                            {setDecimal(
                              product.price *
                                product.quantity *
                                (1 + product.vat / 100),
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </MDBTableBody>
                </MDBTable>
              </Group>
              <MDBTable style={{ color: color }}>
                <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                  <tr>
                    <td>Subtotal:</td>
                    <td>
                      $
                      {setDecimal(
                        parseFloat(getProductsSubtotalPrice(products)),
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total:</b>
                    </td>
                    <td>
                      <b>${setDecimal(getProductsTotalPrice(products))}</b>
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
              <details open={print}>
                <summary>Colilla</summary>
                <div ref={componentRef}>
                  <SaleTicket
                    productsadded={products}
                    totals={getProductsTotalPrice(products)}
                    sellername={sales[0].clientUserName}
                    clientbusbyplate={clientBusByPlate}
                    busplate={sales[0].busPlate}
                    trimmeddataurl={signatureUrl + sales[0].signature}
                    saleType={sales[0].saleType}
                    invoicenumber={sales[0].invoiceNumber}
                    hosedata={getHoseById(sales[0].clientHoseId)}
                  />
                </div>
              </details>
            </Container>
          </MDBModalBody>
          <MDBModalFooter>
            <br></br>
            <br></br>
            <Button
              style={{
                backgroundColor: 'green',
                borderRadius: '100px',
                isolation: 'isolate',
              }}
              onClick={() => handlePrint()}
            >
              Imprimir
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
            <br></br>
            <br></br>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}

export default SaleModal
