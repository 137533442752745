import React, { useEffect, useState } from 'react'
import Menu from '../../components/client/menu'
import PayrollMenus from '../../components/client/shortcutMenus/payrollMenus'
import LoadingScreen from '../../components/main/loadingScreen'
import useStore from '../../components/zustand'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material'
import {
  getYearsToDisplay,
  isEmpty,
  monthFormat,
  notify,
} from '../../services/utils'
import { useNavigate } from 'react-router'
import { Container } from 'react-bootstrap'
import {
  getHistoricalSettlementApi,
  getPayrollEntriesByIdsApi,
} from '../api/payroll'
import PayrollHistorical from '../../components/client/payroll/payrollHistorical'
import { ExpandMore } from '@mui/icons-material'
import SettlementHistorical from '../../components/client/payroll/settlementHistorical'
import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import Pdfviewer from '../../components/custom/pdfviewer'

const PeriodsHistorical = () => {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  const [showPeriodDetailsModal, setShowPeriodDetailsModal] = useState(false)
  const handleOnClosePeriodDetails = () => setShowPeriodDetailsModal(false)

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

  const handleChangeYear = async (year) => {
    setCurrentYear(year)
    await getPeriodsHistorical(tokenData.companyId, tokenData.branchId, year)
  }

  const [currentPeriod, setCurrentPeriod] = useState({})
  const [payrollsByPeriod, setPayrollsByPeriod] = useState({})

  const handlePeriodDetailsChoosed = async (period) => {
    let payrolls = await getPayrollsByPeriod(period.payroll_ids)
    if (payrolls.length == 0)
      notify('warning', 'No hay nóminas en este periodo.')
    else {
      setCurrentPeriod(period)
      setPayrollsByPeriod(payrolls)
      setShowPeriodDetailsModal(true)
    }
  }

  const getPayrollsByPeriod = async (periodId) => {
    let result = await getPayrollEntriesByIdsApi(periodId)
    if (result.data != null) {
      return result.data
    } else {
      notify('warning', 'No hay nóminas en este periodo.')
      return []
    }
  }

  const tokenVerified = async () => {
    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      return navigate('/dashboard')
    }
    await getPeriodsHistorical(
      tokenData.companyId,
      tokenData.branchId,
      currentYear,
    )
  }

  const [periodsHistorical, setPeriodsHistorical] = useState([])

  const getPeriodsHistorical = async (companyId, branchId, year) => {
    let result = await getHistoricalSettlementApi(companyId, branchId, year)
    if (result.data != null) {
      setPeriodsHistorical(groupByYearAndMonth(result.data))
    } else notify('info', 'No hay periodos para el año seleccionado.')
  }

  const groupByYearAndMonth = (periods) => {
    let groupedPeriods = []
    periods.forEach((period) => {
      let year = period.year
      let month = period.month
      let yearIndex = groupedPeriods.findIndex((group) => group.year == year)
      if (yearIndex == -1) {
        groupedPeriods.push({
          year: year,
          months: [{ month: month, periods: [period] }],
        })
      } else {
        let monthIndex = groupedPeriods[yearIndex].months.findIndex(
          (group) => group.month == month,
        )
        if (monthIndex == -1) {
          groupedPeriods[yearIndex].months.push({
            month: month,
            periods: [period],
          })
        } else {
          groupedPeriods[yearIndex].months[monthIndex].periods.push(period)
        }
      }
    })
    return groupedPeriods
  }

  //-----------------------pdf viewer-----------------------
  const [pdfViewerShow, setPdfViewerShow] = React.useState(false)
  const [pdfHeaders, setPdfHeaders] = React.useState({
    title: '',
    src: '',
    fileType: '',
  })
  const handleClosePdfViewer = () => setPdfViewerShow(false)

  const showPdfViewer = (title, src, fileType) => {
    setPdfHeaders({ title, src, fileType })
    setPdfViewerShow(true)
  }
  //--------------------------------------------------------

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <PayrollMenus buttonId={2} />
          <br></br>
          {pdfViewerShow ? (
            <Pdfviewer
              title={pdfHeaders.title}
              src={pdfHeaders.src}
              fileType={pdfHeaders.fileType}
              onClose={handleClosePdfViewer}
            />
          ) : showPeriodDetailsModal ? (
            <div>
              <SettlementHistorical
                currentPeriod={currentPeriod}
                payrollsByPeriod={payrollsByPeriod}
                showPdfViewer={showPdfViewer}
                onClose={handleOnClosePeriodDetails}
              />
            </div>
          ) : (
            <div>
              <h4>Histórico de nóminas por periodo</h4>
              <MDBTabs>
                {getYearsToDisplay().map((year, index) => (
                  <MDBTabsItem key={index}>
                    <MDBTabsLink
                      onClick={() => handleChangeYear(year)}
                      active={currentYear === parseInt(year)}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      {year}
                    </MDBTabsLink>
                  </MDBTabsItem>
                ))}
              </MDBTabs>
              <br></br>
              <MDBTabsContent>
                {periodsHistorical.map((year, index) => (
                  <MDBTabsPane
                    key={index}
                    show={currentYear === parseInt(year.year)}
                  >
                    {year.months.map((month, index) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            <span>{`${monthFormat(
                              parseInt(month.month),
                              false,
                            ).toUpperCase()} ${year.year}`}</span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            padding: '0 15px',
                          }}
                        >
                          <b>Periodos facturados</b>
                          <div
                            style={{
                              display: 'flex',
                              margin: '10px 0',
                            }}
                          >
                            {month.periods.map((period) => (
                              <div
                                style={{
                                  border: '1px solid gray',
                                  borderRadius: '5px',
                                  padding: '5px 10px',
                                  margin: '5px',
                                  backgroundColor: 'rgb(13, 71, 161)',
                                  color: 'white',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  handlePeriodDetailsChoosed(period)
                                }}
                                key={period.id}
                              >
                                {`${period.name}`}
                              </div>
                            ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </MDBTabsPane>
                ))}
              </MDBTabsContent>
            </div>
          )}
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </div>
  )
}

export default PeriodsHistorical
