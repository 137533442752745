import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getHistoricalByThirdClientApi = async (
  thirdClientId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/balancehistorical/${thirdClientId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"BH-1"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el histórico de balance.')
  }
}

export const updateClientHistoricalBalanceApi = async (
  clientHistoricalData,
) => {
  try {
    let rawResult = await fetch(`${apilUrl}/balancehistorical`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clientHistoricalData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"BH-2"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el histórico de balance.')
  }
}
