import React, { useState, useEffect } from 'react'
import { Group } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify, setDecimal } from '../../../services/utils'
import { TextField, Tooltip } from '@mui/material'
import 'react-toastify/dist/ReactToastify.css'
import { Container } from 'react-bootstrap'
import dayjs from 'dayjs'
import {
  accrualTypes,
  deductionTypes,
} from '../../../services/selectData/clientUsersData'
import { ArrowLeft, Plus } from 'tabler-icons-react'
import CustomSelect from '../../custom/customSelect'
import useStore from '../../zustand/index'
import ActionButton from '../../custom/actionButton'
import CurrencyInput from '../../custom/customCurrencyInput'
import Button from 'react-bootstrap/Button'
import { UpdateClientUsersApi } from '../../../pages/api/clientUsers'

const ClientUserNovelties = ({
  onClose = null,
  getClientUsers,
  clientUserDetails,
}) => {
  const { isDarkModeActive } = useStore()
  const infoForm = useForm({
    initialValues: {
      fullName: '',
      username: '',
      email: '',
      roleId: 0,
      stateId: 0,
      userId: '',
      userIdType: 0,
      name: '',
      otherNames: '',
      lastName: '',
      secondLastName: '',
      country: '',
      idDepartment: '',
      cityCode: '',
      address: '',
      countryCode: 57,
      phone: '',
    },
  })

  const rolesForm = useForm({
    initialValues: {
      admin: false,
      sales: false,
      invoice: false,
      modules: false,
      clients: false,
      configuration: false,
    },
  })

  const payrollForm = useForm({
    initialValues: {
      salary: 0.0,
      workerType: 0,
      subCode: 0,
      periodicity: 0,
      contractType: 0,
      area: 0,
      position: '',
      startDate: dayjs(new Date()),
      fireDate: dayjs(new Date()),
      eps: 0,
      pension: 0,
      layoffs: 0,
      workRisk: 0,
      compensation: 0,
      accountTypeKw: 0,
      accountNumber: '',
      bank: '',
      highRisk: false,
      paymentMeans: 0,
    },
  })

  const [selectedAccrual, setSelectedAccrual] = useState(null)
  const accrualForm = useForm({
    initialValues: {
      name: '',
      code: '',
      amount: 0.0,
      days: 0,
    },
  })
  const handleAccrualChange = (e) => {
    if (accruals.find((accrual) => accrual.code === e.code)) {
      notify('warning', 'Ya se ha agregado este devengado.')
      return
    }
    setSelectedAccrual(e)
    accrualForm.setFieldValue('code', e.code)
    accrualForm.setFieldValue('name', e.label)
  }

  const [accruals, setAccruals] = useState([])

  const handleAddAccrual = () => {
    if (accrualForm.values.amount === 0 || accrualForm.values.days === 0) {
      notify('warning', 'Debe ingresar un valor y días para el devengado.')
      return
    }
    let newAccrual = {
      name: accrualForm.values.name,
      code: accrualForm.values.code,
      amount: accrualForm.values.amount,
      days: accrualForm.values.days,
      editable: true,
    }
    setAccruals([...accruals, newAccrual])
    accrualForm.setValues({
      name: '',
      code: '',
      amount: 0,
      days: 0,
    })
    setSelectedAccrual(null)
    notify('success', 'Devengado agregado correctamente.')
  }

  const [selectedDeduction, setSelectedDeduction] = useState(null)
  const deductionForm = useForm({
    initialValues: {
      name: '',
      code: '',
      amount: 0,
      percentage: 0.0,
    },
  })
  const handleDeductionChange = (e) => {
    setSelectedDeduction(e)
    deductionForm.setFieldValue('code', e.code)
    deductionForm.setFieldValue('name', e.label)
  }

  const [deductions, setDeductions] = useState([])

  const handleAddDeductions = () => {
    if (
      deductionForm.values.amount === 0 ||
      deductionForm.values.percentage === 0
    ) {
      notify(
        'warning',
        'Debe ingresar un valor y porcentaje para la deducción.',
      )
      return
    }
    let newDeduction = {
      name: deductionForm.values.name,
      code: deductionForm.values.code,
      amount: deductionForm.values.amount,
      percentage: deductionForm.values.percentage,
      editable: true,
    }
    setDeductions([...deductions, newDeduction])
    deductionForm.setValues({
      name: '',
      code: '',
      amount: 0,
      percentage: 0,
    })
    setSelectedDeduction(null)
    notify('success', 'Deducción agregada correctamente.')
  }

  const getTotalValue = (values, valueName) => {
    let total = 0.0
    values.forEach((value) => {
      total += parseFloat(value[valueName])
    })
    return total
  }

  const deleteFromList = (item, list, setList) => {
    let newList = list.filter((listItem) => listItem !== item)
    setList(newList)
  }

  const updateNovelties = async () => {
    let clientUser = clientUserDetails
    clientUser.accruals = JSON.stringify(accruals)
    clientUser.deductions = JSON.stringify(deductions)
    let result = await UpdateClientUsersApi(clientUser.id, clientUser)
    if (result.data != null) {
      notify('success', 'Novedades actualizadas correctamente.')
      getClientUsers(clientUser.companyId, clientUser.branchId)
      onClose && onClose()
    } else notify('error', 'Error al actualizar las novedades.')
  }

  useEffect(() => {
    infoForm.setValues({
      clientId: clientUserDetails.clientUserId,
      userIdType: clientUserDetails.userIdType.Int32,
      userId: clientUserDetails.userId,
      name: clientUserDetails.name.String,
      otherNames: clientUserDetails.otherNames.String,
      lastName: clientUserDetails.lastName.String,
      secondLastName: clientUserDetails.secondLastName.String,
      country: clientUserDetails.country.Int32,
      idDepartment: clientUserDetails.idDepartment.Int32,
      cityCode: clientUserDetails.cityCode.Int32,
      address: clientUserDetails.address.String,
      phone: clientUserDetails.phone.String,
      email: clientUserDetails.email,
      roleId: clientUserDetails.roleId,
    })
    payrollForm.setValues({
      salary: clientUserDetails.salary,
      workerType: clientUserDetails.workerType,
      subCode: clientUserDetails.subCode,
      periodicity: clientUserDetails.periodicity,
      contractType: clientUserDetails.contractType,
      area: clientUserDetails.area,
      position: clientUserDetails.position,
      startDate: dayjs(clientUserDetails.startDate),
      fireDate: dayjs(clientUserDetails.fireDate),
      eps: clientUserDetails.eps,
      pension: clientUserDetails.pension,
      layoffs: clientUserDetails.layoffs,
      workRisk: clientUserDetails.workRisk,
      compensation: clientUserDetails.compensation,
      accountTypeKw: clientUserDetails.accountTypeKw,
      accountNumber: clientUserDetails.accountNumber,
      bank: clientUserDetails.bank,
      highRisk: clientUserDetails.highRisk,
      paymentMeans: clientUserDetails.paymentMeans,
    })
    rolesForm.setValues({
      admin: clientUserDetails.admin,
      sales: clientUserDetails.sales,
      invoice: clientUserDetails.invoice,
      modules: clientUserDetails.modules,
      clients: clientUserDetails.clients,
      configuration: clientUserDetails.configuration,
    })
    if (clientUserDetails.accruals === '') {
      clientUserDetails.accruals = '[]'
    }
    if (clientUserDetails.deductions === '') {
      clientUserDetails.deductions = '[]'
    }
    setAccruals(JSON.parse(clientUserDetails.accruals))
    setDeductions(JSON.parse(clientUserDetails.deductions))
  }, [clientUserDetails])

  return (
    <Container>
      <div>
        {onClose && (
          <div
            style={{
              margin: '10px',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              width: 'fit-content',
            }}
            onClick={onClose}
          >
            <ArrowLeft />
            Atrás
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            border: '1px solid #000',
            borderRadius: '10px',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              padding: '20px',
              paddingBottom: '10px',
              marginBottom: '10px',
              borderBottom: '1px solid gray',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            }}
          >
            <h4
              style={{
                fontSize: '19px',
              }}
            >
              Novedades del empleado <b>{clientUserDetails.fullName}</b>
            </h4>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '14px',
                border: '1px solid gray',
                borderRadius: '10px',
                padding: '20px',
                width: '100%',
                margin: '0 10px',
              }}
            >
              <h5>Devengados</h5>
              {!selectedAccrual ? (
                <CustomSelect
                  label="Devengados"
                  placeholder="Seleccione un tipo de devengado"
                  options={accrualTypes}
                  onChange={(e) => handleAccrualChange(e)}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  maxWidth={400}
                  withBorder={false}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {selectedAccrual.label}
                  </span>
                  <CurrencyInput
                    variant="standard"
                    required
                    label={`Valor de ${selectedAccrual.label}`}
                    placeholder={`Valor de ${selectedAccrual.label}`}
                    value={accrualForm.values.amount}
                    onChange={(event) =>
                      accrualForm.setFieldValue(
                        'amount',
                        event.currentTarget.value
                          .replace(/,/g, '')
                          .replace('$', ''),
                      )
                    }
                  />
                  <TextField
                    variant="standard"
                    required
                    label="Días"
                    placeholder="Días"
                    type="number"
                    value={accrualForm.values.days}
                    onChange={(event) => {
                      accrualForm.setFieldValue(
                        'days',
                        event.currentTarget.value,
                      )
                    }}
                  />
                  <ActionButton onClick={() => handleAddAccrual()}>
                    <Plus size={20} color="black" />
                  </ActionButton>
                </div>
              )}
            </Group>
            <Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '14px',
                border: '1px solid gray',
                borderRadius: '10px',
                padding: '20px',
                width: '100%',
                margin: '0 10px',
              }}
            >
              <h5>Deducciones</h5>
              {!selectedDeduction ? (
                <CustomSelect
                  label="Deducciones"
                  placeholder="Seleccione un tipo de deducción"
                  options={deductionTypes}
                  onChange={(e) => handleDeductionChange(e)}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  maxWidth={400}
                  withBorder={false}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {selectedDeduction.label}
                  </span>
                  <CurrencyInput
                    variant="standard"
                    required
                    label={`Valor de ${selectedDeduction.label}`}
                    placeholder={`Valor de ${selectedDeduction.label}`}
                    value={deductionForm.values.amount}
                    onChange={(event) =>
                      deductionForm.setFieldValue(
                        'amount',
                        event.currentTarget.value
                          .replace(/,/g, '')
                          .replace('$', ''),
                      )
                    }
                  />
                  <TextField
                    variant="standard"
                    required
                    label="Porcentaje"
                    placeholder="Porcentaje"
                    type="number"
                    value={deductionForm.values.percentage}
                    onChange={(event) => {
                      deductionForm.setFieldValue(
                        'percentage',
                        event.currentTarget.value,
                      )
                    }}
                  />
                  <ActionButton onClick={() => handleAddDeductions()}>
                    <Plus size={20} color="black" />
                  </ActionButton>
                </div>
              )}
            </Group>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            border: '1px solid #000',
            borderRadius: '10px',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              padding: '20px',
              paddingBottom: '10px',
              marginBottom: '10px',
              borderBottom: '1px solid gray',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            }}
          >
            <h4
              style={{
                fontSize: '19px',
              }}
            >
              Resumen de novedades del empleado{' '}
              <b>{clientUserDetails.fullName}</b>
            </h4>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '14px',
                border: '1px solid gray',
                borderRadius: '10px',
                padding: '20px',
                width: '100%',
                margin: '0 10px',
                textAlign: 'left',
              }}
            >
              <h5>Devengados</h5>
              <table
                style={{
                  width: '100%',
                }}
              >
                <thead>
                  <tr>
                    <th>Devengado</th>
                    <th>Días</th>
                    <th>Valor</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accruals.length > 0 &&
                    accruals.map((accrual, index) => (
                      <tr
                        key={index}
                        style={{
                          justifyContent: 'space-between',
                          margin: '5px 0',
                        }}
                      >
                        <td>{accrual.name}</td>
                        <td>{accrual.days} día(s)</td>
                        <td>$ {setDecimal(accrual.amount)}</td>
                        <td>
                          {accrual.editable && (
                            <Tooltip
                              title="Eliminar"
                              followCursor
                              onClick={() => {
                                deleteFromList(accrual, accruals, setAccruals)
                              }}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                                fontSize: '30px',
                              }}
                            >
                              <span>-</span>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td>
                      <hr />
                    </td>
                    <td>
                      <hr />
                    </td>
                    <td>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 'bold',
                        float: 'left',
                      }}
                    >
                      Total devengados
                    </td>
                    <td></td>
                    <td>$ {setDecimal(getTotalValue(accruals, 'amount'))}</td>
                  </tr>
                </tbody>
              </table>
            </Group>
            <Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '14px',
                border: '1px solid gray',
                borderRadius: '10px',
                padding: '20px',
                width: '100%',
                margin: '0 10px',
                textAlign: 'left',
              }}
            >
              <h5>Deducciones</h5>
              <table
                style={{
                  width: '100%',
                }}
              >
                <thead>
                  <tr>
                    <th>Deducción</th>
                    <th>Porcentaje</th>
                    <th>Valor</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {deductions.length > 0 &&
                    deductions.map((deduction, index) => (
                      <tr
                        key={index}
                        style={{
                          justifyContent: 'space-between',
                          margin: '5px 0',
                        }}
                      >
                        <td>{deduction.name}</td>
                        <td>{deduction.percentage}%</td>
                        <td>$ {setDecimal(deduction.amount)}</td>
                        <td>
                          {deduction.editable && (
                            <Tooltip
                              title="Eliminar"
                              followCursor
                              onClick={() => {
                                deleteFromList(
                                  deduction,
                                  deductions,
                                  setDeductions,
                                )
                              }}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                                fontSize: '30px',
                              }}
                            >
                              <span>-</span>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td>
                      <hr />
                    </td>
                    <td>
                      <hr />
                    </td>
                    <td>
                      <hr />
                    </td>
                    <td>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 'bold',
                        float: 'left',
                      }}
                    >
                      Total deducciones
                    </td>
                    <td></td>
                    <td>$ {setDecimal(getTotalValue(deductions, 'amount'))}</td>
                  </tr>
                </tbody>
              </table>
            </Group>
          </div>
          <Group
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: '14px',
              border: '1px solid gray',
              borderRadius: '10px',
              padding: '20px',
              margin: '20px',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            }}
          >
            <b>Devengados totales:</b>${' '}
            {setDecimal(getTotalValue(accruals, 'amount'))} -
            <b>Deducciones totales:</b> ${' '}
            {setDecimal(getTotalValue(deductions, 'amount'))} =
            <b>Neto a pagar:</b> ${' '}
            {setDecimal(
              getTotalValue(accruals, 'amount') -
                getTotalValue(deductions, 'amount'),
            )}
          </Group>
        </div>
        <div
          style={{
            alignItems: 'center',
            textAlign: 'right',
          }}
        >
          <Button
            style={{
              backgroundColor: 'green',
              borderRadius: '100px',
              isolation: 'isolate',
              marginBottom: '10px',
            }}
            onClick={() => {
              updateNovelties()
            }}
          >
            Guardar
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default ClientUserNovelties
