import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import { InputLabel, TextField } from '@mui/material'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { Select as MuiSelect, MenuItem } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import LoadingScreen from '../../components/main/loadingScreen'
import { CirclePlus, Edit, Trash } from 'tabler-icons-react'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import Swal from 'sweetalert2'
import { isEmpty } from '../../services/utils'
import { CircularProgress } from '@mui/material'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import useStore from '../../components/zustand'
import ConfigurationMenus from '../../components/client/shortcutMenus/configurationMenus'
import {
  DeleteClientPaymentMethodApi,
  GetClientPaymentMethodApi,
  GetClientPaymentMethodByCompanyIdApi,
  PostClientPaymentMethodApi,
  UpdateClientPaymentMethodApi,
} from '../api/clientPaymentMethod'
import { FormControl } from '@mui/material'
import { getWaysToPayApi } from '../api/wayToPay'
import ActionButton from '../../components/custom/actionButton'

export default function PaymentMethod() {
  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  //Clients Company
  const [paymentMethods, setPaymentMethods] = useState([])

  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  const [modalPutShow, setModalPutShow] = React.useState(false)

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const form = useForm({
    initialValues: {
      ledgerAccount: '',
      name: '',
      paymentMethod: 0,
      prefix: '',
      voucher: '',
    },
  })

  const putForm = useForm({
    initialValues: {
      id: '',
      ledgerAccount: '',
      name: '',
      paymentMethod: 0,
      prefix: '',
      voucher: '',
    },
  })

  const prefixes = [
    { prefix: 'EF', name: 'Efectivo' },
    { prefix: 'CH', name: 'Cheque' },
    { prefix: 'BN', name: 'Bonos' },
    { prefix: 'TD', name: 'Tarjeta de Débito' },
    { prefix: 'TC', name: 'Tarjeta de Crédito' },
    { prefix: 'TB', name: 'Transferencia Bancaria' },
    { prefix: 'CB', name: 'Consignación Bancaria' },
    { prefix: 'OT', name: 'Otros' },
  ]

  const getMethodData = async (
    id,
    ledgerAccount,
    name,
    paymentMethod,
    prefix,
    voucher,
  ) => {
    putForm.setFieldValue('id', id)
    putForm.setFieldValue('ledgerAccount', ledgerAccount)
    putForm.setFieldValue('name', name)
    putForm.setFieldValue('paymentMethod', paymentMethod)
    putForm.setFieldValue('prefix', prefix)
    putForm.setFieldValue('voucher', voucher)
    setModalPutShow(true)
  }

  const createPaymentMethod = async () => {
    setIsCreateButtonDisabled(true)
    let readyToCreate = true

    if (form.values.ledgerAccount == '') {
      readyToCreate = false
      notify('warning', 'Falta el código de cuenta contable.')
    }
    if (form.values.name == '') {
      readyToCreate = false
      notify('warning', 'Falta el nombre del método de pago.')
    }

    if (form.values.paymentMethod == 0) {
      readyToCreate = false
      notify('warning', 'Falta el tipo de pago.')
    }

    if (form.values.prefix == '') {
      readyToCreate = false
      notify('warning', 'Falta el prefijo de pago.')
    }

    if (form.values.voucher == '') {
      readyToCreate = false
      notify('warning', 'Falta el comprobante.')
    }

    if (readyToCreate) {
      let result = await PostClientPaymentMethodApi(
        tokenData.branchId,
        tokenData.companyId,
        form.values.ledgerAccount,
        form.values.name,
        form.values.paymentMethod,
        form.values.prefix,
        form.values.voucher,
      )
      if (result.data == 'Clientpaymentmethod created') {
        notify('success', 'Creación Exitosa')
        setModalCreateShow(false)
        resetConstants()
        await getPaymentMethods(tokenData.companyId)
      } else {
        notify('warning', 'Error al crear el método de pago.')
      }
    }
    setIsCreateButtonDisabled(false)
  }

  const resetConstants = () => {
    form.setFieldValue('ledgerAccount', '')
    form.setFieldValue('name', '')
    form.setFieldValue('paymentMethod', 0)
    form.setFieldValue('prefix', '')
    form.setFieldValue('voucher', '')
  }

  const deletePaymentMethod = async (id) => {
    Swal.fire({
      title: '¿Eliminar el método de pago seleccionado?',
      text: 'Nota: Esta acción puede provocar inconsistencias en los reportes de ventas',
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (response) => {
      if (response.isConfirmed) {
        let result = await DeleteClientPaymentMethodApi(id)

        if (result.data == 'Clientpaymentmethod deleted') {
          notify('success', 'Método de pago eliminado exitosamente')
          await getPaymentMethods(tokenData.companyId)
        } else {
          notify('warning', 'Error al eliminar el método de pago.')
        }
      }
    })
  }

  const getPaymentMethods = async (companyId) => {
    let result = await GetClientPaymentMethodByCompanyIdApi(companyId)
    result.data != null
      ? setPaymentMethods(result.data)
      : notify('warning', 'No hay métodos de pago creados')
  }

  const updatePaymentMethod = async () => {
    setIsCreateButtonDisabled(true)
    let readyToUpdate = true

    if (putForm.values.ledgerAccount == '') {
      readyToUpdate = false
      notify('warning', 'Falta el código de cuenta contable.')
    }
    if (putForm.values.name == '') {
      readyToUpdate = false
      notify('warning', 'Falta detalles de la cuenta.')
    }

    if (putForm.values.paymentMethod == 0) {
      readyToUpdate = false
      notify('warning', 'Falta el tipo de pago.')
    }

    if (putForm.values.prefix == '') {
      readyToUpdate = false
      notify('warning', 'Falta el prefijo de pago.')
    }

    if (putForm.values.voucher == '') {
      readyToUpdate = false
      notify('warning', 'Falta el comprobante.')
    }

    if (readyToUpdate) {
      let result = await UpdateClientPaymentMethodApi(
        tokenData.branchId,
        tokenData.companyId,
        putForm.values.id,
        putForm.values.ledgerAccount,
        putForm.values.name,
        putForm.values.paymentMethod,
        putForm.values.prefix,
        putForm.values.voucher,
      )
      if (result.data == 'Clientpaymentmethod updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await getPaymentMethods(tokenData.companyId)
      } else {
        notify('warning', 'Error al actualizar el método de pago.')
      }
    }
    setIsCreateButtonDisabled(false)
  }

  const [waysToPay, setWaysToPay] = useState([])

  const getWaysToPay = async () => {
    let result = await getWaysToPayApi()
    if (result.data != null) setWaysToPay(result.data)
    else notify('warning', 'No se encontraron tipos de pago')
  }

  const wayToPayNameById = (id) => {
    let way = waysToPay.find((way) => way.id == id)
    return way.waytopay
  }

  const tokenVerified = async () => {
    //CHECK ROLES

    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await getWaysToPay()
    await getPaymentMethods(tokenData.companyId)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <ConfigurationMenus buttonId={6} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
          >
            Nuevo Método de Pago <CirclePlus size={20} color="#FFFFFF" />
          </Button>
          <br></br>
          <br></br>
          <MDBTable small>
            <MDBTableHead>
              <tr style={{ textAlign: 'center' }}>
                <th>Nombre</th>
                <th>Cuenta Contable</th>
                <th>Forma de Pago</th>
                <th>Prefijo de Pago</th>
                <th>Comprobante</th>
                <th>Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {paymentMethods.map((item, index) => {
                return (
                  <tr style={{ textAlign: 'center' }} key={index}>
                    <td>{item.name}</td>
                    <td>{item.ledgerAccount}</td>
                    <td>{wayToPayNameById(item.paymentMethod)}</td>
                    <td>{item.prefix}</td>
                    <td>{item.voucher}</td>
                    <td>
                      <ActionButton
                        description="Editar"
                        backgroundColor={bgColor}
                        onClick={() =>
                          getMethodData(
                            item.id,
                            item.ledgerAccount,
                            item.name,
                            item.paymentMethod,
                            item.prefix,
                            item.voucher,
                          )
                        }
                      >
                        <Edit size={30} color={color} />
                      </ActionButton>
                      {/* <Button
                        style={{
                          float: 'right',
                          backgroundColor: 'transparent',
                          isolation: 'isolate',
                        }}
                        onClick={() => deletePaymentMethod(item.id)}
                      >
                        <Trash size={30} color="red" />
                      </Button> */}
                    </td>
                  </tr>
                )
              })}
            </MDBTableBody>
          </MDBTable>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando Método de Pago
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Código de cuenta contable"
                      placeholder="Código de cuenta contable"
                      value={putForm.values.ledgerAccount}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'ledgerAccount',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Nombre"
                      placeholder="Nombre"
                      value={putForm.values.name}
                      onChange={(event) =>
                        putForm.setFieldValue('name', event.currentTarget.value)
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        PREFIJO DE PAGO
                      </InputLabel>
                      <MuiSelect
                        required
                        label="Medio de Pago"
                        value={putForm.values.prefix}
                        style={{
                          appearance: 'none',
                          border: '0px',
                          background: 'none',
                        }}
                        onChange={(e) =>
                          putForm.setFieldValue('prefix', e.target.value)
                        }
                      >
                        {prefixes.map((way, index) => (
                          <MenuItem key={index} value={way.prefix}>
                            {way.prefix + ' - ' + way.name}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                    <TextField
                      variant="standard"
                      required
                      label="Comprobante"
                      placeholder="Comprobante"
                      value={putForm.values.voucher}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'voucher',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      FORMA DE PAGO
                    </InputLabel>
                    <MuiSelect
                      required
                      label="Medio de Pago"
                      value={putForm.values.paymentMethod}
                      style={{
                        appearance: 'none',
                        border: '0px',
                        background: 'none',
                      }}
                      onChange={(e) =>
                        putForm.setFieldValue('paymentMethod', e.target.value)
                      }
                    >
                      {waysToPay.map((way, index) => (
                        <MenuItem key={index} value={way.id}>
                          {way.waytopay}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updatePaymentMethod()}
                disabled={isCreateButtonDisabled}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Método de Pago
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Código de cuenta contable"
                      placeholder="Código de cuenta contable"
                      value={form.values.ledgerAccount}
                      onChange={(event) =>
                        form.setFieldValue(
                          'ledgerAccount',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Nombre"
                      placeholder="Nombre"
                      value={form.values.name}
                      onChange={(event) =>
                        form.setFieldValue('name', event.currentTarget.value)
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        PREFIJO DE PAGO
                      </InputLabel>
                      <MuiSelect
                        required
                        label="prefijo de Pago"
                        value={form.values.prefix}
                        style={{
                          appearance: 'none',
                          border: '0px',
                          background: 'none',
                        }}
                        onChange={(e) =>
                          form.setFieldValue('prefix', e.target.value)
                        }
                      >
                        {prefixes.map((way, index) => (
                          <MenuItem key={index} value={way.prefix}>
                            {way.prefix + ' - ' + way.name}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                    <TextField
                      variant="standard"
                      required
                      label="Comprobante"
                      placeholder="Comprobante"
                      value={form.values.voucher}
                      onChange={(event) =>
                        form.setFieldValue('voucher', event.currentTarget.value)
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        FORMA DE PAGO
                      </InputLabel>
                      <MuiSelect
                        required
                        label="Medio de Pago"
                        value={form.values.paymentMethod}
                        style={{
                          appearance: 'none',
                          border: '0px',
                          background: 'none',
                        }}
                        onChange={(e) =>
                          form.setFieldValue('paymentMethod', e.target.value)
                        }
                      >
                        {waysToPay.map((way, index) => (
                          <MenuItem key={index} value={way.id}>
                            {way.waytopay}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createPaymentMethod()}
                disabled={isCreateButtonDisabled}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalCreateShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
