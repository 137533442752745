import React, { useState, useEffect, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import useStore from '../../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { CircularProgress, TextField } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { notify, setFixed } from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import FormControl from '@mui/material/FormControl'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { Trash, CircleMinus, CirclePlus, Lock } from 'tabler-icons-react'
import { setDecimal } from '../../../services/utils'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Select as MuiSelect } from '@mui/material'
import SignatureCanvas from 'react-signature-canvas'
import { useReactToPrint } from 'react-to-print'
import SaleTicket from './saleTicket'
import SaleTicketEmail from './saleTicketEmail'
import CustomSelect from '../../custom/customSelect'
import {
  PostClientSaleApi,
  getClientSalesByIdApi,
} from '../../../pages/api/clientsale'
import { getAllClientProductsApi } from '../../../pages/api/clientProducts'
import {
  PostThirdClientBalanceApi,
  PostThirdClientDebtApi,
  getThirdClientsApi,
} from '../../../pages/api/thirdClients'
import { getDriverBusByPlateApi } from '../../../pages/api/thirdClientsDriverBusses'

export default function ProductSale({
  setModalProductsShow,
  resetConstants,
  tankGallons,
}) {
  const { tokenData, isDarkModeActive } = useStore()

  const [grabAt, setGrabAt] = React.useState('')

  //Clients Products
  const [clientProducts, setClientProducts] = useState([])
  const [productsAdded, setProductsAdded] = useState([])
  const [clientProductsOptions, setClientProductsOptions] = React.useState([])

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  //BUS DRIVER AND COMPANY DATA
  const [clientBusByPlate, setClientBusByPlate] = useState([
    {
      driverName: '',
      driverCc: '',
      discount: 0,
      thirdClientCompanyName: '',
      thirdClientsId: '',
      isGeneric: false,
    },
  ])

  //IF BUS PLATE EXITS
  const [ifBusPlateExists, setIfBusPlateExists] = React.useState(false)

  const [total, setTotalValue] = React.useState(0)
  const [gallonTotalNumber, setGallonTotalNumber] = React.useState(0)

  //TOTALS
  const [subTotalProducts, setSubTotalProducts] = React.useState(0)
  const [totals, setTotals] = React.useState('0')
  const [totalsWithDiscount, setTotalsWithDiscount] = React.useState('0')
  const [quantity, setQuantity] = React.useState(1)

  //PAY MODE
  const [payMode, setPayMode] = useState(0)

  //WAY TO PAY
  const [wayToPayMode, setWayToPayMode] = useState(0)

  //SIGNING CANVAS

  const [busPlate, setBusPlate] = useState('')

  //FOR SIGNING PAD
  const [trimmedDataURL, setTrimmedDataURL] = useState(null)
  const [signatureRef, setSignatureRef] = React.useState(null)
  const [signatureState, setSignatureState] = useState(true)

  const clearSignature = () => {
    signatureRef.clear()
    setTrimmedDataURL(null)
    setSignatureState(true)
    signatureRef.on()
  }
  const trimSignature = () => {
    if (signatureRef.isEmpty()) {
      notify('warning', 'Falta firmar la factura, intente de nuevo.')
    } else {
      setSignatureState(false)
      setTrimmedDataURL(signatureRef.getTrimmedCanvas().toDataURL('image/png'))
      signatureRef.off()
    }
  }

  const form = useForm({
    initialValues: {
      galones: '',
      total: '',
    },
  })

  const [invoiceNumber, setInvoiceNumber] = useState(0)

  //---------------------Print functions--------------------

  const [print, setPrint] = useState(false)
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setPrint(true)
    },
    onAfterPrint: () => {
      setPrint(false)
    },
  })

  /**
   *@description Depending on the api result, it will notify the user depending on the api error
   if it's a success, it will notify the user that the sale was successful and will reset the constants
   if its an error, it will notify the user that something went wrong
   * @param {string} saleType sale, products, movility
   * @param {object} data results from the Createsale Post api (even if it fails)
   */
  const successCreation = async (data) => {
    if (data.data == 'Error Updating Units' && data.lastInsertedId == 0) {
      notify('error', 'Algo salio mal')
    } else if (data.data == 'Signature image failed') {
      notify('error', 'Error al crear (code 428)')
    } else {
      try {
        notify('success', 'Factura Generada')
        setClientBusByPlate([
          {
            driverName: '',
            driverCc: '',
            discount: 0,
            thirdClientCompanyName: '',
            thirdClientsId: '',
            isGeneric: false,
          },
        ])
        handlePrint()
        restartTotals()
        resetConstants()
        setModalProductsShow(false)
      } catch (error) {
        return error
      }
    }
    setIsCreateButtonDisabled(false)
  }

  /**
   * @description Restart all the totals and constant values needed in sale
   */
  const restartTotals = () => {
    setBusPlate('')
    setGallonTotalNumber(0)
    setSubTotalProducts(0)
    setProductsAdded([])
    setQuantity(1)
    setTotalValue(0)
    setTotals('0')
    setPayMode(0)
    setWayToPayMode(0)
    setSignatureState(true)
  }

  const [transferCode, setTransferCode] = useState('')

  //--------------------Sale info detallada---------------

  const [saleInfo, setSaleInfo] = useState([])

  /**
   * @description get the sale info by the sale id
   * @param {number} saleId
   */
  const showSale = async (saleId) => {
    let result = await getClientSalesByIdApi(saleId)
    if (result.data != null) {
      setSaleInfo(result.data)
    } else {
      notify('warning', 'Error, no se encuentra la venta asociada.')
    }
  }

  /**
   * @description set the credit debt of the third client
   * @param {number} thirdClientId
   * @param {number} debt
   */
  const setCreditDebt = async (thirdClientId, debt) => {
    let result = await PostThirdClientDebtApi(thirdClientId, debt)
    if (result.data != null) notify('success', 'Deuda del cliente actualizada.')
    else notify('error', 'Error al crear la deuda del cliente.')
  }

  /**
   * @description set the new balance of the third client
   * @param {number} thirdClientId
   * @param {number} balance
   */
  const setBalance = async (thirdClientId, balance) => {
    let result = await PostThirdClientBalanceApi(thirdClientId, balance)
    if (result.data != null) notify('success', 'Saldo del cliente actualizado.')
    else notify('error', 'Error al actualizar el saldo del cliente.')
  }

  /**
   * @description create the sale of the products only with the data from the form
   *  it first checks if the data is correct and complete, then it creates the sale
   */
  const createSaleProductsOnly = async () => {
    setIsCreateButtonDisabled(true)
    let readyForSale = true
    if (payMode == 0) {
      notify('warning', 'Por favor escoge un modo de pago')
      readyForSale = false
    }
    if (wayToPayMode == 0) {
      notify('warning', 'Por favor escoge una forma de pago')
      readyForSale = false
    } else if (
      payMode == 1 &&
      wayToPayMode != 1 &&
      wayToPayMode != 5 &&
      transferCode == ''
    ) {
      notify('warning', 'Por favor añada el código de transferencia.')
      readyForSale = false
    } else {
      //Comprobar código de transferencia
    }

    if (totals == '0,00 ' || totals == '0') {
      notify('warning', 'No has añadido ningún producto.')
      readyForSale = false
    }

    if (payMode == 2) {
      let creditAvailable = parseFloat(
        thirdClientInfo.credit - thirdClientInfo.debt,
      )

      let balanceAvailable = parseFloat(thirdClientInfo.balance)
      if (wayToPayMode == 5) {
        if (creditAvailable < parseFloat(subTotalProducts)) {
          notify(
            'error',
            'Crédito insuficiente, seleccione otro método de pago.',
          )
          readyForSale = false
        }
      } else {
        if (balanceAvailable < parseFloat(subTotalProducts)) {
          notify('error', 'Saldo insuficiente, seleccione otro método de pago.')
          readyForSale = false
        }
      }
    }

    if (readyForSale) {
      let galonsLeft = parseInt(tankGallons) - parseInt(form.values.galones)
      let gallonsSold = form.values.galones

      if (isNaN(galonsLeft)) {
        galonsLeft = 0
        gallonsSold = '0'
      }

      let saleData = {
        companyId: tokenData.companyId,
        branchId: tokenData.branchId,
        busPlate: busPlate.toUpperCase(),
        driverName: clientBusByPlate[0].driverName,
        driverCc: clientBusByPlate[0].driverCc,
        discount: parseInt(clientBusByPlate[0].discount),
        thirdClientCompanyName: clientBusByPlate[0].thirdClientCompanyName,
        saleType: 1,
        paymentMethod: parseInt(payMode),
        wayToPay: wayToPayMode == 6 ? 5 : parseInt(wayToPayMode),
        clientHoseId: 0,
        clientUserId: parseInt(tokenData.clientId),
        actualGallons: tankGallons,
        gallonsLeft: galonsLeft.toString(),
        gallonsSold: gallonsSold,
        grabAt: grabAt,
        total: total.toString(),
        totalWithDiscount: '0',
        products: JSON.stringify(productsAdded),
        signature: trimmedDataURL,
        transferCode: transferCode,
        totalProducts: subTotalProducts.toFixed(2),
        fuelType: 10,
        thirdClientId: clientBusByPlate[0].thirdClientsId,
        clientUserName: tokenData.clientName,
      }
      let result = await PostClientSaleApi(saleData)
      if (result.data === 'ClientSale created') {
        setInvoiceNumber(result.invoiceNumber)
        showSale(result.lastInsertedId)
        successCreation(result)
        let debt = parseFloat(
          thirdClientInfo.debt + parseFloat(subTotalProducts),
        )
        let balance = parseFloat(
          thirdClientInfo.balance - parseFloat(subTotalProducts),
        )
        if (wayToPayMode == 5) setCreditDebt(thirdClientInfo.id, debt)
        if (wayToPayMode == 6) setBalance(thirdClientInfo.id, balance)
      } else {
        notify('error', 'Error al crear la venta de canastilla.')
      }
    } else {
      setIsCreateButtonDisabled(false)
    }
  }

  /**
   * @description get the client products by the company id
   * @param {number} companyId
   */
  const getClientproducts = async (companyId) => {
    let result = await getAllClientProductsApi(companyId)
    if (result.data != null) {
      let productsSelect = await result.data.map((x) => {
        let name = x.units > 0 ? x.name : x.name + ' (AGOTADO)'
        return { value: x.code, label: name }
      })
      setClientProductsOptions(productsSelect)
      setClientProducts(result.data)
    } else {
      setClientProductsOptions([])
      setClientProducts([])
      notify('warning', 'No se encontraron productos.')
    }
  }

  /**
   * @description Adds a product to the productsAdded array if it is not already added
   * @param {object} product The product object with the following structure: { value: ProductCode, label: ProductName }
   */
  const addProduct = (product) => {
    let findProducts = clientProducts.filter(
      (element) => element.code == product.value,
    )

    if (findProducts[0].units < quantity) {
      notify('warning', 'La cantidad del producto es mayor a la existente.')
    } else {
      let ifProductIsAdded = productsAdded.filter(
        (element) => element.id == findProducts[0].id,
      )

      if (ifProductIsAdded.length > 0) {
        notify('warning', 'El producto ya se encuentra en el carrito.')
      } else {
        let productToAdd = {
          branchId: findProducts[0].branchId,
          clientUsersId: findProducts[0].clientUsersId,
          companyId: findProducts[0].companyId,
          createdAt: findProducts[0].createdAt,
          deleted: findProducts[0].deleted,
          id: findProducts[0].id,
          code: findProducts[0].code,
          store: findProducts[0].store,
          costCenter: clientBusByPlate[0].costCenter,
          name: findProducts[0].name,
          units: findProducts[0].units,
          price: findProducts[0].price,
          unitOfMeasurement: findProducts[0].unitOfMeasurement,
          updatedAt: findProducts[0].updatedAt,
          vat: findProducts[0].vat,
          vatPrice: findProducts[0].vatPrice * quantity,
          quantity: quantity,
          busPlate: busPlate.toUpperCase(),
          discount: clientBusByPlate[0].discount,
        }
        setProductsAdded((productsAdded) => productsAdded.concat(productToAdd))
      }
    }
  }

  /**
   * @description deletes a product from the productsAdded array by the index in the array
   * @param {number} index
   */
  const deleteProduct = (index) => {
    let productToDelete = [...productsAdded]
    productToDelete.splice(index, 1)
    setProductsAdded(productToDelete)
  }

  /**
   * @description updates the quantity of a product in the productsAdded array by the productId
   * @param {number} productId
   * @param {number} quantity
   */
  const updateProductAddedQuantity = (productId, quantity) => {
    if (quantity <= 0) {
      notify('warning', 'La cantidad del producto no puede ser menor a 1.')
    } else {
      let findProducts = clientProducts.filter(
        (element) => element.id == productId,
      )

      if (quantity > findProducts[0].units) {
        notify('warning', 'La cantidad del producto es mayor a la existente.')
      } else {
        let product = productsAdded

        for (let i = 0; i < product.length; i++) {
          if (product[i].id == productId) {
            if (quantity > product[i].quantity) {
              product[i].quantity = quantity
              product[i].vatPrice = product[i].price * quantity
            } else {
              product[i].quantity = quantity
              product[i].vatPrice = product[i].price * quantity
            }
          }
        }
        setProductsAdded([...product])
      }
    }
  }

  /**
   * @description Search the client bus by the bus plate, if the bus plate is valid, set the client bus and the third client info.
   * but first it checks if the busplate is 6 characters long and if it has 3 letters and 3 numbers
   * @param {string} busPlate
   */
  const searchClientBus = async (busPlate) => {
    setBusPlate(busPlate.toUpperCase())
    if (
      busPlate.length == 6 &&
      String(busPlate)
        .toLowerCase()
        .match(/^[a-zA-Z]{3}\d{3}$/)
    ) {
      if (busPlate.length >= 6) {
        form.setFieldValue('galones', '')
        setTotalValue('0')
        setTotals('0')
        setProductsAdded([])

        let result = await getDriverBusByPlateApi(tokenData.companyId, busPlate)
        if (result.data != null) {
          setIfBusPlateExists(true)
          setClientBusByPlate(result.data)
          getThirdClientInfo(result.data[0].thirdClientCompanyName)
        } else notify('warning', 'No se encontró el conductor.')
      }

      if (busPlate.length < 6) {
        resetConstants()
        setIfBusPlateExists(false)
        setClientBusByPlate([
          {
            driverName: '',
            driverCc: '',
            discount: 0,
            thirdClientCompanyName: '',
            thirdClientsId: '',
            isGeneric: false,
          },
        ])
        setPayMode(1)
        form.setFieldValue('galones', '')
        setTotalValue('0')
        setTotals('0')
        setProductsAdded([])
      }
    } else {
      if (busPlate.length == 6)
        notify('warning', 'Las placas deben contener 3 letras y 3 dígitos.')
    }
  }

  const getISODate = (date) => {
    return date.toLocaleDateString('en-ca')
  }

  const [thirdClientInfo, setThirdClientInfo] = useState({
    nit: '000000000',
    thirdClientCompanyName: '',
  })

  /**
   * @description get the third client info by the third client id
   * @param {number} thirdClientId
   */
  const getThirdClientInfo = async (thirdClientCompanyName) => {
    //Mejor obtenerlo con thirdclientID
    let result = await getThirdClientsApi()
    if (result.data != null) {
      let thirdClient = result.data.filter((x) => {
        return x.thirdClientCompanyName == thirdClientCompanyName
      })
      setThirdClientInfo(thirdClient[0])
    }
  }

  const tokenVerified = async () => {
    let date = new Date()
    let options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }
    setGrabAt(
      getISODate(new Date()) + ' ' + date.toLocaleTimeString('en-us', options),
    )

    await Promise.all([
      getClientproducts(tokenData.companyId, tokenData.branchId),
    ])
  }

  const wayToPayOptions =
    payMode == 2
      ? [
          { value: 5, text: 'CRÉDITO' },
          { value: 6, text: 'SALDO' },
        ]
      : [
          { value: 1, text: 'EFECTIVO' },
          { value: 2, text: 'TARJETA DE DÉBITO' },
          { value: 3, text: 'TARJETA DE CRÉDITO' },
          { value: 4, text: 'TRANSFERENCIA BANCARIA' },
        ]

  useEffect(() => {
    tokenVerified()
  }, [])

  /**
   * @description set the total price of the products and the gallons, even the sale entirely
   * each time the variables change
   */
  useEffect(() => {
    let subTotalProducts = 0
    productsAdded.map((x) => {
      subTotalProducts += x.vatPrice
    }, [])
    setSubTotalProducts(subTotalProducts)

    setTotals(setFixed(subTotalProducts + gallonTotalNumber))

    let total = gallonTotalNumber
    let totalDiscount = (clientBusByPlate[0].discount * total) / 100
    setTotalsWithDiscount(setFixed(total - totalDiscount))

    setProductsAdded(productsAdded)

    if (quantity <= 1) {
      setQuantity(1)
    }

    if (productsAdded.length <= 0) {
      subTotalProducts = 0
    }

    if (form.values.galones == '' && productsAdded.length <= 0) {
      setTotals('0')
    }
  }, [
    productsAdded,
    gallonTotalNumber,
    total,
    totals,
    quantity,
    totalsWithDiscount,
  ])

  return (
    <Container
      style={{
        border: '1px solid gray',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <div style={{ display: 'none' }}>
        {saleInfo.length > 0 ? <SaleTicketEmail sales={saleInfo} /> : null}
        <details open={print}>
          <summary>Colilla</summary>
          <div ref={componentRef}>
            <SaleTicket
              productsadded={productsAdded}
              totals={subTotalProducts.toFixed(2)}
              sellername={tokenData.clientName}
              clientbusbyplate={clientBusByPlate}
              busplate={busPlate}
              trimmeddataurl={trimmedDataURL}
              saleType={1}
              invoicenumber={invoiceNumber}
            />
          </div>
        </details>
      </div>
      <Group direction="column" grow>
        <h4>Venta de Productos</h4>
        <Group grow mb="md" mt="md">
          <TextField
            variant="standard"
            required
            label="PLACA"
            placeholder="PLACA"
            onChange={(event) => searchClientBus(event.currentTarget.value)}
          />

          <TextField
            variant="standard"
            required
            label="EMPRESA"
            placeholder="EMPRESA"
            value={thirdClientInfo.thirdClientCompanyName}
            disabled={true}
          />

          <TextField
            variant="standard"
            required
            label="NIT"
            placeholder="NIT"
            type="number"
            disabled={true}
            value={thirdClientInfo.nit}
          />
        </Group>
        {ifBusPlateExists ? (
          <>
            <Group grow mb="md" mt="md">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  MÉTODO DE PAGO
                </InputLabel>
                <MuiSelect
                  required
                  label="MÉTODO DE PAGO"
                  onChange={(e) => setPayMode(e.target.value)}
                  defaultValue={payMode}
                >
                  <MenuItem value={2}>CRÉDITO/SALDO</MenuItem>
                  <MenuItem value={1}>CONTADO</MenuItem>
                </MuiSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  FORMA DE PAGO
                </InputLabel>
                <MuiSelect
                  required
                  label="FORMA DE PAGO"
                  onChange={(e) => setWayToPayMode(e.target.value)}
                  value={wayToPayMode}
                >
                  <MenuItem value={0}></MenuItem>
                  {wayToPayOptions.map((wayToPayMode, index) => {
                    return (
                      <MenuItem key={index} value={wayToPayMode.value}>
                        {wayToPayMode.text}
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
              </FormControl>
              {payMode == 1 &&
              wayToPayMode != 1 &&
              wayToPayMode != 0 &&
              wayToPayMode != 5 ? (
                <TextField
                  variant="standard"
                  required
                  label="CÓDIGO TRANSFERENCIA"
                  placeholder="CÓDIGO TRANSFERENCIA"
                  // value={form.values.galones}
                  onChange={(event) =>
                    setTransferCode(event.currentTarget.value)
                  }
                />
              ) : null}
            </Group>
            <Group mb="md" mt="md">
              <div>
                <CustomSelect
                  title="Buscar productos"
                  placeholder="BUSCAR PRODUCTO"
                  onChange={(product) => addProduct(product)}
                  options={clientProductsOptions}
                  theme={isDarkModeActive ? 'black' : 'white'}
                />
              </div>
            </Group>

            {productsAdded.length != 0 ? (
              <>
                <MDBTable small>
                  <MDBTableHead>
                    <tr>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Acciones</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {productsAdded.map((clientProducts, index) => {
                      return (
                        <tr key={index}>
                          <td>{clientProducts.name}</td>
                          <td>
                            <div>
                              <button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                onClick={() =>
                                  updateProductAddedQuantity(
                                    clientProducts.id,
                                    clientProducts.quantity - 1,
                                  )
                                }
                              >
                                <CircleMinus size={30} color="black" />
                              </button>
                              <span
                                style={{
                                  fontSize: 24,
                                  marginTop: '40px',
                                }}
                              >
                                {' '}
                                {clientProducts.quantity}{' '}
                              </span>
                              <button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                onClick={() =>
                                  updateProductAddedQuantity(
                                    clientProducts.id,
                                    clientProducts.quantity + 1,
                                  )
                                }
                              >
                                <CirclePlus size={30} color="black" />
                              </button>
                            </div>
                          </td>
                          <td>${setDecimal(clientProducts.vatPrice)}</td>
                          <td>
                            <button
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                              }}
                              onClick={() => deleteProduct(index)}
                            >
                              <Trash size={30} color="red" />
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td>Subtotal Productos:</td>
                      <td></td>
                      <td>${setDecimal(subTotalProducts)}</td>
                      <td></td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
                <Text style={{ fontSize: 25, display: 'flex' }}>
                  Total : ${setDecimal(totals)}
                </Text>
              </>
            ) : null}
          </>
        ) : null}
      </Group>

      <br></br>
      {ifBusPlateExists ? (
        <div style={{ textAlign: 'center' }}>
          <Text>Firma:</Text>
          <Container
            style={{ border: '1px solid black', width: 'fit-content' }}
          >
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 440,
                height: 200,
                border: '1px solid black',
              }}
              ref={(ref) => {
                setSignatureRef(ref)
              }}
            />
            {!signatureState ? <Lock /> : null}
          </Container>

          <Button
            style={{
              backgroundColor: 'green',
              borderRadius: '100px',
              margin: '10px',
              isolation: 'isolate',
            }}
            onClick={() => clearSignature()}
          >
            Limpiar
          </Button>
          <Button
            style={{
              backgroundColor: 'green',
              borderRadius: '100px',
              margin: '10px',
              isolation: 'isolate',
            }}
            onClick={() => trimSignature()}
            disabled={!signatureState}
          >
            Firmar
          </Button>

          {/* {trimmedDataURL ? <img src={trimmedDataURL} /> : null} */}
        </div>
      ) : null}
      <hr></hr>
      <Button
        style={{
          backgroundColor: 'green',
          borderRadius: '100px',
          marginRight: '10px',
          isolation: 'isolate',
        }}
        onClick={() => createSaleProductsOnly()}
        disabled={!ifBusPlateExists || isCreateButtonDisabled}
      >
        {isCreateButtonDisabled ? (
          <CircularProgress size={15} color="inherit" />
        ) : null}{' '}
        Facturar
      </Button>
      <Button
        style={{
          backgroundColor: 'transparent',
          borderRadius: '100px',
          color: 'black',
        }}
        onClick={() => {
          setModalProductsShow(false)
          resetConstants()
        }}
      >
        Volver
      </Button>
    </Container>
  )
}
