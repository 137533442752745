import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import Button from 'react-bootstrap/Button'
import ActionButton from '../../components/custom/actionButton'
import { Text } from '@mantine/core'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import LoadingScreen from '../../components/main/loadingScreen'
import {
  Edit,
  Trash,
  Key,
  CirclePlus,
  QuestionMark,
  User,
  Clock,
  History,
  Eye,
  ReportMoney,
} from 'tabler-icons-react'
import { isEmpty } from '../../services/utils'
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import ReactJoyride from 'react-joyride'
import { getAllClientBranchesApi } from '../api/branches'
import {
  DeleteClientUsersApi,
  UpdateClientUserActiveApi,
  getClientUsersApi,
} from '../api/clientUsers'
import { getScheduleApi } from '../api/schedule'
import EditClientUserSchedule from '../../components/client/clientUsers/editClientUserSchedule'
import dayjs from 'dayjs'
import EditCredentialsModal from '../../components/client/clientUsers/modals/editCredentialsModal'
import { ShiftsHistoricalModal } from '../../components/client/clientUsers/modals/shiftsHistoricalModal'
import CreateClientUser from '../../components/client/clientUsers/createClientUser'
import PayrollMenus from '../../components/client/shortcutMenus/payrollMenus'
import Toggle from 'react-toggle'
import ClientUserDetails from '../../components/client/clientUsers/clientUserDetails'
import ClientUserNovelties from '../../components/client/clientUsers/clientUserNovelties'

export default function Employees() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()
  //Clients Branches
  const [branches, setBranches] = useState([])

  //Clients Branch id
  const [branchId, setBranchId] = useState(0)

  //Clients search Branch By Id
  const [searchBranchById, setSearchBranchById] = useState(0)

  const [modalCreateUserShow, setModalCreateUserShow] = React.useState(false)
  const handleOnCloseCreate = () => setModalCreateUserShow(false)

  const [modalPutPasswordShow, setModalPutPasswordShow] = React.useState(false)
  const handleOnCloseCredentialsEdit = () => setModalPutPasswordShow(false)

  const [modalUserDetailsShow, setModalUserDetailsShow] = React.useState(false)
  const handleUserDetails = () => setModalUserDetailsShow(false)

  const [modalUserNoveltiesShow, setModalUserNoveltiesShow] =
    React.useState(false)
  const handleUserNovelties = () => setModalUserNoveltiesShow(false)

  const [modalShiftHistoricalShow, setModalShiftHistoricalShow] =
    React.useState(false)
  const handleOnCloseShiftHistorical = () => setModalShiftHistoricalShow(false)

  const [shiftClientUser, setShiftClientUser] = useState({})

  const [clientUsers, setClientusers] = useState([])

  const handleShiftHistorical = (clientUser) => {
    setShiftClientUser(clientUser)
    setModalShiftHistoricalShow(true)
  }

  const getClientUsers = async (companyId, branchId) => {
    let result = await getClientUsersApi(companyId, branchId)
    if (result.data != null) setClientusers(result.data)
    else {
      notify('warning', 'No hay empleados en esta sucursal')
      setClientusers([])
    }
  }

  const [clientUserDetails, setClientUserDetails] = useState({})
  const setDataToDetails = (clientUser) => {
    setClientUserDetails(clientUser)
    setModalUserDetailsShow(true)
  }

  const [clientUserNovelties, setClientUserNovelties] = useState({})
  const setDataToNovelties = (clientUser) => {
    setClientUserNovelties(clientUser)
    setModalUserNoveltiesShow(true)
  }

  const [clientUserToUpdatePassword, setClientUserToUpdatePassword] = useState(
    {},
  )
  const setDatasToUpdatePassword = async (client) => {
    setClientUserToUpdatePassword(client)
    setModalPutPasswordShow(true)
  }

  const getClientUserById = (clientId) => {
    let result = clientUsers.find((clientUser) => clientUser.id == clientId)
    if (result != undefined) return result
    return { fullName: 'Cliente' }
  }

  //------------------------Schedules------------------------
  const [schedules, setSchedules] = useState([])
  const [currentClientUserId, setCurrentClientUserId] = useState(0)
  const [modalUpdateScheduleShow, setModalUpdateScheduleShow] = useState(false)

  const setScheduleDataToUpdate = (clientUserId) => {
    setCurrentClientUserId(clientUserId)
    setModalUpdateScheduleShow(true)
  }

  const getSchedules = async (companyId, branchId) => {
    let result = await getScheduleApi(companyId, branchId)
    if (result.data != null) setSchedules(result.data)
    else notify('warning', 'No hay horarios registrados')
  }

  const getWorkDaysByClientUserId = (clientUserId) => {
    let result = schedules.find(
      (schedule) => schedule.clientUserId == clientUserId,
    )
    if (result != undefined) {
      let scheduleList = JSON.parse(result.schedules)
      return scheduleList.workDays
    }
    return [
      { active: false, name: 'domingo' },
      { active: false, name: 'lunes' },
      { active: false, name: 'martes' },
      { active: false, name: 'miércoles' },
      { active: false, name: 'jueves' },
      { active: false, name: 'viernes' },
      { active: false, name: 'sábado' },
    ]
  }

  const shortDays = ['D', 'L', 'Ma', 'Mi', 'J', 'V', 'S']

  const checkIsShiftCurrentlyActive = (clientUserId) => {
    let result = schedules.find(
      (schedule) => schedule.clientUserId == clientUserId,
    )
    if (result != undefined) {
      let scheduleList = JSON.parse(result.schedules)
      let today = new Date()
      return scheduleList.events.some((event) => {
        if (dayjs(event.start).toDate().getDay() == today.getDay()) {
          // check same day of the week
          let start = dayjs(event.start).toDate()
          let end = dayjs(event.end).toDate()
          let currentHour = today.getHours() * 60 + today.getMinutes()
          let startHour = start.getHours() * 60 + start.getMinutes()
          let endHour = end.getHours() * 60 + end.getMinutes()

          if (currentHour >= startHour && currentHour <= endHour) {
            // check if current hour is between start and end of the event
            return true
          }
        }
        return false
      })
    }
  }

  const getEventsByClientUserId = (clientUserId) => {
    let result = schedules.find(
      (schedule) => schedule.clientUserId == clientUserId,
    )
    if (result != undefined) {
      let scheduleList = JSON.parse(result.schedules)
      return scheduleList.events
    }
    return []
  }

  //----------------------------------------------------------

  const tokenVerified = async () => {
    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    setBranchId(tokenData.branchId)
    await getClientUsers(tokenData.companyId, tokenData.branchId)
    await getSchedules(tokenData.companyId, tokenData.branchId)
    await getClientBranches(tokenData.companyId)
    setSearchBranchById(tokenData.branchId)
  }

  const setClientUserActive = async (clientUserId, active) => {
    let result = await UpdateClientUserActiveApi(clientUserId, active)
    if (result.data == 'Client Activated') {
      notify('success', 'Estado actualizado')
      await getClientUsers(tokenData.companyId, tokenData.branchId)
    } else {
      notify('warning', 'Error al actualizar estado del empleado')
    }
  }

  const deleteUser = (clientUserId, isSuperUser) => {
    if (isSuperUser) {
      notify('warning', 'No puedes eliminar a un super usuario')
    } else {
      deleteClientUser(clientUserId)
    }
  }

  const deleteClientUser = async (clientUserId) => {
    Swal.fire({
      title: '¿Eliminar el empleado seleccionado?',
      text: 'Nota: Esta acción es irreversible',
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (response) => {
      if (response.isConfirmed) {
        let result = await DeleteClientUsersApi(clientUserId)
        if (result.data == 'ClientUsers deleted') {
          notify('success', 'empleado Eliminado')
          await getClientUsers(tokenData.companyId, tokenData.branchId)
        } else {
          notify('warning', 'Error al eliminar')
        }
      }
    })
  }

  const getClientBranches = async (companyId) => {
    let result = await getAllClientBranchesApi(companyId)
    if (result.data != null) setBranches(result.data)
    else notify('warning', 'No hay sucursales registradas')
  }

  const handleSearchBranchById = async (branchId) => {
    setSearchBranchById(branchId)
    await getClientUsers(tokenData.companyId, branchId)
  }

  const handleOpenCreateUserModal = () => {
    if (searchBranchById <= 0) {
      notify('warning', 'Por favor selecciona una sucursal')
    } else setModalCreateUserShow(true)
  }

  //---------------------- Joyride -----------------------
  const [runJoyride, setRunJoyride] = useState(false)
  const [joyrideSteps] = useState([
    {
      content:
        'Este es el módulo de empleados, en el cual podrás editar los distintos empleados de cada sucursal.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      placement: 'center',
      target: 'body',
      title: 'empleados',
    },
    {
      content:
        'Aquí podrás elegir la sucursal a la cual deseas modificar sus empleados y personal encargado.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      spotlightPadding: 20,
      target: '.branchSelect',
      title: 'Selección de sucursal',
    },
    {
      content:
        'Aquí podrás crear un nuevo empleado, donde llenarás la información necesaria para añadirlo a la lista de empleados, dependiendo de la sucursal.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      spotlightPadding: 20,
      target: '.newUser_button',
      title: 'Creación de empleado',
    },
    {
      content:
        'Aquí puedes ver el listado de empleados con su información de turno, como son su estado, si está activo o no y su información personal.',
      placement: 'bottom',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.usersTable',
      title: 'Información de los empleados',
    },

    {
      content: (
        <div>
          Aquí tienes las opciones para el empleado, como son modificar su
          contraseña con <Key /> sus datos personales y de estado con <Edit /> y
          eliminarlo si se requiere con <Trash color="red" />.
        </div>
      ),
      target: '.userActions',
      title: 'Acciones para el cliente',
      placement: 'top',
      locale: {
        skip: 'Saltar',
        next: 'Siguiente',
        back: 'Atrás',
        last: 'Terminar',
      },
    },
  ])

  const handleJoyrideCallback = (CallBackProps) => {
    const status = CallBackProps.status
    if (status == 'ready') {
      setRunJoyride(false)
    }
  }
  //-----------tabs-------------------

  const [currentTab, setCurrentTab] = useState('tab1')

  const handleTabs = (value) => {
    if (value === currentTab) {
      return
    }
    setCurrentTab(value)
  }

  //----------------------------------

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <Button
            onClick={() => setRunJoyride(true)}
            style={{ float: 'right', background: 'gray', border: 'none' }}
          >
            <QuestionMark /> Ayuda
          </Button>
          <ReactJoyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={runJoyride}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={joyrideSteps}
            styles={{
              options: {
                zIndex: 20,
                primaryColor: 'green',
              },
            }}
          />
          <PayrollMenus buttonId={0} />
          <br></br>
          {modalUpdateScheduleShow ? (
            <EditClientUserSchedule
              currentBranchId={branchId}
              getClientUserById={getClientUserById}
              currentClientUserId={currentClientUserId}
              setModalUpdateScheduleShow={setModalUpdateScheduleShow}
              getSchedules={getSchedules}
              schedules={schedules}
            />
          ) : modalCreateUserShow ? (
            <CreateClientUser
              onClose={handleOnCloseCreate}
              branches={branches}
              getClientUsers={getClientUsers}
            />
          ) : modalUserDetailsShow ? (
            <ClientUserDetails
              onClose={handleUserDetails}
              branches={branches}
              getClientUsers={getClientUsers}
              clientUserDetails={clientUserDetails}
            />
          ) : modalUserNoveltiesShow ? (
            <ClientUserNovelties
              onClose={handleUserNovelties}
              getClientUsers={getClientUsers}
              clientUserDetails={clientUserNovelties}
            />
          ) : (
            <div>
              <FormControl className="branchSelect" variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Sucursal
                </InputLabel>
                <MuiSelect
                  label="Sucursal"
                  onChange={(e) => handleSearchBranchById(e.target.value)}
                  value={searchBranchById}
                >
                  {branches.map((branch, index) => {
                    return (
                      <MenuItem value={branch.id} key={index}>
                        {branch.name}
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
              </FormControl>
              <Button
                variant="primary"
                className="newUser_button"
                style={{
                  backgroundColor: 'green',
                  float: 'right',
                  marginLeft: 5,
                  marginTop: 50,
                  isolation: 'isolate',
                  height: 40,
                }}
                onClick={() => handleOpenCreateUserModal()}
              >
                Nuevo empleado <CirclePlus size={20} color="#FFFFFF" />
              </Button>
              <MDBTabs>
                <MDBTabsItem>
                  <MDBTabsLink
                    active={currentTab === 'tab1'}
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => handleTabs('tab1')}
                  >
                    <User size={20} /> Información
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink
                    active={currentTab === 'tab2'}
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => handleTabs('tab2')}
                  >
                    <Clock size={20} /> Horarios
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
              <MDBTabsContent>
                <MDBTabsPane show={currentTab === 'tab1'}>
                  <br></br>
                  <MDBTable small className="usersTable">
                    <MDBTableHead>
                      <tr style={{ textAlign: 'center' }}>
                        <th>Estado</th>
                        <th>Nombre</th>
                        <th>CC</th>
                        <th>Correo</th>
                        <th>Cargo</th>
                        <th>Fecha de creación</th>
                        <th className="userActions">Acciones</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {clientUsers.map((clientUser, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                {clientUser.active ? 'Activo' : 'Inactivo'}
                                <Toggle
                                  disabled={clientUser.isSuperUser}
                                  checked={clientUser.active}
                                  onChange={() => {
                                    setClientUserActive(
                                      clientUser.id,
                                      !clientUser.active,
                                    )
                                  }}
                                />
                              </div>
                            </td>
                            <td>{clientUser.fullName}</td>
                            <td>{clientUser.userId}</td>
                            <td>{clientUser.email}</td>
                            <td>{clientUser.position}</td>
                            <td>{clientUser.createdAt}</td>
                            <td
                              style={{
                                width: '300px',
                                margin: 'auto',
                                alignItems: 'center',
                              }}
                            >
                              <ActionButton
                                description="Detalles"
                                onClick={() => setDataToDetails(clientUser)}
                              >
                                <Eye size={25} color={color} />
                              </ActionButton>
                              <ActionButton
                                description="Novedades"
                                backgroundColor={bgColor}
                                onClick={() => {
                                  setDataToNovelties(clientUser)
                                }}
                              >
                                <ReportMoney size={25} color={color} />
                              </ActionButton>
                              <ActionButton
                                description="Credenciales"
                                onClick={() =>
                                  setDatasToUpdatePassword(clientUser)
                                }
                              >
                                <Key
                                  size={25}
                                  color="black"
                                  style={{
                                    isolation: 'isolate',
                                  }}
                                />
                              </ActionButton>
                              <ActionButton
                                description="Eliminar"
                                backgroundColor={bgColor}
                                onClick={() =>
                                  deleteUser(
                                    clientUser.id,
                                    clientUser.isSuperUser,
                                  )
                                }
                              >
                                <Trash
                                  size={25}
                                  color="red"
                                  style={{
                                    isolation: 'isolate',
                                  }}
                                />
                              </ActionButton>
                            </td>
                          </tr>
                        )
                      })}
                    </MDBTableBody>
                  </MDBTable>
                </MDBTabsPane>
                <MDBTabsPane show={currentTab === 'tab2'}>
                  <br></br>
                  <MDBTable small className="usersTable">
                    <MDBTableHead>
                      <tr style={{ textAlign: 'center' }}>
                        <th>Nombre</th>
                        <th>Horario resumido</th>
                        <th>Estado</th>
                        <th className="userActions">Acciones</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {clientUsers.map((clientUser, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <td>{getClientUserById(clientUser.id).fullName}</td>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                {getWorkDaysByClientUserId(clientUser.id).map(
                                  (workDay, workDayIndex) => {
                                    return (
                                      <div
                                        key={workDayIndex}
                                        style={{ textAlign: 'center' }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: workDay.active
                                              ? workDayIndex ==
                                                new Date().getDay()
                                                ? 'green'
                                                : '#64DD17'
                                              : 'gray',
                                            margin: 1,
                                            borderRadius: 3,
                                            color: 'white',
                                            isolation: 'isolate',
                                            padding: 5,
                                          }}
                                        >
                                          {shortDays[workDayIndex]}

                                          {getEventsByClientUserId(
                                            clientUser.id,
                                          ).map((event, eventIndex) => {
                                            if (
                                              workDay.active &&
                                              event.id == workDayIndex
                                            ) {
                                              return (
                                                <div key={eventIndex}>
                                                  <Text
                                                    style={{
                                                      fontSize: '12px',
                                                    }}
                                                  >
                                                    {dayjs(event.start).format(
                                                      'hh:mm A',
                                                    )}
                                                  </Text>
                                                  <Text
                                                    style={{
                                                      fontSize: '12px',
                                                    }}
                                                  >
                                                    {dayjs(event.end).format(
                                                      'hh:mm A',
                                                    )}
                                                  </Text>
                                                </div>
                                              )
                                            }
                                          })}
                                        </div>
                                      </div>
                                    )
                                  },
                                )}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                {checkIsShiftCurrentlyActive(clientUser.id) ? (
                                  <div
                                    style={{
                                      backgroundColor: 'green',
                                      margin: 1,
                                      borderRadius: 3,
                                      color: 'white',
                                      padding: 5,
                                      textAlign: 'center',
                                      width: 'fit-content',
                                      isolation: 'isolate',
                                    }}
                                  >
                                    Trabajando
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: 'gray',
                                      margin: 1,
                                      borderRadius: 3,
                                      color: 'white',
                                      padding: 5,
                                      textAlign: 'center',
                                      width: 'fit-content',
                                      isolation: 'isolate',
                                    }}
                                  >
                                    Fuera de turno
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  style={{
                                    float: 'right',
                                    backgroundColor: bgColor,
                                    margin: '5px',
                                    padding: '10px',
                                  }}
                                  onClick={() => {
                                    setScheduleDataToUpdate(clientUser.id)
                                  }}
                                >
                                  <Edit size={25} color={color} />
                                  <Text
                                    style={{
                                      fontSize: '12px',
                                      color: color,
                                    }}
                                  >
                                    Editar
                                  </Text>
                                </Button>
                                <Button
                                  style={{
                                    float: 'right',
                                    backgroundColor: bgColor,
                                    margin: '5px',
                                    padding: '10px',
                                  }}
                                  onClick={() => {
                                    handleShiftHistorical(clientUser)
                                  }}
                                >
                                  <History size={25} color={color} />
                                  <Text
                                    style={{
                                      fontSize: '12px',
                                      color: color,
                                    }}
                                  >
                                    Turnos
                                  </Text>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </MDBTableBody>
                  </MDBTable>
                </MDBTabsPane>
              </MDBTabsContent>
            </div>
          )}
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <EditCredentialsModal
        show={modalPutPasswordShow}
        onClose={handleOnCloseCredentialsEdit}
        getClientUsers={getClientUsers}
        clientUserToUpdatePassword={clientUserToUpdatePassword}
      />
      {modalShiftHistoricalShow ? (
        <ShiftsHistoricalModal
          show={modalShiftHistoricalShow}
          onClose={handleOnCloseShiftHistorical}
          clientUser={shiftClientUser}
        />
      ) : null}
    </div>
  )
}
