import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const PostPayrollApi = async (
  companyId,
  branchId,
  resolutionId,
  clientUserId,
  payrollData,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/payroll/payroll-entries/${companyId}/${branchId}/${resolutionId}/${clientUserId}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payrollData),
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"PAYROLL-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al enviar la nómina.')
  }
}

export const getPayrollEntriesByClientUserIdApi = async (
  companyId,
  branchId,
  clientUserId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/payroll/get-entries/${companyId}/${branchId}/${clientUserId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"PAYROLL-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las nóminas.')
  }
}

export const GetPayrollEntriesDocumentsApi = async (
  prefix,
  numeration,
  resolutionId,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/payroll/get-payroll-entries-documents/${prefix}/${numeration}/${resolutionId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"PAYROLL-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los documentos de la nómina.')
  }
}

export const getHistoricalSettlementApi = async (companyId, branchId, year) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/payroll/historical-settlement/${companyId}/${branchId}/${year}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"PAYROLL-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener histórico de periodos.')
  }
}

export const getPayrollEntriesByIdsApi = async (payrollIds) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/payroll/payroll-entries-by-ids/${payrollIds}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"PAYROLL-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las nóminas.')
  }
}
