import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getDriverBussesApi = async (companyId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients-driver-busses/${companyId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TBUSSES-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los conductores.')
  }
}

export const getDriverBusByPlateApi = async (companyId, busPlate) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients-driver-busses/plate/${busPlate.toUpperCase()}/${companyId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TBUSSES-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el bus.')
  }
}

export const getDriverBussesByCompanyApi = async (
  companyId,
  thirdClientId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients-driver-busses/by-company/${companyId}/${thirdClientId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TBUSSES-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los buses.')
  }
}

export const UpdateDriverBussesApi = async (
  driverBusId,
  thirdClientsId,
  busPlate,
  driverName,
  driverCc,
  isGeneric,
  costCenter,
) => {
  let clientData = {
    thirdClientsId: parseInt(thirdClientsId),
    busPlate: busPlate.toUpperCase(),
    driverName: driverName,
    driverCc: driverCc,
    isGeneric: isGeneric,
    costCenter: costCenter,
  }
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients-driver-busses/${driverBusId}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientData),
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TBUSSES-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el conductor.')
  }
}

export const PostDriverBussesApi = async (
  companyId,
  branchId,
  thirdClientsId,
  busPlate,
  driverName,
  driverCc,
  clientUserId,
  isGeneric,
  costCenter,
) => {
  let driverBusData = {
    companyId: companyId,
    branchId: branchId,
    thirdClientsId: parseInt(thirdClientsId),
    busPlate: busPlate.toUpperCase(),
    driverName: driverName,
    driverCc: driverCc,
    clientUserId: clientUserId,
    isGeneric: isGeneric,
    costCenter: costCenter,
  }
  try {
    let rawResult = await fetch(`${apilUrl}/third-clients-driver-busses`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(driverBusData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TBUSSES-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear el conductor.')
  }
}

export const PostBulkDriverBussesApi = async (driverBussesData) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients-driver-busses/bulk-load`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(driverBussesData),
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TBUSSES-6"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al subir los conductores masivamente.')
  }
}

export const getDriverBussesByBusPlate = async (companyId, busPlate) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients-driver-busses/bus-plate/${busPlate.toUpperCase()}/${companyId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TBUSSES-7"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el bus.')
  }
}
