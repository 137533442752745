import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const PostClientMobilitySaleApi = async (saleData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-mobility-sale`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(saleData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CMSALE-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear la venta de movilidad')
  }
}

export const GetClientMobilitySaleApi = async (
  companyId,
  branchId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-mobility-sale/${companyId}/${branchId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CMSALE-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener la venta de movilidad')
  }
}
