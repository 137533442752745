import { Text } from '@mantine/core'
import React from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 12, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({ ...inputProps }) => {
  const currencyMask = createNumberMask(defaultMaskOptions)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px',
        textAlign: 'left',
        width: '100%',
      }}
    >
      <Text
        style={{
          fontSize: '13px',
          color: 'gray',
          float: 'left',
          marginBottom: '5px',
        }}
      >
        {inputProps.label}
      </Text>
      <MaskedInput
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: '1px solid gray',
        }}
        mask={currencyMask}
        {...inputProps}
        onClick={(event) => {
          event.currentTarget.select()
        }}
      />
    </div>
  )
}

export default CurrencyInput
