import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { CircularProgress, Pagination, TextField } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { Edit, CirclePlus, Upload } from 'tabler-icons-react'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import InputLabel from '@mui/material/InputLabel'
import LoadingScreen from '../../components/main/loadingScreen'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { isEmpty } from '../../services/utils'
import ClientsMenu from '../../components/client/shortcutMenus/clientsMenus'
import { getThirdClientsByCompanyApi } from '../api/thirdClients'
import {
  PostDriverBussesApi,
  UpdateDriverBussesApi,
  getDriverBussesByBusPlate,
  getDriverBussesByCompanyApi,
} from '../api/thirdClientsDriverBusses'
import DriverBusUploadModal from '../../components/client/modals/driverBusUploadModal'
import CustomSelect from '../../components/custom/customSelect'
import BarCode from '../../components/custom/barCode'
import ActionButton from '../../components/custom/actionButton'

export default function Driverbus() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  //Driver Busses
  const [driverBusses, setDriverBusses] = useState([])
  const [driverBussesCount, setDriverBussesCount] = useState(0)

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  const form = useForm({
    initialValues: {
      driverBusId: 0,
      busPlate: '',
      driverName: '',
      driverCc: '',
      isGeneric: false,
      costCenter: '',
    },
  })

  const putForm = useForm({
    initialValues: {
      driverBusId: 0,
      thirdClientsId: 0,
      busPlate: '',
      driverName: '',
      driverCc: '',
      isGeneric: false,
      costCenter: '',
    },
  })

  const successCreation = async (data) => {
    if (data == 'ThirdClientsDriverBusses created') {
      notify('success', 'Creación Exitoso')
      await getDriverBussesByCompany(selectedThirdClientId, pg * rpg - rpg, rpg)
      resetConstants()
      setModalCreateShow(false)
    } else {
      notify('warning', 'El usuario ya se encuentra registrado')
    }

    setIsCreateButtonDisabled(false)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createDriverBus = async () => {
    let readyToCreate = true

    if (
      !String(form.values.busPlate)
        .toLowerCase()
        .match(/^[a-zA-Z]{3}\d{3}$/)
    ) {
      readyToCreate = false
      notify('warning', 'Formato de placa del vehículo inválida (AAA###).')
    }
    if (form.values.driverName == '') {
      readyToCreate = false
      notify('warning', 'Falta el nombre del conductor.')
    }
    if (form.values.driverCc == '') {
      readyToCreate = false
      notify('warning', 'Falta el documento del conductor.')
    }
    if (form.values.costCenter == '') {
      readyToCreate = false
      notify('warning', 'Falta el centro de costo.')
    }

    if (readyToCreate) {
      setIsCreateButtonDisabled(true)
      let result = await PostDriverBussesApi(
        tokenData.companyId,
        tokenData.branchId,
        selectedThirdClientId,
        form.values.busPlate,
        form.values.driverName,
        form.values.driverCc,
        tokenData.clientId,
        form.values.isGeneric,
        form.values.costCenter,
      )
      if (result.data != null) successCreation(result.data)
      else notify('warning', 'Error al crear el conductor.')
    }
  }

  const getDriverBussesByCompany = async (thirdClientsId, offset, limit) => {
    let result = await getDriverBussesByCompanyApi(
      tokenData.companyId,
      thirdClientsId,
      offset,
      limit,
    )
    if (result.data != null) {
      setDriverBusses(result.data)
      setDriverBussesCount(result.count)
    } else {
      setDriverBusses([])
      setDriverBussesCount(0)
      notify('warning', 'No hay buses registrados.')
    }
  }

  const handleCreateBusDriverModal = () => {
    if (thirdClients.length > 0) {
      setModalCreateShow(true)
    } else {
      notify('warning', 'No hay clientes registrados')
    }
  }

  const setDatasToUpdate = async (
    driverBusId,
    thirdClientsId,
    busPlate,
    driverName,
    driverCc,
    isGeneric,
    costCenter,
  ) => {
    putForm.setFieldValue('driverBusId', driverBusId)
    putForm.setFieldValue('thirdClientsId', thirdClientsId)
    putForm.setFieldValue('busPlate', busPlate)
    putForm.setFieldValue('driverName', driverName)
    putForm.setFieldValue('driverCc', driverCc)
    putForm.setFieldValue('isGeneric', isGeneric)
    putForm.setFieldValue('costCenter', costCenter)
    setModalPutShow(true)
  }

  //----------third clients------------
  const [thirdClients, setThirdClients] = useState([])
  const [thirdClientsOption, setThirdClientOption] = React.useState([])

  const getThirdClients = async (companyId) => {
    let result = await getThirdClientsByCompanyApi(companyId)
    if (result.data != null) setThirdClientsOptions(result.data)
    else notify('warning', 'No hay clientes registrados')
  }

  const setThirdClientsOptions = (thirdClients) => {
    if (thirdClients.length > 0) {
      let thirdClientsSelect = thirdClients.map((x) => {
        return { value: x.id, label: x.nit + ' - ' + x.thirdClientCompanyName }
      })
      setThirdClientOption(thirdClientsSelect)
      setThirdClients(thirdClients)
    } else {
      setThirdClientOption([])
      setThirdClients([])
      notify('warning', 'No hay empresas registradas.')
    }
  }

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.clients) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await getThirdClients(tokenData.companyId, tokenData.branchId)
  }

  const [isUpdatebuttonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const updateDriverBus = async () => {
    let readyToUpdate = true
    if (putForm.values.thirdClientsId == 0) {
      readyToUpdate = false
      notify('warning', 'Falta seleccionar la empresa.')
    }

    if (
      !String(putForm.values.busPlate)
        .toLowerCase()
        .match(/^[a-zA-Z]{3}\d{3}$/)
    ) {
      readyToUpdate = false
      notify('warning', 'Ingrese una placa válida.')
    }

    if (putForm.values.driverName == '') {
      readyToUpdate = false
      notify('warning', 'Falta el nombre del conductor.')
    }
    if (putForm.values.driverCc.length < 8) {
      readyToUpdate = false
      notify('warning', 'Falta el documento del conductor.')
    }
    if (putForm.values.costCenter == '') {
      readyToUpdate = false
      notify('warning', 'Falta el centro de costo.')
    }

    if (readyToUpdate) {
      setIsUpdateButtonDisabled(true)
      let result = await UpdateDriverBussesApi(
        putForm.values.driverBusId,
        putForm.values.thirdClientsId,
        putForm.values.busPlate,
        putForm.values.driverName,
        putForm.values.driverCc,
        putForm.values.isGeneric,
        putForm.values.costCenter,
      )
      if (result.data == 'ThirdClientsDriverBusses updated') {
        notify('success', 'Actualización Exitoso')
        getDriverBussesByCompany(selectedThirdClientId, pg * rpg - rpg, rpg)
        setModalPutShow(false)
      } else {
        notify('warning', 'Error al actualizar el conductor.')
      }
    }
    setIsUpdateButtonDisabled(false)
  }

  //-------------------getByBusPlate------------------
  const [busPlateOptions, setBusPlateOptions] = useState([])
  const [busPlateData, setBusPlateData] = useState('')

  const filterInvoice = async (busPlate, toCreate, plateInfo = {}) => {
    let result = await getDriverBussesByBusPlate(tokenData.companyId, busPlate)
    if (result.data != null) {
      if (toCreate) {
        setBusPlateData(plateInfo)
        setDriverBusses(result.data)
        setDriverBussesCount(1)
      } else {
        let busPlateSelect = []
        await result.data.map((x) => {
          busPlateSelect.push({
            value: x.id,
            plate: x.busPlate,
            label: x.busPlate,
          })
        })
        setBusPlateOptions(busPlateSelect)
      }
    }
  }

  //---------get thirdclient by id ------------
  const getThirdClientNameById = (id) => {
    let thirdClientName = thirdClients.find((x) => x.id == id)
    return thirdClientName.thirdClientCompanyName
  }

  //----------------

  const resetConstants = () => {
    form.setFieldValue('driverBusId', 0)
    form.setFieldValue('busPlate', '')
    form.setFieldValue('driverName', '')
    form.setFieldValue('driverCc', '')
    form.setFieldValue('isGeneric', false)
    form.setFieldValue('costCenter', '')
  }

  const handleOnCloseCreate = () => {
    resetConstants()
    setModalCreateShow(false)
  }

  const [selectedThirdClientId, setSelectedThirdClientId] = useState(0)
  const [selectedThirdClientName, setSelectedThirdClientName] = useState('')

  const handleClientSelected = (thirdClient) => {
    setSelectedThirdClientId(thirdClient.value)
    setSelectedThirdClientName(thirdClient.label)
    getDriverBussesByCompany(thirdClient.value, pg * rpg - rpg, rpg)
  }

  //-----------------Pagination-------------------
  const [pg, setpg] = useState(1) //page
  const [rpg, setrpg] = useState(10) //rows per page

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getDriverBussesByCompany(selectedThirdClientId, newOffset, rpg)
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    await getDriverBussesByCompany(selectedThirdClientId, 0, event.target.value)
  }

  //--------------------upload driver-busses-----------------

  const [showUploadModal, setShowUploadModal] = useState(false)
  const handleShowUploadModal = () => setShowUploadModal(true)
  const handleCloseUpload = () => {
    setShowUploadModal(false)
    getDriverBussesByCompany(selectedThirdClientId, pg * rpg - rpg, rpg)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <ClientsMenu buttonId={1} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              float: 'right',
              marginBottom: 10,
              marginRight: 5,
              isolation: 'isolate',
            }}
            onClick={handleShowUploadModal}
          >
            <Upload size={20} color="#FFFFFF" /> Carga Masiva
          </Button>
          <Group mb="lg" grow>
            <CustomSelect
              title="Filtrar por empresa"
              placeholder="Empresa"
              value={{
                value: selectedThirdClientId,
                label: selectedThirdClientName,
              }}
              onChange={(e) => handleClientSelected(e)}
              options={thirdClientsOption}
              theme={isDarkModeActive ? 'black' : 'white'}
              minWidth={300}
            />
            <CustomSelect
              title="Buscar por placas"
              placeholder="Placas"
              onChange={(e) => {
                filterInvoice(e.plate, true, e)
              }}
              onKeyDown={(e) => {
                filterInvoice(e.target.value, false)
              }}
              value={busPlateData}
              options={busPlateOptions}
              theme={isDarkModeActive ? 'black' : 'white'}
            />
          </Group>

          <br></br>
          {driverBusses.length > 0 || selectedThirdClientId != 0 ? (
            <>
              <Button
                variant="primary"
                style={{
                  backgroundColor: 'green',
                  marginLeft: 5,
                  marginBottom: 10,
                  isolation: 'isolate',
                }}
                onClick={() => handleCreateBusDriverModal()}
              >
                Nuevo Bus <CirclePlus size={20} color="#FFFFFF" />
              </Button>

              <br></br>
              <MDBTable small>
                <MDBTableHead>
                  <tr style={{ textAlign: 'center' }}>
                    <th>Empresa</th>
                    <th>Conductor</th>
                    <th>°N Documento</th>
                    <th>Fecha de creación</th>
                    <th>Placa</th>
                    <th>Acciones</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {driverBusses.map((driverBusses, index) => {
                    return (
                      <tr style={{ textAlign: 'center' }} key={index}>
                        <td>
                          {getThirdClientNameById(driverBusses.thirdClientsId)}
                        </td>
                        <td>{driverBusses.driverName}</td>
                        <td>{driverBusses.driverCc}</td>
                        <td>{driverBusses.createdAt}</td>
                        <td style={{ width: '0' }}>
                          <BarCode
                            value={driverBusses.busPlate}
                            color={bgColor}
                          />
                        </td>
                        <td>
                          <ActionButton
                            description="Editar"
                            backgroundColor={bgColor}
                            onClick={() =>
                              setDatasToUpdate(
                                driverBusses.id,
                                driverBusses.thirdClientsId,
                                driverBusses.busPlate,
                                driverBusses.driverName,
                                driverBusses.driverCc,
                                driverBusses.isGeneric,
                                driverBusses.costCenter,
                              )
                            }
                          >
                            <Edit size={30} color={color} />
                          </ActionButton>
                        </td>
                      </tr>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
              <Container>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Filas
                  </InputLabel>
                  <MuiSelect
                    labelId="demo-simple-select-standard-label"
                    autoWidth={true}
                    value={rpg}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </MuiSelect>
                </FormControl>
                <Pagination
                  count={Math.ceil(driverBussesCount / rpg)}
                  page={pg}
                  onChange={handleChangePage}
                  showFirstButton={true}
                  showLastButton={true}
                  style={{ display: 'inline-block' }}
                />
              </Container>
            </>
          ) : null}
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Bus
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group mb="lg" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Placa"
                    placeholder="Placa"
                    value={form.values.busPlate}
                    onChange={(event) => {
                      form.setFieldValue('busPlate', event.currentTarget.value)
                    }}
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Nombre de conductor"
                    placeholder="Nombre de conductor"
                    value={form.values.driverName}
                    onChange={(event) =>
                      form.setFieldValue(
                        'driverName',
                        event.currentTarget.value,
                      )
                    }
                  />
                </Group>
                <Group mb="lg" grow>
                  <TextField
                    variant="standard"
                    required
                    label="°N de documento"
                    placeholder="°N de documento"
                    type="number"
                    value={form.values.driverCc}
                    onChange={(event) => {
                      form.setFieldValue('driverCc', event.currentTarget.value)
                    }}
                  />
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Tipo de Cliente
                    </InputLabel>
                    <MuiSelect
                      label="Tipo de Cliente"
                      onChange={(e) =>
                        form.setFieldValue('isGeneric', e.target.value)
                      }
                      value={form.values.isGeneric}
                    >
                      <MenuItem value={false}>Cliente</MenuItem>
                      <MenuItem value={true}>Genérico</MenuItem>
                    </MuiSelect>
                  </FormControl>
                </Group>
                <Group mb="lg" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Centro de costo"
                    placeholder="Centro de costo"
                    value={form.values.costCenter}
                    onChange={(event) =>
                      form.setFieldValue(
                        'costCenter',
                        event.currentTarget.value,
                      )
                    }
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createDriverBus()}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => {
                  handleOnCloseCreate()
                }}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando Bus
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group mb="lg" grow>
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Cliente
                    </InputLabel>
                    <MuiSelect
                      label="Cliente"
                      onChange={(e) =>
                        putForm.setFieldValue('thirdClientsId', e.target.value)
                      }
                      value={putForm.values.thirdClientsId}
                    >
                      {thirdClients.map((thirdClient, index) => {
                        return (
                          <MenuItem value={thirdClient.id} key={index}>
                            {thirdClient.thirdClientCompanyName}
                          </MenuItem>
                        )
                      })}
                    </MuiSelect>
                  </FormControl>
                  <TextField
                    variant="standard"
                    required
                    label="Placa"
                    placeholder="Placa"
                    value={putForm.values.busPlate}
                    onChange={(event) => {
                      putForm.setFieldValue(
                        'busPlate',
                        event.currentTarget.value,
                      )
                    }}
                  />
                </Group>
                <Group mb="lg" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Nombre de conductor"
                    placeholder="Nombre de conductor"
                    value={putForm.values.driverName}
                    onChange={(event) =>
                      putForm.setFieldValue(
                        'driverName',
                        event.currentTarget.value,
                      )
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="°N de documento"
                    placeholder="°N de documento"
                    value={putForm.values.driverCc}
                    type="number"
                    onChange={(event) => {
                      putForm.setFieldValue(
                        'driverCc',
                        event.currentTarget.value,
                      )
                    }}
                  />
                </Group>
                <Group grow>
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Tipo de Cliente
                    </InputLabel>
                    <MuiSelect
                      label="Tipo de Cliente"
                      onChange={(e) =>
                        putForm.setFieldValue('isGeneric', e.target.value)
                      }
                      value={putForm.values.isGeneric}
                    >
                      <MenuItem value={false}>Persona</MenuItem>
                      <MenuItem value={true}>Genérico</MenuItem>
                    </MuiSelect>
                  </FormControl>
                  <TextField
                    variant="standard"
                    required
                    label="Centro de costo"
                    placeholder="Centro de costo"
                    value={putForm.values.costCenter}
                    onChange={(event) =>
                      putForm.setFieldValue(
                        'costCenter',
                        event.currentTarget.value,
                      )
                    }
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isUpdatebuttonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateDriverBus()}
              >
                {isUpdatebuttonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {showUploadModal ? (
        <DriverBusUploadModal
          show={showUploadModal}
          onClose={handleCloseUpload}
          thirdClients={thirdClients}
        />
      ) : null}
      {/* <MDBModal
        show={modalDeleteShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Eliminando Usuario
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Seguro que quieres eliminar al usuario?</p>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => delteClientUser()}
              >
                Eliminar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalDeleteShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal> */}
    </div>
  )
}
