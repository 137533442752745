import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Group } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import useStore from '../../../components/zustand'
import { TextField } from '@mui/material'
import { Checkbox } from '@mantine/core'
import 'react-toastify/dist/ReactToastify.css'
import { dayjsDateToLocale, notify } from '../../../services/utils.js'
import { useNavigate } from 'react-router'
import Button from 'react-bootstrap/Button'
import { CircleMinus, CirclePlus } from 'tabler-icons-react'
import { CircularProgress, MenuItem } from '@mui/material'
import { isEmpty, setDecimal } from '../../../services/utils.js'
import { MDBTable, MDBTableBody } from 'mdb-react-ui-kit'
import { FormControl, InputLabel } from '@mui/material'
import { Select as MuiSelect } from '@mui/material'
import CustomSelect from '../../custom/customSelect'
import { getClientInvoiceByInvoiceNumberApi } from '../../../pages/api/clientinvoice.js'
import { PostDebitNoteApi } from '../../../pages/api/debitNote.js'
import Notes from '../../custom/notes.js'

export default function NewDebitNote(props) {
  const { setModalCreateShow, successCreation } = props

  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      invoiceNumber: '',
      charge: 0,
      reason: '',
      discount: false,
    },
  })

  //CHECK ROLES
  const tokenVerified = async () => {
    if (!rolesData.invoice) {
      notify('warning', 'No tienes permiso para ver este módulo.')
      navigate('/dashboard')
    }
  }

  //-------------------GetClients------------------
  const [invoiceOptions, setInvoiceOptions] = useState([])
  const [invoiceData, setInvoiceData] = useState('')

  const filterInvoice = async (invoiceNumber, toCreate, bill = {}) => {
    if (invoiceNumber.length > 0) {
      let result = await getClientInvoiceByInvoiceNumberApi(
        tokenData.companyId,
        invoiceNumber,
      )
      if (result.data != null) {
        if (toCreate) {
          setInvoiceData(bill)
          setBillSelected(result.data[0])
          setBillProducts(JSON.parse(result.data[0].items))
          form.setFieldValue('invoiceNumber', invoiceNumber)
          calculateToDebitNoteAdditionalPayments(
            JSON.parse(result.data[0].items),
          )
        } else {
          let invoiceSelect = []
          await result.data.map((x) => {
            if (x.invoiceDianStatus == 'DIAN_ACEPTADO')
              invoiceSelect.push({
                value: x.id,
                label: x.invoiceNumber,
              })
          })
          setInvoiceOptions(invoiceSelect)
        }
      }
    }
  }

  //----------------------Bills methods---------------------------

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)
  const [billSelected, setBillSelected] = useState([]) //current bill

  const [startDate, setStartDate] = useState(new Date())

  const getProductsTotalPrice = (products) => {
    let total = 0
    products.map((product) => {
      let iva =
        product.taxes == undefined ? 0 : parseInt(product.taxes[0].tax_rate)
      let rf =
        product.retentions == undefined
          ? 0
          : parseFloat(product.retentions[0].tax_rate)
      let discount =
        product.discount_rate == undefined ? 0 : parseInt(product.discount_rate)
      total +=
        (product.price *
          product.quantity *
          (1 - discount / 100) *
          (100 + (iva - rf))) /
        100
    })
    return total
  }

  const getProductsSubtotalPrice = (products) => {
    let subtotal = 0
    products.map((product) => {
      let discount =
        product.discount_rate != undefined ? product.discount_rate : 0
      subtotal += product.price * (1 - discount / 100) * product.quantity
    })

    return subtotal
  }

  const setClientAdditionalPayments = (payment, value, index) => {
    let products = [...billProducts]
    if (payment == 'quantity') products[index].quantity = parseInt(value)
    if (payment == 'discount') products[index].discount_rate = parseInt(value)
    if (payment == 'iva')
      products[index].taxes = [
        {
          tax_category: 'IVA',
          tax_rate: parseInt(value),
        },
      ]
    if (payment == 'rf')
      products[index].retentions = [
        {
          tax_category: 'RET_FUENTE',
          tax_rate: parseFloat(value),
        },
      ]

    let total = getTotalPerProduct(products[index], false)
    products = setTotal(products, total, index)
    calculateToDebitNoteAdditionalPayments(products)
    setBillProducts(products)
  }

  const setTotal = (clientbillproducts, total, index) => {
    clientbillproducts[index].total = total
    return clientbillproducts
  }

  const getTotalPerProduct = (clientProducts, isInvoice) => {
    let discount = isInvoice
      ? clientProducts.discount
      : clientProducts.discount_rate != undefined
      ? clientProducts.discount_rate
      : 0
    return (
      (clientProducts.price *
        clientProducts.quantity *
        (100 - parseInt(discount))) /
      100
    )
  }

  //-----------------------SendDebitNotes---------------------

  const [billProducts, setBillProducts] = useState([]) //invoice products

  const createDebitNote = async (bill) => {
    let readyForCreate = true

    if (form.values.reason == '') {
      notify('warning', 'Elija una razón para la nota débito.')
      readyForCreate = false
    }

    if (readyForCreate) {
      setIsCreateButtonDisabled(true)

      let data = {
        baseAmount: parseInt(form.values.charge),
        branchId: tokenData.branchId,
        client: bill.client,
        clientUserId: tokenData.clientId,
        companyId: tokenData.companyId,
        resolutionId: bill.resolutionId,
        discount: form.values.discount,
        id: 0,
        issueDate: dayjsDateToLocale(startDate),
        invoice: bill.uuid, //uuid
        invoiceNumber: form.values.invoiceNumber,
        items: billProducts,
        notes: debitNotesNotes,
        reason: form.values.reason,
        saleId: bill.saleId,
        thirdClientId: bill.thirdClientId,
      }
      let result = await PostDebitNoteApi(data)
      if (result.data != null) {
        successCreation(result)
        resetConstants()
      } else {
        notify('warning', 'Error al crear la nota débito.')
      }
    }
  }

  //--------------------Notes---------------------------
  const [debitNotesNotes, setDebitNotesNotes] = useState([])

  //--------------info invoice---------------
  const [debitNoteAdditionalPayments, setDebitNoteAdditionalPayments] =
    useState({
      5: { name: 'IVA(5%)', total: 0 },
      16: { name: 'IVA(16%)', total: 0 },
      19: { name: 'IVA(19%)', total: 0 },
      discount: { name: 'Descuento', total: 0 },
      rf: { name: 'Ret. FUENTE', total: 0 },
    })

  const calculateToDebitNoteAdditionalPayments = (bills) => {
    let iva5 = 0
    let iva16 = 0
    let iva19 = 0
    let rf = 0
    let discount = 0
    bills.map((product) => {
      let retentions =
        product.retentions != undefined ? product.retentions[0].tax_rate : 0
      let iva = product.taxes != undefined ? product.taxes[0].tax_rate : 0
      let disc = product.discount_rate != undefined ? product.discount_rate : 0

      discount += parseFloat(
        (product.price * product.quantity * parseInt(disc)) / 100,
      )
      let total = product.price * product.quantity - discount
      if (parseInt(iva) == 5) {
        iva5 += (total * parseInt(iva)) / 100
      }
      if (parseInt(iva) == 16) {
        iva16 += (total * parseInt(iva)) / 100
      }
      if (parseInt(iva) == 19) {
        iva19 += (total * parseInt(iva)) / 100
      }
      rf += (total * parseFloat(retentions)) / 100
    })
    let ap = {
      5: { name: 'IVA(5%)', total: iva5 },
      16: { name: 'IVA(16%)', total: iva16 },
      19: { name: 'IVA(19%)', total: iva19 },
      discount: { name: 'Descuento', total: discount },
      rf: { name: 'Ret. FUENTE', total: rf },
    }
    setDebitNoteAdditionalPayments(ap)
  }
  //-----------------------

  const resetConstants = () => {
    form.setFieldValue('invoiceNumber', '')
    form.setFieldValue('charge', '')
    form.setFieldValue('reason', '')
    form.setFieldValue('discount', false)
    setBillProducts([])
    setDebitNotesNotes([])
    setBillSelected([])
    setDebitNoteAdditionalPayments({
      5: { name: 'IVA(5%)', total: 0 },
      16: { name: 'IVA(16%)', total: 0 },
      19: { name: 'IVA(19%)', total: 0 },
      discount: { name: 'Descuento', total: 0 },
      rf: { name: 'Ret. FUENTE', total: 0 },
    })
  }

  //---------------

  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      tokenVerified()
    }
  }, [tokenData, rolesData])

  return (
    <Container
      style={{
        border: '1px solid gray',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <h4>Creando Nueva Nota Débito</h4>
      <hr></hr>
      <Group direction="column" grow>
        <Group mb="md" mt="md" direction="column" grow>
          <CustomSelect
            title="Factura"
            placeholder="FACTURA"
            onChange={(e) => {
              resetConstants()
              filterInvoice(e.label, true, e)
            }}
            onKeyDown={(e) => {
              filterInvoice(e.target.value, false)
            }}
            value={invoiceData}
            options={invoiceOptions}
            theme={isDarkModeActive ? 'black' : 'white'}
          />
        </Group>
        {billProducts.length > 0 ? (
          <>
            <Group mb="md" mt="md" direction="column" grow>
              <h6>{`Factura #${billSelected.invoiceNumber} - (${billSelected.createdAt})`}</h6>
              <table
                style={{
                  borderSpacing: '10px',
                  borderCollapse: 'separate',
                }}
              >
                <thead>
                  <tr>
                    <th>NOMBRE</th>
                    <th>CANTIDAD</th>
                    <th>DESCUENTO</th>
                    <th>RET. FUENTE</th>
                    <th>IVA</th>
                    <th>SUBTOTAL</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {billProducts.map((clientProducts, index) => {
                    return (
                      <tr key={index}>
                        <td>{clientProducts.description}</td>
                        <td>
                          <div>
                            <span
                              style={{
                                fontSize: 16,
                                marginTop: '40px',
                              }}
                            >
                              {' '}
                              {clientProducts.quantity}{' '}
                            </span>
                          </div>
                        </td>
                        <td>
                          <TextField
                            variant="standard"
                            onChange={(event) => {
                              if (
                                event.currentTarget.value >= 0 &&
                                event.currentTarget.value <= 100
                              ) {
                                setClientAdditionalPayments(
                                  'discount',
                                  event.currentTarget.value,
                                  index,
                                )
                              }
                            }}
                            onBlur={(event) => {
                              if (!(event.currentTarget.value != ''))
                                setClientAdditionalPayments(
                                  'discount',
                                  0,
                                  index,
                                )
                            }}
                            type="number"
                            value={
                              clientProducts.discount_rate != undefined
                                ? clientProducts.discount_rate
                                : 0
                            }
                            style={{
                              width: '45px',
                              display: 'inline-block',
                            }}
                          />
                          %
                        </td>
                        <td>
                          <FormControl variant="standard" sx={{ m: 1 }}>
                            <InputLabel id="demo-simple-select-standard-label">
                              Ret. Fuente
                            </InputLabel>
                            <MuiSelect
                              label="Ret. fuente"
                              defaultValue={
                                clientProducts.retentions != undefined
                                  ? clientProducts.retentions[0].tax_rate
                                  : 0
                              }
                              onChange={(e) =>
                                setClientAdditionalPayments(
                                  'rf',
                                  e.target.value,
                                  index,
                                )
                              }
                            >
                              <MenuItem value={0}>0%</MenuItem>
                              <MenuItem value={0.1}>0.1%</MenuItem>
                              <MenuItem value={0.5}>0.5%</MenuItem>
                              <MenuItem value={1}>1%</MenuItem>
                              <MenuItem value={1.5}>1.5%</MenuItem>
                              <MenuItem value={2}>2%</MenuItem>
                              <MenuItem value={2.5}>2.5%</MenuItem>
                              <MenuItem value={3}>3%</MenuItem>
                              <MenuItem value={3.5}>3.5%</MenuItem>
                              <MenuItem value={4}>4%</MenuItem>
                              <MenuItem value={6}>6%</MenuItem>
                              <MenuItem value={7}>7%</MenuItem>
                              <MenuItem value={10}>10%</MenuItem>
                              <MenuItem value={11}>11%</MenuItem>
                              <MenuItem value={20}>20%</MenuItem>
                            </MuiSelect>
                          </FormControl>
                        </td>
                        <td>
                          <FormControl variant="standard" sx={{ m: 1 }}>
                            <InputLabel id="demo-simple-select-standard-label">
                              IVA
                            </InputLabel>
                            <MuiSelect
                              label="IVA"
                              defaultValue={
                                clientProducts.taxes != undefined
                                  ? clientProducts.taxes[0].tax_rate
                                  : 0
                              }
                              onChange={(e) =>
                                setClientAdditionalPayments(
                                  'iva',
                                  e.target.value,
                                  index,
                                )
                              }
                            >
                              <MenuItem value={0}>IVA 0% (Exento)</MenuItem>
                              <MenuItem value={5}>IVA 5%</MenuItem>
                              <MenuItem value={16}>IVA 16%</MenuItem>
                              <MenuItem value={19}>IVA 19%</MenuItem>
                            </MuiSelect>
                          </FormControl>
                        </td>
                        <td>
                          $
                          {setDecimal(
                            clientProducts.price * clientProducts.quantity,
                          )}
                        </td>
                        <td>
                          $
                          {setDecimal(
                            (parseFloat(
                              clientProducts.price *
                                clientProducts.quantity *
                                (1 -
                                  (clientProducts.discount_rate != undefined
                                    ? clientProducts.discount_rate
                                    : 0) /
                                    100),
                            ) *
                              (100 +
                                ((clientProducts.taxes != undefined
                                  ? clientProducts.taxes[0].tax_rate
                                  : 0) -
                                  (clientProducts.retentions != undefined
                                    ? clientProducts.retentions[0].tax_rate
                                    : 0)))) /
                              100,
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Group>
            <Group grow mb="md" mt="md">
              <FormControl variant="standard" sx={{ m: 1 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Razón
                </InputLabel>
                <MuiSelect
                  label="RAZÓN"
                  onChange={(e) => {
                    form.setFieldValue('reason', e.target.value)
                  }}
                  value={form.values.reason}
                >
                  <MenuItem value={''}></MenuItem>
                  <MenuItem value={'INTERESES'}>Intereses</MenuItem>
                  <MenuItem value={'GASTOS'}>Gastos</MenuItem>
                  <MenuItem value={'CAMBIO_VALOR'}>Cambio Valor</MenuItem>
                  <MenuItem value={'OTROS'}>Otros</MenuItem>
                </MuiSelect>
              </FormControl>
              <TextField
                fullWidth={true}
                variant="standard"
                label="Monto"
                placeholder="Monto"
                type="number"
                value={form.values.charge}
                onChange={(event) =>
                  form.setFieldValue('charge', event.currentTarget.value)
                }
              />
            </Group>
            <Group grow mb="md" mt="md">
              <MDBTable>
                <MDBTableBody>
                  <tr>
                    <td>Subtotal:</td>
                    <td>
                      $
                      {setDecimal(
                        parseFloat(getProductsSubtotalPrice(billProducts)),
                      )}
                    </td>
                  </tr>
                  {Object.keys(debitNoteAdditionalPayments).map(
                    (key, index) => {
                      if (
                        debitNoteAdditionalPayments[key].total > 0 &&
                        debitNoteAdditionalPayments[key].name.startsWith('IVA')
                      ) {
                        return (
                          <tr key={index}>
                            <td>{debitNoteAdditionalPayments[key].name}</td>
                            <td>
                              + $
                              {setDecimal(
                                debitNoteAdditionalPayments[key].total,
                              )}
                            </td>
                          </tr>
                        )
                      }
                    },
                  )}
                  {debitNoteAdditionalPayments.discount.total > 0 ? (
                    <tr>
                      <td>Descuento: </td>
                      <td>
                        - $
                        {setDecimal(debitNoteAdditionalPayments.discount.total)}
                      </td>
                    </tr>
                  ) : null}
                  {debitNoteAdditionalPayments.rf.total > 0 ? (
                    <tr>
                      <td>Ret. Fuente: </td>
                      <td>
                        - ${setDecimal(debitNoteAdditionalPayments.rf.total)}
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>
                      <b>Total:</b>
                    </td>
                    <td>
                      <b>${setDecimal(getProductsTotalPrice(billProducts))}</b>
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </Group>
            <Group mb="md" mt="md">
              <Notes
                notes={debitNotesNotes}
                onChange={(notes) => setDebitNotesNotes(notes)}
              />
            </Group>
          </>
        ) : null}
      </Group>
      <hr></hr>
      <Button
        disabled={isCreateButtonDisabled}
        style={{
          backgroundColor: 'green',
          borderRadius: '100px',
          marginRight: '10px',
          isolation: 'isolate',
        }}
        onClick={() =>
          billSelected.length != 0
            ? createDebitNote(billSelected)
            : notify('warning', 'No hay factura seleccionada.')
        }
      >
        {isCreateButtonDisabled ? (
          <CircularProgress size={15} color="inherit" />
        ) : null}{' '}
        Crear
      </Button>
      <Button
        style={{
          backgroundColor: 'transparent',
          borderRadius: '100px',
          color: 'black',
        }}
        onClick={() => {
          resetConstants()
          setModalCreateShow(false)
        }}
      >
        Volver
      </Button>
    </Container>
  )
}
