import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { useNavigate } from 'react-router-dom'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import Menu from '../../components/client/menu'
import useStore from '../../components/zustand'
import Alert from 'react-bootstrap/Alert'
import { Select as MuiSelect } from '@mui/material'
import ReactJoyride from 'react-joyride'
import {
  CirclePlus,
  DotsVertical,
  Download,
  QuestionMark,
  Trash,
} from 'tabler-icons-react'
import LoadingScreen from '../../components/main/loadingScreen'
import saleIcon from '../../resources/images/factura.png'
import pdf from '../../resources/images/pdf.png'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Menu as MenuMui,
  Pagination,
  Select as SelectPages,
} from '@mui/material'
import Swal from 'sweetalert2'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import NewInvoice from '../../components/client/ebilling/newInvoice'
import InvoiceFilters from '../../components/client/filters/invoiceFilters'
import EditBundledSalesModal from '../../components/client/sales/modals/editBundledSalesModal'
import SaleModal from '../../components/client/sales/modals/saleModal'
import BillingMenus from '../../components/client/shortcutMenus/billingMenus'
import sendDian from '../../resources/images/dian.png'
import { dateFormat, isEmpty } from '../../services/utils'
import ResendDian from '../../components/client/ebilling/resendDian'
import ReportModal from '../../components/client/modals/reportModal'
import { getClientSalesByIdApi } from '../api/clientsale'
import {
  DeleteClientInvoiceApi,
  getAllClientInvoiceApi,
  getFilteredClientInvoiceByInvoiceApi,
  getFilteredClientInvoiceByThirdClientAndDateApi,
} from '../api/clientinvoice'
import { getThirdClientsByCompanyApi } from '../api/thirdClients'
import { getClientResolutionsApi } from '../api/clientResolutions'
import NewRumbos from '../../components/client/ebilling/newRumbos'

export default function Invoice() {
  const { tokenData, rolesData } = useStore()

  const navigate = useNavigate()

  const [clients, setClients] = useState([])
  const [invoice, setInvoice] = useState([])

  const [modalCreateShow, setModalCreateShow] = React.useState(false)
  const [ModalResendShow, setModalResendShow] = useState(false)
  const [ModalReportShow, setModalReportShow] = React.useState(false)
  const [ModalRumbosShow, setModalRumbosShow] = React.useState(false)

  const [loading, setLoading] = useState(true)

  const getInvoice = async (
    companyId,
    branchId,
    resolutionId,
    offset,
    limit,
  ) => {
    let result = await getAllClientInvoiceApi(
      companyId,
      branchId,
      resolutionId,
      offset,
      limit,
    )
    if (result.data != null) {
      setTotalInvoiceCount(result.count)
      setInvoice(result.data)
    } else {
      setInvoice([])
      setTotalInvoiceCount(0)
      notify('warning', 'No se encuentran facturas.')
    }
  }

  const successCreation = async (data) => {
    if (data.data == 400) {
      notify(
        'error',
        `Petición o dirección inválida, error tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 500) {
      notify(
        'error',
        `Error en el servidor de tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 401) {
      notify(
        'error',
        `Fallo en la autenticación, contacto con un administrador, error tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 404) {
      notify(
        'error',
        `No se pudo enviar a facturación electrónica, error tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 428) {
      notify(
        'error',
        `No se pudo enviar a facturación electrónica, error tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 201) {
      notify('success', 'Creación Exitosa')
      await getInvoice(
        tokenData.companyId,
        tokenData.branchId,
        currentResolution,
        pg * rpg - rpg,
        rpg,
      )
    }
    setModalCreateShow(false)
    setModalResendShow(false)
    setModalRumbosShow(false)
  }

  const getClients = async (companyId) => {
    let result = await getThirdClientsByCompanyApi(companyId)
    if (result.data != null) setClients(result.data)
    else notify('warning', 'No se encuentran clientes.')
  }

  //----------------Bundled invoices -------------------+

  const [bundledSale, setBundledSale] = useState(0)
  const [showEditBundledSaleModal, setShowEditBundleSaleModal] = useState(false)
  const handleShowEditBundledSaleModal = (invoice) => {
    setBundledSale(invoice)
    setShowEditBundleSaleModal(true)
  }
  const handleCloseEditBundledSale = () => {
    reloadTable()
    setShowEditBundleSaleModal(false)
  }

  //---------------------roles--------------------

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.invoice) {
      notify('warning', 'No tienes permiso para ver este módulo.')
      navigate('/dashboard')
    }
    await getResolutions(tokenData.branchId)
    await getClients(tokenData.companyId, tokenData.branchId)
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  //--------------------Resolutions methods----------------------

  const [resolutions, setResolutions] = useState([])

  const [currentResolution, setCurrentResolution] = useState(0)

  const getResolutions = async (branchId) => {
    const result = await getClientResolutionsApi(branchId)
    if (result.data != null) {
      setResolutions(result.data)
      await getInvoice(
        tokenData.companyId,
        tokenData.branchId,
        result.data[0].id,
        pg * rpg - rpg,
        rpg,
      )
      setResolutionInfo(result.data[0])
      setCurrentResolution(result.data[0].id)
    } else notify('warning', 'No se encuentran resoluciones.')
  }

  const handleResolutionChange = async (resolutionId) => {
    setCurrentResolution(resolutionId)
    setResolutionInfo(resolutions.find((x) => x.id == resolutionId))

    await getInvoice(
      tokenData.companyId,
      tokenData.branchId,
      resolutionId,
      pg * rpg - rpg,
      rpg,
    )
  }

  //--------------------Resolution date check methods----------------------

  const [availableForBilling, setAvailableForBilling] = useState(false)
  const [resolutionAlerts, setResolutionAlerts] = useState([])

  const setResolutionInfo = (resolution) => {
    let today = new Date()
    today = today.toISOString()
    const DAY_UNIT_IN_MILLISECONDS = 24 * 3600 * 1000
    const diffInMilliseconds =
      new Date(resolution.endDate).getTime() - new Date(today).getTime()
    const diffInDays = diffInMilliseconds / DAY_UNIT_IN_MILLISECONDS
    checkResolutionAvailability(
      diffInDays,
      new Date(resolution.endDate),
      resolution.end - resolution.start,
    )
  }

  const checkResolutionAvailability = (
    daysToEndDate,
    resolutionEndDate,
    resolutionLeft,
  ) => {
    const alerts = []
    let availableForBilling = true
    if (daysToEndDate < 6 && daysToEndDate > 0) {
      alerts.push(
        <Alert key={1} variant={daysToEndDate < 4 ? 'warning' : 'info'}>
          Su resolución de la DIAN para facturación electrónica vence el día{' '}
          {dateFormat(resolutionEndDate.toLocaleDateString(), true, false)}.
        </Alert>,
      )
    }
    if (resolutionLeft <= 10 && resolutionLeft > 0) {
      alerts.push(
        <Alert key={2} variant="warning">
          A su resolución de la DIAN para facturación electrónica sólo le quedan{' '}
          {resolutionLeft} facturas dispoinibles.
        </Alert>,
      )
    }
    if (daysToEndDate <= 0) {
      alerts.push(
        <Alert key={3} variant="danger">
          Su resolución de la DIAN para facturación electrónica está caducada,
          genere una nueva con la DIAN y comuníquese con nuestro soporte
          técnico.
        </Alert>,
      )
      availableForBilling = false
    }
    if (resolutionLeft == 0) {
      alerts.push(
        <Alert key={4} variant="danger">
          Su resolución de la DIAN para facturación electrónica se quedó sin
          facturas disponibles, genere una nueva resolución con la DIAN y
          comuníquese con nuestro soporte técnico.
        </Alert>,
      )
      availableForBilling = false
    }
    setAvailableForBilling(availableForBilling)
    setResolutionAlerts(alerts)
  }

  //----------------------Bills methods---------------------------

  const [isBundled, setIsBundled] = useState(false)

  const reloadTable = async () => {
    await getInvoice(
      tokenData.companyId,
      tokenData.branchId,
      currentResolution,
      pg * rpg - rpg,
      rpg,
    )
  }

  //------------------Eliminación de factura----------------------------

  const [invoiceOptions, setInvoiceOptions] = useState(null)
  const resetToDeleteConstants = () => {
    setInvoiceOptions(null)
    setAnchorEl(null)
  }

  const deleteInvoiceState = async () => {
    try {
      Swal.fire({
        title: '¿Eliminar la factura electrónica?',
        text: 'Nota: Esta acción es irreversible',
        icon: 'warning',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(async (response) => {
        if (response.isConfirmed) {
          let result = await DeleteClientInvoiceApi(
            currentResolution,
            invoiceOptions,
          )
          if (result.data == 'Deleted') {
            notify('success', `Factura eliminada con éxito.`)
            resetToDeleteConstants()
            await getInvoice(
              tokenData.companyId,
              tokenData.branchId,
              currentResolution,
              pg * rpg - rpg,
              rpg,
            )
          } else {
            notify('error', `Error al eliminar factura, ${result.data}.`)
          }
        }
      })
    } catch (error) {
      return error
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event, invoice) => {
    setInvoiceOptions(invoice)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(10)
  const [totalInvoiceCount, setTotalInvoiceCount] = useState(0)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getInvoice(
      tokenData.companyId,
      tokenData.branchId,
      currentResolution,
      newOffset,
      rpg,
    )
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    getInvoice(
      tokenData.companyId,
      tokenData.branchId,
      currentResolution,
      0,
      event.target.value,
    )
  }

  //--------------------Sale ---------------

  const [saleInfo, setSaleInfo] = useState([])

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleCloseSale = () => setShow(false)

  const showSale = async (saleId) => {
    let result = await getClientSalesByIdApi(saleId)
    if (result.data != null) {
      setSaleInfo(result.data)
      handleShow()
    } else {
      notify('warning', 'Error, no se encuentra la venta asociada.')
    }
  }
  //---------------------- Joyride -----------------------
  const [runJoyride, setRunJoyride] = useState(false)
  const [joyrideSteps] = useState([
    {
      content:
        'Este es el módulo de Facturación Electrónica, aquí podrá enviar las facturas aprobadas para la DIAN y gestionar las notas crédito y débito.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      placement: 'center',
      target: 'body',
      title: 'Facturación Electrónica',
    },
    {
      content: 'Aquí puedes ver el histórico de las facturas electrónicas.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      spotlightPadding: 20,
      target: '.invoices_table',
      title: 'Información de facturas',
    },
    {
      content: (
        <div style={{ textAlign: 'center' }}>
          <h6>
            Aquí puedes ver los estados de la factura electrónica, los cuales
            varían entre{' '}
          </h6>{' '}
          <div
            style={{
              borderRadius: '7%',
              backgroundColor: '#EF5350',
              color: 'white',
              width: 'fit-content',
              padding: '0px 3px',
              margin: '0 10px 0 10px',
              fontSize: '14px',
            }}
          >
            DIAN RECHAZADO
          </div>
          <div
            style={{
              borderRadius: '7%',
              backgroundColor: '#66BB6A',
              color: 'white',
              width: 'fit-content',
              padding: '0px 3px',
              margin: '0 10px 0 10px',
              fontSize: '14px',
            }}
          >
            DIAN ACEPTADO
          </div>
        </div>
      ),
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.invoices_states',
      title: 'Estados de factura.',
    },

    {
      content: (
        <div>
          Aquí podrás ver toda la información de la venta asociada a cada
          factura electrónica, dando click al botón{' '}
          <img
            src={saleIcon}
            style={{
              float: 'right',
              float: 'inline-start',
              alignContent: 'center',
              cursor: 'pointer',
              width: '40px',
            }}
          />
        </div>
      ),
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.invoices_saleInfo',
      title: 'Información de la venta',
    },
    {
      content: (
        <div>
          En este apartado, podrás ver el archivo pdf de la Factura Electrónica,
          dando click al botón{' '}
          <img
            style={{
              float: 'right',
              float: 'inline-start',
              alignContent: 'center',
              cursor: 'pointer',
              width: '40px',
            }}
            src={pdf}
          />
        </div>
      ),
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.invoices_actions',
      title: 'Acciones',
    },
    {
      content: (
        <div>
          En este apartado, podrás ver las distintas opciones de la Factura
          Electrónica, como son reenviar a DIAN o eliminar factura. Sólo debes
          dar click al botón <DotsVertical />
        </div>
      ),
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.invoices_actions',
      title: 'Acciones',
    },
    {
      content: (
        <div>
          Ahora, para realizar una factura electrónica, sólo debes darle a este
          botón
        </div>
      ),
      placement: 'bottom',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.create_invoice_button',
      title: 'Empezar facturación',
    },
    {
      content: (
        <div>
          Por último, puedes generar reporte de facturación tanto en CSV como en
          EXCEL y descargarlos en tu dispositivo.
        </div>
      ),
      placement: 'top',
      locale: {
        skip: 'Saltar',
        next: 'Siguiente',
        back: 'Atrás',
        last: 'Terminar',
      },
      target: '.invoices_report',
      title: 'Reporte de facturación',
    },
  ])

  const handleJoyrideCallback = (CallBackProps) => {
    const status = CallBackProps.status

    if (status == 'ready') {
      setRunJoyride(false)
    }
  }

  //-----------Filters------------------

  const getFilteredInvoiceByInvoice = async (invoiceString) => {
    let result = await getFilteredClientInvoiceByInvoiceApi(
      tokenData.companyId,
      tokenData.branchId,
      invoiceString,
    )
    if (result.data != null) {
      setInvoice(result.data)
    } else {
      notify('warning', 'No se encuentran facturas para el filtro aplicado.')
      setInvoice([])
    }
  }

  const getFilteredInvoiceByDate = async (
    thirdClientId,
    filterStartDate,
    filterEndDate,
    offset,
    limit,
  ) => {
    let startDate = dateFormat(
      filterStartDate.toLocaleDateString(),
      false,
      true,
    )
    let endDate = dateFormat(filterEndDate.toLocaleDateString(), false, true)
    try {
      let result = await getFilteredClientInvoiceByThirdClientAndDateApi(
        tokenData.companyId,
        tokenData.branchId,
        thirdClientId,
        startDate,
        endDate,
        offset,
        limit,
      )
      if (result.data != null) {
        setInvoice(result.data)
        setTotalInvoiceCount(result.count)
      } else {
        notify('warning', 'No se encuentran facturas para el filtro aplicado.')
        setInvoice([])
        setTotalInvoiceCount(0)
      }
    } catch (error) {
      return error
    }
  }

  //----------------------------------
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#424242' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <div>
        <ReactJoyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={runJoyride}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={joyrideSteps}
          styles={{
            options: {
              zIndex: 20,
              primaryColor: 'green',
            },
          }}
        />
        <Menu />
      </div>
      {!loading ? (
        <Container>
          <Button
            onClick={() => setRunJoyride(true)}
            style={{ float: 'right', background: 'gray', border: 'none' }}
          >
            <QuestionMark /> Ayuda
          </Button>
          <BillingMenus buttonId={0} />
          <br></br>
          <Container style={{ display: 'flex' }}>
            <div style={{ display: 'flex', position: 'absolute', zIndex: 9 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Resolución
                </InputLabel>
                <MuiSelect
                  label="Resolución"
                  onChange={(e) => handleResolutionChange(e.target.value)}
                  value={currentResolution}
                >
                  {resolutions.map((resolution, index) => {
                    return (
                      <MenuItem value={resolution.id} key={index}>
                        {resolution.prefix +
                          ' - ' +
                          resolution.resolutionNumber}
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
              </FormControl>
              <InvoiceFilters
                getFilteredInvoiceByDate={getFilteredInvoiceByDate}
                getFilteredInvoiceByInvoice={getFilteredInvoiceByInvoice}
                getInvoice={getInvoice}
                currentResolution={currentResolution}
              />
            </div>
            <div style={{ height: '50px' }}></div>
          </Container>
          <br></br>
          {resolutionAlerts.map((alert) => {
            return alert
          })}
          <ReportModal
            ModalReportShow={ModalReportShow}
            setModalReportShow={setModalReportShow}
            reportType="invoice"
          />
          {modalCreateShow ? (
            <NewInvoice
              setModalCreateShow={setModalCreateShow}
              isBundled={isBundled}
              successCreation={successCreation}
              resolutionId={currentResolution}
            />
          ) : ModalResendShow ? (
            <ResendDian
              setModalResendShow={setModalResendShow}
              isBundled={isBundled}
              successCreation={successCreation}
              clients={clients}
              bill={invoiceOptions}
            />
          ) : ModalRumbosShow ? (
            <NewRumbos
              setModalRumbosShow={setModalRumbosShow}
              successCreation={successCreation}
              resolutionId={currentResolution}
            />
          ) : (
            <Container>
              {availableForBilling ? (
                <div>
                  <Button
                    variant="primary"
                    style={{
                      backgroundColor: 'green',
                      float: 'left',
                      marginLeft: 5,
                      marginBottom: 10,
                      isolation: 'isolate',
                    }}
                    onClick={() => {
                      setIsBundled(false)
                      setModalCreateShow(true)
                    }}
                    className="create_invoice_button"
                  >
                    Nueva Factura Individual{' '}
                    <CirclePlus size={20} color="#FFFFFF" />
                  </Button>
                  <Button
                    variant="primary"
                    style={{
                      backgroundColor: 'green',
                      float: 'left',
                      marginLeft: 5,
                      marginBottom: 10,
                      isolation: 'isolate',
                    }}
                    onClick={() => {
                      setIsBundled(true)
                      setModalCreateShow(true)
                    }}
                    className="create_bundled_invoice_button"
                  >
                    Nueva Factura Agrupada{' '}
                    <CirclePlus size={20} color="#FFFFFF" />
                  </Button>
                  <Button
                    variant="primary"
                    style={{
                      backgroundColor: 'green',
                      float: 'left',
                      marginLeft: 5,
                      marginBottom: 10,
                      isolation: 'isolate',
                    }}
                    onClick={() => {
                      setModalRumbosShow(true)
                    }}
                    className="create_invoice_button"
                  >
                    Nueva Factura Rumbos{' '}
                    <CirclePlus size={20} color="#FFFFFF" />
                  </Button>
                </div>
              ) : null}
              <Button
                variant="primary"
                style={{
                  backgroundColor: 'green',
                  float: 'right',
                  marginLeft: 5,
                  marginBottom: 10,
                  isolation: 'isolate',
                }}
                onClick={() => {
                  setModalReportShow(true)
                }}
              >
                <Download size={20} color="#FFFFFF" /> Exportar facturas
              </Button>
              <br></br>
              <MDBTable small className="invoices_table">
                <MDBTableHead>
                  <tr>
                    <th
                      scope="col"
                      style={{ textAlign: 'center' }}
                      className="invoices_states"
                    >
                      Estados
                    </th>
                    <th style={{ width: ' 1%', whiteSpace: 'nowrap' }}>
                      Número de factura
                    </th>
                    <th style={{ textAlign: 'center' }}>Cliente</th>
                    <th style={{ textAlign: 'center' }}>Hora de creación</th>
                    <th
                      style={{ textAlign: 'center' }}
                      className="invoices_saleInfo"
                    >
                      Venta Asociada
                    </th>
                    <th
                      style={{
                        width: 'fit-content',
                        textAlign: 'center',
                      }}
                      className="invoices_actions"
                    >
                      Acciones
                    </th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody
                  style={{
                    color: color,
                    backgroundColor: bgColor,
                    isolation: 'isolate',
                  }}
                >
                  {invoice.map((invoice, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {invoice.invoiceDianStatus == 'DIAN_RECHAZADO' ? (
                            <div
                              style={{
                                borderRadius: '7%',
                                backgroundColor: '#EF5350',
                                color: 'white',
                                width: 'fit-content',
                                padding: '0px 3px',
                                margin: '0 10px 0 10px',
                                fontSize: '14px',
                              }}
                            >
                              {invoice.invoiceDianStatus.replace('_', ' ')}
                            </div>
                          ) : (
                            <div
                              style={{
                                borderRadius: '7%',
                                backgroundColor: '#66BB6A',
                                color: 'white',
                                width: 'fit-content',
                                padding: '0px 3px',
                                margin: '0 10px 0 10px',
                                fontSize: '14px',
                              }}
                            >
                              {invoice.invoiceDianStatus.replace('_', ' ')}
                            </div>
                          )}
                          {invoice.bundledSaleId != 0 ? (
                            <div
                              style={{
                                borderRadius: '7%',
                                backgroundColor: 'orange',
                                color: 'white',
                                width: 'fit-content',
                                padding: '0px 3px',
                                margin: '0 10px 0 10px',
                                fontSize: '14px',
                              }}
                            >
                              AGRUPADA
                            </div>
                          ) : null}
                        </td>
                        <td>{invoice.invoiceNumber}</td>

                        <td style={{ textAlign: 'center' }}>
                          {invoice.client}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {invoice.createdAt}
                        </td>
                        <td
                          style={{
                            width: '100px',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <a
                            onClick={() => {
                              invoice.bundledSaleId != 0
                                ? handleShowEditBundledSaleModal(invoice)
                                : showSale(invoice.saleId)
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              style={{
                                float: 'right',
                                float: 'inline-start',
                                alignContent: 'center',
                                cursor: 'pointer',
                                width: '40px',
                              }}
                              src={saleIcon}
                            />
                          </a>
                        </td>
                        <td
                          style={{
                            width: '210px',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <a
                            onClick={() => openInNewTab(invoice.pdfUrls)}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              style={{
                                float: 'right',
                                float: 'inline-start',
                                alignContent: 'center',
                                cursor: 'pointer',
                                width: '40px',
                              }}
                              src={pdf}
                            />
                          </a>

                          <Button
                            style={{ boxShadow: '0px 0px 0px 0px' }}
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e) => handleClick(e, invoice)}
                            variant="secundary"
                          >
                            <DotsVertical color={color}></DotsVertical>
                          </Button>

                          {invoiceOptions != null ? (
                            <MenuMui
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={() => {
                                handleClose()
                              }}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  setModalResendShow(true)
                                  handleClose()
                                }}
                                disabled={
                                  invoiceOptions.invoiceDianStatus ==
                                  'DIAN_RECHAZADO'
                                    ? false
                                    : true
                                }
                              >
                                <img
                                  src={sendDian}
                                  alt="sendDian"
                                  style={{
                                    float: 'right',
                                    float: 'inline-start',
                                    alignContent: 'center',
                                    cursor: 'pointer',
                                    marginRight: '5px',
                                    width: '25px',
                                  }}
                                />
                                Enviar DIAN
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  deleteInvoiceState()
                                  handleClose()
                                }}
                                disabled={
                                  invoiceOptions.invoiceDianStatus ==
                                    'DIAN_RECHAZADO' &&
                                  invoiceOptions.debitNoteDianStatus == '' &&
                                  invoiceOptions.creditNoteDianStatus == ''
                                    ? false
                                    : true
                                }
                              >
                                <Trash
                                  style={{
                                    float: 'right',
                                    float: 'inline-start',
                                    alignContent: 'center',
                                    cursor: 'pointer',
                                    marginRight: '5px',
                                  }}
                                  size={20}
                                  color="black"
                                  strokeWidth={1.2}
                                />
                                Eliminar Factura
                              </MenuItem>
                            </MenuMui>
                          ) : null}
                        </td>
                      </tr>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
              <Container>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Filas
                  </InputLabel>
                  <SelectPages
                    labelId="demo-simple-select-standard-label"
                    autoWidth={true}
                    value={rpg}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </SelectPages>
                </FormControl>
                <Pagination
                  count={Math.ceil(totalInvoiceCount / rpg)}
                  page={pg}
                  onChange={handleChangePage}
                  showFirstButton={true}
                  showLastButton={true}
                  style={{ display: 'inline-block' }}
                />
              </Container>
            </Container>
          )}
          {show ? (
            <SaleModal sales={saleInfo} show={show} onClose={handleCloseSale} />
          ) : null}
          {showEditBundledSaleModal ? (
            <EditBundledSalesModal
              saleId={bundledSale.saleId}
              editable={false}
              thirdClientId={bundledSale.thirdClientId}
              thirdClientCompanyName={bundledSale.client}
              show={showEditBundledSaleModal}
              onClose={handleCloseEditBundledSale}
            />
          ) : null}
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </div>
  )
}
