import React from 'react'
import { Calendar } from 'tabler-icons-react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { toast } from 'react-toastify'

export const notify = (type, message) => {
  toast[type](message)
}

/** 
@description Switch the date format from dd/mm/yyyy to mm/dd/yyyy
@param {string} date Date in format dd/mm/yyyy
@returns {string} Date in format mm/dd/yyyy
**/
export const switchMonthDayDate = (date) => {
  let day = date.substring(0, 2)
  let month = date.substring(3, 5)
  let year = date.substring(6, 10)
  return month + '/' + day + '/' + year
}

/**
 * @description Handle the error message from the API
 * @param {string} apiName Name of the API
 * @param {string} error Error code
 * @param {string} errorText Error message
 * @param {string} apiInfo Additional information about the API result
 **/
export const apiErrorMessageHandler = (
  apiName,
  error,
  errorText,
  apiInfo = '',
) => {
  if (apiInfo !== 'empty result')
    notify('error', `Error interno ${apiName}. ${error} ${errorText}`)
}

export const validateEmail = (email) => {
  if (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  ) {
    return true
  }
  return false
}

/**
 * @description Get the key value of the array value in the typeList for select components with the following data structure {value: any, key: any}
 * @param {Array} typeList The array of objects
 * @param {string} key The key to return
 * @param {any} value the value to search
 * @param {string} valueName The name of the value key in the typeList
 * @returns {string} The key value of the array value in the typeList
 */
export const getTypeKeyByValue = (
  typeList,
  key,
  value,
  valueName = 'value',
) => {
  if (typeList == undefined) return ''
  let type = typeList.find((type) => type[valueName] === value)
  return type == undefined ? '' : type[key]
}

/**
 * @description Get the label of the value in the typeList for select components with the following data structure {value: any, label: string}
 * @param {Array} typeList
 * @param {any} value
 * @returns {string} The label of the value in the typeList
 */

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}))

export const setFixed = (number) => {
  return number.toFixed(2)
}

export const setDecimal = (number, digits = 3) => {
  let numbers = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: digits,
  }).format(number)
  let copToSign = numbers.toString().replace('COP', '')
  return copToSign
}

export default function secondsToTime(e) {
  var h = Math.floor(e / 3600)
      .toString()
      .padStart(1, '0'),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(1, '0'),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0')

  return h + 'h ' + m + 'm'
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

/**
 *
 *
 * @param {Date} date The date to format
 * @param {boolean} withMonthName If true, the month will be displayed with its name, if not, with its number
 * @param {boolean} shortMonthName If true, the month will be displayed with its short name (3 letters)
 * @param {boolean} isFile If true, the date will be formatted to be used in a file name, if not, will be formatted to be displayed in the app.
 *
 * Example: isFile = false -> 30 de septiembre del 2021
 *
 * Example: isFile = true && withMonthName = true -> septiembre-30-2021
 *
 * Example: isFile = true && withMonthName = false -> 2021-09-30
 * @returns {string} The formatted date
 */
export const dateFormat = (
  date,
  withMonthName,
  isFile,
  shortMonthName = false,
) => {
  let fullDate = date.split('/')
  let day = fullDate[0].length == 1 ? '0' + fullDate[0] : fullDate[0]
  let month = ''
  withMonthName
    ? (month = monthFormat(fullDate[1], shortMonthName))
    : (month = fullDate[1].length == 1 ? '0' + fullDate[1] : fullDate[1])
  let year = fullDate[2]
  return !isFile
    ? day + ' ' + month + ' ' + year
    : withMonthName
    ? month + '-' + day + '-' + year
    : year + '-' + month + '-' + day
}

export const dayjsDateToLocale = (date) => {
  return new Date(date).toLocaleDateString('es-CO', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

export const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getDataByStartAndEndFromArray = (array, start, end) => {
  let data = []
  for (let i = start; i < end; i++) {
    if (array[i] != null) {
      data.push(array[i])
    }
  }
  return data
}

export const monthFormat = (month, short = false) => {
  switch (parseInt(month)) {
    case 1:
      return short ? 'ENE' : 'enero'
    case 2:
      return short ? 'FEB' : 'febrero'
    case 3:
      return short ? 'MAR' : 'marzo'
    case 4:
      return short ? 'ABR' : 'abril'
    case 5:
      return short ? 'MAY' : 'mayo'
    case 6:
      return short ? 'JUN' : 'junio'
    case 7:
      return short ? 'JUL' : 'julio'
    case 8:
      return short ? 'AGO' : 'agosto'
    case 9:
      return short ? 'SEP' : 'septiembre'
    case 10:
      return short ? 'OCT' : 'octubre'
    case 11:
      return short ? 'NOV' : 'noviembre'
    case 12:
      return short ? 'DIC' : 'diciembre'
    default:
      return 'Mes invalido'
  }
}

export const getMonthsToDisplay = (year) => {
  let months = []
  let date = new Date()
  if (year == date.getFullYear()) {
    date.setDate(1)
    for (let i = date.getMonth(); i >= 0; --i) {
      months.push({
        monthName: date
          .toLocaleString('default', { month: 'long' })
          .toUpperCase(),
        monthNumber: date.getMonth() + 1,
      })
      date.setMonth(date.getMonth() - 1)
    }
  } else {
    let oldDate = new Date(year, 0, 1)
    for (let i = 0; i < 12; ++i) {
      months.push({
        monthName: oldDate
          .toLocaleString('default', { month: 'long' })
          .toUpperCase(),
        monthNumber: oldDate.getMonth() + 1,
      })
      oldDate.setMonth(oldDate.getMonth() + 1)
    }
  }
  return months
}

export const getYearsToDisplay = () => {
  let years = []
  let date = new Date()
  for (let i = 0; i <= 10; ++i) {
    years.push(date.getFullYear() - i)
  }
  return years
}

export const productUnits = [
  'Spray pequeño',
  'Levantar',
  'Lote calor',
  'Grupo',
  'Equipar',
  'Ración',
  'Disparo',
  'Palo',
  'Tambor de ciento quince kg',
  'Tambor de cien libras',
  'Tambor de cincuenta y cinco galones (us)',
  'Camión cisterna',
  'Contenedor de veinte pies',
  'Contenedor de cuarenta pies',
  'Decilitro por gramo',
  'Gramo por centímetro cúbico',
  'Libra teórica',
  'Gramo por centímetro cuadrado',
  'Tonelada real',
  'Tonelada teórica',
  'Kilogramo por metro cuadrado',
  'Libra por mil pies cuadrados',
  'Día de potencia del caballo por tonelada métrica seca al aire.',
  'Coger peso',
  'Kilogramo por aire seco tonelada métrica',
  'Kilopascales metros cuadrados por gramo',
  'Kilopascales por milímetro',
  'Mililitros por centímetro cuadrado segundo',
  'Pies cúbicos por minuto por pie cuadrado',
  'Onza por pie cuadrado',
  'Onzas por pie cuadrado por 0,01 pulgadas',
  'Mililitro por segundo',
  'Mililitro por minuto',
  'Bolsa súper a granel',
  'Bolsa a granel de quinientos kg',
  'Bolsa a granel de trescientos kg',
  'Bolsa a granel de cincuenta libras',
  'Bolsa de cincuenta libras',
  'Carga de automóviles a granel',
  'Kilogramos teóricos',
  'Tonelada teórica',
  'Sitas',
  'Malla',
  'Kilogramo neto',
  'Parte por millón',
  'Porcentaje de peso',
  'Parte por billón (us)',
  'Porcentaje por 1000 horas',
  'Tasa de fracaso en el tiempo',
  'Libra por pulgada cuadrada, calibre',
  'Oersted',
  'Prueba de escala específica',
  'Cavoltio amperio por librada',
  'Vatio por libra',
  'Amperio tum por centímetro',
  'Milipascal',
  'Gauss',
  'Mili pulgadas',
  'Kilogauss',
  'Libras por pulgada cuadrada absoluta',
  'Enrique',
  'Kilopound por pulgada cuadrada',
  'Fuerza libra pie',
  'Libra por pie cúbico',
  'Equilibrio',
  'Saybold segundo universal',
  'Alimenta',
  'Calorías por centímetro cúbico',
  'Calorías por gramo',
  'Unidad',
  'Veinte mil galones (us) de carros',
  'Diez mil galones (us) de carros',
  'Tambor de diez kg',
  'Tambor de quince kg',
  'Milla de coche',
  'Recuento de coches',
  'Locomotoras',
  'Recuento de cabos',
  'Carro vacio',
  'Millas de tren',
  'Uso de combustible galón (us)',
  'Milla del caboose',
  'Tipo de interés fijo',
  'Tonelada milla',
  'Milla locomotora',
  'Recuento total de coches',
  'Milla de coche total',
  'Cuarto de milla',
  'Radianes por segundo',
  'Radianes por segundo al cuadrado',
  'Röntgen',
  'Unidad térmica británica por hora.',
  'Centímetro cúbico por segundo',
  'Pie cúbico por hora',
  'Pie cúbico por minuto',
  'Centímetro por segundo',
  'Decibel',
  'Kilobyte',
  'Kilobecquerel',
  'Kilocurie',
  'Megagramo',
  'Megagramo por hora',
  'Compartimiento',
  'Metro por minuto',
  'Milliröntgen',
  'Milivoltios',
  'Megajulio',
  'Manmonth',
  'Libra por libra de producto',
  'Libra por pieza de producto',
  'Kilogramo por kilogramo de producto',
  'Kilogramo por pieza de producto',
  'Bobina',
  'Gorra',
  'Centistokes',
  'Paquete de veinte',
  'Microlitro',
  'Micrometro',
  'Miliamperio',
  'Megabyte',
  'Miligramo por hora',
  'Megabecquerel',
  'Microfarad',
  'Newton por metro',
  'Onza pulgada',
  'Pie onza',
  'Picofarad',
  'Libra por hora',
  'Tonelada (us) por hora',
  'Kilolitro por hora',
  'Barril por minuto',
  'Lote',
  'Galón (us) por mil',
  'Mmscf / día',
  'Libras por mil',
  'Bomba',
  'Escenario',
  'Pie cúbico estándar',
  'Potencia hidráulica de caballos',
  'Contar por minuto',
  'Nivel sismico',
  'Infor sismica',
  '15 calorías ca',
  'Amperio metro cuadrado por joule segundo',
  'Ångström',
  'Unidad astronómica',
  'Attojoule',
  'Granero',
  'Granero por electrón voltio',
  'Granero por voltio de electrones esteradiano,',
  'Granero por sterdian',
  'Becquerel por kilogramo',
  'Becquerel por metro cúbico',
  'Amperio por centímetro',
  'Unidad térmica británica por segundo pie cuadrado grado rankin',
  'Unidad térmica británica por libra grado rankin',
  'Unidad térmica británica por segundo pie grado rankin',
  'Unidad térmica británica por hora pie cuadrado grado rankin',
  'Candela por metro cuadrado',
  'Cheval vapeur',
  'Medidor de culombio',
  'Medidor de culombio al cuadrado por voltio',
  'Coulomb por centímetro cúbico',
  'Coulomb por metro cúbico',
  'Amperio por milímetro',
  'Coulomb por milímetro cúbico',
  'Coulomb por kilogramo segundo',
  'Coulomb por mol',
  'Coulomb por centímetro cuadrado',
  'Coulomb por metro cuadrado',
  'Coulomb por milímetro cuadrado',
  'Centímetro cúbico por mol',
  'Información cúbico por mol',
  'Metro cúbico por coulomb',
  'Metro cúbico por kilogramo',
  'Amperio por centímetro cuadrado',
  'Metro cúbico por mol',
  'Amperio por metro cuadrado',
  'Curie por kilogramo',
  'Tonelaje de peso muerto',
  'Decalitro',
  'Decámetro',
  'Decitex',
  'Grado rankin',
  'Negador',
  'Amperio metro cuadrado',
  'Dyn segundo por centímetro cúbico',
  'Dina segundo por centímetro',
  'Dina segundo por centímetro al quinto',
  'Electronvolt',
  'Electronvoltio por metro',
  'Metro electronvolt cuadrado',
  'Electronvoltio de metro cuadrado por kilogramo',
  'Ergio',
  'Erg por centímetro',
  'Amperio por metro cuadrado kelvin al cuadrado',
  'Erg por centímetro cúbico',
  'Erg por gramo',
  'Erg por gramo de segundo',
  'Erg por segundo',
  'Erg por segundo centímetro cuadrado',
  'Erg por centímetro cuadrado segundo',
  'Erg centímetro cuadrado',
  'Ergímetro cuadrado por gramo',
  'Exajulio',
  'Faradio por metro',
  'Amperio por milímetro cuadrado',
  'Femtojoule',
  'Femtometro',
  'Pie por segundo al cuadrado',
  'Pie-fuerza de la libra por segundo',
  'Tonelada de carga',
  'Galón',
  'Unidad de desplazamiento cgs gaussiana',
  'Unidad gaussiana cgs de corriente eléctrica.',
  'Unidad gaussian cgs de carga eléctrica.',
  'Amperio segundo',
  'Unidad gaussian cgs de intensidad de campo eléctrico.',
  'Unidad gaussian cgs de polarización eléctrica.',
  'Unidad gaussian cgs de potencial eléctrico.',
  'Unidad gaussiana cgs de magnetización.',
  'Gigacoulomb por metro cúbico',
  'Gigaelectronvolt',
  'Gigahercios',
  'Gigaohm',
  'Medidor de gigaohm',
  'Gigapascal',
  'Tarifa',
  'Gigavatios',
  'Gon',
  'Gramo por metro cúbico',
  'Gramo por mol',
  'Gris',
  'Gris por segundo',
  'Hectopascal',
  'Henry por metro',
  'Bola',
  'Paquete a granel',
  'Acre',
  'Byte',
  'Amperio por metro',
  'Minuto adicional',
  'Minuto promedio por llamada',
  'Policía',
  'Braza',
  'Informacion de acceso',
  'Ampolla',
  'Hora amperio',
  'Amperio',
  'Año',
  'Solo libra de aluminio',
  'Onza troy o onza de boticarios',
  'Unidad de factor antihemofílico (ahf)',
  'Supositorio',
  'Son',
  'Surtido',
  'Fuerza alcohólica en masa',
  'Fuerza alcohólica por volumen',
  'Ambiente estándar',
  'Ambiente técnico',
  'Cápsula',
  'Vial lleno de polvo',
  'Montaje',
  'Unidad térmica británica por libra',
  'Btu por pie cúbico',
  'Barril (us) por día',
  'Julios por kilogramo kelvin',
  'Julios por metro',
  'Julios por metro cuadrado',
  'Julios por metro a la cuarta potencia',
  'Julios por mol',
  'Julios por mol kelvin',
  'Joule segundo',
  'Litera',
  'Joule metro cuadrado por kilogramo',
  'Kelvin por vatio',
  'Kiloampere',
  'Kiloampere por metro cuadrado',
  'Kiloampere por metro',
  'Kilobecquerel por kilogramo',
  'Kilocoulomb',
  'Kilocoulomb por metro cúbico',
  'Kilocoulomb por metro cuadrado',
  'Kiloelectronvolt',
  'Libra de bateo',
  'Kilogramo metro por segundo',
  'Kilogramo metro cuadrado',
  'Kilogramo metro cuadrado por segundo',
  'Kilogramo por decímetro cúbico',
  'Kilogramo por litro',
  'Caloría termoquímica por gramo',
  'Kilogramo de fuerza',
  'Metro de fuerza de kilogramo',
  'Metro de fuerza de kilogramo por segundo',
  'Barril, imperial',
  'Kilogramo de fuerza por metro cuadrado',
  'Kilojoule per kelvin',
  'Kilojoule por kilogramo',
  'Kilojoule por kilogramo kelvin',
  'Kilojoule por mol',
  'Kilomol',
  'Kilomol por metro cúbico',
  'Kilonewton',
  'Medidor de kilonewton',
  'Kiloohm',
  'Palanquilla',
  'Medidor de kiloohm',
  'Kilopond',
  'Kilosegundo',
  'Kilosiemens',
  'Kilosiemens por metro',
  'Kilovoltios por metro',
  'Kiloveber por metro',
  'Año luz',
  'Litro por mol',
  'Hora lumen',
  'Bollo',
  'Lumen por metro cuadrado',
  'Lumen por vatio',
  'Lumen segundo',
  'Hora de lux',
  'Lux segundo',
  'Maxwell',
  'Megaamperios por metro cuadrado',
  'Megabecquerel por kilogramo',
  'Megacoulomb por metro cúbico',
  'Ciclo',
  'Megacoulomb por metro cuadrado',
  'Megaelectronvolt',
  'Megagramo por metro cúbico',
  'Meganewton',
  'Medidor de meganewton',
  'Megaohm',
  'Metro megaohm',
  'Megasiemens por metro',
  'Megavoltio',
  'Megavolt por metro',
  'Julios por metro cúbico',
  'Metro recíproco cuadrado recíproco segundo',
  'Metro a la cuarta potencia',
  'Microamperios',
  'Microbar',
  'Microcoulomb',
  'Microcoulomb por metro cúbico',
  'Microcoulomb por metro cuadrado',
  'Microfarada por metro',
  'Batt',
  'Microhenry',
  'Microhenry por metro',
  'Micronewton',
  'Medidor de micronewton',
  'Microohm',
  'Medidor de microohmios',
  'Micropascal',
  'Microradiano',
  'Microsegundo',
  'Microsiemens',
  'Bar',
  'Caja base',
  'Tablero',
  'Haz',
  'Pie de tabla',
  'Bolso',
  'Cepillo',
  'Potencia al freno',
  'Trillón de dólares',
  'Cangilón',
  'Cesta',
  'Bala',
  'Barril seco',
  'Barril (ee. uu.) (petróleo, etc.)',
  'Botella',
  'Cien pies de tabla',
  'Becquerel',
  'Bar',
  'Tornillo',
  'Unidad térmica británica',
  'Bushel (ee. uu.)',
  'Bushel (reino unido)',
  'Peso base',
  'Caja',
  'Millones de btus',
  'Llamada',
  'Producto compuesto libra (peso total)',
  'Millifarad',
  'Miligal',
  'Miligramo por metro',
  'Miligray',
  'Milihenry',
  'Milijoule',
  'Milímetro por segundo',
  'Milímetro cuadrado por segundo',
  'Milimol',
  'Mol por kilogramo',
  'Carset',
  'Millinewton',
  'Millinewton por metro',
  'Medidor de miliohm',
  'Segundo milipascal',
  'Miliradian',
  'Milisegundo',
  'Milisiemens',
  'Milisievert',
  'Millitesla',
  'Microvoltios por metro',
  'Milivoltios por metro',
  'Milivatios',
  'Milivatios por metro cuadrado',
  'Milliweber',
  'Topo',
  'Mol por decímetro cúbico',
  'Mol por metro cúbico',
  'Mol por litro',
  'Nanoampere',
  'Partido de carga',
  'Nanocoulomb',
  'Nanofarad',
  'Nanofarad por metro',
  'Nanohenry',
  'Nanohenry por metro',
  'Nanometro',
  'Medidor de nanoohm',
  'Nanosegundo',
  'Nanotesla',
  'Nanovatio',
  'Costo',
  'Neper',
  'Neper por segundo',
  'Picometro',
  'Metro de newton segundo',
  'Newton metro cuadrado kilogramo cuadrado',
  'Newton por metro cuadrado',
  'Newton por milímetro cuadrado',
  'Newton segundo',
  'Newton segundo por metro',
  'Octava',
  'Célula',
  'Ohm centímetro',
  'Ohm metro',
  'Uno',
  'Parsec',
  'Pascal por kelvin',
  'Segundo pascal',
  'Segundo pascal por metro cúbico',
  'Segundo pascal por metro',
  'Petajoule',
  'Telefono',
  'Centipoise',
  'Picoampere',
  'Picocoulomb',
  'Picofarad por metro',
  'Picohenry',
  'Picowatt',
  'Picowatt por metro cuadrado',
  'Medidor de libras',
  'Fuerza de libra',
  'Millicoulomb por kilogramo',
  'Radián',
  'Medidor de radianes al cuadrado por mol',
  'Medidor de radianes al cuadrado por kilogramo',
  'Radian por metro',
  'Ångström recíproco',
  'Metro cúbico recíproco',
  'Metro cúbico recíproco por segundo',
  'Voltios de electrones recíprocos por metro cúbico',
  'Henry recíproco',
  'Grupo de bobina',
  'Joule recíproco por metro cúbico',
  'Kelvin recíproco o kelvin al poder menos uno',
  'Medidor recíproco',
  'Metro cuadrado recíproco',
  'Minuto recíproco',
  'Mole recíproco',
  'Pascal recíproco o pascal a la potencia menos uno',
  'Segundo recíproco',
  'Segundo recíproco por metro cúbico',
  'Segundo recíproco por metro cuadrado',
  'Caja',
  'Capacidad de carga en toneladas métricas',
  'Candela',
  'Grado celsius',
  'Cien',
  'Tarjeta',
  'Centigramo',
  'Envase',
  'Cono',
  'Conector',
  'Coulomb por kilogramo',
  'Bobina',
  'Cientos de licencia',
  'Centilitro',
  'Centímetro cuadrado',
  'Centímetro cúbico',
  'Centímetro',
  'Paquete de cien',
  'Cental (reino unido)',
  'Garrafón',
  'Culombio',
  'Cartucho',
  'Caja',
  'Caso',
  'Caja de cartón',
  'Quilate métrico',
  'Vaso',
  'Curie',
  'Cubrir',
  'Cien libras (quintales) / cien pesos (us)',
  'Cien pesos (reino unido)',
  'Cilindro',
  'Combo',
  'Segundo recíproco por esteradiano',
  'Siemens por metro',
  'Siemens metro cuadrado por mol',
  'Sievert',
  'Mil yardas lineales',
  'Sone',
  'Centímetro cuadrado por ergio',
  'Centímetro cuadrado por erg esterlina',
  'Metro kelvin',
  'Kelvin metro cuadrado por vatio',
  'Segundo recíproco por metros cuadrados esteradianos',
  'Metro cuadrado por julio',
  'Metro cuadrado por kilogramo',
  'Metro cuadrado por mol',
  'Pluma gramo (proteína)',
  'Metro cuadrado por esterilizador',
  'Metro cuadrado por julios esteradianos',
  'Metro cuadrado por voltio segundo',
  'Esteradiano',
  'Sifón',
  'Terahercios',
  'Terajulio',
  'Teravatio',
  'Hora de teravatio',
  'Tesla',
  'Texas',
  'Caloría termoquímica',
  'Caloría termoquímica por gramo kelvin',
  'Calorías termoquímicas por segundo centímetro kelvin',
  'Calorías termoquímicas por segundo centímetro cuadrado kelvin',
  'Mil litros',
  'Tonelada por metro cúbico',
  'Año tropical',
  'Unidad de masa atómica unificada',
  'Var',
  'Voltios al cuadrado por kelvin al cuadrado',
  'Voltio – amperio',
  'Voltio por centímetro',
  'Voltio por kelvin',
  'Milivoltios por kelvin',
  'Kilogramo por centímetro cuadrado',
  'Voltios por metro',
  'Voltios por milímetro',
  'Vatios por kelvin',
  'Vatios por metro kelvin',
  'Vatios por metro cuadrado',
  'Vatios por metro cuadrado kelvin',
  'Vatios por metro cuadrado de kelvin a la cuarta potencia',
  'Vatios por steradian',
  'Vatios por metro cuadrado esterlino',
  'Weber por metro',
  'Röntgen por segundo',
  'Weber por milímetro',
  'Minuto',
  'Segundo',
  'Libro',
  'Bloquear',
  'Redondo',
  'Casete',
  'Dólar por hora',
  'Pulgada a la cuarta potencia',
  'Sandwich',
  'Tabla internacional (it) caloría',
  'Tabla internacional (it) calorías por segundo centímetro kelvin',
  'Tabla internacional (it) calorías por segundo centímetro cuadrado kelvin',
  'Joule metro cuadrado',
  'Kilogramo por mol',
  'Tabla internacional (it) calorías por gramo',
  'Tabla internacional (it) calorías por gramo kelvin',
  'Megacoulomb',
  'Haz',
  'Puntaje de drenaje',
  'Microwatt',
  'Microtesla',
  'Microvoltio',
  'Medidor de millinewton',
  'Microwatt por metro cuadrado',
  'Millicoulomb',
  'Milimol por kilogramo',
  'Millicoulomb por metro cúbico',
  'Millicoulomb por metro cuadrado',
  'Dina por centímetro cuadrado',
  'Metro cúbico (neto)',
  'Movimiento rápido del ojo',
  'Banda',
  'Segundo por metro cúbico',
  'Segundo por metro cúbico radianes',
  'Julios por gramo',
  'Libra bruta',
  'Carga de palet / unidad',
  'Libra de masa',
  'Manga',
  'Despreciar',
  'Diez dias',
  'Día',
  'Libra seca',
  'Disco',
  'La licenciatura',
  'Acuerdo',
  'Década',
  'Decigramo',
  'Dispensador',
  'Decagramo',
  'Decilitro',
  'Informacion cuadrado',
  'Decímetro cúbico',
  'Decímetro',
  'Medidor de decinewton',
  'Docena pieza',
  'Docena par',
  'Tonelaje de desplazamiento',
  'Registro de datos',
  'Tambor',
  'Dram (us)',
  'Dram (reino unido)',
  'Docena rollo',
  'Dracma (reino unido)',
  'Monitor',
  'Tonelada seca',
  'Decitonne',
  'Dina',
  'Pennyweight',
  'Dina por centímetro',
  'Libro de directorio',
  'Docena',
  'Paquete de doce',
  'Cinturón',
  'Remolque',
  'Kilogramo bruto',
  'Tonelada métrica larga',
  'Cada',
  'Casilla de correo electrónico',
  'Cada uno por mes',
  'Paquete de once',
  'Galón equivalente',
  'Sobre',
  'Mil pies cúbicos por día',
  'Fibra por centímetro cúbico de aire',
  'Grado fahrenheit',
  'Faradio',
  'Campo',
  'Mil pies cúbicos',
  'Millón de partículas por pie cúbico',
  'Pie de pista',
  'Cien metros cúbicos',
  'Parche transdérmico',
  'Micromol',
  'Tonelada en escamas',
  'Millones de pies cúbicos',
  'Pie',
  'Libra por pie cuadrado',
  'Pie por minuto',
  'Pie por segundo',
  'Pie cuadrado',
  'Pie cubico',
  'Us galones por minuto',
  'Galon imperial por minuto',
  'Hoja de microficha',
  'Galón (us) por día',
  'Gigabecquerel',
  'Gramo por 100 gramo',
  'Barril bruto',
  'Libra por galón (us)',
  'Gramo por metro (gramo por 100 centímetros)',
  'Gramo de isótopo fisionable',
  'Gramo',
  'Medio galón (ee. uu.)',
  'Branquias',
  'Gill (reino unido)',
  'Gramo por mililitro',
  'Gramo por kilogramo',
  'Gramo por litro',
  'Galón seco (ee. uu.)',
  'Galón (reino unido)',
  'Galón',
  'Gramo por metro cuadrado',
  'Galón bruto',
  'Miligramos por metro cuadrado',
  'Miligramo por metro cúbico',
  'Microgramos por metro cúbico',
  'Gramo',
  'Grano',
  'Bruto',
  'Tonelada de registro bruto',
  'Tonelada bruta',
  'Gigajoule',
  'Galón por mil pies cúbicos',
  'Hora de gigavatios',
  'Patio bruto',
  'Sistema de medición',
  'Media página – electrónica',
  'Medio litro',
  'Madeja',
  'Hectárea',
  'Hectobar',
  'Cien cajas',
  'Cien cuentas',
  'Media docena',
  'Centésima de quilate',
  'Cien pies',
  'Hectogramo',
  'Cien pies cúbicos',
  'Cien hojas',
  'Cien unidades internacionales',
  'Caballo métrico',
  'Cien kilogramos',
  'Cien pies (lineales)',
  'Hectolitro',
  'Milla por hora',
  'Millones de metros cúbicos',
  'Hectómetro',
  'Milímetro convencional de mercurio',
  'Cien onzas troy',
  'Milímetro convencional de agua',
  'Hectolitro de alcohol puro',
  'Cien pies cuadrados',
  'Media hora',
  'Hertz',
  'Contar por pulgada',
  'Persona',
  'Pulgadas de agua',
  'Columna pulgada',
  'Pulgada por minuto',
  'Impresión',
  'Pulgada',
  'Pulgada cuadrada',
  'Pulgada en cubos',
  'Póliza de seguros',
  'Conteo por centímetro',
  'Pulgada por segundo (velocidad lineal)',
  'Pulgada por segundo al cuadrado (aceleración)',
  'Julios por kilogramo',
  'Jumbo',
  'Joule por kelvin',
  'Jarra',
  'Megajulio por kilogramo',
  'Megajulio por metro cúbico',
  'Articulación',
  'Joule',
  'Tarro',
  'Demanda de kilovatios',
  'Kilovoltios amperios reactivos de demanda',
  'Kilovoltio amperio hora reactiva',
  'Amperios kilovoltios (reactivos)',
  'Kilolitro',
  'Pastel',
  'Kilocharacter',
  'Kilobar',
  'Kilogramo decimal',
  'Kelvin',
  'Kilopacket',
  'Barrilete',
  'Kilogramo',
  'Kilogramo por segundo',
  'Kilohercio',
  'Kilogramo por milímetro de ancho',
  'Kilosegmento',
  'Kilojoule',
  'Kilogramo por metro',
  'Kilómetro por hora',
  'Kilometro cuadrado',
  'Kilogramo por metro cúbico',
  'Kilogramo de nitrógeno',
  'Kilogramo de sustancia nombrada',
  'Nudo',
  'Milliequivalencia de potasa cáustica por gramo de producto',
  'Kilopascal',
  'Kilogramo de hidróxido de potasio (potasa cáustica)',
  'Kilogramo de óxido de potasio',
  'Kilogramo de pentóxido de fósforo (anhídrido fosfórico)',
  'Kiloröntgen',
  'Mil libras por pulgada cuadrada',
  'Kilogramo de sustancia 90% seca',
  'Kilogramo de hidróxido de sodio (soda cáustica)',
  'Equipo',
  'Kilómetro',
  'Kilotonne',
  'Kilogramo de uranio',
  'Kilovoltio – ampere',
  'Kilovar',
  'Kilovoltio',
  'Kilogramos por milímetro',
  'Kilovatios hora',
  'Kilovatio',
  'Mililitro por kilogramo',
  'Litro por minuto',
  'Libra por pulgada cúbica',
  'Libra',
  'Libra troy',
  'Centímetro lineal',
  'Litro por día',
  'Lite',
  'Hoja',
  'Pie lineal',
  'Hora de trabajo',
  'Pulgada lineal',
  'Spray grande',
  'Enlazar',
  'Metro lineal',
  'Longitud',
  'Mucho',
  'Libra liquida',
  'Litro de alcohol puro',
  'Capa',
  'Suma global',
  'Ton (reino unido) o longton (ee. uu.)',
  'Litro',
  'Lumen',
  'Lux',
  'Yarda lineal por libra',
  'Yarda lineal',
  'Cinta magnética',
  'Miligramos por litro',
  'Valor monetario',
  'Microcurie',
  'Micropulgada',
  'Millones de btu por 1000 pies cúbicos',
  'Máquina por unidad',
  'Mega litro',
  'Megametro',
  'Megavatio',
  'Mil equivalentes de ladrillo estándar',
  'Mil pies de tabla',
  'Milibar',
  'Microgramo',
  'Milicurie',
  'Aire seco tonelada métrica',
  'Miligramo por pie cuadrado por lado',
  'Miligramo',
  'Milla cuadrada',
  'Mil',
  'Minuto',
  'Millones de unidades internacionales',
  'Miligramo por pulgada cuadrada',
  'Mil millones',
  'Mililitro',
  'Milímetro cuadrado',
  'Milímetro cúbico',
  'Milímetro',
  'Mes',
  'Megapascal',
  'Mil metros',
  'Metro cúbico por hora',
  'Metro cúbico por segundo',
  'Metro por segundo al cuadrado',
  'Estera',
  'Metro cuadrado',
  'Metro cúbico',
  'Metro',
  'Metro por segundo',
  'Numero de mults',
  'Megavolt – ampere',
  'Megavatios hora (1000 kw.h)',
  'Calorías de la pluma',
  'Número de líneas',
  'Punto de impresión',
  'Miligramo por kilogramo',
  'Número de artículos',
  'Barcaza',
  'Número de bobinas',
  'Coche',
  'Número de celdas',
  'Barril neto',
  'Litro neto',
  'Newton',
  'Mensaje',
  'Galón neto (nosotros)',
  'Hora del mensaje',
  'Galón imperial neto',
  'Número de unidades internacionales',
  'Número de pantallas',
  'Carga',
  'Milla nautica',
  'Número de paquetes',
  'Entrenar',
  'Número de parcelas',
  'Numero de pares',
  'Numero de partes',
  'Mho',
  'Micromho',
  'Número de rollos',
  'Tonelada neta',
  'Registro neto de toneladas',
  'Medidor de newton',
  'Vehículo',
  'Parte por mil',
  'Libra por aire seco tonelada métrica',
  'Panel',
  'Ohm',
  'Onza por yarda cuadrada',
  'Onza',
  'Dos paquetes',
  'Hora extra',
  'Onza av',
  'Onza líquida (us)',
  'Onza líquida (reino unido)',
  'Pagina – electronica',
  'Por ciento',
  'Libra por pie',
  'Paquete de tres',
  'Paquete de cuatro',
  'Paquete de cinco',
  'Paquete de seis',
  'Paquete de siete',
  'Paquete de ocho',
  'Paquete de nueve',
  'Paquete',
  'Pascal',
  'Par de pulgadas',
  'Almohadilla',
  'Equivalente en libras',
  'Palet (ascensor)',
  'Plato',
  'Tono',
  'Paquete',
  'Cubo',
  'Porcentaje de libra',
  'Libra neta',
  'Libra por pulgada de longitud',
  'Página por pulgada',
  'Par',
  'Fuerza de libra por pulgada cuadrada',
  'Pinta',
  'Pinta seca',
  'Pinta (reino unido)',
  'Pinta liquida (us)',
  'Bandeja / paquete de bandeja',
  'Media pinta (us)',
  'Libra por pulgada de ancho',
  'Peck dry (us)',
  'Peck dry (reino unido)',
  'Comida',
  'Página – facsímil',
  'Cuarto (de un año)',
  'Página – copia impresa',
  'Cuarto de docena',
  'Un cuarto de hora',
  'Cuarto de kilogramo',
  'Mano de papel',
  'Cuarto de galón (us)',
  'Cuarto seco (ee. uu.)',
  'Cuarto de galón (reino unido)',
  'Cuarto líquido (us)',
  'Cuarto (uk)',
  'Pica',
  'Caloría',
  'Mil metros cúbicos',
  'Estante',
  'Barra',
  'Anillo',
  'Hora de funcionamiento o de funcionamiento',
  'Medida métrica rollo',
  'Carrete',
  'Resma',
  'Medida métrica de resma',
  'Rodar',
  'Libra por resma',
  'Revoluciones por minuto',
  'Revoluciones por segundo',
  'Reiniciar',
  'Ingreso tonelada milla',
  'Correr',
  'Pie cuadrado por segundo',
  'Metro cuadrado por segundo',
  'Sesenta cuartos de pulgada',
  'Sesión',
  'Unidad de almacenamiento',
  'Unidad de publicidad estándar',
  'Saco',
  'Medio año (6 meses)',
  'Puntuación',
  'Escrúpulo',
  'Libra solida',
  'Sección',
  'Segundo',
  'Conjunto',
  'Segmento',
  'Tonelada de envío',
  'Siemens',
  'Camión cisterna dividido',
  'Hoja de deslizamiento',
  'Milla (milla estatutaria)',
  'Varilla cuadrada',
  'Carrete',
  'Paquete de estante',
  'Cuadrado',
  'Tira',
  'Hoja métrica medida',
  'Corto estándar (7200 partidos)',
  'Hoja',
  'Piedra (reino unido)',
  'Tonelada (us) o tonelada corta (uk / us)',
  'Patinar',
  'Madeja',
  'Envío',
  'Línea de telecomunicaciones en servicio',
  'Mil libras brutas',
  'Mil piezas',
  'Bolsa de mil',
  'Caja de mil',
  'Mil galones (us)',
  'Mil impresiones',
  'Mil pulgadas lineales',
  'Décimo pie cúbico',
  'Kiloampere hora (mil amperios hora)',
  'Camion',
  'Termia',
  'Totalizador',
  'Diez metros cuadrados',
  'Mil pulgadas cuadradas',
  'Mil centímetros cuadrados',
  'Tanque, rectangular',
  'Mil pies (lineales)',
  'Estaño',
  'Tonelada (tonelada métrica)',
  'Paquete de diez',
  'Diez pares',
  'Mil pies',
  'Mil metros cúbicos por día',
  'Diez pies cuadrados',
  'Trillón (eur)',
  'Mil pies cuadrados',
  'Tonelada de sustancia 90% seca',
  'Tonelada de vapor por hora',
  'Mil metros lineales',
  'Tubo',
  'Mil kilogramos',
  'Mil hojas',
  'Tanque, cilíndrico',
  'Tratamiento',
  'Tableta',
  'Torr',
  'Línea de telecomunicaciones en servicio promedio',
  'Puerto de telecomunicaciones',
  'Décimo minuto',
  'Décima hora',
  'Uso por línea de telecomunicación promedio',
  'Diez mil yardas',
  'Millones de unidades',
  'Voltio amperio por kilogramo',
  'Frasco',
  'Voltio',
  'Abultar',
  'Visitar',
  'Kilo mojado',
  'Dos semanas',
  'Vatio por kilogramo',
  'Libra mojada',
  'Cable',
  'Tonelada mojada',
  'Weber',
  'Semana',
  'Galon de vino',
  'Rueda',
  'Vatios hora',
  'Peso por pulgada cuadrada',
  'Mes de trabajo',
  'Envolver',
  'Estándar',
  'Vatio',
  'Mililitro de agua',
  'Cadena',
  'Yarda cuadrada',
  'Yarda cúbica',
  'Cien yardas lineales',
  'Yarda',
  'Diez yardas',
  'Van de elevación',
  'Pecho',
  'Barril',
  'Pipa',
  'Arrastrar',
  'Punto de conferencia',
  'Línea de noticias de ágata',
  'Página',
  'Mutuamente definido',
]

export const units = [
  '04',
  '05',
  '08',
  '10',
  '11',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '40',
  '41',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '53',
  '54',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '66',
  '69',
  '71',
  '72',
  '73',
  '74',
  '76',
  '77',
  '78',
  '80',
  '81',
  '84',
  '85',
  '87',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '1A',
  '1B',
  '1C',
  '1D',
  '1E',
  '1F',
  '1G',
  '1H',
  '1I',
  '1J',
  '1K',
  '1L',
  '1M',
  '1X',
  '2ª',
  '2B',
  '2C',
  '2I',
  '2J',
  '2K',
  '2L',
  '2M',
  '2N',
  '2P',
  '2Q',
  '2R',
  '2U',
  '2V',
  '2W',
  '2X',
  '2Y',
  '2Z',
  '3B',
  '3C',
  '3E',
  '3G',
  '3H',
  '3I',
  '4A',
  '4B',
  '4C',
  '4E',
  '4G',
  '4H',
  '4K',
  '4L',
  '4M',
  '4N',
  '4O',
  '4P',
  '4Q',
  '4R',
  '4T',
  '4U',
  '4W',
  '4X',
  '5A',
  '5B',
  '5C',
  '5E',
  '5F',
  '5G',
  '5H',
  '5I',
  '5J',
  '5K',
  '5P',
  '5Q',
  'A1',
  '10',
  'A11',
  'A12',
  'A13',
  'A14',
  'A15',
  'A16',
  'A17',
  'A18',
  'A19',
  'A2',
  'A20',
  'A21',
  'A22',
  'A23',
  'A24',
  'A25',
  'A26',
  'A27',
  'A28',
  'A29',
  'A3',
  'A30',
  'A31',
  'A32',
  'A33',
  'A34',
  'A35',
  'A36',
  'A37',
  'A38',
  'A39',
  'A4',
  'A40',
  'A41',
  'A42',
  'A43',
  'A44',
  'A45',
  'A47',
  'A48',
  'A49',
  'A5',
  'A50',
  'A51',
  'A52',
  'A53',
  'A54',
  'A55',
  'A56',
  'A57',
  'A58',
  'A6',
  'A60',
  'A61',
  'A62',
  'A63',
  'A64',
  'A65',
  'A66',
  'A67',
  'A68',
  'A69',
  'A7',
  'A70',
  'A71',
  'A73',
  'A74',
  'A75',
  'A76',
  'A77',
  'A78',
  'A79',
  'A8',
  'A80',
  'A81',
  'A82',
  'A83',
  'A84',
  'A85',
  'A86',
  'A87',
  'A88',
  'A89',
  'A9',
  'A90',
  'A91',
  'A93',
  'A94',
  'A95',
  'A96',
  'A97',
  'A98',
  'AA',
  'AB',
  'ACR',
  'AD',
  'AE',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AMH',
  'AMP',
  'ANA',
  'AP',
  'APZ',
  'AQ',
  'AR',
  'SON',
  'COMO',
  'ASM',
  'ASU',
  'ATM',
  'ATT',
  'AV',
  'AW',
  'SÍ',
  'AZ',
  'B0',
  'B1',
  'B11',
  'B12',
  'B13',
  'B14',
  'B15',
  'B16',
  'B18',
  'B2',
  'B20',
  'B21',
  'B22',
  'B23',
  'B24',
  'B25',
  'B26',
  'B27',
  'B28',
  'B29',
  'B3',
  'B31',
  'B32',
  'B33',
  'B34',
  'B35',
  'B36',
  'B37',
  'B38',
  'B39',
  'B4',
  'B40',
  'B41',
  'B42',
  'B43',
  'B44',
  'B45',
  'B46',
  'B47',
  'B48',
  'B49',
  'B5',
  'B50',
  'B51',
  'B52',
  'B53',
  'B54',
  'B55',
  'B56',
  'B57',
  'B58',
  'B59',
  'B6',
  'B60',
  'B61',
  'B62',
  'B63',
  'B64',
  'B65',
  'B66',
  'B67',
  'B69',
  'B7',
  'B70',
  'B71',
  'B72',
  'B73',
  'B74',
  'B75',
  'B76',
  'B77',
  'B78',
  'B79',
  'B8',
  'B81',
  'B83',
  'B84',
  'B85',
  'B86',
  'B87',
  'B88',
  'B89',
  'B9',
  'B90',
  'B91',
  'B92',
  'B93',
  'B94',
  'B95',
  'B96',
  'B97',
  'B98',
  'B99',
  'BAR',
  'BB',
  'BD',
  'SER',
  'BFT',
  'BG',
  'BH',
  'BHP',
  'BIL',
  'BJ',
  'BK',
  'BL',
  'BLD',
  'BLL',
  'BO',
  'BP',
  'BQL',
  'BR',
  'BT',
  'BTU',
  'BUA',
  'BUI',
  'BW',
  'BX',
  'BZ',
  'C0',
  'C1',
  'C10',
  'C11',
  'C12',
  'C13',
  'C14',
  'C15',
  'C16',
  'C17',
  'C18',
  'C19',
  'C2',
  'C20',
  'C22',
  'C23',
  'C24',
  'C25',
  'C26',
  'C27',
  'C28',
  'C29',
  'C3',
  'C30',
  'C31',
  'C32',
  'C33',
  'C34',
  'C35',
  'C36',
  'C38',
  'C39',
  'C4',
  'C40',
  'C41',
  'C42',
  'C43',
  'C44',
  'C45',
  'C46',
  'C47',
  'C48',
  'C49',
  'C5',
  'C50',
  'C51',
  'C52',
  'C53',
  'C54',
  'C55',
  'C56',
  'C57',
  'C58',
  'C59',
  'C6',
  'C60',
  'C61',
  'C62',
  'C63',
  'C64',
  'C65',
  'C66',
  'C67',
  'C68',
  'C69',
  'C7',
  'C70',
  'C71',
  'C72',
  'C73',
  'C75',
  'C76',
  'C77',
  'C78',
  'C8',
  'C80',
  'C82',
  'C83',
  'C84',
  'C85',
  'C86',
  'C87',
  'C88',
  'C89',
  'C9',
  'C90',
  'C91',
  'C92',
  'C93',
  'C94',
  'C95',
  'C96',
  'C97',
  'C98',
  'C99',
  'CA',
  'CCT',
  'CDL',
  'CEL',
  'CEN',
  'CG',
  'CGM',
  'CH',
  'CJ',
  'CK',
  'CKG',
  'CL',
  'CLF',
  'CLT',
  'CMK',
  'CMQ',
  'CMT',
  'CNP',
  'CNT',
  'CO',
  'COU',
  'CQ',
  'CR',
  'CS',
  'CT',
  'CTM',
  'CU',
  'CUR',
  'CV',
  'CWA',
  'CWI',
  'CY',
  'CZ',
  'D1',
  'D10',
  'D12',
  'D13',
  'D14',
  'D15',
  'D16',
  'D17',
  'D18',
  'D19',
  'D2',
  'D20',
  'D21',
  'D22',
  'D23',
  'D24',
  'D25',
  'D26',
  'D27',
  'D28',
  'D29',
  'D30',
  'D31',
  'D32',
  'D33',
  'D34',
  'D35',
  'D37',
  'D38',
  'D39',
  'D40',
  'D41',
  'D42',
  'D43',
  'D44',
  'D45',
  'D46',
  'D47',
  'D48',
  'D49',
  'D5',
  'D50',
  'D51',
  'D52',
  'D53',
  'D54',
  'D55',
  'D56',
  'D57',
  'D58',
  'D59',
  'D6',
  'D60',
  'D61',
  'D62',
  'D63',
  'D64',
  'D65',
  'D66',
  'D67',
  'D69',
  'D7',
  'D70',
  'D71',
  'D72',
  'D73',
  'D74',
  'D75',
  'D76',
  'D77',
  'D79',
  'D8',
  'D80',
  'D81',
  'D82',
  'D83',
  'D85',
  'D86',
  'D87',
  'D88',
  'D89',
  'D9',
  'D90',
  'D91',
  'D92',
  'D93',
  'D94',
  'D95',
  'D96',
  'D97',
  'D98',
  'D99',
  'DAA',
  'DAD',
  'DAY',
  'DB',
  'DC',
  'DD',
  'DE',
  'DEC',
  'DG',
  'DI',
  'DJ',
  'DLT',
  'DMK',
  'DMQ',
  'DMT',
  'DN',
  'DPC',
  'DPR',
  'DPT',
  'DQ',
  'DR',
  'DRA',
  'DRI',
  'DRL',
  'DRM',
  'DS',
  'DT',
  'DTN',
  'DU',
  'DWT',
  'DX',
  'DY',
  'DZN',
  'DZP',
  'E2',
  'E3',
  'E4',
  'E5',
  'EA',
  'EB',
  'CE',
  'EP',
  'EQ',
  'EV',
  'F1',
  'F9',
  'FAH',
  'FAR',
  'FB',
  'FC',
  'FD',
  'FE',
  'FF',
  'FG',
  'FH',
  'FL',
  'FM',
  'FOT',
  'FP',
  'FR',
  'FS',
  'FTK',
  'FTQ',
  'G2',
  'G3',
  'G7',
  'GB',
  'GBQ',
  'GC',
  'GD',
  'GE',
  'GF',
  'GFI',
  'GGR',
  'GH',
  'GIA',
  'GII',
  'GJ',
  'GK',
  'GL',
  'GLD',
  'GLI',
  'GLL',
  'GM',
  'GN',
  'GO',
  'GP',
  'GQ',
  'GRM',
  'GRN',
  'GRO',
  'GRT',
  'GT',
  'GV',
  'GW',
  'GWH',
  'GY',
  'GZ',
  'H1',
  'H2',
  'HA',
  'HAR',
  'HBA',
  'HBX',
  'HC',
  'HD',
  'ÉL',
  'HF',
  'HGM',
  'HH',
  'HI',
  'HIU',
  'HJ',
  'HK',
  'HL',
  'HLT',
  'HM',
  'HMQ',
  'HMT',
  'HN',
  'HO',
  'HP',
  'HPA',
  'HS',
  'HT',
  'HTZ',
  'IC',
  'IE',
  'IF',
  'II',
  'IL',
  'IM',
  'INH',
  'INK',
  'INQ',
  'IP',
  'IT',
  'IU',
  'IV',
  'J2',
  'JB',
  'JE',
  'JG',
  'JK',
  'JM',
  'JO',
  'JOU',
  'JR',
  'K1',
  'K2',
  'K3',
  'K5',
  'K6',
  'KA',
  'KB',
  'KBA',
  'KD',
  'KEL',
  'KF',
  'KG',
  'KGM',
  'KGS',
  'KHZ',
  'KI',
  'KJ',
  'KJO',
  'KL',
  'KMH',
  'KMK',
  'KMQ',
  'KNI',
  'KNS',
  'KNT',
  'KO',
  'KPA',
  'KPH',
  'KPO',
  'KPP',
  'KR',
  'KS',
  'KSD',
  'KSH',
  'KT',
  'KTM',
  'KTN',
  'KUR',
  'KVA',
  'KVR',
  'KVT',
  'KW',
  'KWH',
  'KWT',
  'KX',
  'L2',
  'LA',
  'LBR',
  'LBT',
  'LC',
  'LD',
  'LE',
  'LEF',
  'LF',
  'LH',
  'LI',
  'LJ',
  'LK',
  'LM',
  'LN',
  'LO',
  'LP',
  'LPA',
  'LR',
  'LS',
  'LTN',
  'LTR',
  'LUM',
  'LUX',
  'LX',
  'LY',
  'M0',
  'M1',
  'M4',
  'M5',
  'M7',
  'M9',
  'MA',
  'MAL',
  'MAM',
  'MAW',
  'MBE',
  'MBF',
  'MBR',
  'MC',
  'MCU',
  'MD',
  'MF',
  'MGM',
  'MIK',
  'MIL',
  'MIN',
  'MIU',
  'MK',
  'MLD',
  'MLT',
  'MMK',
  'MMQ',
  'MMT',
  'LUN',
  'MPA',
  'MQ',
  'MQH',
  'MQS',
  'MSK',
  'MT',
  'MTK',
  'MTQ',
  'MTR',
  'MTS',
  'MV',
  'MVA',
  'MWH',
  'N1',
  'N2',
  'N3',
  'NA',
  'NAR',
  'NB',
  'NBB',
  'NC',
  'NCL',
  'ND',
  'NE',
  'NEW',
  'NF',
  'NG',
  'NH',
  'NI',
  'NIU',
  'NJ',
  'NL',
  'MNI',
  'NMP',
  'NN',
  'NPL',
  'NPR',
  'TNP',
  'NQ',
  'NR',
  'NRL',
  'NT',
  'NTT',
  'NU',
  'NV',
  'NX',
  'NY',
  'OA',
  'OHM',
  'EN',
  'ONZ',
  'OP',
  'OT',
  'ONZ',
  'OZA',
  'OZI',
  'P0',
  'P1',
  'P2',
  'P3',
  'P4',
  'P5',
  'P6',
  'P7',
  'P8',
  'P9',
  'PA',
  'PAL',
  'PB',
  'PD',
  'PE',
  'PF',
  'PG',
  'Pi',
  'PK',
  'PL',
  'PM',
  'PN',
  'PO',
  'PQ',
  'PR',
  'PD',
  'PT',
  'PTD',
  'PTI',
  'PTL',
  'PU',
  'PV',
  'PW',
  'PY',
  'PZ',
  'Q3',
  'QA',
  'QAN',
  'QB',
  'QD',
  'QH',
  'QK',
  'QR',
  'QT',
  'QTD',
  'QTI',
  'QTL',
  'QTR',
  'R1',
  'R4',
  'R9',
  'RA',
  'RD',
  'RG',
  'RH',
  'RK',
  'RL',
  'RM',
  'RN',
  'RO',
  'RP',
  'RPM',
  'RPS',
  'RS',
  'RT',
  'RU',
  'S3',
  'S4',
  'S5',
  'S6',
  'S7',
  'S8',
  'SA',
  'SAN',
  'OCS',
  'SCR',
  'SD',
  'SE',
  'SEC',
  'SET',
  'SG',
  'SHT',
  'SIE',
  'SK',
  'SL',
  'SMI',
  'SN',
  'SO',
  'SP',
  'SQ',
  'SR',
  'SS',
  'SST',
  'ST',
  'ITS',
  'STN',
  'SV',
  'SO',
  'SX',
  'T0',
  'T1',
  'T3',
  'T4',
  'T5',
  'T6',
  'T7',
  'T8',
  'TA',
  'TAH',
  'TC',
  'TD',
  'TE',
  'TF',
  'TI',
  'TJ',
  'TK',
  'TL',
  'TN',
  'TNE',
  'TP',
  'TPR',
  'TQ',
  'TQD',
  'TR',
  'TRL',
  'TS',
  'TSD',
  'TSH',
  'TT',
  'TU',
  'TV',
  'TW',
  'TY',
  'U1',
  'U2',
  'UA',
  'UB',
  'UC',
  'UD',
  'UE',
  'UF',
  'UH',
  'UM',
  'VA',
  'VI',
  'VLT',
  'VQ',
  'VS',
  'W2',
  'W4',
  'WA',
  'WB',
  'WCD',
  'WE',
  'WEB',
  'WEE',
  'WG',
  'WH',
  'WHR',
  'WI',
  'WM',
  'WR',
  'WSD',
  'WTT',
  'WW',
  'X1',
  'YDK',
  'YDQ',
  'YL',
  'YRD',
  'YT',
  'Z1',
  'Z2',
  'Z3',
  'Z4',
  'Z5',
  'Z6',
  'Z8',
  'ZP',
  'ZZ',
]
