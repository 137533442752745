import React, { useState, useEffect } from 'react'
import useStore from '../../../components/zustand'
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
} from '@mui/material'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { getTypeKeyByValue, notify } from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { validateEmail } from '../../../services/utils'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import { MDBTabsContent, MDBTabsPane } from 'mdb-react-ui-kit'
import { UpdateClientUsersApi } from '../../../pages/api/clientUsers'
import { ButtonGroup, ButtonToolbar, Container } from 'react-bootstrap'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  areaTypes,
  compensationTypes,
  contractTypes,
  documentTypes,
  epsTypes,
  layoffTypes,
  paymentMeansTypes,
  pensionTypes,
  periodicityTypes,
  riskTypes,
  workerTypes,
} from '../../../services/selectData/clientUsersData'
import {
  countriesData,
  departmentsData,
} from '../../../services/selectData/placesData'
import { apilUrl } from '../../../services/url'
import CustomSelect from '../../custom/customSelect'
import CurrencyInput from '../../custom/customCurrencyInput'

const EditClientUser = ({
  onClose,
  getClientUsers,
  clientUserToUpdate,
  branches,
}) => {
  const { tokenData, isDarkModeActive } = useStore()

  //Clients Branch id
  const [branchId, setBranchId] = useState(0)
  const [citiesOptions, setCitiesOptions] = React.useState([])

  const getMunicipios = async (department) => {
    infoForm.setFieldValue('idDepartment', department.value)
    try {
      let result = await fetch(
        `${apilUrl}/municipios/filters/${department.value}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      setCitiesOptions(result.data)
    } catch (error) {
      return error
    }
  }

  const infoForm = useForm({
    initialValues: {
      fullName: '',
      username: '',
      email: '',
      roleId: 0,
      stateId: 0,
      userId: '',
      userIdType: 0,
      name: '',
      otherNames: '',
      lastName: '',
      secondLastName: '',
      country: '',
      idDepartment: '',
      cityCode: '',
      address: '',
      countryCode: 57,
      phone: '',
      roleId: 0,
    },
  })

  const rolesForm = useForm({
    initialValues: {
      admin: false,
      sales: false,
      invoice: false,
      modules: false,
      clients: false,
      configuration: false,
    },
  })

  const payrollForm = useForm({
    initialValues: {
      salary: 0.0,
      workerType: 0,
      subCode: 0,
      periodicity: 0,
      contractType: 0,
      area: 0,
      position: '',
      startDate: dayjs(new Date()),
      fireDate: dayjs(new Date()),
      eps: 0,
      pension: 0,
      layoffs: 0,
      workRisk: 0,
      compensation: 0,
      accountTypeKw: 0,
      accountNumber: '',
      bank: '',
      highRisk: false,
      paymentMeans: 0,
    },
  })

  const resetConstants = () => {
    infoForm.setFieldValue('fullName', '')
    infoForm.setFieldValue('username', '')
    infoForm.setFieldValue('email', '')
    infoForm.setFieldValue('roleId', 0)
    infoForm.setFieldValue('stateId', 0)
    infoForm.setFieldValue('userId', '')
    infoForm.setFieldValue('userIdType', 0)
    infoForm.setFieldValue('name', '')
    infoForm.setFieldValue('otherNames', '')
    infoForm.setFieldValue('lastName', '')
    infoForm.setFieldValue('secondLastName', '')
    infoForm.setFieldValue('country', '')
    infoForm.setFieldValue('idDepartment', '')
    infoForm.setFieldValue('cityCode', '')
    infoForm.setFieldValue('address', '')
    infoForm.setFieldValue('countryCode', 57)
    infoForm.setFieldValue('phone', '')
    handleRole('admin', false)
    payrollForm.setFieldValue('salary', 0.0)
    payrollForm.setFieldValue('workerType', 0)
    payrollForm.setFieldValue('subCode', 0)
    payrollForm.setFieldValue('periodicity', '')
    payrollForm.setFieldValue('contractType', '')
    payrollForm.setFieldValue('area', '')
    payrollForm.setFieldValue('position', '')
    payrollForm.setFieldValue('startDate', dayjs(new Date()))
    payrollForm.setFieldValue('fireDate', dayjs(new Date()))
    payrollForm.setFieldValue('eps', 0)
    payrollForm.setFieldValue('pension', 0)
    payrollForm.setFieldValue('layoffs', 0)
    payrollForm.setFieldValue('workRisk', 0)
    payrollForm.setFieldValue('compensation', 0)
    payrollForm.setFieldValue('accountTypeKw', 0)
    payrollForm.setFieldValue('accountNumber', '')
    payrollForm.setFieldValue('bank', '')
    payrollForm.setFieldValue('highRisk', false)
    payrollForm.setFieldValue('paymentMeans', 0)
  }

  const handleAdminRole = (adminRole) => {
    rolesForm.setFieldValue('admin', adminRole)
    rolesForm.setFieldValue('sales', adminRole)
    rolesForm.setFieldValue('invoice', adminRole)
    rolesForm.setFieldValue('modules', adminRole)
    rolesForm.setFieldValue('clients', adminRole)
    rolesForm.setFieldValue('configuration', adminRole)
  }

  const handleRole = async (roleName, roleValue) => {
    rolesForm.setFieldValue(roleName, roleValue)
    if (roleName == 'admin') {
      handleAdminRole(roleValue)
    }
  }

  useEffect(() => {
    checkRolesForAdmin()
  }, [rolesForm])

  const checkRolesForAdmin = () => {
    if (
      rolesForm.values.sales == true &&
      rolesForm.values.invoice == true &&
      rolesForm.values.modules == true &&
      rolesForm.values.clients == true &&
      rolesForm.values.configuration == true
    ) {
      !rolesForm.values.admin && rolesForm.setFieldValue('admin', true)
    } else {
      rolesForm.values.admin && rolesForm.setFieldValue('admin', false)
    }
  }

  const [bankInfoChecked, setBankInfoChecked] = useState(false)

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const checkPersonalData = () => {
    if (infoForm.values.userIdType == 0) {
      notify('warning', 'Seleccione el tipo de documento')
      return false
    }
    if (infoForm.values.userId == '') {
      notify('warning', 'Ingrese el número de documento')
      return false
    }
    if (infoForm.values.name == '') {
      notify('warning', 'Ingrese el nombre')
      return false
    }
    if (infoForm.values.lastName == '') {
      notify('warning', 'Ingrese el primer apellido')
      return false
    }
    if (infoForm.values.country == 0) {
      notify('warning', 'Seleccione el país')
      return false
    }
    if (infoForm.values.idDepartment == 0) {
      notify('warning', 'Seleccione el departamento')
      return false
    }
    if (infoForm.values.cityCode == 0) {
      notify('warning', 'Seleccione el municipio')
      return false
    }
    if (infoForm.values.address == '') {
      notify('warning', 'Ingrese la dirección')
      return false
    }
    if (infoForm.values.phone == '') {
      notify('warning', 'Ingrese el teléfono')
      return false
    }
    if (!validateEmail(infoForm.values.email)) {
      notify('warning', 'Ingrese un correo electrónico válido')
      return false
    }
    return true
  }

  const checkLaborData = () => {
    if (payrollForm.values.salary <= 0) {
      notify('warning', 'Ingrese el salario')
      return false
    }
    if (payrollForm.values.workerType == 0) {
      notify('warning', 'Seleccione el tipo de trabajador')
      return false
    }
    if (payrollForm.values.periodicity == 0) {
      notify('warning', 'Seleccione la periodicidad de pago')
      return false
    }
    if (payrollForm.values.contractType == 0) {
      notify('warning', 'Seleccione el tipo de contrato')
      return false
    }
    if (payrollForm.values.startDate >= payrollForm.values.fireDate) {
      notify('warning', 'La fecha de inicio no puede ser mayor a la final')
      return false
    }
    if (branchId == 0) {
      notify('warning', 'Seleccione una sucursal')
      return false
    }
    if (payrollForm.values.position == '') {
      notify('warning', 'Ingrese el cargo')
      return false
    }
    return true
  }

  const checkInsuranceData = () => {
    if (payrollForm.values.eps == 0) {
      notify('warning', 'Seleccione la EPS')
      return false
    }
    if (payrollForm.values.pension == 0) {
      notify('warning', 'Seleccione el fondo de pensión')
      return false
    }
    if (payrollForm.values.layoffs == 0) {
      notify('warning', 'Seleccione el fondo de cesantías')
      return false
    }
    if (payrollForm.values.workRisk == 0) {
      notify('warning', 'Seleccione el riesgo laboral')
      return false
    }
    if (payrollForm.values.compensation == 0) {
      notify('warning', 'Seleccione la caja de compensación')
      return false
    }
    if (bankInfoChecked) {
      if (payrollForm.values.accountTypeKw == 0) {
        notify('warning', 'Seleccione el tipo de cuenta bancaria')
        return false
      }
      if (payrollForm.values.accountNumber == '') {
        notify('warning', 'Ingrese el número de cuenta bancaria')
        return false
      }
    }
    return true
  }

  const updateClientUser = async () => {
    setIsUpdateButtonDisabled(true)

    let clientUsersData = {
      stateId: 1,
      address: { string: infoForm.values.address },
      admin: rolesForm.values.admin,
      branchId: branchId,
      cityCode: { int32: infoForm.values.cityCode },
      clients: rolesForm.values.clients,
      companyId: tokenData.companyId,
      configuration: rolesForm.values.configuration,
      country: { int32: infoForm.values.country },
      countryCode: { string: 57 },
      email: infoForm.values.email,
      roleId: infoForm.values.roleId,
      fullName:
        infoForm.values.name +
        ' ' +
        infoForm.values.otherNames +
        ' ' +
        infoForm.values.lastName +
        ' ' +
        infoForm.values.secondLastName,
      idDepartment: { int32: infoForm.values.idDepartment },
      invoice: rolesForm.values.invoice,
      lastName: { string: infoForm.values.lastName },
      modules: rolesForm.values.modules,
      name: { string: infoForm.values.name },
      otherNames: { string: infoForm.values.otherNames },
      phone: { string: infoForm.values.phone },
      sales: rolesForm.values.sales,
      secondLastName: { string: infoForm.values.secondLastName },
      userId: infoForm.values.userId,
      userIdType: { int32: infoForm.values.userIdType },
      username: infoForm.values.email,
      accountNumber: payrollForm.values.accountNumber,
      accountTypeKw: payrollForm.values.accountTypeKw,
      area: payrollForm.values.area,
      bank: payrollForm.values.bank,
      compensation: payrollForm.values.compensation,
      contractType: payrollForm.values.contractType,
      eps: payrollForm.values.eps,
      fireDate: new Date(payrollForm.values.fireDate),
      layoffs: payrollForm.values.layoffs,
      pension: payrollForm.values.pension,
      periodicity: payrollForm.values.periodicity,
      position: payrollForm.values.position,
      salary: parseFloat(payrollForm.values.salary),
      startDate: new Date(payrollForm.values.startDate),
      subCode: payrollForm.values.subCode,
      workRisk: payrollForm.values.workRisk,
      workerType: payrollForm.values.workerType,
      highRisk: payrollForm.values.highRisk,
      paymentMeans: payrollForm.values.paymentMeans,
    }
    let result = await UpdateClientUsersApi(
      infoForm.values.clientId,
      clientUsersData,
    )
    if (result.data == 'Client Updated') {
      notify('success', 'Actualización Exitoso')
      onClose()
      await getClientUsers(tokenData.companyId, tokenData.branchId)
    } else {
      notify('warning', 'Error al actualizar')
    }

    setIsUpdateButtonDisabled(false)
  }
  //-----------tabs-------------------

  useEffect(() => {
    setBranchId(clientUserToUpdate.branchId)
    getMunicipios({ value: clientUserToUpdate.idDepartment.Int32 })
    clientUserToUpdate.accountTypeKw != 0 && setBankInfoChecked(true)
    infoForm.setValues({
      clientId: clientUserToUpdate.clientUserId,
      userIdType: clientUserToUpdate.userIdType.Int32,
      userId: clientUserToUpdate.userId,
      name: clientUserToUpdate.name.String,
      otherNames: clientUserToUpdate.otherNames.String,
      lastName: clientUserToUpdate.lastName.String,
      secondLastName: clientUserToUpdate.secondLastName.String,
      country: clientUserToUpdate.country.Int32,
      idDepartment: clientUserToUpdate.idDepartment.Int32,
      cityCode: clientUserToUpdate.cityCode.Int32,
      address: clientUserToUpdate.address.String,
      phone: clientUserToUpdate.phone.String,
      email: clientUserToUpdate.email,
      roleId: clientUserToUpdate.roleId,
    })
    payrollForm.setValues({
      salary: clientUserToUpdate.salary,
      workerType: clientUserToUpdate.workerType,
      subCode: clientUserToUpdate.subCode,
      periodicity: clientUserToUpdate.periodicity,
      contractType: clientUserToUpdate.contractType,
      area: clientUserToUpdate.area,
      position: clientUserToUpdate.position,
      startDate: dayjs(clientUserToUpdate.startDate),
      fireDate: dayjs(clientUserToUpdate.fireDate),
      eps: clientUserToUpdate.eps,
      pension: clientUserToUpdate.pension,
      layoffs: clientUserToUpdate.layoffs,
      workRisk: clientUserToUpdate.workRisk,
      compensation: clientUserToUpdate.compensation,
      accountTypeKw: clientUserToUpdate.accountTypeKw,
      accountNumber: clientUserToUpdate.accountNumber,
      bank: clientUserToUpdate.bank,
      highRisk: clientUserToUpdate.highRisk,
      paymentMeans: clientUserToUpdate.paymentMeans,
    })
    rolesForm.setValues({
      admin: clientUserToUpdate.admin,
      sales: clientUserToUpdate.sales,
      invoice: clientUserToUpdate.invoice,
      modules: clientUserToUpdate.modules,
      clients: clientUserToUpdate.clients,
      configuration: clientUserToUpdate.configuration,
    })
  }, [clientUserToUpdate])

  const [currentTab, setCurrentTab] = useState('tab1')

  const handleTabs = (value) => {
    if (value === currentTab) {
      return
    }
    setCurrentTab(value)
  }
  //----------------------------------------------------------

  const handleBranchId = (branchId) => {
    setBranchId(branchId)
  }

  //-------------Stepper-----------------
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set())

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const [currentSalaryButtonId, setCurrentSalaryButtonId] = useState(1)
  const setButtonColorById = (id) => {
    if (id == currentSalaryButtonId) {
      return {
        backgroundColor: 'rgb(13, 71, 161)',
        borderRadius: '0%',
      }
    } else {
      return {
        backgroundColor: 'transparent',
        color: isDarkModeActive ? 'white' : 'black',
        borderRadius: '0%',
      }
    }
  }

  /* aquí se cambia el tipo de salario que se va a asignar
  0: salario mínimo
  1: salario
  2: salario integral
  Si se selecciona salario mínimo, se asigna el valor de 1300000 (año 2024) y se desactiva el textfield de salario
  */
  const handleSalaryButton = (id) => {
    setCurrentSalaryButtonId(id)
    if (id == 0) {
      payrollForm.setFieldValue('salary', 1300000)
    }
  }

  return (
    <Container
      style={{
        borderRadius: '10px',
        marginBottom: '10px',
        width: 'fit-content',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <h4>Edición del empleado</h4>
      <Stepper
        activeStep={activeStep}
        style={{ width: '100%', padding: '10px' }}
        alternativeLabel
      >
        <Step key={0}>
          <StepLabel>Datos personales</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>Datos laborales</StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel>Entidades</StepLabel>
        </Step>
        <Step key={3}>
          <StepLabel>Roles</StepLabel>
        </Step>
      </Stepper>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === 'tab1'}>
          <Group
            direction="column"
            style={{
              width: '100%',
              padding: '20px',
              margin: '0',
            }}
            grow
          >
            <div>
              <Group grow mb="md" mt="md">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Documento
                  </InputLabel>
                  <MuiSelect
                    label="Documento"
                    onChange={(e) =>
                      infoForm.setFieldValue('userIdType', e.target.value)
                    }
                    value={infoForm.values.userIdType}
                  >
                    <MenuItem value={0} disabled />
                    {documentTypes.map((documentType, index) => {
                      return (
                        <MenuItem value={documentType.value} key={index}>
                          {documentType.label}
                        </MenuItem>
                      )
                    })}
                  </MuiSelect>
                </FormControl>
                <TextField
                  variant="standard"
                  required
                  label="Número de documento"
                  placeholder="Número de documento"
                  type="number"
                  value={infoForm.values.userId}
                  onChange={(event) => {
                    infoForm.setFieldValue('userId', event.currentTarget.value)
                  }}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <TextField
                  variant="standard"
                  required
                  label="Nombre"
                  placeholder="Nombre"
                  value={infoForm.values.name}
                  onChange={(event) => {
                    infoForm.setFieldValue('name', event.currentTarget.value)
                  }}
                />
                <TextField
                  variant="standard"
                  required
                  label="Otros nombres"
                  placeholder="Otros nombres"
                  value={infoForm.values.otherNames}
                  onChange={(event) => {
                    infoForm.setFieldValue(
                      'otherNames',
                      event.currentTarget.value,
                    )
                  }}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <TextField
                  variant="standard"
                  required
                  label="Primer apellido"
                  placeholder="Primer apellido"
                  value={infoForm.values.lastName}
                  onChange={(event) => {
                    infoForm.setFieldValue(
                      'lastName',
                      event.currentTarget.value,
                    )
                  }}
                />
                <TextField
                  variant="standard"
                  required
                  label="Segundo apellido"
                  placeholder="Segundo apellido"
                  value={infoForm.values.secondLastName}
                  onChange={(event) => {
                    infoForm.setFieldValue(
                      'secondLastName',
                      event.currentTarget.value,
                    )
                  }}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <CustomSelect
                  title="País"
                  placeholder="País"
                  options={countriesData}
                  value={{
                    value: infoForm.values.country,
                    label: getTypeKeyByValue(
                      countriesData,
                      'label',
                      infoForm.values.country,
                    ),
                  }}
                  onChange={(e) => {
                    infoForm.setFieldValue('country', e.value)
                  }}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  minWidth={200}
                  maxWidth={200}
                  withBorder={false}
                />
                <CustomSelect
                  title="Departamento"
                  placeholder="Departamento"
                  defaultValue={infoForm.values.idDepartment}
                  value={{
                    value: infoForm.values.idDepartment,
                    label: getTypeKeyByValue(
                      departmentsData,
                      'label',
                      infoForm.values.idDepartment,
                    ),
                  }}
                  onChange={getMunicipios}
                  options={departmentsData}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  minWidth={200}
                  maxWidth={200}
                  withBorder={false}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <CustomSelect
                  title="Municipio"
                  placeholder="Municipio"
                  defaultValue={infoForm.values.cityCode}
                  onChange={(e) => {
                    infoForm.setFieldValue('cityCode', e.value)
                  }}
                  value={{
                    value: infoForm.values.cityCode,
                    label: getTypeKeyByValue(
                      citiesOptions,
                      'label',
                      infoForm.values.cityCode,
                    ),
                  }}
                  options={citiesOptions}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  minWidth={200}
                  maxWidth={200}
                  withBorder={false}
                />
                <TextField
                  variant="standard"
                  required
                  label="Dirección"
                  placeholder="Dirección"
                  value={infoForm.values.address}
                  onChange={(event) => {
                    infoForm.setFieldValue('address', event.currentTarget.value)
                  }}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <TextField
                  disabled
                  variant="standard"
                  label="Código de país"
                  value={'57'}
                />
                <TextField
                  variant="standard"
                  required
                  label="Teléfono"
                  placeholder="Teléfono"
                  type="tel"
                  value={infoForm.values.phone}
                  onChange={(event) => {
                    infoForm.setFieldValue('phone', event.currentTarget.value)
                  }}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <TextField
                  variant="standard"
                  required
                  label="Correo"
                  placeholder="Correo"
                  type="email"
                  value={infoForm.values.email}
                  onChange={(event) => {
                    infoForm.setFieldValue('email', event.currentTarget.value)
                  }}
                />
              </Group>
            </div>
            <hr></hr>
            <div>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                  marginRight: '10px',
                }}
                onClick={() => {
                  checkPersonalData() && handleTabs('tab2')
                  handleNext()
                }}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Siguiente
              </Button>
              <Button
                style={{
                  backgroundColor: 'white',
                  borderRadius: '100px',
                  color: 'black',
                  isolation: 'isolate',
                }}
                onClick={() => {
                  onClose()
                  resetConstants()
                }}
              >
                Cerrar
              </Button>
            </div>
          </Group>
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === 'tab2'}>
          <Group
            direction="column"
            style={{
              width: '100%',
              padding: '20px',
              margin: '0',
            }}
            grow
          >
            <ButtonToolbar
              style={{
                justifyContent: 'center',
              }}
              aria-label="Toolbar with button groups"
            >
              <ButtonGroup className="me-2" aria-label="First group">
                <Button
                  style={setButtonColorById(0)}
                  onClick={() => handleSalaryButton(0)}
                >
                  Salario mínimo
                </Button>
                <Button
                  style={setButtonColorById(1)}
                  onClick={() => handleSalaryButton(1)}
                >
                  Salario
                </Button>
                <Button
                  style={setButtonColorById(2)}
                  onClick={() => handleSalaryButton(2)}
                >
                  Salario Integral
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
            <div>
              {currentSalaryButtonId != 0 && (
                <Group grow mb="md" mt="md">
                  <CurrencyInput
                    variant="standard"
                    required
                    label="Salario"
                    placeholder="Salario"
                    value={payrollForm.values.salary}
                    onChange={(event) =>
                      payrollForm.setFieldValue(
                        'salary',
                        event.currentTarget.value
                          .replace(/,/g, '')
                          .replace('$', ''),
                      )
                    }
                  />
                </Group>
              )}
              <Group grow mb="md" mt="md">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Tipo de trabajador
                  </InputLabel>
                  <MuiSelect
                    label="Tipo de trabajador"
                    value={payrollForm.values.workerType}
                    onChange={(e) =>
                      payrollForm.setFieldValue('workerType', e.target.value)
                    }
                  >
                    <MenuItem value={0} disabled />
                    {workerTypes.map((workerType, index) => {
                      return (
                        <MenuItem value={workerType.value} key={index}>
                          {workerType.label}
                        </MenuItem>
                      )
                    })}
                  </MuiSelect>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Subtipo de trabajador
                  </InputLabel>
                  <MuiSelect
                    label="Subtipo de trabajador"
                    value={payrollForm.values.subCode}
                    onChange={(e) =>
                      payrollForm.setFieldValue('subCode', e.target.value)
                    }
                  >
                    <MenuItem value={0} disabled />
                    <MenuItem value={1}>No aplica</MenuItem>
                    <MenuItem value={2}>
                      Dependiente pensionado por vejez activo
                    </MenuItem>
                  </MuiSelect>
                </FormControl>
              </Group>
              <Group grow mb="md" mt="md">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Periodicidad de pago
                  </InputLabel>
                  <MuiSelect
                    label="Periodicidad de pago"
                    value={payrollForm.values.periodicity}
                    onChange={(e) =>
                      payrollForm.setFieldValue('periodicity', e.target.value)
                    }
                  >
                    <MenuItem value={0} disabled />
                    {periodicityTypes.map((periodicityType, index) => {
                      return (
                        <MenuItem value={periodicityType.value} key={index}>
                          {periodicityType.label}
                        </MenuItem>
                      )
                    })}
                  </MuiSelect>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Medio de pago
                  </InputLabel>
                  <MuiSelect
                    label="Medio de pago"
                    value={payrollForm.values.paymentMeans}
                    onChange={(e) =>
                      payrollForm.setFieldValue('paymentMeans', e.target.value)
                    }
                  >
                    <MenuItem value={0} disabled />
                    {paymentMeansTypes.map((paymentMeansType, index) => {
                      return (
                        <MenuItem value={paymentMeansType.value} key={index}>
                          {paymentMeansType.label}
                        </MenuItem>
                      )
                    })}
                  </MuiSelect>
                </FormControl>
              </Group>
              <Group grow mb="md" mt="md">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="¿Con Aux. transporte?"
                    checked={false}
                    onChange={(event) => {}}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Alto Riesgo"
                    checked={payrollForm.values.highRisk}
                    onChange={(event) => {
                      payrollForm.setFieldValue(
                        'highRisk',
                        event.target.checked,
                      )
                    }}
                  />
                </FormGroup>
              </Group>
              <h5>Contratos</h5>
              <Group grow mb="md" mt="md">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Tipo de contrato
                  </InputLabel>
                  <MuiSelect
                    label="Tipo de contrato"
                    value={payrollForm.values.contractType}
                    onChange={(e) =>
                      payrollForm.setFieldValue('contractType', e.target.value)
                    }
                  >
                    <MenuItem value={0} disabled />
                    {contractTypes.map((contractType, index) => {
                      return (
                        <MenuItem value={contractType.value} key={index}>
                          {contractType.label}
                        </MenuItem>
                      )
                    })}
                  </MuiSelect>
                </FormControl>
              </Group>
              <Group grow mb="md" mt="md">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ES"
                >
                  <DatePicker
                    label="Fecha inicial"
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small' } }}
                    value={payrollForm.values.startDate}
                    onChange={(date) => {
                      payrollForm.setFieldValue('startDate', date)
                    }}
                  />
                  <DatePicker
                    label="Fecha final"
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small' } }}
                    value={payrollForm.values.fireDate}
                    onChange={(date) => {
                      payrollForm.setFieldValue('fireDate', date)
                    }}
                  />
                </LocalizationProvider>
              </Group>
              <h5>General</h5>
              <Group grow mb="md" mt="md">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Sede
                  </InputLabel>
                  <MuiSelect
                    label="Sede"
                    onChange={(e) => handleBranchId(e.target.value)}
                    value={branchId}
                  >
                    <MenuItem value={0} disabled />
                    {branches.map((branch, index) => {
                      return (
                        <MenuItem value={branch.id} key={index}>
                          {branch.name}
                        </MenuItem>
                      )
                    })}
                  </MuiSelect>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Área
                  </InputLabel>
                  <MuiSelect
                    label="Área"
                    value={payrollForm.values.area}
                    onChange={(e) =>
                      payrollForm.setFieldValue('area', e.target.value)
                    }
                  >
                    <MenuItem value={0} disabled />
                    {areaTypes.map((areaType, index) => {
                      return (
                        <MenuItem value={areaType.value} key={index}>
                          {areaType.label}
                        </MenuItem>
                      )
                    })}
                  </MuiSelect>
                </FormControl>
              </Group>
              <Group grow mb="md" mt="md">
                <TextField
                  variant="standard"
                  required
                  label="Cargo"
                  placeholder="Cargo"
                  value={payrollForm.values.position}
                  onChange={(event) => {
                    payrollForm.setFieldValue(
                      'position',
                      event.currentTarget.value,
                    )
                  }}
                />
              </Group>
            </div>
            <hr></hr>
            <div>
              <Button
                style={{
                  backgroundColor: 'rgb(13, 71, 161)',
                  borderRadius: '100px',
                  isolation: 'isolate',
                  marginRight: '10px',
                }}
                onClick={() => {
                  handleTabs('tab1')
                  handleBack()
                }}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Atrás
              </Button>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                  marginRight: '10px',
                }}
                onClick={() => {
                  checkLaborData() && handleTabs('tab3')
                  handleNext()
                }}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Siguiente
              </Button>
              <Button
                style={{
                  backgroundColor: 'white',
                  borderRadius: '100px',
                  color: 'black',
                  isolation: 'isolate',
                }}
                onClick={() => {
                  onClose()
                  resetConstants()
                }}
              >
                Cerrar
              </Button>
            </div>
          </Group>
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === 'tab3'}>
          <Group
            direction="column"
            style={{
              width: '100%',
              padding: '20px',
              margin: '0',
            }}
            grow
          >
            <div>
              <Group grow mb="md" mt="md">
                <CustomSelect
                  placeholder="EPS"
                  title="EPS"
                  options={epsTypes}
                  value={{
                    value: payrollForm.values.eps,
                    label: getTypeKeyByValue(
                      epsTypes,
                      'label',
                      payrollForm.values.eps,
                    ),
                  }}
                  onChange={(e) => {
                    payrollForm.setFieldValue('eps', e.value)
                  }}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  minWidth={400}
                  maxWidth={400}
                  withBorder={false}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <CustomSelect
                  title="Fondo de pensión"
                  placeholder="Fondo de pensión"
                  options={pensionTypes}
                  value={{
                    value: payrollForm.values.pension,
                    label: getTypeKeyByValue(
                      pensionTypes,
                      'label',
                      payrollForm.values.pension,
                    ),
                  }}
                  onChange={(e) => {
                    payrollForm.setFieldValue('pension', e.value)
                  }}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  minWidth={400}
                  maxWidth={400}
                  withBorder={false}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <CustomSelect
                  title="Fondo de cesantías"
                  placeholder="Fondo de cesantías"
                  options={layoffTypes}
                  value={{
                    value: payrollForm.values.layoffs,
                    label: getTypeKeyByValue(
                      layoffTypes,
                      'label',
                      payrollForm.values.layoffs,
                    ),
                  }}
                  onChange={(e) => {
                    payrollForm.setFieldValue('layoffs', e.value)
                  }}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  minWidth={400}
                  maxWidth={400}
                  withBorder={false}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <CustomSelect
                  title="Riesgo laboral"
                  placeholder="Riesgo laboral"
                  options={riskTypes}
                  value={{
                    value: payrollForm.values.workRisk,
                    label: getTypeKeyByValue(
                      riskTypes,
                      'label',
                      payrollForm.values.workRisk,
                    ),
                  }}
                  onChange={(e) => {
                    payrollForm.setFieldValue('workRisk', e.value)
                  }}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  minWidth={400}
                  maxWidth={400}
                  withBorder={false}
                />
              </Group>
              <Group grow mb="md" mt="md">
                <CustomSelect
                  title="Caja de compensación"
                  placeholder="Caja de compensación"
                  options={compensationTypes}
                  value={{
                    value: payrollForm.values.compensation,
                    label: getTypeKeyByValue(
                      compensationTypes,
                      'label',
                      payrollForm.values.compensation,
                    ),
                  }}
                  onChange={(e) => {
                    payrollForm.setFieldValue('compensation', e.value)
                  }}
                  theme={isDarkModeActive ? 'black' : 'white'}
                  minWidth={400}
                  maxWidth={400}
                  withBorder={false}
                />
              </Group>
              <Group
                style={{
                  width: '100%',
                  justifyContent: 'space-around',
                }}
                mb="md"
                mt="md"
              >
                <h5>Información bancaria</h5>
                <Switch
                  style={{
                    float: 'right',
                  }}
                  color="success"
                  size="small"
                  checked={bankInfoChecked}
                  onChange={(e) => setBankInfoChecked(e.target.checked)}
                />
              </Group>
              {bankInfoChecked && (
                <Group grow mb="md" mt="md">
                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Tipo de cuenta bancaria
                    </InputLabel>
                    <MuiSelect
                      label="Tipo de cuenta bancaria"
                      value={payrollForm.values.accountTypeKw}
                      onChange={(e) =>
                        payrollForm.setFieldValue(
                          'accountTypeKw',
                          e.target.value,
                        )
                      }
                    >
                      <MenuItem value={0} disabled />
                      <MenuItem value={1}>Ahorros</MenuItem>
                      <MenuItem value={2}>Corriente</MenuItem>
                    </MuiSelect>
                  </FormControl>
                  <TextField
                    variant="standard"
                    required
                    label="Cuenta bancaria"
                    placeholder="Cuenta bancaria"
                    value={payrollForm.values.accountNumber}
                    onChange={(event) => {
                      payrollForm.setFieldValue(
                        'accountNumber',
                        event.currentTarget.value,
                      )
                    }}
                  />
                </Group>
              )}
            </div>
            <hr></hr>
            <div>
              <Button
                style={{
                  backgroundColor: 'rgb(13, 71, 161)',
                  borderRadius: '100px',
                  isolation: 'isolate',
                  marginRight: '10px',
                }}
                onClick={() => {
                  handleTabs('tab2')
                  handleBack()
                }}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Atrás
              </Button>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                  marginRight: '10px',
                }}
                onClick={() => {
                  checkInsuranceData() && handleTabs('tab4')
                  handleNext()
                }}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Siguiente
              </Button>
              <Button
                style={{
                  backgroundColor: 'white',
                  borderRadius: '100px',
                  color: 'black',
                  isolation: 'isolate',
                }}
                onClick={() => {
                  onClose()
                  resetConstants()
                }}
              >
                Cerrar
              </Button>
            </div>
          </Group>
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === 'tab4'}>
          <Group
            direction="column"
            style={{
              width: '100%',
              padding: '20px',
              margin: '0',
            }}
            grow
          >
            <div>
              <Group grow mb="md" mt="md">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Admin
                  </InputLabel>
                  <MuiSelect
                    label="Admin"
                    value={rolesForm.values.admin}
                    onChange={(e) => handleRole('admin', e.target.value)}
                  >
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </MuiSelect>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Ventas
                  </InputLabel>
                  <MuiSelect
                    label="Ventas"
                    value={rolesForm.values.sales}
                    onChange={(e) => handleRole('sales', e.target.value)}
                  >
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Group>

              <Group grow mb="md" mt="md">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Facturación electrónica
                  </InputLabel>
                  <MuiSelect
                    label="Facturación Electronica"
                    value={rolesForm.values.invoice}
                    onChange={(e) => handleRole('invoice', e.target.value)}
                  >
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </MuiSelect>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Módulos
                  </InputLabel>
                  <MuiSelect
                    label="Módulos"
                    value={rolesForm.values.modules}
                    onChange={(e) => handleRole('modules', e.target.value)}
                  >
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Group>
              <Group grow mb="md" mt="md">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Clientes
                  </InputLabel>
                  <MuiSelect
                    label="Clientes"
                    value={rolesForm.values.clients}
                    onChange={(e) => handleRole('clients', e.target.value)}
                  >
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </MuiSelect>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Configuración
                  </InputLabel>
                  <MuiSelect
                    label="Configuración"
                    value={rolesForm.values.configuration}
                    onChange={(e) =>
                      handleRole('configuration', e.target.value)
                    }
                  >
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Group>
            </div>
            <hr></hr>
            <div>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'rgb(13, 71, 161)',
                  borderRadius: '100px',
                  isolation: 'isolate',
                  marginRight: '10px',
                }}
                onClick={() => {
                  handleTabs('tab3')
                  handleBack()
                }}
              >
                Atrás
              </Button>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                  marginRight: '10px',
                }}
                onClick={updateClientUser}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'white',
                  borderRadius: '100px',
                  color: 'black',
                  isolation: 'isolate',
                }}
                onClick={() => {
                  onClose()
                  resetConstants()
                }}
              >
                Cerrar
              </Button>
            </div>
          </Group>
        </MDBTabsPane>
      </MDBTabsContent>
    </Container>
  )
}

export default EditClientUser
