import React, { useState, useEffect } from 'react'
import useStore from '../../../components/zustand'
import { Menu } from '@mui/material'
import Button from 'react-bootstrap/Button'
import {
  Pagination,
  MenuItem,
  Select as SelectPages,
  InputLabel,
  FormControl,
  Tooltip,
} from '@mui/material'
import { monthFormat, notify, setDecimal } from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import { Container } from 'react-bootstrap'
import {
  DotsVertical,
  Edit,
  File,
  FileDollar,
  MailForward,
  Trash,
  Users,
} from 'tabler-icons-react'
import pdf from '../../../resources/images/pdf.png'
import { GetPayrollEntriesDocumentsApi } from '../../../pages/api/payroll'
import ActionButton from '../../custom/actionButton'

const PayrollHistorical = ({
  getPayrollEntries,
  clientUserDetails,
  payrollEntries,
  entriesCount,
  showPdfViewer,
}) => {
  const { isDarkModeActive } = useStore()
  const getTotalFromCategory = (category) => {
    let total = 0
    category.forEach((accrual) => {
      total += accrual.amount
    })
    return total
  }

  const getTotalSalary = (accruals, deductions) => {
    let total = 0
    accruals.forEach((accrual) => {
      total += accrual.amount
    })
    deductions.forEach((deduction) => {
      total -= deduction.amount
    })
    return total
  }

  const handlePdfViewer = async (prefix, number, resolutionId) => {
    let result = await GetPayrollEntriesDocumentsApi(
      prefix,
      number,
      resolutionId,
    )
    if (result.data != null) {
      showPdfViewer(
        `PDF De nómina de ${clientUserDetails.fullName}`,
        result.data.pdf,
        'base64',
      )
    } else {
      notify('error', 'Error al obtener el documento.')
    }
  }

  //-------------------dots menu-------------------
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(5)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getPayrollEntries(clientUserDetails, newOffset, rpg)
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    getPayrollEntries(clientUserDetails, 0, event.target.value)
  }

  //-----------tabs-------------------
  const [currentTab, setCurrentTab] = useState('tab1')

  const handleTabs = (value) => {
    if (value === currentTab) {
      return
    }
    setCurrentTab(value)
  }

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? 'black' : 'white')
  }, [isDarkModeActive])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '1px solid gray',
        borderRadius: '10px',
        padding: '20px',
        alignItems: 'center',
        marginBottom: '20px',
      }}
    >
      <h4
        style={{
          float: 'left',
          fontSize: '19px',
        }}
      >
        Historial de nómina de <b>{clientUserDetails.fullName}</b>
      </h4>
      <MDBTabs>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabs('tab1')}
            active={currentTab === 'tab1'}
            style={{
              backgroundColor: 'transparent',
              color: color,
              isolation: 'isolate',
            }}
          >
            <Users /> Nómina electrónica
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleTabs('tab2')}
            active={currentTab === 'tab2'}
            style={{
              backgroundColor: 'transparent',
              color: color,
              isolation: 'isolate',
            }}
          >
            <FileDollar /> Colillas de pago
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
      <MDBTabsContent
        style={{
          width: '100%',
          margin: '20px',
        }}
      >
        <MDBTabsPane show={currentTab === 'tab1'}>
          <MDBTable small>
            <MDBTableHead>
              <tr style={{ textAlign: 'center' }}>
                <th>Periodo</th>
                <th>Devengados</th>
                <th>Deducciones</th>
                <th>Total</th>
                <th className="userActions">Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {payrollEntries.map((entry, index) => (
                <tr
                  key={index}
                  style={{ textAlign: 'center', verticalAlign: 'middle' }}
                >
                  <td>
                    <div>
                      <span>{entry.prefix + entry.number}</span>
                      <div>
                        <span>
                          {monthFormat(
                            new Date(entry.createdAt).getMonth(),
                            false,
                          ) +
                            ' ' +
                            new Date(entry.createdAt).getFullYear()}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Tooltip
                      title={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          {JSON.parse(entry.accruals).map((accrual) => (
                            <span>{`${accrual.code} - $${setDecimal(
                              accrual.amount,
                              2,
                            )}`}</span>
                          ))}
                        </div>
                      }
                    >
                      ${' '}
                      {setDecimal(
                        getTotalFromCategory(JSON.parse(entry.accruals)),
                        2,
                      )}
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip
                      title={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          {JSON.parse(entry.deductions).map((deduction) => (
                            <span>{`${deduction.code} - $${setDecimal(
                              deduction.amount,
                              2,
                            )}`}</span>
                          ))}
                        </div>
                      }
                    >
                      ${' '}
                      {setDecimal(
                        getTotalFromCategory(JSON.parse(entry.deductions), 2),
                      )}
                    </Tooltip>
                  </td>
                  <td>
                    ${' '}
                    {setDecimal(
                      getTotalSalary(
                        JSON.parse(entry.accruals),
                        JSON.parse(entry.deductions),
                      ),
                      2,
                    )}
                  </td>
                  <td
                    style={{
                      width: '300px',
                      margin: 'auto',
                      alignItems: 'center',
                    }}
                  >
                    <ActionButton
                      description="Enviar por correo"
                      style={{
                        backgroundColor: bgColor,
                        margin: '5px',
                        padding: '5px',
                        height: 'fit-content',
                      }}
                      onClick={() => {}}
                    >
                      <MailForward size={20} color="black" />
                    </ActionButton>
                    <ActionButton
                      description="Ver PDF"
                      style={{
                        backgroundColor: bgColor,
                        margin: '5px',
                        padding: '5px',
                        height: 'fit-content',
                      }}
                      onClick={() => {
                        handlePdfViewer(
                          entry.prefix,
                          entry.number,
                          entry.resolutionId,
                        )
                      }}
                    >
                      <img
                        alt="pdf"
                        style={{
                          alignContent: 'center',
                          cursor: 'pointer',
                          width: '20px',
                        }}
                        src={pdf}
                      />
                    </ActionButton>
                    <ActionButton
                      description="Ver XML"
                      style={{
                        backgroundColor: bgColor,
                        margin: '5px',
                        padding: '5px',
                        height: 'fit-content',
                      }}
                      onClick={() => {}}
                    >
                      <File size={20} color="blue" />
                    </ActionButton>
                    <Button
                      style={{
                        boxShadow: '0px 0px 0px 0px',
                        padding: '9px 0px',
                        height: 'fit-content',
                      }}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e) => handleClick(e)}
                      variant="secundary"
                    >
                      <DotsVertical color={color}></DotsVertical>
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => {
                        handleClose()
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <Edit size={20} /> Editar
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: 'red',
                        }}
                        onClick={handleClose}
                      >
                        <Trash size={20} color="red" /> Eliminar
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
          <Container>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Filas
              </InputLabel>
              <SelectPages
                labelId="demo-simple-select-standard-label"
                autoWidth={true}
                value={rpg}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </SelectPages>
            </FormControl>
            <Pagination
              count={Math.ceil(entriesCount / rpg)}
              page={pg}
              onChange={handleChangePage}
              showFirstButton={true}
              showLastButton={true}
              style={{ display: 'inline-block' }}
            />
          </Container>
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === 'tab2'}></MDBTabsPane>
      </MDBTabsContent>
    </div>
  )
}

export default PayrollHistorical
