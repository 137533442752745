import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getAllClientBranchesApi = async (companyId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/branches/all/${companyId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BRANCH-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las sucursales.')
  }
}

export const getClientBranchesByCompanyApi = async (companyId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/branches/${companyId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BRANCH-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las sucursales por compañia.')
  }
}
