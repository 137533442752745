import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import { TextField } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { notify } from '../../../services/utils'
import { apilUrl } from '../../../services/url'
import { validateEmail } from '../../../services/utils'
import { CircularProgress } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import useStore from '../../../components/zustand'

//Token Verification
import { verifiedToken } from '../../../services/token.service'
import CustomSelect from '../../../components/custom/customSelect'
import { UpdateCompanyApi, getCompanyByIdApi } from '../../api/companies'

export default function CompanyGetStarted(props) {
  const { tokenData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const [options, setOptions] = React.useState([
    { value: 5, label: 'ANTIOQUIA' },
    { value: 8, label: 'ATLÁNTICO' },
    { value: 11, label: 'BOGOTÁ, D.C.' },
    { value: 13, label: 'BOLÍVAR' },
    { value: 15, label: 'BOYACÁ' },
    { value: 17, label: 'CALDAS' },
    { value: 18, label: 'CAQUETÁ' },
    { value: 19, label: 'CAUCA' },
    { value: 20, label: 'CESAR' },
    { value: 23, label: 'CÓRDOBA' },
    { value: 25, label: 'CUNDINAMARCA' },
    { value: 27, label: 'CHOCÓ' },
    { value: 41, label: 'HUILA' },
    { value: 44, label: 'LA GUAJIRA' },
    { value: 47, label: 'MAGDALENA' },
    { value: 50, label: 'META' },
    { value: 52, label: 'NARIÑO' },
    { value: 54, label: 'NORTE DE SANTANDER' },
    { value: 63, label: 'QUINDIO' },
    { value: 66, label: 'RISARALDA' },
    { value: 68, label: 'SANTANDER' },
    { value: 70, label: 'SUCRE' },
    { value: 73, label: 'TOLIMA' },
    { value: 76, label: 'VALLE DEL CAUCA' },
    { value: 81, label: 'ARAUCA' },
    { value: 85, label: 'CASANARE' },
    { value: 86, label: 'PUTUMAYO' },
    {
      value: 88,
      label: 'ARCHIPIÉLAGO DE SAN ANDRÉS, PROVIDENCIA Y SANTA CATALINA',
    },
    { value: 91, label: 'AMAZONAS' },
    { value: 94, label: 'GUAINÍA' },
    { value: 95, label: 'GUAVIARE' },
    { value: 97, label: 'VAUPÉS' },
    { value: 99, label: 'VICHADA' },
  ])

  const [selectedOption, setSelectedOption] = useState('')

  const [municipiosOptions, setMunicipiosOptions] = React.useState([])

  const [selectedMunicipiosOptions, setSelectedMunicipiosOptions] = useState('')

  const [logoFile, setLogoFile] = React.useState(null)

  const form = useForm({
    initialValues: {
      id: 0,
      name: '',
      personType: 0,
      idType: 0,
      vatLiability: 0,
      taxManager: 0,
      email: '',
      phone: '',
      department: 0,
      municipalitie: 0,
      address: '',
    },
  })

  const handlePersonType = (personType) => {
    form.setFieldValue('personType', personType)
  }

  const handlIdType = (idType) => {
    form.setFieldValue('idType', idType)
  }

  const handleVatLiability = (vatLiability) => {
    form.setFieldValue('vatLiability', vatLiability)
  }

  const handleTaxManager = (taxManager) => {
    form.setFieldValue('taxManager', taxManager)
  }

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }

  const handleLogoFile = ({ target }) => {
    if (checkIfIsFile(target.files[0].name)) {
      if (target.files < 1 || !target.validity.valid) {
        return
      }
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          // let base64String = /,(.+)/.exec(result)[1];
          setLogoFile(result)
        }
      })
    } else {
      notify(
        'warning',
        'El archivo debe ser un uno de los siguientes formatos. png, jpg o jpeg',
      )
    }
  }

  const checkIfIsFile = (file) => {
    let fileExtention =
      file.substring(file.lastIndexOf('.') + 1, file.length) || file
    if (
      fileExtention == 'png' ||
      fileExtention == 'jpg' ||
      fileExtention == 'jpeg'
    ) {
      return true
    } else {
      return false
    }
  }

  const getCompany = async (companyId) => {
    let result = await getCompanyByIdApi(companyId)
    if (result.data != null) {
      form.setFieldValue('id', result.data[0].id)
      form.setFieldValue('name', result.data[0].name)
      form.setFieldValue('email', result.data[0].email)
    } else {
      notify('error', 'Error al obtener la empresa.')
    }
  }

  const getMunicipios = async (municipio) => {
    setSelectedOption(municipio)

    try {
      let result = await fetch(
        `${apilUrl}/municipios/filters/${municipio.value}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      setMunicipiosOptions(result.data)
    } catch (error) {
      return error
    }
  }

  const updateCompany = async () => {
    let readyToUpdate = true

    if (form.values.name == '') {
      readyToUpdate = false
      notify('warning', 'Falta el nombre.')
    } else if (logoFile == '' || logoFile == null) {
      readyToUpdate = false
      notify('warning', 'Falta el logotipo.')
    } else if (form.values.personType <= 0) {
      readyToUpdate = false
      notify('warning', 'Falta elegir el tipo de persona.')
    } else if (parseInt(form.values.vatLiability) <= 0) {
      readyToUpdate = false
      notify('warning', 'Falta elegir la responsabilidad de IVA.')
    } else if (parseInt(form.values.taxManager) <= 0) {
      readyToUpdate = false
      notify('warning', 'Falta elegir gerente de impuestos.')
    } else if (form.values.email == '') {
      readyToUpdate = false
      notify('warning', 'Falta el correo electrónico.')
    } else if (form.values.phone.length < 7) {
      readyToUpdate = false
      notify('warning', 'Falta ingresar el número telefónico.')
    } else if (selectedOption.label == '') {
      readyToUpdate = false
      notify('warning', 'Falta el departamento.')
    } else if (selectedMunicipiosOptions.label == '') {
      readyToUpdate = false
      notify('warning', 'Falta la ciudad/municipio.')
    } else if (form.values.address == '') {
      readyToUpdate = false
      notify('warning', 'Falta la dirección.')
    }
    if (readyToUpdate) {
      setIsCreateButtonDisabled(true)
      let islandData = {
        name: form.values.name,
        personType: parseInt(form.values.personType),
        idType: parseInt(form.values.idType),
        vatLiability: parseInt(form.values.vatLiability),
        taxManager: parseInt(form.values.taxManager),
        email: form.values.email,
        phone: form.values.phone,
        department: selectedOption.label,
        municipalitie: selectedMunicipiosOptions.label,
        idDepartment: selectedOption.value.toString(),
        cityCode: selectedMunicipiosOptions.codigo_ciudad,
        address: form.values.address,
        logo: logoFile,
      }
      let result = await UpdateCompanyApi(tokenData.companyId, islandData)
      if (result.data == 'Company updated') {
        notify('success', 'Datos Guardados con Éxito')

        //CALL PARENT FUNCTION
        window.setTimeout(() => {
          props.start()
        }, 500)
        await getCompany(tokenData.companyId)
      } else {
        notify('warning', 'Error al actualizar empresa.')
      }
      setIsCreateButtonDisabled(false)
    }
  }

  const tokenVerified = async () => {
    let result = await verifiedToken()

    if (result == false) {
      navigate('/')
    } else {
      await getCompany(tokenData.companyId)
    }
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  return (
    <div>
      <Container>
        <br></br>
        <form>
          <Group direction="column" grow>
            <img src={logoFile} width="300px" height="auto"></img>
            <Group grow mb="md" mt="md">
              <>
                <label htmlFor="camCom" className="form-label">
                  Logo de empresa (png, jpg, jpeg) *
                </label>
                <input
                  className="form-control form-control-sm"
                  id="camCom"
                  type="file"
                  required
                  // value={fileName && fileName.name}
                  onChange={handleLogoFile}
                />
              </>
            </Group>
            <br></br>
            <Group grow mb="md" mt="md">
              <TextField
                variant="standard"
                required
                label="Nombre De Empresa"
                placeholder="Nombre De Empresa"
                value={form.values.name}
                onChange={(event) =>
                  form.setFieldValue('name', event.currentTarget.value)
                }
              />

              <TextField
                variant="standard"
                required
                label="Correo"
                placeholder="Correo"
                type="email"
                value={form.values.email}
                onChange={(event) =>
                  validateEmail(event.currentTarget.value)
                    ? form.setFieldValue('email', event.currentTarget.value)
                    : form.setFieldValue('email', '')
                }
              />
            </Group>

            <Group grow mb="md" mt="md">
              <TextField
                variant="standard"
                required
                label="Télefono"
                placeholder="Télefono"
                defaultValue={form.values.phone}
                type="number"
                onChange={(event) =>
                  form.setFieldValue('phone', event.currentTarget.value)
                }
              />

              <TextField
                variant="standard"
                required
                label="Direción"
                placeholder="Direción"
                value={form.values.address}
                onChange={(event) =>
                  form.setFieldValue('address', event.currentTarget.value)
                }
              />
            </Group>

            <Group grow mb="md" mt="md">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Tipo de persona
                </InputLabel>
                <MuiSelect
                  label="Tipo de persona"
                  onChange={(e) => handlePersonType(e.target.value)}
                  value={form.values.personType}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={2}>Persona Jurídica</MenuItem>
                  <MenuItem value={1}>Persona Natural</MenuItem>
                </MuiSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Tipo de documento
                </InputLabel>
                <MuiSelect
                  label="Tipo de documento"
                  onChange={(e) => handlIdType(e.target.value)}
                  value={form.values.idType}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={1}>Nit</MenuItem>
                  <MenuItem value={2}>Nit de otro país</MenuItem>
                  <MenuItem value={3}>Cédula de extranjería</MenuItem>
                  <MenuItem value={4}>Tarjeta de extranjería</MenuItem>
                  <MenuItem value={5}>
                    Documento de identificación extranjero
                  </MenuItem>
                  <MenuItem value={6}>Tarjeta de identidad</MenuItem>
                  <MenuItem value={7}>Cédula de ciudadanía</MenuItem>
                  <MenuItem value={8}>Registro civil</MenuItem>
                  <MenuItem value={9}>Pasaporte</MenuItem>
                </MuiSelect>
              </FormControl>
            </Group>

            <Group grow mb="md" mt="md">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Responsabilidad del IVA
                </InputLabel>
                <MuiSelect
                  label="Responsabilidad del IVA"
                  onChange={(e) => handleVatLiability(e.target.value)}
                  value={form.values.vatLiability}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={1}>No Responsable de IVA</MenuItem>
                  <MenuItem value={2}>Responsable de IVA</MenuItem>
                </MuiSelect>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Gerente de impuestos
                </InputLabel>
                <MuiSelect
                  label="Gerente de impuestos"
                  onChange={(e) => handleTaxManager(e.target.value)}
                  value={form.values.taxManager}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={1}>No responsable (R-99-PN)</MenuItem>
                  <MenuItem value={2}>
                    Régimen simple de tributación (O-47)
                  </MenuItem>
                  <MenuItem value={3}>Gran Contribuyente (O-13)</MenuItem>
                  <MenuItem value={4}>Autorretenedor (O-15)</MenuItem>
                  <MenuItem value={5}>Agente de retención (O-23)</MenuItem>
                </MuiSelect>
              </FormControl>
            </Group>
            <CustomSelect
              title="Departamento:"
              selectedOption={selectedOption}
              value={selectedOption}
              onChange={getMunicipios}
              options={options}
              theme={isDarkModeActive ? 'black' : 'white'}
            />
            <CustomSelect
              title="Ciudad/Municipio:"
              selectedOption={selectedMunicipiosOptions}
              value={selectedMunicipiosOptions}
              onChange={setSelectedMunicipiosOptions}
              options={municipiosOptions}
              theme={isDarkModeActive ? 'black' : 'white'}
            />
          </Group>
          <br></br>
          <Button
            style={{
              backgroundColor: 'green',
              borderRadius: '100px',
              isolation: 'isolate',
            }}
            onClick={() => updateCompany()}
            disabled={isCreateButtonDisabled}
          >
            {isCreateButtonDisabled ? (
              <CircularProgress size={15} color="inherit" />
            ) : null}
            Aceptar
          </Button>
        </form>
      </Container>
    </div>
  )
}
