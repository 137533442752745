import React, { useEffect, useState } from 'react'
import { Group } from '@mantine/core'
import Container from 'react-bootstrap/Container'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { setDecimal } from '../../../services/utils'
import { notify } from '../../../services/utils'
import {
  Pagination,
  MenuItem,
  Select as SelectPages,
  InputLabel,
  FormControl,
} from '@mui/material'
import useStore from '../../zustand'
import { getHistoricalByThirdClientApi } from '../../../pages/api/balancehistorical'

export function BalanceHistorical(props) {
  //-------------Balancehistorical----------------

  const [historical, setHistorical] = useState([])
  const getCurrentHistorical = async (offset, limit) => {
    let result = await getHistoricalByThirdClientApi(
      props.thirdClientId,
      offset,
      limit,
    )
    if (result.data != null) {
      setTotalBalanceNoteCount(result.count)
      setHistorical(result.data)
    } else {
      notify('warning', 'No hay histórico de balance para este cliente.')
    }
  }

  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(5)
  const [totalBalanceNoteCount, setTotalBalanceNoteCount] = useState(0)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getCurrentHistorical(newOffset, rpg)
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    getCurrentHistorical(0, event.target.value)
  }

  //-----------------------

  useEffect(() => {
    getCurrentHistorical(pg * rpg - rpg, rpg)
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('transparent')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#424242' : 'transparent')
  }, [isDarkModeActive])

  return (
    <Container>
      <Group grow mb="md" mt="md" style={{ display: 'inline' }}>
        <MDBTable small>
          <MDBTableHead>
            <tr>
              <th>Modificado por</th>
              <th>Nuevo Saldo</th>
              <th>Saldo anterior</th>
              <th>Fecha de creación</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
            {historical.map((historic, index) => {
              return (
                <tr key={index}>
                  <td>{historic.modifiedBy}</td>
                  <td>${setDecimal(historic.newBalance)}</td>
                  <td>${setDecimal(historic.wasBalance)}</td>
                  <td>{historic.createdAt}</td>
                </tr>
              )
            })}
          </MDBTableBody>
        </MDBTable>
      </Group>{' '}
      <Container>
        <FormControl variant="standard">
          <InputLabel id="demo-simple-select-standard-label">Filas</InputLabel>
          <SelectPages
            labelId="demo-simple-select-standard-label"
            autoWidth={true}
            value={rpg}
            onChange={handleChangeRowsPerPage}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </SelectPages>
        </FormControl>
        <Pagination
          count={Math.ceil(totalBalanceNoteCount / rpg)}
          page={pg}
          onChange={handleChangePage}
          showFirstButton={true}
          showLastButton={true}
          style={{ display: 'inline-block' }}
        />
      </Container>
    </Container>
  )
}
