import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getClientConceptsApi = async (companyId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/clientconcepts/${companyId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CCONCEPTS-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los conceptos.')
  }
}

export const PostClientConceptsApi = async (
  branchId,
  companyId,
  name,
  transactionType,
  conceptCode,
  paymentMethodId,
  resolutionId,
) => {
  try {
    let data = {
      branchId: branchId,
      companyId: companyId,
      name: name,
      transactionType: transactionType,
      conceptCode: conceptCode,
      paymentMethodId: paymentMethodId,
      resolutionId: resolutionId,
    }
    let rawResult = await fetch(`${apilUrl}/clientconcepts`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CCONCEPTS-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear el concepto.')
  }
}

export const UpdateClientConceptsApi = async (
  clientConceptsId,
  name,
  transactionType,
  conceptCode,
  paymentMethodId,
  resolutionId,
) => {
  try {
    let data = {
      Name: name,
      TransactionType: transactionType,
      ConceptCode: conceptCode,
      PaymentMethodId: paymentMethodId,
      ResolutionId: resolutionId,
    }
    let rawResult = await fetch(
      `${apilUrl}/clientconcepts/${clientConceptsId}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CCONCEPTS-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el concepto.')
  }
}

export const DeleteClientConceptsApi = async (clientConceptsId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/clientconcepts/${clientConceptsId}`,
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CCONCEPTS-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar el concepto.')
  }
}
