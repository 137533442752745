import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import { InputLabel, TextField } from '@mui/material'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import { Select as MuiSelect, MenuItem } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import LoadingScreen from '../../components/main/loadingScreen'
import { CirclePlus, Edit } from 'tabler-icons-react'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import Swal from 'sweetalert2'
import { isEmpty } from '../../services/utils'
import { CircularProgress } from '@mui/material'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import useStore from '../../components/zustand'
import ConfigurationMenus from '../../components/client/shortcutMenus/configurationMenus'
import { FormControl } from '@mui/material'
import {
  DeleteClientConceptsApi,
  PostClientConceptsApi,
  UpdateClientConceptsApi,
  getClientConceptsApi,
} from '../api/clientconcepts'
import { getClientResolutionsApi } from '../api/clientResolutions'
import ActionButton from '../../components/custom/actionButton'

export default function ClientConcepts() {
  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  //Clients Company
  const [clientConcepts, setClientConcepts] = useState([])

  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  const [modalPutShow, setModalPutShow] = React.useState(false)

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const EnumPaymentMethods = {
    1: 'CONTADO',
    2: 'CRÉDITO',
  }

  const form = useForm({
    initialValues: {
      name: '',
      transactionType: '',
      conceptCode: '',
      paymentMethodId: 0,
      resolutionId: 0,
    },
  })

  const putForm = useForm({
    initialValues: {
      id: '',
      name: '',
      transactionType: '',
      conceptCode: '',
      paymentMethodId: 0,
      resolutionId: 0,
    },
  })

  const getConceptData = async (
    id,
    name,
    transactionType,
    conceptCode,
    paymentMethodId,
    resolutionId,
  ) => {
    putForm.setFieldValue('id', id)
    putForm.setFieldValue('name', name)
    putForm.setFieldValue('transactionType', transactionType)
    putForm.setFieldValue('conceptCode', conceptCode)
    putForm.setFieldValue('paymentMethodId', paymentMethodId)
    putForm.setFieldValue('resolutionId', resolutionId)
    setModalPutShow(true)
  }

  const createClientConcept = async () => {
    setIsCreateButtonDisabled(true)
    let readyToCreate = true

    if (form.values.name == '') {
      readyToCreate = false
      notify('warning', 'Falta el nombre del concepto.')
    }

    if (form.values.transactionType == '') {
      readyToCreate = false
      notify('warning', 'Falta el tipo de transacción.')
    }

    if (form.values.conceptCode == '') {
      readyToCreate = false
      notify('warning', 'Falta el código del concepto.')
    }

    if (form.values.paymentMethodId == 0) {
      readyToCreate = false
      notify('warning', 'Falta el método de pago.')
    }

    if (form.values.resolutionId == 0) {
      readyToCreate = false
      notify('warning', 'Falta la resolución.')
    }

    if (readyToCreate) {
      let result = await PostClientConceptsApi(
        tokenData.branchId,
        tokenData.companyId,
        form.values.name,
        form.values.transactionType,
        form.values.conceptCode,
        form.values.paymentMethodId,
        form.values.resolutionId,
      )
      if (result.data == 'Clientconcepts created') {
        notify('success', 'Creación Exitosa')
        setModalCreateShow(false)
        resetConstants()
        await getClientConcepts(tokenData.companyId)
      } else {
        notify('warning', 'Error al crear el concepto.')
      }
    }
    setIsCreateButtonDisabled(false)
  }

  const resetConstants = () => {
    form.setFieldValue('name', '')
    form.setFieldValue('transactionType', '')
    form.setFieldValue('conceptCode', '')
    form.setFieldValue('paymentMethodId', 0)
    form.setFieldValue('resolutionId', 0)
  }

  const deleteClientConcept = async (id) => {
    Swal.fire({
      title: '¿Eliminar el concepto de venta seleccionado?',
      text: 'Nota: Esta acción puede provocar inconsistencias en los reportes de ventas',
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (response) => {
      if (response.isConfirmed) {
        let result = await DeleteClientConceptsApi(id)
        if (result.data == 'Clientconcepts deleted') {
          notify('success', 'Concepto eliminado')
          await getClientConcepts(tokenData.companyId)
        } else {
          notify('warning', 'Error al eliminar el concepto.')
        }
      }
    })
  }

  const getClientConcepts = async (companyId) => {
    let result = await getClientConceptsApi(companyId)
    result.data != null
      ? setClientConcepts(result.data)
      : notify('warning', 'No hay conceptos creados')
  }

  const updateClientConcepts = async () => {
    setIsCreateButtonDisabled(true)
    let readyToUpdate = true

    if (putForm.values.name == '') {
      readyToUpdate = false
      notify('warning', 'Falta el nombre del concepto.')
    }

    if (putForm.values.transactionType == '') {
      readyToUpdate = false
      notify('warning', 'Falta el tipo de transacción.')
    }

    if (putForm.values.conceptCode == '') {
      readyToUpdate = false
      notify('warning', 'Falta el código del concepto.')
    }

    if (putForm.values.paymentMethodId == 0) {
      readyToUpdate = false
      notify('warning', 'Falta el método de pago.')
    }

    if (putForm.values.resolutionId == 0) {
      readyToUpdate = false
      notify('warning', 'Falta la resolución.')
    }

    if (readyToUpdate) {
      let result = await UpdateClientConceptsApi(
        putForm.values.id,
        putForm.values.name,
        putForm.values.transactionType,
        putForm.values.conceptCode,
        putForm.values.paymentMethodId,
        putForm.values.resolutionId,
      )
      if (result.data == 'Clientconcepts updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await getClientConcepts(tokenData.companyId)
      } else {
        notify('warning', 'Error al actualizar el concepto.')
      }
    }
    setIsCreateButtonDisabled(false)
  }

  //--------------------Resolutions methods----------------------

  const [resolutions, setResolutions] = useState([])

  const getResolutions = async (branchId) => {
    const result = await getClientResolutionsApi(branchId)
    if (result.data != null) setResolutions(result.data)
    else notify('warning', 'No se encuentran resoluciones.')
  }

  const getResolutionById = (resolutionId) => {
    let resolution = resolutions.find((x) => x.id === resolutionId)
    if (resolution != null)
      return resolution.prefix + resolution.resolutionNumber
    else return ''
  }

  //---------------------

  const tokenVerified = async () => {
    //CHECK ROLES

    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await getResolutions(tokenData.branchId)
    await getClientConcepts(tokenData.companyId)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <ConfigurationMenus buttonId={7} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
          >
            Nuevo Concepto de Venta <CirclePlus size={20} color="#FFFFFF" />
          </Button>
          <br></br>
          <br></br>
          <MDBTable small>
            <MDBTableHead>
              <tr style={{ textAlign: 'center' }}>
                <th>Nombre</th>
                <th>Tipo de Transacción</th>
                <th>Código de Concepto</th>
                <th>Método de Pago</th>
                <th>Resolución</th>
                <th>Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {clientConcepts.map((item, index) => {
                return (
                  <tr style={{ textAlign: 'center' }} key={index}>
                    <td>{item.name}</td>
                    <td>{item.transactionType}</td>
                    <td>{item.conceptCode}</td>
                    <td>{EnumPaymentMethods[item.paymentMethodId]}</td>
                    <td>{getResolutionById(item.resolutionId)}</td>
                    <td>
                      <ActionButton
                        description="Editar"
                        backgroundColor={bgColor}
                        onClick={() =>
                          getConceptData(
                            item.id,
                            item.name,
                            item.transactionType,
                            item.conceptCode,
                            item.paymentMethodId,
                            item.resolutionId,
                          )
                        }
                      >
                        <Edit size={30} color={color} />
                      </ActionButton>
                      {/* <Button
                        style={{
                          float: 'right',
                          backgroundColor: 'transparent',
                          isolation: 'isolate',
                        }}
                        onClick={() => deleteClientConcept(item.id)}
                      >
                        <Trash size={30} color="red" />
                      </Button> */}
                    </td>
                  </tr>
                )
              })}
            </MDBTableBody>
          </MDBTable>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando Concepto
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Nombre"
                      placeholder="Nombre"
                      value={putForm.values.name}
                      onChange={(event) =>
                        putForm.setFieldValue('name', event.currentTarget.value)
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Tipo de Transacción"
                      placeholder="Tipo de Transacción"
                      value={putForm.values.transactionType}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'transactionType',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Código de Concepto"
                      placeholder="Código de Concepto"
                      value={putForm.values.conceptCode}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'conceptCode',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Método de Pago
                      </InputLabel>
                      <MuiSelect
                        required
                        label="Método de Pago"
                        value={putForm.values.paymentMethodId}
                        style={{
                          appearance: 'none',
                          border: '0px',
                          background: 'none',
                        }}
                        onChange={(e) =>
                          putForm.setFieldValue(
                            'paymentMethodId',
                            e.target.value,
                          )
                        }
                      >
                        <MenuItem value={1}>CONTADO</MenuItem>
                        <MenuItem value={2}>CRÉDITO</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Resolución
                    </InputLabel>
                    <MuiSelect
                      required
                      label="Resolución"
                      value={putForm.values.resolutionId}
                      style={{
                        appearance: 'none',
                        border: '0px',
                        background: 'none',
                      }}
                      onChange={(e) =>
                        putForm.setFieldValue('resolutionId', e.target.value)
                      }
                    >
                      {resolutions.map((resolution, index) => (
                        <MenuItem key={index} value={resolution.id}>
                          {resolution.prefix + '' + resolution.resolutionNumber}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateClientConcepts()}
                disabled={isCreateButtonDisabled}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Concepto de Venta
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Nombre"
                      placeholder="Nombre"
                      value={form.values.name}
                      onChange={(event) =>
                        form.setFieldValue('name', event.currentTarget.value)
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Tipo de Transacción"
                      placeholder="Tipo de Transacción"
                      value={form.values.transactionType}
                      onChange={(event) =>
                        form.setFieldValue(
                          'transactionType',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Método de Pago
                      </InputLabel>
                      <MuiSelect
                        required
                        label="Método de Pago"
                        value={form.values.paymentMethodId}
                        style={{
                          appearance: 'none',
                          border: '0px',
                          background: 'none',
                        }}
                        onChange={(e) =>
                          form.setFieldValue('paymentMethodId', e.target.value)
                        }
                      >
                        <MenuItem value={1}>CONTADO</MenuItem>
                        <MenuItem value={2}>CRÉDITO</MenuItem>
                      </MuiSelect>
                    </FormControl>
                    <TextField
                      variant="standard"
                      required
                      label="Código de Concepto"
                      placeholder="Código de Concepto"
                      value={form.values.conceptCode}
                      onChange={(event) =>
                        form.setFieldValue(
                          'conceptCode',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Resolución
                    </InputLabel>
                    <MuiSelect
                      required
                      label="Resolución"
                      style={{
                        appearance: 'none',
                        border: '0px',
                        background: 'none',
                      }}
                      value={form.values.resolutionId}
                      onChange={(e) =>
                        form.setFieldValue('resolutionId', e.target.value)
                      }
                    >
                      {resolutions.map((resolution, index) => (
                        <MenuItem key={index} value={resolution.id}>
                          {resolution.prefix + '' + resolution.resolutionNumber}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createClientConcept()}
                disabled={isCreateButtonDisabled}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalCreateShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
