import { React } from 'react'
import { Routes, HashRouter, Route } from 'react-router-dom'
import Login from './pages/register/login.js'
import Notfound from './pages/main/not-found.js'
import Dashboard from './pages/main/dashboard.js'
import State from './pages/statistics/state.js'
import NewSale from './pages/sales/newsale.js'
import Sales from './pages/sales/sales.js'
import Invoice from './pages/ebilling/invoice.js'
import Tanks from './pages/station/tanks.js'
import Hoses from './pages/station/hoses.js'
import Islands from './pages/station/islands.js'
import Clients from './pages/management/clients.js'
import DriverBus from './pages/management/driver-bus.js'
import GallonPrice from './pages/configuration/gallonprice.js'
import Products from './pages/configuration/products.js'
import Mobility from './pages/configuration/mobility.js'
import Company from './pages/configuration/company.js'
import MoneyDelivery from './pages/management/moneydelivery.js'
import GetStarted from './pages/register/getStarted/get-started.js'
import DebitNote from './pages/ebilling/debitnote.js'
import CreditNote from './pages/ebilling/creditnote.js'
import Statistics from './pages/statistics/statistics.js'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './App.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import CompareSales from './pages/sales/comparesales.js'
import ReceiptDispenser from './pages/configuration/receiptdispenser.js'
import useStore from './components/zustand'
import { ThemeProvider, createTheme } from '@mui/material'
import LedgerAccountsConfig from './pages/configuration/ledgerAccounts.js'
import PaymentMethod from './pages/configuration/paymentMethod.js'
import ClientConcepts from './pages/configuration/clientconcepts.js'
import Register from './pages/register/register.js'
import Landing from './pages/main/landing.js'
import Employees from './pages/payroll/employees.js'
import Settlement from './pages/payroll/settlement.js'
import PeriodsHistorical from './pages/payroll/periodsHistorical.js'

const App = () => {
  const { isDarkModeActive } = useStore()

  return (
    <ThemeProvider
      theme={createTheme({
        palette: { mode: isDarkModeActive ? 'dark' : 'light' },
      })}
    >
      <div className="App">
        <div style={{ width: '100%' }}>
          <HashRouter>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/state" element={<State />} />
              <Route path="/*" element={<Notfound />} />
              <Route path="/newsale" element={<NewSale />} />
              <Route path="/sales" element={<Sales />} />
              <Route path="/invoice" element={<Invoice />} />
              <Route path="/debitnote" element={<DebitNote />} />
              <Route path="/tanks" element={<Tanks />} />
              <Route path="/islands" element={<Islands />} />
              <Route path="/hoses" element={<Hoses />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/driver-bus" element={<DriverBus />} />
              <Route path="/gallonprice" element={<GallonPrice />} />
              <Route path="/products" element={<Products />} />
              <Route path="/mobility" element={<Mobility />} />
              <Route path="/company" element={<Company />} />
              <Route path="/employees" element={<Employees />} />
              <Route path="/moneydelivery" element={<MoneyDelivery />} />
              <Route path="/get-started" element={<GetStarted />} />
              <Route path="/creditnote" element={<CreditNote />} />
              {/* <Route path="/statistics" element={<Statistics />} /> */}
              <Route path="/comparesales" element={<CompareSales />} />
              <Route path="/receiptdispenser" element={<ReceiptDispenser />} />
              <Route path="/paymentMethod" element={<PaymentMethod />} />
              <Route path="/clientConcepts" element={<ClientConcepts />} />
              <Route path="/settlement" element={<Settlement />} />
              <Route
                path="/ledgeraccounts"
                element={<LedgerAccountsConfig />}
              />
              <Route path="/periods" element={<PeriodsHistorical />} />
            </Routes>
          </HashRouter>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
