import React from 'react'
import { useEffect, useState } from 'react'
import Menu from '../../components/client/menu.js'
import { notify } from '../../services/utils.js'
import { useNavigate } from 'react-router'
import useStore from '../../components/zustand/index.js'
import { isEmpty } from '../../services/utils.js'
import LoadingScreen from '../../components/main/loadingScreen'

export default function Notfound() {
  const { rolesData } = useStore()
  const navigate = useNavigate()
  //Roles

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.sales) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [rolesData])

  return (
    <div>
      <Menu />
      {!loading ? (
        <div style={{ textAlign: 'center' }}>
          <h1>Página no encontrada</h1>
          <h2>Error 404</h2>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  )
}
