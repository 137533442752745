import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const PostDebitNoteApi = async (DebitNoteData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/debit-note`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(DebitNoteData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"DN-1"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear la nota crédito.')
  }
}

export const getDebitNotesApi = async (
  companyId,
  branchId,
  resolutionId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/debit-note/all/${companyId}/${branchId}/${resolutionId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"DN-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las notas crédito.')
  }
}

export const DeleteDebitNoteApi = async (
  debitNoteId,
  companyId,
  debitNoteNumeration,
  invoiceNumber,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/debit-note/${debitNoteId}/${companyId}/${debitNoteNumeration}/${invoiceNumber}`,
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"DN-3"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar la nota crédito.')
  }
}
export const getFilteredDebitNotesByInvoiceApi = async (
  companyId,
  branchId,
  invoiceString,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/debit-note/filter/invoice-number/${companyId}/${branchId}/${invoiceString}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"DN-4"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las notas crédito.')
  }
}

export const getFilteredDebitNotesByDateApi = async (
  companyId,
  branchId,
  thirdClientId,
  startDate,
  endDate,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/debit-note/filter/${companyId}/${branchId}/${thirdClientId}/${startDate}/${endDate}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"DN-5"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las notas crédito.')
  }
}
