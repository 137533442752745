import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group, Text, Paper } from '@mantine/core'
import { CircularProgress, TextField } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Edit, CirclePlus, Trash } from 'tabler-icons-react'
import { isEmpty, setDecimal } from '../../services/utils'
import saleIcon from '../../resources/images/factura.png'
import LoadingScreen from '../../components/main/loadingScreen'
import SaleModal from '../../components/client/sales/modals/saleModal'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import {
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Select as SelectPages,
} from '@mui/material'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import ConfigurationMenus from '../../components/client/shortcutMenus/configurationMenus'
import { getClientSalesByIdApi } from '../api/clientsale'
import { GetClientMobilitySaleApi } from '../api/clientMobilitySale'
import {
  DeleteClientMobilityApi,
  PostClientMobilityApi,
  UpdateClientMobilityApi,
  getClientMobilityApi,
} from '../api/clientMobility'
import ActionButton from '../../components/custom/actionButton'

export default function Mobility() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  //Clients movility
  const [movility, setIslands] = useState([])

  //Clients movility data
  const [movilityData, setMovilityData] = useState([])

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  //DELETE MODAL
  const [modalDeleteShow, setModalDeleteShow] = React.useState(false)

  const [clientMovilityId, setClientMovilityId] = React.useState(0)

  const [isThereClientMovility, setIsThereClientMovility] = useState(false)

  const form = useForm({
    initialValues: {
      provider: '',
      total: 0,
      remaining: 0,
    },
  })

  const putForm = useForm({
    initialValues: {
      provider: '',
      total: 0,
      remaining: 0,
      movilityId: 0,
    },
  })

  //GETS MOVILITY FIELDS
  const getMovilityData = async (movilityId, provider, total, remaining) => {
    putForm.setFieldValue('movilityId', movilityId)
    putForm.setFieldValue('provider', provider)
    putForm.setFieldValue('total', total)
    putForm.setFieldValue('remaining', remaining)
    setModalPutShow(true)
  }

  const successCreation = async () => {
    notify('success', 'Creación Exitoso')
    await getClientMobility(tokenData.companyId, tokenData.branchId)
    setModalCreateShow(false)
    setIsCreateButtonDisabled(false)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createMobility = async () => {
    if (form.values.provider == '' || parseInt(form.values.total) <= 0) {
      notify('warning', 'Por favor llena todos los campos.')
    } else {
      setIsCreateButtonDisabled(true)
      let result = await PostClientMobilityApi(
        tokenData.companyId,
        tokenData.branchId,
        form.values.provider,
        form.values.total,
        form.values.remaining,
      )
      if (result.data != null) successCreation()
      else notify('warning', 'Error al crear la movilidad.')
    }
  }

  const getClientMobility = async (companyId, branchId) => {
    let result = await getClientMobilityApi(companyId, branchId)
    if (result.data != null) {
      setIslands(result.data)
      setIsThereClientMovility(false)
    } else setIsThereClientMovility(true)
  }

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const updateClientMovility = async () => {
    if (
      putForm.values.provider == '' ||
      parseInt(putForm.values.total) <= 0 ||
      parseInt(putForm.values.remaining) <= 0
    ) {
      notify('warning', 'Por favor llena todos los campos.')
    } else {
      setIsUpdateButtonDisabled(true)
      let result = await UpdateClientMobilityApi(
        putForm.values.movilityId,
        putForm.values.provider,
        putForm.values.total,
        putForm.values.remaining,
      )

      if (result.data == 'Clientmobility updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)

        await getClientMobility(tokenData.companyId, tokenData.branchId)
      } else {
        notify('warning', 'Error al actualizar')
      }
    }
    setIsUpdateButtonDisabled(false)
  }

  const deleteClientMovility = (clientMovilityId) => {
    setClientMovilityId(clientMovilityId)
    setModalDeleteShow(true)
  }

  const deleteMobility = async () => {
    let result = await DeleteClientMobilityApi(clientMovilityId)
    if (result.data == 'Clientmobility deleted') {
      notify('success', 'Movilidad Eliminada')
      setModalDeleteShow(false)
      await getClientMobility(tokenData.companyId, tokenData.branchId)
    } else {
      notify('warning', 'Error al eliminar')
    }
  }

  const getMobilityData = async (companyId, branchId, offset, limit) => {
    let result = await GetClientMobilitySaleApi(
      companyId,
      branchId,
      offset,
      limit,
    )
    if (result.data != null) {
      setMovilityData(result.data)
      setSaleCount(result.count)
    } else {
      setMovilityData([])
      setSaleCount(0)
      notify('warning', 'No hay ventas de movilidad.')
    }
  }

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }

    await getClientMobility(tokenData.companyId, tokenData.branchId)

    await getMobilityData(tokenData.companyId, tokenData.branchId, 0, 10)
  }

  //-----------------Pagination-------------------
  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(10)
  const [saleCount, setSaleCount] = useState(0)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg

    await getMobilityData(
      tokenData.companyId,
      tokenData.branchId,
      newOffset,
      rpg,
    )
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)

    getMobilityData(
      tokenData.companyId,
      tokenData.branchId,
      0,
      event.target.value,
    )
  }

  //--------------------Sale ---------------

  const [saleInfo, setSaleInfo] = useState([])

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleCloseSale = () => setShow(false)

  const showSale = async (saleId) => {
    let result = await getClientSalesByIdApi(saleId)
    if (result.data != null) {
      setSaleInfo(result.data)
      handleShow()
    } else {
      notify('warning', 'Error, no se encuentra la venta asociada.')
    }
  }

  //-------------------------------------------

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <ConfigurationMenus buttonId={3} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
          >
            Nueva Movilidad <CirclePlus size={20} color="#FFFFFF" />
          </Button>
          <br></br>
          <Row xs={1} md={3} className="g-4">
            {isThereClientMovility
              ? null
              : movility.map((movility, index) => {
                  return (
                    <Col key={index}>
                      <Paper withBorder radius="md" p="xs">
                        <Button
                          style={{
                            float: 'right',
                            backgroundColor: bgColor,
                            margin: '5px',
                            padding: '10px',
                          }}
                          onClick={() => deleteClientMovility(movility.id)}
                        >
                          <Trash
                            size={30}
                            color="red"
                            style={{ isolation: 'isolate' }}
                          />
                          <Text
                            style={{
                              fontSize: '12px',
                              color: color,
                            }}
                          >
                            Eliminar
                          </Text>
                        </Button>
                        <ActionButton
                          description="Editar"
                          backgroundColor={bgColor}
                          onClick={() =>
                            getMovilityData(
                              movility.id,
                              movility.provider,
                              movility.total,
                              movility.remaining,
                            )
                          }
                        >
                          <Edit size={30} color={color} />
                        </ActionButton>
                        <Group>
                          <div>
                            <Text
                              color="dimmed"
                              size="xl"
                              transform="uppercase"
                              weight={700}
                            >
                              {movility.provider}
                            </Text>
                            <Text
                              color="dimmed"
                              size="md"
                              transform="uppercase"
                              weight={700}
                            >
                              Deuda : ${setDecimal(movility.total)}
                            </Text>
                            <Text
                              color="dimmed"
                              size="md"
                              transform="uppercase"
                              weight={700}
                            >
                              Deuda Restante: ${setDecimal(movility.remaining)}
                            </Text>
                            <Text weight={700} size="md">
                              {movility.createdAt}
                            </Text>
                          </div>
                        </Group>
                      </Paper>
                    </Col>
                  )
                })}
          </Row>

          <br></br>
          <br></br>
          <MDBTable small>
            <MDBTableHead>
              <tr>
                <th>Movilidad</th>
                {/* <th>Galones</th>
              // 
              <th>Galones Restantes</th> */}
                <th>Galones Vendidos</th>
                <th>Total</th>
                {/* <th>Registro de manguera</th> */}
                <th>Hora de venta</th>
                <th>Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {movilityData.map((mobility, index) => {
                return (
                  <tr key={index}>
                    <td>{mobility.thirdClientCompanyName.toUpperCase()}</td>
                    {/* <td>{sale.actualGallons}</td>
                    // 
                    <td>{sale.gallonsLeft}</td> */}
                    <td>{mobility.gallonsSold}</td>
                    <td>${setDecimal(mobility.total)}</td>
                    {/* <td>{sale.grabAt}</td> */}
                    <td>{mobility.createdAt}</td>
                    <td>
                      <a
                        onClick={() => showSale(mobility.saleId)}
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          style={{
                            float: 'right',
                            float: 'inline-start',
                            alignContent: 'center',
                            cursor: 'pointer',
                            width: '40px',
                          }}
                          src={saleIcon}
                        />
                      </a>
                    </td>
                  </tr>
                )
              })}
            </MDBTableBody>
          </MDBTable>
          {show ? (
            <SaleModal sales={saleInfo} show={show} onClose={handleCloseSale} />
          ) : null}
          <Container>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Filas
              </InputLabel>
              <SelectPages
                labelId="demo-simple-select-standard-label"
                autoWidth={true}
                value={rpg}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </SelectPages>
            </FormControl>
            <Pagination
              count={Math.ceil(saleCount / rpg)}
              page={pg}
              onChange={handleChangePage}
              showFirstButton={true}
              showLastButton={true}
              style={{ display: 'inline-block' }}
              defaultPage={pg}
            />
          </Container>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nueva Movilidad
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Proveedor"
                    placeholder="Proveedor"
                    value={form.values.provider}
                    onChange={(event) =>
                      form.setFieldValue('provider', event.currentTarget.value)
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Deuda"
                    placeholder="Deuda"
                    type="number"
                    value={form.values.total}
                    onChange={(event) => {
                      event.currentTarget.value >= 0
                        ? form.setFieldValue('total', event.currentTarget.value)
                        : form.setFieldValue('total', '')
                    }}
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Deuda Restante"
                    placeholder="Deuda Restante"
                    type="number"
                    value={form.values.remaining}
                    onChange={(event) => {
                      event.currentTarget.value >= 0
                        ? form.setFieldValue(
                            'remaining',
                            event.currentTarget.value,
                          )
                        : form.setFieldValue('total', '')
                    }}
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createMobility()}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalCreateShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Proveedor"
                    placeholder="Proveedor"
                    value={putForm.values.provider}
                    onChange={(event) =>
                      putForm.setFieldValue(
                        'provider',
                        event.currentTarget.value,
                      )
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Deuda"
                    placeholder="Deuda"
                    type="number"
                    value={putForm.values.total}
                    onChange={(event) => {
                      event.currentTarget.value >= 0
                        ? putForm.setFieldValue(
                            'total',
                            event.currentTarget.value,
                          )
                        : putForm.setFieldValue('total', '')
                    }}
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Deuda Restante"
                    placeholder="Deuda Restante"
                    type="number"
                    value={putForm.values.remaining}
                    onChange={(event) => {
                      event.currentTarget.value >= 0
                        ? putForm.setFieldValue(
                            'remaining',
                            event.currentTarget.value,
                          )
                        : putForm.setFieldValue('remaining', '')
                    }}
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateClientMovility()}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={modalDeleteShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Eliminando Movilidad
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Seguro que quieres eliminar esta Movilidad?</p>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => deleteMobility()}
              >
                Eliminar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalDeleteShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
