import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import { TextField } from '@mui/material'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import LoadingScreen from '../../components/main/loadingScreen'
import { CirclePlus, Edit, Trash, Upload } from 'tabler-icons-react'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import Swal from 'sweetalert2'
import { isEmpty } from '../../services/utils'
import { CircularProgress } from '@mui/material'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import useStore from '../../components/zustand'
import ConfigurationMenus from '../../components/client/shortcutMenus/configurationMenus'
import {
  DeleteLedgerAccountsApi,
  PostLedgerAccountsApi,
  getLedgerAccountsApi,
  updateLedgerAccountsApi,
} from '../api/iltda'
import LedgerAccountsUploadModal from '../../components/client/modals/ledgerAccountsUploadModal'
import ActionButton from '../../components/custom/actionButton'

export default function LedgerAccountsConfig() {
  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  //Clients Company
  const [ledgerAccounts, setLedgerAccounts] = useState([])

  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  const [modalPutShow, setModalPutShow] = React.useState(false)

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  //UPLOAD MODAL
  const [showUploadModal, setShowUploadModal] = useState(false)
  const handleShowUploadModal = () => setShowUploadModal(true)
  const handleCloseUpload = async () => {
    setShowUploadModal(false)
    await getLedgerAccounts(tokenData.companyId)
  }

  const form = useForm({
    initialValues: {
      id: '',
      ledgerAccountCode: '',
      Details: '',
    },
  })

  const putForm = useForm({
    initialValues: {
      id: '',
      ledgerAccountCode: '',
      Details: '',
    },
  })

  //GETS ISLAND FIELDS
  const getCompanyData = async (id, ledgerAccountCode, Details) => {
    putForm.setFieldValue('id', id)
    putForm.setFieldValue('ledgerAccountCode', ledgerAccountCode)
    putForm.setFieldValue('Details', Details)
    setModalPutShow(true)
  }

  const createLedgerAccount = async () => {
    setIsCreateButtonDisabled(true)
    let readyToCreate = true

    if (form.values.ledgerAccountCode == '') {
      readyToCreate = false
      notify('warning', 'Falta el código de cuenta contable.')
    }
    if (form.values.Details == '') {
      readyToCreate = false
      notify('warning', 'Falta detalles de la cuenta.')
    }
    if (readyToCreate) {
      let result = await PostLedgerAccountsApi(
        tokenData.branchId,
        tokenData.companyId,
        form.values.ledgerAccountCode,
        form.values.Details,
      )
      if (result.data == 'Clientiltda created') {
        notify('success', 'Creación Exitosa')
        setModalCreateShow(false)
        resetConstants()
        await getLedgerAccounts(tokenData.companyId)
      } else {
        notify('warning', 'Error al crear la cuenta contable.')
      }
    }
    setIsCreateButtonDisabled(false)
  }

  const resetConstants = () => {
    form.setFieldValue('id', '')
    form.setFieldValue('ledgerAccountCode', '')
    form.setFieldValue('Details', '')
  }

  const deleteLedgerAccounts = async (id) => {
    Swal.fire({
      title: '¿Eliminar la cuenta contable seleccionada?',
      text: 'Nota: Esta acción puede provocar inconsistencias en los reportes de ventas',
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (response) => {
      if (response.isConfirmed) {
        let result = await DeleteLedgerAccountsApi(id)
        if (result.data == 'Iltda deleted') {
          notify('success', 'Cuenta Contable Eliminada')
          await getLedgerAccounts(tokenData.companyId)
        } else {
          notify('warning', 'Error al eliminar la cuenta contable.')
        }
      }
    })
  }

  const getLedgerAccounts = async (companyId) => {
    let result = await getLedgerAccountsApi(companyId)
    result.data != null
      ? setLedgerAccounts(result.data)
      : notify('warning', 'No hay cuentas contables creadas')
  }

  const updateLedgerAccount = async () => {
    setIsCreateButtonDisabled(true)
    let readyToUpdate = true

    if (putForm.values.ledgerAccountCode == '') {
      readyToUpdate = false
      notify('warning', 'Falta el código de cuenta contable.')
    }
    if (putForm.values.Details == '') {
      readyToUpdate = false
      notify('warning', 'Falta detalles de la cuenta.')
    }
    if (readyToUpdate) {
      let result = await updateLedgerAccountsApi(
        tokenData.branchId,
        tokenData.companyId,
        putForm.values.id,
        putForm.values.ledgerAccountCode,
        putForm.values.Details,
      )
      if (result.data == 'Iltda updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await getLedgerAccounts(tokenData.companyId)
      } else {
        notify('warning', 'Error al actualizar la cuenta contable.')
      }
    }
    setIsCreateButtonDisabled(false)
  }

  const tokenVerified = async () => {
    //CHECK ROLES

    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await getLedgerAccounts(tokenData.companyId)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <ConfigurationMenus buttonId={5} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
          >
            Nueva Cuenta Contable <CirclePlus size={20} color="#FFFFFF" />
          </Button>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              float: 'right',
              marginBottom: 10,
              marginRight: 5,
              isolation: 'isolate',
            }}
            onClick={handleShowUploadModal}
          >
            <Upload size={20} color="white" /> Carga Masiva
          </Button>
          <br></br>
          <br></br>
          <MDBTable small>
            <MDBTableHead>
              <tr style={{ textAlign: 'center' }}>
                <th>Cuenta contable</th>
                <th>Detalles</th>
                <th>Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {ledgerAccounts.map((item, index) => {
                return (
                  <tr style={{ textAlign: 'center' }} key={index}>
                    <td>{item.ledgerAccountCode}</td>
                    <td>{item.Detail}</td>
                    <td>
                      <ActionButton
                        description="Editar"
                        backgroundColor={bgColor}
                        onClick={() =>
                          getCompanyData(
                            item.id,
                            item.ledgerAccountCode,
                            item.Detail,
                          )
                        }
                      >
                        <Edit size={30} color={color} />
                      </ActionButton>
                      {/* <Button
                        style={{
                          float: 'right',
                          backgroundColor: 'transparent',
                          isolation: 'isolate',
                        }}
                        onClick={() => deleteLedgerAccounts(item.id)}
                      >
                        <Trash size={30} color="red" />
                      </Button> */}
                    </td>
                  </tr>
                )
              })}
            </MDBTableBody>
          </MDBTable>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      {showUploadModal ? (
        <LedgerAccountsUploadModal
          show={showUploadModal}
          onClose={handleCloseUpload}
        />
      ) : null}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando cuenta contable
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Código de cuenta contable"
                      placeholder="Código de cuenta contable"
                      value={putForm.values.ledgerAccountCode}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'ledgerAccountCode',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Detalles"
                      placeholder="Detalles"
                      value={putForm.values.Details}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'Details',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateLedgerAccount()}
                disabled={isCreateButtonDisabled}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Cuenta Contable
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Código de cuenta contable"
                      placeholder="Código de cuenta contable"
                      value={form.values.ledgerAccountCode}
                      onChange={(event) =>
                        form.setFieldValue(
                          'ledgerAccountCode',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Detalles"
                      placeholder="Detalles"
                      value={form.values.Details}
                      onChange={(event) =>
                        form.setFieldValue('Details', event.currentTarget.value)
                      }
                    />
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createLedgerAccount()}
                disabled={isCreateButtonDisabled}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalCreateShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
