import { ExpandMore } from '@mui/icons-material'
import { useState } from 'react'

export default function CustomExpandMore(props) {
  const [isExpanded, setExpanded] = useState(false)
  const handleOnClick = () => {
    setExpanded(!isExpanded)
  }

  return isExpanded ? (
    <>
      <tr>
        <div style={{ backgroundColor: '#F5F5F5' }} onClick={handleOnClick}>
          <ExpandMore
            style={{ transform: isExpanded ? 'scaleY(-1)' : 'scaleY(1)' }}
          />
          {props.title}
        </div>
      </tr>
      {props.children}
    </>
  ) : (
    <tr>
      <div style={{ backgroundColor: '#F5F5F5' }} onClick={handleOnClick}>
        <ExpandMore
          style={{ transform: isExpanded ? 'scaleY(-1)' : 'scaleY(1)' }}
        />
        {props.title}
      </div>
    </tr>
  )
}
