import React, { useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import { BalanceHistorical } from './balanceHistorical'
import { CreditHistorical } from './creditHistorical'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import useStore from '../../zustand'

export function HistoricalModal(props) {
  const { show, onClose } = props

  const [basicActive, setBasicActive] = useState('tab1')

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return
    }

    setBasicActive(value)
  }

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size="xl">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Histórico de crédito para {props.thirdClientCompanyName}
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <Container>
              <MDBTabs className="mb-3">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleBasicClick('tab1')}
                    active={basicActive === 'tab1'}
                    style={{ backgroundColor: 'transparent', color: color }}
                  >
                    Histórico del Crédito
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleBasicClick('tab2')}
                    active={basicActive === 'tab2'}
                    style={{ backgroundColor: 'transparent', color: color }}
                  >
                    Histórico del Saldo
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
              <MDBTabsContent>
                <MDBTabsPane show={basicActive === 'tab1'}>
                  <CreditHistorical
                    thirdClientCompanyName={props.thirdClientCompanyName}
                    thirdClientId={props.thirdClientId}
                  />
                </MDBTabsPane>
                <MDBTabsPane show={basicActive === 'tab2'}>
                  <BalanceHistorical
                    thirdClientCompanyName={props.thirdClientCompanyName}
                    thirdClientId={props.thirdClientId}
                  />
                </MDBTabsPane>
              </MDBTabsContent>
            </Container>
          </MDBModalBody>
          <br></br>
          <MDBModalFooter>
            <br></br>
            <br></br>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
            <br></br>
            <br></br>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}
