import React, { useState, useEffect } from 'react'
import useStore from '../../zustand'
import { CircularProgress } from '@mui/material'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import {
  dayjsDateToLocale,
  getTypeKeyByValue,
  notify,
} from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { Container } from 'react-bootstrap'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  contractTypes,
  documentTypes,
  paymentMeansTypes,
  periodicityTypes,
  workerTypes,
} from '../../../services/selectData/clientUsersData'
import { departmentsData } from '../../../services/selectData/placesData'
import { apilUrl } from '../../../services/url'
import CustomSelect from '../../custom/customSelect'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useForm } from '@mantine/hooks'
import { PostPayrollApi } from '../../../pages/api/payroll'
import Notes from '../../custom/notes'
import { getClientResolutionsApi } from '../../../pages/api/clientResolutions'
import ClientUserInfo from '../clientUsers/clientUserInfo'
import ClientUserNovelties from '../clientUsers/clientUserNovelties'

const CreateSettlement = ({
  onClose,
  clientUsers,
  branches,
  selectedInterval,
  getClientUsers,
}) => {
  const { tokenData, isDarkModeActive } = useStore()
  const [citiesOptions, setCitiesOptions] = React.useState([])

  const getMunicipios = async (municipio) => {
    try {
      let result = await fetch(`${apilUrl}/municipios/filters/${municipio}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
      setCitiesOptions(result.data)
    } catch (error) {
      return error
    }
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const [clientUsersData, setClientUsersData] = useState([])
  const setClientUsersOptions = (data, label) => {
    let options = []
    data.forEach((element) => {
      options.push({
        value: element.id,
        label: element[label],
      })
    })
    return options
  }

  const [selectedClientUser, setSelectedClientUser] = useState({
    companyId: 0,
    branchId: 0,
    idDepartment: 0,
    userId: 0,
    userIdType: { Int32: 0 },
    name: { String: '' },
    otherNames: { String: '' },
    lastName: { String: '' },
    secondLastName: { String: '' },
    country: { Int32: 0 },
    idDepartment: { Int32: 0 },
    cityCode: { Int32: 0 },
    address: { String: '' },
    phone: { String: '' },
    email: '',
    salary: 0,
    workerType: 0,
    subCode: 0,
    periodicity: 0,
    contractType: 0,
    area: 0,
    startDate: dayjs(new Date()),
    fireDate: dayjs(new Date()),
    branchId: 0,
    position: '',
    highRisk: false,
    integralSalary: false,
  })
  const [selectedClientOption, setSelectedClientOption] = useState(null)
  const handleClientUserChange = (value) => {
    setSelectedClientUser(clientUsers.find((user) => user.id === value.value))
    setSelectedClientOption(value)
  }

  const payrollForm = useForm({
    initialValues: {
      initialSettlementDate: dayjs(new Date()),
      finalSettlementDate: dayjs(new Date()),
      issueDate: dayjs(new Date()),
      paymentDate: dayjs(new Date()),
    },
  })

  useEffect(() => {
    if (selectedInterval != undefined)
      payrollForm.setValues({
        initialSettlementDate: dayjs(
          new Date(
            selectedInterval.year,
            selectedInterval.month - 1,
            selectedInterval.start,
          ),
        ),
        finalSettlementDate: dayjs(
          new Date(
            selectedInterval.year,
            selectedInterval.month - 1,
            selectedInterval.end,
          ),
        ),
        issueDate: dayjs(new Date()),
        paymentDate: dayjs(new Date()),
      })
  }, [selectedInterval])

  const [settlementNotes, setSettlementNotes] = useState([])

  const createPayroll = async () => {
    setIsCreateButtonDisabled(true)
    if (selectedClientUser == null) {
      notify('warning', 'Seleccione un empleado.')
      setIsCreateButtonDisabled(false)
      return
    }

    let payrollData = {
      payroll: {
        env: 'PRODUCCION', // quemado
        prefix: '', // quemado
        number: 0, // quemado
        salary: selectedClientUser.salary,
        periodicity: getTypeKeyByValue(
          periodicityTypes,
          'code',
          selectedClientUser.periodicity,
        ),
        'initial-settlement-date': dayjsDateToLocale(
          payrollForm.values.finalSettlementDate,
        ),
        'final-settlement-date': dayjsDateToLocale(
          payrollForm.values.finalSettlementDate,
        ),
        'issue-date': dayjsDateToLocale(payrollForm.values.issueDate),
        'payment-date': dayjsDateToLocale(payrollForm.values.paymentDate),
        notes: settlementNotes.map((note) => {
          return { text: note }
        }),
        accruals: JSON.parse(selectedClientUser.accruals),
        deductions: JSON.parse(selectedClientUser.deductions),
        employee: {
          code: selectedClientUser.id.toString(),
          email: selectedClientUser.email,
          'payment-means': getTypeKeyByValue(
            paymentMeansTypes,
            'code',
            selectedClientUser.paymentMeans,
          ),
          'worker-type': getTypeKeyByValue(
            workerTypes,
            'code',
            selectedClientUser.workerType,
          ),
          'sub-code':
            selectedClientUser.subCode == 1
              ? 'NO_APLICA'
              : 'DEPENDIENTE_PENSIONADO_POR_VEJEZ_ACTIVO',
          'start-date': dayjsDateToLocale(selectedClientUser.startDate),
          'fire-date': dayjsDateToLocale(selectedClientUser.fireDate),
          'high-risk': selectedClientUser.highRisk,
          'integral-salary': selectedClientUser.integralSalary,
          'contract-type': getTypeKeyByValue(
            contractTypes,
            'code',
            selectedClientUser.contractType,
          ),
          'identification-type': getTypeKeyByValue(
            documentTypes,
            'code',
            selectedClientUser.userIdType.Int32,
          ),
          identification: selectedClientUser.userId,
          'first-name': selectedClientUser.name.String,
          'other-names': selectedClientUser.otherNames.String,
          'last-name': selectedClientUser.lastName.String,
          'second-last-name': selectedClientUser.secondLastName.String,
          bank: 'test bank 1', // quemado
          'account-type-kw':
            selectedClientUser.accountTypeKw == 1 ? 'AHORROS' : 'CORRIENTE',
          'account-number': selectedClientUser.accountNumber.toString(),
          address: {
            city: getTypeKeyByValue(
              citiesOptions,
              'codigo_ciudad',
              selectedClientUser.cityCode.Int32,
            ),
            line: selectedClientUser.address.String,
            department: getTypeKeyByValue(
              departmentsData,
              'code',
              selectedClientUser.idDepartment.Int32,
            ),
          },
        },

        software: {
          pin: '4123412',
          'dian-id': 'd0e88268-a4ab-447d-918c-19c1c248b5c3',
        },
      },
      historicalSettlement: {
        name: selectedInterval.label,
        startDate: dayjsDateToLocale(payrollForm.values.initialSettlementDate),
        endDate: dayjsDateToLocale(payrollForm.values.finalSettlementDate),
        year: selectedInterval.year.toString(),
        month: selectedInterval.month.toString(),
        periodId: selectedInterval.value,
      },
    }
    const result = await PostPayrollApi(
      selectedClientUser.companyId,
      selectedClientUser.branchId,
      currentResolution.value,
      selectedClientUser.id,
      payrollData,
    )
    if (result.data) {
      notify('success', 'Nómina creada con éxito.')
      onClose()
    } else {
      notify('error', 'Error al crear la nómina.')
      setIsCreateButtonDisabled(false)
    }
  }
  //--------------------Resolutions methods----------------------

  const [resolutionOptions, setResolutionOptions] = useState([])
  const [currentResolution, setCurrentResolution] = useState({
    value: 0,
    label: '',
  })
  const getResolutions = async (branchId) => {
    const result = await getClientResolutionsApi(branchId)
    if (result.data != null) {
      setResolutionOptions(
        result.data.map((resolution) => {
          return {
            value: resolution.id,
            label: resolution.prefix + ' - ' + resolution.resolutionNumber,
          }
        }),
      )
      setCurrentResolution({
        value: result.data[0].id,
        label: result.data[0].prefix + ' - ' + result.data[0].resolutionNumber,
      })
    } else notify('warning', 'No se encuentran resoluciones.')
  }

  // ----------------------------------------------

  useEffect(() => {
    setClientUsersData(setClientUsersOptions(clientUsers, 'fullName'))
  }, [tokenData])

  useEffect(() => {
    selectedClientUser.branchId != 0 &&
      getResolutions(selectedClientUser.branchId)
    if (selectedClientUser.idDepartment != undefined)
      getMunicipios(selectedClientUser.idDepartment.Int32)
  }, [selectedClientUser])

  return (
    <Container
      style={{
        borderRadius: '10px',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: '20px',
      }}
    >
      <h4>Creación de liquidación de {selectedInterval.label}</h4>
      <Group grow mb="md" mt="md">
        <CustomSelect
          title="Empleado"
          placeholder="Empleado"
          options={clientUsersData}
          value={selectedClientOption}
          onChange={(value) => handleClientUserChange(value)}
          minWidth={300}
          maxWidth={300}
          theme={isDarkModeActive ? 'black' : 'white'}
        />
        <CustomSelect
          title="Resolución"
          placeholder="Resolución"
          options={resolutionOptions}
          value={currentResolution}
          onChange={(value) => setCurrentResolution(value)}
          minWidth={300}
          maxWidth={300}
          theme={isDarkModeActive ? 'black' : 'white'}
        />
      </Group>
      {selectedClientOption && (
        <Group direction="column" grow>
          <ClientUserInfo
            clientUserDetails={selectedClientUser}
            branches={branches}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              border: '1px solid #000',
              borderRadius: '10px',
              padding: '20px',
              marginBottom: '20px',
            }}
          >
            <h5
              style={{
                float: 'left',
              }}
            >
              Información de liquidación de nómina
            </h5>
            <br />
            <ClientUserNovelties
              clientUserDetails={selectedClientUser}
              getClientUsers={getClientUsers}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ES">
              <Group
                grow
                mb="md"
                mt="md"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}
              >
                <DatePicker
                  readOnly
                  label="Fecha inicial de liquidación de nómina"
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: 'small' } }}
                  value={payrollForm.values.initialSettlementDate}
                  onChange={(value) =>
                    payrollForm.setFieldValue('initialSettlementDate', value)
                  }
                />
                <DatePicker
                  readOnly
                  label="Fecha final de liquidación de nómina"
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: 'small' } }}
                  value={payrollForm.values.finalSettlementDate}
                  onChange={(value) =>
                    payrollForm.setFieldValue('finalSettlementDate', value)
                  }
                />
                <DatePicker
                  label="Fecha de generación de la nómina electrónica"
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: 'small' } }}
                  value={payrollForm.values.issueDate}
                  onChange={(value) =>
                    payrollForm.setFieldValue('issueDate', value)
                  }
                />
                <DatePicker
                  label="Fecha de pago de la nómina electrónica"
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: 'small' } }}
                  value={payrollForm.values.paymentDate}
                  onChange={(value) =>
                    payrollForm.setFieldValue('paymentDate', value)
                  }
                />
              </Group>
            </LocalizationProvider>
            <Group mb="md" mt="md">
              <Notes
                onChange={(notes) => {
                  setSettlementNotes(notes)
                }}
              />
            </Group>
            <hr></hr>
            <div>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                  marginRight: '10px',
                }}
                onClick={createPayroll}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'white',
                  borderRadius: '100px',
                  color: 'black',
                  isolation: 'isolate',
                }}
                onClick={() => {
                  onClose()
                }}
              >
                Cerrar
              </Button>
            </div>
          </div>
        </Group>
      )}
    </Container>
  )
}

export default CreateSettlement
