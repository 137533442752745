import Barcode from 'react-barcode'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Download } from 'tabler-icons-react'
import { Tooltip } from '@mui/material'

const BarCode = ({ value, color = 'black' }) => {
  //----print
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        marginLeft: '20px',
      }}
    >
      <div ref={componentRef}>
        <Barcode
          value={value}
          background="transparent"
          width={1.5}
          height={30}
          color={color}
        />
      </div>
      <Tooltip title="Descargar">
        <button
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            marginBottom: '25px',
          }}
          onClick={handlePrint}
        >
          <Download size={20} />
        </button>
      </Tooltip>
    </div>
  )
}

export default BarCode
