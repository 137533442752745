import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import useStore from '../../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { TextField } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { dayjsDateToLocale, notify } from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { CirclePlus, CircleMinus } from 'tabler-icons-react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

import Swal from 'sweetalert2'
import {
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material'
import { setDecimal, isEmpty } from '../../../services/utils'
import { MDBTable, MDBTableBody } from 'mdb-react-ui-kit'
import { Select as MuiSelect } from '@mui/material'
import CustomSelect from '../../custom/customSelect'
import {
  getBundledSalesByIdApi,
  getBundledSalesBySaleStateApi,
} from '../../../pages/api/bundledsale'
import {
  getClientSalesBySaleStateApi,
  getOneClientSaleByInvoiceNumberApi,
} from '../../../pages/api/clientsale'
import { PostClientInvoiceApi } from '../../../pages/api/clientinvoice'
import { getThirdClientsByCompanyApi } from '../../../pages/api/thirdClients'
import { getWaysToPayApi } from '../../../pages/api/wayToPay'
import { GetClientPaymentMethodByCompanyIdApi } from '../../../pages/api/clientPaymentMethod'
import Notes from '../../custom/notes'

export default function NewInvoice(props) {
  const { setModalCreateShow, isBundled, successCreation, resolutionId } = props
  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  //Clients
  const [clients, setClients] = useState([])
  const [clientProductsOptions, setClientProductsOptions] = React.useState([])
  const [clientsFind, setClientsFind] = React.useState([
    { value: 0, label: 'Cliente', nit: '', thirdClientCompanyName: '' },
  ])

  //SALES ENUM
  const EnumSales = {
    Created: 1,
    Validated: 2,
    Accepted: 3,
    Refused: 4,
    Bundled: 5,
  }

  const form = useForm({
    initialValues: {
      orderReference: '',
      payment_means: '',
      payment_means_type: 0,
    },
  })

  const createInvoice = async () => {
    if (form.values.payment_means == 0) {
      notify('warning', 'Elija la forma de pago.')
      setIsCreateButtonDisabled(false)
      return
    }
    // API Para crear tanto venta individual como agrupada
    let saleData = {
      saleId: billSelected.id, //id de la venta
      thirdClientId: clientsFind[0].id,
      companyId: tokenData.companyId,
      branchId: tokenData.branchId,
      resolutionId: resolutionId,
      clientUserId: tokenData.clientId,
      thirdClientName: clientsFind[0].thirdClientCompanyName,
      issueDate: dayjsDateToLocale(startDate),
      paymentDate: dayjsDateToLocale(endDate),
      orderReference: form.values.orderReference,
      payment_means_type:
        form.values.payment_means_type == 1 ? 'DEBITO' : 'CREDITO',
      payment_means: getPaymentText(form.values.payment_means),
      clientPaymentMeanId: form.values.payment_means,
      thirdClient: clientsFind,
      items: getItemsForInvoice(),
      notes: invoiceNotes,
      fuelType: isBundled ? 10 : parseInt(billSelected.fuelType),
      bundledSaleId: isBundled ? bundledSaleId : 0,
      total: setDecimal(getProductsTotalPrice(clientBillProducts, true)),
      clientDocumentId: tokenData.userId,
    }
    let result = await PostClientInvoiceApi(saleData)
    if (result.data != null) {
      successCreation(result)
      resetConstants()
    } else {
      notify('error', 'Error al crear la factura.')
    }
  }

  const [waysToPay, setWaysToPay] = useState([])

  const getWaysToPay = async () => {
    let result = await getWaysToPayApi()
    if (result.data != null) setWaysToPay(result.data)
    else notify('warning', 'No se encontraron tipos de pago')
  }

  const getPaymentText = (pay) => {
    let paymentMethod = paymentMethods.find((x) => x.id === pay)
    let wayToPay = waysToPay.find((x) => x.id === paymentMethod.paymentMethod)
    if (wayToPay != null) return wayToPay.dataicoPaymentMeans
    else return 'No definida'
  }

  const getClients = async (companyId) => {
    let result = await getThirdClientsByCompanyApi(companyId)
    if (result.data != null) {
      let clientSelect = await result.data.map((x) => {
        return { value: x.id, label: x.nit + ' - ' + x.thirdClientCompanyName }
      })
      setClientProductsOptions(clientSelect)
      setClients(result.data)
    }
  }

  const addClient = (client) => {
    let findClients = clients.filter((element) => element.id == client.value)
    setClientsFind(findClients)
    onClientSelected(findClients)
  }

  //----------------Bundled invoices -------------------+

  const [bundledSaleId, setBundledSaleId] = useState(0)

  const getBundledSales = async (bundledSaleId) => {
    let result = await getBundledSalesByIdApi(bundledSaleId)
    if (result.data != null) {
      setBundledSaleId(bundledSaleId)
      let bill = {
        id: bundledSaleId,
        paymentMethod: 2,
        wayToPay: 1,
        fueltype: 10, //se quema en 10 para fueltype por defecto, la tabla lo pide pero no se usa como dato
        gallonsSold: 0,
        total: 0,
        totalWithDiscount: 0,
        saleState: 5,
        invoiceNumber: bundledSaleId,
        createdAt: clientsFind[0].createdAt,
        discount: 0,
      }
      let productsAux = []
      result.data.map((sale) => {
        bill.total = parseFloat(bill.total) + parseFloat(sale.total)
        bill.totalWithDiscount =
          parseFloat(bill.totalWithDiscount) +
          parseFloat(sale.totalWithDiscount)
        bill.gallonsSold =
          parseFloat(bill.gallonsSold) + parseFloat(sale.gallonsSold)
        JSON.parse(sale.products).map((product) => {
          product.saleId = sale.id
          productsAux.push(product)
        })
      })
      let group = []
      let codes = []
      productsAux.map((product) => {
        let bundledProducts = addToBundledProducts(
          group,
          codes,
          product.code,
          product,
        )
        group = bundledProducts[0]
        codes = bundledProducts[1]
      })
      bill.products = JSON.stringify(group)
      AdditionalPayments(bill, group)
    } else {
      notify('warning', 'No se encontró la factura.')
    }
  }

  const addToBundledProducts = (group, codes, code, product) => {
    let productIndex = codes.indexOf(parseInt(code))
    if (
      productIndex !== -1 &&
      group[productIndex].busPlate === product.busPlate &&
      code !== '01' // Es el código global de los combustibles
    ) {
      group[productIndex] = meshProducts(group[productIndex], product)
    } else {
      group.push({
        code: code !== '01' ? parseInt(code) : code,
        name: product.name,
        quantity: product.quantity,
        total: product.vatPrice * product.quantity,
        price: product.price,
        vat: product.vat,
        busPlate: product.busPlate,
        unitOfMeasurement: product.unitOfMeasurement,
        saleId: product.saleId,
        costCenter: product.costCenter,
      })
      codes.push(parseInt(code))
    } //
    return [group, codes]
  }

  const meshProducts = (productOg, productToAdd) => {
    let product = {
      code: productOg.code,
      name: productOg.name,
      quantity: productOg.quantity + productToAdd.quantity,
      total: productOg.total + productToAdd.quantity * productToAdd.vatPrice,
      price: productOg.price,
      vat: productOg.vat,
      busPlate: productOg.busPlate,
      unitOfMeasurement: productOg.unitOfMeasurement,
      saleId: productOg.saleId,
      costCenter: productOg.costCenter,
    }
    return product
  }

  //---------------------roles--------------------

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.invoice) {
      notify('warning', 'No tienes permiso para ver este módulo.')
      navigate('/dashboard')
    }
    await getWaysToPay()
    await getPaymentMethods(tokenData.companyId)
    await getClients(tokenData.companyId, tokenData.branchId)
  }

  //--------------------Invoice data methods----------------------

  const getItemsForInvoice = () => {
    let items = []
    clientBillProducts.map((product) => {
      let saleNumber =
        product.code === '01'
          ? isBundled
            ? '#' + product.saleId + ' - '
            : '#' + billSelected.invoiceNumber + ' - '
          : ''
      let item = {
        sku: `${product.code}`,
        saleId: product.saleId,
        quantity: product.quantity,
        description: '[' + product.busPlate + '] ' + saleNumber + product.name,
        measuring_unit: product.unitOfMeasurement,
        price: product.price,
        paymentMethod: billSelected.paymentMethod,
        costCenter: product.costCenter,
      }
      if (parseFloat(product.discount) > 0) {
        item.discount_rate = parseFloat(product.discount)
      }
      if (parseInt(product.vat) > 0) {
        item.taxes = [
          {
            tax_category: 'IVA',
            tax_rate: parseInt(product.vat),
          },
        ]
      }
      if (parseFloat(product.rf) > 0.0) {
        item.retentions = [
          {
            tax_category: 'RET_FUENTE',
            tax_rate: parseFloat(product.rf),
          },
        ]
      }
      items.push(item)
    })
    return items
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)
  const [billSelected, setBillSelected] = useState([]) //current bill
  const [clientBillProducts, setClientBillProducts] = useState([]) //current bill products
  const [isClientSelected, setIsClientSelected] = useState(false) //if a client is selected
  const [clientBillsOptions, setClientBillsOptions] = useState([]) //array of client bills options for customSelect
  const [additionalPayments, setAdditionalPayments] = useState({
    //additional payment as taxes and retentions
    5: { name: 'IVA(5%)', total: 0 },
    16: { name: 'IVA(16%)', total: 0 },
    19: { name: 'IVA(19%)', total: 0 },
    discount: { name: 'Descuento', total: 0 },
    rf: { name: 'Ret. FUENTE', total: 0 },
  })

  const setDateShortcut = (days) => {
    if (days > 0 && days < 1000) {
      let result = new Date(startDate)
      result.setDate(result.getDate() + days)
      setEndDate(dayjs(result))
    }
  }

  const [startDate, setStartDate] = React.useState(dayjs(new Date()))
  const [endDate, setEndDate] = React.useState(dayjs(new Date()))

  const handleBillingState = () => {
    let startDateParsed = new Date(startDate)
    let endDateParsed = new Date(endDate)
    let readyForInvoice = true
    if (!isClientSelected) {
      notify('warning', 'Seleccione un cliente primero.')
      readyForInvoice = false
    }
    if (billSelected.length == 0) {
      notify('warning', 'Seleccione una factura primero.')
      readyForInvoice = false
    }
    if (endDateParsed < startDateParsed || startDateParsed > endDateParsed) {
      notify(
        'warning',
        'La fecha de VENCIMIENTO debe ser mayor a la fecha de EMISIÓN',
      )
      readyForInvoice = false
    }
    if (readyForInvoice) {
      Swal.fire({
        title: '¿Enviar factura a la DIAN?',
        text: 'Nota: Esta acción no es reversible',
        icon: 'warning',
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsCreateButtonDisabled(true)
          createInvoice()
        }
      })
    }
  }

  const handleSelectABill = async (saleId) => {
    let result = await getOneClientSaleByInvoiceNumberApi(
      tokenData.companyId,
      tokenData.branchId,
      saleId,
    )
    if (result.data != null) {
      let products = getClientBillProducts(result.data[0])
      AdditionalPayments(result.data[0], products)
    } else {
      notify('warning', 'No se encontró la factura.')
      setBillSelected([])
      setClientBillProducts([])
    }
  }

  const AdditionalPayments = (bill, products) => {
    setBillSelected(bill)
    form.setFieldValue('payment_means_type', bill.paymentMethod)
    let ap = {
      5: { name: 'IVA(5%)', total: 0 },
      16: { name: 'IVA(16%)', total: 0 },
      19: { name: 'IVA(19%)', total: 0 },
      discount: { name: 'Descuento', total: 0 },
      rf: { name: 'Ret. FUENTE', total: 0 },
    }
    products.map((product) => {
      product.rf = 0
      product.total = getTotalPerProduct(product)
      if (!isBundled && parseInt(product.vat) > 0) {
        ap[parseInt(product.vat)].total +=
          (product.total * parseInt(product.vat)) / 100
      }
      ap.discount.total += product.price * product.quantity - product.total
    })
    setAdditionalPayments(ap)
    setClientBillProducts(products)
  }

  const resetConstants = () => {
    setBillSelected([])
    setIsClientSelected(false)
    setClientBillProducts([])
    setAdditionalPayments({
      5: { name: 'IVA(5%)', total: 0 },
      16: { name: 'IVA(16%)', total: 0 },
      19: { name: 'IVA(19%)', total: 0 },
      discount: { name: 'Descuento', total: 0 },
      rf: { name: 'Ret. FUENTE', total: 0 },
    })
    setInvoiceNotes([])
    setStartDate(dayjs(new Date()))
    setEndDate(dayjs(new Date()))
  }

  const getProductsTotalPrice = (products, isInvoice) => {
    let total = 0
    products.map((product) => {
      let iva =
        product.taxes == undefined ? 0 : parseInt(product.taxes[0].tax_rate)

      let rf =
        product.retentions == undefined
          ? 0
          : parseFloat(product.retentions[0].tax_rate)
      !isInvoice
        ? (total += (product.total * (100 + (iva - rf))) / 100)
        : (total +=
            (product.total *
              (100 + (parseInt(product.vat) - parseFloat(product.rf)))) /
            100)
    })

    return total
  }

  const getProductsSubtotalPrice = (products) => {
    let subtotal = 0
    products.map((product) => {
      let discount =
        product.discount_rate != undefined ? product.discount_rate : 0
      subtotal += product.price * (1 - discount / 100) * product.quantity
    })

    return subtotal
  }

  const getClientBillProducts = (bill) => {
    let products = JSON.parse(bill.products)
    return products
  }

  const setClientAdditionalPayments = (payment, value, index) => {
    let clientbillproducts = [...clientBillProducts]
    if (payment == 'iva') clientbillproducts[index].vat = value
    if (payment == 'rf') clientbillproducts[index].rf = value
    if (payment == 'discount') clientbillproducts[index].discount = value
    let total = getTotalPerProduct(clientbillproducts[index])
    clientbillproducts = setTotal(clientbillproducts, total, index)
    calculateAdditionalPayments(clientbillproducts)
    setClientBillProducts(clientbillproducts)
  }
  const setTotal = (clientbillproducts, total, index) => {
    clientbillproducts[index].total = total
    return clientbillproducts
  }

  const calculateAdditionalPayments = (bills) => {
    let iva5 = 0
    let iva16 = 0
    let iva19 = 0
    let rf = 0
    let discount = 0
    bills.map((product) => {
      if (parseInt(product.vat) == 5) {
        iva5 += (product.total * parseInt(product.vat)) / 100
      }
      if (parseInt(product.vat) == 16) {
        iva16 += (product.total * parseInt(product.vat)) / 100
      }
      if (parseInt(product.vat) == 19) {
        iva19 += (product.total * parseInt(product.vat)) / 100
      }
      rf += parseFloat(product.total * product.rf) / 100
      discount +=
        parseFloat(
          product.price * product.quantity * parseFloat(product.discount),
        ) / 100
    })
    let ap = {
      5: { name: 'IVA(5%)', total: iva5 },
      16: { name: 'IVA(16%)', total: iva16 },
      19: { name: 'IVA(19%)', total: iva19 },
      discount: { name: 'Descuento', total: discount },
      rf: { name: 'Ret. FUENTE', total: rf },
    }
    setAdditionalPayments(ap)
  }

  const getTotalPerProduct = (clientProducts) => {
    return (
      (clientProducts.price *
        clientProducts.quantity *
        (100 - parseFloat(clientProducts.discount))) /
      100
    )
  }

  //Load bills data from selected client
  const onClientSelected = async (client) => {
    let result = isBundled
      ? await getBundledSalesBySaleStateApi(
          client[0].companyId,
          client[0].branchId,
          client[0].id,
          EnumSales.Bundled,
        )
      : await getClientSalesBySaleStateApi(
          client[0].companyId,
          client[0].branchId,
          EnumSales.Validated,
        )
    if (result.data != null) {
      let invoiceSelect = []
      result.data.map((x) => {
        invoiceSelect.push({
          value: isBundled ? x.id : x.invoiceNumber,
          label: `${
            isBundled
              ? ''
              : x.posTerpelConsecutive != ''
              ? '(Consecutivo ' + x.posTerpelConsecutive + ') - '
              : ''
          }Factura #${isBundled ? x.id : x.invoiceNumber}`,
        })
      })
      setClientBillsOptions(invoiceSelect)
    } else {
      notify('warning', 'No se encontraron facturas por estado de venta.')

      setClientBillsOptions([])
    }
  }

  //---------------------paymentMethods----------------

  const [paymentMethods, setPaymentMethods] = useState([])

  const getPaymentMethods = async (companyId) => {
    let result = await GetClientPaymentMethodByCompanyIdApi(companyId)
    result.data != null
      ? setPaymentMethods(result.data)
      : notify('warning', 'No hay métodos de pago creados')
  }

  //--------------------Notes---------------------------
  const [invoiceNotes, setInvoiceNotes] = useState([])

  //----------------------------------
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      tokenVerified()
      resetConstants()
    }
  }, [tokenData, rolesData, isBundled])

  return (
    <Container
      style={{
        border: '1px solid gray',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <h4> Creando Nueva Factura {isBundled ? 'Agrupada' : 'Individual'}</h4>
      <hr></hr>
      <Group direction="column" grow>
        <CustomSelect
          title="Empresa/Cliente"
          placeholder="Cliente"
          onChange={(product) => {
            addClient(product)
            setIsClientSelected(true)
          }}
          value={{
            value: clientsFind[0].id,
            label:
              clientsFind[0].nit +
              ' - ' +
              clientsFind[0].thirdClientCompanyName,
          }}
          options={clientProductsOptions}
          theme={isDarkModeActive ? 'black' : 'white'}
        />
        {isClientSelected ? (
          <Group>
            <Group>
              <CustomSelect
                title="Factura"
                placeholder="Factura"
                onChange={(product) => {
                  if (product.value != 0)
                    isBundled
                      ? getBundledSales(product.value)
                      : handleSelectABill(product.value)
                }}
                options={clientBillsOptions}
                theme={isDarkModeActive ? 'black' : 'white'}
                minWidth={500}
              />
            </Group>
            <Group>
              {billSelected.saleState == EnumSales.Validated ||
              billSelected.saleState == EnumSales.Bundled ? (
                <Group>
                  <h6>{`${
                    isBundled
                      ? ''
                      : billSelected.posTerpelConsecutive != ''
                      ? '(Consecutivo ' +
                        billSelected.posTerpelConsecutive +
                        ') - '
                      : ''
                  }Factura #${billSelected.invoiceNumber} - (${
                    billSelected.createdAt
                  }) - ${setDecimal(
                    parseFloat(billSelected.totalWithDiscount) +
                      getProductsTotalPrice(clientBillProducts, true),
                  )}$`}</h6>
                  <table
                    style={{
                      borderSpacing: '10px',
                      borderCollapse: 'separate',
                      width: '100%',
                    }}
                  >
                    <thead>
                      <tr>
                        <th># RECIBO</th>
                        <th>PLACA</th>
                        <th>NOMBRE</th>
                        <th>CANTIDAD</th>
                        <th>DESCUENTO</th>
                        <th>RET. FUENTE</th>
                        <th>IVA</th>
                        <th>SUBTOTAL</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientBillProducts.map((clientProducts, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {clientProducts.code === '01'
                                ? isBundled
                                  ? clientProducts.saleId
                                  : billSelected.invoiceNumber
                                : null}
                            </td>
                            <td>{clientProducts.busPlate}</td>
                            <td> {clientProducts.name}</td>
                            <td>
                              <div>
                                <span
                                  style={{
                                    fontSize: 16,
                                    marginTop: '40px',
                                  }}
                                >
                                  {setDecimal(clientProducts.quantity, 3)}
                                </span>
                              </div>
                            </td>
                            <td>
                              <TextField
                                variant="standard"
                                onChange={(event) => {
                                  if (
                                    event.currentTarget.value >= 0 &&
                                    event.currentTarget.value <= 100
                                  ) {
                                    setClientAdditionalPayments(
                                      'discount',
                                      event.currentTarget.value,
                                      index,
                                    )
                                  }
                                }}
                                onBlur={(event) => {
                                  if (!(event.currentTarget.value != ''))
                                    setClientAdditionalPayments(
                                      'discount',
                                      0,
                                      index,
                                    )
                                }}
                                type="number"
                                value={clientProducts.discount}
                                style={{
                                  width: '45px',
                                  display: 'inline-block',
                                }}
                              />
                              %
                            </td>
                            <td>
                              <FormControl variant="standard" sx={{ m: 1 }}>
                                <InputLabel id="demo-simple-select-standard-label">
                                  Ret. Fuente
                                </InputLabel>
                                <MuiSelect
                                  label="Ret. Fuente"
                                  value={clientProducts.rf}
                                  onChange={(e) =>
                                    setClientAdditionalPayments(
                                      'rf',
                                      e.target.value,
                                      index,
                                    )
                                  }
                                >
                                  <MenuItem value={0}>0%</MenuItem>
                                  <MenuItem value={0.1}>0.1%</MenuItem>
                                  <MenuItem value={0.5}>0.5%</MenuItem>
                                  <MenuItem value={1}>1%</MenuItem>
                                  <MenuItem value={1.5}>1.5%</MenuItem>
                                  <MenuItem value={2}>2%</MenuItem>
                                  <MenuItem value={2.5}>2.5%</MenuItem>
                                  <MenuItem value={3}>3%</MenuItem>
                                  <MenuItem value={3.5}>3.5%</MenuItem>
                                  <MenuItem value={4}>4%</MenuItem>
                                  <MenuItem value={6}>6%</MenuItem>
                                  <MenuItem value={7}>7%</MenuItem>
                                  <MenuItem value={10}>10%</MenuItem>
                                  <MenuItem value={11}>11%</MenuItem>
                                  <MenuItem value={20}>20%</MenuItem>
                                </MuiSelect>
                              </FormControl>
                            </td>
                            <td>
                              <FormControl variant="standard" sx={{ m: 1 }}>
                                <InputLabel id="demo-simple-select-standard-label">
                                  IVA
                                </InputLabel>
                                <MuiSelect
                                  label="IVA"
                                  value={parseInt(clientProducts.vat)}
                                  onChange={(e) =>
                                    setClientAdditionalPayments(
                                      'iva',
                                      e.target.value,
                                      index,
                                    )
                                  }
                                >
                                  <MenuItem value={0}>IVA 0%</MenuItem>
                                  <MenuItem value={5}>IVA 5%</MenuItem>
                                  <MenuItem value={16}>IVA 16%</MenuItem>
                                  <MenuItem value={19}>IVA 19%</MenuItem>
                                </MuiSelect>
                              </FormControl>
                            </td>
                            <td>
                              $
                              {setDecimal(
                                clientProducts.price *
                                  clientProducts.quantity *
                                  (1 - clientProducts.discount / 100),
                              )}
                            </td>
                            <td>
                              $
                              {setDecimal(
                                (parseFloat(
                                  (clientProducts.price *
                                    clientProducts.quantity *
                                    (100 -
                                      parseFloat(clientProducts.discount))) /
                                    100,
                                ) *
                                  (100 +
                                    (clientProducts.vat - clientProducts.rf))) /
                                  100,
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <MDBTable>
                    <MDBTableBody>
                      <tr>
                        <td>Subtotal:</td>
                        <td>
                          $
                          {setDecimal(
                            parseFloat(
                              getProductsSubtotalPrice(clientBillProducts),
                            ),
                          )}
                        </td>
                      </tr>
                      {Object.keys(additionalPayments).map((key, index) => {
                        if (
                          additionalPayments[key].total > 0 &&
                          additionalPayments[key].name.startsWith('IVA')
                        ) {
                          return (
                            <tr key={index}>
                              <td>{additionalPayments[key].name}</td>
                              <td>
                                + ${setDecimal(additionalPayments[key].total)}
                              </td>
                            </tr>
                          )
                        }
                      })}
                      {additionalPayments.discount.total > 0 ? (
                        <tr>
                          <td>Descuento: </td>

                          <td>
                            - ${setDecimal(additionalPayments.discount.total)}
                          </td>
                        </tr>
                      ) : null}
                      {additionalPayments.rf.total > 0 ? (
                        <tr>
                          <td>Ret. Fuente: </td>
                          <td>- ${setDecimal(additionalPayments.rf.total)}</td>
                        </tr>
                      ) : null}
                      {additionalPayments.discount.total > 0 ? (
                        <tr>
                          <td>Base Gravable:</td>
                          <td>
                            $
                            {setDecimal(
                              parseFloat(
                                getProductsSubtotalPrice(clientBillProducts) -
                                  additionalPayments.discount.total,
                              ),
                            )}
                          </td>
                        </tr>
                      ) : null}

                      <tr>
                        <td>
                          <b>Total:</b>
                        </td>
                        <td>
                          <b>
                            $
                            {setDecimal(
                              getProductsTotalPrice(clientBillProducts, true),
                            )}
                          </b>
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </Group>
              ) : null}
            </Group>
            {billSelected.saleState == EnumSales.Validated ||
            billSelected.saleState == EnumSales.Bundled ? (
              <>
                <Group mb="md" mt="md">
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      MÉTODO DE VENTA
                    </InputLabel>
                    <MuiSelect
                      required
                      label="MÉTODO DE PAGO"
                      value={form.values.payment_means_type}
                      style={{
                        appearance: 'none',
                        border: '0px',
                        background: 'none',
                        width: '180px',
                      }}
                      onChange={(event) => {
                        form.setFieldValue(
                          'payment_means_type',
                          event.target.value,
                        )
                      }}
                    >
                      <MenuItem value={2}>CRÉDITO</MenuItem>
                      <MenuItem value={1}>CONTADO</MenuItem>
                    </MuiSelect>
                  </FormControl>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      MEDIO DE PAGO
                    </InputLabel>
                    <MuiSelect
                      required
                      label="FORMA DE PAGO"
                      value={form.values.payment_means}
                      style={{
                        appearance: 'none',
                        border: '0px',
                        background: 'none',
                        width: '220px',
                      }}
                      onChange={(e) => {
                        form.setFieldValue('payment_means', e.target.value)
                      }}
                    >
                      {paymentMethods.map((paymentMethod, index) => (
                        <MenuItem key={index} value={paymentMethod.id}>
                          {paymentMethod.voucher + ' - ' + paymentMethod.name}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      FORMA DE PAGO
                    </InputLabel>
                    <MuiSelect
                      required
                      label="FORMA DE PAGO"
                      value={billSelected.wayToPay}
                      style={{
                        appearance: 'none',
                        border: '0px',
                        background: 'none',
                      }}
                      disabled={true}
                    >
                      {waysToPay.map((wayToPay, index) => (
                        <MenuItem key={index} value={wayToPay.id}>
                          {wayToPay.waytopay}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                  <TextField
                    variant="standard"
                    label="CÓDIGO TRANSFERENCIA"
                    disabled={true}
                    value={billSelected.transferCode}
                    style={{
                      appearance: 'none',
                      border: 'none',
                      background: 'none',
                    }}
                  />
                </Group>
                <Group grow mb="md" mt="md">
                  <div>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="ES"
                    >
                      <DatePicker
                        label="FECHA EMISIÓN"
                        format="DD/MM/YYYY"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                      <DatePicker
                        label="FECHA VENCIMIENTO"
                        format="DD/MM/YYYY"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </LocalizationProvider>
                    <TextField
                      variant="standard"
                      type="number"
                      label="Días"
                      style={{
                        width: '45px',
                        marginLeft: '10px',
                      }}
                      onChange={(event) =>
                        setDateShortcut(parseInt(event.target.value))
                      }
                    />
                  </div>
                </Group>
                <TextField
                  fullWidth={true}
                  variant="standard"
                  label="ORDEN DE COMPRA"
                  placeholder="ORDEN DE COMPRA"
                  value={form.values.orderReference}
                  onChange={(event) =>
                    form.setFieldValue(
                      'orderReference',
                      event.currentTarget.value,
                    )
                  }
                />
                <Group mb="md" mt="md">
                  <Notes
                    notes={invoiceNotes}
                    onChange={(notes) => setInvoiceNotes(notes)}
                  />
                </Group>
              </>
            ) : null}
          </Group>
        ) : null}
      </Group>

      <hr></hr>
      <Button
        style={{
          backgroundColor: 'green',
          borderRadius: '100px',
          marginRight: '10px',
          isolation: 'isolate',
        }}
        onClick={() => handleBillingState()}
        disabled={isCreateButtonDisabled}
      >
        {isCreateButtonDisabled ? (
          <CircularProgress size={15} color="inherit" />
        ) : null}
        Crear
      </Button>
      <Button
        style={{
          backgroundColor: 'transparent',
          borderRadius: '100px',
          color: 'black',
        }}
        onClick={() => {
          resetConstants()
          setModalCreateShow(false)
        }}
      >
        Volver
      </Button>
    </Container>
  )
}
