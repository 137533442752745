import { Group, Paper, Text } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { CircularProgress, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { useNavigate } from 'react-router-dom'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import Menu from '../../components/client/menu'
import useStore from '../../components/zustand'
import { Select as MuiSelect } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import {
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {
  BusStop,
  Circle,
  CircleMinus,
  CirclePlus,
  Edit,
} from 'tabler-icons-react'
import LoadingScreen from '../../components/main/loadingScreen'
import { isEmpty } from '../../services/utils'
import StationMenus from '../../components/client/shortcutMenus/stationMenus'
import {
  PostClientHoseApi,
  getClientHosesApi,
  updateClientHoseApi,
} from '../api/clienthoses'
import { getClientIslandsApi } from '../api/clientIslands'
import { getClientTanksApi } from '../api/clientTanks'

export default function Hoses() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  //ISLAND ID
  const [islandId, setIslandId] = React.useState(0)

  const form = useForm({
    initialValues: {
      hoseName: '',
    },
  })

  const putForm = useForm({
    initialValues: {
      hoseId: 0,
      hoseName: '',
      islandId: 0,
    },
  })

  //GETS HOSE FIELDS
  const getHoseData = async (hoseId, hoseName, islandId) => {
    putForm.setFieldValue('hoseId', hoseId)
    putForm.setFieldValue('hoseName', hoseName)
    putForm.setFieldValue('islandId', islandId)
    setModalPutShow(true)
  }

  const successCreation = async () => {
    notify('success', 'Creación Exitoso')
    await getClientHoses(tokenData.companyId, tokenData.branchId)
    setModalCreateShow(false)
    setIsCreateButtonDisabled(false)
  }

  const handleIslandId = (islandId) => {
    setIslandId(islandId)
  }

  const handleEditIslandId = (islandId) => {
    setIslandId(islandId)
    putForm.setFieldValue('islandId', islandId)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createHose = async () => {
    if (form.values.hoseName == '' || islandId == 0) {
      notify('warning', 'Por favor llena todos los campos.')
    } else {
      setIsCreateButtonDisabled(true)
      let islandData = {
        companyId: tokenData.companyId,
        branchId: tokenData.branchId,
        clientId: tokenData.clientId,
        name: form.values.hoseName,
        islandId: parseInt(islandId),
        deleted: false,
      }
      let result = await PostClientHoseApi(islandData)
      if (result.data == 'ClientHoses created') successCreation()
      else notify('warning', 'Error al crear manguera')
    }
  }

  //Clients Islands
  const [islands, setIslands] = useState([])
  const getClientIslands = async (companyId, branchId) => {
    try {
      let result = await getClientIslandsApi(companyId, branchId)
      if (result.data != null) setIslands(result.data)
      else {
        setIslands([])
        notify('warning', 'No hay islas creadas.')
      }
    } catch (error) {
      return false
    }
  }

  const getIslandById = (id) => {
    let island = islands.find((island) => island.id === id)
    if (island == undefined) return ''
    else return island.name
  }

  //Clients Hoses
  const [hoses, setHoses] = useState([])
  const getClientHoses = async (companyId, branchId) => {
    let result = await getClientHosesApi(companyId, branchId)
    if (result.data != null) setHoses(result.data)
    else setHoses([])
  }

  //Clients Tanks
  const [tanks, setTanks] = useState([])

  const getClientTanks = async (companyId, branchId) => {
    let result = await getClientTanksApi(companyId, branchId)
    if (result.data != null) setTanks(result.data)
    else {
      setTanks([])
      notify('warning', 'No hay tanques registrados.')
    }
  }

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const updateClientHoses = async () => {
    if (putForm.values.hoseName == '' || putForm.values.islandId == 0) {
      notify('warning', 'Por favor llena todos los campos.')
    } else {
      setIsUpdateButtonDisabled(true)
      let hoseData = {
        name: putForm.values.hoseName,
        islandId: parseInt(putForm.values.islandId),
      }
      let result = await updateClientHoseApi(putForm.values.hoseId, hoseData)
      if (result.data == 'ClientHoses updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await getClientHoses(tokenData.companyId, tokenData.branchId)
      } else {
        notify('warning', 'Error al actualizar')
      }
    }
    setIsUpdateButtonDisabled(false)
  }

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.modules) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await getClientTanks(tokenData.companyId, tokenData.branchId)
    await getClientIslands(tokenData.companyId, tokenData.branchId)
    await getClientHoses(tokenData.companyId, tokenData.branchId)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <StationMenus buttonId={2} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
          >
            Nueva Manguera <CirclePlus size={20} color="#FFFFFF" />
          </Button>

          <br></br>
          <Container>
            <Row xs={1} md={3} className="g-4">
              {/* {islands.map((island, index) => {
                return (
                  <Col key={index}>
                    <Paper withBorder radius="md" p="xs">
                      <Group>
                        <BusStop
                          size={60}
                          color="#3e98c7"
                          style={{ marginRight: '10px', isolation: 'isolate' }}
                        />
                        <div>
                          <Text
                            color="dimmed"
                            size="xl"
                            transform="uppercase"
                            weight={700}
                          >
                            {island.name}
                          </Text>
                        </div>
                      </Group>
                      <br />
                      {hoses
                        .filter((hose) => hose.islandId === island.id)
                        .map((hose, index) => {
                          return (
                            <Col key={index}>
                              <Paper withBorder radius="md" p="xs">
                                <Button
                                  style={{
                                    float: 'right',
                                    backgroundColor: 'transparent',
                                  }}
                                  onClick={() =>
                                    getHoseData(
                                      hose.id,
                                      hose.name,
                                      hose.islandId,
                                    )
                                  }
                                >
                                  <Edit size={30} color="#000" />
                                </Button>

                                {hose.occupied ? (
                                  <Text
                                    color="dimmed"
                                    size="xl"
                                    transform="uppercase"
                                    weight={700}
                                  >
                                    <CircleMinus size={30} color="red" />{' '}
                                    {hose.clientName}
                                  </Text>
                                ) : (
                                  <Text
                                    color="dimmed"
                                    size="xl"
                                    transform="uppercase"
                                    weight={700}
                                  >
                                    <Circle
                                      size={30}
                                      color="green"
                                      className="darkmode-ignore"
                                    />{' '}
                                    Desocupado
                                  </Text>
                                )}
                                <Group>
                                  <div>
                                    <Text
                                      color="dimmed"
                                      size="xl"
                                      transform="uppercase"
                                      weight={700}
                                    >
                                      {hose.name}
                                    </Text>
                                    <Text weight={700} size="md">
                                      {hose.createdAt}
                                    </Text>
                                  </div>
                                </Group>
                              </Paper>
                            </Col>
                          )
                        })}
                    </Paper>
                  </Col>
                )
              })} */}
              {islands.map((island, index) => {
                return (
                  <Col key={index}>
                    <Paper withBorder radius="md" p="xs">
                      <Group>
                        <BusStop
                          size={60}
                          color="green" //"#3e98c7"
                          style={{ marginRight: '10px', isolation: 'isolate' }}
                        />
                        <div>
                          <Text
                            color="dimmed"
                            size="xl"
                            transform="uppercase"
                            weight={700}
                          >
                            {island.name}
                          </Text>
                          <Text
                            color="dimmed"
                            size="md"
                            transform="uppercase"
                            weight={700}
                          >
                            Tanque :
                            <select
                              disabled={true}
                              defaultValue={island.tankId}
                              style={{ appearance: 'none', border: '0px' }}
                            >
                              {tanks.map((tank, index) => {
                                return (
                                  <option
                                    value={tank.id}
                                    key={index}
                                    style={{ display: 'none' }}
                                  >
                                    {tank.name}
                                  </option>
                                )
                              })}
                            </select>
                          </Text>
                        </div>
                      </Group>
                      <br />
                      <Row>
                        {hoses
                          .filter((hose) => hose.islandId === island.id)
                          .map((hose, index) => {
                            return (
                              <Col
                                key={index}
                                style={{ width: '100%', marginBottom: '20px' }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    border: `1px solid ${
                                      hose.occupied ? 'red' : 'green'
                                    }`,
                                    isolation: 'isolate',
                                    borderRadius: '5px',
                                  }}
                                >
                                  <Paper
                                    radius="none"
                                    className="hose_state"
                                    style={{
                                      width: '100%',
                                      borderRight: `1px solid ${
                                        hose.occupied ? 'red' : 'green'
                                      }`,
                                      isolation: 'isolate',
                                      backgroundColor: 'transparent',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: hose.occupied
                                          ? 'red'
                                          : 'green',
                                        isolation: 'isolate',
                                      }}
                                    >
                                      <Text
                                        color="white"
                                        size="sm"
                                        transform="uppercase"
                                        weight={500}
                                      >
                                        {hose.occupied
                                          ? hose.clientName
                                          : 'DISPONIBLE'}
                                      </Text>
                                    </div>
                                    <Group direction="row">
                                      <div
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Text
                                          color="dimmed"
                                          size="xs"
                                          p={5}
                                          transform="uppercase"
                                          weight={500}
                                        >
                                          {hose.name}
                                        </Text>
                                      </div>
                                    </Group>
                                  </Paper>
                                  <div
                                    style={{
                                      padding: '10px',
                                      cursor: 'pointer',
                                      justifyContent: 'center',
                                      isolation: 'isolate',
                                    }}
                                    onClick={() =>
                                      getHoseData(
                                        hose.id,
                                        hose.name,
                                        hose.islandId,
                                      )
                                    }
                                  >
                                    <Edit size={25} color={color} />
                                    <Text
                                      style={{
                                        fontSize: '12px',
                                        color: color,
                                      }}
                                    >
                                      Editar
                                    </Text>
                                  </div>
                                </div>
                              </Col>
                            )
                          })}
                      </Row>
                    </Paper>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog>
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nueva Manguera
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Nombre de la manguera"
                    placeholder="Nombre de la manguera"
                    value={form.values.hoseName}
                    onChange={(event) =>
                      form.setFieldValue('hoseName', event.currentTarget.value)
                    }
                  />

                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Isla
                    </InputLabel>
                    <MuiSelect
                      label="Isla"
                      onChange={(e) => handleIslandId(e.target.value)}
                    >
                      <MenuItem value={0}></MenuItem>
                      {islands.map((island, index) => {
                        return (
                          <MenuItem value={island.id} key={index}>
                            {island.name}
                          </MenuItem>
                        )
                      })}
                    </MuiSelect>
                  </FormControl>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createHose()}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalCreateShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog>
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando <i>{putForm.values.hoseName}</i> de{' '}
                <i>{getIslandById(putForm.values.islandId)}</i>
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Nombre de la isla"
                    placeholder="Nombre de la isla"
                    value={putForm.values.hoseName}
                    onChange={(event) =>
                      putForm.setFieldValue(
                        'hoseName',
                        event.currentTarget.value,
                      )
                    }
                  />

                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Isla
                    </InputLabel>
                    <MuiSelect
                      label="Isla"
                      onChange={(e) => handleEditIslandId(e.target.value)}
                      value={putForm.values.islandId}
                    >
                      <MenuItem value={0}></MenuItem>
                      {islands.map((island, index) => {
                        return (
                          <MenuItem value={island.id} key={index}>
                            {island.name}
                          </MenuItem>
                        )
                      })}
                    </MuiSelect>
                  </FormControl>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateClientHoses()}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
