import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import { TextField } from '@mui/material'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import calcularDigitoVerificacion from '../../services/calcularDigitoVerificacion'
import { apilUrl } from '../../services/url'
import LoadingScreen from '../../components/main/loadingScreen'
import { Edit } from 'tabler-icons-react'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'

import { isEmpty, validateEmail } from '../../services/utils'
import { CircularProgress } from '@mui/material'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import useStore from '../../components/zustand'

import ConfigurationMenus from '../../components/client/shortcutMenus/configurationMenus'
import CustomSelect from '../../components/custom/customSelect'
import { UpdateCompanyApi, getCompanyByIdApi } from '../api/companies'
import { departmentsData } from '../../services/selectData/placesData'
import ActionButton from '../../components/custom/actionButton'

export default function Company() {
  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  //Clients Company
  const [company, setCompany] = useState([])

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const [selectedOption, setSelectedOption] = useState('')

  const [municipiosOptions, setMunicipiosOptions] = React.useState([])

  const [selectedMunicipiosOptions, setSelectedMunicipiosOptions] = useState('')

  const [logoFile, setLogoFile] = React.useState(null)

  const form = useForm({
    initialValues: {
      id: 0,
      name: '',
      personType: 0,
      idType: 0,
      vatLiability: 0,
      taxManager: 0,
      email: '',
      phone: '',
      department: 0,
      municipalitie: 0,
      address: '',
    },
  })

  //GETS ISLAND FIELDS
  const getCompanyData = async (
    id,
    name,
    logo,
    personType,
    idType,
    vatLiability,
    taxManager,
    email,
    phone,
    department,
    municipalitie,
    idDepartment,
    cityCode,
    address,
  ) => {
    form.setFieldValue('id', id)
    form.setFieldValue('name', name)
    setLogoFile(logo)
    form.setFieldValue('personType', personType)
    form.setFieldValue('idType', idType)
    form.setFieldValue('vatLiability', vatLiability)
    form.setFieldValue('taxManager', taxManager)
    form.setFieldValue('email', email)
    form.setFieldValue('phone', phone)
    form.setFieldValue('department', department)
    form.setFieldValue('municipalitie', municipalitie)
    form.setFieldValue('address', address)
    setSelectedOption({ value: parseInt(idDepartment), label: department })
    setSelectedMunicipiosOptions({
      label: municipalitie,
      codigo_ciudad: cityCode,
    })
    setModalPutShow(true)
  }

  const handlIdType = (idType) => {
    form.setFieldValue('idType', idType)
  }

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }

  const handleLogoFile = ({ target }) => {
    if (checkIfIsFile(target.files[0].name)) {
      if (target.files < 1 || !target.validity.valid) {
        return
      }
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          // let base64String = /,(.+)/.exec(result)[1];
          setLogoFile(result)
        }
      })
    } else {
      notify(
        'warning',
        'El archivo debe ser un uno de los siguientes formatos. png, jpg o jpeg',
      )
    }
  }

  const checkIfIsFile = (file) => {
    let fileExtention =
      file.substring(file.lastIndexOf('.') + 1, file.length) || file
    if (
      fileExtention == 'png' ||
      fileExtention == 'jpg' ||
      fileExtention == 'jpeg'
    ) {
      return true
    } else {
      return false
    }
  }

  const getCompany = async (companyId) => {
    let result = await getCompanyByIdApi(companyId)
    if (result.data != null) setCompany(result.data)
    else notify('warning', 'Error al obtener la empresa.')
  }

  const getMunicipios = async (municipio) => {
    setSelectedOption(municipio)
    try {
      let result = await fetch(
        `${apilUrl}/municipios/filters/${municipio.value}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      setMunicipiosOptions(result.data)
    } catch (error) {
      return error
    }
  }

  const updateCompany = async () => {
    let readyToUpdate = true
    if (form.values.name == '') {
      readyToUpdate = false
      notify('warning', 'Falta el nombre de la empresa.')
    }
    if (logoFile == '' || logoFile == null) {
      readyToUpdate = false
      notify('warning', 'Falta el logotipo.')
    }
    if (form.values.personType <= 0) {
      readyToUpdate = false
      notify('warning', 'Falta el tipo de persona.')
    }
    if (parseInt(form.values.vatLiability) <= 0) {
      readyToUpdate = false
      notify('warning', 'Falta el responsable de IVA.')
    }
    if (parseInt(form.values.taxManager) <= 0) {
      readyToUpdate = false
      notify('warning', 'Falta el gerente de impuestos.')
    }
    if (form.values.email == '') {
      readyToUpdate = false
      notify('warning', 'Falta el correo electrónico.')
    }
    if (selectedOption.label == '') {
      readyToUpdate = false
      notify('warning', 'Falta el departamento.')
    }
    if (selectedMunicipiosOptions.label == '') {
      readyToUpdate = false
      notify('warning', 'Falta la ciudad/municipio.')
    }
    if (form.values.address == '') {
      readyToUpdate = false
      notify('warning', 'Falta la dirección.')
    }
    if (form.values.phone.length < 7) {
      readyToUpdate = false
      notify('warning', 'El número telefónico debe tener al menos 7 dígitos.')
    }

    if (readyToUpdate) {
      setIsCreateButtonDisabled(true)
      let companyData = {
        name: form.values.name,
        personType: parseInt(form.values.personType),
        idType: parseInt(form.values.idType),
        vatLiability: parseInt(form.values.vatLiability),
        taxManager: parseInt(form.values.taxManager),
        email: form.values.email,
        phone: form.values.phone,
        department: selectedOption.label,
        municipalitie: selectedMunicipiosOptions.label,
        idDepartment: selectedOption.value.toString(),
        cityCode: selectedMunicipiosOptions.codigo_ciudad,
        address: form.values.address,
        logo: logoFile,
      }
      let result = await UpdateCompanyApi(form.values.id, companyData)
      if (result.data == 'Company updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await getCompany(tokenData.companyId)
      } else {
        notify('warning', 'Error al actualizar empresa.')
      }
      setIsCreateButtonDisabled(false)
    }
  }

  const tokenVerified = async () => {
    //CHECK ROLES

    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }

    await getCompany(tokenData.companyId)
  }

  const getDocumentType = (id) => {
    switch (id) {
      case 1:
        return 'Nit'
      case 2:
        return 'Nit de otro país'
      case 3:
        return 'Cédula de extranjería'
      case 4:
        return 'Tarjeta de extranjería'
      case 5:
        return 'Documento de identificación extranjero'
      case 6:
        return 'Tarjeta de identidad'
      case 7:
        return 'Cédula de ciudadanía'
      case 8:
        return 'Registro civil'
      case 9:
        return 'Pasaporte'
      default:
        return ''
    }
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <ConfigurationMenus buttonId={2} />
          <br></br>
          <br></br>
          <MDBTable small>
            <MDBTableHead>
              <tr
                style={{
                  textAlign: 'center',
                }}
              >
                <th>Logo</th>
                <th>Empresa</th>
                <th>Tipo de persona</th>
                <th>Documento</th>
                <th>Correo</th>
                <th>Departamento</th>
                <th>Municipio</th>
                <th>Dirección</th>
                <th>Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {company.map((company, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <img
                        src={company.logo}
                        width="50px"
                        height="auto"
                        alt="company-logo"
                      ></img>
                    </td>
                    <td>{company.name}</td>
                    <td>
                      <select
                        style={{
                          appearance: 'none',
                          border: '0px',
                          backgroundColor: 'transparent',
                        }}
                        defaultValue={company.personType}
                        disabled={true}
                      >
                        <option value={2} style={{ display: 'none' }}>
                          Persona Jurídica
                        </option>
                        <option value={1} style={{ display: 'none' }}>
                          Persona Natural
                        </option>
                      </select>
                    </td>
                    <td>
                      {getDocumentType(company.idType)} {company.nit}-
                      {calcularDigitoVerificacion(company.nit)}
                    </td>
                    <td>{company.email}</td>
                    <td>{company.department}</td>
                    <td>{company.municipalitie}</td>
                    <td>{company.address}</td>
                    <td>
                      <ActionButton
                        description="Editar"
                        backgroundColor={bgColor}
                        onClick={() =>
                          getCompanyData(
                            company.id,
                            company.name,
                            company.logo,
                            company.personType,
                            company.idType,
                            company.vatLiability,
                            company.taxManager,
                            company.email,
                            company.phone,
                            company.department,
                            company.municipalitie,
                            company.idDepartment,
                            company.cityCode,
                            company.address,
                          )
                        }
                      >
                        <Edit size={30} color={color} />
                      </ActionButton>
                    </td>
                  </tr>
                )
              })}
            </MDBTableBody>
          </MDBTable>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando datos de empresa
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <img
                    src={logoFile}
                    width="300px"
                    alt="client-logo"
                    height="auto"
                  ></img>
                  <Group grow mb="md" mt="md">
                    <>
                      <label htmlFor="camCom" className="form-label">
                        Logo de empresa (png, jpg, jpeg) *
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="camCom"
                        type="file"
                        required
                        // value={fileName && fileName.name}
                        onChange={handleLogoFile}
                      />
                    </>
                  </Group>
                  <br></br>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Nombre De Empresa"
                      placeholder="Nombre De Empresa"
                      value={form.values.name}
                      onChange={(event) =>
                        form.setFieldValue('name', event.currentTarget.value)
                      }
                    />

                    <TextField
                      variant="standard"
                      required
                      label="Correo"
                      placeholder="Correo"
                      value={form.values.email}
                      type="email"
                      onChange={(event) => {
                        validateEmail(event.currentTarget.value)
                          ? form.setFieldValue(
                              'email',
                              event.currentTarget.value,
                            )
                          : form.setFieldValue('email', '')
                      }}
                    />
                  </Group>

                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Teléfono"
                      placeholder="Teléfono"
                      value={form.values.phone}
                      type="number"
                      onChange={(event) => {
                        form.setFieldValue('phone', event.currentTarget.value)
                      }}
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Direción"
                      placeholder="Direción"
                      value={form.values.address}
                      onChange={(event) =>
                        form.setFieldValue('address', event.currentTarget.value)
                      }
                    />
                  </Group>

                  <Group grow mb="md" mt="md">
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Tipo de persona
                      </InputLabel>
                      <MuiSelect
                        label="Tipo de persona"
                        onChange={(e) =>
                          form.setFieldValue('personType', e.target.value)
                        }
                        value={form.values.personType}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={2}>Persona Jurídica</MenuItem>
                        <MenuItem value={1}>Persona Natural</MenuItem>
                      </MuiSelect>
                    </FormControl>

                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Tipo de Documento
                      </InputLabel>
                      <MuiSelect
                        label="Tipo de documento"
                        onChange={(e) => handlIdType(e.target.value)}
                        value={form.values.idType}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>Nit</MenuItem>
                        <MenuItem value={2}>Nit de otro país</MenuItem>
                        <MenuItem value={3}>Cédula de extranjería</MenuItem>
                        <MenuItem value={4}>Tarjeta de extranjería</MenuItem>
                        <MenuItem value={5}>
                          Documento de identificación extranjero
                        </MenuItem>
                        <MenuItem value={6}>Tarjeta de identidad</MenuItem>
                        <MenuItem value={7}>Cédula de ciudadanía</MenuItem>
                        <MenuItem value={8}>Registro civil</MenuItem>
                        <MenuItem value={9}>Pasaporte</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>

                  <Group grow mb="md" mt="md">
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Responsabilidad del IVA
                      </InputLabel>
                      <MuiSelect
                        label="Responsabilidad del IVA"
                        onChange={(e) =>
                          form.setFieldValue('vatLiability', e.target.value)
                        }
                        value={form.values.vatLiability}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>No Responsable de IVA</MenuItem>
                        <MenuItem value={2}>Responsable de IVA</MenuItem>
                      </MuiSelect>
                    </FormControl>

                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Gerente de impuestos
                      </InputLabel>
                      <MuiSelect
                        label="Gerente de impuestos"
                        onChange={(e) =>
                          form.setFieldValue('taxManager', e.target.value)
                        }
                        value={form.values.taxManager}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>No responsable (R-99-PN)</MenuItem>
                        <MenuItem value={2}>
                          Régimen simple de tributación (O-47)
                        </MenuItem>
                        <MenuItem value={3}>Gran Contribuyente (O-13)</MenuItem>
                        <MenuItem value={4}>Autorretenedor (O-15)</MenuItem>
                        <MenuItem value={5}>
                          Agente de retención (O-23)
                        </MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                  <CustomSelect
                    title="Departamento"
                    placeholder="Departamento"
                    defaultValue={selectedOption}
                    onChange={getMunicipios}
                    options={departmentsData}
                    value={selectedOption}
                    theme={isDarkModeActive ? 'black' : 'white'}
                  />
                  <CustomSelect
                    title="Municipio"
                    placeholder="Municipio"
                    defaultValue={selectedMunicipiosOptions}
                    onChange={setSelectedMunicipiosOptions}
                    options={municipiosOptions}
                    value={selectedMunicipiosOptions}
                    theme={isDarkModeActive ? 'black' : 'white'}
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateCompany()}
                disabled={isCreateButtonDisabled}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
