import React, { useState } from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown'
import '../../styles/navdropdown.css'

export default function CustomNavBarDropdown({
  title,
  id,
  drop = 'down',
  children,
  hoverable = true,
}) {
  const [show, setShow] = useState(false)

  const [timeoutId, setTimeoutId] = useState(null)

  const handleMouseLeave = () => {
    setTimeoutId(setTimeout(() => setShow(false), 100))
  }

  const handleMouseMove = () => {
    setShow(true)
    if (timeoutId) clearTimeout(timeoutId)
  }

  return (
    <NavDropdown
    className='navdropdown'
      title={title}
      id={id}
      drop={drop}
      show={hoverable ? show : undefined}
      onMouseMove={() => hoverable && handleMouseMove()}
      onMouseLeave={() => hoverable && handleMouseLeave()}
      onClick={() => !hoverable && setShow(!show)}
    >
      {children}
    </NavDropdown>
  )
}
