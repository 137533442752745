import Button from 'react-bootstrap/Button'
import { read, utils } from 'xlsx'
import React, { useEffect, useState } from 'react'
import useStore from '../../../components/zustand'
import { notify } from '../../../services/utils'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { Trash } from 'tabler-icons-react'
import { CircularProgress, TextField } from '@mui/material'
import { Group } from '@mantine/core'
import { Container } from 'react-bootstrap'
import { useForm } from '@mantine/hooks'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { PostBulkClientProductsApi } from '../../../pages/api/clientProducts'

export default function ProductsUploadModal(props) {
  const { show, onClose, reloadTable } = props
  const { tokenData, isDarkModeActive } = useStore()

  const [loadedProducts, setLoadedProducts] = useState([])

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const handleImport = ($event) => {
    const files = $event.target.files
    if (files.length) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = async (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          try {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
            let loadedInfo = getLoadedInfo(rows)
            setLoadedProducts(loadedInfo)
          } catch (e) {
            notify(
              'error',
              'Verifique que el nombre de todas las columnas sea correcto.',
            )
          }
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const rowColumnNames = useForm({
    initialValues: {
      code: 'code',
      name: 'name',
      price: 'price',
      unitOfMeasurement: 'unitOfMeasurement',
      units: 'units',
      vat: 'vat',
      vatPrice: 'vatPrice',
      store: 'store',
    },
  })

  // PARA SUBIR REFERENCIAS SCI, NO BORRAR
  // const getLoadedInfo = (rows) => {
  //   let products = []
  //   rows.map((row) => {
  //     products.push({
  //       branchId: tokenData.branchId,
  //       clientUsersId: tokenData.clientId,
  //       code: row['REFERENCIA'].trim(),
  //       companyId: tokenData.companyId,
  //       name: row['DESCRIPCION'].trim(),
  //       price: parseInt(row['VALOR']), //parseInt(row[rowColumnNames.values.price]),
  //       unitOfMeasurement: '04', // row[rowColumnNames.values.unitOfMeasurement],
  //       units: 0, // parseInt(row[rowColumnNames.values.units]),
  //       vat: 0, // parseInt(row[rowColumnNames.values.vat]),
  //       vatPrice: 0, // parseInt(row[rowColumnNames.values.vatPrice]),
  //       store: row['BODEGA'].trim(),
  //     })
  //   })
  //   return products
  // }

  const getLoadedInfo = (rows) => {
    let products = []
    rows.map((row) => {
      products.push({
        branchId: tokenData.branchId,
        clientUsersId: tokenData.clientId,
        code: row[rowColumnNames.values.code].toString().trim(),
        companyId: tokenData.companyId,
        name: row[rowColumnNames.values.name].trim(),
        price: parseFloat(row[rowColumnNames.values.price]),
        unitOfMeasurement: row[rowColumnNames.values.unitOfMeasurement].trim(),
        units: parseInt(row[rowColumnNames.values.units]),
        vat: parseInt(row[rowColumnNames.values.vat]),
        vatPrice: parseFloat(row[rowColumnNames.values.vatPrice]),
        store: row[rowColumnNames.values.store].trim(),
      })
    })
    return products
  }

  const handleUpload = async () => {
    await uploadProducts(loadedProducts)
  }

  const uploadProducts = async (products) => {
    setIsCreateButtonDisabled(true)
    let result = await PostBulkClientProductsApi(products)
    if (result.data != null) successCreation(result)
    else notify('warning', 'Error al subir los productos masivamente.')
    setIsCreateButtonDisabled(false)
  }

  const successCreation = async (data) => {
    if (data.data == 'Products Bulk Created') {
      notify('success', 'Productos cargados con éxito.')
      onClose()
      await reloadTable()
    } else {
      notify('warning', data.data)
    }
  }

  const modifyProduct = (productName, value, index) => {
    let loadedProductsAux = [...loadedProducts]
    loadedProductsAux[index][productName] = value

    setLoadedProducts(loadedProductsAux)
  }

  const deleteProduct = (index) => {
    let loadedProductsAux = [...loadedProducts]
    loadedProductsAux.splice(index, 1)
    setLoadedProducts(loadedProductsAux)
  }

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size={loadedProducts.length == 0 ? 'lg' : 'xl'}>
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Carga masiva de productos
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            {loadedProducts.length == 0 ? (
              <Container>
                <Container>
                  <h4>Columnas de tu archivo</h4>
                  <p>
                    En este apartado deberás diligenciar el nombre de tus
                    columnas del archivo EXCEL, correspondientes a la
                    información que necesitamos de tus productos
                  </p>
                  <hr></hr>
                  <Group grow>
                    <TextField
                      variant="standard"
                      label="Código del producto"
                      placeholder="Columna del código"
                      onChange={(event) => {
                        rowColumnNames.setFieldValue(
                          'code',
                          event.currentTarget.value,
                        )
                      }}
                    />
                    <TextField
                      variant="standard"
                      label="Nombre del producto"
                      placeholder="Columna del nombre"
                      onChange={(event) => {
                        rowColumnNames.setFieldValue(
                          'name',
                          event.currentTarget.value,
                        )
                      }}
                    />
                    <TextField
                      variant="standard"
                      label="Precio del producto"
                      placeholder="Columna del precio"
                      onChange={(event) => {
                        rowColumnNames.setFieldValue(
                          'price',
                          event.currentTarget.value,
                        )
                      }}
                    />
                  </Group>
                  <Group grow>
                    <TextField
                      variant="standard"
                      label="Unidad de medida"
                      placeholder="Columna de unidad de medida"
                      onChange={(event) => {
                        rowColumnNames.setFieldValue(
                          'unitOfMeasurement',
                          event.currentTarget.value,
                        )
                      }}
                    />
                    <TextField
                      variant="standard"
                      label="Unidades en inventario"
                      placeholder="Columna de cantidad de unidades"
                      onChange={(event) => {
                        rowColumnNames.setFieldValue(
                          'units',
                          event.currentTarget.value,
                        )
                      }}
                    />
                  </Group>
                  <Group grow>
                    <TextField
                      variant="standard"
                      label="IVA"
                      placeholder="Columna del IVA"
                      onChange={(event) => {
                        rowColumnNames.setFieldValue(
                          'vat',
                          event.currentTarget.value,
                        )
                      }}
                    />
                    <TextField
                      variant="standard"
                      label="Precio con IVA"
                      placeholder="Columna del precio con IVA"
                      onChange={(event) => {
                        rowColumnNames.setFieldValue(
                          'vatPrice',
                          event.currentTarget.value,
                        )
                      }}
                    />
                    <TextField
                      variant="standard"
                      label="Bodega"
                      placeholder="Columna del código de bodega"
                      onChange={(event) => {
                        rowColumnNames.setFieldValue(
                          'store',
                          event.currentTarget.value,
                        )
                      }}
                    />
                  </Group>
                </Container>
                <hr></hr>
                <Container>
                  <h4>Ahora, carga tu archivo</h4>
                  <input
                    type="file"
                    name="file"
                    className="form-control form-control-xl"
                    id="inputGroupFile"
                    required
                    style={{ maxWidth: '400px' }}
                    onChange={handleImport}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Container>
              </Container>
            ) : null}
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              disabled={loadedProducts.length == 0 || isCreateButtonDisabled}
              style={{ backgroundColor: 'green', borderRadius: '100px' }}
              onClick={() => handleUpload()}
            >
              {isCreateButtonDisabled ? (
                <CircularProgress size={15} color="inherit" />
              ) : null}{' '}
              Subir
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cerrar
            </Button>
          </MDBModalFooter>
          <MDBModalBody>
            {loadedProducts.length > 0 ? (
              <MDBTable small style={{ color: color }}>
                <MDBTableHead>
                  <tr>
                    <th>Código</th>
                    <th>Nombre</th>
                    <th>Unidades</th>
                    <th>Precio</th>
                    <th>Unid. Medida</th>
                    <th>IVA</th>
                    <th>Bodega</th>
                    <th>Acciones</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                  {loadedProducts.map((loadedProduct, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <TextField
                            variant="standard"
                            value={loadedProduct.code}
                            style={{
                              width: '45px',
                            }}
                            onChange={(event) => {
                              modifyProduct(
                                'code',
                                event.currentTarget.value,
                                index,
                              )
                            }}
                          />
                        </td>
                        <td>
                          <TextField
                            variant="standard"
                            value={loadedProduct.name}
                            onChange={(event) => {
                              modifyProduct(
                                'name',
                                event.currentTarget.value,
                                index,
                              )
                            }}
                          />
                        </td>
                        <td>
                          <TextField
                            variant="standard"
                            type="number"
                            value={loadedProduct.units}
                            style={{
                              width: '60px',
                            }}
                            onChange={(event) => {
                              modifyProduct(
                                'units',
                                parseInt(event.currentTarget.value),
                                index,
                              )
                            }}
                          />
                        </td>
                        <td>
                          <TextField
                            variant="standard"
                            value={loadedProduct.price}
                            type="number"
                            style={{
                              width: '75px',
                            }}
                            onChange={(event) => {
                              modifyProduct(
                                'price',
                                parseInt(event.currentTarget.value),
                                index,
                              )
                            }}
                          />
                        </td>
                        <td>
                          <TextField
                            variant="standard"
                            value={loadedProduct.unitOfMeasurement}
                            style={{
                              width: '40px',
                            }}
                            onChange={(event) => {
                              modifyProduct(
                                'unitOfMeasurement',
                                event.currentTarget.value,
                                index,
                              )
                            }}
                          />
                        </td>
                        <td>
                          <TextField
                            variant="standard"
                            value={loadedProduct.vat}
                            type="number"
                            style={{
                              width: '40px',
                            }}
                            onChange={(event) => {
                              modifyProduct(
                                'vat',
                                parseInt(event.currentTarget.value),
                                index,
                              )
                            }}
                          />
                        </td>
                        <td>
                          <TextField
                            variant="standard"
                            value={loadedProduct.store}
                            style={{
                              width: '40px',
                            }}
                            onChange={(event) => {
                              modifyProduct(
                                'store',
                                event.currentTarget.value,
                                index,
                              )
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            style={{
                              float: 'right',
                              backgroundColor: 'transparent',
                            }}
                            onClick={() => deleteProduct(index)}
                          >
                            <Trash size={25} color="red" />
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
            ) : null}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}
