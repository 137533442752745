import React, { useEffect, useState } from 'react'
import { read, utils } from 'xlsx'
import useStore from '../../components/zustand'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import { isEmpty, setDecimal } from '../../services/utils'
import { useNavigate } from 'react-router'
import { notify } from '../../services/utils'
import SalesMenus from '../../components/client/shortcutMenus/salesMenus'
import LoadingScreen from '../../components/main/loadingScreen'
import Button from 'react-bootstrap/Button'
import { Download, QuestionMark } from 'tabler-icons-react'
import ReactJoyride from 'react-joyride'
import { ExportExcel } from '../../services/reportExport'
import { compareClientSalesApi } from '../api/clientsale'

const CompareSales = () => {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  const [loadedSales, setLoadedSales] = useState([])
  const [sales, setSales] = useState([])

  const resetConstants = () => {
    setSales([])
    setLoadedSales([])
  }

  const handleImport = ($event) => {
    resetConstants()
    const files = $event.target.files
    if (files.length) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = async (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          let loadedInfo = getLoadedInfo(rows)

          setLoadedSales(rows)
          await getSales(loadedInfo[0], tokenData.companyId, rows)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const getLoadedInfo = (rows) => {
    let numerations = []
    let busPlates = []
    let creationDates = []
    rows.map((row) => {
      busPlates.push(row['Placa '])
      creationDates.push(row['Fecha Venta '].substring(0, 10))
      numerations.push(row['No. pedido o POS '].toString())
    })
    return [numerations, busPlates, creationDates]
  }

  const getSales = async (numerations, companyId, loadedSales) => {
    try {
      let data = {
        receiptDispenserNumeration: numerations,
      }
      let result = await compareClientSalesApi(data, companyId)
      if (result.data != null) {
        let sales = compareSales(result.data, loadedSales)
        setSales(sales)
      }
    } catch (error) {
      return error
    }
  }

  const findSale = (sales, loadedSaleId) => {
    let sale = sales.find((x) => x.receiptDispenserNumeration == loadedSaleId)
    return sale
  }

  const compareSales = (sales, loadedSales) => {
    let validSales = []
    loadedSales.map((loadedSale) => {
      let findedSale = findSale(sales, loadedSale['No. pedido o POS '])
      if (findedSale == undefined) {
        findedSale = {
          receiptDispenserNumeration: loadedSale['No. pedido o POS '],
          busPlate: loadedSale['Placa '],
          total: loadedSale['Total '],
          state: 'NA',
        }
      } else {
        if (
          Math.abs(
            parseFloat(findedSale.total) - parseFloat(loadedSale['Total ']),
          ) > 10
        ) {
          findedSale.state = 'Error'
        } else {
          findedSale.state = 'Validada'
        }
      }
      validSales.push(findedSale)
    })
    return validSales
  }

  const setStateStyles = (saleState) => {
    let color = '#000000'
    switch (saleState) {
      case 'Validada':
        color = '#66BB6A'
        break
      default:
        color = '#EF5350'
        break
    }

    return {
      borderRadius: '7%',
      backgroundColor: color,
      color: 'white',
      width: 'fit-content',
      padding: '0px 3px',
      margin: '0 10px 0 10px',
      fontSize: '14px',
      textAlign: 'center',
    }
  }

  const tokenVerified = async () => {
    //CHECK ROLES

    if (!(rolesData.admin || rolesData.configuration)) {
      notify('warning', 'No tienes permiso para ver este módulo.')
      navigate('/dashboard')
    }
  }

  //---------------------- Joyride -----------------------
  const [runJoyride, setRunJoyride] = useState(false)
  const [joyrideSteps] = useState([
    {
      content:
        'Este es el módulo de comparación de ventas, en el cual podrás comparar las ventas registradas por el dispensador respecto a las registradas en AvrilApp.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      placement: 'center',
      target: 'body',
      title: 'Comparación de Ventas',
    },
    {
      content:
        'En este apartado deberás cargar las ventas que deseas comparar con las registradas en el sistema (Preferiblemente un archivo tipo EXCEL).',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      spotlightPadding: 20,
      target: '.form-control',
      title: 'Carga de ventas',
      placement: 'top',
    },
    {
      content:
        'Aquí podrás ver información de las ventas que cargaste desde el archivo.',
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.loadedSales',
      title: 'Ventas Cargadas',
    },
    {
      content:
        'Aquí se reflejarán las ventas que se encontraron en el sistema y se le mostrará si el monto es igual o no.',
      placement: 'top',
      target: '.sales',
      title: 'Ventas del Sistema',
      locale: {
        skip: 'Saltar',
        next: 'Siguiente',
        back: 'Atrás',
        last: 'Terminar',
      },
    },
  ])

  const handleJoyrideCallback = (CallBackProps) => {
    const status = CallBackProps.status

    if (status == 'ready') {
      setRunJoyride(false)
    }
  }
  //-----------------Reports------------------

  const generateReport = async (data) => {
    try {
      let ExcelExportName = `ventasComparadas`
      ExportExcel({
        excelData: trimSalesReport(data),
        fileName: ExcelExportName,
      })
    } catch (error) {
      return error
    }
  }

  const trimSalesReport = (sales) => {
    let report = []
    sales.map((sale) => {
      let reportSale = {}
      reportSale.placa = sale.busPlate
      reportSale.total = sale.total
      reportSale.numeracion_dispensador = sale.receiptDispenserNumeration
      reportSale.estado = sale.state
      report.push(reportSale)
    })
    return report
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  return (
    <div>
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={runJoyride}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={joyrideSteps}
        styles={{
          options: {
            zIndex: 20,
            primaryColor: 'green',
          },
        }}
      />
      <Menu />
      {!loading ? (
        <Container>
          <Button
            onClick={() => setRunJoyride(true)}
            style={{ float: 'right', background: 'gray', border: 'none' }}
          >
            <QuestionMark /> Ayuda
          </Button>
          <SalesMenus buttonId={2} />
          <br></br>
          <h5>Carga de Ventas</h5>
          <Container className="custom-file">
            {sales.length > 0 ? (
              <Button
                onClick={() => generateReport(sales)}
                style={{ float: 'right', background: 'green', border: 'none' }}
              >
                <Download
                  style={{ marginRight: '10px', marginBottom: '5px' }}
                  size={20}
                />
                Descargar Reporte
              </Button>
            ) : null}
            <input
              type="file"
              name="file"
              className="form-control form-control-xl"
              id="inputGroupFile"
              required
              style={{ maxWidth: '400px' }}
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Container>
          <hr></hr>
          <br></br>
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <h4>Ventas cargadas</h4>
                <MDBTable className="loadedSales">
                  <MDBTableHead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Placa</th>
                      <th scope="col">Total</th>
                      <th scope="col">Fecha</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {loadedSales.length ? (
                      loadedSales.map((loadedSale, index) => (
                        <tr key={index}>
                          <td>{loadedSale['No. pedido o POS ']}</td>
                          <td>{loadedSale['Placa ']}</td>
                          <td>$ {setDecimal(loadedSale['Total '])}</td>
                          <td>{loadedSale['Fecha Venta ']}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No está comparando ventas aún.
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <h4>Ventas del Sistema</h4>
                <MDBTable className="sales" style={{ borderLeft: '1px solid' }}>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Placa</th>
                      <th scope="col">Total</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Estado</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {loadedSales.length ? (
                      sales.map((sale, index) => (
                        <tr key={index}>
                          <td>{sale.receiptDispenserNumeration}</td>
                          <td>{sale.busPlate}</td>
                          <td>${setDecimal(sale.total)}</td>
                          <td>{sale.createdAt}</td>
                          <td>
                            <div style={setStateStyles(sale.state)}>
                              {sale.state}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No está comparando ventas aún.
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </div>
  )
}

export default CompareSales
