import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import { TextField } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { notify } from '../../../services/utils'
import useStore from '../../../components/zustand'

//Token Verification
import { verifiedToken } from '../../../services/token.service'
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
} from '@mui/material'
import { getGallonPricesApi, updateGallonPriceApi } from '../../api/gallonPrice'

export default function GallonPrice(props) {
  const { tokenData } = useStore()
  const navigate = useNavigate()

  const enumFuelType = {
    1: 'Diésel',
    2: 'Corriente',
    3: 'Extra',
    4: 'Premium',
    5: 'Urea',
    6: 'ACPM',
    7: 'Greenplus',
  }

  const [fuelInfo, setFuelInfo] = useState({})

  const getGallonPrices = async (companyId) => {
    let result = await getGallonPricesApi(companyId)
    if (result.data != null) {
      let gallonPrices = []
      result.data.map((fuel) => {
        gallonPrices.push({
          id: fuel.id,
          price: fuel.price,
          reference: fuel.reference,
          store: fuel.store,
        })
      })
      setFuelInfo(gallonPrices)
    }
  }

  const tokenVerified = async () => {
    let result = await verifiedToken()

    if (result == false) {
      navigate('/')
    } else {
      await getData()
      await getGallonPrices(tokenData.companyId)
    }
  }

  const setFuelData = (id, type, value) => {
    let fuelInfoCopy = [...fuelInfo]
    fuelInfoCopy.map((fuel, index) => {
      if (index == id) {
        fuel[type] = type == 'price' ? parseInt(value) : value
      }
    })
    setFuelInfo(fuelInfoCopy)
  }

  const updateGallonPrice = async () => {
    let result = await updateGallonPriceApi(fuelInfo)
    if (result.data == 'GallonPrice updated') {
      notify('success', 'Actualización Exitoso')

      //CALL PARENT FUNCTION
      window.setTimeout(() => {
        props.onNextClick()
      }, 500)
      await getGallonPrices(tokenData.companyId)
    } else {
      notify('warning', 'Error al actualizar precio del galón.')
    }
  }

  //creating function to load ip address from the API
  const getData = async () => {
    //const res = await axios.get('https://geolocation-db.com/json/')
    //setIP(res.data.IPv4)
  }

  const gasSetter = useForm({
    initialValues: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
    },
  })

  const setGasSetter = (fuelType, isChecked) => {
    gasSetter.setFieldValue(fuelType, isChecked)
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  return (
    <div>
      <Container>
        <form>
          <Group direction="column" grow>
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                TIPO DE COMBUSTIBLE
              </FormLabel>
              <FormGroup>
                {Object.keys(enumFuelType).map((type, index) => {
                  return (
                    <Group key={index} grow>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="success"
                            onChange={(event) => {
                              setGasSetter(type, event.target.checked)
                              if (!event.target.checked) {
                                setFuelData(index, 'price', 0)
                                setFuelData(index, 'reference', '')
                                setFuelData(index, 'store', '')
                              }
                            }}
                          />
                        }
                        label={enumFuelType[type]}
                      />
                      {gasSetter.values[type] == true ? (
                        <Group>
                          <TextField
                            variant="standard"
                            required
                            label={'Precio Galón de ' + enumFuelType[type]}
                            type="number"
                            placeholder="Precio de galón"
                            onChange={(event) =>
                              setFuelData(
                                index,
                                'price',
                                event.currentTarget.value,
                              )
                            }
                          />
                          <TextField
                            variant="standard"
                            required
                            label="Bodega"
                            type="text"
                            placeholder="Bodega"
                            onChange={(event) =>
                              setFuelData(
                                index,
                                'store',
                                event.currentTarget.value,
                              )
                            }
                          />
                          <TextField
                            variant="standard"
                            required
                            label="Referencia"
                            type="text"
                            placeholder="Referencia"
                            onChange={(event) =>
                              setFuelData(
                                index,
                                'reference',
                                event.currentTarget.value,
                              )
                            }
                          />
                        </Group>
                      ) : null}
                    </Group>
                  )
                })}
              </FormGroup>
            </FormControl>
          </Group>
          <br></br>
          <Button
            style={{
              backgroundColor: 'green',
              borderRadius: '100px',
              width: '100%',
              isolation: 'isolate',
            }}
            onClick={() => updateGallonPrice()}
          >
            Aceptar
          </Button>
        </form>
      </Container>
    </div>
  )
}
