import React, { useState, useEffect } from 'react'
import { useForm } from '@mantine/hooks'
import { notify } from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { Container } from 'react-bootstrap'
import dayjs from 'dayjs'
import { apilUrl } from '../../../services/url'
import { ArrowLeft } from 'tabler-icons-react'
import EditClientUser from './editClientUser'
import { getPayrollEntriesByClientUserIdApi } from '../../../pages/api/payroll'
import PayrollHistorical from '../payroll/payrollHistorical'
import Pdfviewer from '../../custom/pdfviewer'
import ClientUserInfo from './clientUserInfo'

const ClientUserDetails = ({
  onClose,
  getClientUsers,
  branches,
  clientUserDetails,
}) => {

  const infoForm = useForm({
    initialValues: {
      fullName: '',
      username: '',
      email: '',
      roleId: 0,
      stateId: 0,
      userId: '',
      userIdType: 0,
      name: '',
      otherNames: '',
      lastName: '',
      secondLastName: '',
      country: '',
      idDepartment: '',
      cityCode: '',
      address: '',
      countryCode: 57,
      phone: '',
    },
  })

  const rolesForm = useForm({
    initialValues: {
      admin: false,
      sales: false,
      invoice: false,
      modules: false,
      clients: false,
      configuration: false,
    },
  })

  const payrollForm = useForm({
    initialValues: {
      salary: 0.0,
      workerType: 0,
      subCode: 0,
      periodicity: 0,
      contractType: 0,
      area: 0,
      position: '',
      startDate: dayjs(new Date()),
      fireDate: dayjs(new Date()),
      eps: 0,
      pension: 0,
      layoffs: 0,
      workRisk: 0,
      compensation: 0,
      accountTypeKw: 0,
      accountNumber: '',
      bank: '',
      highRisk: false,
      paymentMeans: 0,
    },
  })

  const [payrollEntries, setPayrollEntries] = useState([])
  const [entriesCount, setEntriesCount] = useState(0)

  const getPayrollEntries = async (clientUser, offset, limit) => {
    let result = await getPayrollEntriesByClientUserIdApi(
      clientUser.companyId,
      clientUser.branchId,
      clientUser.clientUserId,
      offset,
      limit,
    )
    if (result.data != null) {
      setPayrollEntries(result.data)
      setEntriesCount(result.count)
    } else notify('warning', 'No se encontraron nóminas electrónicas.')
  }

  const [modalEditUserShow, setModalEditUserShow] = React.useState(false)
  const handleOnCloseEdit = () => setModalEditUserShow(false)

  const [clientUserToUpdate, setClientUserToUpdate] = useState({})

  //-----------------------pdf viewer-----------------------
  const [pdfViewerShow, setPdfViewerShow] = React.useState(false)
  const [pdfHeaders, setPdfHeaders] = React.useState({
    title: '',
    src: '',
    fileType: '',
  })
  const handleClosePdfViewer = () => setPdfViewerShow(false)

  const showPdfViewer = (title, src, fileType) => {
    setPdfHeaders({ title, src, fileType })
    setPdfViewerShow(true)
  }
  //--------------------------------------------------------

  useEffect(() => {
    getPayrollEntries(clientUserDetails, 0, 10)
    
    infoForm.setValues({
      clientId: clientUserDetails.clientUserId,
      userIdType: clientUserDetails.userIdType.Int32,
      userId: clientUserDetails.userId,
      name: clientUserDetails.name.String,
      otherNames: clientUserDetails.otherNames.String,
      lastName: clientUserDetails.lastName.String,
      secondLastName: clientUserDetails.secondLastName.String,
      country: clientUserDetails.country.Int32,
      idDepartment: clientUserDetails.idDepartment.Int32,
      cityCode: clientUserDetails.cityCode.Int32,
      address: clientUserDetails.address.String,
      phone: clientUserDetails.phone.String,
      email: clientUserDetails.email,
      roleId: clientUserDetails.roleId,
    })
    payrollForm.setValues({
      salary: clientUserDetails.salary,
      workerType: clientUserDetails.workerType,
      subCode: clientUserDetails.subCode,
      periodicity: clientUserDetails.periodicity,
      contractType: clientUserDetails.contractType,
      area: clientUserDetails.area,
      position: clientUserDetails.position,
      startDate: dayjs(clientUserDetails.startDate),
      fireDate: dayjs(clientUserDetails.fireDate),
      eps: clientUserDetails.eps,
      pension: clientUserDetails.pension,
      layoffs: clientUserDetails.layoffs,
      workRisk: clientUserDetails.workRisk,
      compensation: clientUserDetails.compensation,
      accountTypeKw: clientUserDetails.accountTypeKw,
      accountNumber: clientUserDetails.accountNumber,
      bank: clientUserDetails.bank,
      highRisk: clientUserDetails.highRisk,
      paymentMeans: clientUserDetails.paymentMeans,
    })
    rolesForm.setValues({
      admin: clientUserDetails.admin,
      sales: clientUserDetails.sales,
      invoice: clientUserDetails.invoice,
      modules: clientUserDetails.modules,
      clients: clientUserDetails.clients,
      configuration: clientUserDetails.configuration,
    })
  }, [clientUserDetails])
  return (
    <Container>
      {modalEditUserShow ? (
        <EditClientUser
          onClose={handleOnCloseEdit}
          clientUserToUpdate={clientUserToUpdate}
          getClientUsers={getClientUsers}
          branches={branches}
        />
      ) : pdfViewerShow ? (
        <Pdfviewer
          title={pdfHeaders.title}
          src={pdfHeaders.src}
          fileType={pdfHeaders.fileType}
          onClose={handleClosePdfViewer}
        />
      ) : (
        <div>
          <div
            style={{
              margin: '10px',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              width: 'fit-content',
            }}
            onClick={onClose}
          >
            <ArrowLeft />
            Atrás
          </div>
          <ClientUserInfo
            clientUserDetails={clientUserDetails}
            branches={branches}
            setModalEditUserShow={setModalEditUserShow}
            setClientUserToUpdate={setClientUserToUpdate}
            editable
          />
          <br></br>
          <PayrollHistorical
            getPayrollEntries={getPayrollEntries}
            clientUserDetails={clientUserDetails}
            payrollEntries={payrollEntries}
            entriesCount={entriesCount}
            showPdfViewer={showPdfViewer}
          />
        </div>
      )}
    </Container>
  )
}

export default ClientUserDetails
