import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const UpdateClientTankGallonsApi = async (tankId, tankData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-tanks/gallons/${tankId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tankData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CTANKS-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar los galones del tanque.')
  }
}

export const getClientTankGallonsByIslandApi = async (
  companyId,
  branchId,
  islandId,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-tanks/gallons/${companyId}/${branchId}/${islandId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CTANKS-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los galones del tanque para la isla.')
  }
}

export const getClientTanksApi = async (companyId, branchId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-tanks/one/${companyId}/${branchId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CTANKS-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los tanques.')
  }
}

export const PostClientTankApi = async (tankData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-tanks`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tankData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CTANKS-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear el tanque.')
  }
}

export const UpdateClientTankApi = async (tankId, tankData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-tanks/${tankId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tankData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CTANKS-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el tanque.')
  }
}
