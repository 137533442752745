import { clientUserLogoutApi } from '../pages/api/clientUsers'
import { notify } from './utils'

export const LogOut = async (isExpired = false) => {
  localStorage.clear()
  if (isExpired)
    notify(
      'warning',
      'Su suscripción ha finalizado, por favor contacte a soporte técnico.',
    )

  let result = await clientUserLogoutApi()
  if (result.data == 'logged out') {
    window.open('/', '_self')
  }
}
