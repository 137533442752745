import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import useStore from '../../../components/zustand'
import { notify } from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import CompanyGetStarted from '../getStarted/companyGetStarted'
import GallonPriceGetStarted from '../getStarted/gallonpriceGetStarted'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'

//Token Verification
import { verifiedToken } from '../../../services/token.service'
import { isEmpty } from '../../../services/utils'
import { clientUserGetStartedApi } from '../../api/clientUsers'

export default function GetStarted() {
  const { tokenData } = useStore()
  //-------------Stepper-----------------
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set())
  const steps = ['Tipo de gasolina y su valor', 'Información de la empresa']

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  //-------------
  const ref = useRef(null)

  const navigate = useNavigate()

  const [clientId, setClientId] = useState(0)

  const start = async () => {
    let data = {}

    let result = await clientUserGetStartedApi(clientId, data)
    if (result.data == 'Client Get Started Updated') {
      navigate('/dashboard')
    } else {
      notify('warning', 'Error al actualizar el cliente recién creado.')
    }
    navigate('/dashboard')
  }

  const tokenVerified = async () => {
    let result = await verifiedToken()

    if (result == false || isEmpty(tokenData)) {
      navigate('/login')
    } else {
      setClientId(tokenData.clientId)
    }
  }

  useEffect(() => {
    tokenVerified()
  }, [tokenData])

  return (
    <Container style={{ width: '80%' }}>
      <br></br>
      <br></br>
      <h2 style={{ textAlign: 'center' }}>Gracias por confiar en AvrilApp</h2>
      <p style={{ textAlign: 'center' }}>
        Para continuar por favor llena los siguientes campos.
      </p>
      <br></br>

      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {}
          const labelProps = {}
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <br></br>
      <br></br>
      {activeStep == 0 ? (
        <React.Fragment>
          <GallonPriceGetStarted onNextClick={handleNext} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CompanyGetStarted start={start} />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <br></br>
            <Button onClick={handleBack}>Atrás</Button>
          </Box>
        </React.Fragment>
      )}
    </Container>
  )
}
