export const documentTypes = [
  { value: 1, label: 'Cédula de extranjería', code: 'CEDULA_DE_EXTRANJERIA' },
  { value: 2, label: 'NUIP', code: 'NUIP' },
  { value: 3, label: 'PEP', code: 'PEP' },
  { value: 4, label: 'Tarjeta de extranjería', code: 'TARJETA_DE_EXTRANJERIA' },
  { value: 5, label: 'NIT de otro país', code: 'NIT_DE_OTRO_PAIS' },
  { value: 6, label: 'Tarjeta de identidad', code: 'TARJETA_DE_IDENTIDAD' },
  {
    value: 7,
    label: 'Documento de identificación extranjero',
    code: 'DOCUMENTO_DE_IDENTIFICATION_EXTRANJERO',
  },
  { value: 8, label: 'Pasaporte', code: 'PASAPORTE' },
  { value: 9, label: 'Cédula de ciudadanía', code: 'CEDULA_DE_CIUDADANIA' },
  { value: 10, label: 'NIT', code: 'NIT' },
  { value: 11, label: 'Registro civil', code: 'REGISTRO_CIVIL' },
]

export const paymentMeansTypes = [
  { value: 1, label: 'Cheque', code: 'CHEQUE' },
  { value: 2, label: 'Consiganción', code: 'CONSIGNACION' },
  { value: 3, label: 'Efectivo', code: 'EFECTIVO' },
  { value: 4, label: 'Transferencia', code: 'TRANSFERENCIA' },
  { value: 5, label: 'Crédito', code: 'CREDITO' },
  { value: 6, label: 'Débito', code: 'DEBITO' },
  { value: 7, label: 'Débito ahorro', code: 'DEBITO_AHORRO' },
  { value: 8, label: 'Otro', code: 'OTRO' },
]

export const workerTypes = [
  {
    value: 1,
    label: 'Aprendices del SENA en etapa lectiva',
    code: 'APRENDICES_DEL_SENA_EN_ETAPA_LECTIVA',
  },
  {
    value: 2,
    label: 'Aprendices del SENA en etapa productiva',
    code: 'APRENDICES_DEL_SENA_EN_ETAPA_PRODUCTIVA',
  },
  {
    value: 3,
    label: 'Cooperados o pre cooperativas de trabajo asociado',
    code: 'COOPERADOS_O_PRE_COOPERATIVAS_DE_TRABAJO_ASOCIADO',
  },
  { value: 4, label: 'Dependiente', code: 'DEPENDIENTE' },
  {
    value: 5,
    label:
      'Dependiente entidades o universidades públicas con régimen especial en salud',
    code: 'DEPENDIENTE_ENTIDADES_O_UNIVERSIDADES_PUBLICAS_CON_REGIMEN_ESPECIAL_EN_SALUD',
  },
  {
    value: 6,
    label: 'Estudiantes aportes solo riesgos laborales',
    code: 'ESTUDIANTES_APORTES_SOLO_RIESGOS_LABORALES',
  },
  {
    value: 7,
    label: 'Estudiantes de postgrado en salud',
    code: 'ESTUDIANTES_DE_POSTGRADO_EN_SALUD',
  },
  {
    value: 8,
    label: 'Estudiantes de prácticas laborales en el sector público',
    code: 'ESTUDIANTES_DE_PRACTICAS_LABORALES_EN_EL_SECTOR_PUBLICO',
  },
  {
    value: 9,
    label: 'Funcionarios públicos sin tope máximo de IBC',
    code: 'FUNCIONARIOS_PUBLICOS_SIN_TOPE_MAXIMO_DE_IBC',
  },
  { value: 10, label: 'Madre Comunitaria', code: 'MADRE_COMUNITARIA' },
  {
    value: 11,
    label: 'Pre pensionado con aporte voluntario a salud',
    code: 'PRE_PENSIONADO_CON_APORTE_VOLUNTARIO_A_SALUD',
  },
  {
    value: 12,
    label: 'Pre pensionado de entidad en liquidación',
    code: 'PRE_PENSIONADO_DE_ENTIDAD_EN_LIQUIDACION',
  },
  {
    value: 13,
    label: 'Profesor de establecimiento particular',
    code: 'PROFESOR_DE_ESTABLECIMIENTO_PARTICULAR',
  },
  { value: 14, label: 'Servicio Doméstico', code: 'SERVICIO_DOMESTICO' },
  {
    value: 15,
    label: 'Trabajador de tiempo parcial',
    code: 'TRABAJADOR_DE_TIEMPO_PARCIAL',
  },
  {
    value: 16,
    label:
      'Trabajador dependiente de entidad beneficiaria del sistema general de participaciones ‐ aportes patronales',
    code: 'TRABAJADOR_DEPENDIENTE_DE_ENTIDAD_BENEFICIARIA_DEL_SISTEMA_GENERAL_DE_PARTICIPACIONES_APORTES_PATRONALES',
  },
]

export const contractTypes = [
  { value: 1, label: 'Aprendizaje', code: 'APRENDIZAJE' },
  { value: 2, label: 'Obra o labor', code: 'OBRA_LABOR' },
  { value: 3, label: 'Prácticas o Pasantías', code: 'PRACTICAS_PASANTIAS' },
  { value: 4, label: 'Termino Fijo', code: 'TERMINO_FIJO' },
  { value: 5, label: 'Término indefinido', code: 'TERMINO_INDEFINIDO' },
]

export const epsTypes = [
  { value: 1, label: 'ADRES (ANTES FOSYGA) - MIN001 / CONSORCIO SAYP 2011' },
  {
    value: 2,
    label: 'ADRES (REGÍMENES ESPECIALES) - MIN002 / CONSORCIO SAYP 2011',
  },
  { value: 3, label: 'ALIANSALUD EPS (ANTES COLMEDICA) - EPS001' },
  {
    value: 4,
    label: 'ALIANZA MEDELLIN ANTIOQUIA EPS SAS (SAVIA SALUD) - EPS040',
  },
  { value: 5, label: 'ANAS WAYUU E P S I FOSYGA - EPSIC4' },
  { value: 6, label: 'ASMET SALUD EPS SAS - ESSC62' },
  {
    value: 7,
    label: 'ASOCIACION DE CABILDOS INDIGENAS DEL CESAR - DUSAKAWI - EPSIC1',
  },
  {
    value: 8,
    label:
      'ASOCIACION DE CABILDOS INDIGENAS DEL RESGUARDO INDIGENA ZENU DE SAN ANDRES DE SOTAVENTO CORDOBA - SUCRE (MANEXKA) - EPSIC2',
  },
  { value: 9, label: 'ASOCIACION INDIGENA DEL CAUDA A.I.C - EPSIC3' },
  { value: 10, label: 'CAFESALUD EPS - EPS003' },
  { value: 11, label: 'CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO EPSS' },
  { value: 12, label: 'CAJA DE COMPENSACION FAMILIAR CAFAM EPS - CCFC18' },
  { value: 13, label: 'CAJACOPI EPS S.A.S - CCFC55' },
  { value: 14, label: 'CAPRESOCA EPS - EPSC25' },
  { value: 15, label: 'CMRC RECAUDO FOSYGA-EMSSANAR E.S.S - ESSC18' },
  { value: 16, label: 'EMP MUTUAL PARA EL DESAR EMDISALUD ESS - ESSC02' },
  {
    value: 17,
    label: 'EMPRESAS PÚBLICAS DE MEDELLÍN DEPARTAMENTO MÉDICO - EAS016',
  },
  { value: 18, label: 'ENTIDAD COOPERATIVA SOLIDARIA “ECOOPSOS” - ESSC91' },
  {
    value: 19,
    label: 'ENTIDAD PROMOTORA DE SALUD FAMISANAR LIMITADA - EPS017',
  },
  {
    value: 20,
    label:
      'ENTIDAD PROMOTORA DE SALUD ORGANISMO COOPERATIVO SALUDCOOP - EPS013',
  },
  { value: 21, label: 'ENTIDAD PROMOTORA DE SALUD PIJAOSALUD E - EPSIC6' },
  { value: 22, label: 'ENTIDAD PROMOTORA DE SALUD SANITAS S.A. - EPS005' },
  {
    value: 23,
    label:
      'ENTIDAD PROMOTORA DE SALUD SERVICIO OCCIDENTAL DE SALUD S.A. S.O.S. - EPS018',
  },
  { value: 24, label: 'EPS SURA - EPS010' },
  { value: 25, label: 'EPS-CCFC COMFAMILIAR DE NARIÑO - CCFC27' },
  { value: 26, label: 'EPS-CCFC COMFAMILIAR GUAJIRA - CCFC23' },
  {
    value: 27,
    label:
      'EPS-S AMBUQ ASOCIACION MUTUAL BARRIOS UNIDOS DE QUIBDO E.S.S. AMBUQ - ESSC76',
  },
  { value: 28, label: 'EPS-S COMFACOR - CCFC15' },
  { value: 29, label: 'EPS-S COMFAMILIAR HUILA - CCFC24' },
  { value: 30, label: 'EPS-S COMPARTA - ESSC33' },
  { value: 31, label: 'EPS-S CONVIDA - EPSC22' },
  { value: 32, label: 'EPS-S MUTUAL SER - ESSC07' },
  {
    value: 33,
    label:
      'FONDO DE PASIVO SOCIAL DE FERROCARRILES NACIONALES DE COLOMBIA - EAS027',
  },
  { value: 34, label: 'FUNDACION SALUD MIA EPS - EPS046' },
  { value: 35, label: 'MALLAMAS (CONTRIBUTIVO) - EPSIC5' },
  { value: 36, label: 'MALLAMAS (SUBSIDIADO) - EPSI05' },
  { value: 37, label: 'MEDIMAS EPS SUBSIDIADO - EPS045' },
  { value: 38, label: 'NUEVA EMPRESA PROMOTORA DE SALUD S.A - EPS037' },
  { value: 39, label: 'RECAUDO FOSYGA CAPITAL SALUD - EPSC34' },
  { value: 40, label: 'SALUD TOTAL S.A. ENTIDAD PROMOTORA DE SALUD - EPS002' },
  { value: 41, label: 'SALUDVIDA S.A. ENTIDAD PROMOTORA DE SALUD - EPS033' },
  { value: 42, label: 'UNIDAD DE SERVICIOS DE SALUD UNISALUD (UPTC) - RES014' },
  {
    value: 43,
    label:
      'UNIDAD DE SERVICIOS DE SALUD UNISALUD - UNIVERSIDAD NACIONAL DE COLOMBIA - RES008',
  },
]

export const pensionTypes = [
  { value: 1, label: 'PENSIONES DE ANTIOQUIA' },
  { value: 2, label: 'SKANDIA FONDO ALTERNATIVO DE PENSIONES' },
  { value: 3, label: 'COLFONDOS' },
  {
    value: 4,
    label: 'FONDO DE PREVISIÓN SOCIAL DEL CONGRESO DE LA REPÚBLICA – FONPRECON',
  },
  { value: 5, label: 'PORVENIR' },
  {
    value: 6,
    label:
      'CAJA DE AUXILIOS Y PRESTACIONES DE LA ASOCIACIÓN COLOMBIANA DE AVIADORES CIVILES ACDAC "CAXDAC"',
  },
  {
    value: 7,
    label: 'SKANDIA ADMINISTRADORA DE FONDOS DE PENSIONES Y CESANTIAS S.A',
  },
  { value: 8, label: 'COLPENSIONES' },
  { value: 9, label: 'PROTECCION' },
]

export const layoffTypes = [
  { value: 1, label: 'COLFONDOS' },
  { value: 2, label: 'PROTECCION' },
  { value: 3, label: 'PORVENIR' },
  { value: 4, label: 'SKANDIA' },
  { value: 5, label: 'FONDO NACIONAL DEL AHORRO' },
  { value: 6, label: 'FOMAG' },
]

export const riskTypes = [
  { value: 1, label: 'Riesgo I - 0.522%' },
  { value: 2, label: 'Riesgo II - 1.044%' },
  { value: 3, label: 'Riesgo III - 2.436%' },
  { value: 4, label: 'Riesgo IV - 4.350%' },
  { value: 5, label: 'Riesgo V - 6.960%' },
]

export const compensationTypes = [
  { value: 1, label: 'CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO' },
  { value: 2, label: 'CAJA DE COMPENSACIÓN FAMILIAR CAFAM' },
  {
    value: 3,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR CAJACOPI ATLANTICO',
  },
  { value: 4, label: 'CAJA DE COMPENSACIÓN FAMILIAR CAMACOL' },
  {
    value: 5,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR CAMPESINA COMCAJA',
  },
  {
    value: 6,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR COMFAMILIAR DEL ATLANTICO',
  },
  {
    value: 7,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR COMFENALCO ANTIOQUIA',
  },
  {
    value: 8,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR COMFENALCO DEL VALLE DEL CAUCA',
  },
  {
    value: 9,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR COMFENALCO SANTANDER',
  },
  { value: 10, label: 'CAJA DE COMPENSACIÓN FAMILIAR COMPENSAR' },
  {
    value: 11,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DE ANTIOQUIA COMFAMA',
  },
  { value: 12, label: 'CAJA DE COMPENSACIÓN FAMILIAR DE ARAUCA COMFIAR' },
  {
    value: 13,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DE BARRANCABERMEJA CAFABA',
  },
  {
    value: 14,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DE BARRANQUILLA COMBARRANQUILLA',
  },
  { value: 15, label: 'CAJA DE COMPENSACIÓN FAMILIAR DE BOYACA' },
  { value: 16, label: 'CAJA DE COMPENSACIÓN FAMILIAR DE CALDAS' },
  { value: 17, label: 'CAJA DE COMPENSACIÓN FAMILIAR DE CARTAGENA' },
  {
    value: 18,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DE CORDOBA COMFACOR',
  },
  {
    value: 19,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DE CUNDINAMARCA',
  },
  {
    value: 20,
    label:
      'CAJA DE COMPENSACIÓN FAMILIAR DE FENALCO - ANDI COMFENALCO CARTAGENA',
  },
  {
    value: 21,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DE FENALCO COMFENALCO QUINDIO',
  },
  {
    value: 22,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DE FENALCO DEL TOLIMA',
  },
  { value: 23, label: 'CAJA DE COMPENSACIÓN FAMILIAR DE LA GUAJIRA' },
  { value: 24, label: 'CAJA DE COMPENSACIÓN FAMILIAR DE NARIÑO' },
  { value: 25, label: 'CAJA DE COMPENSACIÓN FAMILIAR DE RISARALDA' },
  {
    value: 26,
    label:
      'CAJA DE COMPENSACIÓN FAMILIAR DE SAN ANDRES Y PROVIDENCIA, ISLAS CAJASAI',
  },
  { value: 27, label: 'CAJA DE COMPENSACIÓN FAMILIAR DE SUCRE' },
  {
    value: 28,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL AMAZONAS CAFAMAZ',
  },
  { value: 29, label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL CAQUETÁ' },
  { value: 30, label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL CASANARE' },
  { value: 31, label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL CAUCA' },
  { value: 32, label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL CHOCÓ' },
  { value: 33, label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL HUILA' },
  { value: 34, label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL MAGDALENA' },
  {
    value: 35,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL NORTE DE SANTANDER COMFANORTE',
  },
  {
    value: 36,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL ORIENTE COLOMBIANO COMFAORIENTE',
  },
  { value: 37, label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL PUTUMAYO' },
  {
    value: 38,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL SUR DEL TOLIMA CAFASUR',
  },
  {
    value: 39,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL TOLIMA COMFATOLIMA',
  },
  {
    value: 40,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR DEL VALLE DEL CAUCA COMFAMILIAR ANDI',
  },
  {
    value: 41,
    label: 'CAJA DE COMPENSACIÓN FAMILIAR REGIONAL DEL META COFREM',
  },
  { value: 42, label: 'CAJA SANTANDEREANA DE SUBSIDIO FAMILIAR CAJASAN' },
  { value: 43, label: 'COMFACESAR' },
]

export const periodicityTypes = [
  { value: 1, label: 'Semanal', code: 'SEMANAL', days: 7 },
  { value: 2, label: 'Decenal', code: 'DECENAL', days: 10 },
  { value: 3, label: 'Catorcenal', code: 'CATORCENAL', days: 14 },
  { value: 4, label: 'Quincenal', code: 'QUINCENAL', days: 15 },
  { value: 5, label: 'Mensual', code: 'MENSUAL', days: 31 },
]

export const areaTypes = [
  { value: 1, label: 'Administrativa' },
  { value: 2, label: 'Comercial' },
  { value: 3, label: 'Contable' },
  { value: 4, label: 'Financiera' },
  { value: 5, label: 'Gerencia' },
  { value: 6, label: 'Operativa' },
  { value: 7, label: 'Recursos Humanos' },
  { value: 8, label: 'Sistemas' },
  { value: 9, label: 'Tecnología' },
]

export const accrualTypes = [
  { value: 1, label: 'Comisión', code: 'COMISION' },
  { value: 2, label: 'Compensación', code: 'COMPENSACION' },
  {
    value: 3,
    label: 'Bonificación por alimentación',
    code: 'BONO_EPCTV_ALIMENTACION',
  },
  { value: 4, label: 'Bonificación', code: 'BONIFICACION' },
  {
    value: 5,
    label: 'Bonificación por retiro',
    code: 'BONIFICACION_RETIRO',
  },
  {
    value: 6,
    label: 'Licencia remunerada',
    code: 'LICENCIA_REMUNERADA',
  },
  {
    value: 7,
    label: 'Licencia de paternidad',
    code: 'LICENCIA_PATERNIDAD',
  },
  { value: 8, label: 'Prima', code: 'PRIMA' },
  {
    value: 9,
    label: 'Vacación compensada',
    code: 'VACACION_COMPENSADA',
  },
  { value: 10, label: 'Huelga legal', code: 'HUELGA_LEGAL' },
  {
    value: 11,
    label: 'Licencia no remunerada',
    code: 'LICENCIA_NO_REMUNERADA',
  },
  {
    value: 12,
    label: 'Hora extra nocturna diurna',
    code: 'HORA_EXTRA_NOCTURNA_DF',
  },
  { value: 13, label: 'Hora extra diurna', code: 'HORA_EXTRA_DIURNA' },
  {
    value: 14,
    label: 'Hora recargo diurna diurna',
    code: 'HORA_RECARGO_DIURNA_DF',
  },
  { value: 15, label: 'Vacación', code: 'VACACION' },
  { value: 16, label: 'Otro concepto', code: 'OTRO_CONCEPTO' },
  { value: 17, label: 'Apoyo práctica', code: 'APOYO_PRACTICA' },
  {
    value: 18,
    label: 'Hora recargo nocturno diurna',
    code: 'HORA_RECARGO_NOCTURNO_DF',
  },
  { value: 19, label: 'Viático', code: 'VIATICO' },
  {
    value: 20,
    label: 'Compensación extraordinaria',
    code: 'COMPENSACION_EXTRAORDINARIA',
  },
  { value: 21, label: 'Reintegro', code: 'REINTEGRO' },
  { value: 22, label: 'Incapacidad', code: 'INCAPACIDAD' },
  { value: 23, label: 'Indemnización', code: 'INDEMNIZACION' },
  { value: 24, label: 'Anticipo', code: 'ANTICIPO' },
  { value: 25, label: 'Bono por EPCTV', code: 'BONO_EPCTV' },
  { value: 26, label: 'Cesantías', code: 'CESANTIAS' },
  {
    value: 27,
    label: 'Intereses sobre cesantías',
    code: 'CESANTIAS_INTERESES',
  },
  {
    value: 28,
    label: 'Hora recargo nocturno',
    code: 'HORA_RECARGO_NOCTURNO',
  },
  {
    value: 29,
    label: 'Hora extra diurna diurna',
    code: 'HORA_EXTRA_DIURNA_DF',
  },
  {
    value: 30,
    label: 'Auxilio de transporte',
    code: 'AUXILIO_DE_TRANSPORTE',
  },
  { value: 31, label: 'Hora extra nocturna', code: 'HORA_EXTRA_NOCTURNA' },
  { value: 32, label: 'Dotación', code: 'DOTACION' },
  { value: 33, label: 'Auxilio', code: 'AUXILIO' },
  { value: 34, label: 'Pago tercero', code: 'PAGO_TERCERO' },
  { value: 35, label: 'Teletrabajo', code: 'TELETRABAJO' },
  { value: 36, label: 'Básico', code: 'BASICO' },
]

export const deductionTypes = [
  { value: 1, label: 'Deuda', code: 'DEUDA' },
  { value: 2, label: 'AFC', code: 'AFC' },
  { value: 3, label: 'Planes complementarios', code: 'PLANES_COMPLEMENTARIOS' },
  { value: 4, label: 'Educación', code: 'EDUCACION' },
  { value: 5, label: 'Fondo de pensiones', code: 'FONDO_PENSION' },
  { value: 6, label: 'Libranza', code: 'LIBRANZA' },
  {
    value: 7,
    label: 'Fondo solidaridad pensional',
    code: 'FONDO_SOLIDARIDAD_PENSIONAL',
  },
  { value: 8, label: 'Cooperativa', code: 'COOPERATIVA' },
  { value: 9, label: 'Salud', code: 'SALUD' },
  { value: 10, label: 'Retención en la fuente', code: 'RETENCION_FUENTE' },
  { value: 11, label: 'Sanción', code: 'SANCION' },
  { value: 12, label: 'Reintegro', code: 'REINTEGRO' },
  { value: 13, label: 'Anticipo', code: 'ANTICIPO' },
  { value: 14, label: 'Pensión voluntaria', code: 'PENSION_VOLUNTARIA' },
  { value: 15, label: 'Embargo fiscal', code: 'EMBARGO_FISCAL' },
  { value: 16, label: 'Sindicato', code: 'SINDICATO' },
  { value: 17, label: 'Pago a tercero', code: 'PAGO_TERCERO' },
  { value: 18, label: 'Otra deducción', code: 'OTRA_DEDUCCION' },
  { value: 19, label: 'Fondo de subsistencia', code: 'FONDO_SUBSISTENCIA' },
  { value: 20, label: 'Sanción privada', code: 'SANCION_PRIVADA' },
]
