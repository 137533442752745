import React, { useState } from 'react'
import companyLogo from '../../resources/images/horizontal-azul.png'
import asesor from '../../resources/images/asesor.png'
import { Button, Group } from '@mantine/core'
import { useNavigate } from 'react-router'
import '../../styles/Landing.css'
import ImageSlider from '../../components/custom/imageSlider'
import { Container } from 'react-bootstrap'
import Footer from '../../components/main/Footer'

export default function Landing() {
  const navigate = useNavigate()

  const beneficts = require.context('../../resources/images/beneficts', true)
  const benefictsImageList = beneficts.keys().map((image) => beneficts(image))
  const benefictTitles = [
    'Venta de combustible',
    'Faturación electrónica',
    'Generación de reportes',
    'Eficiencia en los procesos',
    'Manejo de nómina',
  ]

  const number = '3168772750'
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')

  return (
    <div>
      <header
        style={{
          backgroundColor: '#212121',
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 10,
          padding: '15px',
        }}
      >
        <span
          style={{
            float: 'left',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            src={companyLogo}
            alt="logo"
            style={{ width: '120px' }}
            draggable="false"
          />
          <i
            style={{
              fontSize: '10px',
              color: 'gray',
              maxWidth: '100px',
            }}
          >
            {process.env.REACT_APP_CURRENT_VERSION}
          </i>
        </span>
        <div
          style={{
            float: 'right',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '190px',
            margin: '10px',
          }}
        >
          <Button
            style={{
              color: 'white',
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              padding: '0px',
            }}
            className="mainButton"
            onClick={() => {
              navigate('/register')
            }}
          >
            Regístrate
          </Button>
          <Button
            variant="primary"
            className="mainButton"
            onClick={() => {
              navigate('/login')
            }}
          >
            Ingresar
          </Button>
        </div>
      </header>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '120px',
          backgroundColor: 'transparent',
        }}
      >
        <Group
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
              textAlign: 'center',
              width: '400px',
            }}
            className="main-title"
          >
            <span className="maintitle">
              <h2
                style={{
                  color: '#A1D5F8',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                Gestiona tu estación
              </h2>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '280px',
                }}
              >
                <h2
                  style={{
                    color: '#A1D5F8',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: '7px',
                  }}
                >
                  con
                </h2>

                <h1
                  style={{
                    color: '#4DABF7',
                    fontWeight: '900',
                  }}
                >
                  AVRILAPP
                </h1>
              </span>
            </span>
            <br></br>
            <h4
              style={{
                color: '#7D7D7D',
                fontWeight: 500,
              }}
            >
              Ten el control de tus ventas, inventarios y empleados en un solo
              lugar
            </h4>
            <br></br>
            <Button
              variant="primary"
              style={{
                fontSize: '18px',
                height: '45px',
              }}
              className="mainButton"
              onClick={() => {
                navigate('/register')
              }}
            >
              Regístrate
            </Button>
          </div>
          <ImageSlider />
        </Group>
      </Container>
      <Container>
        <Group
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '100px',
            backgroundColor: '#4DABF7',
            borderRadius: 15,
            padding: '20px',
          }}
        >
          <h2>Con nosotros tendrás</h2>
          <Group
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px 50px',
            }}
          >
            {benefictsImageList.map((image, index) => {
              return (
                <div
                  style={{
                    padding: '10px',
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  key={index}
                  className="beneficts-container"
                >
                  <img
                    src={image}
                    style={{
                      width: '110px',
                    }}
                    draggable="false"
                  />
                  <br></br>
                  <b>
                    <span
                      style={{
                        color: 'white',
                        fontWeight: 'bolder',
                        fontSize: '14px',
                        textShadow: '2px 2px 3px #212121',
                      }}
                    >
                      {benefictTitles[index]}
                    </span>
                  </b>
                </div>
              )
            })}
          </Group>
        </Group>
      </Container>
      <Container>
        <Group
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px',
          }}
        >
          <h2
            style={{
              color: 'black',
            }}
          >
            Comunícate con nosotros
          </h2>
          <Group
            style={{
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#4DABF7',
                padding: '20px 0px',
                borderRadius: 15,
                width: '26rem',
              }}
            >
              <h2>Contáctenos</h2>
              <span
                style={{
                  color: 'white',
                  fontSize: '16px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Podremos resolver sus dudas sobre nuestros servicios
              </span>
              <form>
                <Group
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 30px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <span className="form-label">Nombre</span>
                    <input
                      type="text"
                      style={{
                        padding: '7px',
                        border: 'none',
                        backgroundColor: '#A1D5F8',
                      }}
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <span className="form-label">Correo electrónico</span>
                    <input
                      type="email"
                      style={{
                        padding: '7px',
                        border: 'none',
                        backgroundColor: '#A1D5F8',
                      }}
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <span className="form-label">Mensaje</span>
                    <textarea
                      style={{
                        padding: '7px',
                        border: 'none',
                        backgroundColor: '#A1D5F8',
                        resize: 'none',
                      }}
                      rows="3"
                      required
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <Button
                    variant="primary"
                    style={{
                      height: '40px',
                      fontSize: '20px',
                    }}
                    className="secondaryButton"
                    onClick={() => {
                      window.open(
                        `https://wa.me/57${number}?text=${
                          'Hola, mi nombre y correo son ' +
                          name +
                          ', ' +
                          email +
                          ', ' +
                          message
                        }`,
                      )
                    }}
                  >
                    Enviar
                  </Button>
                  <div
                    style={{
                      textAlign: 'center',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    <span>
                      Al darle enviar, está aceptando nuestra{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://files.avrilapp.com/politica.pdf"
                        style={{
                          color: 'rgb(253, 193, 54)',
                        }}
                      >
                        política de tratamiento de datos personales
                      </a>
                    </span>
                  </div>
                </Group>
              </form>
            </Group>

            <img
              src={asesor}
              alt="logo"
              style={{
                width: '30rem',
              }}
            />
          </Group>
        </Group>
      </Container>
      <Footer />
    </div>
  )
}
