import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getThirdClientsApi = async () => {
  try {
    let rawResult = await fetch(`${apilUrl}/third-clients`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TCLIENTS-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los clientes.')
  }
}

export const getThirdClientsByCompanyApi = async (
  companyId,
  offset = 0,
  limit = 2000,
  thirdClientId = 0,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients/one/${companyId}/${thirdClientId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TCLIENTS-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los clientes.')
  }
}

export const UpdateThirdClientsApi = async (clientId, clientData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/third-clients/${clientId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clientData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TCLIENTS-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el cliente.')
  }
}

export const PostThirdClientDebtApi = async (thirdClientId, debt) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients/debt/${thirdClientId}/${debt}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TCLIENTS-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar deuda del cliente.')
  }
}

export const PostThirdClientBalanceApi = async (thirdClientId, balance) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/third-clients/balance/${thirdClientId}/${balance}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TCLIENTS-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar saldo del cliente.')
  }
}

export const PostThirdClientsApi = async (clientsData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/third-clients`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clientsData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TCLIENTS-6"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear el cliente.')
  }
}

export const PostBulkThirdClientsApi = async (thirdClientsData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/third-clients/bulk-load`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(thirdClientsData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"TCLIENTS-7"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al subir los clientes masivamente.')
  }
}
