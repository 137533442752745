import React, { useEffect } from 'react'
import { useState } from 'react'
import { Group } from '@mantine/core'
import Container from 'react-bootstrap/Container'
import {
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBModal,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit'
import Toggle from 'react-toggle'
import saleIcon from '../../../../resources/images/factura.png'
import Button from 'react-bootstrap/Button'
import SaleModal from './saleModal'
import { notify } from '../../../../services/utils'
import useStore from '../../../zustand'
import { CircularProgress } from '@mui/material'
import {
  UpdateBundledSaleApi,
  getBundledSalesByIdApi,
} from '../../../../pages/api/bundledsale'
import {
  getClientSalesByIdApi,
} from '../../../../pages/api/clientsale'

const EditBundledSalesModal = (props) => {
  const { show, onClose } = props
  const { isDarkModeActive } = useStore()

  const setStateStyles = (saleState) => {
    let color = '#000000'
    switch (saleState) {
      case 1:
        color = 'rgb(33, 150, 243)'
        break
      case 2:
        color = '#66BB6A'
        break
      case 3:
        color = 'gray'
        break
      case 4:
        color = 'rgb(229, 57, 53)'
        break
      case 5:
        color = 'orange'
        break
      default:
        color = 'rgb(33, 150, 243)'
        break
    }

    return {
      borderRadius: '7%',
      backgroundColor: color,
      color: 'white',
      width: '150px',
      padding: '0px 3px',
      margin: '0 10px 0 10px',
      fontSize: '14px',
      textAlign: 'center',
    }
  }

  const setStateText = (saleState) => {
    switch (saleState) {
      case 1:
        return 'CREADA'
      case 2:
        return 'APROBADA'
      case 3:
        return 'ENVIADA DIAN'
      case 4:
        return 'DIAN RECHAZADA'
      case 5:
        return 'AGRUPADA'
      default:
        return 'CREADA'
    }
  }

  //to utils
  const tableStateStyle = (state) => {
    if (isDarkModeActive) return { backgroundColor: '#424242' }
    if (state == 1) return { backgroundColor: 'rgb(212, 234, 251)' } //sin aprobar
    if (state == 2) return { backgroundColor: 'rgb(243, 252, 232)' } //aprobada
    if (state == 3) return { backgroundColor: 'rgb(238, 238, 238)' } //dian
    if (state == 4) return { backgroundColor: 'rgb(255, 235, 238' } //rechazada
    if (state == 5) return { backgroundColor: 'rgb(255, 236, 179)' } //agrupada
  }

  //---------- bundled sales --------------

  const [groupSales, setGroupSales] = useState([])
  const [toDeleteGroupSales, setToDeleteGroupSales] = useState([])
  const [groupSalesIds, setGroupSalesIds] = useState([])
  const [toDeleteGroupSalesIds, setToDeleteGroupSalesIds] = useState([])

  const addToGroupSales = (id, sale) => {
    let group = [...groupSales]
    let ids = [...groupSalesIds]
    group.push({ saleId: sale.id, invoicenumber: sale.invoiceNumber })
    ids.push(id)
    setGroupSales(group)
    setGroupSalesIds(ids)
  }

  const addToDeleteGroupSales = (id, sale) => {
    let group = [...toDeleteGroupSales]
    let ids = [...toDeleteGroupSalesIds]
    group.push({ saleId: sale.id, invoicenumber: sale.invoiceNumber })
    ids.push(id)
    setToDeleteGroupSales(group)
    setToDeleteGroupSalesIds(ids)
  }

  const deleteFromToDeleteGroupSales = (id) => {
    let group = [...toDeleteGroupSales]
    let ids = [...toDeleteGroupSalesIds]
    const index = ids.indexOf(id)
    if (index > -1) {
      group.splice(index, 1)
      ids.splice(index, 1)
    }
    setToDeleteGroupSales(group)
    setToDeleteGroupSalesIds(ids)
  }

  const deleteFromGroupSales = (id) => {
    let group = [...groupSales]
    let ids = [...groupSalesIds]
    const index = ids.indexOf(id)
    if (index > -1) {
      group.splice(index, 1)
      ids.splice(index, 1)
    }
    setGroupSales(group)
    setGroupSalesIds(ids)
  }

  const toggleHandler = (isToggled, sale) => {
    if (isToggled) {
      addToGroupSales(sale.id, sale)
      deleteFromToDeleteGroupSales(sale.id)
    } else {
      addToDeleteGroupSales(sale.id, sale)
      deleteFromGroupSales(sale.id)
    }
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const sendBundledSale = async () => {
    if (groupSalesIds.length > 1) {
      setIsCreateButtonDisabled(true)
      try {
        let bundledSale = {
          newSales: JSON.stringify(groupSales),
          salesToRemove: JSON.stringify(toDeleteGroupSales),
        }
        let result = await UpdateBundledSaleApi(bundledSale, props.saleId)
        if (result.data != null) {
          notify('success', 'Facturas agrupadas con éxito')
          onClose()
        } else {
          notify('error', 'Error al agrupar las facturas')
        }
      } catch (error) {
        return error
      }
    } else {
      notify('warning', 'Seleccione al menos 2 facturas para agrupar.')
    }
    setIsCreateButtonDisabled(false)
  }

  //--------------get sales --------------

  const [sale, setSale] = useState([])
  const [saleCount, setSaleCount] = useState(0)

  const getSales = async () => {
    let sales = await getBundledSalesByIdApi(props.saleId)
    if (sales.data != null) {
      setSale(sales.data)
      let group = []
      let ids = []
      sales.data.map((sale) => {
        group.push({ saleId: sale.id, invoicenumber: sale.invoiceNumber })
        ids.push(sale.id)
      })
      setGroupSales(group)
      setGroupSalesIds(ids)
      setSaleCount(sales.count)
    } else {
      notify('warning', 'No hay ventas asociadas a esta empresa.')
    }
  }

  //--------------------Sale info detallada---------------

  const [saleInfo, setSaleInfo] = useState([])

  const [showSaleModal, setShowSaleModal] = useState(false)
  const handleShowSaleModal = () => setShowSaleModal(true)
  const handleCloseSale = () => setShowSaleModal(false)

  const showSale = async (saleId) => {
    let result = await getClientSalesByIdApi(saleId)
    if (result.data != null) {
      setSaleInfo(result.data)
      handleShowSaleModal()
    } else {
      notify('error', 'Error al obtener la información de la venta.')
    }
  }

  const tokenVerified = async () => {
    await getSales()
  }

  useEffect(() => {
    tokenVerified()
  }, [])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size="lg">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              {props.editable ? `Editando ` : `Mostrando `}
              recibos para el grupo #{props.saleId} de{' '}
              {props.thirdClientCompanyName}
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <Container>
              <Group grow mb="md" mt="md" style={{ display: 'inline' }}>
                <MDBTable small className="salesTable">
                  <MDBTableHead>
                    <tr>
                      <th style={{ textAlign: 'center' }}>Estado</th>
                      <th style={{ textAlign: 'center' }}>Número de factura</th>
                      <th style={{ textAlign: 'center' }}>Hora de venta</th>
                      <th className="saleInfo">Información detallada</th>
                      {props.editable ? (
                        <th style={{ textAlign: 'center' }}>Acciones</th>
                      ) : null}
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                    {sale.map((sale, index) => {
                      return (
                        <tr key={index} style={tableStateStyle(sale.saleState)}>
                          <td>
                            <div style={setStateStyles(sale.saleState)}>
                              {setStateText(sale.saleState)}
                            </div>
                          </td>
                          <td>{sale.invoiceNumber}</td>
                          <td>{sale.createdAt}</td>
                          <td
                            style={{
                              width: '100px',
                              margin: 'auto',
                              textAlign: 'center',
                            }}
                          >
                            <a
                              onClick={() => showSale(sale.id)}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                style={{
                                  float: 'right',
                                  float: 'inline-start',
                                  alignContent: 'center',
                                  cursor: 'pointer',
                                  width: '40px',
                                }}
                                src={saleIcon}
                              />
                            </a>
                          </td>
                          {props.editable ? (
                            <td>
                              {
                                <Toggle
                                  className="custom-classname"
                                  defaultChecked={true}
                                  onChange={(e) => {
                                    toggleHandler(e.currentTarget.checked, sale)
                                  }}
                                />
                              }
                            </td>
                          ) : null}
                        </tr>
                      )
                    })}
                  </MDBTableBody>
                </MDBTable>
              </Group>
              {showSaleModal ? (
                <SaleModal
                  sales={saleInfo}
                  show={showSaleModal}
                  onClose={handleCloseSale}
                />
              ) : null}
            </Container>
          </MDBModalBody>
          <MDBModalFooter>
            {props.editable ? (
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => sendBundledSale(props.thirdClientId)}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
            ) : null}
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cerrar
            </Button>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}

export default EditBundledSalesModal
