import { Text } from '@mantine/core'
import React from 'react'
import Select from 'react-select'

const CustomSelect = ({
  title,
  onChange,
  onKeyDown = null,
  options,
  theme,
  defaultValue = '',
  placeholder = '',
  value = '',
  minWidth = '0',
  maxWidth = '100%',
  disabled = false,
  withBorder = true,
}) => {
  const themeColor = theme
  const alterThemeColor = themeColor === 'white' ? 'gray' : 'white'
  return (
    <div
      style={{
        textAlign: 'left',
      }}
    >
      <Text
        style={{
          fontSize: '14px',
          color: 'gray',
        }}
      >
        {title}
      </Text>
      <Select
        isDisabled={disabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            minWidth: minWidth,
            maxWidth: maxWidth,
            border: withBorder ? 'default' : 'none',
            borderBottom: withBorder
              ? 'default'
              : '1px solid ' + alterThemeColor,
            margin: 0,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            neutral0: themeColor,
          },
        })}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}

export default CustomSelect
