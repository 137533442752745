import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getClientInvoiceByBillingFormatAndDateApi = async (
  billingFormat,
  companyId,
  dateStart,
  dateEnd,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-invoice/${billingFormat}/${companyId}/${dateStart}/${dateEnd}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"INVOICE-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las facturas por formato y fecha.')
  }
}

export const getClientInvoiceByInvoiceNumberApi = async (
  companyId,
  invoiceNumber,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-invoice/by-invoicenumber/${companyId}/${invoiceNumber}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"INVOICE-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener la factura por número de factura.')
  }
}

export const PostClientInvoiceApi = async (saleData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-invoice`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(saleData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"INVOICE-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear la factura.')
  }
}

export const PostClientInvoiceResendApi = async (saleData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-invoice/resend`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(saleData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"INVOICE-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al reenviar la factura.')
  }
}

export const getAllClientInvoiceApi = async (
  companyId,
  branchId,
  resolutionId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-invoice/all/${companyId}/${branchId}/${resolutionId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"INVOICE-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las facturas.')
  }
}

export const DeleteClientInvoiceApi = async (resolutionId, invoice) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-invoice/${invoice.id}/${
        invoice.invoiceNumber
      }/${resolutionId}/${invoice.bundledSaleId != 0 ? 0 : invoice.saleId}/${
        invoice.bundledSaleId != 0 ? invoice.bundledSaleId : 0
      }`,
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"INVOICE-6"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar la factura.')
  }
}

export const getFilteredClientInvoiceByInvoiceApi = async (
  companyId,
  branchId,
  invoiceString,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-invoice/filter/invoice-number/${companyId}/${branchId}/${invoiceString}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"INVOICE-7"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las facturas filtradas.')
  }
}

export const getFilteredClientInvoiceByThirdClientAndDateApi = async (
  companyId,
  branchId,
  thirdClientId,
  startDate,
  endDate,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-invoice/filter/${companyId}/${branchId}/${thirdClientId}/${startDate}/${endDate}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"INVOICE-8"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las facturas filtradas.')
  }
}
