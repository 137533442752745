import React, { useEffect, useState } from 'react'
import { ButtonGroup, ButtonToolbar } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import useStore from '../../zustand'

const BillingMenus = (props) => {
  const { isDarkModeActive } = useStore()
  const [buttonColor, setButtonColor] = useState('black')

  const setColorById = (id) => {
    if (id == props.buttonId) {
      return {
        backgroundColor: 'rgb(13, 71, 161)',
        borderRadius: '0%',
      }
    } else {
      return {
        backgroundColor: 'transparent',
        color: buttonColor,
        borderRadius: '0%',
      }
    }
  }

  useEffect(() => {
    setButtonColor(isDarkModeActive ? 'white' : 'black')
  }, [isDarkModeActive])

  const buttonsLinks = ['/invoice', '/creditnote', '/debitnote']

  const buttonsNames = ['Facturación', 'Nota crédito', 'Nota débito']

  return (
    <ButtonToolbar aria-label="Toolbar with button groups">
      <ButtonGroup className="me-2" aria-label="First group">
        {buttonsNames.map((button, index) => {
          return (
            <Button
              key={index}
              as={Link}
              to={buttonsLinks[index]}
              style={setColorById(index)}
            >
              {button}
            </Button>
          )
        })}
      </ButtonGroup>
    </ButtonToolbar>
  )
}

export default BillingMenus
