import { apilUrl } from '../../services/url'
import { notify } from '../../services/utils'
import { apiErrorMessageHandler } from '../../services/utils'

export const getReasonsApi = async () => {
  try {
    let rawResult = await fetch(`${apilUrl}/reason`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error) {
      apiErrorMessageHandler(
        '"REASON-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    }
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las razones.')
  }
}
