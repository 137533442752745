import { Group } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css'
import useStore from '../../../components/zustand'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { CircleMinus, CirclePlus } from 'tabler-icons-react'

import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material'
import { Select as MuiSelect } from '@mui/material'
import { MDBTable, MDBTableBody } from 'mdb-react-ui-kit'
import { dayjsDateToLocale, notify, setDecimal } from '../../../services/utils'
import { Container } from 'react-bootstrap'
import { getClientSalesByIdApi } from '../../../pages/api/clientsale'
import { PostClientInvoiceResendApi } from '../../../pages/api/clientinvoice'

export default function ResendDian(props) {
  const { tokenData } = useStore()

  const { setModalResendShow, successCreation, bill, clients } = props

  const form = useForm({
    initialValues: {
      orderReference: '',
      payment_means: '',
      payment_means_type: '',
    },
  })

  const getPaymentText = (pay) => {
    let wayToPay = ''
    switch (pay) {
      case 1:
        wayToPay = 'CASH'
        break
      case 2:
        wayToPay = 'DEBIT_CARD'
        break
      case 3:
        wayToPay = 'CREDIT_CARD'
        break
      case 4:
        wayToPay = 'BANK_TRANSFER'
        break
      case 5:
        wayToPay = 'MUTUAL_AGREEMENT'
        break
      default:
        break
    }
    return wayToPay
  }

  //----------------Bundled invoices -------------------+

  const [bundledSale, setBundledSale] = useState(0)

  //----------------------Bills methods---------------------------

  const [isBundled, setIsBundled] = useState(false)

  const setDateShortcut = (days) => {
    if (days > 0 && days < 1000) {
      let result = new Date(startDate)
      result.setDate(result.getDate() + days)
      setEndDate(dayjs(result))
    }
  }

  const [startDate, setStartDate] = React.useState(dayjs(new Date()))
  const [endDate, setEndDate] = React.useState(dayjs(new Date()))

  const getProductsTotalPrice = (products) => {
    let total = 0
    products.map((product) => {
      let iva =
        product.taxes == undefined ? 0 : parseInt(product.taxes[0].tax_rate)
      let rf =
        product.retentions == undefined
          ? 0
          : parseFloat(product.retentions[0].tax_rate)

      total += (product.total * (100 + (iva - rf))) / 100
    })
    return total
  }

  const getProductsSubtotalPrice = (products) => {
    let subtotal = 0
    products.map((product) => {
      let discount =
        product.discount_rate != undefined ? product.discount_rate : 0
      subtotal += product.price * (1 - discount / 100) * product.quantity
    })

    return subtotal
  }

  const setTotal = (clientbillproducts, total, index) => {
    clientbillproducts[index].total = total
    return clientbillproducts
  }

  const getTotalPerProduct = (clientProducts) => {
    let discount =
      clientProducts.discount_rate != undefined
        ? clientProducts.discount_rate
        : 0
    return (
      (clientProducts.price *
        clientProducts.quantity *
        (100 - parseInt(discount))) /
      100
    )
  }

  //--------------------------------Reenviar a dian ---------------------------------
  const [isResendButtonDisabled, setIsAnnulationButtonDisabled] =
    useState(false)
  const [toResendBillProducts, setToResendBillProducts] = useState([])
  const [toResendBill, setResendBill] = React.useState({
    invoiceId: 0,
    items: toResendBillProducts,
    uuid: 0,
  })

  const [toResendAdditionalPayments, setToResendAdditionalPayments] = useState({
    5: { name: 'IVA(5%)', total: 0 },
    16: { name: 'IVA(16%)', total: 0 },
    19: { name: 'IVA(19%)', total: 0 },
    discount: { name: 'Descuento', total: 0 },
    rf: { name: 'Ret. FUENTE', total: 0 },
  })

  const handleResendDianButton = async (bill) => {
    let result = await getClientSalesByIdApi(bill.saleId)
    if (result.data != null) {
      let thirdClient = clients.filter(
        (client) => client.id == bill.thirdClientId,
      )
      setInvoiceSale(result.data[0])
      setInvoiceThirdClient(thirdClient)
    }
    let products = JSON.parse(bill.items)
    let ap = {
      5: { name: 'IVA(5%)', total: 0 },
      16: { name: 'IVA(16%)', total: 0 },
      19: { name: 'IVA(19%)', total: 0 },
      discount: { name: 'Descuento', total: 0 },
      rf: { name: 'Ret. FUENTE', total: 0 },
    }
    products.map((product) => {
      product.total = getTotalPerProduct(product)
      if (product.taxes != undefined)
        ap[parseInt(product.taxes[0].tax_rate)].total +=
          (product.total * parseInt(product.taxes[0].tax_rate)) / 100
      if (product.retentions != undefined) {
        ap.rf.total +=
          (product.total * parseFloat(product.retentions[0].tax_rate)) / 100
      }
      if (product.discount_rate != undefined) {
        ap.discount.total +=
          (product.price * parseInt(product.discount_rate)) / 100
      }
    })
    setToResendAdditionalPayments(ap)
    setResendBill(bill)
    setToResendBillProducts(products)
    setResendNotes(JSON.parse(bill.notes))
    setIsBundled(bill.bundledSaleId != 0)
    setBundledSale(bill.bundledSaleId)
  }

  const [invoiceThirdClient, setInvoiceThirdClient] = useState([])
  const [invoiceSale, setInvoiceSale] = useState(null)

  const sendBillToDian = async () => {
    try {
      setIsAnnulationButtonDisabled(true)
      let data = {
        branchId: toResendBill.branchId,
        client: toResendBill.client,
        clientUserId: toResendBill.clientUserId,
        companyId: toResendBill.companyId,
        resolutionId: toResendBill.resolutionId,
        createdAt: toResendBill.createdAt,
        creditNoteDianStatus: toResendBill.creditNoteDianStatus,
        debitNoteDianStatus: toResendBill.debitNoteDianStatus,
        deleted: false,
        id: toResendBill.id,
        invoiceDianStatus: toResendBill.invoiceDianStatus,
        invoiceNumber: toResendBill.invoiceNumber,
        thirdClientName: toResendBill.client,
        issueDate: dayjsDateToLocale(startDate),
        paymentDate: dayjsDateToLocale(endDate),
        orderReference: form.values.orderReference,
        payment_means_type:
          invoiceSale.paymentMethod == 1 ? 'DEBITO' : 'CREDITO',
        payment_means: getPaymentText(invoiceSale.wayToPay),
        thirdClient: invoiceThirdClient,
        items: toResendBillProducts,
        notes: ResendNotes,
        pdfUrls: toResendBill.pdfUrls,
        resolutionNumber: toResendBill.resolutionNumber,
        saleId: toResendBill.saleId,
        updatedAt: toResendBill.updatedAt,
        thirdClientId: toResendBill.thirdClientId,
        bundledSaleId: isBundled ? bundledSale : 0,
        total: setDecimal(getProductsTotalPrice(toResendBillProducts)),
        clientDocumentId: tokenData.userId,
      }
      let result = await PostClientInvoiceResendApi(data)
      if (result.data != null) successCreation(result)
      else notify('error', 'Error al reenviar la factura.')
    } catch (error) {
      return error
    }
  }

  const changeToResendBillProductsPayment = (payment, value, index) => {
    let products = [...toResendBillProducts]
    if (payment == 'discount')
      if (value != 0) products[index].discount_rate = parseInt(value)
      else delete products[index].discount_rate
    if (payment == 'iva')
      if (value != 0)
        products[index].taxes = [
          {
            tax_category: 'IVA',
            tax_rate: parseInt(value),
          },
        ]
      else delete products[index].taxes
    if (payment == 'rf')
      if (value != 0)
        products[index].retentions = [
          {
            tax_category: 'RET_FUENTE',
            tax_rate: parseFloat(value),
          },
        ]
      else delete products[index].retentions

    let total = getTotalPerProduct(products[index])
    products = setTotal(products, total, index)
    setToResendBillProducts(products)
    calculateAdditionalPayments(products)
  }

  const calculateAdditionalPayments = (bills) => {
    let iva5 = 0
    let iva16 = 0
    let iva19 = 0
    let rf = 0
    let discount = 0
    bills.map((product) => {
      if (product.taxes != undefined) {
        if (parseInt(product.taxes[0].tax_rate) == 5) {
          iva5 += (product.total * parseInt(product.taxes[0].tax_rate)) / 100
        }
        if (parseInt(product.taxes[0].tax_rate) == 16) {
          iva16 += (product.total * parseInt(product.taxes[0].tax_rate)) / 100
        }
        if (parseInt(product.taxes[0].tax_rate) == 19) {
          iva19 += (product.total * parseInt(product.taxes[0].tax_rate)) / 100
        }
      }
      if (product.retentions != undefined) {
        rf += parseFloat(product.total * product.retentions[0].tax_rate) / 100
      }
      if (product.discount_rate != undefined)
        discount +=
          parseFloat(
            product.price * product.quantity * parseInt(product.discount_rate),
          ) / 100
    })
    let ap = {
      5: { name: 'IVA(5%)', total: iva5 },
      16: { name: 'IVA(16%)', total: iva16 },
      19: { name: 'IVA(19%)', total: iva19 },
      discount: { name: 'Descuento', total: discount },
      rf: { name: 'Ret. FUENTE', total: rf },
    }
    setToResendAdditionalPayments(ap)
  }

  //--------------------Notes---------------------------
  const [ResendNotes, setResendNotes] = useState([])

  const handleNewResendNote = () => {
    let notes = [...ResendNotes]
    let note = ''
    notes.push(note)
    setResendNotes(notes)
  }

  const handleDeleteResendNote = (index) => {
    let notes = [...ResendNotes]
    notes.splice(index, 1)
    setResendNotes(notes)
  }

  const handleEditResendNote = (note, index) => {
    let notes = [...ResendNotes]
    notes[index] = note
    setResendNotes(notes)
  }

  //----------------------------------
  useEffect(() => {
    handleResendDianButton(bill)
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#424242' : 'white')
  }, [isDarkModeActive])

  return (
    <Container
      style={{
        border: '1px solid gray',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <h4>Reenviando factura a la DIAN</h4>
      <hr></hr>
      <form>
        <Group direction="column" grow>
          <Group>
            <Group>
              <Group>
                {toResendBillProducts.length > 0 ? (
                  <>
                    <h6>{`Factura #${toResendBill.invoiceNumber} - (${toResendBill.createdAt})`}</h6>
                    <table
                      style={{
                        borderSpacing: '10px',
                        borderCollapse: 'separate',
                      }}
                    >
                      <thead>
                        <tr>
                          <th>NOMBRE</th>
                          <th>CANTIDAD</th>
                          <th>DESCUENTO</th>
                          <th>RET. FUENTE</th>
                          <th>IVA</th>
                          <th>SUBTOTAL</th>
                          <th>TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {toResendBillProducts.map((clientProducts, index) => {
                          return (
                            <tr key={index}>
                              <td>{clientProducts.description}</td>
                              <td>
                                <div>
                                  <span
                                    style={{
                                      fontSize: 16,
                                      marginTop: '40px',
                                    }}
                                  >
                                    {' '}
                                    {clientProducts.quantity}{' '}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <TextField
                                  variant="standard"
                                  onChange={(event) => {
                                    if (
                                      event.currentTarget.value >= 0 &&
                                      event.currentTarget.value <= 100
                                    ) {
                                      changeToResendBillProductsPayment(
                                        'discount',
                                        event.currentTarget.value,
                                        index,
                                      )
                                    }
                                  }}
                                  onBlur={(event) => {
                                    if (!(event.currentTarget.value != ''))
                                      changeToResendBillProductsPayment(
                                        'discount',
                                        0,
                                        index,
                                      )
                                  }}
                                  type="number"
                                  value={
                                    clientProducts.discount_rate != undefined
                                      ? clientProducts.discount_rate
                                      : 0
                                  }
                                  style={{
                                    width: '45px',
                                    display: 'inline-block',
                                  }}
                                />
                                %
                              </td>
                              <td>
                                <FormControl variant="standard" sx={{ m: 1 }}>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    Ret. Fuente
                                  </InputLabel>
                                  <MuiSelect
                                    label="Ret. fuente"
                                    value={parseFloat(
                                      clientProducts.retentions != undefined
                                        ? clientProducts.retentions[0].tax_rate
                                        : 0,
                                    )}
                                    onChange={(e) =>
                                      changeToResendBillProductsPayment(
                                        'rf',
                                        e.target.value,
                                        index,
                                      )
                                    }
                                  >
                                    <MenuItem value={0}>0%</MenuItem>
                                    <MenuItem value={0.1}>0.1%</MenuItem>
                                    <MenuItem value={0.5}>0.5%</MenuItem>
                                    <MenuItem value={1}>1%</MenuItem>
                                    <MenuItem value={1.5}>1.5%</MenuItem>
                                    <MenuItem value={2}>2%</MenuItem>
                                    <MenuItem value={2.5}>2.5%</MenuItem>
                                    <MenuItem value={3}>3%</MenuItem>
                                    <MenuItem value={3.5}>3.5%</MenuItem>
                                    <MenuItem value={4}>4%</MenuItem>
                                    <MenuItem value={6}>6%</MenuItem>
                                    <MenuItem value={7}>7%</MenuItem>
                                    <MenuItem value={10}>10%</MenuItem>
                                    <MenuItem value={11}>11%</MenuItem>
                                    <MenuItem value={20}>20%</MenuItem>
                                  </MuiSelect>
                                </FormControl>
                              </td>
                              <td>
                                <FormControl variant="standard" sx={{ m: 1 }}>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    IVA
                                  </InputLabel>
                                  <MuiSelect
                                    label="IVA"
                                    value={parseInt(
                                      clientProducts.taxes != undefined
                                        ? clientProducts.taxes[0].tax_rate
                                        : 0,
                                    )}
                                    onChange={(e) =>
                                      changeToResendBillProductsPayment(
                                        'iva',
                                        e.target.value,
                                        index,
                                      )
                                    }
                                  >
                                    <MenuItem value={0}>IVA 0%</MenuItem>
                                    <MenuItem value={5}>IVA 5%</MenuItem>
                                    <MenuItem value={16}>IVA 16%</MenuItem>
                                    <MenuItem value={19}>IVA 19%</MenuItem>
                                  </MuiSelect>
                                </FormControl>
                              </td>
                              <td>
                                $
                                {setDecimal(
                                  clientProducts.price *
                                    clientProducts.quantity *
                                    (1 -
                                      (clientProducts.discount_rate != undefined
                                        ? clientProducts.discount_rate
                                        : 0) /
                                        100),
                                )}
                              </td>
                              <td>
                                $
                                {setDecimal(
                                  (parseFloat(
                                    (clientProducts.price *
                                      clientProducts.quantity *
                                      (100 -
                                        parseInt(
                                          clientProducts.discount_rate !=
                                            undefined
                                            ? clientProducts.discount_rate
                                            : 0,
                                        ))) /
                                      100,
                                  ) *
                                    (100 +
                                      ((clientProducts.taxes != undefined
                                        ? clientProducts.taxes[0].tax_rate
                                        : 0) -
                                        (clientProducts.retentions != undefined
                                          ? clientProducts.retentions[0]
                                              .tax_rate
                                          : 0)))) /
                                    100,
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <MDBTable>
                      <MDBTableBody
                        style={{ color: color, isolation: 'isolate' }}
                      >
                        <tr>
                          <td>Subtotal:</td>
                          <td>
                            $
                            {setDecimal(
                              parseFloat(
                                getProductsSubtotalPrice(toResendBillProducts),
                              ),
                            )}
                          </td>
                        </tr>

                        {Object.keys(toResendAdditionalPayments).map(
                          (key, index) => {
                            if (toResendAdditionalPayments[key].total > 0) {
                              return (
                                <tr key={index}>
                                  <td>
                                    {toResendAdditionalPayments[key].name}
                                  </td>
                                  <td>
                                    + $
                                    {setDecimal(
                                      toResendAdditionalPayments[key].total,
                                    )}
                                  </td>
                                </tr>
                              )
                            }
                          },
                        )}
                        <tr>
                          <td>
                            <b>Total:</b>
                          </td>
                          <td>
                            <b>
                              $
                              {setDecimal(
                                getProductsTotalPrice(
                                  toResendBillProducts,
                                  false,
                                ),
                              )}
                            </b>
                          </td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </>
                ) : null}
              </Group>
            </Group>
            <Group mb="md" mt="md">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  MÉTODO DE VENTA
                </InputLabel>
                <MuiSelect
                  required
                  label="MÉTODO DE VENTA"
                  value={invoiceSale != null ? invoiceSale.paymentMethod : 0}
                  style={{
                    appearance: 'none',
                    border: '0px',
                    background: 'none',
                    width: '180px',
                  }}
                  disabled={true}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={2}>CRÉDITO</MenuItem>

                  <MenuItem value={1}>CONTADO</MenuItem>
                </MuiSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  MEDIO DE PAGO
                </InputLabel>
                <MuiSelect
                  required
                  label="FORMA DE PAGO"
                  value={invoiceSale != null ? invoiceSale.wayToPay : 0}
                  style={{
                    appearance: 'none',
                    border: '0px',
                    background: 'none',
                    width: '220px',
                  }}
                  disabled={true}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={5}>CRÉDITO</MenuItem>
                  <MenuItem value={1}>EFECTIVO</MenuItem>
                  <MenuItem value={2}>TARJETA DE DÉBITO</MenuItem>
                  <MenuItem value={3}>TARJETA DE CRÉDITO</MenuItem>
                  <MenuItem value={4}>TRANSFER. BANCARIA</MenuItem>
                </MuiSelect>
              </FormControl>
              <TextField
                variant="standard"
                label="CÓDIGO TRANSFERENCIA"
                value={form.values.orderReference}
                style={{
                  appearance: 'none',
                  border: 'none',
                  background: 'none',
                }}
                onChange={(event) =>
                  form.setFieldValue('orderReference', event.target.value)
                }
              />
            </Group>
            <Group grow mb="md" mt="md">
              <div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ES"
                >
                  <DatePicker
                    label="FECHA EMISIÓN"
                    format="DD/MM/YYYY"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                  <DatePicker
                    label="FECHA VENCIMIENTO"
                    format="DD/MM/YYYY"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
                </LocalizationProvider>
                <TextField
                  variant="standard"
                  type="number"
                  label="Días"
                  style={{
                    width: '45px',
                    marginLeft: '10px',
                  }}
                  onChange={(event) =>
                    setDateShortcut(parseInt(event.target.value))
                  }
                />
                {/* <b style={{ fontSize: '13px' }}>Plazo{'    '}</b>
                <a
                  onClick={() => setDateShortcut(30)}
                  style={{
                    textDecoration: 'none',
                    margin: '0 3px 5px 0',
                    color: '#007EFF',
                    cursor: 'pointer',
                  }}
                >
                  30
                </a>
                <a
                  onClick={() => setDateShortcut(60)}
                  style={{
                    textDecoration: 'none',
                    margin: '0 3px 5px 0',
                    color: '#007EFF',
                    cursor: 'pointer',
                  }}
                >
                  60
                </a>
                <a
                  onClick={() => setDateShortcut(90)}
                  style={{
                    textDecoration: 'none',
                    margin: '0 3px 5px 0',
                    color: '#007EFF',
                    cursor: 'pointer',
                  }}
                >
                  90
                </a> */}
              </div>
            </Group>
            <Group mb="md" mt="md">
              {ResendNotes.length > 0 ? (
                <>
                  <u>Notas:</u>
                  <MDBTable>
                    <MDBTableBody
                      style={{ color: color, isolation: 'isolate' }}
                    >
                      {ResendNotes.map((note, index) => (
                        <tr key={index}>
                          <td>#{index + 1}:</td>
                          <td>
                            <TextField
                              variant="standard"
                              value={note}
                              onChange={(e) =>
                                handleEditResendNote(e.target.value, index)
                              }
                            ></TextField>
                          </td>
                          <td>
                            <Button
                              style={{
                                border: 'none',
                                borderRadius: 50,
                                background: '#f45b69',
                                isolation: 'isolate',
                              }}
                              onClick={() => handleDeleteResendNote(index)}
                            >
                              Quitar <CircleMinus />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                </>
              ) : null}
              <Button
                style={{
                  border: 'none',
                  borderRadius: 50,
                  background: 'green',
                  isolation: 'isolate',
                }}
                onClick={() => handleNewResendNote()}
              >
                Añadir nota <CirclePlus size={30} color="#FFFFFF" />
              </Button>
            </Group>
          </Group>
        </Group>
      </form>
      <hr></hr>
      <Button
        style={{
          backgroundColor: 'green',
          borderRadius: '100px',
          isolation: 'isolate',
        }}
        onClick={() => sendBillToDian()}
        disabled={isResendButtonDisabled}
      >
        {isResendButtonDisabled ? (
          <CircularProgress size={15} color="inherit" />
        ) : null}{' '}
        Reenviar
      </Button>
      <Button
        style={{
          backgroundColor: 'transparent',
          borderRadius: '100px',
          color: 'black',
        }}
        onClick={() => setModalResendShow(false)}
      >
        Cerrar
      </Button>
    </Container>
  )
}
