import React, { useState, useEffect } from 'react'
import useStore from '../../../components/zustand'
import { Menu } from '@mui/material'
import Button from 'react-bootstrap/Button'
import {
  Pagination,
  MenuItem,
  Select as SelectPages,
  InputLabel,
  FormControl,
  Tooltip,
} from '@mui/material'
import {
  getDataByStartAndEndFromArray,
  notify,
  setDecimal,
} from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import { Container } from 'react-bootstrap'
import {
  ArrowLeft,
  DotsVertical,
  Edit,
  File,
  FileDollar,
  MailForward,
  Trash,
  Users,
} from 'tabler-icons-react'
import pdf from '../../../resources/images/pdf.png'
import { GetPayrollEntriesDocumentsApi } from '../../../pages/api/payroll'
import ActionButton from '../../custom/actionButton'
import { getClientUsersApi } from '../../../pages/api/clientUsers'

const SettlementHistorical = ({
  currentPeriod,
  payrollsByPeriod,
  showPdfViewer,
  onClose,
}) => {
  const { isDarkModeActive } = useStore()
  const getTotalFromCategory = (category) => {
    let total = 0
    category.forEach((accrual) => {
      total += accrual.amount
    })
    return total
  }

  const getTotalSalary = (accruals, deductions) => {
    let total = 0
    accruals.forEach((accrual) => {
      total += accrual.amount
    })
    deductions.forEach((deduction) => {
      total -= deduction.amount
    })
    return total
  }

  const [clientUsers, setClientUsers] = useState([])

  const getClientUsers = async (companyId, branchId) => {
    let result = await getClientUsersApi(companyId, branchId)
    if (result.data != null) {
      setClientUsers(result.data)
    } else notify('warning', 'Error al obtener los usuarios.')
  }

  const getClientUserById = (id) => {
    let user = clientUsers.find((user) => user.id === id)
    return user ? user : {}
  }

  useEffect(() => {
    getClientUsers(currentPeriod.companyId, currentPeriod.branchId)
  }, [currentPeriod])

  const handlePdfViewer = async (
    prefix,
    number,
    resolutionId,
    clientUserId,
  ) => {
    let result = await GetPayrollEntriesDocumentsApi(
      prefix,
      number,
      resolutionId,
    )
    if (result.data != null) {
      showPdfViewer(
        `PDF De nómina de ${getClientUserById(clientUserId).fullName}`,
        result.data.pdf,
        'base64',
      )
    } else {
      notify('error', 'Error al obtener el documento.')
    }
  }

  //-------------------dots menu-------------------
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(5)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    setPayrollsToDisplay(
      getDataByStartAndEndFromArray(
        payrollsByPeriod,
        newOffset,
        newOffset + rpg,
      ),
    )
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    setPayrollsToDisplay(
      getDataByStartAndEndFromArray(
        payrollsByPeriod,
        0,
        parseInt(event.target.value),
      ),
    )
  }

  const [payrollsToDisplay, setPayrollsToDisplay] = useState(
    getDataByStartAndEndFromArray(payrollsByPeriod, 0, rpg),
  )

  //-----------tabs-------------------
  const [currentTab, setCurrentTab] = useState('tab1')

  const handleTabs = (value) => {
    if (value === currentTab) {
      return
    }
    setCurrentTab(value)
  }

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? 'black' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      {onClose && (
        <div
          style={{
            margin: '10px',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            width: 'fit-content',
          }}
          onClick={onClose}
        >
          <ArrowLeft />
          Atrás
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          border: '1px solid gray',
          borderRadius: '10px',
          padding: '20px',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <h4
            style={{
              float: 'left',
              fontSize: '19px',
            }}
          >
            Historial del periodo <b>{currentPeriod.name}</b> del año{' '}
            <b>{currentPeriod.year}</b>
          </h4>
        </div>
        <MDBTabsPane show={currentTab === 'tab1'}>
          <MDBTable small>
            <MDBTableHead>
              <tr style={{ textAlign: 'center' }}>
                <th>Periodo</th>
                <th>Devengados</th>
                <th>Deducciones</th>
                <th>Total</th>
                <th className="userActions">Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {payrollsToDisplay.map((entry, index) => (
                <tr
                  key={index}
                  style={{ textAlign: 'center', verticalAlign: 'middle' }}
                >
                  <td>
                    <div>
                      <span>{entry.prefix + entry.number}</span>
                      <div>
                        <span>
                          {getClientUserById(entry.clientUserId).fullName}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Tooltip
                      title={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          {JSON.parse(entry.accruals).map((accrual, index) => (
                            <span key={index}>{`${accrual.code} - $${setDecimal(
                              accrual.amount,
                              2,
                            )}`}</span>
                          ))}
                        </div>
                      }
                    >
                      <span>
                        ${' '}
                        {setDecimal(
                          getTotalFromCategory(JSON.parse(entry.accruals)),
                          2,
                        )}
                      </span>
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip
                      title={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          {JSON.parse(entry.deductions).map(
                            (deduction, index) => (
                              <span key={index}>{`${
                                deduction.code
                              } - $${setDecimal(deduction.amount, 2)}`}</span>
                            ),
                          )}
                        </div>
                      }
                    >
                      <span>
                        ${' '}
                        {setDecimal(
                          getTotalFromCategory(JSON.parse(entry.deductions), 2),
                        )}
                      </span>
                    </Tooltip>
                  </td>
                  <td>
                    ${' '}
                    {setDecimal(
                      getTotalSalary(
                        JSON.parse(entry.accruals),
                        JSON.parse(entry.deductions),
                      ),
                      2,
                    )}
                  </td>
                  <td
                    style={{
                      width: '300px',
                      margin: 'auto',
                      alignItems: 'center',
                    }}
                  >
                    <ActionButton
                      description="Enviar por correo"
                      style={{
                        backgroundColor: bgColor,
                        margin: '5px',
                        padding: '5px',
                        height: 'fit-content',
                      }}
                      onClick={() => {}}
                    >
                      <MailForward size={20} color="black" />
                    </ActionButton>
                    <ActionButton
                      description="Ver PDF"
                      style={{
                        backgroundColor: bgColor,
                        margin: '5px',
                        padding: '5px',
                        height: 'fit-content',
                      }}
                      onClick={() => {
                        handlePdfViewer(
                          entry.prefix,
                          entry.number,
                          entry.resolutionId,
                          entry.clientUserId,
                        )
                      }}
                    >
                      <img
                        alt="pdf"
                        style={{
                          alignContent: 'center',
                          cursor: 'pointer',
                          width: '20px',
                        }}
                        src={pdf}
                      />
                    </ActionButton>
                    <ActionButton
                      description="Ver XML"
                      style={{
                        backgroundColor: bgColor,
                        margin: '5px',
                        padding: '5px',
                        height: 'fit-content',
                      }}
                      onClick={() => {}}
                    >
                      <File size={20} color="blue" />
                    </ActionButton>
                    <Button
                      style={{
                        boxShadow: '0px 0px 0px 0px',
                        padding: '9px 0px',
                        height: 'fit-content',
                      }}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e) => handleClick(e)}
                      variant="secundary"
                    >
                      <DotsVertical color={color}></DotsVertical>
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => {
                        handleClose()
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <Edit size={20} /> Editar
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: 'red',
                        }}
                        onClick={handleClose}
                      >
                        <Trash size={20} color="red" /> Eliminar
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
          {payrollsByPeriod.length > 5 && (
            <Container>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Filas
                </InputLabel>
                <SelectPages
                  labelId="demo-simple-select-standard-label"
                  autoWidth={true}
                  value={rpg}
                  onChange={handleChangeRowsPerPage}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </SelectPages>
              </FormControl>
              <Pagination
                count={Math.ceil(payrollsByPeriod.length / rpg)}
                page={pg}
                onChange={handleChangePage}
                showFirstButton={true}
                showLastButton={true}
                style={{ display: 'inline-block' }}
              />
            </Container>
          )}
        </MDBTabsPane>
      </div>
    </div>
  )
}

export default SettlementHistorical
