import React from 'react'
import '../../styles/imageSlider.css'
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from 'react-icons/bs'

const images = require.context('../../resources/images/slider', true)
const imageList = images.keys().map((image) => images(image))

export default function ImageSlider() {
  const [slide, setSlide] = React.useState(0)

  const nextSlide = () => {
    if (slide === imageList.length - 1) {
      setSlide(0)
    } else {
      setSlide(slide + 1)
    }
  }

  const prevSlide = () => {
    if (slide === 0) {
      setSlide(imageList.length - 1)
    } else {
      setSlide(slide - 1)
    }
  }

  return (
    <div className="carousel">
      {imageList.map((image, index) => {
        return (
          <div
            key={index}
            className={slide === index ? 'slide' : 'slide slide-hidden'}
          >
            <img
              src={image}
              alt={`slide ${index + 1}`}
              style={{
                width: '100%',
                borderRadius: '0.5rem',
              }}
              draggable="false"
            />
          </div>
        )
      })}
      <BsArrowLeftCircleFill
        size={30}
        onClick={prevSlide}
        className="arrow arrow-left"
      />
      <BsArrowRightCircleFill
        size={30}
        onClick={nextSlide}
        className="arrow arrow-right"
      />
      <div className="description">
        <span className="description-title">
          Lectura de combustible en tiempo real
        </span>
        <span className="description-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in rlaborum.
        </span>
      </div>
      <span className="indicators">
        {imageList.map((_, idx) => {
          return (
            <button
              key={idx}
              onClick={() => setSlide(idx)}
              className={
                slide === idx ? 'indicator' : 'indicator indicator-inactive'
              }
            />
          )
        })}
      </span>
    </div>
  )
}
