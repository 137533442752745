import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Menu from '../../components/client/menu'

import { Group, Paper, Text } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { CircularProgress, TextField } from '@mui/material'
import {
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { CircularProgressbar } from 'react-circular-progressbar'
import { useNavigate } from 'react-router-dom'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { ChargingPile, CirclePlus, Edit } from 'tabler-icons-react'
import LoadingScreen from '../../components/main/loadingScreen'
import useStore from '../../components/zustand'

//tokenData Verification
import StationMenus from '../../components/client/shortcutMenus/stationMenus'
import { isEmpty } from '../../services/utils'
import {
  PostClientTankApi,
  UpdateClientTankApi,
  getClientTanksApi,
} from '../api/clientTanks'

export default function Tanks() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  //Clients Tanks
  const [tanks, setTanks] = useState([])

  //CREAT MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  const form = useForm({
    initialValues: {
      tankName: '',
      tankSize: '',
      gallons: '',
    },
  })

  const successCreation = async () => {
    notify('success', 'Creación Exitoso')
    form.setFieldValue('tankName', '')
    form.setFieldValue('tankSize', '')
    form.setFieldValue('gallons', '')
    await getClientTanks(tokenData.companyId, tokenData.branchId)
    setModalCreateShow(false)
    setIsCreateButtonDisabled(false)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createTank = async () => {
    if (parseInt(form.values.gallons) > parseInt(form.values.tankSize)) {
      notify('warning', 'Los galones superan el tamaño del tanque.')
    } else {
      setIsCreateButtonDisabled(true)
      if (
        form.values.tankName === '' ||
        form.values.tankSize === '' ||
        form.values.gallons === ''
      ) {
        notify('warning', 'Por favor llena todos los campos.')
      } else {
        let tankData = {
          clientId: tokenData.clientId,
          companyId: tokenData.companyId,
          branchId: tokenData.branchId,
          name: form.values.tankName,
          tankSize: parseInt(form.values.tankSize),
          gallons: form.values.gallons,
        }
        let result = await PostClientTankApi(tankData)
        if (result.data != null) successCreation()
      }
    }
  }

  const getClientTanks = async (companyId, branchId) => {
    let result = await getClientTanksApi(companyId, branchId)
    if (result.data != null) setTanks(result.data)
    else {
      setTanks([])
      notify('warning', 'No hay tanques registrados.')
    }
  }

  const setTanksSize = (value) => {
    if (value <= 0) {
      notify('warning', 'El tamaño del tanque no puede ser numeros negativos.')
      form.setFieldValue('tankSize', '')
    } else {
      form.setFieldValue('tankSize', value)
    }
  }

  const setGallonsSize = (value) => {
    if (value <= 0) {
      notify('warning', 'El tamaño del galón no puede ser numeros negativos.')
      form.setFieldValue('gallons', '')
    } else {
      form.setFieldValue('gallons', value)
    }
  }

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.modules) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }

    await getClientTanks(tokenData.companyId, tokenData.branchId)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <StationMenus buttonId={0} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
          >
            Nuevo Tanque <CirclePlus size={20} color="#FFFFFF" />
          </Button>
          <br></br>
          <Row xs={2} md={3}>
            {tanks.map((tank, index) => {
              return (
                <Col key={index}>
                  <StatsRing
                    getClientTanks={getClientTanks}
                    id={tank.id}
                    name={tank.name}
                    tankSize={tank.tankSize}
                    gallons={tank.gallons}
                    pricePerGallon={tank.pricePerGallon}
                    createdAt={tank.createdAt}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog>
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nuevo Tanque
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Nombre del tanque"
                    placeholder="Nombre del tanque"
                    value={form.values.tankName}
                    onChange={(event) =>
                      form.setFieldValue('tankName', event.currentTarget.value)
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Tamaño de tanque"
                    placeholder="Tamaño de tanque"
                    type="number"
                    value={form.values.tankSize}
                    onChange={(event) =>
                      setTanksSize(event.currentTarget.value)
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Galones"
                    placeholder="Galones"
                    type="number"
                    value={form.values.gallons}
                    onChange={(event) =>
                      setGallonsSize(event.currentTarget.value)
                    }
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createTank()}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalCreateShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}

export function StatsRing(props) {
  const { tokenData } = useStore()
  const form = useForm({
    initialValues: {
      id: props.id,
      tankName: props.name,
      tankSize: props.tankSize,
      gallons: props.gallons,
    },
  })

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  const setTanksSize = (value) => {
    if (value <= 0) {
      notify('warning', 'El tamaño del tanque no puede ser numeros negativos.')
      form.setFieldValue('tankSize', '')
    } else {
      form.setFieldValue('tankSize', value)
    }
  }

  const setGallonsSize = (value) => {
    if (value <= 0) {
      notify('warning', 'El tamaño del galón no puede ser numeros negativos.')
      form.setFieldValue('gallons', '')
    } else {
      form.setFieldValue('gallons', value)
    }
  }

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const updateClientTank = async () => {
    if (parseInt(form.values.gallons) > parseInt(form.values.tankSize)) {
      notify('warning', 'Los galones superan el tamaño del tanque.')
    } else {
      setIsUpdateButtonDisabled(true)
      if (
        form.values.tankName === '' ||
        form.values.tankSize === '' ||
        form.values.gallons === ''
      ) {
        notify('warning', 'Por favor llena todos los campos.')
      } else {
        let tankData = {
          name: form.values.tankName,
          tankSize: parseInt(form.values.tankSize),
          gallons: form.values.gallons,
        }
        let result = await UpdateClientTankApi(form.values.id, tankData)
        if (result.data === 'ClientTanks updated') {
          notify('success', 'Actualización Exitoso')
          setModalPutShow(false)
          await props.getClientTanks(tokenData.companyId, tokenData.branchId)
        } else {
          notify('warning', 'Error al actualizar tanque')
        }
      }
    }
    setIsUpdateButtonDisabled(false)
  }

  //----------load modal------------------

  const [modalLoadShow, setModalLoadShow] = React.useState(false)
  const [gallonsToLoad, setGallonsToLoad] = React.useState(0)

  const handleGallonsToLoad = (value) => {
    if (
      parseFloat(form.values.gallons) + parseFloat(value) <=
      form.values.tankSize
    ) {
      value >= 0 ? setGallonsToLoad(value) : setGallonsToLoad(0)
    } else if (value == '') {
      setGallonsToLoad('')
    } else {
      notify(
        'error',
        'El valor no puede ser mayor a ' +
          parseFloat(form.values.tankSize).toString() +
          ' galones.',
      )
    }
  }

  const loadGallonsToTank = async () => {
    setIsUpdateButtonDisabled(true)
    if (gallonsToLoad == '') {
      notify('warning', 'Por favor inserte los galones a cargar.')
      setIsUpdateButtonDisabled(false)
      return false
    }

    let tankData = {
      name: form.values.tankName,
      tankSize: parseInt(form.values.tankSize),
      gallons: (
        parseFloat(form.values.gallons) + parseFloat(gallonsToLoad)
      ).toString(),
    }

    let result = await UpdateClientTankApi(form.values.id, tankData)
    if (result.data === 'ClientTanks updated') {
      notify('success', 'Actualización Exitoso')
      setModalLoadShow(false)
      await props.getClientTanks(tokenData.companyId, tokenData.branchId)
      form.setFieldValue('gallons', tankData.gallons)
    } else {
      notify('warning', 'Error al actualizar tanque')
    }
    setGallonsToLoad(0)
    setIsUpdateButtonDisabled(false)
  }

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <Paper withBorder radius="md" p="xs">
      <Group style={{ justifyContent: 'space-between' }}>
        <div style={{ width: 100, height: 100 }}>
          <CircularProgressbar
            maxValue={props.tankSize}
            value={props.gallons}
            text={`${parseFloat(props.gallons).toFixed(2)}`}
          />
        </div>
        <div>
          <Text
            color="dimmed"
            size="xl"
            transform="uppercase"
            weight={700}
            style={{ overflowWrap: 'break-word', width: '120px' }}
          >
            {props.name}
          </Text>
          <Text color="dimmed" size="md" transform="uppercase" weight={700}>
            Tamaño({props.tankSize})
          </Text>
        </div>{' '}
        <div
          style={{ display: 'flex', flexDirection: 'column', float: 'right' }}
        >
          <Button
            style={{ float: 'right', backgroundColor: 'transparent' }}
            onClick={() => setModalLoadShow(true)}
          >
            <ChargingPile size={30} color="#000" />
          </Button>
          <Button
            style={{ float: 'right', backgroundColor: 'transparent' }}
            onClick={() => setModalPutShow(true)}
          >
            <Edit size={30} color="#000" />
          </Button>
        </div>
      </Group>

      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog>
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando {form.values.tankName}
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Nombre del tanque"
                    placeholder="Nombre del tanque"
                    value={form.values.tankName}
                    onChange={(event) =>
                      form.setFieldValue('tankName', event.currentTarget.value)
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Tamaño de tanque"
                    placeholder="Tamaño de tanque"
                    type="number"
                    value={form.values.tankSize}
                    onChange={(event) =>
                      setTanksSize(event.currentTarget.value)
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Galones"
                    placeholder="Galones"
                    type="number"
                    value={form.values.gallons}
                    onChange={(event) =>
                      setGallonsSize(event.currentTarget.value)
                    }
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateClientTank()}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={modalLoadShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Recargando {form.values.tankName}
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form
                onSubmit={form.onSubmit(() => {})}
                style={{ display: 'flex' }}
              >
                <div style={{ width: 100, height: 100, margin: '0 50px' }}>
                  <CircularProgressbar
                    maxValue={props.tankSize}
                    value={
                      parseFloat(form.values.gallons) +
                      (gallonsToLoad != '' ? parseFloat(gallonsToLoad) : 0)
                    }
                    text={`${parseFloat(
                      parseFloat(form.values.gallons) +
                        (gallonsToLoad != '' ? parseFloat(gallonsToLoad) : 0),
                    ).toFixed(2)}`}
                  />
                </div>
                <Group grow>
                  <TextField
                    disabled={true}
                    variant="standard"
                    label="Nombre del tanque"
                    placeholder="Nombre del tanque"
                    value={form.values.tankName}
                  />

                  <TextField
                    disabled={true}
                    variant="standard"
                    label="Tamaño de tanque"
                    placeholder="Tamaño de tanque"
                    type="number"
                    value={form.values.tankSize}
                  />

                  <TextField
                    variant="standard"
                    label="Galones a tanquear"
                    placeholder="Galones a tanquear"
                    type="number"
                    value={gallonsToLoad}
                    inputProps={{ min: 0, max: form.values.gallons }}
                    onChange={(event) => {
                      handleGallonsToLoad(event.currentTarget.value)
                    }}
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => loadGallonsToTank()}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Cargar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalLoadShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </Paper>
  )
}
