import React from 'react'
import styles from '../../styles/Home.module.css'
import Menu from '../../components/client/menu'
import { Link } from 'react-router-dom'
import useStore from '../../components/zustand'

export default function Dashboard() {
  const { rolesData } = useStore()

  //ROLES

  return (
    <div>
      <Menu />
      <div className={styles.container}>
        <main className={styles.main}>
          <h1 className={styles.title}>
            Bienvenido a{' '}
            <a
              className="darkmode-ignore"
              style={{ textDecoration: 'none', cursor: 'default' }}
              href="/#"
            >
              AvrilApp
            </a>
          </h1>

          <p className={styles.description}>Accesos directos </p>

          <div className={styles.grid}>
            {rolesData.admin ? (
              <Link className={styles.card} to={'/state'}>
                <h3>Estado &rarr;</h3>
                <p>Acá podrás el estado de los tanques.</p>
              </Link>
            ) : null}

            {rolesData.sales ? (
              <>
                <div
                  className={styles.card}
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h3>Ventas</h3>
                  <Link to="/newsale">Nueva Venta &rarr;</Link>
                  <Link to="/sales">Registro de ventas &rarr;</Link>
                  {rolesData.admin || rolesData.configuration ? (
                    <Link to="/comparesales">Comparación de ventas &rarr;</Link>
                  ) : null}
                  <br></br>
                </div>
              </>
            ) : null}
            {rolesData.invoice ? (
              <div
                className={styles.card}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h3>Facturación</h3>
                <Link to="/invoice">Facturación Electrónica &rarr;</Link>
                <Link to="/creditnote">Notas Crédito &rarr;</Link>
                <Link to="/debitnote">Notas Débito &rarr;</Link>
              </div>
            ) : null}
            {rolesData.modules ? (
              <>
                <div
                  className={styles.card}
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h3>Estación</h3>
                  <Link to="/tanks">Tanques &rarr;</Link>
                  <Link to="/islands">Islas &rarr;</Link>
                  <Link to="/hoses">Mangueras &rarr;</Link>
                </div>
              </>
            ) : null}
            {rolesData.clients ? (
              <>
                <div
                  className={styles.card}
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h3>Clientes</h3>
                  <Link to="/clients">Clientes &rarr;</Link>
                  <Link to="/driver-bus">Buses &rarr;</Link>
                  <br></br>
                </div>
              </>
            ) : null}
            {rolesData.admin ? (
              <>
                <div
                  className={styles.card}
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h3>Nómina</h3>
                  <Link to="/employees">Empleados &rarr;</Link>
                  <Link to="/settlement">Liquidación &rarr;</Link>
                  <Link to="/dashboard">Historial de periodos</Link>
                  <Link to="/dashboard">Configuración</Link>
                </div>
              </>
            ) : null}
            {rolesData.configuration ? (
              <>
                <div
                  className={styles.card}
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h3>Configuración</h3>
                  <Link to="/gallonprice">Combustibles &rarr;</Link>
                  <Link to="/receiptdispenser">
                    Numeración del dispensador &rarr;
                  </Link>
                  <Link to="/billingreport">Cuentas contables &rarr;</Link>
                  <Link to="/products">Productos &rarr;</Link>
                  <Link to="/mobility">Movilidad &rarr;</Link>
                  <Link to="/company">Empresa &rarr;</Link>
                </div>
              </>
            ) : null}
          </div>
        </main>
      </div>
    </div>
  )
}
