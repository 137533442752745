import React, { useState, useEffect } from 'react'
import useStore from '../../../components/zustand'
import Button from 'react-bootstrap/Button'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Select as MuiSelect,
  Typography,
} from '@mui/material'
import { Group, Text } from '@mantine/core'
import { Edit } from 'tabler-icons-react'
import 'react-toastify/dist/ReactToastify.css'
import 'dayjs/locale/es'
import 'react-toggle/style.css'
import { MenuItem, InputLabel, FormControl } from '@mui/material'
import { notify } from '../../../services/utils'
import { getThirdClientsByCompanyApi } from '../../../pages/api/thirdClients'
import CustomSelect from '../../custom/customSelect'
import { UpdateClientSalesApi } from '../../../pages/api/clientsale'
import Toggle from 'react-toggle'

export default function EditMenu(props) {
  const { tokenData, isDarkModeActive } = useStore()

  const { salesToEdit, resetEditConstants } = props

  const [filterExpanded, setFilterExpanded] = useState(false)

  const [paymentMethodSelected, setPaymentMethodSelected] = useState(0)

  const [editPaymentMethod, setEditPaymentMethod] = useState(false)
  const [editThirdClient, setEditThirdClient] = useState(false)
  const [editSaleState, setEditSaleState] = useState(false)

  const [selectedThirdClientId, setSelectedThirdClientId] = useState(0)
  const [selectedThirdClientName, setSelectedThirdClientName] = useState('')

  const EnumSales = {
    Created: 1,
    Validated: 2,
    Accepted: 3,
    Refused: 4,
    Bundled: 5,
  }

  const handleClientSelected = (thirdClient) => {
    setSelectedThirdClientId(thirdClient.value)
    setSelectedThirdClientName(thirdClient.label)
  }

  const UpdateSales = async () => {
    if (!editPaymentMethod && !editThirdClient && !editSaleState) {
      notify('warning', 'No se han realizado cambios')
      return
    }

    let data = []
    salesToEdit.map((sale) => {
      if (sale.isEditable)
        data.push({
          id: sale.id,
          paymentMethod: editPaymentMethod
            ? paymentMethodSelected
            : sale.paymentMethod,
          thirdClientId: editThirdClient
            ? selectedThirdClientId
            : sale.thirdClientId,
          thirdClientCompanyName: editThirdClient
            ? selectedThirdClientName
            : sale.thirdClientCompanyName,
          saleState: editSaleState ? EnumSales.Validated : sale.saleState,
        })
    })
    let result = await UpdateClientSalesApi(data)
    if (result.data === 'Masive PMTD updated') {
      notify('success', 'Se han actualizado las ventas')
      setFilterExpanded(false)
      setEditPaymentMethod(false)
      setEditThirdClient(false)
      setSelectedThirdClientId(0)
      setSelectedThirdClientName('')
      setPaymentMethodSelected(0)
      resetEditConstants()
    } else {
      notify('warning', 'No se han podido actualizar las ventas')
    }
  }

  //--------select thirdclientclient -------

  const [thirdClientsOptions, setThirdClientsOptions] = useState([])

  const getThirdClients = async () => {
    let result = await getThirdClientsByCompanyApi(tokenData.companyId)
    if (result.data != null) {
      let clientSelect = await result.data.map((x) => {
        return { value: x.id, label: x.thirdClientCompanyName }
      })
      setThirdClientsOptions(clientSelect)
    }
  }

  useEffect(() => {
    getThirdClients()
  }, [])

  return (
    <Accordion
      className="filterButton"
      expanded={filterExpanded}
      onChange={() => setFilterExpanded(!filterExpanded)}
      style={{
        width: filterExpanded ? '27.2em' : '7em',
        height: filterExpanded ? 'auto' : '2.5em',
        top: filterExpanded ? 0 : '1em',
        marginLeft: '10px',
      }}
    >
      <AccordionSummary
        expandIcon={
          <Edit size={25} style={{ float: 'right', cursor: 'pointer' }} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <b>{filterExpanded ? 'Editar ventas' : 'Editar'}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr style={{ margin: '10px', top: '10px' }}>
              <td>
                <Toggle
                  style={{}}
                  checked={editSaleState}
                  onChange={(e) => setEditSaleState(e.target.checked)}
                />
              </td>
              <td>
                <Text style={{ marginLeft: '10px' }}>
                  ¿Enviar las seleccionadas a facturación?
                </Text>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: '50px',
                  margin: 'auto',
                  textAlign: 'center',
                }}
              >
                <Checkbox
                  checked={editPaymentMethod}
                  onChange={() => setEditPaymentMethod(!editPaymentMethod)}
                />
              </td>
              <td>
                <FormControl
                  variant="standard"
                  style={{ minWidth: '100%', margin: '10px 0' }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Método de Pago
                  </InputLabel>
                  <MuiSelect
                    disabled={!editPaymentMethod}
                    label="Método de Pago"
                    value={paymentMethodSelected}
                    onChange={(e) => setPaymentMethodSelected(e.target.value)}
                  >
                    <MenuItem value={1}>CONTADO</MenuItem>
                    <MenuItem value={2}>CRÉDITO</MenuItem>
                  </MuiSelect>
                </FormControl>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: '50px',
                  margin: 'auto',
                  textAlign: 'center',
                }}
              >
                <Checkbox
                  checked={editThirdClient}
                  onChange={() => setEditThirdClient(!editThirdClient)}
                />
              </td>
              <td>
                <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                  <CustomSelect
                    title="Empresa/Cliente"
                    disabled={!editThirdClient}
                    placeholder="Cliente"
                    value={{
                      value: selectedThirdClientId,
                      label: selectedThirdClientName,
                    }}
                    onChange={(e) => handleClientSelected(e)}
                    options={thirdClientsOptions}
                    theme={isDarkModeActive ? 'black' : 'white'}
                    minWidth={300}
                  />
                </FormControl>
              </td>
            </tr>
          </tbody>
        </table>
        <Group>
          <Button
            style={{
              marginTop: '20px',
              float: 'right',
              backgroundColor: 'rgb(13, 71, 161)',
              marginLeft: '5px',
            }}
            onClick={() => UpdateSales()}
          >
            Aceptar
          </Button>
        </Group>
      </AccordionDetails>
    </Accordion>
  )
}
