import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ToastContainer } from 'react-toastify'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ToastContainer
      position="top-right"
      autoClose={3500}
      hideProgressBar={true}
      newestOnTop={false}
      draggable={false}
      closeOnClick
      pauseOnHover
    />
    <App />
  </React.StrictMode>,
)
reportWebVitals()
