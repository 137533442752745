import { Group } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router'
import { notify } from '../../services/utils.js'
import 'react-toastify/dist/ReactToastify.css'
import { CirclePlus, Trash } from 'tabler-icons-react'
import Menu from '../../components/client/menu.js'
import LoadingScreen from '../../components/main/loadingScreen'
import useStore from '../../components/zustand'
import { dateFormat, isEmpty, setDecimal } from '../../services/utils.js'
import { Select as MuiSelect } from '@mui/material'
import {
  FormControl,
  InputLabel,
  Pagination,
  Select as SelectPages,
} from '@mui/material'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import Swal from 'sweetalert2'
import NewCreditNote from '../../components/client/ebilling/newCreditNote.js'
import InvoiceFilters from '../../components/client/filters/invoiceFilters.js'
import SaleModal from '../../components/client/sales/modals/saleModal.js'
import BillingMenus from '../../components/client/shortcutMenus/billingMenus.js'
import saleIcon from '../../resources/images/factura.png'
import pdf from '../../resources/images/pdf.png'
import { getClientSalesByIdApi } from '../api/clientsale.js'
import {
  DeleteCreditNoteApi,
  getCreditNotesApi,
  getFilteredCreditNotesByDateApi,
  getFilteredCreditNotesByInvoiceApi,
} from '../api/creditNote.js'
import { getClientResolutionsApi } from '../api/clientResolutions.js'

export default function CreditNote() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      invoiceNumber: '',
    },
  })

  //--------------------------------Anulación---------------------------------

  const [modalAnnulateShow, setModalAnnulateShow] = React.useState(false)

  //----------------------GetCreditNotes------------------
  const [creditNotes, setCreditNotes] = useState([])

  const getCreditNotes = async (
    companyId,
    branchId,
    resolutionId,
    offset,
    limit,
  ) => {
    let result = await getCreditNotesApi(
      companyId,
      branchId,
      resolutionId,
      offset,
      limit,
    )
    if (result.data != null) {
      setTotalCreditNoteCount(result.count)
      setCreditNotes(result.data)
    } else {
      setCreditNotes([])
      setTotalCreditNoteCount(0)
      notify('warning', 'No se encuentran notas crédito.')
    }
  }

  //CHECK ROLES
  const tokenVerified = async () => {
    if (!rolesData.invoice) {
      notify('warning', 'No tienes permiso para ver este módulo.')
      navigate('/dashboard')
    }
    await getResolutions(tokenData.branchId)
  }

  //--------------------Sale ---------------

  const [saleInfo, setSaleInfo] = useState([])

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleCloseSale = () => setShow(false)

  const showSale = async (saleId) => {
      let result = await getClientSalesByIdApi(saleId)
      if (result.data != null) {
        setSaleInfo(result.data)
        handleShow()
      } else {
        notify('warning', 'Error, no se encuentra la venta asociada.')
      }
  }

  //----------------------Bills methods---------------------------

  const getProductsTotalPrice = (products) => {
    let total = 0
    products.map((product) => {
      let iva =
        product.taxes == undefined ? 0 : parseInt(product.taxes[0].tax_rate)
      let rf =
        product.retentions == undefined
          ? 0
          : parseFloat(product.retentions[0].tax_rate)
      let discount =
        product.discount_rate == undefined ? 0 : parseInt(product.discount_rate)
      total +=
        (product.price *
          product.quantity *
          (1 - discount / 100) *
          (100 + (iva - rf))) /
        100
    })
    return total
  }

  //-----------------------SendCreditNotes---------------------

  const successCreation = async (data) => {
    if (data.data == 400) {
      notify(
        'error',
        `Petición o dirección inválida, error tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 500) {
      notify(
        'error',
        `Error en el servidor de tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 401) {
      notify(
        'error',
        `Fallo en la autenticación, contacto con un administrador, error tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 404) {
      notify(
        'error',
        `No se pudo enviar a facturación electrónica, error tipo ${data.data},${data.dataicoErrorMsg}`,
      )
    }
    if (data.data == 201) {
      notify('success', 'Creación Exitoso')

      await getCreditNotes(
        tokenData.companyId,
        tokenData.branchId,
        currentResolution,
        pg * rpg - rpg,
        rpg,
      )
    }
    setModalAnnulateShow(false)
  }
  //--------------DeleteCreditNote--------------

  const deleteCreditNoteState = async (creditNote) => {
    Swal.fire({
      title: '¿Eliminar la nota crédito?',
      text: 'Nota: Esta acción es irreversible',
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(async (response) => {
      if (response.isConfirmed) {
        let result = await DeleteCreditNoteApi(
          creditNote.id,
          tokenData.companyId,
          creditNote.creditNoteNumeration,
          creditNote.invoiceNumber,
        )
        if (result.data == 'Deleted') {
          notify('success', `Nota Crédito eliminada con éxito.`)
          await getCreditNotes(
            tokenData.companyId,
            tokenData.branchId,
            currentResolution,
            pg * rpg - rpg,
            rpg,
          )
        } else {
          notify('error', `Error, ${result.data}.`)
        }
      }
    })
  }

  //--------------------Resolutions methods----------------------

  const [resolutions, setResolutions] = useState([])

  const [currentResolution, setCurrentResolution] = useState(0)

  const getResolutions = async (branchId) => {
    const result = await getClientResolutionsApi(branchId)
    if (result.data != null) {
      setResolutions(result.data)
      await getCreditNotes(
        tokenData.companyId,
        tokenData.branchId,
        result.data[0].id,
        pg * rpg - rpg,
        rpg,
      )
      setCurrentResolution(result.data[0].id)
    } else notify('warning', 'No se encuentran resoluciones.')
  }

  const handleResolutionChange = async (resolutionId) => {
    setCurrentResolution(resolutionId)
    await getCreditNotes(
      tokenData.companyId,
      tokenData.branchId,
      resolutionId,
      pg * rpg - rpg,
      rpg,
    )
  }

  //-------------------Acciones-----------

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(10)
  const [totalCreditNoteCount, setTotalCreditNoteCount] = useState(0)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg

    await getCreditNotes(
      tokenData.companyId,
      tokenData.branchId,
      currentResolution,
      newOffset,
      rpg,
    )
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)

    getCreditNotes(
      tokenData.companyId,
      tokenData.branchId,
      currentResolution,
      0,
      event.target.value,
    )
  }

  //-----------Filters-------------------

  const getFilteredCreditNotesByInvoice = async (invoiceString) => {
    let result = await getFilteredCreditNotesByInvoiceApi(
      tokenData.companyId,
      tokenData.branchId,
      invoiceString,
    )
    if (result.data != null) {
      setCreditNotes(result.data)
      setTotalCreditNoteCount(result.count)
    } else {
      notify('warning', 'No se encuentran facturas para el filtro aplicado.')
      setCreditNotes([])
      setTotalCreditNoteCount(0)
    }
  }

  const getFilteredCreditNotesByDate = async (
    thirdClientId,
    filterStartDate,
    filterEndDate,
    offset,
    limit,
  ) => {
    let startDate = dateFormat(
      filterStartDate.toLocaleDateString(),
      false,
      true,
    )
    let endDate = dateFormat(filterEndDate.toLocaleDateString(), false, true)
    let result = await getFilteredCreditNotesByDateApi(
      tokenData.companyId,
      tokenData.branchId,
      thirdClientId,
      startDate,
      endDate,
      offset,
      limit,
    )
    if (result.data != null) {
      setCreditNotes(result.data)
      setTotalCreditNoteCount(result.count)
    } else {
      notify('warning', 'No se encuentran facturas para el filtro aplicado.')
      setCreditNotes([])
      setTotalCreditNoteCount(0)
    }
  }

  //-----------------------

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <BillingMenus buttonId={1} />
          <br></br>
          <Container style={{ display: 'flex' }}>
            <div
              style={{ display: 'flex', position: 'absolute', zIndex: 9 }}
            >
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Resolución
                </InputLabel>
                <MuiSelect
                  label="Resolución"
                  onChange={(e) => handleResolutionChange(e.target.value)}
                  value={currentResolution}
                >
                  {resolutions.map((resolution, index) => {
                    return (
                      <MenuItem value={resolution.id} key={index}>
                        {resolution.prefix +
                          ' - ' +
                          resolution.resolutionNumber}
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
              </FormControl>
              <InvoiceFilters
                getFilteredInvoiceByDate={getFilteredCreditNotesByDate}
                getFilteredInvoiceByInvoice={getFilteredCreditNotesByInvoice}
                getInvoice={getCreditNotes}
                currentResolution={currentResolution}
              />
            </div>
            <div style={{ height: '50px' }}></div>
          </Container>
          <br></br>
          {modalAnnulateShow ? (
            <NewCreditNote
              setModalAnnulateShow={setModalAnnulateShow}
              successCreation={successCreation}
            />
          ) : (
            <Container>
              <Button
                variant="primary"
                style={{
                  backgroundColor: 'green',
                  marginLeft: 5,
                  marginBottom: 10,
                  isolation: 'isolate',
                }}
                onClick={() => setModalAnnulateShow(true)}
                className="create_invoice_button"
              >
                Nueva Nota Crédito <CirclePlus size={20} color="#FFFFFF" />
              </Button>
              <br></br>
              <MDBTable small>
                <MDBTableHead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Estado</th>
                    <th style={{ textAlign: 'center' }}>No.</th>
                    <th style={{ textAlign: 'center' }}>No. Fac</th>
                    <th style={{ textAlign: 'center' }}>Cliente</th>
                    <th style={{ textAlign: 'center' }}>Total</th>
                    <th style={{ textAlign: 'center' }}>Fecha</th>
                    <th style={{ textAlign: 'center' }}>Venta Asociada</th>
                    <th style={{ width: 'fit-content', textAlign: 'center' }}>
                      Acciones
                    </th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {creditNotes.map((creditNote, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {creditNote.dianStatus == 'DIAN_RECHAZADO' ? (
                            <div
                              style={{
                                borderRadius: '7%',
                                backgroundColor: '#EF5350',
                                color: 'white',
                                width: 'fit-content',
                                padding: '0px 3px',
                                margin: '0 10px 0 10px',
                                fontSize: '14px',
                              }}
                            >
                              {creditNote.dianStatus.replace('_', ' ')}
                            </div>
                          ) : (
                            <div
                              style={{
                                borderRadius: '7%',
                                backgroundColor: '#66BB6A',
                                color: 'white',
                                width: 'fit-content',
                                padding: '0px 3px',
                                margin: '0 10px 0 10px',
                                fontSize: '14px',
                              }}
                            >
                              {creditNote.dianStatus.replace('_', ' ')}
                            </div>
                          )}
                        </td>
                        <td>{creditNote.number}</td>
                        <td>{creditNote.invoiceNumber}</td>
                        <td>{creditNote.client}</td>
                        <td>
                          {' '}
                          $
                          {setDecimal(
                            getProductsTotalPrice(JSON.parse(creditNote.items)),
                          )}
                        </td>
                        <td>{creditNote.createdAt}</td>
                        <td
                          style={{
                            width: '100px',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <a
                            onClick={() => showSale(creditNote.saleId)}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              style={{
                                float: 'right',
                                float: 'inline-start',
                                alignContent: 'center',
                                cursor: 'pointer',
                                width: '40px',
                              }}
                              src={saleIcon}
                            />
                          </a>
                        </td>
                        <td
                          style={{
                            width: '210px',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <img
                            onClick={() => openInNewTab(creditNote.pdfUrls)}
                            style={{
                              float: 'right',
                              float: 'inline-start',
                              alignContent: 'center',
                              cursor: 'pointer',
                              width: '40px',
                            }}
                            src={pdf}
                            // color="#EF5350"
                            // strokeWidth={1.2}
                          />
                          {creditNote.dianStatus == 'DIAN_RECHAZADO' ? (
                            <Trash
                              onClick={() => {
                                deleteCreditNoteState(creditNote)
                              }}
                              size={40}
                              color="red"
                              strokeWidth={1.2}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : (
                            <Trash size={40} color="gray" strokeWidth={1.2} />
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
              <Container>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Filas
                  </InputLabel>
                  <SelectPages
                    labelId="demo-simple-select-standard-label"
                    autoWidth={true}
                    value={rpg}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </SelectPages>
                </FormControl>
                <Pagination
                  count={Math.ceil(totalCreditNoteCount / rpg)}
                  page={pg}
                  onChange={handleChangePage}
                  showFirstButton={true}
                  showLastButton={true}
                  style={{ display: 'inline-block' }}
                />
              </Container>
            </Container>
          )}
          {show ? (
            <SaleModal sales={saleInfo} show={show} onClose={handleCloseSale} />
          ) : null}
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </div>
  )
}
