import Button from 'react-bootstrap/Button'
import { read, utils } from 'xlsx'
import React, { useEffect, useState } from 'react'
import useStore from '../../../components/zustand'
import { notify } from '../../../services/utils'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { Trash } from 'tabler-icons-react'
import { CircularProgress } from '@mui/material'
import { Container } from 'react-bootstrap'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { PostBulkDriverBussesApi } from '../../../pages/api/thirdClientsDriverBusses'
import CustomSelect from '../../custom/customSelect'
import CustomExpandMore from '../../custom/customExpandMore'

export default function DriverBusUploadModal(props) {
  const { show, onClose, thirdClients } = props
  const { tokenData, isDarkModeActive } = useStore()

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const handleImport = ($event) => {
    const files = $event.target.files
    if (files.length) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = async (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          try {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
            setLoadedInfo(rows)
          } catch (e) {
            notify(
              'error',
              'Verifique que el nombre de todas las columnas sea correcto.',
            )
          }
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const [loadedThirdClients, setLoadedThirdClients] = useState([])
  const [loadedBusses, setLoadedBusses] = useState([])

  const setLoadedInfo = (rows) => {
    let thirdClients = []
    let busPlates = []
    rows.map((row) => {
      if (
        row['CODIGO'].trim().length == 4 &&
        row['CODIGO'].substring(2, 4) != '00'
      ) {
        busPlates.push({
          companyId: tokenData.companyId,
          branchId: tokenData.branchId,
          thirdClientsId: 0,
          busPlate: row['DESCRIPCION'].toUpperCase().trim(),
          driverName: 'ENCARGADO',
          driverCc: '999999999',
          clientUserId: tokenData.clientId,
          isGeneric: false,
          costCenter: row['CODIGO'].trim(),
        })
      } else if (row['CODIGO'].trim().length == 2) {
        thirdClients.push({
          costCenter: row['CODIGO'].trim(),
          description: row['DESCRIPCION'].toUpperCase(),
          id: 0,
        })
      }
    })
    setLoadedThirdClients(thirdClients)
    setLoadedBusses(busPlates)
  }

  const handleUpload = async () => {
    await uploadBusses(loadedBusses)
  }

  const checkIfThirdClientsAreSetted = () => {
    let busError = loadedBusses.find((bus) => bus.thirdClientsId == 0)
    if (busError == undefined) return true
    else {
      notify(
        'warning',
        'Debe asignar una empresa a todos los buses. el código ' +
          busError.costCenter +
          ' no tiene empresa asignada.',
      )
      return false
    }
  }

  const uploadBusses = async (busses) => {
    setIsCreateButtonDisabled(true)
    if (checkIfThirdClientsAreSetted()) {
      let result = await PostBulkDriverBussesApi(busses)
      if (result.data != null) successCreation(result)
      else notify('warning', 'Error al subir los buses masivamente.')
    }
    setIsCreateButtonDisabled(false)
  }

  const successCreation = async (data) => {
    if (data.data == 'ThirdClientsDriverBusses created') {
      notify('success', 'Buses cargados con éxito.')
      onClose()
    } else {
      notify('warning', data.data)
    }
  }

  //------------third clients-----------------

  const [clientProductsOptions, setClientProductsOptions] = React.useState([])

  const setThirdClientsOptions = (thirdClients) => {
    if (thirdClients.length > 0) {
      let thirdClientsSelect = thirdClients.map((x) => {
        return {
          value: x.id,
          label: x.nit + ' - ' + x.thirdClientCompanyName,
          thirdClient: x,
        }
      })
      setClientProductsOptions(thirdClientsSelect)
    } else {
      setClientProductsOptions([])
      notify('warning', 'No hay empresas registradas.')
    }
  }

  const setBussesThirdClient = (
    thirdClientCostCenter,
    thirdclientId,
    thirdClientInfo,
  ) => {
    let loadedBussesAux = [...loadedBusses]
    loadedBussesAux.map((bus) => {
      let busCostCenter = bus.costCenter.substring(0, 2)
      if (busCostCenter == thirdClientCostCenter) {
        bus.thirdClientsId = thirdclientId
        bus.discount = thirdClientInfo.discount
      }
    })
    let loadedThirdClientsAux = [...loadedThirdClients]
    loadedThirdClientsAux.some((thirdClient) => {
      if (thirdClient.costCenter == thirdClientCostCenter) {
        thirdClient.id = thirdclientId
        return true
      }
    })
    setLoadedThirdClients(loadedThirdClientsAux)
    setLoadedBusses(loadedBussesAux)
  }

  const deleteThirdClient = (thirdClientCostCenter, thirdClientIndex) => {
    let loadedThirdClientAux = [...loadedThirdClients]
    loadedThirdClientAux.splice(thirdClientIndex, 1)

    let loadedBussesAux = [...loadedBusses]
    for (let i = loadedBussesAux.length - 1; i >= 0; i--) {
      let busCostCenter = loadedBussesAux[i].costCenter.substring(0, 2)
      if (busCostCenter == thirdClientCostCenter) {
        loadedBussesAux.splice(i, 1)
      }
    }
    setLoadedBusses(loadedBussesAux)
    setLoadedThirdClients(loadedThirdClientAux)
  }

  const deleteBus = (costCenter) => {
    let loadedBussesAux = [...loadedBusses]
    let busToDeleteIndex = loadedBussesAux.findIndex(
      (bus) => bus.costCenter == costCenter,
    )
    loadedBussesAux.splice(busToDeleteIndex, 1)
    setLoadedBusses(loadedBussesAux)
  }

  const getThirdClientNameById = (id) => {
    let thirdClient = thirdClients.find((x) => x.id == id)
    if (thirdClient == undefined) return ''
    return thirdClient.nit + ' - ' + thirdClient.thirdClientCompanyName
  }

  const getBussesByCostCenter = (costCenter) => {
    let busses = loadedBusses.filter(
      (bus) => bus.costCenter.substring(0, 2) == costCenter,
    )
    return busses
  }

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  useEffect(() => {
    setThirdClientsOptions(thirdClients)
  }, [])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size={loadedThirdClients.length == 0 ? 'lg' : 'xl'}>
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Carga masiva de buses
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            {loadedThirdClients.length == 0 ? (
              <Container>
                <h4>Ahora, carga tu archivo</h4>
                <input
                  type="file"
                  name="file"
                  className="form-control form-control-xl"
                  id="inputGroupFile"
                  required
                  style={{ maxWidth: '400px' }}
                  onChange={handleImport}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </Container>
            ) : null}
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              disabled={
                loadedThirdClients.length == 0 || isCreateButtonDisabled
              }
              style={{ backgroundColor: 'green', borderRadius: '100px' }}
              onClick={() => handleUpload()}
            >
              {isCreateButtonDisabled ? (
                <CircularProgress size={15} color="inherit" />
              ) : null}{' '}
              Subir
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cerrar
            </Button>
          </MDBModalFooter>
          <MDBModalBody>
            {loadedThirdClients.length > 0 ? (
              <MDBTable small style={{ color: color }}>
                <MDBTableHead>
                  <tr>
                    <th>Código</th>
                    <th>Descripción</th>
                    <th>Empresa</th>
                    <th>Acciones</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                  {loadedThirdClients.map((loadedThirdClient, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{loadedThirdClient.costCenter}</td>
                          <td>{loadedThirdClient.description}</td>
                          <td>
                            <CustomSelect
                              title="Empresa"
                              placeholder="Buscar empresa"
                              value={
                                loadedThirdClient.id != 0
                                  ? {
                                      value: loadedThirdClient.thirdClientsId,
                                      label: getThirdClientNameById(
                                        loadedThirdClient.id,
                                      ),
                                    }
                                  : ''
                              }
                              onChange={(client) =>
                                setBussesThirdClient(
                                  loadedThirdClient.costCenter,
                                  client.value,
                                  client.thirdClient,
                                )
                              }
                              options={clientProductsOptions}
                              theme={isDarkModeActive ? 'black' : 'white'}
                            />
                          </td>
                          <td>
                            <Button
                              style={{
                                float: 'right',
                                backgroundColor: 'transparent',
                              }}
                              onClick={() =>
                                deleteThirdClient(
                                  loadedThirdClient.costCenter,
                                  index,
                                )
                              }
                            >
                              <Trash size={25} color="red" />
                            </Button>
                          </td>
                        </tr>
                        <CustomExpandMore
                          title={'Placas de ' + loadedThirdClient.description}
                        >
                          {getBussesByCostCenter(
                            loadedThirdClient.costCenter,
                          ).map((bus, index) => {
                            return (
                              <tr
                                key={index}
                                style={{ backgroundColor: '#F5F5F5' }}
                              >
                                <td>{bus.costCenter}</td>
                                <td>{bus.busPlate}</td>
                                <td>
                                  {getThirdClientNameById(bus.thirdClientsId)}
                                </td>
                                <td>
                                  <Button
                                    style={{
                                      float: 'right',
                                      backgroundColor: 'transparent',
                                    }}
                                    onClick={() => deleteBus(bus.costCenter)}
                                  >
                                    <Trash size={25} color="red" />
                                  </Button>
                                </td>
                              </tr>
                            )
                          })}
                        </CustomExpandMore>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
            ) : null}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}
