import React from 'react'
import Container from 'react-bootstrap/Container'
import { Group, Text, Paper } from '@mantine/core'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { UserCircle } from 'tabler-icons-react'
import { setDecimal } from '../../../../services/utils'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

export default function SellerState(props) {
  return (
    <div>
      <Container>
        <Row xs={1} md={3} className="g-4">
          <Row>
            <Col>
              <Paper withBorder radius="md" p="xs" style={{ width: '280px' }}>
                <Group>
                  <Text
                    color="dimmed"
                    size="xl"
                    transform="uppercase"
                    weight={700}
                  >
                    <UserCircle
                      style={{
                        float: 'right',
                        float: 'inline-start',
                        alignContent: 'center',
                        marginRight: '5px',
                      }}
                      size={50}
                      color="black"
                      strokeWidth={1.2}
                    />
                    {props.client.fullName}
                  </Text>
                  <Accordion
                    expanded={props.show}
                    style={{ boxShadow: 'none' }}
                  >
                    <AccordionSummary
                      expandIcon={null}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Text
                        color="dimmed"
                        size="md"
                        transform="uppercase"
                        weight={700}
                      >
                        Información del turno
                      </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Group style={{ display: 'inline-block' }}>
                        <Text
                          color="dimmed"
                          size="md"
                          transform="uppercase"
                          weight={700}
                        >
                          Total facturado $
                          {props.show
                            ? setDecimal(props.client.total)
                            : '**********'}
                        </Text>
                        <Text
                          color="dimmed"
                          size="md"
                          transform="uppercase"
                          weight={700}
                        >
                          Ventas crédito:{' '}
                          {props.show ? props.client.totalCredit : '***'}
                        </Text>
                        <Text
                          color="dimmed"
                          size="md"
                          transform="uppercase"
                          weight={700}
                        >
                          Ventas débito:{' '}
                          {props.show ? props.client.totalDebit : '***'}
                        </Text>
                      </Group>
                    </AccordionDetails>
                  </Accordion>
                </Group>
              </Paper>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  )
}
