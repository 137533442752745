import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import useStore from '../../zustand'
import Button from 'react-bootstrap/Button'
import { Divider, Group } from '@mantine/core'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import 'react-toastify/dist/ReactToastify.css'
import { Filter } from 'tabler-icons-react'

import { MenuItem, InputLabel, FormControl } from '@mui/material'
import { Select as MuiSelect } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { notify } from '../../../services/utils'
import { dateFormat } from '../../../services/utils'
import { getClientUsersApi } from '../../../pages/api/clientUsers'

export default function MoneyDeliveryFilters(props) {
  const { tokenData, isDarkModeActive } = useStore()
  const { getDeliveries, getDeliveriesByUserOrClientUser } = props

  //-----------Filters-------------------

  const [filterExpanded, setFilterExpanded] = useState(false)
  const [filterStartDate, setFilterStartDate] = useState(dayjs(new Date()))
  const [filterEndDate, setFilterEndDate] = useState(dayjs(new Date()))
  const [UserId, setUserId] = useState(0)
  const [clientUserId, setClientUserId] = useState(0)
  const [minDate, setMinDate] = useState(dayjs(new Date()))

  const FilterSales = async (offset, limit) => {
    if (filterStartDate > filterEndDate) {
      notify('warning', 'La fecha inicial no puede ser mayor a la fecha final')
      return
    }
    if (UserId === 0 && clientUserId === 0) {
      await getDeliveries(
        tokenData.companyId,
        tokenData.branchId,
        offset,
        limit,
      )
    } else {
      let startDate = dateFormat(
        new Date(filterStartDate).toLocaleDateString(),
        false,
        true,
      )
      let endDate = new Date(filterEndDate)
      endDate.setDate(endDate.getDate() + 1)
      endDate = dateFormat(endDate.toLocaleDateString(), false, true)

      await getDeliveriesByUserOrClientUser(
        UserId,
        clientUserId,
        startDate,
        endDate,
        offset,
        limit,
      )
    }
  }

  const setMinFilterDate = () => {
    const date = new Date()
    date.setMonth(date.getMonth() - 6)
    date.setDate(1)
    setMinDate(dayjs(date))
  }

  const resetFilters = async () => {
    setFilterExpanded(false)
    setUserId(0)
    setClientUserId(0)
    setFilterStartDate(dayjs(new Date()))
    setFilterEndDate(dayjs(new Date()))
    await getDeliveries(tokenData.companyId, tokenData.branchId, 0, 10)
  }

  const [adminUsers, setAdminUsers] = useState([])
  const [clientUsers, setClientUsers] = useState([])

  const getUsers = async (companyId, branchId) => {
    let result = await getClientUsersApi(companyId, branchId)
    if (result.data != null) {
      let adminClients = []
      let clientUsers = []
      result.data.map((user) => {
        if (user.admin) {
          adminClients.push(user)
        }
        if (user.sales) {
          clientUsers.push(user)
        }
      })
      setAdminUsers(adminClients)
      setClientUsers(clientUsers)
    } else {
      notify('warning', 'No hay usuarios en esta sucursal')
      setClientUsers([])
      setAdminUsers([])
    }
  }

  //----------

  useEffect(() => {
    getUsers(tokenData.companyId, tokenData.branchId)
    setMinFilterDate()
  }, [])

  return (
    <Accordion
      className="filterButton"
      expanded={filterExpanded}
      onChange={() => setFilterExpanded(!filterExpanded)}
      style={{
        width: filterExpanded ? '30em' : '8.5em',
      }}
    >
      <AccordionSummary
        expandIcon={
          <Filter size={40} style={{ float: 'right', cursor: 'pointer' }} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <b>Filtros</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container>
          <Group grow>
            <Group>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Por Encargado
                </InputLabel>
                <MuiSelect
                  required
                  label="Encargado"
                  value={UserId}
                  onChange={(e) => {
                    setUserId(e.target.value)
                  }}
                >
                  <MenuItem value={0}>Cualquier Encargado</MenuItem>
                  {adminUsers.map((clientUser, index) => {
                    return (
                      <MenuItem key={index} value={clientUser.id}>
                        {clientUser.fullName}
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
              </FormControl>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Por Islero
                </InputLabel>
                <MuiSelect
                  required
                  label="Islero"
                  value={clientUserId}
                  onChange={(e) => {
                    setClientUserId(e.target.value)
                  }}
                >
                  <MenuItem value={0}>Cualquier Islero</MenuItem>
                  {clientUsers.map((thirdClient, index) => {
                    return (
                      <MenuItem key={index} value={thirdClient.id}>
                        {thirdClient.fullName}
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
              </FormControl>
            </Group>
            <Group grow>
              <div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ES"
                >
                  <DatePicker
                    label="FECHA INICIAL"
                    format="DD/MM/YYYY"
                    disableFuture={true}
                    minDate={minDate}
                    value={filterStartDate}
                    onChange={(date) => setFilterStartDate(date)}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <br></br>
                  <br></br>
                  <DatePicker
                    label="FECHA FINAL"
                    format="DD/MM/YYYY"
                    disableFuture={true}
                    minDate={minDate}
                    value={filterEndDate}
                    onChange={(date) => setFilterEndDate(date)}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </LocalizationProvider>
              </div>
            </Group>
          </Group>
        </Container>
        <br></br>
        <Divider />
        <Group>
          <Button
            style={{
              marginTop: '20px',
              float: 'right',
              backgroundColor: 'rgb(13, 71, 161)',
              marginLeft: '5px',
            }}
            onClick={() => FilterSales(0, 10, true)}
          >
            Filtrar
          </Button>
          <Button
            style={{
              marginTop: '20px',
              float: 'right',
              backgroundColor: '#EEEEEE',
              color: 'black',
            }}
            onClick={resetFilters}
          >
            Restablecer
          </Button>
        </Group>
      </AccordionDetails>
    </Accordion>
  )
}
