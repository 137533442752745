import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { apilUrl } from '../../services/url'
import {
  Edit,
  QuestionMark,
  CirclePlus,
  History,
  Upload,
} from 'tabler-icons-react'
import LoadingScreen from '../../components/main/loadingScreen'
import {
  validateEmail,
  setDecimal,
  isEmpty,
  BootstrapTooltip,
} from '../../services/utils'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { HistoricalModal } from '../../components/client/historical/historicalModal'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import {
  TextField,
  Select as MuiSelect,
  CircularProgress,
  Pagination,
} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import ReactJoyride from 'react-joyride'
import ClientsMenu from '../../components/client/shortcutMenus/clientsMenus'
import CustomSelect from '../../components/custom/customSelect'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import calcularDigitoVerificacion from '../../services/calcularDigitoVerificacion'
import { updateClientHistoricalBalanceApi } from '../api/balancehistorical'
import {
  PostThirdClientsApi,
  UpdateThirdClientsApi,
  getThirdClientsByCompanyApi,
} from '../api/thirdClients'
import ThirdClientsUploadModal from '../../components/client/modals/thirdClientsUploadModal'
import ActionButton from '../../components/custom/actionButton'

export default function Clients() {
  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  //Clients Users
  const [clients, setClients] = useState([])
  const [clientsCount, setClientsCount] = useState(0)

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  const [selectedOption, setSelectedOption] = useState('')

  const options = [
    { value: 5, label: 'ANTIOQUIA' },
    { value: 8, label: 'ATLÁNTICO' },
    { value: 11, label: 'BOGOTÁ, D.C.' },
    { value: 13, label: 'BOLÍVAR' },
    { value: 15, label: 'BOYACÁ' },
    { value: 17, label: 'CALDAS' },
    { value: 18, label: 'CAQUETÁ' },
    { value: 19, label: 'CAUCA' },
    { value: 20, label: 'CESAR' },
    { value: 23, label: 'CÓRDOBA' },
    { value: 25, label: 'CUNDINAMARCA' },
    { value: 27, label: 'CHOCÓ' },
    { value: 41, label: 'HUILA' },
    { value: 44, label: 'LA GUAJIRA' },
    { value: 47, label: 'MAGDALENA' },
    { value: 50, label: 'META' },
    { value: 52, label: 'NARIÑO' },
    { value: 54, label: 'NORTE DE SANTANDER' },
    { value: 63, label: 'QUINDIO' },
    { value: 66, label: 'RISARALDA' },
    { value: 68, label: 'SANTANDER' },
    { value: 70, label: 'SUCRE' },
    { value: 73, label: 'TOLIMA' },
    { value: 76, label: 'VALLE DEL CAUCA' },
    { value: 81, label: 'ARAUCA' },
    { value: 85, label: 'CASANARE' },
    { value: 86, label: 'PUTUMAYO' },
    {
      value: 88,
      label: 'ARCHIPIÉLAGO DE SAN ANDRÉS, PROVIDENCIA Y SANTA CATALINA',
    },
    { value: 91, label: 'AMAZONAS' },
    { value: 94, label: 'GUAINÍA' },
    { value: 95, label: 'GUAVIARE' },
    { value: 97, label: 'VAUPÉS' },
    { value: 99, label: 'VICHADA' },
  ]

  const [municipiosOptions, setMunicipiosOptions] = React.useState([])

  const [selectedMunicipiosOptions, setSelectedMunicipiosOptions] = useState('')

  const [isThereData, setIsThereData] = useState(false)

  const form = useForm({
    initialValues: {
      thirdClientCompanyName: '',
      nit: '',
      dv: '',
      email: '',
      sendEmail: false,
      phone: '',
      party_type: '',
      tax_level_code: 0,
      regimen: '',
      department: '',
      municipalitie: '',
      idDepartment: '',
      cityCode: '',
      address_line: '',
      first_name: '',
      family_name: '',
      discount: 0,
      credit: 0,
      balance: 0,
      vatliability: 0,
      mainEconomicActivity: '', //provisional
      isAuthorized: true, //provisional
      isRumbo: false,
    },
  })

  const putForm = useForm({
    initialValues: {
      clientiId: '',
      thirdClientCompanyName: '',
      nit: '',
      dv: '',
      discount: '',
      credit: '',
      debt: '',
      balance: '',
      sendEmail: false,
      email: '',
      phone: '',
      party_type: '',
      tax_level_code: 0,
      regimen: '',
      department: '',
      municipalitie: '',
      idDepartment: '',
      cityCode: '',
      address_line: '',
      first_name: '',
      family_name: '',
      vatliability: 0,
      mainEconomicActivity: '', //provisional
      isRumbo: false,
    },
  })

  const getMunicipios = async (municipio) => {
    setSelectedOption(municipio)
    setSelectedMunicipiosOptions('')
    try {
      let result = await fetch(
        `${apilUrl}/municipios/filters/${municipio.value}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      setMunicipiosOptions(result.data)
    } catch (error) {
      return error
    }
  }

  const resetConstants = () => {
    form.setFieldValue('thirdClientCompanyName', '')
    form.setFieldValue('nit', '')
    form.setFieldValue('dv', '')
    form.setFieldValue('email', '')
    form.setFieldValue('sendEmail', false)
    form.setFieldValue('phone', '')
    form.setFieldValue('party_type', '')
    form.setFieldValue('tax_level_code', 0)
    form.setFieldValue('regimen', '')
    form.setFieldValue('department', '')
    form.setFieldValue('municipalitie', '')
    form.setFieldValue('idDepartment', '')
    form.setFieldValue('cityCode', '')
    form.setFieldValue('address_line', '')
    form.setFieldValue('first_name', '')
    form.setFieldValue('family_name', '')
    form.setFieldValue('discount', 0)
    form.setFieldValue('credit', 0)
    form.setFieldValue('balance', 0)
    form.setFieldValue('vatliability', 0)
    form.setFieldValue('mainEconomicActivity', '')
    form.setFieldValue('isAuthorized', true) //provisional
    form.setFieldValue('isRumbo', false)
    setSelectedOption('')
    setSelectedMunicipiosOptions('')
  }

  const successCreation = async (data) => {
    if (data == 'ThirdClients created') {
      setIsThereData(false)
      notify('success', 'Creación Exitoso')
      await getClients(tokenData.companyId, pg * rpg - rpg, rpg)
      setModalCreateShow(false)
      resetConstants()
    } else {
      notify('warning', 'El usuario ya se encuentra registrado')
    }
    setIsCreateButtonDisabled(false)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createClient = async () => {
    let readyToCreate = true

    if (form.values.thirdClientCompanyName == '') {
      readyToCreate = false
      notify('warning', 'Falta el nombre de la empresa.')
    }
    if (form.values.nit == '') {
      readyToCreate = false
      notify('warning', 'Falta el NIT.')
    }
    if (form.values.discount < 0) {
      readyToCreate = false
      notify('warning', 'El descuento no puede ser menor a cero.')
    }
    if (!validateEmail(form.values.email)) {
      readyToCreate = false
      notify('warning', 'Verifique el correo electrónico.')
    }
    if (form.values.phone == '') {
      readyToCreate = false
      notify('warning', 'Falta el número telefónico')
    }

    if (form.values.address_line == '') {
      readyToCreate = false
      notify('warning', 'Falta la dirección')
    }

    if (readyToCreate) {
      setIsCreateButtonDisabled(true)
      let clientsData = {
        companyId: tokenData.companyId,
        branchId: tokenData.branchId,
        thirdClientCompanyName: form.values.thirdClientCompanyName,
        nit: form.values.nit,
        email: form.values.email,
        sendEmail: form.values.sendEmail,
        phone: form.values.phone,
        party_type: form.values.party_type,
        tax_level_code: form.values.tax_level_code,
        department: selectedOption.label,
        municipalitie: selectedMunicipiosOptions.label,
        idDepartment: selectedOption.value.toString(),
        cityCode: selectedMunicipiosOptions.codigo_ciudad,
        address_line: form.values.address_line,
        first_name: form.values.first_name,
        family_name: form.values.family_name,
        discount: parseInt(form.values.discount),
        clientUserId: tokenData.clientId,
        credit: parseInt(form.values.credit),
        debt: 0,
        balance: parseInt(form.values.balance),
        vatliability: parseInt(form.values.vatliability),
        mainEconomicActivity: form.values.mainEconomicActivity,
        isAuthorized: form.values.isAuthorized,
        isRumbo: form.values.isRumbo,
      }
      let result = await PostThirdClientsApi(clientsData)
      if (result.data != null) successCreation(result.data)
      else notify('warning', 'Error al crear el cliente.')
    }
  }

  const getClients = async (companyId, offset, limit) => {
    let result = await getThirdClientsByCompanyApi(companyId, offset, limit)
    if (result.data != null) {
      setClients(result.data)
      setClientsCount(result.count)
    } else setIsThereData(true)
  }

  //----------third clients----------------
  const [thirdClientsOption, setThirdClientOption] = React.useState([])

  const getThirdClients = async (companyId) => {
    let result = await getThirdClientsByCompanyApi(companyId)
    if (result.data != null) setThirdClientsOptions(result.data)
    else notify('warning', 'No hay clientes registrados')
  }

  const setThirdClientsOptions = (thirdClients) => {
    if (thirdClients.length > 0) {
      let thirdClientsSelect = []
      thirdClientsSelect.push({ value: 0, label: 'TODAS LAS EMPRESAS' })
      thirdClients.map((x) => {
        thirdClientsSelect.push({
          value: x.id,
          label: x.nit + ' - ' + x.thirdClientCompanyName,
        })
      })
      setThirdClientOption(thirdClientsSelect)
    } else {
      setThirdClientOption([])
      notify('warning', 'No hay empresas registradas.')
    }
  }

  const [wasCredit, setWasCredit] = useState(0)
  const [wasDebt, setWasDebt] = useState(0)
  const [wasBalance, setWasBalance] = useState(0)

  const setDatasToUpdate = async (
    id,
    thirdClientCompanyName,
    nit,
    dv,
    discount,
    credit,
    debt,
    balance,
    sendEmail,
    email,
    phone,
    party_type,
    tax_level_code,
    regimen,
    department,
    municipalitie,
    idDepartment,
    cityCode,
    address_line,
    first_name,
    family_name,
    vatliability,
    mainEconomicActivity,
    isRumbo,
  ) => {
    putForm.setFieldValue('clientId', id)
    putForm.setFieldValue('dv', dv)
    putForm.setFieldValue('thirdClientCompanyName', thirdClientCompanyName)
    putForm.setFieldValue('nit', nit)
    putForm.setFieldValue('discount', discount)
    putForm.setFieldValue('credit', credit)
    putForm.setFieldValue('debt', debt)
    putForm.setFieldValue('balance', balance)
    putForm.setFieldValue('sendEmail', sendEmail)
    putForm.setFieldValue('email', email)
    putForm.setFieldValue('phone', phone)
    putForm.setFieldValue('party_type', party_type)
    putForm.setFieldValue('tax_level_code', parseInt(tax_level_code))
    putForm.setFieldValue('regimen', regimen)
    putForm.setFieldValue('department', department)
    putForm.setFieldValue('municipalitie', municipalitie)
    putForm.setFieldValue('idDepartment', idDepartment)
    putForm.setFieldValue('cityCode', cityCode)
    putForm.setFieldValue('address_line', address_line)
    putForm.setFieldValue('first_name', first_name)
    putForm.setFieldValue('family_name', family_name)
    putForm.setFieldValue('vatliability', vatliability)
    putForm.setFieldValue('mainEconomicActivity', mainEconomicActivity)
    putForm.setFieldValue('isRumbo', isRumbo)
    setWasCredit(credit)
    setWasDebt(debt)
    setWasBalance(balance)
    getMunicipios({ value: parseInt(idDepartment), label: department })
    setSelectedMunicipiosOptions({
      value: 0, //value 0 para renderizar selects
      label: municipalitie,
      codigo_ciudad: cityCode,
    })
    setModalPutShow(true)
  }

  const tokenVerified = async () => {
    //CHECK ROLES

    if (!rolesData.clients) {
      notify('warning', 'No tienes permiso para ver este módulo.')
      navigate('/dashboard')
    }
    await getThirdClients(tokenData.companyId)
    await getClients(tokenData.companyId, pg * rpg - rpg, rpg)
  }

  //-------------Credithistorical----------------

  const [show, setShow] = useState(false)
  const handleCloseHistorical = () => setShow(false)
  const [thirdClientName, setThirdClientName] = useState('')
  const [thirdClientId, setThirdClientId] = useState(0)
  const handleShowHistorical = async (
    thirdClientId,
    thirdClientCompanyName,
  ) => {
    setThirdClientName(thirdClientCompanyName)
    setThirdClientId(thirdClientId)
    setShow(true)
  }

  //-----------------------------------------

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const updateClient = async () => {
    let readyToUpdate = true
    if (putForm.values.thirdClientCompanyName == '') {
      readyToUpdate = false
      notify('warning', 'Falta el nombre de la empresa.')
    }
    if (!validateEmail(putForm.values.email)) {
      readyToUpdate = false
      notify('warning', 'Verifique el correo electrónico.')
    }
    if (putForm.values.nit == '' || putForm.values.nit.length <= 6) {
      readyToUpdate = false
      notify('warning', 'Falta el NIT.')
    }
    if (putForm.values.discount < 0) {
      readyToUpdate = false
      notify('warning', 'El descuento no puede ser menor a cero.')
    }
    if (putForm.values.credit < 0) {
      readyToUpdate = false
      notify('warning', 'El crédito no puede ser menor a cero.')
    }
    if (parseInt(putForm.values.debt) < 0) {
      readyToUpdate = false
      notify('warning', 'La deuda no puede ser menor a cero.')
    }
    if (parseInt(putForm.values.debt) > parseInt(putForm.values.credit)) {
      readyToUpdate = false
      notify('warning', 'La deuda no puede ser mayor al cupo de crédito.')
    }
    if (parseInt(putForm.values.phone.length) < 7) {
      readyToUpdate = false
      notify('warning', 'El número telefónico debe tener mínimo 7 dígitos.')
    }
    if (selectedMunicipiosOptions == '') {
      readyToUpdate = false
      notify('warning', 'Debe seleccionar un municipio.')
    }

    if (putForm.values.address_line == '') {
      readyToUpdate = false
      notify('warning', 'Debe ingresar una dirección.')
    }

    if (readyToUpdate) {
      setIsUpdateButtonDisabled(true)
      let clientData = {
        companyId: tokenData.companyId,
        branchId: tokenData.branchId,
        thirdClientCompanyName: putForm.values.thirdClientCompanyName,
        nit: putForm.values.nit,
        dv: calcularDigitoVerificacion(putForm.values.nit),
        email: putForm.values.email,
        sendEmail: putForm.values.sendEmail,
        phone: putForm.values.phone,
        party_type: putForm.values.party_type,
        tax_level_code: putForm.values.tax_level_code,
        department: selectedOption.label,
        municipalitie: selectedMunicipiosOptions.label,
        idDepartment: selectedOption.value.toString(),
        cityCode: selectedMunicipiosOptions.codigo_ciudad,
        address_line: putForm.values.address_line,
        first_name: putForm.values.first_name,
        family_name: putForm.values.family_name,
        discount: parseInt(putForm.values.discount),
        clientUserId: tokenData.clientId,
        credit: parseInt(putForm.values.credit),
        debt: 0,
        balance: parseInt(putForm.values.balance),
        vatliability: parseInt(putForm.values.vatliability),
        mainEconomicActivity: putForm.values.mainEconomicActivity,
        isRumbo: putForm.values.isRumbo,
      }
      let result = await UpdateThirdClientsApi(
        putForm.values.clientId,
        clientData,
      )
      if (result.data == 'ThirdClients updated') {
        if (
          wasCredit != putForm.values.credit ||
          wasDebt != putForm.values.debt
        )
          await updateClientHistoricalCredit(
            putForm.values.credit,
            putForm.values.debt,
          )
        await updateClientHistoricalBalance(putForm.values.balance)
        notify('success', 'Actualización Exitosa')
        setModalPutShow(false)
        await getClients(tokenData.companyId, pg * rpg - rpg, rpg)
      } else {
        notify('warning', 'Error al actualizar el cliente.')
      }
    }
    setIsUpdateButtonDisabled(false)
  }

  const updateClientHistoricalCredit = async (credit, debt) => {
    try {
      let clientHistoricalData = {
        clientUserId: tokenData.clientId,
        deleted: false,
        modifiedBy: tokenData.clientName,
        newCredit: parseInt(credit),
        newDebt: parseInt(debt),
        thirdClientsId: parseInt(putForm.values.clientId),
        wasCredit: wasCredit,
        wasDebt: wasDebt,
      }
      await fetch(`${apilUrl}/credithistorical`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientHistoricalData),
      }).then((response) => response.json())
    } catch (error) {
      return error
    }
  }

  const updateClientHistoricalBalance = async (balance) => {
    let clientHistoricalData = {
      clientUserId: tokenData.clientId,
      deleted: false,
      modifiedBy: tokenData.clientName,
      newBalance: parseInt(balance),
      thirdClientsId: parseInt(putForm.values.clientId),
      wasBalance: wasBalance,
    }
    await updateClientHistoricalBalanceApi(clientHistoricalData)
  }

  //----------------------Filter third-clients-----------------

  const [selectedThirdClientId, setSelectedThirdClientId] = useState(0)
  const [selectedThirdClientName, setSelectedThirdClientName] =
    useState('TODAS LAS EMPRESAS')

  const handleClientSelected = (thirdClient) => {
    setSelectedThirdClientId(thirdClient.value)
    setSelectedThirdClientName(thirdClient.label)
    if (thirdClient.value == 0) getClients(tokenData.companyId, 0, rpg)
    getThirdClientById(thirdClient.value)
  }

  const getThirdClientById = async (thirdClientId) => {
    let result = await getThirdClientsByCompanyApi(
      tokenData.companyId,
      pg * rpg - rpg,
      rpg,
      thirdClientId,
    )
    if (result.data != null) {
      setClients(result.data)
      setClientsCount(result.count)
    } else {
      notify('warning', 'No se encontraron resultados.')
    }
  }

  //--------------------upload third-clients-----------------

  const [showUploadModal, setShowUploadModal] = useState(false)
  const handleShowUploadModal = () => setShowUploadModal(true)
  const handleCloseUpload = () => {
    setShowUploadModal(false)
    reloadClients()
  }

  const reloadClients = async () => {
    await getClients(tokenData.companyId, pg * rpg - rpg, rpg)
  }

  //-----------------Pagination-------------------
  const [pg, setpg] = useState(1) //page
  const [rpg, setrpg] = useState(10) //rows per page

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getClients(tokenData.companyId, newOffset, rpg)
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    await getClients(tokenData.companyId, 0, event.target.value)
  }

  //---------------------- Joyride -----------------------
  const [runJoyride, setRunJoyride] = useState(false)
  const [joyrideSteps] = useState([
    {
      content:
        'Este es el módulo de clientes, en el cual podrás editar las distintas empresas de terceros.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      placement: 'center',
      target: 'body',
      title: 'Clientes',
    },
    {
      content:
        'Aquí podrás crear un nuevo cliente, donde llenarás la información necesaria para añadirlo a la lista de empresas de terceros.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      spotlightPadding: 20,
      target: '.createClient_button',
      title: 'Creación de cliente',
    },
    {
      content:
        'Aquí puedes ver el listado de clientes con su información de negocio, como son el valor del descuento, del crédito y del saldo a favor.',
      placement: 'bottom',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.clientTable',
      title: 'Información del cliente',
    },

    {
      content: (
        <div>
          Aquí tienes las opciones para el cliente, como son modificar sus datos
          de negocio con <Edit /> y revisar el historial de cambios de estos
          datos con <History />.
        </div>
      ),
      target: '.clientActions',
      title: 'Acciones para el cliente',
      placement: 'top',
      locale: {
        skip: 'Saltar',
        next: 'Siguiente',
        back: 'Atrás',
        last: 'Terminar',
      },
    },
  ])

  const handleJoyrideCallback = (CallBackProps) => {
    const status = CallBackProps.status
    //const type = CallBackProps.type

    if (status == 'ready') {
      setRunJoyride(false)
    }
  }

  //----------------------------------

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <Button
            onClick={() => setRunJoyride(true)}
            style={{ float: 'right', background: 'gray', border: 'none' }}
          >
            <QuestionMark /> Ayuda
          </Button>
          <ReactJoyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={runJoyride}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={joyrideSteps}
            styles={{
              options: {
                zIndex: 20,
                primaryColor: 'green',
              },
            }}
          />
          <ClientsMenu buttonId={0} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              float: 'right',
              marginBottom: 10,
              marginRight: 5,
              isolation: 'isolate',
            }}
            onClick={handleShowUploadModal}
          >
            <Upload size={20} color="#FFFFFF" /> Carga Masiva
          </Button>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
            className="createClient_button"
          >
            Nuevo Cliente <CirclePlus size={20} color="#FFFFFF" />
          </Button>
          <br></br>
          <br></br>
          <MDBTable small className="clientTable">
            <MDBTableHead>
              <tr
                style={{
                  textAlign: 'center',
                }}
              >
                <th>
                  <CustomSelect
                    title="Cliente"
                    placeholder="Cliente"
                    value={{
                      value: selectedThirdClientId,
                      label: selectedThirdClientName,
                    }}
                    onChange={(e) => handleClientSelected(e)}
                    options={thirdClientsOption}
                    theme={isDarkModeActive ? 'black' : 'white'}
                    minWidth={300}
                  />
                </th>
                <th>Nit</th>
                <th>Descuento</th>
                <th>Crédito</th>
                <th>Deuda</th>
                <th>Saldo</th>
                <th>Fecha de creación</th>
                <th className="clientActions">Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {isThereData
                ? null
                : clients.map((clients, index) => {
                    return (
                      <tr
                        style={{
                          textAlign: 'center',
                        }}
                        key={index}
                      >
                        <td>{clients.thirdClientCompanyName}</td>
                        <td>
                          {clients.nit}-
                          {calcularDigitoVerificacion(clients.nit)}
                        </td>
                        <td>{clients.discount}%</td>
                        <td>${setDecimal(clients.credit)}</td>
                        <td>${setDecimal(clients.debt)}</td>
                        <td>${setDecimal(clients.balance)}</td>
                        <td>{clients.createdAt}</td>
                        <td>
                          <ActionButton
                            description="Historial de cambios"
                            backgroundColor={bgColor}
                            onClick={() => {
                              handleShowHistorical(
                                clients.id,
                                clients.thirdClientCompanyName,
                              )
                            }}
                          >
                            <History size={30} color={color} />
                          </ActionButton>
                          <ActionButton
                            description="Editar"
                            backgroundColor={bgColor}
                            onClick={() =>
                              setDatasToUpdate(
                                clients.id,
                                clients.thirdClientCompanyName,
                                clients.nit,
                                clients.dv,
                                clients.discount,
                                clients.credit,
                                clients.debt,
                                clients.balance,
                                clients.sendEmail,
                                clients.email,
                                clients.phone,
                                clients.party_type,
                                clients.tax_level_code,
                                clients.regimen,
                                clients.department,
                                clients.municipalitie,
                                clients.idDepartment,
                                clients.cityCode,
                                clients.address_line,
                                clients.first_name,
                                clients.family_name,
                                clients.vatliability,
                                clients.mainEconomicActivity,
                                clients.isRumbo,
                              )
                            }
                          >
                            <Edit size={30} color={color} />
                          </ActionButton>
                        </td>
                      </tr>
                    )
                  })}
            </MDBTableBody>
          </MDBTable>
          <Container>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Filas
              </InputLabel>
              <MuiSelect
                labelId="demo-simple-select-standard-label"
                autoWidth={true}
                value={rpg}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </MuiSelect>
            </FormControl>
            <Pagination
              count={Math.ceil(clientsCount / rpg)}
              page={pg}
              onChange={handleChangePage}
              showFirstButton={true}
              showLastButton={true}
              style={{ display: 'inline-block' }}
            />
          </Container>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      {show ? (
        <HistoricalModal
          show={show}
          thirdClientCompanyName={thirdClientName}
          thirdClientId={thirdClientId}
          onClose={handleCloseHistorical}
        />
      ) : null}
      {showUploadModal ? (
        <ThirdClientsUploadModal
          show={showUploadModal}
          onClose={handleCloseUpload}
        />
      ) : null}
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nuevo Cliente
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Empresa"
                      placeholder="Empresa"
                      onChange={(event) => {
                        event.currentTarget.value.length > 0
                          ? form.setFieldValue(
                              'thirdClientCompanyName',
                              event.currentTarget.value,
                            )
                          : form.setFieldValue('thirdClientCompanyName', '')
                      }}
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        TIPO DE PERSONA
                      </InputLabel>
                      <MuiSelect
                        label="TIPO DE PERSONA"
                        onChange={(e) => {
                          form.setFieldValue('party_type', e.target.value)
                          if (e.target.value == 1) form.setFieldValue('dv', '')
                        }}
                        value={form.values.party_type}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>Persona Natural</MenuItem>
                        <MenuItem value={2}>Persona Jurídica</MenuItem>
                      </MuiSelect>
                    </FormControl>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Responsabilidad del IVA
                      </InputLabel>
                      <MuiSelect
                        label="Responsabilidad del IVA"
                        onChange={(e) =>
                          form.setFieldValue('vatliability', e.target.value)
                        }
                        value={form.values.vatliability}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>No Responsable de IVA</MenuItem>
                        <MenuItem value={2}>Responsable de IVA</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Nit"
                      placeholder="Nit"
                      onChange={(event) => {
                        form.setFieldValue('nit', event.currentTarget.value)
                      }}
                      value={form.values.nit}
                    />
                    <div style={{ display: 'flex' }}>
                      <TextField
                        disabled={true}
                        variant="standard"
                        type="number"
                        label="DV"
                        placeholder="DV"
                        value={calcularDigitoVerificacion(form.values.nit)}
                      />
                      <BootstrapTooltip
                        disableFocusListener
                        title="Calculamos el dígito de verificación por ti"
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          DV
                          <div
                            style={{
                              height: '20px',
                              width: '20px',
                              backgroundColor: 'transparent',
                              borderRadius: '50%',
                              display: 'inline-block',
                              textAlign: 'center',
                              border: `2px solid ${color}`,
                              isolation: 'isolate',
                            }}
                          >
                            <QuestionMark
                              size={15}
                              style={{ marginBottom: '10px' }}
                              color={color}
                            ></QuestionMark>
                          </div>
                        </div>
                      </BootstrapTooltip>
                    </div>
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      label="ACTIVIDAD ECONÓMICA PRINCIPAL"
                      placeholder="ACTIVIDAD ECONÓMICA PRINCIPAL"
                      value={form.values.mainEconomicActivity}
                      onChange={(event) =>
                        form.setFieldValue(
                          'mainEconomicActivity',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        RESPONSABILIDAD FISCAL
                      </InputLabel>
                      <MuiSelect
                        label="RESPONSABILIDAD FISCAL"
                        onChange={(e) =>
                          form.setFieldValue('tax_level_code', e.target.value)
                        }
                        value={form.values.tax_level_code}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>No responsable (R-99-PN)</MenuItem>
                        <MenuItem value={2}>
                          Régimen simple de tributación (O-47)
                        </MenuItem>
                        <MenuItem value={3}>Gran Contribuyente (O-13)</MenuItem>
                        <MenuItem value={4}>Autorretenedor (O-15)</MenuItem>
                        <MenuItem value={5}>
                          Agente de retención (O-23)
                        </MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Nombres"
                      placeholder="Nombres"
                      value={form.values.first_name}
                      onChange={(event) =>
                        form.setFieldValue(
                          'first_name',
                          event.currentTarget.value,
                        )
                      }
                    />

                    <TextField
                      variant="standard"
                      required
                      label="Apellidos"
                      placeholder="Apellidos"
                      value={form.values.family_name}
                      onChange={(event) =>
                        form.setFieldValue(
                          'family_name',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Correo"
                      placeholder="Correo"
                      type="email"
                      onChange={(event) => {
                        form.setFieldValue('email', event.currentTarget.value)
                      }}
                      value={form.values.email}
                    />

                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Teléfono"
                      placeholder="Teléfono"
                      onChange={(event) => {
                        form.setFieldValue('phone', event.currentTarget.value)
                      }}
                      value={form.values.phone}
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Crédito"
                      placeholder="Crédito"
                      onChange={(event) => {
                        0 <= event.currentTarget.value.length
                          ? form.setFieldValue(
                              'credit',
                              event.currentTarget.value,
                            )
                          : form.setFieldValue('credit', 0)
                      }}
                      value={form.values.credit}
                    />
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Saldo"
                      placeholder="Saldo"
                      onChange={(event) => {
                        0 <= event.currentTarget.value.length
                          ? form.setFieldValue(
                              'balance',
                              event.currentTarget.value,
                            )
                          : form.setFieldValue('balance', 0)
                      }}
                      value={form.values.balance}
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Descuento"
                      placeholder="Descuento"
                      value={form.values.discount}
                      type="number"
                      onChange={(event) =>
                        form.setFieldValue(
                          'discount',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Dirección"
                      placeholder="Dirección"
                      onChange={(event) =>
                        form.setFieldValue(
                          'address_line',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Facturar al correo electrónico"
                        checked={form.values.sendEmail}
                        onChange={(event) =>
                          form.setFieldValue(
                            'sendEmail',
                            event.currentTarget.checked,
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Cliente Rumbos"
                        checked={form.values.isRumbo}
                        onChange={(event) =>
                          form.setFieldValue(
                            'isRumbo',
                            event.currentTarget.checked,
                          )
                        }
                      />
                    </FormGroup>
                  </Group>
                  <CustomSelect
                    title="Departamento"
                    placeholder="Departamento"
                    defaultValue={selectedOption}
                    onChange={getMunicipios}
                    options={options}
                    value={selectedOption}
                    theme={isDarkModeActive ? 'black' : 'white'}
                  />
                  <CustomSelect
                    title="Municipio"
                    placeholder="Municipio"
                    defaultValue={selectedMunicipiosOptions}
                    onChange={setSelectedMunicipiosOptions}
                    options={municipiosOptions}
                    value={selectedMunicipiosOptions}
                    theme={isDarkModeActive ? 'black' : 'white'}
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createClient()}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => {
                  setModalCreateShow(false)
                  resetConstants()
                }}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando cliente
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Empresa"
                      placeholder="Empresa"
                      value={putForm.values.thirdClientCompanyName}
                      onChange={(event) => {
                        putForm.setFieldValue(
                          'thirdClientCompanyName',
                          event.currentTarget.value,
                        )
                      }}
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        TIPO DE PERSONA
                      </InputLabel>
                      <MuiSelect
                        label="TIPO DE PERSONA"
                        onChange={(e) => {
                          putForm.setFieldValue('party_type', e.target.value)
                          if (e.target.value == 1)
                            putForm.setFieldValue('dv', '')
                        }}
                        value={putForm.values.party_type}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>Persona Natural</MenuItem>
                        <MenuItem value={2}>Persona Jurídica</MenuItem>
                      </MuiSelect>
                    </FormControl>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Responsabilidad del IVA
                      </InputLabel>
                      <MuiSelect
                        label="Responsabilidad del IVA"
                        onChange={(e) =>
                          putForm.setFieldValue('vatliability', e.target.value)
                        }
                        value={putForm.values.vatliability}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>No Responsable de IVA</MenuItem>
                        <MenuItem value={2}>Responsable de IVA</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Nit"
                      placeholder="Nit"
                      value={putForm.values.nit}
                      onChange={(event) => {
                        putForm.setFieldValue('nit', event.currentTarget.value)
                      }}
                    />
                    <div style={{ display: 'flex' }}>
                      <TextField
                        disabled={true}
                        variant="standard"
                        required
                        type="number"
                        label="DV"
                        placeholder="DV"
                        value={calcularDigitoVerificacion(putForm.values.nit)}
                      />
                      <BootstrapTooltip
                        disableFocusListener
                        title="Calculamos el dígito de verificación por ti"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          DV
                          <div
                            style={{
                              height: '20px',
                              width: '20px',
                              backgroundColor: 'transparent',
                              borderRadius: '50%',
                              display: 'inline-block',
                              textAlign: 'center',
                              border: `2px solid ${color}`,
                              isolation: 'isolate',
                            }}
                          >
                            <QuestionMark
                              size={15}
                              style={{ marginBottom: '10px' }}
                              color={color}
                            ></QuestionMark>
                          </div>
                        </div>
                      </BootstrapTooltip>
                    </div>
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="ACTIVIDAD ECONÓMICA PRINCIPAL"
                      placeholder="ACTIVIDAD ECONÓMICA PRINCIPAL"
                      value={putForm.values.mainEconomicActivity}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'mainEconomicActivity',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        RESPONSABILIDAD FISCAL
                      </InputLabel>
                      <MuiSelect
                        label="RESPONSABILIDAD FISCAL"
                        onChange={(e) =>
                          putForm.setFieldValue(
                            'tax_level_code',
                            e.target.value,
                          )
                        }
                        value={putForm.values.tax_level_code}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>No responsable (R-99-PN)</MenuItem>
                        <MenuItem value={2}>
                          Régimen simple de tributación (O-47)
                        </MenuItem>
                        <MenuItem value={3}>Gran Contribuyente (O-13)</MenuItem>
                        <MenuItem value={4}>Autorretenedor (O-15)</MenuItem>
                        <MenuItem value={5}>
                          Agente de retención (O-23)
                        </MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Nombres"
                      placeholder="Nombres"
                      value={putForm.values.first_name}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'first_name',
                          event.currentTarget.value,
                        )
                      }
                    />

                    <TextField
                      variant="standard"
                      required
                      label="Apellidos"
                      placeholder="Apellidos"
                      value={putForm.values.family_name}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'family_name',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Correo"
                      placeholder="Correo"
                      type="email"
                      value={putForm.values.email}
                      onChange={(event) => {
                        putForm.setFieldValue(
                          'email',
                          event.currentTarget.value,
                        )
                      }}
                    />

                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Teléfono"
                      placeholder="Teléfono"
                      value={putForm.values.phone}
                      onChange={(event) => {
                        putForm.setFieldValue(
                          'phone',
                          event.currentTarget.value,
                        )
                      }}
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Crédito"
                      placeholder="Crédito"
                      onChange={(event) => {
                        0 <= event.currentTarget.value.length
                          ? putForm.setFieldValue(
                              'credit',
                              event.currentTarget.value,
                            )
                          : putForm.setFieldValue('credit', 0)
                      }}
                      value={putForm.values.credit}
                    />
                    <TextField
                      variant="standard"
                      required
                      type="number"
                      label="Saldo"
                      placeholder="Saldo"
                      onChange={(event) => {
                        0 <= event.currentTarget.value.length
                          ? putForm.setFieldValue(
                              'balance',
                              event.currentTarget.value,
                            )
                          : putForm.setFieldValue('balance', 0)
                      }}
                      value={putForm.values.balance}
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Descuento"
                      placeholder="Descuento"
                      value={putForm.values.discount}
                      type="number"
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'discount',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <TextField
                      variant="standard"
                      required
                      label="Dirección"
                      placeholder="Dirección"
                      value={putForm.values.address_line}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'address_line',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                  <Group grow mb="md" mt="md">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Facturar al correo electrónico"
                        checked={putForm.values.sendEmail}
                        onChange={(event) =>
                          putForm.setFieldValue(
                            'sendEmail',
                            event.currentTarget.checked,
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Cliente Rumbos"
                        checked={putForm.values.isRumbo}
                        onChange={(event) =>
                          putForm.setFieldValue(
                            'isRumbo',
                            event.currentTarget.checked,
                          )
                        }
                      />
                    </FormGroup>
                  </Group>
                  <CustomSelect
                    title="Departamento"
                    placeholder="Departamento"
                    defaultValue={selectedOption}
                    onChange={getMunicipios}
                    options={options}
                    value={selectedOption}
                    theme={isDarkModeActive ? 'black' : 'white'}
                  />
                  <CustomSelect
                    title="Municipio"
                    placeholder="Municipio"
                    defaultValue={selectedMunicipiosOptions}
                    onChange={setSelectedMunicipiosOptions}
                    options={municipiosOptions}
                    value={selectedMunicipiosOptions}
                    theme={isDarkModeActive ? 'black' : 'white'}
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateClient()}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
