import { Group, Text } from '@mantine/core'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  getMonthsToDisplay,
  getYearsToDisplay,
  monthFormat,
  notify,
} from '../../../services/utils'
import CreateSettlement from './createSettlement'
import { periodicityTypes } from '../../../services/selectData/clientUsersData'

const PayrollIntervals = ({
  clientUsers,
  branches,
  searchBranchById,
  getClientUsers,
}) => {
  const handleOpenCreateSettlementModal = () => {
    if (searchBranchById <= 0) {
      notify('warning', 'Por favor selecciona una sucursal')
    } else if (reportYear <= 0 || reportMonth <= 0) {
      notify('warning', 'Por favor selecciona un año y un mes')
    } else if (selectedInterval.value <= 0) {
      notify('warning', 'Por favor selecciona un periodo')
    } else setModalCreateSettlementShow(true)
  }

  const [reportYear, setReportYear] = useState(new Date().getFullYear())
  const [reportMonth, setReportMonth] = useState(new Date().getMonth() + 1)
  const [intervals, setIntervals] = useState([])

  const handleYearChange = async (date) => {
    setReportYear(date)
    setReportMonth(0)
  }

  const handleMonthChange = async (date) => {
    setReportMonth(date)
  }

  const [selectedInterval, setSelectedInterval] = useState({
    value: 0,
    start: 0,
    end: 0,
    month: 0,
    year: 0,
    label: '',
  })

  const handleSelectedInterval = (value) => {
    let interval = intervals.find((interval) => interval.value === value)
    setSelectedInterval(interval)
  }

  const getIntervalsByPeriodicity = (month, year) => {
    let daysInMonth = new Date(year, month, 0).getDate()
    let intervals = []
    let id = 0
    periodicityTypes.forEach((periodicity) => {
      let start = 0
      let end = 0
      let label = ''
      for (let i = 1; i <= daysInMonth; i++) {
        if (periodicity.days === 31) {
          id++
          end = daysInMonth
          label = `${start + 1} ${monthFormat(
            month,
            true,
          )} - ${end} ${monthFormat(month, true)} (${periodicity.label})`
          return intervals.push({
            value: id,
            start: start + 1,
            month: month,
            year: year,
            end: end,
            label: label,
          })
        }
        if (i % periodicity.days === 0) {
          id++
          end = i
          label = `${start + 1} ${monthFormat(
            month,
            true,
          )} - ${end} ${monthFormat(month, true)} (${periodicity.label})`
          start = i
          intervals.push({
            value: id,
            start: start + 1,
            month: month,
            year: year,
            end: end,
            label: label,
          })
        }
        if (daysInMonth - i < periodicity.days) {
          id++
          start = i
          end = daysInMonth
          label = `${start + 1} ${monthFormat(
            month,
            true,
          )} - ${end} ${monthFormat(month, true)} (${periodicity.label})`
          return intervals.push({
            value: id,
            start: start + 1,
            month: month,
            year: year,
            end: end,
            label: label,
          })
        }
      }
    })
    return intervals
  }

  useEffect(() => {
    setIntervals(getIntervalsByPeriodicity(reportMonth, reportYear))
  }, [reportMonth, reportYear])

  const [modalCreateSettlementShow, setModalCreateSettlementShow] =
    React.useState(false)

  return (
    <div>
      {modalCreateSettlementShow ? (
        <CreateSettlement
          show={modalCreateSettlementShow}
          onClose={() => setModalCreateSettlementShow(false)}
          clientUsers={clientUsers}
          branchId={searchBranchById}
          branches={branches}
          selectedInterval={selectedInterval}
          getClientUsers={getClientUsers}
        />
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '10px',
              textAlign: 'left',
              width: '50%',
              border: '1px solid gray',
              padding: '20px',
              borderRadius: '5px',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                float: 'left',
                marginBottom: '20px',
                color: 'gray',
                fontWeight: 'bold',
              }}
            >
              Liquidación de nómina por periodo
            </span>
            <Group grow>
              <FormControl variant="standard" sx={{ minWidth: 150 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  AÑO
                </InputLabel>
                <MuiSelect
                  label="Año"
                  value={reportYear}
                  onChange={(e) => handleYearChange(e.target.value)}
                >
                  {getYearsToDisplay().map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: 150 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  MES
                </InputLabel>
                <MuiSelect
                  label="Mes"
                  value={reportMonth}
                  onChange={(e) => handleMonthChange(e.target.value)}
                >
                  {getMonthsToDisplay(reportYear).map((month, index) => (
                    <MenuItem key={index} value={month.monthNumber}>
                      {month.monthName}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: 150 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Periodo
                </InputLabel>
                <MuiSelect
                  label="periodo"
                  onChange={(e) => {
                    handleSelectedInterval(e.target.value)
                  }}
                  value={selectedInterval.value}
                >
                  <MenuItem value={0} disabled />
                  {intervals.map((interval, index) => {
                    return (
                      <MenuItem value={interval.value} key={index}>
                        {interval.label}
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
              </FormControl>
            </Group>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                marginTop: 20,
              }}
            >
              <Button
                variant="primary"
                className="newUser_button"
                style={{
                  backgroundColor: 'green',
                  float: 'right',
                  marginLeft: 5,
                  marginTop: 20,
                  isolation: 'isolate',
                  width: 'fit-content',
                }}
                onClick={() => {}} // in progress
              >
                Nómina Grupal
              </Button>
              <Button
                variant="primary"
                className="newUser_button"
                style={{
                  backgroundColor: 'green',
                  float: 'right',
                  marginLeft: 5,
                  marginTop: 20,
                  isolation: 'isolate',
                  width: 'fit-content',
                }}
                onClick={() => handleOpenCreateSettlementModal()}
              >
                Nómina Individual
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PayrollIntervals
