import Button from 'react-bootstrap/Button'
import { read, utils } from 'xlsx'
import React, { useEffect, useState } from 'react'
import useStore from '../../../components/zustand'
import { notify } from '../../../services/utils'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { Trash } from 'tabler-icons-react'
import { CircularProgress } from '@mui/material'
import { Container } from 'react-bootstrap'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { PostBulkThirdClientsApi } from '../../../pages/api/thirdClients'

export default function ThirdClientsUploadModal(props) {
  const { show, onClose } = props
  const { tokenData, isDarkModeActive } = useStore()

  const [loadedthirdClients, setLoadedthirdClients] = useState([])

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const handleImport = ($event) => {
    const files = $event.target.files
    if (files.length) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = async (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames
        if (sheets.length) {
          try {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
            let loadedInfo = getLoadedInfo(rows)
            setLoadedthirdClients(loadedInfo)
          } catch (e) {
            notify(
              'error',
              'Verifique que el nombre de todas las columnas sea correcto.',
            )
          }
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const getLoadedInfo = (rows) => {
    let thirdClients = []
    rows.map((row) => {
      if (row['CONTACTO'].trim() != '')
        thirdClients.push({
          companyId: tokenData.companyId,
          branchId: tokenData.branchId,
          thirdClientCompanyName: row['CONTACTO'].trim(),
          nit: row['NIT'].trim(),
          email:
            row['CORREO'].trim() != ''
              ? row['CORREO'].trim()
              : 'avrilapp@generico.com',
          sendEmail: false,
          phone: row['TELEFONO'].trim(),
          party_type: 1, // Temporal
          tax_level_code: 1, // temporal
          department: row['DEPARTAMENTO'].toUpperCase().trim(),
          municipalitie: row['CIUDAD'].toUpperCase().trim(),
          idDepartment: row['MUNICIPIO'].substring(0, 2),
          cityCode: row['MUNICIPIO'].substring(2),
          address_line: row['DIRECCION'].trim(),
          first_name: row['RAZON SOCIAL'].trim(),
          family_name: '.', // quemado
          discount: 0,
          clientUserId: tokenData.clientId,
          credit: 0,
          debt: 0,
          balance: 0,
          vatliability: row['Clasificación Tributaria'] != 'S' ? 1 : 2, //QUE SIGNIFICA QUE SEA S o N ????? 1: Persona Natural, 2: Persona Jurídica
          mainEconomicActivity: '',
          isAuthorized: '',
        })
    })
    return thirdClients
  }

  const handleUpload = async () => {
    await uploadthirdClients(loadedthirdClients)
  }

  const uploadthirdClients = async (thirdClients) => {
    setIsCreateButtonDisabled(true)
    let result = await PostBulkThirdClientsApi(thirdClients)
    if (result.data != null) successCreation(result)
    else notify('warning', 'Error al subir los clientes masivamente.')
    setIsCreateButtonDisabled(false)
  }

  const successCreation = async (data) => {
    if (data.data == 'ThirdClients created') {
      notify('success', 'Clientes cargados con éxito.')
      onClose()
    } else {
      notify('warning', data.data)
    }
  }

  const deleteThirdClient = (index) => {
    let loadedthirdClientsAux = [...loadedthirdClients]
    loadedthirdClientsAux.splice(index, 1)
    setLoadedthirdClients(loadedthirdClientsAux)
  }

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size={loadedthirdClients.length == 0 ? 'lg' : 'xl'}>
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
            maxHeight: '55rem',
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Carga masiva de Clientes
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            {loadedthirdClients.length == 0 ? (
              <Container>
                <h4>Ahora, carga tu archivo</h4>
                <input
                  type="file"
                  name="file"
                  className="form-control form-control-xl"
                  id="inputGroupFile"
                  required
                  style={{ maxWidth: '400px' }}
                  onChange={handleImport}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </Container>
            ) : null}
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              disabled={
                loadedthirdClients.length == 0 || isCreateButtonDisabled
              }
              style={{ backgroundColor: 'green', borderRadius: '100px' }}
              onClick={() => handleUpload()}
            >
              {isCreateButtonDisabled ? (
                <CircularProgress size={15} color="inherit" />
              ) : null}{' '}
              Subir
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cerrar
            </Button>
          </MDBModalFooter>
          <MDBModalBody
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              overflow: 'auto',
            }}
          >
            {loadedthirdClients.length > 0 ? (
              <MDBTable
                small
                style={{
                  color: color,
                }}
              >
                <MDBTableHead>
                  <tr>
                    <th>Acciones</th>
                    <th>CONTACTO</th>
                    <th>NIT</th>
                    <th>CORREO</th>
                    <th>TELEFONO</th>
                    <th>DEPARTAMENTO</th>
                    <th>CIUDAD</th>
                    <th>ID.DEP</th>
                    <th>ID.CIU</th>
                    <th>DIRECCION</th>
                    <th>RAZON SOCIAL</th>
                    <th>Clasificación Tributaria</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                  {loadedthirdClients.map((loadedThirdClient, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Button
                            style={{
                              float: 'right',
                              backgroundColor: 'transparent',
                            }}
                            onClick={() => deleteThirdClient(index)}
                          >
                            <Trash size={25} color="red" />
                          </Button>
                        </td>
                        <td>{loadedThirdClient.thirdClientCompanyName}</td>
                        <td>{loadedThirdClient.nit}</td>
                        <td>{loadedThirdClient.email}</td>
                        <td>{loadedThirdClient.phone}</td>
                        <td>{loadedThirdClient.department}</td>
                        <td>{loadedThirdClient.municipalitie}</td>
                        <td>{loadedThirdClient.idDepartment}</td>
                        <td>{loadedThirdClient.cityCode}</td>
                        <td>{loadedThirdClient.address_line}</td>
                        <td>{loadedThirdClient.first_name}</td>
                        <td>
                          {loadedThirdClient.vatliability == 1
                            ? 'Persona Natural'
                            : 'Persona Jurídica'}
                        </td>
                      </tr>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
            ) : null}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}
