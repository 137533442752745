import React, { useState, useEffect } from 'react'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Checkbox, CircularProgress, TextField } from '@mui/material'
import { Group, Text } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import SaleModal from '../../components/client/sales/modals/saleModal'
import { CirclePlus, Edit, Filter, Trash } from 'tabler-icons-react'
import BundledSalesModal from '../../components/client/sales/modals/bundledSalesModal'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import Toggle from 'react-toggle'
import LoadingScreen from '../../components/main/loadingScreen'
import { setDecimal, dateFormat, isEmpty } from '../../services/utils'
import 'dayjs/locale/es'
import { useNavigate } from 'react-router-dom'
import 'react-toggle/style.css'
import saleIcon from '../../resources/images/factura.png'
import ReactJoyride from 'react-joyride'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import EditBundledSalesModal from '../../components/client/sales/modals/editBundledSalesModal'
import Swal from 'sweetalert2'
import {
  Pagination,
  MenuItem,
  Select as SelectPages,
  InputLabel,
  FormControl,
} from '@mui/material'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import { Download, QuestionMark, Upload } from 'tabler-icons-react'
import SalesMenus from '../../components/client/shortcutMenus/salesMenus'
import SalesFilters from '../../components/client/filters/salesFilters'
import SalesUploadModal from '../../components/client/sales/modals/salesUploadModal'
import ReportModal from '../../components/client/modals/reportModal'
import {
  deleteBundledSaleApi,
  getBundledSalesByInvoiceNumberApi,
  getFilteredBundledSalesByDatesApi,
  getFilteredBundledSalesByThirdClientApi,
} from '../api/bundledsale'
import {
  getFilteredClientSalesByDatesApi,
  getClientSalesByInvoiceNumberApi,
  UpdateClientSaleStateApi,
  getClientSalesApi,
  getClientSalesByThirdClientIdApi,
  getClientSalesByUserApi,
  getClientSalesByIdApi,
  getClientSalesBySaleStateApi,
} from '../api/clientsale'
import { updateClientHoseApi } from '../api/clienthoses'
import { getThirdClientsByCompanyApi } from '../api/thirdClients'
import { getWaysToPayApi } from '../api/wayToPay'
import EditMenu from '../../components/client/sales/editMenu'
import RumbosSalesModal from '../../components/client/sales/modals/rumbosSalesModal'

export default function Sales() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  const [basicActive, setBasicActive] = useState('tab1')
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return
    }
    setpg(1)
    setrpg(10)
    setBasicActive(value)
  }

  //Clients Sales
  const [sales, setSales] = useState([])
  const [salesCount, setSalesCount] = useState(0)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  //SALE ID
  const [saleId, setSaleId] = React.useState(0)

  //SALES ENUM
  const EnumSales = {
    Created: 1,
    Validated: 2,
    Accepted: 3,
    Refused: 4,
    Bundled: 5,
  }

  //UPLOAD MODAL
  const [showUploadModal, setShowUploadModal] = useState(false)
  const handleShowUploadModal = () => setShowUploadModal(true)
  const handleCloseUpload = () => {
    setShowUploadModal(false)
  }

  const setStateStyles = (saleState) => {
    let color = '#000000'
    switch (saleState) {
      case 1:
        color = 'rgb(33, 150, 243)'
        break
      case 2:
        color = '#66BB6A'
        break
      case 3:
        color = 'gray'
        break
      case 4:
        color = 'rgb(229, 57, 53)'
        break
      case 5:
        color = 'orange'
        break
      default:
        color = 'rgb(33, 150, 243)'
        break
    }

    return {
      borderRadius: '7%',
      backgroundColor: color,
      color: 'white',
      width: '150px',
      fontSize: '14px',
      textAlign: 'center',
    }
  }

  const setStateText = (saleState) => {
    switch (saleState) {
      case 1:
        return 'CREADA'
      case 2:
        return 'APROBADA'
      case 3:
        return 'ENVIADA DIAN'
      case 4:
        return 'DIAN RECHAZADA'
      case 5:
        return 'AGRUPADA'
      default:
        return 'CREADA'
    }
  }

  //to utils
  const tableStateStyle = (state) => {
    if (isDarkModeActive) return { backgroundColor: '#424242' }
    if (state == 1) return { backgroundColor: 'rgb(212, 234, 251)' } //sin aprobar
    if (state == 2) return { backgroundColor: 'rgb(243, 252, 232)' } //aprobada
    if (state == 3) return { backgroundColor: 'rgb(238, 238, 238)' } //dian
    if (state == 4) return { backgroundColor: 'rgb(255, 235, 238' } //rechazada
    if (state == 5) return { backgroundColor: 'rgb(255, 236, 179)' } //agrupada
  }

  // -------

  const form = useForm({
    initialValues: {
      fullName: '',
      username: '',
      email: '',
      password: '',
      roleId: 0,
      stateId: 0,
      userId: '',
      active: true,
    },
  })

  const putForm = useForm({
    initialValues: {
      islandId: 0,
      islandName: '',
      tankId: 0,
    },
  })

  const handleEditTankId = (tankId) => {
    form.setFieldValue('tankId', tankId)
  }

  //---------------------- sale state ---------------

  const updateSaleState = async (saleId, saleState) => {
    let result = await UpdateClientSaleStateApi(saleId, saleState)
    if (result.data == 'ClientSale updated') {
      currentSaleState == 0
        ? await getSalesWithoutFilters(
            tokenData.companyId,
            tokenData.branchId,
            tokenData.clientId,
            rolesData,
            pg * rpg - rpg,
            rpg,
          )
        : await getClientSalesBySaleState(
            tokenData.companyId,
            tokenData.branchId,
            currentSaleState,
            pg * rpg - rpg,
            rpg,
          )
    } else {
      notify('warning', 'Error al actualizar')
    }
  }

  const [bundledSales, setBundledSales] = useState([])
  const [bundledSalesCount, setBundledSalesCount] = useState(0)

  const [showBundledSaleModal, setShowBundleSaleModal] = useState(false)
  const handleShowBundledSaleModal = () => setShowBundleSaleModal(true)
  const handleCloseBundledSale = () => {
    reloadTable()
    setShowBundleSaleModal(false)
  }

  const [showRumbosSaleModal, setShowRumbosSaleModal] = useState(false)
  const handleShowRumbosSaleModal = () => setShowRumbosSaleModal(true)
  const handleCloseRumbosSale = () => {
    reloadTable()
    setShowRumbosSaleModal(false)
  }

  const [showEditBundledSaleModal, setShowEditBundleSaleModal] = useState(false)
  const [editableBundledSaleModal, setEditableBundledSaleModal] =
    useState(false)
  const handleShowEditBundledSaleModal = (saleId, editable) => {
    setSaleId(saleId)
    setEditableBundledSaleModal(editable)
    setShowEditBundleSaleModal(true)
  }
  const handleCloseEditBundledSale = () => {
    reloadTable()
    setShowEditBundleSaleModal(false)
  }

  const getBundledSales = async (
    companyId,
    branchId,
    thirdClientId,
    offset,
    limit,
  ) => {
    let sales = await getFilteredBundledSalesByThirdClientApi(
      companyId,
      branchId,
      thirdClientId,
      offset,
      limit,
    )

    if (sales.data != null) {
      setBundledSales(sales.data)
      setBundledSalesCount(sales.count)
    } else {
      setBundledSales([])
      setBundledSalesCount(0)
      notify('warning', 'No hay ventas agrupadas para esta empresa.')
    }
  }

  const deleteBundledSale = async (bundledSaleId) => {
    try {
      let sales = await deleteBundledSaleApi(bundledSaleId)
      if (sales.data != null) {
        reloadTable()
        notify('success', 'Venta agrupada eliminada con éxito.')
      }
    } catch (error) {
      return error
    }
  }

  const getSales = async (
    companyId,
    branchId,
    clientId,
    roles,
    offset,
    limit,
  ) => {
    let result = roles.admin
      ? thirdClientId == 0
        ? await getClientSalesApi(companyId, branchId, offset, limit)
        : await getClientSalesByThirdClientIdApi(
            thirdClientId,
            branchId,
            offset,
            limit,
          )
      : await getClientSalesByUserApi(
          companyId,
          branchId,
          clientId,
          offset,
          limit,
        )
    return result
  }

  const getSalesWithoutFilters = async (
    companyId,
    branchId,
    clientId,
    roles,
    offset,
    limit,
  ) => {
    let result = await getSales(
      companyId,
      branchId,
      clientId,
      roles,
      offset,
      limit,
    )
    if (result.data != null) {
      setSales(result.data)
      setSalesCount(result.count)
      setBillsToEditArray(result.data)
      setCurrentFilter('none')
    } else {
      notify('warning', 'No hay ventas asociadas a esta empresa.')
      setSales([])
      setSalesCount(0)
    }
  }

  const getClientSalesBySaleState = async (
    companyId,
    branchId,
    saleState,
    offset,
    limit,
  ) => {
    let result = await getClientSalesBySaleStateApi(
      companyId,
      branchId,
      saleState,
      offset,
      limit,
    )
    if (result.data != null) {
      setSales(result.data)
      setSalesCount(result.count)
      setBillsToEditArray(result.data)
      setCurrentFilter('state')
    } else {
      notify('warning', 'No hay ventas asociadas a esta empresa.')
      setSales([])
      setSalesCount(0)
    }
  }

  const reloadTable = async () => {
    await getSalesWithoutFilters(
      tokenData.companyId,
      tokenData.branchId,
      tokenData.clientId,
      rolesData,
      pg * rpg - rpg,
      rpg,
    )
    await getBundledSales(
      tokenData.companyId,
      tokenData.branchId,
      thirdClientId,
      pg * rpg - rpg,
      rpg,
    )
  }

  const formatSaleType = (saleType) => {
    switch (saleType) {
      case 1:
        return 'CONTADO'
      case 2:
        return 'CREDITO'
      default:
        break
    }
  }

  const [waysToPay, setWaysToPay] = useState([])
  const getWaysToPay = async () => {
    let result = await getWaysToPayApi()
    if (result.data != null) setWaysToPay(result.data)
    else notify('warning', 'No se encontraron tipos de pago')
  }

  //Roles

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.sales) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }

    await getWaysToPay()
    await getSalesWithoutFilters(
      tokenData.companyId,
      tokenData.branchId,
      tokenData.clientId,
      rolesData,
      pg * rpg - rpg,
      rpg,
    )
    await getBundledSales(
      tokenData.companyId,
      tokenData.branchId,
      thirdClientId,
      pg * rpg - rpg,
      rpg,
    )
    await getThirdClients()
  }

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const updateClientIsland = async () => {
    setIsUpdateButtonDisabled(true)
    let islandData = {
      name: putForm.values.islandName,
      tankId: parseInt(putForm.values.tankId),
    }

    try {
      let result = await updateClientHoseApi(
        putForm.values.islandId,
        islandData,
      )
      if (result.data == 'ClientIslands updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)

        await getSalesWithoutFilters(
          tokenData.companyId,
          tokenData.branchId,
          tokenData.clientId,
          rolesData,
          pg * rpg - rpg,
          rpg,
        )
      } else {
        notify('warning', 'Error al actualizar')
      }
    } catch (error) {
      return error
    }
    setIsUpdateButtonDisabled(false)
  }

  const getProductsTotalInitialPrice = (bill) => {
    let total = 0
    let products = JSON.parse(bill.products)
    products.map((product) => {
      total +=
        (product.price * product.quantity * (100 + parseInt(product.vat))) / 100
    })
    return total
  }

  //-----------Filters-------------------

  const getFilteredSalesByDate = async (
    thirdClientId,
    filterStartDate,
    filterEndDate,
    offset,
    limit,
    isBundled = false,
  ) => {
    let startDate = dateFormat(
      filterStartDate.toLocaleDateString(),
      false,
      true,
    )
    let endDate = dateFormat(filterEndDate.toLocaleDateString(), false, true)
    let result = isBundled
      ? await getFilteredBundledSalesByDatesApi(
          tokenData.companyId,
          tokenData.branchId,
          thirdClientId,
          startDate,
          endDate,
          offset,
          limit,
        )
      : await getFilteredClientSalesByDatesApi(
          tokenData.companyId,
          tokenData.branchId,
          thirdClientId,
          startDate,
          endDate,
          offset,
          limit,
        )

    if (result.data != null) {
      if (isBundled) {
        setBundledSales(result.data)
        setBundledSalesCount(result.count)
      } else {
        setSales(result.data)
        setSalesCount(result.count)
        setBillsToEditArray(result.data)
        setCurrentFilter('date')
      }
    } else {
      notify('warning', 'No se encuentran ventas para el filtro aplicado.')
      setSales([])
      setSalesCount(0)
      setBundledSales([])
      setBundledSalesCount(0)
    }
  }

  const getFilteredSalesByInvoice = async (invoiceNumberFilter, isBundled) => {
    let result = isBundled
      ? await getBundledSalesByInvoiceNumberApi(
          tokenData.companyId,
          tokenData.branchId,
          invoiceNumberFilter,
        )
      : await getClientSalesByInvoiceNumberApi(
          tokenData.companyId,
          tokenData.branchId,
          invoiceNumberFilter,
        )

    if (result.data != null) {
      if (isBundled) {
        setBundledSales(result.data)
      } else {
        setSales(result.data)
        setBillsToEditArray(result.data)
        setCurrentFilter('invoice')
      }
    } else {
      notify('warning', 'No se encuentran ventas para el filtro aplicado.')
      setSales([])
      setBundledSales([])
    }
  }

  const [currentSaleState, setCurrentSaleState] = useState(0)
  const [currentFilter, setCurrentFilter] = useState('none')

  const handleStateChange = async (saleState) => {
    setCurrentSaleState(saleState)
    if (saleState == 0) {
      await getSalesWithoutFilters(
        tokenData.companyId,
        tokenData.branchId,
        tokenData.clientId,
        rolesData,
        pg * rpg - rpg,
        rpg,
      )
    } else {
      await getClientSalesBySaleState(
        tokenData.companyId,
        tokenData.branchId,
        saleState,
        pg * rpg - rpg,
        rpg,
      )
    }
  }

  const saleStates = [
    { value: 1, label: 'CREADA' },
    { value: 2, label: 'APROBADA' },
    { value: 3, label: 'ENVIADA DIAN' },
    { value: 4, label: 'DIAN RECHAZADA' },
    { value: 5, label: 'AGRUPADA' },
  ]

  //-----------------Pagination-------------------
  const [pg, setpg] = useState(1) //page
  const [rpg, setrpg] = useState(10) //rows per page

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    currentSaleState == 0
      ? basicActive == 'tab1'
        ? currentSaleState == 0
          ? await getSalesWithoutFilters(
              tokenData.companyId,
              tokenData.branchId,
              tokenData.clientId,
              rolesData,
              newOffset,
              rpg,
            )
          : await getClientSalesBySaleState(
              tokenData.companyId,
              tokenData.branchId,
              currentSaleState,
              newOffset,
              rpg,
            )
        : await getBundledSales(
            tokenData.companyId,
            tokenData.branchId,
            thirdClientId,
            newOffset,
            rpg,
          )
      : await getClientSalesBySaleState(
          tokenData.companyId,
          tokenData.branchId,
          currentSaleState,
          newOffset,
          rpg,
        )
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)

    currentSaleState == 0
      ? basicActive == 'tab1'
        ? await getSalesWithoutFilters(
            tokenData.companyId,
            tokenData.branchId,
            tokenData.clientId,
            rolesData,
            0,
            event.target.value,
          )
        : await getBundledSales(
            tokenData.companyId,
            tokenData.branchId,
            thirdClientId,
            0,
            event.target.value,
          )
      : await getClientSalesBySaleState(
          tokenData.companyId,
          tokenData.branchId,
          currentSaleState,
          0,
          event.target.value,
        )
  }

  //-----------------Table Editable-------------------

  const [billsToEdit, setBillsToEdit] = useState([])
  const [allBillsEdit, setAllBillsEdit] = useState(false)
  const [anyBillEdit, setAnyBillEdit] = useState(false)

  const setBillsToEditArray = (bills) => {
    let billsArray = []
    bills.map((bill) => {
      billsArray.push({
        id: bill.id,
        paymentMethod: bill.paymentMethod,
        thirdClientId: bill.thirdClientId,
        thirdClientCompanyName: bill.thirdClientCompanyName,
        saleState: bill.saleState,
        isEditable: false,
      })
    })
    setBillsToEdit(billsArray)
  }

  const getEditBill = (saleId) => {
    let bill = billsToEdit.find((x) => x.id == saleId)
    return bill.isEditable
  }

  const handleEditBill = (saleId, check) => {
    let bills = [...billsToEdit]
    let allBillsEdit = true
    let anyBillEdit = false
    bills.map((x) => {
      if (x.id == saleId) x.isEditable = check
      if (!x.isEditable) allBillsEdit = false
      if (x.isEditable) anyBillEdit = true
    })
    setAnyBillEdit(anyBillEdit)
    setAllBillsEdit(allBillsEdit)
    setBillsToEdit(bills)
  }

  const handleEditAllBills = (check) => {
    let bills = [...billsToEdit]
    bills.map((bill) => {
      if (
        bill.saleState == EnumSales.Created ||
        bill.saleState == EnumSales.Validated
      )
        bill.isEditable = check
    })
    setAnyBillEdit(check)
    setAllBillsEdit(check)
    setBillsToEdit(bills)
  }

  const resetEditConstants = () => {
    setAnyBillEdit(false)
    setAllBillsEdit(false)
    if (currentSaleState == 0)
      getSalesWithoutFilters(
        tokenData.companyId,
        tokenData.branchId,
        tokenData.clientId,
        rolesData,
        pg * rpg - rpg,
        rpg,
      )
    else
      getClientSalesBySaleState(
        tokenData.companyId,
        tokenData.branchId,
        currentSaleState,
        pg * rpg - rpg,
        rpg,
      )
  }

  //-----------------Reports------------------

  const [ModalReportShow, setModalReportShow] = React.useState(false)

  //---------------------- Joyride -----------------------
  const [runJoyride, setRunJoyride] = useState(false)
  const [joyrideSteps] = useState([
    {
      content:
        'Este es el módulo de ventas, el cual usarán los encargados de venta tanto de productos como de gasolina, para ver, gestionar y agrupar recibos.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      placement: 'center',
      target: 'body',
      title: 'Ventas',
    },
    {
      content:
        'Aquí podrás elegir el tipo de recibo, si uno individual o agrupado.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      spotlightPadding: 20,
      target: '.salesTab',
      title: 'Tipo de recibo',
      placement: 'top',
    },
    {
      content:
        'En esta tabla encontrarás la información básica acerca de las ventas que has hecho.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      spotlightPadding: 20,
      target: '.salesTable',
      title: 'Venta de productos',
      placement: 'top',
    },
    {
      content: (
        <div>
          Aquí puedes ver información más detallada acerca de la venta, dando
          click al botón{' '}
          <img
            alt=""
            src={saleIcon}
            style={{
              float: 'inline-start',
              alignContent: 'center',
              cursor: 'pointer',
              width: '40px',
            }}
          />
        </div>
      ),
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.saleInfo',
      title: 'Información de la venta',
    },
    {
      content: (
        <div>
          Aquí podrás activar una venta, activando el botón <Toggle /> de cada
          venta.
        </div>
      ),
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.invoiceState',
      title: 'Estados de la manguera',
    },
    {
      content: (
        <div>
          Para una gestión más amplia y organizada, puedes escoger filtros de
          ventas al presionar <Filter />.
        </div>
      ),
      placement: 'top',
      locale: {
        skip: 'Saltar',
        next: 'Siguiente',
        back: 'Atrás',
        last: 'Terminar',
      },
      target: '.filterButton',
      title: 'Filtros',
    },
  ])

  const handleJoyrideCallback = (CallBackProps) => {
    const status = CallBackProps.status

    if (status == 'ready') {
      setRunJoyride(false)
    }
  }

  //--------------------Sale info detallada---------------

  const [saleInfo, setSaleInfo] = useState([])

  const [showSaleModal, setShowSaleModal] = useState(false)
  const handleShowSaleModal = () => setShowSaleModal(true)
  const handleCloseSale = () => setShowSaleModal(false)

  const showSale = async (saleId) => {
    let result = await getClientSalesByIdApi(saleId)
    if (result.data != null) {
      setSaleInfo(result.data)
      handleShowSaleModal()
    } else {
      notify('warning', 'Error, no se encuentra la venta asociada.')
    }
  }

  //--------select thirdclientclient -------

  const [thirdClientsOptions, setThirdClientsOptions] = useState([])
  const [thirdClientId, setThirdClientId] = useState(0)
  const [thirdClientCompanyName, setThirdClientCompanyName] =
    useState('Clientes')

  const onThirdClientIdChange = async (thirdClientId) => {
    setThirdClientId(thirdClientId)
    let name = thirdClientsOptions.find((x) => x.value == thirdClientId)
    if (name == undefined) return
    setThirdClientCompanyName(name.label)
  }

  const getThirdClients = async () => {
    let result = await getThirdClientsByCompanyApi(tokenData.companyId)
    if (result.data != null) {
      let clientSelect = await result.data.map((x) => {
        return { value: x.id, label: x.thirdClientCompanyName }
      })
      setThirdClientsOptions(clientSelect)
    }
  }

  //-----------------filter check----------
  useEffect(() => {
    if (currentFilter !== 'state') setCurrentSaleState(0)
  }, [sales])
  //---------------
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={runJoyride}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={joyrideSteps}
        styles={{
          options: {
            zIndex: 20,
            primaryColor: 'green',
          },
        }}
      />
      <Menu />
      {!loading ? (
        <Container>
          <Button
            onClick={() => setRunJoyride(true)}
            style={{ float: 'right', background: 'gray', border: 'none' }}
          >
            <QuestionMark /> Ayuda
          </Button>
          <SalesMenus buttonId={1} />
          <br></br>
          {rolesData.configuration || rolesData.admin ? (
            <>
              <Button
                variant="primary"
                style={{
                  backgroundColor: 'green',
                  float: 'right',
                  marginLeft: 5,
                  marginBottom: 10,
                  isolation: 'isolate',
                }}
                onClick={() => {
                  setModalReportShow(true)
                }}
              >
                <Download size={20} color="#FFFFFF" /> Exportar Ventas
              </Button>
              <Button
                variant="primary"
                style={{
                  backgroundColor: 'green',
                  float: 'right',
                  marginBottom: 10,
                  marginRight: 5,
                  isolation: 'isolate',
                }}
                onClick={handleShowUploadModal}
              >
                <Upload size={20} color="#FFFFFF" /> Carga Masiva
              </Button>
            </>
          ) : null}
          <div>
            <MDBTabs className="mb-3">
              <MDBTabsItem>
                <MDBTabsLink
                  style={{ backgroundColor: 'transparent' }}
                  onClick={() => handleBasicClick('tab1')}
                  active={basicActive === 'tab1'}
                >
                  Recibos individuales
                </MDBTabsLink>
              </MDBTabsItem>
              {rolesData.admin ? (
                <MDBTabsItem>
                  <MDBTabsLink
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => handleBasicClick('tab2')}
                    active={basicActive === 'tab2'}
                  >
                    Recibos agrupados
                  </MDBTabsLink>
                </MDBTabsItem>
              ) : null}
            </MDBTabs>
            <MDBTabsContent>
              <MDBTabsPane show={basicActive === 'tab1'}>
                {rolesData.admin ? (
                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      zIndex: 8,
                    }}
                  >
                    <SalesFilters
                      getFilteredSalesByDate={getFilteredSalesByDate}
                      getFilteredSalesByInvoice={getFilteredSalesByInvoice}
                      getSales={getSalesWithoutFilters}
                      getBundledSales={getBundledSales}
                      onThirdClientIdChange={onThirdClientIdChange}
                      isBundled={false}
                      offset={pg * rpg - rpg}
                      limit={rpg}
                    />
                    {anyBillEdit ? (
                      <EditMenu
                        salesToEdit={billsToEdit}
                        resetEditConstants={resetEditConstants}
                      />
                    ) : null}
                  </div>
                ) : null}
                <div style={{ height: '50px' }}></div>
                <MDBTable small className="salesTable">
                  <MDBTableHead>
                    <tr>
                      {rolesData.configuration ||
                      rolesData.admin ||
                      rolesData.invoice ? (
                        <th
                          style={{
                            padding: '0px',
                            margin: 'auto 0',
                          }}
                        >
                          <Checkbox
                            checked={allBillsEdit}
                            color="warning"
                            onChange={(e) =>
                              handleEditAllBills(e.target.checked)
                            }
                          />
                        </th>
                      ) : null}
                      <th style={{ textAlign: 'center' }}>
                        {rolesData.configuration ||
                        rolesData.admin ||
                        rolesData.invoice ? (
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Estado
                            </InputLabel>
                            <SelectPages
                              label="Estado"
                              onChange={(e) =>
                                handleStateChange(e.target.value)
                              }
                              value={currentSaleState}
                            >
                              <MenuItem value={0}>Todos</MenuItem>
                              {saleStates.map((sale, index) => (
                                <MenuItem value={sale.value}>
                                  <div
                                    key={index}
                                    style={setStateStyles(
                                      sale.value,
                                      form.values[sale.label],
                                    )}
                                  >
                                    {setStateText(sale.value)}
                                  </div>
                                </MenuItem>
                              ))}
                            </SelectPages>
                          </FormControl>
                        ) : (
                          'Estado'
                        )}
                      </th>
                      <th style={{ textAlign: 'center' }}>No. recibo</th>
                      <th style={{ textAlign: 'center' }}>Consecutivo</th>
                      <th style={{ textAlign: 'center' }}>Placa</th>
                      <th style={{ textAlign: 'center' }}>Método de venta</th>
                      <th style={{ textAlign: 'center' }}>Galones Vendidos</th>
                      <th style={{ textAlign: 'center' }}>Total</th>
                      <th style={{ textAlign: 'center' }}>Empresa</th>
                      <th style={{ textAlign: 'center' }}>Fecha</th>
                      <th
                        className="invoiceState"
                        style={{ textAlign: 'center' }}
                      >
                        Acciones
                      </th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                    {sales.map((sale, index) => {
                      return (
                        <tr key={index} style={tableStateStyle(sale.saleState)}>
                          {rolesData.configuration ||
                          rolesData.admin ||
                          rolesData.invoice ? (
                            <td
                              style={{
                                padding: '0px',
                              }}
                            >
                              <Checkbox
                                checked={getEditBill(sale.id)}
                                disabled={
                                  sale.saleState != EnumSales.Created &&
                                  sale.saleState != EnumSales.Validated
                                }
                                color="warning"
                                onChange={(e) =>
                                  handleEditBill(sale.id, e.target.checked)
                                }
                              />
                            </td>
                          ) : null}
                          <td>
                            <div style={setStateStyles(sale.saleState)}>
                              {setStateText(sale.saleState)}
                            </div>
                          </td>
                          <td>{sale.invoiceNumber}</td>
                          <td>{sale.posTerpelConsecutive}</td>
                          <td>{sale.busPlate}</td>
                          <td>{formatSaleType(sale.paymentMethod)}</td>
                          <td>{sale.gallonsSold}</td>
                          {rolesData.admin ? (
                            <td>
                              ${setDecimal(getProductsTotalInitialPrice(sale))}
                            </td>
                          ) : sale.paymentMethod == 1 ? (
                            <td>
                              {'$' +
                                setDecimal(getProductsTotalInitialPrice(sale))}
                            </td>
                          ) : (
                            <td>NA</td>
                          )}
                          <td>{sale.thirdClientCompanyName}</td>
                          <td>
                            {dateFormat(
                              new Date(sale.createdAt).toLocaleDateString(),
                              true,
                              false,
                              true,
                            )}
                          </td>
                          <td>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                onClick={() => showSale(sale.id)}
                                style={{ cursor: 'pointer' }}
                              >
                                <img
                                  alt=""
                                  style={{
                                    float: 'inline-start',
                                    alignContent: 'center',
                                    cursor: 'pointer',
                                    width: '40px',
                                  }}
                                  src={saleIcon}
                                />
                                <Text
                                  style={{
                                    fontSize: '14px',
                                  }}
                                >
                                  Detalles
                                </Text>
                              </div>
                              {rolesData.configuration ||
                              rolesData.admin ||
                              rolesData.invoice ? (
                                <div
                                  style={{
                                    marginLeft: 10,
                                    marginTop: 9,
                                  }}
                                >
                                  {sale.saleState == EnumSales.Accepted ||
                                  sale.saleState == EnumSales.Refused ||
                                  sale.saleState == EnumSales.Bundled ? (
                                    <Toggle
                                      disabled={true}
                                      checked={
                                        sale.saleState == EnumSales.Validated
                                          ? true
                                          : false
                                      }
                                    />
                                  ) : sale.saleState == EnumSales.Validated ? (
                                    <Toggle
                                      checked={
                                        sale.saleState == EnumSales.Validated
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        updateSaleState(
                                          sale.id,
                                          EnumSales.Created,
                                        )
                                      }
                                    />
                                  ) : (
                                    <Toggle
                                      checked={
                                        sale.saleState == EnumSales.Validated
                                          ? true
                                          : false
                                      }
                                      className="custom-classname"
                                      onChange={() =>
                                        updateSaleState(
                                          sale.id,
                                          EnumSales.Validated,
                                        )
                                      }
                                    />
                                  )}
                                  <Text
                                    style={{
                                      fontSize: '14px',
                                    }}
                                  >
                                    Facturar
                                  </Text>
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </MDBTableBody>
                </MDBTable>
                <Container>
                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Filas
                    </InputLabel>
                    <SelectPages
                      labelId="demo-simple-select-standard-label"
                      autoWidth={true}
                      value={rpg}
                      onChange={handleChangeRowsPerPage}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </SelectPages>
                  </FormControl>
                  <Pagination
                    count={Math.ceil(
                      (basicActive == 'tab1' ? salesCount : bundledSalesCount) /
                        rpg,
                    )}
                    page={pg}
                    onChange={handleChangePage}
                    showFirstButton={true}
                    showLastButton={true}
                    style={{ display: 'inline-block' }}
                  />
                </Container>
              </MDBTabsPane>
              <MDBTabsPane show={basicActive === 'tab2'}>
                <Button
                  style={{
                    backgroundColor: 'green',
                    float: 'right',
                    marginLeft: 5,
                    marginBottom: 10,
                    isolation: 'isolate',
                  }}
                  onClick={() => handleShowBundledSaleModal()}
                >
                  <CirclePlus size={20} color="white" /> Nueva factura agrupada
                </Button>
                <Button
                  style={{
                    backgroundColor: 'green',
                    float: 'right',
                    marginLeft: 5,
                    marginBottom: 10,
                    isolation: 'isolate',
                  }}
                  onClick={() => handleShowRumbosSaleModal()}
                >
                  <CirclePlus size={20} color="white" /> Nueva factura Rumbos
                </Button>
                {rolesData.admin ? (
                  <SalesFilters
                    getFilteredSalesByDate={getFilteredSalesByDate}
                    getFilteredSalesByInvoice={getFilteredSalesByInvoice}
                    getSales={getSalesWithoutFilters}
                    getBundledSales={getBundledSales}
                    onThirdClientIdChange={onThirdClientIdChange}
                    isBundled={true}
                    offset={pg}
                    limit={rpg}
                  />
                ) : null}
                {showRumbosSaleModal ? (
                  <RumbosSalesModal
                    show={showRumbosSaleModal}
                    onClose={handleCloseRumbosSale}
                  />
                ) : null}
                {showBundledSaleModal ? (
                  <BundledSalesModal
                    thirdClientId={thirdClientId}
                    show={showBundledSaleModal}
                    onClose={handleCloseBundledSale}
                  />
                ) : null}
                {showEditBundledSaleModal ? (
                  <EditBundledSalesModal
                    saleId={saleId}
                    editable={editableBundledSaleModal}
                    thirdClientId={thirdClientId}
                    thirdClientCompanyName={thirdClientCompanyName}
                    show={showEditBundledSaleModal}
                    onClose={handleCloseEditBundledSale}
                  />
                ) : null}
                <MDBTable small className="salesTable">
                  <MDBTableHead>
                    <tr>
                      <th style={{ textAlign: 'center' }}>Estado</th>
                      <th style={{ textAlign: 'center' }}>Grupo</th>
                      <th style={{ textAlign: 'center' }}>Recibos agrupados</th>
                      <th style={{ textAlign: 'center' }}>Hora de creación</th>
                      <th style={{ textAlign: 'center' }}>Acciones</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                    {bundledSales.map((sale, index) => {
                      return (
                        <tr key={index} style={tableStateStyle(sale.saleState)}>
                          <td>
                            <div style={setStateStyles(sale.saleState)}>
                              {setStateText(sale.saleState)}
                            </div>
                          </td>
                          <td>{sale.id}</td>

                          <td
                            style={{
                              width: '100px',
                              margin: 'auto',
                              textAlign: 'center',
                            }}
                          >
                            <div
                              onClick={() =>
                                handleShowEditBundledSaleModal(sale.id, false)
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                alt=""
                                style={{
                                  float: 'inline-start',
                                  alignContent: 'center',
                                  cursor: 'pointer',
                                  width: '40px',
                                }}
                                src={saleIcon}
                              />
                            </div>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {sale.createdAt}
                          </td>

                          <td style={{ textAlign: 'center' }}>
                            <Edit
                              color={
                                sale.saleState != EnumSales.Accepted &&
                                sale.saleState != EnumSales.Refused
                                  ? 'black'
                                  : 'gray'
                              }
                              style={{
                                margin: '10px',
                                cursor:
                                  sale.saleState != EnumSales.Accepted &&
                                  sale.saleState != EnumSales.Refused
                                    ? 'pointer'
                                    : 'default',
                              }}
                              onClick={() =>
                                sale.saleState != EnumSales.Accepted &&
                                sale.saleState != EnumSales.Refused
                                  ? handleShowEditBundledSaleModal(
                                      sale.id,
                                      true,
                                    )
                                  : null
                              }
                            ></Edit>
                            <Trash
                              color={
                                sale.saleState != EnumSales.Accepted &&
                                sale.saleState != EnumSales.Refused
                                  ? 'red'
                                  : 'gray'
                              }
                              style={{
                                margin: '10px',
                                isolation: 'isolate',
                                cursor:
                                  sale.saleState != EnumSales.Accepted &&
                                  sale.saleState != EnumSales.Refused
                                    ? 'pointer'
                                    : 'default',
                              }}
                              onClick={() =>
                                sale.saleState != EnumSales.Accepted &&
                                sale.saleState != EnumSales.Refused
                                  ? Swal.fire({
                                      title: '¿Eliminar venta agrupada?',
                                      text: 'Nota: Esta acción no es reversible',
                                      icon: 'warning',
                                      confirmButtonText: 'Enviar',
                                      cancelButtonText: 'Cancelar',
                                      showCancelButton: true,
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        deleteBundledSale(sale.id)
                                      }
                                    })
                                  : null
                              }
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </MDBTableBody>
                </MDBTable>
                <Container>
                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Filas
                    </InputLabel>
                    <SelectPages
                      labelId="demo-simple-select-standard-label"
                      autoWidth={true}
                      value={rpg}
                      onChange={handleChangeRowsPerPage}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </SelectPages>
                  </FormControl>
                  <Pagination
                    count={Math.ceil(
                      (basicActive == 'tab1' ? salesCount : bundledSalesCount) /
                        rpg,
                    )}
                    page={pg}
                    onChange={handleChangePage}
                    showFirstButton={true}
                    showLastButton={true}
                    style={{ display: 'inline-block' }}
                  />
                </Container>
              </MDBTabsPane>
            </MDBTabsContent>
          </div>
          {showSaleModal ? (
            <SaleModal
              sales={saleInfo}
              show={showSaleModal}
              onClose={handleCloseSale}
            />
          ) : null}
          <ReportModal
            ModalReportShow={ModalReportShow}
            setModalReportShow={setModalReportShow}
            reportType="sales"
          />
        </Container>
      ) : (
        <LoadingScreen />
      )}
      {showUploadModal ? (
        <SalesUploadModal
          show={showUploadModal}
          onClose={handleCloseUpload}
          reloadTable={reloadTable}
        />
      ) : null}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Nombre de la isla"
                    placeholder="Nombre de la isla"
                    value={putForm.values.islandName}
                    onChange={(event) =>
                      form.setFieldValue(
                        'islandName',
                        event.currentTarget.value,
                      )
                    }
                  />

                  <select
                    onChange={(e) => handleEditTankId(e.target.value)}
                    value={putForm.values.tankId}
                  >
                    {sales.map((sale, index) => {
                      return (
                        <option value={sale.id} key={index}>
                          {sale.name}
                        </option>
                      )
                    })}
                  </select>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateClientIsland()}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
