import { create } from 'zustand'
import Darkmode from 'darkmode-js'

const options = {
  backgroundColor: '#F5F5F5', // default: '#fff'
  autoMatchOsTheme: false, // default: true
}

const darkmode = new Darkmode(options)

const useStore = create((set) => ({
  tokenData: {},
  addTokenData: (data) => set((state) => ({ tokenData: data })),
  rolesData: {},
  addRoles: (data) => set((state) => ({ rolesData: data })),
  companyData: {},
  addCompanyData: (data) => set((state) => ({ companyData: data })),
  darkMode: darkmode,
  setDarkMode: (data) => set((state) => ({ darkMode: data })),
  isDarkModeActive: darkmode.isActivated(),
  setIsDarkModeActive: (isDarkMode) =>
    set((state) => ({ isDarkModeActive: isDarkMode })),
}))

export default useStore
