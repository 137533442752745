import { Group, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { getMonthsToDisplay, getYearsToDisplay, notify, switchMonthDayDate } from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import useStore from '../../../components/zustand'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material'
import { Select as MuiSelect } from '@mui/material'
import {
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit'
import {
  ExportCSV,
  ExportExcel,
  ExportText,
} from '../../../services/reportExport'
import { dateFormat } from '../../../services/utils'
import { getClientInvoiceByBillingFormatAndDateApi } from '../../../pages/api/clientinvoice'
import { getClientSalesReportsByDateApi } from '../../../pages/api/clientsale'
import { GetClientPaymentMethodByCompanyIdApi } from '../../../pages/api/clientPaymentMethod'
import { getClientConceptsApi } from '../../../pages/api/clientconcepts'

export default function ReportModal(props) {
  const { tokenData } = useStore()

  const { ModalReportShow, setModalReportShow, reportType } = props

  //-----------------Reports------------------
  const [reportTitle, setReportTitle] = React.useState('')
  const [fileFormat, setFileFormat] = React.useState(0)
  const [reportMonth, setReportMonth] = React.useState(
    new Date().getMonth() + 1,
  )
  const [reportYear, setReportYear] = React.useState(new Date().getFullYear())
  const [reportPrefix, setReportPrefix] = React.useState('')
  const [reportCurrentNumeration, setReportCurrentNumeration] =
    React.useState(0)

  const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] =
    React.useState(false)

  const setPrefixAndNumeration = (report, prefix, numeration) => {
    let numerationToSet = numeration
    report.map((item) => {
      item['Número de factura'] = (prefix + String(numerationToSet)).padStart(
        9,
        '0',
      ) // EL 9 ES TEMPORAL, A DEFINIR
      numerationToSet++
    })
    return { data: report }
  }

  const billingFormats = [
    { id: 1, reportType: 'invoice', label: 'CONTAI' },
    { id: 2, reportType: 'sales', label: 'SCI' },
    { id: 3, reportType: 'invoice', label: 'Medios de Pago' },
  ]

  const [billingFormat, setBillingFormat] = React.useState({
    id: -1,
    value: 0,
    reportType: '',
    label: '',
  })

  //---------------------ClientConcepts----------------

  const [clientConcepts, setClientConcepts] = useState([])
  const [clientConceptsByMethods, setClientConceptsByMethods] = useState([
    { id: 1, name: 'Contado', conceptCode: '0' },
    { id: 2, name: 'Crédito', conceptCode: '0' },
  ])

  const getClientConcepts = async (companyId) => {
    let result = await getClientConceptsApi(companyId)
    result.data != null
      ? setClientConcepts(result.data)
      : notify('warning', 'No hay conceptos creados')
  }

  const handleConceptByMethodChange = (value, index) => {
    let newConceptsByMethods = [...clientConceptsByMethods]
    newConceptsByMethods[index].conceptCode = value
    setClientConceptsByMethods(newConceptsByMethods)
  }

  const getClientConceptInfoByMethod = (id) => {
    let conceptCode = clientConceptsByMethods.find((item) => item.id == id)
    let concept = clientConcepts.find(
      (item) => item.id == conceptCode.conceptCode,
    )
    return concept
  }

  const checkClientConceptsByMethods = () => {
    let readyForReport = true
    clientConceptsByMethods.some((item) => {
      if (item.conceptCode == 0) {
        readyForReport = false
        notify('warning', 'Por favor elija un concepto para ' + item.name + '.')
      }
    })
    return readyForReport
  }

  //---------------------paymentMethods----------------

  const [paymentMethods, setPaymentMethods] = useState([])

  const getPaymentMethods = async (companyId) => {
    let result = await GetClientPaymentMethodByCompanyIdApi(companyId)
    result.data != null
      ? setPaymentMethods(result.data)
      : notify('warning', 'No hay métodos de pago creados')
  }

  const getPaymentMethodInfoById = (id) => {
    let paymentMethod = paymentMethods.filter((item) => item.id == id)
    return paymentMethod[0]
  }

  //-------------------------------------------------

  const generateReport = async () => {
    let readyForReport = true
    if (billingFormat.value == 0) {
      readyForReport = false
      notify('warning', 'Por favor elija el tipo de formato.')
    }
    if (fileFormat == 0) {
      readyForReport = false
      notify('warning', 'Por favor elija el tipo de archivo.')
    }

    if (billingFormat.reportType == 'sales')
      readyForReport = checkClientConceptsByMethods()

    if (readyForReport) {
      setIsGenerateButtonDisabled(true)
      let StartMonth = reportMonth < 10 ? `0${reportMonth}` : reportMonth
      let EndMonth =
        reportMonth + 1 < 10 ? `0${reportMonth + 1}` : reportMonth + 1
      let StartDate = `01/${StartMonth}/${reportYear}`
      let FinishDate = `01/${
        EndMonth > 12 ? '01/' + (reportYear + 1) : EndMonth + '/' + reportYear
      }`

      let dateStart = dateFormat(StartDate, false, true)
      let dateEnd = dateFormat(FinishDate, false, true)
      let result = null
      switch (billingFormat.reportType) {
        case 'sales':
          result = await getClientSalesReportsByDateApi(
            tokenData.companyId,
            dateStart,
            dateEnd,
          )
          if (result.data != null)
            result = setPrefixAndNumeration(
              result.data,
              reportPrefix,
              reportCurrentNumeration,
            )
          break
        case 'invoice': // tanto para invoice como para paymentmethods
          result = await getClientInvoiceByBillingFormatAndDateApi(
            'iltda', // quemado, buscar otra forma
            tokenData.companyId,
            dateStart,
            dateEnd,
          )
          break
        default:
          break
      }
      try {
        let ExcelExportName = `${billingFormat.label}_${tokenData.clientName}_${
          tokenData.branchName
        }_${dateFormat(StartDate, true, true)}_hasta_${dateFormat(
          FinishDate,
          true,
          true,
        )}`

        let dataToExport = formatDataByReportType(result.data, billingFormat)

        if (result.data != null) {
          switch (
            fileFormat //1 CSV, 2 EXCEL, 3 TXT
          ) {
            case 1:
              ExportCSV({ csvData: dataToExport, fileName: ExcelExportName })
              break
            case 2:
              ExportExcel({
                excelData: dataToExport,
                fileName: ExcelExportName,
              })
              break
            case 3:
              ExportText({
                textData: dataToExport,
                fileName: ExcelExportName,
              })
              break
            default:
              notify('warning', 'Por favor elija el tipo de archivo.')
              break
          }
          setModalReportShow(false)
        } else {
          notify(
            'info',
            'No hay facturas para el rango de fechas seleccionado.',
          )
        }
      } catch (error) {
        return error
      }
    }
    setIsGenerateButtonDisabled(false)
  }

  const formatDataByReportType = (data, billingFormat) => {
    let formattedData = []
    //1 CONTAI
    //2 SCI
    //3 Medios de Pago
    switch (billingFormat.id) {
      case 1:
        let nextConsefactu = ''
        let totalById = 0
        data.map((item, index) => {
          let paymentMethodInfo = getPaymentMethodInfoById(
            item.clientPaymentMeanId,
          )
          nextConsefactu =
            index < data.length - 1 ? String(data[index + 1].consefactu) : 0
          let cuenta = String(item.cuenta).padEnd(10, ' ')
          let comprobante = paymentMethodInfo.voucher
          let fecha = switchMonthDayDate(String(item.fecha))
          let consefactu = String(item.consefactu).padEnd(9, ' ')
          let idtercero = String(item.idtercero).padStart(11, ' ')
          let description = String(item.detalle)
            .substring(0, 28)
            .padEnd(28, ' ')
          let naturaleza = '2'
          let base = String('0.00').padStart(21, ' ')
          let centroCostos = String(item.costcenter).padStart(6, ' ')
          let total = String(item.total).padStart(21, ' ')
          totalById = totalById + parseFloat(item.total)
          formattedData.push({
            cuenta,
            comprobante,
            fecha,
            consefactu,
            consefactu,
            idtercero,
            description,
            naturaleza,
            total,
            base,
            centroCostos,
            ' ': '   ',
            ' ': '   0',
          })

          if (String(nextConsefactu) !== String(item.consefactu)) {
            let cuenta = String('11050501').padEnd(10, ' ')
            let comprobante = '00001'
            let fecha = switchMonthDayDate(String(item.fecha))
            let consefactu = String(item.consefactu).padEnd(9, ' ')
            let idtercero = String(item.idtercero).padStart(11, ' ')
            let description = String('CAJA').substring(0, 28).padEnd(28, ' ')
            let naturaleza = '1'
            let base = String('0.00').padStart(21, ' ')
            let centroCostos = String(item.costcenter).padStart(6, ' ')
            let total = String(totalById + '.00').padStart(21, ' ')
            totalById = 0
            formattedData.push({
              cuenta,
              comprobante,
              fecha,
              consefactu,
              consefactu,
              idtercero,
              description,
              naturaleza,
              total,
              base,
              centroCostos,
              ' ': '   ',
              ' ': '   0',
            })
          }
        })
        break
      case 2:
        data.map((item) => {
          let products = JSON.parse(item.Productos)
          products.map((product) => {
            let conceptByMethod = getClientConceptInfoByMethod(
              item['Método de pago '],
            )
            let row = {
              'Fecha Transacción': item['Fecha de creación']
                .substring(0, 10)
                .split('-')
                .reverse()
                .join('/'),
              'Tipo Transacción': conceptByMethod.transactionType,
              'Número del Documento': item['Número de factura'],
              'Cliente Acreedor': item['Nit del cliente'],
              Bodega: product.store,
              Referencia: product.code,
              'Código Concepto': conceptByMethod.conceptCode, // quemado
              Cantidad: product.quantity,
              Precio: product.price.toFixed(2),
              'Centro de Costo': item['Centro de costos'],
              'Descripción Referencia': `${product.name} ${dateFormat(
                new Date(product.createdAt).toLocaleDateString(),
                true,
                false,
                true,
              )} - ${product.busPlate}`, // PRODUCTO FECHA - PLACA
              'Tipo Retención': '99', // quemado -> investigar
              'Documento Referencia': product.conseFactu,
            }
            formattedData.push(row)
          })
        })
        break
      case 3:
        data.map((item) => {
          let paymentMethodInfo = getPaymentMethodInfoById(
            item.clientPaymentMeanId,
          )
          let cuenta = paymentMethodInfo.ledgerAccount
          let comprobante = paymentMethodInfo.voucher
          let paymentType = paymentMethodInfo.prefix
          let fecha = switchMonthDayDate(String(item.fecha))
          let consefactu = item.consefactu
          let total = item.total
          formattedData.push({
            consefactu,
            fecha,
            comprobante,
            total,
            'Tipo Transacción': 30, // quemado, tipo venta en sci
            paymentType,
            cuenta,
          })
        })
        break
      default:
        formattedData = data
        break
    }
    return formattedData
  }

  const handleYearChange = async (date) => {
    setReportYear(date)
    getMonthsToDisplay(date)
  }

  const handleBillingFormat = (index) => {
    setBillingFormat(billingFormats.find((item) => item.id == index))
  }

  const setModalTitle = () => {
    switch (reportType) {
      case 'sales':
        setReportTitle('Reporte de Ventas')
        break
      case 'invoice':
        setReportTitle('Reporte de Facturación')
        break
      default:
        setReportTitle('Reporte')
        break
    }
  }

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    getPaymentMethods(tokenData.companyId)
    getClientConcepts(tokenData.companyId)
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#424242' : 'white')
    setModalTitle()
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={ModalReportShow}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size="lg">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Generando {reportTitle}
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <Group grow>
              <FormControl variant="standard" sx={{ minWidth: 150 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  AÑO
                </InputLabel>
                <MuiSelect
                  label="Año"
                  value={reportYear}
                  onChange={(date) => handleYearChange(date.target.value)}
                >
                  {getYearsToDisplay().map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: 150 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  MES
                </InputLabel>
                <MuiSelect
                  label="Mes"
                  value={reportMonth}
                  onChange={(date) => setReportMonth(date.target.value)}
                >
                  {getMonthsToDisplay(reportYear).map((month, index) => (
                    <MenuItem key={index} value={month.monthNumber}>
                      {month.monthName}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            </Group>
            <br></br>
            <Group grow>
              <FormControl variant="standard" sx={{ minWidth: 200 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Formato de facturación
                </InputLabel>
                <MuiSelect
                  label="Formato de facturación"
                  value={billingFormat.id}
                  onChange={(event) => {
                    handleBillingFormat(event.target.value)
                  }}
                >
                  {billingFormats.map((billFormat, index) =>
                    reportType === billFormat.reportType ? (
                      <MenuItem key={index} value={billFormat.id}>
                        {billFormat.label}
                      </MenuItem>
                    ) : null,
                  )}
                </MuiSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: 150 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Tipo de archivo
                </InputLabel>
                <MuiSelect
                  label="Tipo de archivo"
                  value={fileFormat}
                  onChange={(e) => setFileFormat(e.target.value)}
                >
                  <MenuItem key={1} value={1}>
                    CSV
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    EXCEL
                  </MenuItem>
                  <MenuItem key={3} value={3}>
                    Texto Plano
                  </MenuItem>
                </MuiSelect>
              </FormControl>
            </Group>
            <br></br>
            {billingFormat.reportType == 'sales' ? (
              <Group grow>
                {clientConceptsByMethods.map((method, index) => (
                  <FormControl
                    key={index}
                    variant="standard"
                    sx={{ minWidth: 200 }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Concepto para {method.name}
                    </InputLabel>
                    <MuiSelect
                      label={'Concepto para ' + method.name}
                      value={clientConceptsByMethods[index].conceptCode}
                      onChange={(event) => {
                        handleConceptByMethodChange(event.target.value, index)
                      }}
                    >
                      {clientConcepts.map((concept, index) => (
                        <MenuItem key={index} value={concept.id}>
                          {concept.conceptCode + ' - ' + concept.name}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                ))}
              </Group>
            ) : null}
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              disabled={isGenerateButtonDisabled}
              style={{
                backgroundColor: 'green',
                isolation: 'isolate',
                borderRadius: '100px',
              }}
              onClick={() => generateReport()}
            >
              {isGenerateButtonDisabled ? (
                <CircularProgress size={15} color="inherit" />
              ) : null}{' '}
              Generar
            </Button>
            <br></br>
            <br></br>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => {
                setModalReportShow(false)
                setIsGenerateButtonDisabled(false)
              }}
            >
              Cancelar
            </Button>
            <br></br>
            <br></br>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}
