import React from 'react'
import { Tooltip } from '@mui/material'
import Button from 'react-bootstrap/Button'

const ActionButton = ({
  onClick,
  description,
  backgroundColor = 'white',
  children,
}) => {
  return (
    <Tooltip title={description}>
      <Button
        style={{
          backgroundColor: backgroundColor,
          margin: '5px',
          padding: '5px',
          height: 'fit-content',
          width: 'fit-content',
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

export default ActionButton
