import { TextField } from '@mui/material'
import { MDBTable, MDBTableBody } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { CircleMinus, CirclePlus } from 'tabler-icons-react'

const Notes = ({ notes = [], onChange = () => {} }) => {
  //--------------------Notes---------------------------
  const [notesList, setNotesList] = useState(notes)

  const handleNewNote = () => {
    let notes = [...notesList]
    let note = ''
    notes.push(note)
    setNotesList(notes)
  }

  const handleDeleteNote = (index) => {
    let notes = [...notesList]
    notes.splice(index, 1)
    setNotesList(notes)
  }

  const handleEditNote = (note, index) => {
    let notes = [...notesList]
    notes[index] = note
    setNotesList(notes)
  }

  const getNotes = () => {
    let notesResult = []
    notesList.map((note) => {
      if (note.length > 0) notesResult.push(note)
    })
    onChange(notesResult)
  }

  useEffect(() => {
    getNotes()
  }, [notesList])

  //----------------------------------
  return (
    <div>
      {notesList.length > 0 ? (
        <>
          <u>Notas:</u>
          <MDBTable>
            <MDBTableBody>
              {notesList.map((note, index) => (
                <tr key={index}>
                  <td>#{index + 1}:</td>
                  <td>
                    <TextField
                      variant="standard"
                      value={note}
                      onChange={(e) => handleEditNote(e.target.value, index)}
                    ></TextField>
                  </td>
                  <td>
                    <Button
                      style={{
                        border: 'none',
                        borderRadius: 50,
                        background: '#f45b69',
                        padding: '10px 20px',
                      }}
                      onClick={() => handleDeleteNote(index)}
                    >
                      Quitar <CircleMinus size={20} />
                    </Button>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </>
      ) : null}
      <Button
        style={{
          border: 'none',
          borderRadius: 50,
          background: 'green',
          padding: '10px 20px',
        }}
        onClick={() => handleNewNote()}
      >
        Añadir nota <CirclePlus size={20} color="#FFFFFF" />
      </Button>
    </div>
  )
}

export default Notes
