import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getClientResolutionsApi = async (branchId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/resolutions/all/${branchId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CRES-1"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las resoluciones.')
  }
}

export const getClientResolutionByIdApi = async (resolutionId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/resolutions/${resolutionId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CRES-2"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener la resolución.')
  }
}
