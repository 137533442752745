import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getLedgerAccountsApi = async (companyId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/iltda/${companyId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error) {
      apiErrorMessageHandler(
        '"ILTDA-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    }
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las cuentas contables.')
  }
}

export const PostLedgerAccountsApi = async (
  companyId,
  branchId,
  ledgerAccountCode,
  details,
) => {
  try {
    let configData = {
      branchId: branchId,
      companyId: companyId,
      ledgerAccountCode: ledgerAccountCode,
      detail: details,
    }
    let rawResult = await fetch(`${apilUrl}/iltda/${companyId}/${branchId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(configData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"ILTDA-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear la cuenta contable.')
  }
}

export const DeleteLedgerAccountsApi = async (ledgerAccountId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/iltda/${ledgerAccountId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"ILTDA-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar la cuenta contable.')
  }
}

export const updateLedgerAccountsApi = async (
  branchId,
  companyId,
  ledgerAccountId,
  ledgerAccountCode,
  details,
) => {
  let configData = {
    branchId: branchId,
    companyId: companyId,
    ledgerAccountCode: ledgerAccountCode,
    detail: details,
  }
  try {
    let rawResult = await fetch(`${apilUrl}/iltda/${ledgerAccountId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(configData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"ILTDA-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar la cuenta contable.')
  }
}

export const PostBulkLedgerAccountsApi = async (companyId, branchId, data) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/iltda/bulk-load/${companyId}/${branchId}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"ILTDA-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al subir las cuentas contables.')
  }
}
