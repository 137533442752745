import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getClientMobilityApi = async (companyId, branchId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/clientmobility/all/${companyId}/${branchId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CMOBILITY-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener la movilidad.')
  }
}

export const UpdateClientMobilityApi = async (
  mobilityId,
  provider,
  total,
  remaining,
) => {
  let movilityData = {
    provider: provider,
    total: parseFloat(total),
    remaining: parseFloat(remaining),
  }

  try {
    let rawResult = await fetch(`${apilUrl}/clientmobility/${mobilityId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(movilityData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CMOBILITY-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar la movilidad.')
  }
}

export const PostClientMobilityApi = async (
  companyId,
  branchId,
  provider,
  total,
  remaining,
) => {
  let mobilityData = {
    companyId: companyId,
    branchId: branchId,
    provider: provider,
    total: parseFloat(total),
    remaining: parseFloat(remaining),
  }
  try {
    let rawResult = await fetch(`${apilUrl}/clientmobility`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mobilityData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CMOBILITY-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear la movilidad.')
  }
}

export const DeleteClientMobilityApi = async (clientMovilityId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/clientmobility/${clientMovilityId}`,
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CMOBILITY-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar la movilidad.')
  }
}
