import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import CSV from 'sheetjs-style'
import { saveAs } from 'file-saver'

export const ExportExcel = ({ excelData, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats.officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  return exportToExcel(fileName)
}

export const ExportCSV = ({ csvData, fileName }) => {
  const fileType = 'text/csv;charset=utf-8;'
  const fileExtension = '.csv'
  const exportToCSV = async () => {
    const ws = CSV.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const csvBuffer = CSV.write(wb, { bookType: 'csv', type: 'array' })
    const data = new Blob([csvBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  return exportToCSV(fileName)
}

export const ExportText = ({ textData, fileName }) => {
  const fileType = 'text/plain;charset=utf-8;'
  const fileExtension = '.txt'
  const exportToTXT = async () => {
    const ws = CSV.utils.json_to_sheet(textData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const csvBuffer = CSV.write(wb, { bookType: 'csv', type: 'array' })
    const data = new Blob([csvBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  return exportToTXT(fileName)
}
