import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Group, Text, Paper } from '@mantine/core'
import { TextField } from '@mui/material'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { apilUrl } from '../../services/url'
import { Edit } from 'tabler-icons-react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import LoadingScreen from '../../components/main/loadingScreen'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import ConfigurationMenus from '../../components/client/shortcutMenus/configurationMenus'
import useStore from '../../components/zustand'
import { isEmpty } from '../../services/utils'
import { getClientBranchesByCompanyApi } from '../api/branches'

export default function ReceiptDispenser() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  //Clients Companies
  const [branches, setBranches] = useState([])

  //Clients Numeration
  const [numeration, setNumeration] = useState([])

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  //COMPANY ID
  const [companyId, setCompanyId] = React.useState(0)

  const putForm = useForm({
    initialValues: {
      id: 0,
      numeration: 0,
      createdAt: '',
      updatedAt: '',
    },
  })

  //GETS HOSE FIELDS
  const getNumerationData = async (id, numeration, createdAt, updatedAt) => {
    putForm.setFieldValue('id', id)
    putForm.setFieldValue('numeration', numeration)
    putForm.setFieldValue('createdAt', createdAt)
    putForm.setFieldValue('updatedAt', updatedAt)
    setModalPutShow(true)
  }

  const getClientCompanies = async () => {
    let result = await getClientBranchesByCompanyApi(tokenData.companyId)
    if (result.data != null) setBranches(result.data)
    else notify('warning', 'No hay sucursales registradas para esta empresa.')
  }
  const handleCompanyId = async (branchId) => {
    setCompanyId(branchId)
    await getClientCompanyNumeration(branchId)
  }

  const getClientCompanyNumeration = async (branchId) => {
    try {
      let result = await fetch(
        `${apilUrl}/receiptdispenser/${tokenData.companyId}/${branchId}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      setNumeration(result.data[0])
    } catch (error) {
      return false
    }
  }

  const updateNumeration = async () => {
    if (putForm.values.numeration == 0) {
      notify('warning', 'Por favor llena todos los campos.')
    } else {
      let numerationData = {
        numeration: putForm.values.numeration,
      }
      try {
        let result = await fetch(
          `${apilUrl}/receiptdispenser/${putForm.values.id}`,
          {
            method: 'PUT',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(numerationData),
          },
        ).then((response) => response.json())
        if (result.data == 'Receiptdispenser updated') {
          notify('success', 'Actualización Exitoso')
          setModalPutShow(false)
          await getClientCompanyNumeration(putForm.values.id)
        } else {
          notify('warning', 'Error al actualizar')
        }
      } catch (error) {
        return error
      }
    }
  }

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!(rolesData.admin || rolesData.configuration)) {
      notify('warning', 'No tienes permiso para ver este módulo.')
      navigate('/dashboard')
    }

    await getClientCompanies()

    handleCompanyId(tokenData.companyId)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <Container>
            <ConfigurationMenus buttonId={4} />
            <br></br>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Selecciona una sucursal
              </InputLabel>
              <MuiSelect
                disabled={!rolesData.admin}
                value={companyId}
                label="Seleciona una sucursal"
                onChange={(e) => handleCompanyId(e.target.value)}
              >
                {branches.map((branch, index) => {
                  return (
                    <MenuItem value={branch.id} key={index}>
                      {branch.name}
                    </MenuItem>
                  )
                })}
              </MuiSelect>
            </FormControl>

            <br></br>
          </Container>
          {companyId == 0 ? null : (
            <Container>
              <br></br>
              <Row xs={1} md={3} className="g-4">
                <Col>
                  <Paper withBorder radius="md" p="xs">
                    <br></br>
                    <Button
                      style={{
                        float: 'right',
                        backgroundColor: 'transparent',
                      }}
                      onClick={() =>
                        getNumerationData(
                          numeration.id,
                          numeration.numeration,
                          numeration.createdAt,
                          numeration.updatedAt,
                        )
                      }
                    >
                      <Edit size={30} color="#000" />
                    </Button>
                    <Text
                      color="black"
                      size="xl"
                      transform="uppercase"
                      weight={700}
                    >
                      Numeración
                    </Text>
                    <Text
                      color="dimmed"
                      size="ml"
                      transform="uppercase"
                      weight={700}
                    >
                      Numeración actual: {numeration.numeration}
                    </Text>
                    <Text
                      color="dimmed"
                      size="ml"
                      transform="uppercase"
                      weight={700}
                    >
                      Creación el: {numeration.createdAt}
                    </Text>
                    <Text
                      color="dimmed"
                      size="ml"
                      transform="uppercase"
                      weight={700}
                    >
                      Modificado el: {numeration.updatedAt}
                    </Text>
                    <br></br>
                  </Paper>
                </Col>
              </Row>
            </Container>
          )}
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group direction="column" grow>
                  <Group grow mb="md" mt="md">
                    <TextField
                      variant="standard"
                      required
                      label="Número actual"
                      placeholder="Número actual"
                      value={putForm.values.numeration}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'numeration',
                          event.currentTarget.value,
                        )
                      }
                    />
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateNumeration()}
              >
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <br></br>
    </div>
  )
}
