import React, { useState } from 'react'
import { MDBPopover, MDBPopoverHeader, MDBPopoverBody } from 'mdb-react-ui-kit'
import { Edit } from 'tabler-icons-react'
import '../../../styles/modal.module.css'
import dayjs from 'dayjs'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Button from 'react-bootstrap/Button'

const ScheduleEventPopUp = ({ event, editEvent }) => {
  const [startEventTime, setStartEventTime] = useState(event.event.start)
  const [endEventTime, setEndEventTime] = useState(event.event.end)

  const setEventTime = (time, type) => {
    let eventTime = new Date(
      `2024-09-0${event.event.start.getDay() + 1}T00:00:00`,
    )
    eventTime.setHours(time.toDate().getHours(), time.toDate().getMinutes())
    if (type == 'start') setStartEventTime(eventTime)
    else setEndEventTime(eventTime)
  }

  const handleEditEvent = () => {
    let endEventDate = endEventTime
    if (startEventTime.getHours() > endEventTime.getHours()) {
      if (endEventTime.getDay() == 6) {
        endEventDate.setDate(endEventTime.getDate() - 6)
        let midTimeDown = new Date(`2024-09-07T11:00:00`)
        let midTimeUp = new Date(`2024-09-01T00:00:00`)
        editEvent({
          event: event.event,
          start: startEventTime,
          end: midTimeDown,
        })
        editEvent({
          event: {
            id: 7,
            title: event.event.title,
            start: midTimeUp,
            end: endEventDate,
            allDay: false,
          },
          start: midTimeUp,
          end: endEventDate,
        })
      } else {
        endEventDate.setDate(endEventTime.getDate() + 1)
        editEvent({
          event: event.event,
          start: startEventTime,
          end: endEventDate,
        })
      }
    } else {
      editEvent({
        event: event.event,
        start: startEventTime,
        end: endEventTime,
      })
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '5px',
      }}
    >
      <div style={{ width: '100%' }}>{event.event.title}</div>
      <div style={{ width: '100%' }}>
        {event.event.title != 'Descanso' ? (
          <MDBPopover
            popover
            clickable
            color="secondary"
            placement="right"
            btnChildren={<Edit size={20} color="white" />}
            color="transparent"
          >
            <MDBPopoverHeader
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <span>Editar evento</span>
              <span>
                Inicio:
                {dayjs(startEventTime).locale('es').format('dddd, h:mm A')}
              </span>
              <span>
                Fin: {dayjs(endEventTime).locale('es').format('dddd, h:mm A')}
              </span>
            </MDBPopoverHeader>
            <MDBPopoverBody>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="ES"
              >
                <TimePicker
                  label="HORA DE INICIO"
                  defaultValue={dayjs(startEventTime)}
                  onChange={(newValue) => setEventTime(newValue, 'start')}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <hr></hr>
                <TimePicker
                  label="HORA DE FIN"
                  defaultValue={dayjs(endEventTime)}
                  onChange={(newValue) => setEventTime(newValue, 'end')}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
              <Button
                onClick={handleEditEvent}
                style={{
                  backgroundColor: 'green',
                  isolation: 'isolate',
                  float: 'right',
                  margin: '10px',
                }}
                className="btn btn-primary"
              >
                Guardar
              </Button>
            </MDBPopoverBody>
          </MDBPopover>
        ) : null}
      </div>
    </div>
  )
}

export default ScheduleEventPopUp
