import React, { useState, useEffect } from 'react'
import { Group, Text } from '@mantine/core'
import 'react-toastify/dist/ReactToastify.css'
import { setDecimal, dateFormat } from '../../../../services/utils'
import {
  ChevronLeft,
  ChevronRight,
  Logout,
  ReportMoney,
  UserCircle,
} from 'tabler-icons-react'
import useStore from '../../../zustand'
import {
  getUserTotalSalesByDayApi,
  getUserTypeOfSalesByDayApi,
} from '../../../../pages/api/clientsale'
import Collapse from '@mui/material/Collapse'
import WorkShiftForm from '../../clientUsers/workShiftForm'
import { LogOut } from '../../../../services/Auth'
import { useNavigate } from 'react-router'

export default function CurrentClientUserState() {
  const { tokenData, isDarkModeActive } = useStore()
  const navigate = useNavigate()
  //----- Islero Tarjeta ---------------

  const [totalDeliveryCount, setTotalDeliveryCount] = useState([])
  const todayDate = new Date()

  const getTotalDeliveries = async (user) => {
    try {
      var tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      let today = dateFormat(todayDate.toLocaleDateString(), false, true)
      tomorrow = dateFormat(tomorrow.toLocaleDateString(), false, true)
      let result = await getUserTotalSalesByDayApi(
        user.clientId,
        today,
        tomorrow,
      )
      if (result.data != null) setTotalDeliveryCount(result.data)
      else setTotalDeliveryCount([])
    } catch (error) {
      return false
    }
  }

  const [deliveriesTypeTotal, setDeliveriesTypeTotal] = useState([])

  const getDeliveriesTypeTotal = async (user) => {
    try {
      var tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      let today = dateFormat(todayDate.toLocaleDateString(), false, true)
      tomorrow = dateFormat(tomorrow.toLocaleDateString(), false, true)
      let result = await getUserTypeOfSalesByDayApi(
        user.clientId,
        today,
        tomorrow,
      )
      if (result != null) setDeliveriesTypeTotal(result)
      else setDeliveriesTypeTotal([])
    } catch (error) {
      return false
    }
  }

  const [expanded, setExpanded] = React.useState(false)

  useEffect(() => {
    getTotalDeliveries(tokenData)
    getDeliveriesTypeTotal(tokenData)
  }, [])

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  const [showText, setShowText] = useState(false)

  //------------------------------

  return (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        left: 0,
        zIndex: 100,
      }}
    >
      <Collapse
        style={{
          width: '250px',
          backgroundColor: bgColor,
          border: '1px solid #e0e0e0',
          borderLeft: 'none',
          borderRadius: '0 0 10px 0',
          padding: '20px',
          height: '100%',
        }}
        in={expanded}
        timeout="auto"
        unmountOnExit
        orientation="horizontal"
      >
        <div>
          <Text
            size="lg"
            transform="uppercase"
            weight={500}
            mb={10}
            color={color}
          >
            <UserCircle
              style={{
                marginRight: '5px',
              }}
              size={35}
              color={color}
              strokeWidth={1.2}
            />
            {tokenData.clientName}
          </Text>
          <Group style={{ display: 'inline-block' }}>
            <Text color={color} size="md" transform="uppercase" weight={700}>
              Total facturado ${setDecimal(totalDeliveryCount)}
            </Text>
            <Text color={color} size="md" transform="uppercase" weight={700}>
              Ventas crédito: {deliveriesTypeTotal.credit}
            </Text>
            <Text color={color} size="md" transform="uppercase" weight={700}>
              Ventas débito: {deliveriesTypeTotal.debit}
            </Text>
          </Group>
          <hr></hr>
          <WorkShiftForm />
          <hr></hr>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: '#FFFFFF',
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #e0e0e0',
              marginTop: '10px',
              backgroundColor: 'rgb(13, 71, 161)',
            }}
            onClick={() => {
              navigate('/moneydelivery')
            }}
          >
            <span style={{ marginRight: '10px' }}>Entregas</span>
            <ReportMoney size={20} />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: color,
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #e0e0e0',
              marginTop: '10px',
            }}
            onClick={() => {
              LogOut()
            }}
          >
            <span style={{ marginRight: '10px' }}>Cerrar Sesión</span>
            <Logout size={20} />
          </div>
        </div>
      </Collapse>
      <div>
        <div
          style={{
            backgroundColor: bgColor,
            borderRadius: '0 10px 10px 0',
            border: '1px solid #e0e0e0',
            borderLeft: 'none',
            cursor: 'pointer',
            padding: '5px',
            color: { color },
          }}
          onMouseOver={() => setShowText(true)}
          onMouseOut={() => setShowText(false)}
          onClick={() => setExpanded(!expanded)}
        >
          <UserCircle color={color} />{' '}
          <span
            style={{
              color: color,
            }}
          >
            {showText ? 'Empleado' : ''}
          </span>
          {expanded ? (
            <ChevronLeft color={color} />
          ) : (
            <ChevronRight color={color} />
          )}
        </div>
      </div>
    </div>
  )
}
