import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getBundledSalesByIdApi = async (bundledSaleId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/bundledsale/sales-by-id/${bundledSaleId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BSALE-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas agrupadas.')
  }
}

export const getBundledSalesBySaleStateApi = async (
  companyId,
  branchId,
  clientId,
  saleState,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/bundledsale/by-salestate/${companyId}/${branchId}/${clientId}/${saleState}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BSALE-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify(
      'error',
      'Error al obtener las ventas agrupadas por estado de venta.',
    )
  }
}

export const postBundledSaleApi = async (bundledSale) => {
  try {
    let rawResult = await fetch(`${apilUrl}/bundledsale`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bundledSale),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BSALE-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear la venta agrupada.')
  }
}

export const UpdateBundledSaleApi = async (bundledSale, bundledSaleId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/bundledsale/${bundledSaleId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bundledSale),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BSALE-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar la venta agrupada.')
  }
}

export const getFilteredBundledSalesByThirdClientApi = async (
  companyId,
  branchId,
  thirdClientId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/bundledsale/filter/${companyId}/${branchId}/${thirdClientId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BSALE-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas agrupadas por cliente.')
  }
}

export const deleteBundledSaleApi = async (bundledSaleId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/bundledsale/${bundledSaleId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BSALE-6"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar la venta agrupada.')
  }
}

export const getFilteredBundledSalesByDatesApi = async (
  companyId,
  branchId,
  thirdClientId,
  startDate,
  endDate,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/bundledsale/filter-by-dates/${companyId}/${branchId}/${thirdClientId}/${startDate}/${endDate}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BSALE-7"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener las ventas agrupadas por fecha.')
  }
}

export const getBundledSalesByInvoiceNumberApi = async (
  companyId,
  branchId,
  invoiceNumber,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/bundledsale/filter/bundle-sale-id/${companyId}/${branchId}/${invoiceNumber}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"BSALE-8"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify(
      'error',
      'Error al obtener las ventas agrupadas por número de factura.',
    )
  }
}
