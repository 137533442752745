import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { getTypeKeyByValue, setDecimal } from '../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import {
  areaTypes,
  compensationTypes,
  contractTypes,
  epsTypes,
  layoffTypes,
  pensionTypes,
  workerTypes,
} from '../../../services/selectData/clientUsersData'
import { departmentsData } from '../../../services/selectData/placesData'
import { apilUrl } from '../../../services/url'
import {
  AlertCircle,
  Briefcase,
  Building,
  BuildingBank,
  BuildingHospital,
  CurrencyDollar,
  Edit,
  File,
  HomeDollar,
  HomeHeart,
  Mail,
  MapPin,
  Phone,
  PigMoney,
  UserCircle,
  Users,
} from 'tabler-icons-react'

const ClientUserInfo = ({
  clientUserDetails,
  branches,
  setModalEditUserShow = () => {},
  setClientUserToUpdate = () => {},
  editable = false,
}) => {
  const setDatasToUpdate = async (clientUser) => {
    setClientUserToUpdate(clientUser)
    setModalEditUserShow(true)
  }

  const [citiesOptions, setCitiesOptions] = React.useState([])

  const getMunicipios = async (department) => {
    try {
      let result = await fetch(`${apilUrl}/municipios/filters/${department}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
      setCitiesOptions(result.data)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    getMunicipios(clientUserDetails.idDepartment.Int32)
  }, [clientUserDetails.idDepartment])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          border: '1px solid gray',
          borderRadius: '10px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <h4
            style={{
              float: 'left',
              fontSize: '19px',
            }}
          >
            Detalles del empleado <b>{clientUserDetails.fullName}</b>
          </h4>
          {editable && (
            <Button
              style={{
                float: 'right',
                backgroundColor: 'green',
                margin: '5px',
                padding: '10px',
                isolation: 'isolate',
                width: '120px',
              }}
              onClick={() => setDatasToUpdate(clientUserDetails)}
            >
              <Edit size={25} />
              <Text
                style={{
                  fontSize: '12px',
                }}
              >
                Editar
              </Text>
            </Button>
          )}
        </div>
        <div
          style={{
            border: '1px solid gray',
            width: '95%',
            margin: '20px 10px',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Group
            style={{
              fontSize: '14px',
              display: 'grid',
            }}
          >
            <h5>Datos personales</h5>
            <DetailComponent
              icon={<Users />}
              title="Nombre completo"
              value={clientUserDetails.fullName}
            />
            <DetailComponent
              icon={<Mail />}
              title="Correo electrónico"
              value={clientUserDetails.email}
            />
            <DetailComponent
              icon={<Phone />}
              title="Teléfono"
              value={clientUserDetails.phone.String}
            />
            <DetailComponent
              icon={<MapPin />}
              title="Dirección"
              value={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span>{clientUserDetails.address.String}</span>
                  <span>
                    {getTypeKeyByValue(
                      citiesOptions,
                      'label',
                      clientUserDetails.cityCode.Int32,
                    ) +
                      ', ' +
                      getTypeKeyByValue(
                        departmentsData,
                        'label',
                        clientUserDetails.idDepartment.Int32,
                      )}
                  </span>
                </div>
              }
            />
            <DetailComponent
              icon={<Building />}
              title="Sede"
              value={getTypeKeyByValue(
                branches,
                'name',
                clientUserDetails.branchId,
                'id',
              )}
            />
            <DetailComponent
              icon={<Briefcase />}
              title="Área"
              value={getTypeKeyByValue(
                areaTypes,
                'label',
                clientUserDetails.area,
              )}
            />
          </Group>
          <Group
            style={{
              fontSize: '14px',
              display: 'grid',
              borderLeft: '1px solid #000',
              paddingLeft: '10px',
            }}
          >
            <h5>Datos laborales</h5>
            <DetailComponent
              icon={<CurrencyDollar />}
              title="Salario"
              value={setDecimal(clientUserDetails.salary, 2)}
            />
            <DetailComponent
              icon={<UserCircle />}
              title="Tipo de trabajador"
              value={getTypeKeyByValue(
                workerTypes,
                'label',
                clientUserDetails.workerType,
              )}
            />
            <DetailComponent
              icon={<AlertCircle />}
              title="Riesgo laboral"
              value={
                clientUserDetails.highRisk ? 'Alto riesgo' : 'Sin alto riesgo'
              }
            />
            <DetailComponent
              icon={<File />}
              title="Tipo de contrato"
              value={getTypeKeyByValue(
                contractTypes,
                'label',
                clientUserDetails.contractType,
              )}
            />
          </Group>
          <Group
            style={{
              fontSize: '14px',
              display: 'grid',
              borderLeft: '1px solid #000',
              paddingLeft: '10px',
            }}
          >
            <h5>Entidades</h5>
            <DetailComponent
              icon={<BuildingHospital />}
              title="EPS"
              value={getTypeKeyByValue(
                epsTypes,
                'label',
                clientUserDetails.eps,
              )}
            />
            <DetailComponent
              icon={<PigMoney />}
              title="Pensión"
              value={getTypeKeyByValue(
                pensionTypes,
                'label',
                clientUserDetails.pension,
              )}
            />
            <DetailComponent
              icon={<HomeDollar />}
              title="Cesantías"
              value={getTypeKeyByValue(
                layoffTypes,
                'label',
                clientUserDetails.layoffs,
              )}
            />
            <DetailComponent
              icon={<HomeHeart />}
              title="Caja de compensación"
              value={getTypeKeyByValue(
                compensationTypes,
                'label',
                clientUserDetails.compensation,
              )}
            />
            {clientUserDetails.accountNumber != undefined && (
              <DetailComponent
                icon={<BuildingBank />}
                title="Información Bancaria"
                value={`${
                  clientUserDetails.accountTypeKw === 1
                    ? 'Ahorros'
                    : 'Corriente'
                }
              ***
              ${clientUserDetails.accountNumber.substring(
                clientUserDetails.accountNumber.length - 3,
                clientUserDetails.accountNumber.length,
              )}`}
              />
            )}
          </Group>
        </div>
      </div>
    </div>
  )
}

export default ClientUserInfo

const DetailComponent = ({ icon, title, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      {icon}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
        }}
      >
        <span>
          <b>{title}</b>
        </span>
        <span>{value}</span>
      </div>
    </div>
  )
}
