import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import useStore from '../../../components/zustand'
import Button from 'react-bootstrap/Button'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Select as MuiSelect,
  Typography,
} from '@mui/material'
import { TextField } from '@mui/material'
import { Group } from '@mantine/core'
import { Calendar, Filter, ListNumbers } from 'tabler-icons-react'
import 'react-toastify/dist/ReactToastify.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'react-toggle/style.css'
import { MenuItem, InputLabel, FormControl } from '@mui/material'
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import { notify } from '../../../services/utils'
import { getThirdClientsByCompanyApi } from '../../../pages/api/thirdClients'
import { useForm } from '@mantine/hooks'

export default function SalesFilters(props) {
  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const {
    getFilteredSalesByDate,
    getFilteredSalesByInvoice,
    getSales,
    getBundledSales,
    isBundled,
    onThirdClientIdChange,
    offset,
    limit,
  } = props

  //-----------Filters-------------------

  const [filterExpanded, setFilterExpanded] = useState(false)
  const [filterStartDate, setFilterStartDate] = useState(dayjs(new Date()))
  const [filterEndDate, setFilterEndDate] = useState(dayjs(new Date()))
  const [filterChose, setFilterChose] = useState(1)
  const [invoiceNumberFilter, setInvoiceNumberFilter] = useState(0)
  const [minDate, setMinDate] = useState(dayjs(new Date()))

  const form = useForm({
    initialValues: {
      CREADA: true,
      APROBADA: true,
      'ENVIADA DIAN': true,
      'DIAN RECHAZADA': true,
      AGRUPADA: true,
    },
  })

  const FilterSales = async () => {
    setFilterExpanded(false)
    if (filterStartDate > filterEndDate) {
      notify('warning', 'La fecha inicial no puede ser mayor a la fecha final')
      return
    }
    // 1 = date, 2 = invoice
    switch (filterChose) {
      case 1:
        await getFilteredSalesByDate(
          thirdClientId,
          new Date(filterStartDate),
          new Date(filterEndDate),
          offset,
          limit,
          isBundled,
        )
        break
      case 2:
        await getFilteredSalesByInvoice(invoiceNumberFilter, isBundled)
        break
      default:
        break
    }
  }

  const setMinFilterDate = () => {
    const date = new Date()
    date.setMonth(date.getMonth() - 6)
    date.setDate(1)
    setMinDate(dayjs(date))
  }

  const [filterTab, setFilterTab] = useState('tab1')

  const handleFilterTabs = (value, filterId) => {
    if (value === filterTab) {
      return
    }
    setFilterChose(filterId)
    setFilterTab(value)
  }

  const resetFilters = async () => {
    setFilterExpanded(false)
    setThirdClientId(0)
    onThirdClientIdChange(0)
    setInvoiceNumberFilter(0)
    setFilterStartDate(dayjs(new Date()))
    setFilterEndDate(dayjs(new Date()))
    setFilterChose(1)
    setFilterTab('tab1')
    form.setValues({
      CREADA: true,
      APROBADA: true,
      'ENVIADA DIAN': true,
      'DIAN RECHAZADA': true,
      AGRUPADA: true,
    })
    await getSales(
      tokenData.companyId,
      tokenData.branchId,
      tokenData.clientId,
      rolesData,
      0,
      10,
    )
    await getBundledSales(
      tokenData.companyId,
      tokenData.branchId,
      thirdClientId,
      0,
      10,
    )
  }

  //--------select thirdclientclient -------

  const [thirdClientsOptions, setThirdClientsOptions] = useState([])
  const [thirdClientId, setThirdClientId] = useState(0)

  const getThirdClients = async () => {
    let result = await getThirdClientsByCompanyApi(tokenData.companyId)
    if (result.data != null) {
      let clientSelect = await result.data.map((x) => {
        return { value: x.id, label: x.thirdClientCompanyName }
      })
      setThirdClientsOptions(clientSelect)
    }
  }

  useEffect(() => {
    getThirdClients()
    setMinFilterDate()
  }, [])

  return (
    <Accordion
      className="filterButton"
      expanded={filterExpanded}
      onChange={() => setFilterExpanded(!filterExpanded)}
      style={{
        width: filterExpanded ? '20em' : '7em',
        height: filterExpanded ? 'auto' : '2.5em',
        top: '1em',
      }}
    >
      <AccordionSummary
        expandIcon={
          <Filter size={25} style={{ float: 'right', cursor: 'pointer' }} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <b>Filtros</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container>
          <MDBTabs className="mb-3 salesTab">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleFilterTabs('tab1', 1)}
                active={filterTab === 'tab1'}
                style={{ backgroundColor: 'transparent' }}
              >
                <Calendar size={20} /> Fecha
              </MDBTabsLink>
            </MDBTabsItem>
            {rolesData.admin ? (
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleFilterTabs('tab2', 2)}
                  active={filterTab === 'tab2'}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <ListNumbers size={20} /> Recibo
                </MDBTabsLink>
              </MDBTabsItem>
            ) : null}
          </MDBTabs>
          <MDBTabsContent>
            <MDBTabsPane show={filterTab === 'tab1'}>
              <Group>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Por empresa
                  </InputLabel>
                  <MuiSelect
                    required
                    label="Empresa"
                    value={thirdClientId}
                    onChange={(e) => {
                      setThirdClientId(e.target.value)
                      onThirdClientIdChange(e.target.value)
                    }}
                  >
                    <MenuItem value={0}>Todas las empresas</MenuItem>
                    {thirdClientsOptions.map((thirdClient, index) => {
                      return (
                        <MenuItem key={index} value={thirdClient.value}>
                          {thirdClient.label}
                        </MenuItem>
                      )
                    })}
                  </MuiSelect>
                </FormControl>
                <Group grow>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ES"
                  >
                    <DatePicker
                      label="FECHA INICIAL"
                      format="DD/MM/YYYY"
                      disableFuture={true}
                      minDate={minDate}
                      value={filterStartDate}
                      onChange={(date) => setFilterStartDate(date)}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                    <DatePicker
                      label="FECHA FINAL"
                      format="DD/MM/YYYY"
                      disableFuture={true}
                      minDate={minDate}
                      value={filterEndDate}
                      onChange={(date) => setFilterEndDate(date)}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </LocalizationProvider>
                </Group>
              </Group>
            </MDBTabsPane>
            <MDBTabsPane show={filterTab === 'tab2'}>
              <Group grow>
                <TextField
                  variant="standard"
                  type="number"
                  label="Número de recibo"
                  placeholder="Número de recibo"
                  onChange={(event) => {
                    setInvoiceNumberFilter(parseInt(event.currentTarget.value))
                  }}
                />
              </Group>
            </MDBTabsPane>
          </MDBTabsContent>
        </Container>
        <br></br>
        <Divider />
        <Group>
          <Button
            style={{
              marginTop: '20px',
              float: 'right',
              backgroundColor: 'rgb(13, 71, 161)',
              marginLeft: '5px',
            }}
            onClick={() => FilterSales()}
          >
            Filtrar
          </Button>
          <Button
            style={{
              marginTop: '20px',
              float: 'right',
              backgroundColor: '#EEEEEE',
              color: 'black',
            }}
            onClick={resetFilters}
          >
            Restablecer
          </Button>
        </Group>
      </AccordionDetails>
    </Accordion>
  )
}
