import Button from 'react-bootstrap/Button'
import { read, utils } from 'xlsx'
import React, { useEffect, useState } from 'react'
import useStore from '../../zustand'
import { notify } from '../../../services/utils'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { Trash } from 'tabler-icons-react'
import { CircularProgress } from '@mui/material'
import { Container } from 'react-bootstrap'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { PostBulkLedgerAccountsApi } from '../../../pages/api/iltda'

export default function LedgerAccountsUploadModal(props) {
  const { show, onClose } = props
  const { tokenData, isDarkModeActive } = useStore()

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const handleImport = ($event) => {
    const files = $event.target.files
    if (files.length) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = async (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          try {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
            setLoadedInfo(rows)
          } catch (e) {
            notify(
              'error',
              'Verifique que el nombre de todas las columnas sea correcto.',
            )
          }
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const [loadedAccounts, setLoadedAccounts] = useState([])

  const setLoadedInfo = (rows) => {
    let ledgerAccounts = []
    rows.map((row) => {
      let ledgerAccount = {
        ledgerAccountCode: row['CUENTA'].trim(),
        detail: row['NOMBRE'].trim(),
      }
      ledgerAccounts.push(ledgerAccount)
    })
    setLoadedAccounts(ledgerAccounts)
  }

  const handleUpload = async () => {
    await uploadAccounts(loadedAccounts)
  }

  const uploadAccounts = async (accounts) => {
    setIsCreateButtonDisabled(true)
    let result = await PostBulkLedgerAccountsApi(
      tokenData.companyId,
      tokenData.branchId,
      accounts,
    )
    if (result.data != null) successCreation(result)
    else notify('warning', 'Error al subir cuentas contables masivamente.')
    setIsCreateButtonDisabled(false)
  }

  const successCreation = async (data) => {
    if (data.data == 'Clientiltda created') {
      notify('success', 'Cuentas cargadas con éxito.')
      onClose()
    } else {
      notify('warning', data.data)
    }
  }

  const deleteAccount = (accountIndex) => {
    let loadedAccountsAux = [...loadedAccounts]
    loadedAccountsAux.splice(accountIndex, 1)
    setLoadedAccounts(loadedAccountsAux)
  }

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size="lg">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Carga masiva de cuentas contables
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            {loadedAccounts.length == 0 ? (
              <Container>
                <h4>Ahora, carga tu archivo</h4>
                <input
                  type="file"
                  name="file"
                  className="form-control form-control-xl"
                  id="inputGroupFile"
                  required
                  style={{ maxWidth: '400px' }}
                  onChange={handleImport}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </Container>
            ) : null}
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              disabled={loadedAccounts.length == 0 || isCreateButtonDisabled}
              style={{ backgroundColor: 'green', borderRadius: '100px' }}
              onClick={() => handleUpload()}
            >
              {isCreateButtonDisabled ? (
                <CircularProgress size={15} color="inherit" />
              ) : null}{' '}
              Subir
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cerrar
            </Button>
          </MDBModalFooter>
          <MDBModalBody>
            {loadedAccounts.length > 0 ? (
              <MDBTable small style={{ color: color }}>
                <MDBTableHead>
                  <tr>
                    <th>Cuenta Contable</th>
                    <th>Nombre</th>
                    <th>Acciones</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                  {loadedAccounts.map((loadedAccount, index) => {
                    return (
                      <tr key={index}>
                        <td>{loadedAccount.ledgerAccountCode}</td>
                        <td>{loadedAccount.detail}</td>
                        <td>
                          <Button
                            style={{
                              float: 'right',
                              backgroundColor: 'transparent',
                            }}
                            onClick={() => deleteAccount(index)}
                          >
                            <Trash size={25} color="red" />
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
            ) : null}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}
