import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useStore from '../../components/zustand'
import { Group, Text, Paper } from '@mantine/core'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { dateFormat, isEmpty } from '../../services/utils'
import { Eye, Tank } from 'tabler-icons-react'
//Token Verification
import LoadingScreen from '../../components/main/loadingScreen'
import SellerState from '../../components/client/statistics/states/sellerState'
import { setDecimal } from '../../services/utils'
import Clock from '../../components/custom/clock'
import IslandState from '../../components/client/statistics/states/IslandState'
import {
  getAllUsersClientSalesByDayApi,
  getUsersClientSalesTotalByDayApi,
} from '../api/clientsale'
import { getClientHosesApi } from '../api/clienthoses'
import { getClientTanksApi } from '../api/clientTanks'

export default function State() {
  const { tokenData, rolesData } = useStore()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  //Clients Tanks
  const [tanks, setTanks] = useState([])

  const getClientTanks = async (companyId, branchId) => {
    let result = await getClientTanksApi(companyId, branchId)
    if (result.data != null) setTanks(result.data)
    else {
      setTanks([])
      notify('warning', 'No hay tanques registrados.')
    }
  }

  //-------------hoses state----------------

  const [hoses, setHoses] = useState([])

  const getClientHoses = async (companyId, branchId) => {
    let result = await getClientHosesApi(companyId, branchId)
    if (result.data != null) setHoses(result.data)
    else setHoses([])
  }

  //--------------Clientsales--------------

  const [clients, setClients] = useState([])

  const getClientSales = async (companyId, branchId) => {
    try {
      var todayDate = new Date()
      var tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      let today = dateFormat(todayDate.toLocaleDateString(), false, true)
      tomorrow = dateFormat(tomorrow.toLocaleDateString(), false, true)
      let result = await getAllUsersClientSalesByDayApi(
        companyId,
        branchId,
        today,
        tomorrow,
      )
      if (result.data != null) {
        setClients(result.data)
      } else {
        setClients([])
      }
    } catch (error) {
      return error
    }
  }
  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.sales) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    } else {
      getStateInfo()
    }
  }

  //---------------------check today sales
  const [checkDeliveryTotal, setCheckDeliveryTotal] = useState(false)
  const [totalSalesToday, setTotalSalesToday] = useState(0)

  const getTotalSalesToday = async (companyId, branchId) => {
    try {
      var todayDate = new Date()
      var tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      let today = dateFormat(todayDate.toLocaleDateString(), false, true)
      tomorrow = dateFormat(tomorrow.toLocaleDateString(), false, true)
      let result = await getUsersClientSalesTotalByDayApi(
        companyId,
        branchId,
        today,
        tomorrow,
      )
      if (result.data != null) setTotalSalesToday(result.data)
      else setTotalSalesToday(0)
    } catch (error) {
      return error
    }
  }

  const onEyeClicked = async () => {
    setCheckDeliveryTotal(true)
    window.setTimeout(() => {
      setCheckDeliveryTotal(false)
    }, 5000)
  }

  const getStateInfo = async () => {
    await getClientTanks(tokenData.companyId, tokenData.branchId)
    await getClientHoses(tokenData.companyId, tokenData.branchId)
    await getClientSales(tokenData.companyId, tokenData.branchId)
    await getTotalSalesToday(tokenData.companyId, tokenData.branchId)
  }

  //------------------

  const MINUTE_MS = 7000
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
      const interval = setInterval(() => {
        getStateInfo()
      }, MINUTE_MS)
      return () => clearInterval(interval)
    } else setLoading(true)
  }, [tokenData, rolesData])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <Clock />
          <div
            style={{
              borderRadius: '2%',
              backgroundColor: 'rgb(250, 250, 250,0.5)',
              margin: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'rgb(250, 250, 250)',
                padding: '5px',
                marginBottom: '20px',
              }}
            >
              <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>
                Tanques
              </h4>
            </div>
            <br></br>
            <Container>
              <Row xs={1} md={4}>
                {tanks.map((tank, index) => {
                  return (
                    <Col
                      key={index}
                      style={{
                        display: 'block',
                        margin: 'auto',
                        width: '400px',
                        marginBottom: '50px',
                      }}
                    >
                      <StatsRing
                        name={tank.name}
                        tankSize={tank.tankSize}
                        gallons={tank.gallons}
                        createdAt={tank.createdAt}
                        size={120}
                      />
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
          <div
            style={{
              borderRadius: '2%',
              backgroundColor: 'rgb(250, 250, 250,0.5)',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgb(250, 250, 250)',
                padding: '5px',
                marginBottom: '20px',
              }}
            >
              <h4 style={{ textAlign: 'center' }}>Islas y Dispensadores</h4>
            </div>
            <div>
              <IslandState tanks={tanks} hoses={hoses} />
            </div>
          </div>
          {rolesData.admin ? (
            <div
              style={{
                borderRadius: '2%',
                backgroundColor: 'rgb(250, 250, 250,0.5)',
                margin: 10,
              }}
            >
              <div
                style={{
                  backgroundColor: 'rgb(250, 250, 250)',
                  padding: '5px',
                  marginBottom: '20px',
                }}
              >
                <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>
                  Isleros
                </h4>
              </div>
              <div
                style={{
                  borderRadius: '2%',
                  paddingTop: '15px',
                  margin: 10,
                  display: 'flex',
                }}
              >
                <Row xs={1} md={3} className="g-4">
                  <Row>
                    <Col>
                      <Paper
                        withBorder
                        radius="md"
                        p="xs"
                        style={{ width: '300px' }}
                      >
                        <Group>
                          <Text
                            color="dimmed"
                            size="md"
                            transform="uppercase"
                            weight={700}
                          >
                            Total recuadado del día:
                          </Text>
                          <Text
                            color="dimmed"
                            size="md"
                            transform="uppercase"
                            weight={700}
                          ></Text>
                          <Group style={{ display: 'inline-block' }}>
                            <Text
                              color="dimmed"
                              size="md"
                              transform="uppercase"
                              weight={700}
                            >
                              Total facturado $
                              {checkDeliveryTotal
                                ? setDecimal(totalSalesToday)
                                : '**********'}
                            </Text>
                          </Group>
                          <br></br>
                        </Group>{' '}
                        <Group>
                          <Eye
                            className="darkmode-ignore"
                            size={40}
                            color={checkDeliveryTotal ? 'gray' : 'green'}
                            style={{ float: 'right', cursor: 'pointer' }}
                            onClick={() => onEyeClicked()}
                          />
                        </Group>
                      </Paper>
                    </Col>
                  </Row>
                </Row>
              </div>
              <br></br>
              <div
                style={{
                  borderRadius: '2%',
                  paddingTop: '15px',
                  margin: 10,
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {clients.length > 0
                  ? clients.map((client, index) => {
                      return (
                        <SellerState
                          key={index}
                          client={client}
                          show={checkDeliveryTotal}
                        />
                      )
                    })
                  : null}
              </div>
            </div>
          ) : null}
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </div>
  )
}

export function StatsRing(props) {
  return (
    <Paper withBorder radius="md" p="xs">
      <Group>
        <div style={{ width: props.size, isolation: 'isolate' }}>
          <CircularProgressbar
            maxValue={props.tankSize}
            value={props.gallons}
            text={`${parseFloat(props.gallons).toFixed(2)}`}
            styles={buildStyles({
              textSize: '15px',
              pathColor: `green`,
              textColor: 'gray',
              trailColor: 'lightgray',
              backgroundColor: 'orange',
            })}
          />
        </div>
        <div>
          <Text color="dimmed" size="xl" transform="uppercase" weight={700}>
            {props.name}
          </Text>
          <Text color="dimmed" size="md" transform="uppercase" weight={700}>
            Tamaño({props.tankSize})
          </Text>
        </div>
      </Group>
    </Paper>
  )
}
