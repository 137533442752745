import React, { useEffect, useState } from 'react'
import { Group } from '@mantine/core'
import Container from 'react-bootstrap/Container'

import Button from 'react-bootstrap/Button'
import { useForm } from '@mantine/hooks'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { setDecimal } from '../../../services/utils'
import { apilUrl } from '../../../services/url'
import { notify } from '../../../services/utils'
import {
  Pagination,
  MenuItem,
  Select as SelectPages,
  InputLabel,
  FormControl,
} from '@mui/material'
import useStore from '../../zustand'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'

export function ProductHistorical(props) {
  const { tokenData } = useStore()
  const { show, onClose } = props

  //-------------Balancehistorical----------------

  const [historical, setHistorical] = useState([])
  const getCurrentHistorical = async (offset, limit) => {
    try {
      let result = await fetch(
        `${apilUrl}/clientproductshistorical/${tokenData.companyId}/${offset}/${limit}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      if (result.data != null) {
        setTotalBalanceNoteCount(result.count)
        setHistorical(result.data)
      } else {
        notify('warning', 'No hay histórico de cambios de precio.')
      }
    } catch (error) {
      return error
    }
  }

  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(5)
  const [totalBalanceNoteCount, setTotalBalanceNoteCount] = useState(0)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getCurrentHistorical(newOffset, rpg)
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    getCurrentHistorical(0, event.target.value)
  }

  //-----------------------

  useEffect(() => {
    getCurrentHistorical(pg * rpg - rpg, rpg)
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      {' '}
      <MDBModalDialog size="xl">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Histórico de Cambio de Precios
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <Container>
              <Group grow mb="md" mt="md" style={{ display: 'inline' }}>
                <MDBTable small>
                  <MDBTableHead>
                    <tr>
                      <th>Modificado por</th>
                      <th>Producto</th>
                      <th>Nuevo precio</th>
                      <th>Precio anterior</th>
                      <th>Fecha de creación</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                    {historical.map((historic, index) => {
                      return (
                        <tr key={index}>
                          <td>{historic.modifiedBy}</td>
                          <td>{historic.productName}</td>
                          <td>${setDecimal(historic.newPrice)}</td>
                          <td>${setDecimal(historic.wasPrice)}</td>
                          <td>{historic.createdAt}</td>
                        </tr>
                      )
                    })}
                  </MDBTableBody>
                </MDBTable>
              </Group>{' '}
              <Container>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Filas
                  </InputLabel>
                  <SelectPages
                    labelId="demo-simple-select-standard-label"
                    autoWidth={true}
                    value={rpg}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </SelectPages>
                </FormControl>
                <Pagination
                  count={Math.ceil(totalBalanceNoteCount / rpg)}
                  page={pg}
                  onChange={handleChangePage}
                  showFirstButton={true}
                  showLastButton={true}
                  style={{ display: 'inline-block' }}
                />
              </Container>
            </Container>
          </MDBModalBody>
          <MDBModalFooter>
            <br></br>
            <br></br>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
            <br></br>
            <br></br>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}
