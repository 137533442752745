import React, { useEffect, useState } from 'react'
import { Group } from '@mantine/core'
import Container from 'react-bootstrap/Container'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import secondsToTime from '../../../../services/utils'
import { notify } from '../../../../services/utils'
import {
  Pagination,
  MenuItem,
  Select as SelectPages,
  InputLabel,
  FormControl,
} from '@mui/material'
import useStore from '../../../zustand'
import { getAllEndTimeByClientUserIdApi } from '../../../../pages/api/endtime'
import { getReasonsApi } from '../../../../pages/api/reason'

export function EndTimeHistorical(props) {
  const { clientUser } = props
  const [historical, setHistorical] = useState([])
  const getCurrentHistorical = async (offset, limit) => {
    let result = await getAllEndTimeByClientUserIdApi(
      clientUser.id,
      offset,
      limit,
    )
    if (result.data != null) {
      setEntriesCount(result.count)
      setHistorical(result.data)
    } else {
      notify('warning', 'No hay histórico de cierres para este empleado.')
    }
  }

  const [reasons, setReasons] = useState([])

  const getReasons = async () => {
    const result = await getReasonsApi()
    if (result.data != null) setReasons(result.data)
    else setReasons([])
  }

  const getReasonById = (id) => {
    let reason = reasons.find((reason) => reason.id === id)
    return reason ? reason.name : ''
  }

  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(5)
  const [entriesCount, setEntriesCount] = useState(0)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getCurrentHistorical(newOffset, rpg)
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    getCurrentHistorical(0, event.target.value)
  }

  //-----------------------

  useEffect(() => {
    getCurrentHistorical(pg * rpg - rpg, rpg)
    getReasons()
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
  }, [isDarkModeActive])

  return (
    <Container>
      <Group grow mb="md" mt="md" style={{ display: 'inline' }}>
        <MDBTable small>
          <MDBTableHead>
            <tr style={{ textAlign: 'center' }}>
              <th>Razón</th>
              <th>Tiempo antes</th>
              <th>Tiempo después</th>
              <th>Fecha de salida</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
            {historical.map((historic, index) => {
              return (
                <tr key={index} style={{ textAlign: 'center' }}>
                  <td>{getReasonById(historic.reasonId)}</td>
                  <td>{secondsToTime(parseInt(historic.beforeTime))}</td>
                  <td>{secondsToTime(parseInt(historic.afterTime))}</td>
                  <td>{new Date(historic.date).toLocaleString('es-CO')}</td>
                </tr>
              )
            })}
          </MDBTableBody>
        </MDBTable>
      </Group>
      <Container>
        <FormControl variant="standard">
          <InputLabel id="demo-simple-select-standard-label">Filas</InputLabel>
          <SelectPages
            labelId="demo-simple-select-standard-label"
            autoWidth={true}
            value={rpg}
            onChange={handleChangeRowsPerPage}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </SelectPages>
        </FormControl>
        <Pagination
          count={Math.ceil(entriesCount / rpg)}
          page={pg}
          onChange={handleChangePage}
          showFirstButton={true}
          showLastButton={true}
          style={{ display: 'inline-block' }}
        />
      </Container>
    </Container>
  )
}
