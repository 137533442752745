import React, { useEffect, useState } from 'react'
import {
  apiErrorMessageHandler,
  notify,
  setDecimal,
} from '../../../services/utils'
import '../../../styles/saleEmail.css'
import { signatureUrl } from '../../../services/url'
import { useRef } from 'react'
import { apilUrl } from '../../../services/url'
import useStore from '../../zustand'
import { getWaysToPayApi } from '../../../pages/api/wayToPay'

export default function SaleTicketEmail(props) {
  const { tokenData } = useStore()
  const [branchData, setBranchData] = useState({
    clientName: 'test',
    branchName: 'Sucursal 1',
    branchAddress: '1',
    branchPhone: '123123213',
  })

  const logo = props.logo

  const getProductsTotalPrice = (products) => {
    let total = 0
    products.map((product) => {
      total += (product.price * product.quantity * (100 + product.vat)) / 100
    })

    return total
  }

  const EnumPaymentMethods = {
    1: 'CONTADO',
    2: 'CRÉDITO',
  }

  const [sales, setSales] = useState(props.sales)
  const [products, setProducts] = useState(JSON.parse(props.sales[0].products))

  const getBranchData = async () => {
    setBranchData(tokenData)
  }

  const componentRef = useRef()

  const sendHtmlToEmail = async (html, thirdClientId) => {
    try {
      let top =
        "<!DOCTYPE html><html lang='en'><head><meta charset='utf-8' /><meta name='viewport' content='width=device-width, initial-scale=1' /><title>Avril App</title><!-- Favicon --><link rel='icon' href='./images/favicon.png' type='image/x-icon' /><!-- Invoice styling --><style>body {font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;text-align: center;color: #777;}body h1 {font-weight: 300;margin-bottom: 0px;padding-bottom: 0px;color: #000;}body h3 {font-weight: 300;margin-top: 10px;margin-bottom: 20px;font-style: italic;color: #555;}body a {color: #06f;}.invoice-box {max-width: 800px;margin: auto;padding: 30px;border: 1px solid #eee;box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);font-size: 16px;line-height: 24px;font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;color: #555;}.invoice-box table {width: 100%;line-height: inherit;text-align: left;border-collapse: collapse;}.invoice-box table td {padding: 5px;vertical-align: top;}.invoice-box table tr td:nth-child(2) {text-align: right;}.invoice-box table tr.top table td {padding-bottom: 20px;}.invoice-box table tr.top table td.title {font-size: 45px;line-height: 45px;color: #333;}.invoice-box table tr.information table td {padding-bottom: 40px;}.invoice-box table tr.heading td {background: #eee;border-bottom: 1px solid #ddd;font-weight: bold;}.invoice-box table tr.details td {padding-bottom: 20px;}.invoice-box table tr.item td {border-bottom: 1px solid #eee;}.invoice-box table tr.item.last td {border-bottom: none;}.invoice-box table tr.total td:nth-child(2) {border-top: 2px solid #eee;font-weight: bold;}@media only screen and (max-width: 600px) {.invoice-box table tr.top table td {width: 100%;display: block;text-align: center;}.invoice-box table tr.information table td {width: 100%;display: block;text-align: center;}}</style></head><body>"
      let bottom = '</body></html>'
      let EmailHtml = top + html + bottom
      let saleData = { emailHtml: EmailHtml, thirdClientId: thirdClientId }
      let rawResult = await fetch(`${apilUrl}/email`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(saleData),
      }).then((response) => response)
      let result = await rawResult.json()
      if (result.error)
        apiErrorMessageHandler(
          '"SALEEMAIL-1"',
          rawResult.status,
          rawResult.statusText,
        )
      if (result.data) notify('success', 'Correo enviado correctamente.')
      else return { data: null }
    } catch (e) {
      return e
    }
  }

  const [waysToPay, setWaysToPay] = useState([])
  const getWaysToPay = async () => {
    let result = await getWaysToPayApi()
    if (result.data != null) setWaysToPay(result.data)
  }

  const getWayToPayText = (wayToPay) => {
    let wayToPayText = waysToPay.find((x) => x.id === wayToPay)
    if (wayToPayText != null) return wayToPayText.waytopay
    else return 'No definida'
  }

  const sendEmail = () => {
    let stringTo = componentRef.current.outerHTML
    sendHtmlToEmail(stringTo.replace(/"/g, "'"), props.sales[0].thirdClientId)
  }

  let sendedEmail = true
  useEffect(() => {
    getWaysToPay()
    if (sendedEmail == true) {
      getBranchData()
      setSales(props.sales)
      sendEmail()
      sendedEmail = false
    }
  }, [])

  return (
    <div ref={componentRef} className="invoice-box">
      <table>
        <tbody>
          <tr className="top">
            <td colSpan="3">
              <table>
                <tbody>
                  <tr>
                    <td className="title">
                      {/* <img
                        src={logo}
                        alt="AvrilAPP"
                        style={{
                          width: '180px',
                          margin: '20px',
                          textAlign: 'center',
                        }}
                      /> */}
                      <h5>{branchData.clientName}</h5>
                    </td>
                    <td style={{ textAlign: ' right' }}>
                      Recibo #: {sales[0].invoiceNumber}
                      <br />
                      Creado: {sales[0].createdAt}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="information">
            <td colSpan="3">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {branchData.branchName}
                      <br />
                      DIR: {branchData.branchAddress.toUpperCase()}
                      <br />
                      TEL: {branchData.branchPhone}
                    </td>
                    <td style={{ textAlign: ' right' }}>
                      {sales[0].thirdClientCompanyName}
                      <br />
                      Placa: {sales[0].busPlate}
                      <br />
                      Conductor: {sales[0].driverName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr className="heading">
            <td>Método de pago</td>

            <td>Forma de Pago</td>
            {sales[0].transferCode != '' ? (
              <td>Código de Transferencia</td>
            ) : (
              <td></td>
            )}
          </tr>

          <tr className="details">
            <td>{EnumPaymentMethods[sales[0].paymentMethod]}</td>

            <td>{getWayToPayText(sales[0].wayToPay)}</td>
            {sales[0].transferCode != '' ? (
              <td>{sales[0].transferCode}</td>
            ) : (
              <td></td>
            )}
          </tr>

          <tr className="heading">
            <td>Producto</td>
            <td>Cantidad</td>
            <td>Total</td>
          </tr>

          {products.map((product, index) => {
            return (
              <tr className="item" key={index}>
                <td>{product.name}</td>
                <td>{product.quantity}</td>
                <td>
                  $
                  {setDecimal(
                    product.price * product.quantity * (1 + product.vat / 100),
                  )}
                </td>
              </tr>
            )
          })}
          {sales[0].gallonsSold > 0 ? (
            <tr className="item">
              <td>Combustible</td>
              <td>{sales[0].gallonsSold} galón/es</td>
              <td>${setDecimal(sales[0].totalWithDiscount)}</td>
            </tr>
          ) : null}

          <tr className="total">
            <td></td>
            <td></td>
            <td>
              <b>Total:</b> $
              {setDecimal(
                parseFloat(sales[0].totalWithDiscount) +
                  getProductsTotalPrice(products, true),
              )}
            </td>
          </tr>

          <tr>
            <td>Firma del cliente: </td>
            <td>
              <img src={signatureUrl + sales[0].signature} width={100}></img>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
