import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const getGallonPricesApi = async (companyId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/gallon-price/company/${companyId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"GPRICE-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los precios del galón.')
  }
}

export const getGallonPricesByFuelTypeApi = async (companyId, fuelType) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/gallon-price/branch/by-fueltype/${companyId}/${fuelType.value}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"GPRICE-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los precios del galón.')
  }
}

export const updateGallonPriceApi = async (gallonPriceData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/gallon-price`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ gallons: gallonPriceData }),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"GPRICE-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el precio del galón.')
  }
}
