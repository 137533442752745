import { apilUrl } from './url'
const decodeToken = async () => {
  try {
    let result = await fetch(`${apilUrl}/verified-token/token-data`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
    return result.data
  } catch (error) {
    return false
  }
}

export default decodeToken
