import React from 'react'
import { setDecimal } from '../../../services/utils'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { Row } from 'react-bootstrap'
import useStore from '../../../components/zustand'
import calcularDigitoVerificacion from '../../../services/calcularDigitoVerificacion'

export default function SaleTicket(props) {
  const { companyData } = useStore()
  const company = companyData
  const branchData = props.branchdata
  const productsAdded = props.productsadded
  const totals = props.totals
  const sellerName = props.sellername
  const clientBusByPlate = props.clientbusbyplate[0]
  const busPlate = props.busplate
  const trimmedDataURL = props.trimmeddataurl
  const saleType = props.saleType
  const invoiceNumber = props.invoicenumber
  const logo = company[0].logo
  const hoseData =
    props.hosedata != null
      ? props.hosedata
      : {
          tankName: '-',
          name: '-',
          islandName: '-',
        }
  return (
    <div>
      {saleType != 0 ? (
        <Row
          xs={1}
          md={1}
          className="row justify-content-center"
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            display: 'inline-block',
          }}
        >
          <div>
            <img
              src={logo}
              alt="companyLogo"
              style={{ width: '180px', margin: '20px', textAlign: 'center' }}
            />
            <p style={{ textAlign: 'center', marginTop: '-20px' }}>
              ESTACIÓN DE SERVICIO
            </p>
            <p style={{ textAlign: 'center' }}>
              {company[0].name.toUpperCase()}
            </p>
            <p style={{ textAlign: 'center', marginTop: '-20px' }}>
              NIT: {company[0].nit} - {' '}
              {calcularDigitoVerificacion(company[0].nit)}
            </p>
            <p style={{ textAlign: 'center', marginTop: '-20px' }}>
              DIR: {company[0].address.toUpperCase()}
            </p>
            <p style={{ textAlign: 'center', marginTop: '-20px' }}>
              TEL: {company[0].phone}
            </p>
            <p style={{ textAlign: 'center', marginTop: '-20px' }}>
              {company[0].municipalitie.toUpperCase()} -{' '}
              {company[0].department.toUpperCase()}
            </p>
          </div>
          <div>
            {saleType != 1 ? (
              <MDBTable small style={{ margin: 'auto' }}>
                <MDBTableHead>
                  <tr>
                    <th>
                      <b>TANQ.</b>
                    </th>
                    <th>
                      <b>ISLA</b>
                    </th>
                    <th>
                      <b>MANG.</b>
                    </th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td>{hoseData.tankName}</td>
                    <td>{hoseData.islandName}</td>
                    <td>{hoseData.name}</td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            ) : null}
          </div>
          <MDBTable small style={{ textAlign: 'center', margin: 'auto' }}>
            <MDBTableHead>
              <tr>
                <th>
                  <b>PROD.</b>
                </th>
                <th>
                  <b>CANT.</b>
                </th>

                <th>
                  <b>PRECIO</b>
                </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {productsAdded.map((clientProducts, index) => {
                return (
                  <tr key={index}>
                    <td>{clientProducts.name.toUpperCase()}</td>
                    <td>{clientProducts.quantity}</td>
                    <td>${setDecimal(clientProducts.vatPrice)}</td>
                  </tr>
                )
              })}
              <tr>
                <td>Total</td>
                <td></td>
                <td>
                  <b>${setDecimal(totals)}</b>
                </td>
              </tr>
            </MDBTableBody>
          </MDBTable>
          <hr></hr>
          <div>
            <p style={{ marginLeft: 'auto', marginTop: '-20px' }}>
              Fecha: {getDateTime().toUpperCase()}
            </p>
            <p style={{ marginLeft: 'auto', marginTop: '-20px' }}>
              Recibo: {invoiceNumber}
            </p>
            <p style={{ marginLeft: 'auto', marginTop: '-20px' }}>
              Islero: {sellerName.toUpperCase()}
            </p>
          </div>

          <hr></hr>
          <div>
            <p
              style={{
                textAlign: 'center',
                marginTop: '-20px',
                maxWidth: '300px',
              }}
            >
              Nombre: {clientBusByPlate.thirdClientCompanyName.toUpperCase()}
            </p>
            <p style={{ textAlign: 'center', marginTop: '-20px' }}>
              Placa: {busPlate.toUpperCase()}
            </p>
          </div>
          <hr></hr>
          <div>
            <p style={{ textAlign: 'center', marginTop: 'auto' }}>AvrilAPP</p>
            <p style={{ textAlign: 'center', marginTop: 'auto' }}>
              GRACIAS POR SU COMPRA
            </p>
            <p>Firma:</p>
            <img src={trimmedDataURL} style={{ width: '120px' }} />
          </div>
        </Row>
      ) : (
        <div></div>
      )}
    </div>
  )
}

function getDateTime() {
  var now = new Date()
  var year = now.getFullYear()
  var month = now.getMonth() + 1
  var day = now.getDate()
  var hour = now.getHours()
  var minute = now.getMinutes()
  var second = now.getSeconds()
  var ampm = hour >= 12 ? 'PM' : 'AM'
  if (month.toString().length == 1) {
    month = '0' + month
  }
  if (day.toString().length == 1) {
    day = '0' + day
  }
  if (hour.toString().length == 1) {
    hour = '0' + hour
  }
  if (minute.toString().length == 1) {
    minute = '0' + minute
  }
  if (second.toString().length == 1) {
    second = '0' + second
  }
  var dateTime =
    year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ' ' + ampm
  return dateTime
}
