import React, { useEffect, useState } from 'react'
import companyLogo from '../../resources/images/horizontal-azul.png'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mantine/core'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { ArrowUpRightCircle, Logout, UserCircle } from 'tabler-icons-react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'
// ZUSTAND
import useStore from '../zustand'
import { verifiedRoles } from '../../services/roles.service'
import decodeToken from '../../services/decodeToken.service'
import { verifiedToken } from '../../services/token.service'
import { ChevronDown, ChevronUp } from 'tabler-icons-react'
import { notify } from '../../services/utils'
import { getCompanyByIdApi } from '../../pages/api/companies'
import { GetExpirationDateApi } from '../../pages/api/expirationDate'
import CustomNavBarDropdown from '../custom/customNavBarDropdown'
import CurrentClientUserState from './statistics/states/currentClientUserState'
import { Switch } from '@mui/material'
import { Offcanvas } from 'react-bootstrap'
import { LogOut } from '../../services/Auth'

export default function Menu() {
  const {
    addTokenData,
    addRoles,
    rolesData,
    tokenData,
    addCompanyData,
    isDarkModeActive,
    darkMode,
    setIsDarkModeActive,
  } = useStore()

  const navigate = useNavigate()

  const loadUserData = async () => {
    if (Object.keys(rolesData).length === 0) {
      let token = await decodeToken()
      if (token === false) return navigate('/')
      let verifiedTokenData = await verifiedToken()
      if (verifiedTokenData === false) return navigate('/')
      addTokenData(token)
      getIsExpired(token)

      let result = await verifiedRoles()
      addRoles(result[0])
      getCompany(token.companyId)
    }
  }

  const getCompany = async (companyId) => {
    let result = await getCompanyByIdApi(companyId)
    if (result.data != null) addCompanyData(result.data)
    else notify('error', 'Error al obtener la empresa.')
  }

  const getIsExpired = async (tokenData) => {
    let result = await GetExpirationDateApi(tokenData.companyId)
    if (result.data != 'Invalid Token') {
      if (result.data[0].isExpired) LogOut(true)
    }
  }

  useEffect(() => {
    loadUserData()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getIsExpired(tokenData)
      getCompany(tokenData.companyId)
    }, 1800000)
    return () => clearInterval(interval)
  }, [tokenData])

  const handleDarkMode = () => {
    darkMode.toggle()
    setIsDarkModeActive(!isDarkModeActive)
  }

  const [isMenuVisible, setIsMenuVisible] = useState(true)

  const [navBarMenuExpanded, setNavBarMenuExpanded] = useState(false)
  const toggleNavBar = () => {
    setNavBarMenuExpanded(!navBarMenuExpanded)
  }

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 10,
        }}
      >
        <Navbar
          collapseOnSelect
          fixed="top"
          expand="xxl"
          style={{
            backgroundColor: '#212121',
            color: 'white',
            position: 'sticky',
            top: '0',
            display: isMenuVisible ? 'flex' : 'none',
            justifyContent: 'space-around',
            padding: '5px 10px',
            height: '5em',
          }}
          variant="dark"
          onToggle={toggleNavBar}
        >
          <Navbar.Brand
            as={Link}
            to="/dashboard"
            style={{
              flexDirection: 'column',
              position: 'absolute',
              left: '5%',
            }}
          >
            <span
              style={{
                float: 'left',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <img
                src={companyLogo}
                alt="logo"
                style={{ width: '120px' }}
                draggable="false"
              />
              <i
                style={{
                  fontSize: '10px',
                  color: 'gray',
                  maxWidth: '100px',
                }}
              >
                {process.env.REACT_APP_CURRENT_VERSION}
              </i>
            </span>
          </Navbar.Brand>
          <Container>
            <div
              style={{
                display: 'flex',
              }}
            >
              {/* <Nav.Link as={Link} eventKey={2} to="/newsale">
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: 'green',
                    borderRadius: '100px',
                    color: '#FFFFFF',
                    borderColor: 'transparent',
                    isolation: 'isolate',
                  }}
                >
                  <ArrowUpRightCircle size={30} color="#FFFFFF" />
                  Vender
                </Button>
              </Nav.Link> */}
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Offcanvas
              placement="end"
              style={{
                backgroundColor: '#212121',
                color: 'white',
              }}
            >
              <Offcanvas.Header closeButton closeVariant="white">
                <Offcanvas.Title>
                  <img src={companyLogo} alt="CompanyLogo" width={150} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  style={{
                    margin: 'auto',
                  }}
                >
                  {rolesData.admin ? (
                    <Nav.Link
                      style={{
                        color: 'rgba(255,255,255,.55)',
                      }}
                      as={Link}
                      to="/state"
                    >
                      Estado
                    </Nav.Link>
                  ) : null}
                  {rolesData.sales ? (
                    <CustomNavBarDropdown
                      title="Ventas"
                      hoverable={!navBarMenuExpanded}
                    >
                      <NavDropdown.Item as={Link} to="/newsale">
                        Nueva Venta
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/sales">
                        Ventas
                      </NavDropdown.Item>{' '}
                      {rolesData.admin || rolesData.configuration ? (
                        <NavDropdown.Item as={Link} to="/comparesales">
                          Comparación Ventas
                        </NavDropdown.Item>
                      ) : null}
                    </CustomNavBarDropdown>
                  ) : null}
                  {rolesData.invoice ? (
                    <CustomNavBarDropdown
                      title="Facturación Electrónica"
                      hoverable={!navBarMenuExpanded}
                    >
                      <NavDropdown.Item as={Link} to="/invoice">
                        Factura Electrónica
                      </NavDropdown.Item>{' '}
                      <NavDropdown.Item as={Link} to="/creditnote">
                        Nota Crédito
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/debitnote">
                        Nota Débito
                      </NavDropdown.Item>
                    </CustomNavBarDropdown>
                  ) : null}
                  {rolesData.modules ? (
                    <CustomNavBarDropdown
                      title="Estación"
                      hoverable={!navBarMenuExpanded}
                    >
                      <NavDropdown.Item as={Link} to="/tanks">
                        Tanques
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/islands">
                        Islas
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/hoses">
                        Mangueras
                      </NavDropdown.Item>
                    </CustomNavBarDropdown>
                  ) : null}
                  {rolesData.clients ? (
                    <CustomNavBarDropdown
                      title="Clientes"
                      hoverable={!navBarMenuExpanded}
                    >
                      <NavDropdown.Item as={Link} to="/clients">
                        Clientes
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/driver-bus">
                        Buses
                      </NavDropdown.Item>
                    </CustomNavBarDropdown>
                  ) : null}
                  {rolesData.admin && (
                    <CustomNavBarDropdown
                      title="Nómina"
                      hoverable={!navBarMenuExpanded}
                    >
                      <NavDropdown.Item as={Link} to="/employees">
                        Empleados
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/settlement">
                        Liquidación
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/periods">
                        Historial de periodos
                      </NavDropdown.Item>
                    </CustomNavBarDropdown>
                  )}
                  {rolesData.configuration && (
                    <CustomNavBarDropdown
                      title="Configuración"
                      hoverable={!navBarMenuExpanded}
                    >
                      <NavDropdown.Item as={Link} to="/gallonprice">
                        Combustibles
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        component={Link}
                        to="/receiptdispenser"
                      >
                        Numeración de dispensador
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        component={Link}
                        to="/products"
                      >
                        Productos
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        as={Link}
                        component={Link}
                        to="/clientConcepts"
                      >
                        Conceptos de Venta
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        component={Link}
                        to="/ledgeraccounts"
                      >
                        Cuentas Contables
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        component={Link}
                        to="/paymentmethod"
                      >
                        Métodos de Pago
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/mobility">
                        Movilidad
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/employees">
                        Usuarios
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/company">
                        Empresa
                      </NavDropdown.Item>
                    </CustomNavBarDropdown>
                  )}

                  {/* {rolesData.configuration ||
                rolesData.invoice ||
                rolesData.admin ? (
                  <Nav.Link as={Link} to="/statistics">
                    Estadísticas
                  </Nav.Link>
                ) : null} */}
                </Nav>
                <Nav>
                  <CustomNavBarDropdown
                    title={
                      <span>
                        <UserCircle size={30} color="white" />{' '}
                        {tokenData.clientName &&
                          tokenData.clientName.toUpperCase()}
                      </span>
                    }
                    hoverable={!navBarMenuExpanded}
                  >
                    <NavDropdown.Item onClick={handleDarkMode}>
                      <span style={{ marginRight: '10px' }}>Tema oscuro </span>
                      <Switch
                        checked={isDarkModeActive}
                        color="success"
                        size="small"
                      />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => LogOut()}>
                      <span style={{ marginRight: '10px' }}>Cerrar sesión</span>
                      <Logout size={20} />
                    </NavDropdown.Item>
                  </CustomNavBarDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <Button
          variant="link"
          onClick={toggleMenu}
          style={{
            backgroundColor: '#212121',
            color: 'white',
            border: 'none',
            borderRadius: '0px 0px 10px 10px',
            width: 'fit-content',
            height: 'fit-content',
            right: 0,
          }}
        >
          {isMenuVisible ? (
            <ChevronUp size={20} color="white" />
          ) : (
            <ChevronDown size={20} color="white" />
          )}
        </Button>
      </div>
      <CurrentClientUserState />
      <div
        style={{
          marginTop: isMenuVisible ? '120px' : '40px',
        }}
      />
    </>
  )
}
