function calcularDigitoVerificacion(nit) {
  let vpri = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71]
  nit = nit.toString()
  let x = 0
  let y = 0
  let z = nit.length

  for (let i = 0; i < z; i++) {
    y = nit.substr(z - 1 - i, 1)
    x += y * vpri[i]
  }

  y = x % 11

  let digito = y > 1 ? 11 - y : y

  return digito < 10 ? digito : 0
}

export default calcularDigitoVerificacion
