import React from 'react'
import { Spinner } from 'react-bootstrap'
import useStore from '../zustand'

const LoadingScreen = () => {
  const { isDarkModeActive } = useStore()
  const backgroundColor = isDarkModeActive ? 'black' : 'white'
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          color: 'gray',
          margin: '10rem auto',
          position: 'relative',
        }}
      >
        <h1 style={{ color: 'gray' }}>Cargando módulo...</h1>
        <br></br>
        <Spinner></Spinner>
      </div>
    </div>
  )
}

export default LoadingScreen
