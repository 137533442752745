import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const clientUserLogoutApi = async () => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-users/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CUSERS-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al cerrar sesión.')
  }
}

export const getClientUsersApi = async (companyId, branchId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-users/${companyId}/${branchId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CUSERS-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener los usuarios del cliente.')
  }
}

export const PostClientUsersApi = async (clientUsersData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-users`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clientUsersData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CUSERS-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear el usuario del cliente.')
  }
}

export const DeleteClientUsersApi = async (clientUserId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-users/${clientUserId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CUSERS-4"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar el usuario del cliente.')
  }
}

export const UpdateClientUsersApi = async (clientId, clientUsersData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/client-users/${clientId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clientUsersData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CUSERS-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el usuario del cliente.')
  }
}

export const UpdateClientUserPasswordApi = async (clientId, password) => {
  try {
    let clientUsersData = {
      password: password,
    }
    let rawResult = await fetch(
      `${apilUrl}/client-users/password/${clientId}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientUsersData),
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CUSERS-6"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify(
      'error',
      'Error al actualizar la contraseña del usuario del cliente.',
    )
  }
}

export const clientUserLoginApi = async (email, password) => {
  try {
    let logIngData = {
      username: email,
      password: password,
    }
    let rawResult = await fetch(`${apilUrl}/client-users/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logIngData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error) {
      return result
    }
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error del servidor al iniciar sesión.')
    return null
  }
}

export const clientUserGetStartedApi = async (clientId, clientUsersData) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/client-users/get-started/${clientId}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientUsersData),
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CUSERS-8"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al configurar el cliente por primera vez.')
  }
}

export const UpdateClientUserActiveApi = async (clientUserId, active) => {
  try {
    let clientUsersData = {
      active: active,
    }
    let rawResult = await fetch(
      `${apilUrl}/client-users/active/${clientUserId}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientUsersData),
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CUSERS-9"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al activar/desactivar el usuario del cliente.')
  }
}
