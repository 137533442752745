import React, { useState, useEffect } from 'react'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group, Text, Paper } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoadingScreen from '../../components/main/loadingScreen'
import ReactJoyride from 'react-joyride'
import {
  GasStationOff,
  GasStation,
  Eye,
  QuestionMark,
  ShoppingCart,
  CurrencyDollar,
  BusStop,
} from 'tabler-icons-react'
import { isEmpty } from '../../services/utils'
import { useNavigate } from 'react-router'
import CurrentClientUserState from '../../components/client/statistics/states/currentClientUserState'
import SalesMenus from '../../components/client/shortcutMenus/salesMenus'
import GasSale from '../../components/client/sales/gasSale'
import ProductSale from '../../components/client/sales/productSale'
import { UpdateClientHoseStateApi, getClientHosesApi } from '../api/clienthoses'
import { getClientIslandsApi } from '../api/clientIslands'
import { getClientTankGallonsByIslandApi } from '../api/clientTanks'
import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from 'mdb-react-ui-kit'

export default function NewSale() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  const [modalPutShow, setModalPutShow] = React.useState(false)
  const [modalProductsShow, setModalProductsShow] = React.useState(false)

  /** 
  @description quantity of gallons to sell
  **/
  const [tankGallons, setTankGallons] = React.useState('')
  const [tankIdSetted, setTankIdSetted] = React.useState(0)

  /** 
  @description array of products added to the sale
  **/
  const [productsAdded, setProductsAdded] = useState([])

  /** 
  @description hose id setted to sell
  **/
  const [hoseIdSetted, setHoseId] = React.useState(0)

  //---------------------- Joyride -----------------------
  const [runJoyride, setRunJoyride] = useState(false)
  const [joyrideSteps] = useState([
    {
      content:
        'Este es el módulo de ventas, el cual usarán los encargados de venta tanto de productos como de gasolina.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      placement: 'center',
      target: 'body',
      title: 'Ventas',
    },
    {
      content: 'Aquí puedes vender sólo productos de la vitrina, sin gasolina.',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },

      spotlightPadding: 20,
      target: '.onlyproductssale_button',
      title: 'Venta de productos',
    },
    {
      content:
        'Aquí puedes vender gasolina y productos, sólo elige una manguera disponible.',
      placement: 'bottom',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.hoses_display',
      title: 'Venta con gasolina',
    },

    {
      content: (
        <div>Sólo podrás vender de una manguera mientras esté disponible.</div>
      ),
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.hose_state',
      title: 'Estados de la manguera',
    },
    {
      content: (
        <div>
          Ahora, para vender gasolina, sólo debes darle al ícono
          <GasStation
            size={30}
            color="green"
            className="darkmode-ignore"
          />{' '}
          para vender por galones, o bien a{' '}
          <CurrencyDollar size={30} color="green" className="darkmode-ignore" />{' '}
          para vender por precio.
        </div>
      ),
      placement: 'top',
      locale: { skip: 'Saltar', next: 'Siguiente', back: 'Atrás' },
      target: '.sell_gas_button',
      title: 'Estados de la manguera',
    },
    {
      content: (
        <div>
          Por último, puedes ver el estado de tus ventas en esta zona,
          presionando
          <Eye size={30} color="green" />
        </div>
      ),
      placement: 'top',
      locale: {
        skip: 'Saltar',
        next: 'Siguiente',
        back: 'Atrás',
        last: 'Terminar',
      },
      target: '.seller_info',
      title: 'Información del turno',
    },
  ])

  /**
  @description Callback function to handle the joyride steps, based on the status of the joyride
  @param {CallBackProps} CallBackProps to check the status of the joyride
  **/
  const handleJoyrideCallback = (CallBackProps) => {
    const status = CallBackProps.status
    if (status == 'ready') {
      setRunJoyride(false)
    }
  }

  const [currentIslandTab, setCurrentIslandTab] = useState('tab1')
  /**
  @description function to handle the tabs of the islands to display the hoses
  @param {string} tab to check and set the current island tab
  **/
  const handleBasicClick = (tab) => {
    if (tab === currentIslandTab) {
      return
    }
    setCurrentIslandTab(tab)
  }
  //----------------------------------Islands--------------------------------------

  /**
  @description Array of client islands
  **/
  const [islands, setIslands] = useState([])

  /**
    @description Get client islands, then set islands if data exists
    @param {string} companyId Company id from current user
    @param {string} branchId Branch id from current user
  **/
  const getClientIslands = async (companyId, branchId) => {
    let result = await getClientIslandsApi(companyId, branchId)
    if (result.data != null) {
      setIslands(result.data)
    } else {
      setIslands([])
      notify('warning', 'No hay islas registradas')
    }
  }

  //--------------------------------------

  /**
  @description Form to handle the values of the gallons to sell and the total price
   **/
  const form = useForm({
    initialValues: {
      galones: '',
      total: '',
    },
  })

  /**
  @param {boolean} isByGallons Boolean to know if the sale is by gallons or by price
  **/
  const [isByGallons, setIsByGallons] = useState(true)

  /**
  @description Get the gallons to sell and set the hose id to sell
  @param {number} hoseId Hose id to set the hose to sell
  @param {number} islandId Island id to set the island to sell
  @param {boolean} isByGallons Boolean to know if the sale is by gallons or by price
  **/
  const getTankGallons = async (hoseId, islandId, isByGallons) => {
    setHoseId(hoseId)
    setIsByGallons(isByGallons)
    restartTotals()
    form.setFieldValue('galones', '')

    let result = await getClientTankGallonsByIslandApi(
      tokenData.companyId,
      tokenData.branchId,
      islandId,
    )
    if (result.data != null) {
      setTankIdSetted(result.data[0].id)
      setTankGallons(result.data[0].gallons)
      await updateHoseState(hoseId, tokenData.clientId, tokenData.clientName)
    } else {
      notify('warning', 'No hay tanques registrados')
    }
  }

  /**
  @description Update the hose state to occupied
  @param {number} hoseId Hose id to set the hose client is using to sell
  @param {number} clientId Client id to set the client which is selling
  @param {string} clientName Client name to set the client which is selling
  **/
  const updateHoseState = async (hoseId, clientId, clientName) => {
    let hoseData = {
      clientId: parseInt(clientId),
      clientName: clientName,
      occupied: true,
    }
    const search = await hoses.find((element) => element.id == hoseId)
    if (search.clientId == 0 || search.clientId == tokenData.clientId) {
      setModalPutShow(true)

      let result = await UpdateClientHoseStateApi(hoseId, hoseData)
      if (result.data == 'ClientHoses updated') {
      } else {
        notify('warning', 'Error al actualizar')
      }
    } else {
      if (tokenData.clientId != search.clientId) {
        notify('warning', 'Manguera Ocupada')
      }
    }
  }

  /**
  @description Update the hose state to not occupied, but checks if the client is the same
  @param {number} hoseId Hose id to set the hose client is using to sell
  @param {number} clientId Client id to set the client which is selling
  **/
  const notBusy = async (hoseId, clientId) => {
    let hoseData = {
      clientId: 0,
      clientName: '',
      occupied: false,
    }
    if (clientId == tokenData.clientId) {
      let result = await UpdateClientHoseStateApi(hoseId, hoseData)
      if (result.data == 'ClientHoses updated') {
        await getClientHoses(tokenData.companyId, tokenData.branchId)
        notify('success', 'Manguera desocupada')
      } else {
        notify('warning', 'Error al desocupar la manguera')
      }
    } else {
      notify('warning', 'No tienes permiso para desocupar la manguera')
    }
  }

  /**
  @description Restart the totals and the products added
  **/
  const restartTotals = () => {
    setProductsAdded([])
  }

  /**
  @description Open the modal to sell only products
  **/
  const saleProductsOnlyModal = () => {
    restartTotals()
    setModalProductsShow(true)
  }

  /**
  @description Array of client hoses
  **/
  const [hoses, setHoses] = useState([])

  /**
   @description - Get client hoses, then set hoses if data exists
    @param {number} companyId Company id from current user
    @param {number} branchId Branch id from current user
  **/
  const getClientHoses = async (companyId, branchId) => {
    let result = await getClientHosesApi(companyId, branchId)
    if (result.data != null) setHoses(result.data)
    else setHoses([])
  }

  /**
  @description resets the constants to their initial state
  **/
  const resetConstants = () => {
    form.setFieldValue('galones', '')
    setProductsAdded([])
  }

  /**
  @description verifies if the user has the correct roles to see the module, then gets the client hoses and islands. if not, it will redirect to the dashboard
  **/
  const tokenVerified = async () => {
    if (!rolesData.sales) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await Promise.all([
      getClientHoses(tokenData.companyId, tokenData.branchId),
      getClientIslands(tokenData.companyId, tokenData.branchId),
    ]).finally(() => {
      setLoading(false)
    })
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      tokenVerified()
    }
  }, [tokenData, rolesData])

  /**
  @description Sets the totals, the products added and the gallons total number when the products added or the gallons total number change
  **/
  useEffect(() => {
    let subTotalProducts = 0
    productsAdded.map((x) => {
      subTotalProducts += x.vatPrice
    }, [])
    setProductsAdded(productsAdded)
    if (productsAdded.length <= 0) {
      subTotalProducts = 0
    }
  }, [productsAdded])

  return (
    <div>
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={runJoyride}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={joyrideSteps}
        styles={{
          options: {
            zIndex: 20,
            primaryColor: 'green',
          },
        }}
      />
      <Menu />
      {!loading ? (
        <Container>
          <Button
            onClick={() => setRunJoyride(true)}
            style={{ float: 'right', background: 'gray', border: 'none' }}
          >
            <QuestionMark /> Ayuda
          </Button>
          <SalesMenus buttonId={0} />
          <br></br>
          {modalPutShow ? (
            <GasSale
              setModalPutShow={setModalPutShow}
              hoseIdSetted={hoseIdSetted}
              resetConstants={resetConstants}
              tankGallons={tankGallons}
              tankIdSetted={tankIdSetted}
              notBusy={notBusy}
              isByGallons={isByGallons}
            />
          ) : modalProductsShow ? (
            <ProductSale
              setModalProductsShow={setModalProductsShow}
              hoseIdSetted={hoseIdSetted}
              resetConstants={resetConstants}
              tankGallons={tankGallons}
              tankIdSetted={tankIdSetted}
            />
          ) : (
            <div>
              <div>
                <Group
                  style={{ float: 'right' }}
                  direction="column"
                  className="seller_info"
                >
                  <Button
                    className="onlyproductssale_button"
                    style={{
                      backgroundColor: 'green',
                      borderRadius: '100px',
                      isolation: 'isolate',
                    }}
                    onClick={() => saleProductsOnlyModal()}
                  >
                    <ShoppingCart style={{ marginRight: '10px' }} />
                    Venta de Productos
                  </Button>
                </Group>
              </div>
              <Container>
                <MDBTabs className="mb-3 salesTab">
                  <MDBTabsItem style={{ display: 'flex' }}>
                    {islands.map((island, index) => {
                      return (
                        <MDBTabsLink
                          key={index}
                          onClick={() => handleBasicClick(`tab${index + 1}`)}
                          active={currentIslandTab === `tab${index + 1}`}
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <BusStop /> {island.name}
                        </MDBTabsLink>
                      )
                    })}
                  </MDBTabsItem>
                </MDBTabs>
                <MDBTabsContent>
                  {islands.map((island, index) => {
                    return (
                      <MDBTabsPane
                        key={index}
                        show={currentIslandTab === `tab${index + 1}`}
                      >
                        <Row xs={1} md={3} className="g-3 hoses_display">
                          {hoses
                            .filter((hose) => hose.islandId === island.id)
                            .map((hose, index) => {
                              return (
                                <Col key={index} style={{ width: '250px' }}>
                                  <Paper
                                    withBorder
                                    radius="md"
                                    border="transparent"
                                    className="hose_state"
                                    style={{
                                      width: '100%',
                                      border: '1px solid white',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: hose.occupied
                                          ? 'red'
                                          : 'green',
                                        isolation: 'isolate',
                                      }}
                                    >
                                      <Text
                                        color="white"
                                        size="xl"
                                        transform="uppercase"
                                        weight={500}
                                      >
                                        {hose.occupied
                                          ? hose.clientName
                                          : 'DISPONIBLE'}
                                      </Text>
                                    </div>
                                    <Group direction="row">
                                      <div
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Text
                                          color="dimmed"
                                          size="xl"
                                          p={5}
                                          transform="uppercase"
                                          weight={500}
                                        >
                                          {hose.name}
                                        </Text>
                                      </div>
                                      <div
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                        }}
                                        className="sell_gas_button"
                                      >
                                        <button
                                          style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            backgroundColor: 'transparent',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '5px 0px',
                                            border: 'none',
                                          }}
                                          onClick={() =>
                                            hose.occupied
                                              ? notBusy(hose.id, hose.clientId)
                                              : getTankGallons(
                                                  hose.id,
                                                  hose.islandId,
                                                  false,
                                                )
                                          }
                                        >
                                          <CurrencyDollar
                                            size={20}
                                            color={
                                              hose.occupied ? 'gray' : 'green'
                                            }
                                            className="darkmode-ignore"
                                          />
                                          <Text
                                            color={
                                              hose.occupied ? 'gray' : 'green'
                                            }
                                            size="sm"
                                            transform="capitalize"
                                            weight={600}
                                            style={{ isolation: 'isolate' }}
                                          >
                                            Valor
                                          </Text>
                                        </button>
                                        <button
                                          style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            backgroundColor: 'transparent',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '5px 0px',
                                            border: 'none',
                                          }}
                                          onClick={() =>
                                            hose.occupied
                                              ? notBusy(hose.id, hose.clientId)
                                              : getTankGallons(
                                                  hose.id,
                                                  hose.islandId,
                                                  true,
                                                )
                                          }
                                          className="sell_gas_button"
                                        >
                                          {hose.occupied ? (
                                            <GasStationOff
                                              size={20}
                                              color="gray"
                                              className="darkmode-ignore"
                                            />
                                          ) : (
                                            <GasStation
                                              size={20}
                                              color="green"
                                              className="darkmode-ignore"
                                            />
                                          )}
                                          <Text
                                            color={
                                              hose.occupied ? 'gray' : 'green'
                                            }
                                            size="sm"
                                            transform="capitalize"
                                            weight={600}
                                            style={{ isolation: 'isolate' }}
                                          >
                                            Galón
                                          </Text>
                                        </button>
                                      </div>
                                    </Group>
                                  </Paper>
                                </Col>
                              )
                            })}
                        </Row>
                      </MDBTabsPane>
                    )
                  })}
                </MDBTabsContent>
              </Container>
            </div>
          )}
          <br></br>
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </div>
  )
}
