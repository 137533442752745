import React, { useEffect } from 'react'
import { useForm } from '@mantine/hooks'
import { Text, Group, Button, Checkbox } from '@mantine/core'
import { apiErrorMessageHandler, notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { apilUrl } from '../../services/url'
import { TextField, Tooltip } from '@mui/material'
import { useState } from 'react'
import { BootstrapTooltip, validateEmail } from '../../services/utils'
import { useNavigate } from 'react-router-dom'
import { Input, InputLabel } from '@mui/material'
import { CircularProgress } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import useStore from '../../components/zustand'
import { QuestionMark } from 'tabler-icons-react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Col, Row } from 'react-bootstrap'

export default function Register() {
  const { isDarkModeActive } = useStore()

  const navigate = useNavigate()

  const [rutFile, setRutFile] = React.useState(null)
  const [camaraDeComercioFile, setCamaraDeComercioFile] = React.useState(null)
  const [cedulaDeRepresentanteFile, setCedulaDeRepresentante] =
    React.useState(null)
  // const [rutFileName, setRutFileName] = React.useState(null)

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      company: '',
      nit: '',
      password: '',
      validatePassword: '',
      terms: false,
    },

    validationRules: {
      email: (val) => /^\S+@\S+$/.test(val),
      password: (val) => val.length >= 6,
    },
  })

  const [isCreateButtonDisabled, SetIsCreateButtonDisabled] = useState(false)

  const successRegister = (data) => {
    if (data == 'user already exists') {
      notify('warning', 'El usuario ya se encuentra registrado')
    } else if (data == 'Company file failed') {
      notify('error', 'Error al registrar (code 428)')
    } else {
      notify('success', 'Registro Exitoso')
      navigate('/login')
    }
  }

  const register = async () => {
    let readyForRegister = true
    if (captchaState == false) {
      notify('warning', 'Complete el captcha.')
      readyForRegister = false
    }

    if (form.values.nit == '') {
      readyForRegister = false
      notify('warning', 'El NIT debe contener más de 6 dígitos.')
    }
    if (form.values.email == '') {
      readyForRegister = false
      notify('warning', 'Correo inválido.')
    }
    if (form.values.password.length < 6) {
      readyForRegister = false
    }
    if (form.values.validatePassword != form.values.password) {
      readyForRegister = false
      notify('warning', 'La contraseña no conciden.')
    }
    if (form.values.terms) {
      if (
        rutFile == null ||
        camaraDeComercioFile == null ||
        cedulaDeRepresentanteFile == null
      ) {
        readyForRegister = false
        notify('warning', 'Por favor verifica todo los archivos adjuntos.')
      }
    } else {
      readyForRegister = false
      notify(
        'warning',
        'Debes aceptar los términos y condiciones para continuar.',
      )
    }
    if (readyForRegister) {
      SetIsCreateButtonDisabled(true)
      let registerData = {
        fullName: form.values.name,
        companyName: form.values.company,
        nit: form.values.nit,
        username: form.values.email,
        email: form.values.email,
        password: form.values.password,
        rutFile: rutFile,
        camaraDeComercioFile: camaraDeComercioFile,
        cedulaDeRepresentanteFile: cedulaDeRepresentanteFile,
        logo: '', // logo vacío por registro
      }
      let rawResult = await fetch(`${apilUrl}/users-signup`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registerData),
      }).then((response) => response)
      let result = await rawResult.json()
      if (result.error)
        apiErrorMessageHandler(
          '"REGISTER"',
          rawResult.status,
          rawResult.statusText,
        )
      if (result.data) successRegister(result.data)
      else successRegister({ data: null })
    }
    SetIsCreateButtonDisabled(false)
  }

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }

  const checkIfIsPdf = (file) => {
    let fileExtention =
      file.substring(file.lastIndexOf('.') + 1, file.length) || file
    if (fileExtention == 'pdf') {
      return true
    } else {
      return false
    }
  }

  const handleRutFile = ({ target }) => {
    if (checkIfIsPdf(target.files[0].name)) {
      if (target.files < 1 || !target.validity.valid) {
        return
      }
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          let base64String = /,(.+)/.exec(result)[1]
          setRutFile(base64String)
          // setRutFileName(target.files[0])
        }
      })
    } else {
      notify('warning', 'El archivo debe ser un PDF.')
    }
  }

  const handleCamaraDeComercioFile = ({ target }) => {
    if (checkIfIsPdf(target.files[0].name)) {
      if (target.files < 1 || !target.validity.valid) {
        return
      }
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          let base64String = /,(.+)/.exec(result)[1]
          setCamaraDeComercioFile(base64String)
        }
      })
    } else {
      notify('warning', 'El archivo debe ser un PDF.')
    }
  }

  const handleCedulaDeRepresentanteFile = ({ target }) => {
    if (checkIfIsPdf(target.files[0].name)) {
      if (target.files < 1 || !target.validity.valid) {
        return
      }
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          let base64String = /,(.+)/.exec(result)[1]
          setCedulaDeRepresentante(base64String)
        }
      })
    } else {
      notify('warning', 'El archivo debe ser un PDF.')
    }
  }

  //-----------captcha-----------------

  const captcha = React.useRef(null)
  const [captchaState, setCaptchaState] = React.useState(false)
  const captchaHandler = () => {
    if (captcha.current.getValue()) {
      setCaptchaState(true)
    } else {
      setCaptchaState(false)
    }
  }

  const [color, setColor] = useState('black')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
  }, [isDarkModeActive])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <form
        onSubmit={form.onSubmit(() => {})}
        style={{
          display: 'flex',
        }}
      >
        <Row
          style={{
            display: 'flex',
            borderRadius: '10px',
            backgroundColor: 'white',
            margin: '110px 0',
            width: 'fit-content',
            padding: '0px',
          }}
        >
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '50px',
              margin: 0,
              backgroundColor: 'white',
              borderRadius: '10px 0px 0px 10px',
              minWidth: '450px',
            }}
          >
            <Group direction="column" align="center">
              <Text
                style={{
                  fontSize: '30px',
                }}
                weight={500}
                color="dimmed"
              >
                Registra tu empresa
              </Text>
            </Group>
            <br></br>
            <Group direction="column" grow>
              <Group grow mb="md">
                <TextField
                  variant="standard"
                  label="Nombre Completo"
                  placeholder="Nombre Completo"
                  onChange={(event) =>
                    form.setFieldValue('name', event.currentTarget.value)
                  }
                />
                <TextField
                  variant="standard"
                  required
                  label="Empresa"
                  placeholder="Nombre Empresa"
                  onChange={(event) =>
                    form.setFieldValue('company', event.currentTarget.value)
                  }
                />
              </Group>

              <Group grow mb="md">
                <div style={{ display: 'flex' }}>
                  <TextField
                    variant="standard"
                    required
                    label="NIT"
                    placeholder="NIT"
                    type="number"
                    onChange={(event) =>
                      form.setFieldValue('nit', event.currentTarget.value)
                    }
                  />
                  <BootstrapTooltip
                    disableFocusListener
                    title="Calculamos el dígito de verificación por ti"
                  >
                    <div>
                      DV
                      <div
                        style={{
                          height: '20px',
                          width: '20px',
                          backgroundColor: 'transparent',
                          borderRadius: '50%',
                          display: 'inline-block',
                          textAlign: 'center',
                          border: `2px solid ${color}`,
                          isolation: 'isolate',
                        }}
                      >
                        <QuestionMark
                          size={15}
                          style={{ marginBottom: '10px' }}
                          color={color}
                        ></QuestionMark>
                      </div>
                    </div>
                  </BootstrapTooltip>
                </div>
                <TextField
                  variant="standard"
                  autoComplete="off"
                  required
                  label="Correo"
                  placeholder="Correo Electrónico"
                  type="email"
                  onChange={(event) => {
                    validateEmail(event.currentTarget.value)
                      ? form.setFieldValue('email', event.currentTarget.value)
                      : form.setFieldValue('email', '')
                  }}
                />
              </Group>

              <FormControl variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Contraseña *
                </InputLabel>
                <Input
                  label="Contraseña"
                  autoComplete="off"
                  required
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(event) =>
                    form.setFieldValue('password', event.currentTarget.value)
                  }
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Confirme su contraseña *
                </InputLabel>
                <Input
                  label="Confirmar la contraseña"
                  autoComplete="off"
                  required
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(event) =>
                    form.setFieldValue(
                      'validatePassword',
                      event.currentTarget.value,
                    )
                  }
                />
              </FormControl>
            </Group>
          </Col>
          <Col
            style={{
              padding: '50px',
              margin: 0,
              borderRadius: '0px 10px 10px 0px',
              backgroundColor: 'white',
            }}
          >
            <div
              style={{
                margin: '20px 0px',
              }}
            >
              <label
                style={{
                  color: color,
                }}
                htmlFor="rut"
                className="form-label"
              >
                Rut (PDF) *
              </label>
              <input
                className="form-control form-control-sm"
                id="rut"
                type="file"
                required
                onChange={handleRutFile}
              />
            </div>
            <div
              style={{
                margin: '20px 0px',
              }}
            >
              <label
                style={{
                  color: color,
                }}
                htmlFor="camCom"
                className="form-label"
              >
                Cámara de comercio (PDF) *
              </label>
              <input
                className="form-control form-control-sm"
                id="camCom"
                type="file"
                required
                onChange={handleCamaraDeComercioFile}
              />
            </div>
            <div
              style={{
                margin: '20px 0px',
              }}
            >
              <label
                style={{
                  color: color,
                }}
                htmlFor="cedulaRep"
                className="form-label"
              >
                Cédula representante legal (PDF) *
              </label>
              <input
                className="form-control form-control-sm"
                id="cedulaRep"
                type="file"
                required
                onChange={handleCedulaDeRepresentanteFile}
              />
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px 0px',
              }}
            >
              <Checkbox
                checked={form.values.terms}
                onChange={(event) =>
                  form.setFieldValue('terms', event.currentTarget.checked)
                }
              />
              <Tooltip title="Ver términos y condiciones">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://files.avrilapp.com/politica.pdf"
                  style={{ fontSize: '15px', marginLeft: '10px' }}
                >
                  Acepto los términos y condiciones
                </a>
              </Tooltip>
            </div>
            <Group direction="column" align="center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                theme={isDarkModeActive ? 'dark' : 'light'}
                onChange={captchaHandler}
                ref={captcha}
              />
              <div>
                <Button
                  onClick={() => navigate('/login')}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '100px',
                    isolation: 'isolate',
                    color: 'black',
                    border: '1px solid black',
                    marginRight: '10px',
                  }}
                  disabled={isCreateButtonDisabled}
                >
                  Regresar
                </Button>
                <Button
                  type="submit"
                  onClick={() => register()}
                  style={{
                    backgroundColor: 'green',
                    borderRadius: '100px',
                    isolation: 'isolate',
                  }}
                  disabled={isCreateButtonDisabled}
                >
                  {isCreateButtonDisabled ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : null}{' '}
                  Registrar
                </Button>
              </div>
            </Group>
          </Col>
        </Row>
      </form>
    </div>
  )
}
