import React, { useState, useEffect, useCallback } from 'react'
import Container from 'react-bootstrap/Container'
import useStore from '../../zustand'
import {
  ButtonGroup,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import 'react-toastify/dist/ReactToastify.css'
import { notify } from '../../../services/utils'
import { PostScheduleApi } from '../../../pages/api/schedule'
import { dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import { Calendar } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ScheduleEventPopUp from './scheduleEventPopUp'
import { Select as MuiSelect } from '@mui/material'

const EditClientUserSchedule = ({
  getClientUserById,
  currentBranchId,
  setModalUpdateScheduleShow,
  currentClientUserId,
  getSchedules,
  schedules,
}) => {
  const { tokenData } = useStore()

  const [isUpdateScheduleButtonDisabled, setIsUpdateScheduleButtonDisabled] =
    useState(false)

  //------------------------Schedules------------------------
  const DnDCalendar = withDragAndDrop(Calendar)

  const [startEventTime, setStartEventTime] = useState(
    new Date('2024-09-01T00:00:00'),
  )
  const [endEventTime, setEndEventTime] = useState(
    new Date('2024-09-01T08:00:00'),
  )
  const [breakStartTime, setBreakStartTime] = useState(
    new Date('2024-09-01T05:00:00'),
  )
  const [breakEndTime, setBreakEndTime] = useState(
    new Date('2024-09-01T06:00:00'),
  )

  const setEventTime = (time, type) => {
    setSelectedShiftId(0)
    let eventTime = new Date('2024-09-01T00:00:00')
    eventTime.setHours(time.toDate().getHours(), time.toDate().getMinutes())
    if (type == 'start') setStartEventTime(eventTime)
    else setEndEventTime(eventTime)
  }

  const setBreakEventTime = (time, type) => {
    let eventTime = new Date('2024-09-01T00:00:00')
    eventTime.setHours(time.toDate().getHours(), time.toDate().getMinutes())
    if (type == 'start') setBreakStartTime(eventTime)
    else setBreakEndTime(eventTime)
  }

  const [selectedShiftId, setSelectedShiftId] = useState(0)
  const [shifts, setShifts] = useState([
    {
      id: 1,
      details: 'TURNO 1 - 06:00 AM - 11:59 PM',
      start: new Date('2024-09-01T06:00:00'),
      end: new Date('2024-09-01T23:59:00'),
    },
    {
      id: 2,
      details: 'TURNO 2 - 12:00 PM - 17:59 PM',
      start: new Date('2024-09-01T12:00:00'),
      end: new Date('2024-09-01T17:59:00'),
    },
    {
      id: 3,
      details: 'TURNO 3 - 18:00 PM - 23:59 AM',
      start: new Date('2024-09-01T18:00:00'),
      end: new Date('2024-09-01T23:59:00'),
    },
    {
      id: 4,
      details: 'TURNO 4 - 24:00 PM - 05:59 AM',
      start: new Date('2024-09-01T00:00:00'),
      end: new Date('2024-09-01T05:59:00'),
    },
  ])

  const handleSelectShift = (shiftId) => {
    setSelectedShiftId(shiftId)
    let shift = shifts.find((shift) => shift.id == shiftId)
    setStartEventTime(shift.start)
    setEndEventTime(shift.end)
  }

  const [workDays, setWorkDays] = useState([
    { active: false, name: 'domingo' },
    { active: false, name: 'lunes' },
    { active: false, name: 'martes' },
    { active: false, name: 'miércoles' },
    { active: false, name: 'jueves' },
    { active: false, name: 'viernes' },
    { active: false, name: 'sábado' },
  ])

  const [daysChoosed, setDaysChoosed] = useState(false)
  const [scheduleEvents, setScheduleEvents] = useState([])

  const setWorkDaysData = (index) => {
    let newWorkDays = [...workDays]
    newWorkDays[index].active = !newWorkDays[index].active
    setWorkDays(newWorkDays)
  }

  const getScheduleByClientUserId = (clientUserId) => {
    let result = schedules.find(
      (schedule) => schedule.clientUserId == clientUserId,
    )
    if (result != undefined) {
      let scheduleList = JSON.parse(result.schedules)
      setWorkDays(scheduleList.workDays)
      setScheduleEvents(eventsDateToObject(scheduleList.events))
      setDaysChoosed(true)
    }
  }

  const eventsDateToObject = (events) => {
    let eventsObject = []
    events.map((event) => {
      eventsObject.push({
        id: event.id,
        title: event.title,
        start: dayjs(event.start).toDate(),
        end: dayjs(event.end).toDate(),
        allDay: event.allDay,
        shift: event.shift,
      })
    })
    return eventsObject
  }

  const localizer = dayjsLocalizer(dayjs)

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true
      }

      setScheduleEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end, allDay }]
      })
    },
    [setScheduleEvents],
  )

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setScheduleEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end }]
      })
    },
    [setScheduleEvents],
  )

  const editEvent = ({ event, start, end }) => {
    setScheduleEvents((prev) => {
      const existing = prev.find((ev) => ev.id === event.id) ?? {}
      const filtered = prev.filter((ev) => ev.id !== event.id)
      return [...filtered, { ...existing, start, end }]
    })
  }

  const eventPropGetter = useCallback(
    // events props setter
    (event) => ({
      ...{
        style: {
          backgroundColor: 'rgba(0, 128, 0, 0.8)',
          border: isDarkModeActive ? '1px solid white' : '1px solid black',
        },
      },
      ...(event.title == 'Descanso' && {
        style: {
          backgroundColor: 'white',
          color: 'black',
          fontSize: '12px',
        },
      }),
    }),
    [],
  )

  const createBreakEvents = (events) => {
    workDays.map((day, index) => {
      if (day.active == false) return
      let fixedStartDate = new Date('2024-09-01T00:00:00')
      let fixedEndDate = new Date('2024-09-01T00:00:00')
      fixedStartDate.setDate(fixedStartDate.getDate() + index)
      fixedStartDate.setHours(
        breakStartTime.getHours(),
        breakStartTime.getMinutes(),
      )
      fixedEndDate.setDate(breakEndTime.getDate() + index)
      fixedEndDate.setHours(breakEndTime.getHours(), breakEndTime.getMinutes())
      events.push({
        id: index + 10,
        title: 'Descanso',
        start: dayjs(fixedStartDate).toDate(),
        end: dayjs(fixedEndDate).toDate(),
        allDay: false,
        shift: selectedShiftId,
      })
    })
    setScheduleEvents(events)
  }

  const createWeekEvents = () => {
    let events = []
    workDays.map((day, index) => {
      if (day.active == false) return

      let fixedStartDate = new Date('2024-09-01T00:00:00')
      let fixedEndDate = new Date('2024-09-01T00:00:00')
      fixedStartDate.setDate(fixedStartDate.getDate() + index)
      fixedStartDate.setHours(
        startEventTime.getHours(),
        startEventTime.getMinutes(),
      )
      fixedEndDate.setDate(endEventTime.getDate() + index)
      fixedEndDate.setHours(endEventTime.getHours(), endEventTime.getMinutes())

      let endEventDate = fixedEndDate
      if (fixedStartDate.getHours() > fixedEndDate.getHours()) {
        if (fixedEndDate.getDay() == 6) {
          endEventDate.setDate(fixedEndDate.getDate() - 6)
          let midTimeDown = new Date(`2024-09-07T11:00:00`)
          let midTimeUp = new Date(`2024-09-01T00:00:00`)
          events.push({
            id: index,
            title: getClientUserById(currentClientUserId).fullName,
            start: fixedStartDate,
            end: midTimeDown,
            allDay: false,
            shift: selectedShiftId,
          })
          events.push({
            id: 7,
            title: getClientUserById(currentClientUserId).fullName,
            start: midTimeUp,
            end: endEventDate,
            allDay: false,
            shift: selectedShiftId,
          })
        } else {
          endEventDate.setDate(fixedEndDate.getDate() + 1)
          events.push({
            id: index,
            title: getClientUserById(currentClientUserId).fullName,
            start: fixedStartDate,
            end: endEventDate,
            allDay: false,
            shift: selectedShiftId,
          })
        }
      } else {
        events.push({
          id: index,
          title: getClientUserById(currentClientUserId).fullName,
          start: dayjs(fixedStartDate).toDate(),
          end: dayjs(fixedEndDate).toDate(),
          allDay: false,
          shift: selectedShiftId,
        })
      }
    })
    createBreakEvents(events)
    setDaysChoosed(true)
  }

  const createSchedule = async () => {
    setIsUpdateScheduleButtonDisabled(true)
    let readyToCreate = true
    if (scheduleEvents.length == 0) {
      readyToCreate = false
      notify('warning', 'Por favor seleccione los días de trabajo')
    }
    if (readyToCreate) {
      let scheduleData = {
        companyId: tokenData.companyId,
        branchId: currentBranchId,
        clientUserId: currentClientUserId,
        schedules: JSON.stringify({
          events: scheduleEvents, // hours of work
          workDays: workDays, // days of work (boolean)
        }),
      }
      let result = await PostScheduleApi(scheduleData)
      if (result.data == 'Schedule created successfully') {
        notify('success', 'Horario creado')
        await getSchedules(tokenData.companyId, tokenData.branchId)
        setModalUpdateScheduleShow(false)
        resetScheduleConstants()
      } else {
        notify('warning', 'Error al crear el horario')
      }
    }
    setIsUpdateScheduleButtonDisabled(false)
  }

  const resetScheduleConstants = () => {
    setWorkDays([
      { active: false, name: 'domingo' },
      { active: false, name: 'lunes' },
      { active: false, name: 'martes' },
      { active: false, name: 'miércoles' },
      { active: false, name: 'jueves' },
      { active: false, name: 'viernes' },
      { active: false, name: 'sábado' },
    ])
    setScheduleEvents([])
    setDaysChoosed(false)
    setIsUpdateScheduleButtonDisabled(false)
    setSelectedShiftId(0)
  }

  //----------------------------------------------------------

  useEffect(() => {
    getScheduleByClientUserId(currentClientUserId)
  }, [currentClientUserId])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <Container
      style={{
        border: '1px solid gray',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <Group direction="column" grow>
        <h4>
          Modificando Turno de {getClientUserById(currentClientUserId).fullName}
        </h4>
        {!daysChoosed ? (
          <Group direction="column" grow>
            <h4>Días laborales</h4>
            <ButtonGroup className="me-2" aria-label="First group">
              {workDays.map((day, index) => {
                return (
                  <Button
                    key={index}
                    style={{
                      backgroundColor: day.active ? 'green' : 'white',
                      color: day.active ? 'white' : 'black',
                      borderRadius: '0%',
                    }}
                    onClick={() => setWorkDaysData(index)}
                  >
                    {day.name.toUpperCase()}
                  </Button>
                )
              })}
            </ButtonGroup>
            <Group direction="column" mb="md" mt="md">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Turno
                </InputLabel>
                <MuiSelect
                  label="Turno"
                  onChange={(e) => handleSelectShift(e.target.value)}
                  value={selectedShiftId}
                >
                  <MenuItem value={0}>Personalizado</MenuItem>
                  {shifts.map((shift) => {
                    return <MenuItem value={shift.id}>{shift.details}</MenuItem>
                  })}
                </MuiSelect>
              </FormControl>
            </Group>
            <Group grow mb="md" mt="md">
              <Group grow mb="md" mt="md" direction="column">
                <h4>Jornada</h4>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ES"
                >
                  <TimePicker
                    label="HORA DE INICIO"
                    value={dayjs(startEventTime)}
                    onChange={(newValue) => setEventTime(newValue, 'start')}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <TimePicker
                    label="HORA DE FIN"
                    value={dayjs(endEventTime)}
                    onChange={(newValue) => setEventTime(newValue, 'end')}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </LocalizationProvider>
              </Group>
              <Group grow mb="md" mt="md" direction="column">
                <h4>Descanso</h4>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ES"
                >
                  <TimePicker
                    label="HORA DE INICIO"
                    value={dayjs(breakStartTime)}
                    onChange={(newValue) =>
                      setBreakEventTime(newValue, 'start')
                    }
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <TimePicker
                    label="HORA DE FIN"
                    value={dayjs(breakEndTime)}
                    onChange={(newValue) => setBreakEventTime(newValue, 'end')}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </LocalizationProvider>
              </Group>
            </Group>
          </Group>
        ) : null}
        {daysChoosed ? (
          <Group grow>
            <DnDCalendar
              popup
              resizable
              showMultiDayTimes
              defaultDate={new Date('2024-09-01T00:00:00')}
              defaultView="week"
              toolbar={false}
              views={['week']}
              events={scheduleEvents}
              localizer={localizer}
              onEventDrop={moveEvent}
              onEventResize={resizeEvent}
              eventPropGetter={eventPropGetter}
              components={{
                event: (event) => ScheduleEventPopUp({ event, editEvent }),
              }}
              formats={{
                dayFormat: (date, culture, localizer) =>
                  localizer.format(dayjs(date).locale('es'), 'dddd', culture),
              }}
            />
          </Group>
        ) : null}
        <Group>
          {daysChoosed ? (
            <Button
              disabled={isUpdateScheduleButtonDisabled}
              style={{
                backgroundColor: 'green',
                borderRadius: '100px',
                isolation: 'isolate',
              }}
              onClick={createSchedule}
            >
              {isUpdateScheduleButtonDisabled ? (
                <CircularProgress size={15} color="inherit" />
              ) : null}{' '}
              Actualizar
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: 'green',
                borderRadius: '100px',
                isolation: 'isolate',
              }}
              onClick={() => createWeekEvents()}
            >
              Continuar
            </Button>
          )}
          {daysChoosed ? (
            <Button
              style={{
                backgroundColor: 'green',
                borderRadius: '100px',
                isolation: 'isolate',
              }}
              onClick={resetScheduleConstants}
            >
              Limpiar
            </Button>
          ) : null}
          <Button
            style={{
              backgroundColor: bgColor,
              borderRadius: '100px',
              color: color,
            }}
            onClick={() => {
              resetScheduleConstants()
              setModalUpdateScheduleShow(false)
            }}
          >
            Cerrar
          </Button>
        </Group>
      </Group>
    </Container>
  )
}

export default EditClientUserSchedule
