import React, { useState, useEffect } from 'react'
import useStore from '../../../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { UpdateClientUserPasswordApi } from '../../../../pages/api/clientUsers'

const EditCredentialsModal = (props) => {
  const { show, onClose, getClientUsers, clientUserToUpdatePassword } = props
  const { tokenData } = useStore()

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const putPasswordForm = useForm({
    initialValues: {
      password: '',
      validatePassword: '',
    },
  })

  //----------------------------------------------------------

  const updateClientPassword = async () => {
    let readyForUpdatePassword = true
    if (
      putPasswordForm.values.validatePassword == '' ||
      putPasswordForm.values.password == ''
    ) {
      notify('warning', 'Por favor llena todos los campos.')
      readyForUpdatePassword = false
    }
    if (
      putPasswordForm.values.validatePassword != putPasswordForm.values.password
    ) {
      notify('warning', 'La contraseña no conciden.')
      readyForUpdatePassword = false
    }

    if (readyForUpdatePassword) {
      let result = await UpdateClientUserPasswordApi(
        clientUserToUpdatePassword.id,
        putPasswordForm.values.password,
      )
      if (result.data == 'Client Password Changed') {
        notify('success', 'Actualización Exitoso')
        onClose()
        await getClientUsers(tokenData.companyId, tokenData.branchId)
      } else {
        notify('warning', 'Error al actualizar contraseña')
      }
    }
  }

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])
  return (
    <MDBModal
      show={show}
      staticBackdrop
      aria-labelledby="contained-modal-title-vcenter"
    >
      <MDBModalDialog size="sm">
        <MDBModalContent
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >
          <MDBModalHeader>
            <MDBModalTitle id="contained-modal-title-vcenter">
              Modificando Contraseña
            </MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <form>
              <Group direction="column" grow>
                <FormControl variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    Nueva Contraseña *
                  </InputLabel>
                  <Input
                    autoComplete="off"
                    required
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(event) =>
                      6 <= event.currentTarget.value.length
                        ? putPasswordForm.setFieldValue(
                            'password',
                            event.currentTarget.value,
                          )
                        : putPasswordForm.setFieldValue('password', '')
                    }
                  />
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    Confirme la Contraseña *
                  </InputLabel>
                  <Input
                    autoComplete="off"
                    required
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(event) =>
                      6 <= event.currentTarget.value.length
                        ? putPasswordForm.setFieldValue(
                            'validatePassword',
                            event.currentTarget.value,
                          )
                        : putPasswordForm.setFieldValue('validatePassword', '')
                    }
                  />
                </FormControl>
              </Group>
            </form>
          </MDBModalBody>
          <MDBModalFooter>
            <Button
              style={{
                backgroundColor: 'green',
                borderRadius: '100px',
                isolation: 'isolate',
              }}
              onClick={() => updateClientPassword()}
            >
              Actualizar
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                borderRadius: '100px',
                color: color,
              }}
              onClick={() => onClose()}
            >
              Cerrar
            </Button>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}

export default EditCredentialsModal
