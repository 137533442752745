import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import { Text } from '@mantine/core'
import useStore from '../../components/zustand'
import { moneySignatureUrl, apilUrl } from '../../services/url'
import Button from 'react-bootstrap/Button'
import { Group } from '@mantine/core'
import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import { Select as MuiSelect } from '@mui/material'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import saleIcon from '../../resources/images/factura.png'
import { Coin } from 'tabler-icons-react'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import SignatureCanvas from 'react-signature-canvas'
import LoadingScreen from '../../components/main/loadingScreen'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { Lock } from 'tabler-icons-react'
import {
  FormControl,
  InputLabel,
  Select as SelectPages,
  MenuItem,
  Pagination,
} from '@mui/material'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { dateFormat, isEmpty, setDecimal } from '../../services/utils'
import Toggle from 'react-toggle'
import SaleModal from '../../components/client/sales/modals/saleModal'
import MoneyDeliverySalesModal from '../../components/client/modals/moneyDeliverySalesModal'
import MoneyDeliveryFilters from '../../components/client/filters/moneyDeliveryFilters'
import {
  getClientSalesByIdApi,
  getClientSalesByUserTodayApi,
} from '../api/clientsale'
import { getClientUsersApi } from '../api/clientUsers'
export default function Moneydelivery() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  //Clients Users
  const [Users, setClientusers] = useState([])

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  const [amount, setAmount] = useState(0)
  const [deliverBy, setDeliverBy] = useState('')
  const [deliverById, setDeliverById] = useState(0)

  //FOR SIGNING PAD
  const [trimmedDataURL, setTrimmedDataURL] = useState(null)
  const [signatureRef, setSignatureRef] = React.useState({})
  const [signatureState, setSignatureState] = useState(true)

  const clearSignature = () => {
    signatureRef.clear()
    setTrimmedDataURL(null)
    setSignatureState(true)
    signatureRef.on()
  }
  const trimSignature = () => {
    if (signatureRef.isEmpty()) {
      notify('warning', 'Falta firmar la factura, intente de nuevo.')
    } else {
      setSignatureState(false)
      setTrimmedDataURL(signatureRef.getTrimmedCanvas().toDataURL('image/png'))
      signatureRef.off()
    }
  }

  const successCreation = async (data) => {
    if (data == 'user already exists') {
      notify('warning', 'El usuario ya se encuentra registrado')
    } else if (data == 'MoneySignature image failed') {
      notify('error', 'Error al crear (code 428)')
    } else {
      notify('success', 'Creación Exitoso')
      await getDeliveries(
        tokenData.companyId,
        tokenData.branchId,
        pg * rpg - rpg,
        rpg,
      )
    }
    resetConstants()
    setModalCreateShow(false)
    setIsCreateButtonDisabled(false)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createDelivery = async () => {
    let readyToCreate = true

    if (deliverById == 0) {
      notify('warning', 'Por favor seleccione un islero.')
      readyToCreate = false
    }

    if (stringifyGroupSalesIds() == '') {
      notify('warning', 'Por favor seleccione al menos una venta.')
      readyToCreate = false
    }

    if (trimmedDataURL == null) {
      notify('warning', 'Por favor firme la entrega.')
      readyToCreate = false
    }

    if (amount == 0) {
      notify('warning', 'Por favor ingrese el monto a entregar.')
      readyToCreate = false
    }

    if (readyToCreate) {
      setIsCreateButtonDisabled(true)
      let clientUsersData = {
        amount: parseFloat(amount),
        branchId: tokenData.branchId,
        companyId: tokenData.companyId,
        createdBy: tokenData.clientName,
        deliverBy: deliverBy,
        createdById: tokenData.clientId,
        deliverById: deliverById,
        signature: trimmedDataURL,
        salesId: stringifyGroupSalesIds(),
      }
      fetch(`${apilUrl}/moneydelivery`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientUsersData),
      })
        .then((response) => response.json())
        .then((data) => successCreation(data.data))
    }
  }

  const [clientSales, setClientSales] = useState([])
  const todayDate = new Date()

  const getClientSales = async (clientId) => {
    try {
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      let today = dateFormat(todayDate.toLocaleDateString(), false, true)
      tomorrow = dateFormat(tomorrow.toLocaleDateString(), false, true)
      let result = await getClientSalesByUserTodayApi(
        tokenData.companyId,
        tokenData.branchId,
        clientId,
        today,
        tomorrow,
      )
      if (result.data != null) {
        setClientSales(result.data)
        return result.data
      } else {
        setClientSales([])
        notify('warning', 'El islero no ha hecho ventas hoy.')
        return []
      }
    } catch (error) {
      return []
    }
  }

  const getProductsTotalInitialPrice = (bill) => {
    let total = 0
    let products = JSON.parse(bill.products)
    products.map((product) => {
      total +=
        (product.price * product.quantity * (100 + parseInt(product.vat))) / 100
    })
    return total
  }

  const getClientUsers = async (companyId, branchId) => {
    let result = await getClientUsersApi(companyId, branchId)
    if (result.data != null) setClientusers(result.data)
    else {
      notify('warning', 'No hay usuarios en esta sucursal')
      setClientusers([])
    }
  }

  //--------------------Check today sales by user--------------

  const getDeliveriesByDateAndUser = (date, userId) => {
    let todayDeliveries = []
    deliveries.map((delivery) => {
      let createdAt = delivery.createdAt
        .split(' ')[0]
        .split('-')
        .reverse()
        .join('/')
      if (createdAt == date && delivery.deliverById == userId) {
        todayDeliveries.push(delivery)
      }
    })
    return todayDeliveries
  }

  const [salesToDeliver, setSalesToDeliver] = useState([])

  const compareSalesWithDeliveries = (sales, deliveries) => {
    let salesIds = []
    let notDeliveredSales = []
    deliveries.map((delivery) => {
      let deliverySalesId = delivery.salesId.split(',')
      deliverySalesId.map((id) => salesIds.push(parseInt(id)))
    })
    sales.map((sale) => {
      if (!salesIds.includes(sale.id)) {
        notDeliveredSales.push(sale)
      }
    })
    if (notDeliveredSales.length == 0)
      notify('info', 'No hay ventas a entregar para este islero hoy.')
    setSalesToDeliver(notDeliveredSales)
  }

  //--------------deliveries------------------

  const [deliveries, setDeliveries] = useState([])

  const getDeliveries = async (companyId, branchId, offset, limit) => {
    try {
      let result = await fetch(
        `${apilUrl}/moneydelivery/all/${companyId}/${branchId}/${offset}/${limit}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      if (result.count > 0 && result.data != null) {
        setTotalDeliveryCount(result.count)
        setDeliveries(result.data)
      } else {
        notify('warning', 'No hay entregas registradas.')
      }
    } catch (error) {
      return false
    }
  }

  const getDeliveriesByUserOrClientUser = async (
    createdById = 0,
    deliverById = 0,
    startDate,
    endDate,
    offset,
    limit,
  ) => {
    try {
      let result = await fetch(
        `${apilUrl}/moneydelivery/filter/${tokenData.companyId}/${tokenData.branchId}/${createdById}/${deliverById}/${startDate}/${endDate}/${offset}/${limit}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      if (result.count > 0 && result.data != null) {
        setTotalDeliveryCount(result.count)
        setDeliveries(result.data)
      } else {
        notify('warning', 'No hay entregas registradas.')
      }
    } catch (error) {
      return false
    }
  }

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.configuration || !rolesData.invoice || !rolesData.admin) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await getDeliveries(
      tokenData.companyId,
      tokenData.branchId,
      pg * rpg - rpg,
      rpg,
    )
    await getClientUsers(tokenData.companyId, tokenData.branchId)
  }

  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(10)
  const [totalDeliveryCount, setTotalDeliveryCount] = useState(0)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg

    await getDeliveries(tokenData.companyId, tokenData.branchId, newOffset, rpg)
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)

    getDeliveries(
      tokenData.companyId,
      tokenData.branchId,
      0,
      event.target.value,
    )
  }

  //---------- group sales --------------

  const [groupSales, setGroupSales] = useState([])
  const [groupSalesIds, setGroupSalesIds] = useState([])

  const addToGroupSales = (id, sale) => {
    let group = [...groupSales]
    let ids = [...groupSalesIds]
    group.push({ saleId: sale.id, invoicenumber: sale.invoiceNumber })
    ids.push(id)
    setGroupSales(group)
    setGroupSalesIds(ids)
  }
  const deleteFromGroupSales = (id) => {
    let group = [...groupSales]
    let ids = [...groupSalesIds]
    const index = ids.indexOf(id)
    if (index > -1) {
      group.splice(index, 1)
      ids.splice(index, 1)
    }
    setGroupSales(group)
    setGroupSalesIds(ids)
  }

  const stringifyGroupSalesIds = () => {
    let string = ''
    groupSales.map((sale) => {
      string += sale.saleId + ','
    })
    string = string.slice(0, -1)
    return string
  }

  const getSaleBySaleId = (saleId) => {
    let sale = clientSales.find((sale) => sale.id == saleId)
    return sale
  }

  const getTotalPriceSelectedSales = () => {
    let total = 0
    if (groupSales.length == 0) return setDecimal(total)
    groupSales.map((sale) => {
      total += getProductsTotalInitialPrice(getSaleBySaleId(sale.saleId))
    })
    return setDecimal(total)
  }

  //--------------show related sales------------------

  const [delivery, setDelivery] = useState(0)
  const [showMoneyDeliverySalesModal, setShowMoneyDeliverySalesModal] =
    useState(false)
  const handleShowMoneyDeliverySalesModal = (delivery) => {
    setDelivery(delivery)
    setShowMoneyDeliverySalesModal(true)
  }
  const handleCloseMoneyDeliverySalesModal = () => {
    setShowMoneyDeliverySalesModal(false)
  }

  //------------------

  const handleInChargeChange = async (inChargeId) => {
    let name = Users.find((user) => user.id == inChargeId)
    setDeliverBy(name.fullName)
    setDeliverById(inChargeId)
    let sales = await getClientSales(inChargeId)
    let deliveries = getDeliveriesByDateAndUser(
      todayDate.toLocaleDateString(),
      inChargeId,
    )
    compareSalesWithDeliveries(sales, deliveries)
  }

  const handleInChangeAmount = (amount) => {
    setAmount(amount)
  }

  const resetConstants = () => {
    setAmount(0)
    setDeliverBy('')
    setDeliverById(0)
    setGroupSales([])
    setGroupSalesIds([])
    if (signatureRef != null) clearSignature()
    setSignatureState(true)
  }

  //--------------------Sale info detallada---------------

  const [saleInfo, setSaleInfo] = useState([])

  const [showSaleModal, setShowSaleModal] = useState(false)
  const handleShowSaleModal = () => setShowSaleModal(true)
  const handleCloseSale = () => setShowSaleModal(false)

  const showSale = async (saleId) => {
    let result = await getClientSalesByIdApi(saleId)
    if (result.data != null) {
      setSaleInfo(result.data)
      handleShowSaleModal()
    }
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container style={{ display: 'flex', flexDirection: 'column' }}>
          <Container>
            <Button
              variant="primary"
              style={{
                backgroundColor: 'green',
                borderRadius: '70px',
                marginBottom: 10,
                float: 'left',
                isolation: 'isolate',
              }}
              onClick={() => setModalCreateShow(true)}
            >
              <Coin /> Nueva Entrega
            </Button>
            <br></br>
          </Container>
          <br></br>
          <Container>
            <Group>
              <MoneyDeliveryFilters
                getDeliveries={getDeliveries}
                getDeliveriesByUserOrClientUser={
                  getDeliveriesByUserOrClientUser
                }
              />
              <MDBTable small>
                <MDBTableHead>
                  <tr>
                    <th>Encargado</th>
                    <th>Islero</th>
                    <th>Cantidad</th>
                    <th>Fecha</th>
                    <th>Ventas Asociadas</th>
                    <th>Firma</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ color: color, isolation: 'isolate' }}>
                  {deliveries.map((delivery, index) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: isDarkModeActive
                            ? '#424242'
                            : 'transparent',
                        }}
                      >
                        <td>{delivery.createdBy}</td>
                        <td>{delivery.deliverBy}</td>
                        <td>${setDecimal(delivery.amount)}</td>
                        <td>{delivery.createdAt}</td>
                        <td
                          style={{
                            margin: 'auto',
                          }}
                        >
                          <a
                            onClick={() => {
                              handleShowMoneyDeliverySalesModal(delivery)
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              style={{
                                float: 'right',
                                float: 'inline-start',
                                alignContent: 'center',
                                cursor: 'pointer',
                                width: '40px',
                              }}
                              src={saleIcon}
                            />
                          </a>
                        </td>
                        <td>
                          <img
                            src={moneySignatureUrl + delivery.signature}
                            width={100}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
              <Container>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Filas
                  </InputLabel>
                  <SelectPages
                    labelId="demo-simple-select-standard-label"
                    autoWidth={true}
                    value={rpg}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </SelectPages>
                </FormControl>
                <Pagination
                  count={Math.ceil(totalDeliveryCount / rpg)}
                  page={pg}
                  onChange={handleChangePage}
                  showFirstButton={true}
                  showLastButton={true}
                  style={{ display: 'inline-block' }}
                />
              </Container>
              {showMoneyDeliverySalesModal ? (
                <MoneyDeliverySalesModal
                  delivery={delivery}
                  show={showMoneyDeliverySalesModal}
                  onClose={handleCloseMoneyDeliverySalesModal}
                />
              ) : null}
            </Group>
          </Container>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nueva Entrega
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group mb="md" mt="md" grow>
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Islero
                    </InputLabel>
                    <MuiSelect
                      value={deliverById}
                      label="Islero"
                      onChange={(e) => handleInChargeChange(e.target.value)}
                    >
                      {Users.map((user, index) => {
                        return (
                          <MenuItem value={user.id} key={index}>
                            {user.fullName}
                          </MenuItem>
                        )
                      })}
                    </MuiSelect>
                  </FormControl>
                </Group>
                {deliverById != 0 && salesToDeliver.length > 0 ? (
                  <Container>
                    <FloatingLabel>
                      <TextField
                        label="Monto a entregar"
                        variant="standard"
                        type="text"
                        autoComplete="off"
                        value={amount}
                        onChange={(e) => handleInChangeAmount(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </FloatingLabel>

                    <MDBTable small className="salesTable">
                      <MDBTableHead
                        style={{ color: color, isolation: 'isolate' }}
                      >
                        <tr>
                          <th style={{ textAlign: 'center' }}>
                            Número de factura
                          </th>
                          <th style={{ textAlign: 'center' }}>Hora de venta</th>
                          <th style={{ textAlign: 'center' }}>Total</th>
                          <th className="saleInfo">Información detallada</th>
                          <th style={{ textAlign: 'center' }}>Asignar</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody
                        style={{ color: color, isolation: 'isolate' }}
                      >
                        {salesToDeliver.map((sale, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: isDarkModeActive
                                  ? '#424242'
                                  : 'transparent',
                              }}
                            >
                              <td>{sale.invoiceNumber}</td>
                              <td>{sale.createdAt}</td>
                              <td>
                                $
                                {setDecimal(getProductsTotalInitialPrice(sale))}
                              </td>
                              <td
                                style={{
                                  width: '100px',
                                  margin: 'auto',
                                  textAlign: 'center',
                                }}
                              >
                                <a
                                  onClick={() => showSale(sale.id)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img
                                    style={{
                                      float: 'right',
                                      float: 'inline-start',
                                      alignContent: 'center',
                                      cursor: 'pointer',
                                      width: '40px',
                                    }}
                                    src={saleIcon}
                                  />
                                </a>
                              </td>
                              <td>
                                {sale.saleState == 5 ||
                                sale.saleState == 3 ||
                                sale.saleState == 4 ||
                                sale.saleState == 2 ? (
                                  <Toggle
                                    className="custom-classname"
                                    disabled={true}
                                  />
                                ) : (
                                  <Toggle
                                    className="custom-classname"
                                    onChange={(e) => {
                                      e.currentTarget.checked
                                        ? addToGroupSales(sale.id, sale)
                                        : deleteFromGroupSales(sale.id)
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </MDBTableBody>
                    </MDBTable>
                    <Text>
                      <b>Total:</b> ${getTotalPriceSelectedSales()}
                    </Text>
                  </Container>
                ) : (
                  <h4>Seleccione una empresa en los filtros</h4>
                )}
                <br></br>

                <div style={{ textAlign: 'center' }}>
                  <Text>Firma:</Text>
                  <Container
                    style={{ border: '1px solid black', width: 'fit-content' }}
                  >
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 440,
                        height: 200,
                        border: '1px solid black',
                      }}
                      ref={(ref) => {
                        setSignatureRef(ref)
                      }}
                    />
                    {!signatureState ? <Lock /> : null}
                  </Container>
                  <Button
                    style={{
                      backgroundColor: 'green',
                      borderRadius: '100px',
                      margin: '10px',
                      isolation: 'isolate',
                    }}
                    onClick={() => clearSignature()}
                  >
                    Limpiar
                  </Button>
                  <Button
                    style={{
                      backgroundColor: 'green',
                      borderRadius: '100px',
                      margin: '10px',
                      isolation: 'isolate',
                    }}
                    onClick={() => trimSignature()}
                    disabled={!signatureState}
                  >
                    Firmar
                  </Button>
                  {/* {trimmedDataURL ? <img src={trimmedDataURL} /> : null} */}
                </div>
              </form>
              {showSaleModal ? (
                <SaleModal
                  sales={saleInfo}
                  show={showSaleModal}
                  onClose={handleCloseSale}
                />
              ) : null}
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createDelivery()}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => {
                  setModalCreateShow(false)
                  resetConstants()
                }}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
