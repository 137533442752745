import { apilUrl } from '../../services/url'
import { apiErrorMessageHandler, notify } from '../../services/utils'

export const PostClientPaymentMethodApi = async (
  branchId,
  companyId,
  ledgerAccount,
  name,
  paymentMethod,
  prefix,
  voucher,
) => {
  try {
    let data = {
      branchId: branchId,
      companyId: companyId,
      ledgerAccount: ledgerAccount,
      name: name,
      paymentMethod: paymentMethod,
      prefix: prefix,
      voucher: voucher,
    }
    let rawResult = await fetch(`${apilUrl}/clientpaymentmethod`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CPM-1"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al crear el metodo de pago')
  }
}

export const UpdateClientPaymentMethodApi = async (
  branchId,
  companyId,
  id,
  ledgerAccount,
  name,
  paymentMethod,
  prefix,
  voucher,
) => {
  try {
    let data = {
      id: id,
      branchId: branchId,
      companyId: companyId,
      ledgerAccount: ledgerAccount,
      name: name,
      paymentMethod: paymentMethod,
      prefix: prefix,
      voucher: voucher,
    }
    let rawResult = await fetch(`${apilUrl}/clientpaymentmethod/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CPM-2"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al actualizar el metodo de pago')
  }
}

export const DeleteClientPaymentMethodApi = async (id) => {
  try {
    let rawResult = await fetch(`${apilUrl}/clientpaymentmethod/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CPM-3"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al eliminar el metodo de pago')
  }
}

export const GetClientPaymentMethodApi = async (paymentMethodId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/clientpaymentmethod/one/${paymentMethodId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler('"CPM-4"', rawResult.status, rawResult.statusText)
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el metodo de pago')
  }
}

export const GetClientPaymentMethodByCompanyIdApi = async (companyId) => {
  try {
    let rawResult = await fetch(`${apilUrl}/clientpaymentmethod/${companyId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error)
      apiErrorMessageHandler(
        '"CPM-5"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el metodo de pago')
  }
}
