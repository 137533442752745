import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group, Text } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import {
  Pagination,
  TextField,
  Select as SelectPages,
  CircularProgress,
} from '@mui/material'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import { apilUrl } from '../../services/url'
import { Edit, Trash, CirclePlus, History, Upload } from 'tabler-icons-react'
import InputLabel from '@mui/material/InputLabel'
import LoadingScreen from '../../components/main/loadingScreen'
import MenuItem from '@mui/material/MenuItem'
import NativeSelect from '@mui/material/NativeSelect'
import FormControl from '@mui/material/FormControl'
import { Select as MuiSelect } from '@mui/material'
import { setDecimal, productUnits, units, isEmpty } from '../../services/utils'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'

import ConfigurationMenus from '../../components/client/shortcutMenus/configurationMenus'
import { ProductHistorical } from '../../components/client/historical/productHistorical'
import ProductsUploadModal from '../../components/client/modals/productsUploadModal'
import {
  PostClientProductApi,
  UpdateCLientProductApi,
  deleteClientProductApi,
  getClientProductsByCompanyApi,
} from '../api/clientProducts'
import { getLedgerAccountsApi } from '../api/iltda'
import CustomSelect from '../../components/custom/customSelect'
import ActionButton from '../../components/custom/actionButton'

export default function Products() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()

  //Clients Products
  const [clientProducts, setClientProducts] = useState([])

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  //UPLOAD MODAL
  const [showUploadModal, setShowUploadModal] = useState(false)
  const handleShowUploadModal = () => setShowUploadModal(true)
  const handleCloseUpload = () => {
    setShowUploadModal(false)
  }

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  const form = useForm({
    initialValues: {
      code: '',
      name: '',
      units: 0,
      price: 0,
      vat: 0,
      vatPrice: 0,
      measurement: '',
      ledgerAccountId: 0,
      store: '',
    },
  })

  const putForm = useForm({
    initialValues: {
      id: 0,
      code: '',
      name: '',
      units: 0,
      price: 0,
      vat: 0,
      vatPrice: 0,
      measurement: '',
      clientId: 0,
      store: '',
    },
  })

  const [ledgerAccounts, setLedgerAccounts] = useState([])
  const [ledgerAccountsOption, setLedgerAccountsOption] = useState([])

  const getLedgerAccounts = async (companyId) => {
    let result = await getLedgerAccountsApi(companyId)
    if (result.data != null) setLedgerAccountsOptions(result.data)
    else notify('warning', 'No hay cuentas contables registradas.')
  }

  const setLedgerAccountsOptions = (ledgerAccounts) => {
    if (ledgerAccounts.length > 0) {
      let ledgerAccountsSelect = ledgerAccounts.map((x) => {
        return { value: x.id, label: x.ledgerAccountCode + ' - ' + x.Detail }
      })
      setLedgerAccountsOption(ledgerAccountsSelect)
      setLedgerAccounts(ledgerAccounts)
    } else {
      setLedgerAccountsOption([])
      setLedgerAccounts([])
      notify('warning', 'No hay cuentas contables registradas.')
    }
  }

  const findLedgerAccount = (id) => {
    let account = 'NO DEFINIDA'
    if (id == 0) return account
    ledgerAccounts.map((acc) => {
      if (acc.id == id) {
        account = acc.ledgerAccountCode + '-' + acc.Detail
      }
    })
    return account
  }

  const successCreation = async (data) => {
    if (data == 'Client Products Created') {
      notify('success', 'Creación Exitoso')
      resetConstants()
      await getClientproducts(tokenData.companyId, pg * rpg - rpg, rpg)
      setModalCreateShow(false)
    } else {
      data == 'product already created'
        ? notify('warning', 'Este producto ya existe')
        : notify('warning', 'Algo salio mal')
    }
    setIsCreateButtonDisabled(false)
  }

  const resetConstants = () => {
    form.setFieldValue('code', '')
    form.setFieldValue('name', '')
    form.setFieldValue('units', 0)
    form.setFieldValue('price', 0)
    form.setFieldValue('vat', 0)
    form.setFieldValue('vatPrice', 0)
    form.setFieldValue('measurement', '')
    form.setFieldValue('ledgerAccountId', 0)
    form.setFieldValue('store', '')
    setSelectedLedgerAccountId(0)
    setSelectedLedgerAccount('')
  }

  const resetUpdateConstants = () => {
    putForm.setFieldValue('id', 0)
    putForm.setFieldValue('code', '')
    putForm.setFieldValue('name', '')
    putForm.setFieldValue('units', 0)
    putForm.setFieldValue('price', 0)
    putForm.setFieldValue('vat', 0)
    putForm.setFieldValue('vatPrice', 0)
    putForm.setFieldValue('measurement', '')
    putForm.setFieldValue('clientId', 0)
    putForm.setFieldValue('store', '')
    setSelectedLedgerAccountId(0)
    setSelectedLedgerAccount('')
  }

  const setEditProductMeasurement = (measurement) => {
    putForm.setFieldValue('measurement', measurement)
  }

  //ver unidades según siglas
  const getNameIdByUnits = (unit) => {
    let id = -1
    units.map((element, index) => {
      if (element === unit) {
        id = index
      }
    })
    return id
  }

  const handleVat = (vat) => {
    form.setFieldValue('vat', vat)
    let vatPriceValue = (form.values.price / 100) * vat
    let newPrice = vatPriceValue + parseInt(form.values.price)
    form.setFieldValue('vatPrice', newPrice)
  }

  const handleVatEdit = (vat) => {
    putForm.setFieldValue('vat', vat)

    let vatPriceValue = (putForm.values.price / 100) * vat
    let newPrice = vatPriceValue + parseInt(putForm.values.price)
    putForm.setFieldValue('vatPrice', newPrice)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createClientProduct = async () => {
    let readyToCreate = true

    if (form.values.code == '') {
      readyToCreate = false
      notify('warning', 'Falta el código del producto.')
    }
    if (form.values.name == '') {
      readyToCreate = false
      notify('warning', 'Falta el nombre del producto.')
    }
    if (parseInt(form.values.units) <= 0 || form.values.units <= '') {
      readyToCreate = false
      notify('warning', 'Faltan las unidades actuales.')
    }
    if (parseInt(form.values.price) <= 0 || form.values.price <= '') {
      readyToCreate = false
      notify('warning', 'Falta el precio unitario.')
    }
    if (parseInt(form.values.vat) < 0) {
      readyToCreate = false
      notify('warning', 'Falta seleccionar el valor del IVA.')
    }
    if (parseInt(form.values.vatPrice) <= 0) {
      readyToCreate = false
      notify('warning', 'Falta el precio con impuesto.')
    }
    if (form.values.measurement == '') {
      readyToCreate = false
      notify('warning', 'Falta la unidad de medida del producto.')
    }

    if (form.values.store == '') {
      readyToCreate = false
      notify('warning', 'Falta la bodega.')
    }

    if (selectedLedgerAccountId == 0) {
      readyToCreate = false
      notify('warning', 'Falta seleccionar la cuenta contable.')
    }

    if (readyToCreate) {
      setIsCreateButtonDisabled(true)
      let clientProductData = {
        companyId: tokenData.companyId,
        branchId: tokenData.branchId,
        code: form.values.code,
        name: form.values.name,
        units: parseInt(form.values.units),
        price: parseInt(form.values.price),
        vat: parseInt(form.values.vat),
        vatPrice: parseInt(form.values.vatPrice),
        unitOfMeasurement: form.values.measurement,
        clientUsersId: tokenData.clientId,
        ledgerAccountId: selectedLedgerAccountId,
        store: form.values.store,
      }
      let result = await PostClientProductApi(clientProductData)
      if (result.data != null) successCreation(result.data)
    }
  }

  const reloadTable = async () => {
    await getClientproducts(tokenData.companyId, pg * rpg - rpg, rpg)
  }

  const getClientproducts = async (companyId, offset, limit) => {
    let result = await getClientProductsByCompanyApi(companyId, offset, limit)
    if (result.data != null) {
      setClientProducts(result.data)
      setTotalProductsCount(result.count)
    } else {
      setClientProducts([])
      setTotalProductsCount(0)
      notify('warning', 'No hay productos registrados.')
    }
  }

  const [wasPrice, setWasPrice] = useState(0)

  const setDatasToUpdate = async (
    id,
    code,
    name,
    units,
    price,
    unitOfMeasurement,
    vat,
    vatPrice,
    clientId,
    ledgerAccountId,
    store,
  ) => {
    putForm.setFieldValue('id', id)
    putForm.setFieldValue('code', code)
    putForm.setFieldValue('name', name)
    putForm.setFieldValue('units', units)
    putForm.setFieldValue('price', price)
    putForm.setFieldValue('vat', vat)
    putForm.setFieldValue('vatPrice', vatPrice)
    putForm.setFieldValue('measurement', unitOfMeasurement)
    putForm.setFieldValue('clientId', clientId)
    putForm.setFieldValue('store', store)
    setSelectedLedgerAccountId(ledgerAccountId)
    setSelectedLedgerAccount(findLedgerAccount(ledgerAccountId))
    setWasPrice(price)
    setModalPutShow(true)
  }

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }
    await getLedgerAccounts(tokenData.companyId)
    await getClientproducts(tokenData.companyId, pg * rpg - rpg, rpg)
  }

  const deleteProduct = (id) => {
    deleteClientProducts(id)
  }

  const deleteClientProducts = async (id) => {
    try {
      Swal.fire({
        title: '¿Eliminar el producto seleccionado?',
        text: 'Nota: Esta acción puede provocar inconsistencias en los reportes de ventas',
        icon: 'warning',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(async (response) => {
        if (response.isConfirmed) {
          let result = await deleteClientProductApi(id)
          if (result.data == 'Client Products Deleted') {
            notify('success', 'Producto Eliminado')
            setTotalProductsCount(totalProductsCount - 1)
            await getClientproducts(tokenData.companyId, pg * rpg - rpg, rpg)
          } else {
            notify('warning', 'Error al eliminar')
          }
        }
      })
    } catch (error) {
      return error
    }
  }

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const updateClientProduct = async () => {
    let readyToUpdate = true

    if (putForm.values.code == '') {
      readyToUpdate = false
      notify('warning', 'Falta el código del producto.')
    }
    if (putForm.values.name == '') {
      readyToUpdate = false
      notify('warning', 'Falta el nombre del producto.')
    }
    if (parseInt(putForm.values.units) <= 0 || putForm.values.units <= '') {
      readyToUpdate = false
      notify('warning', 'Faltan las unidades actuales.')
    }
    if (parseInt(putForm.values.price) <= 0 || putForm.values.price <= '') {
      readyToUpdate = false
      notify('warning', 'Falta el precio unitario.')
    }
    if (parseInt(putForm.values.vat) < 0) {
      readyToUpdate = false
      notify('warning', 'Falta seleccionar el valor del IVA.')
    }
    if (parseInt(putForm.values.vatPrice) <= 0) {
      readyToUpdate = false
      notify('warning', 'Falta el precio con impuesto.')
    }
    if (putForm.values.measurement == '') {
      readyToUpdate = false
      notify('warning', 'Falta la unidad de medida del producto.')
    }

    if (putForm.values.store == '') {
      readyToUpdate = false
      notify('warning', 'Falta la bodega.')
    }

    if (selectedLedgerAccountId == 0) {
      readyToUpdate = false
      notify('warning', 'Falta seleccionar la cuenta contable.')
    }

    if (readyToUpdate) {
      setIsUpdateButtonDisabled(true)
      let clientProductsData = {
        code: putForm.values.code,
        name: putForm.values.name,
        units: parseInt(putForm.values.units),
        price: parseInt(putForm.values.price),
        vat: parseInt(putForm.values.vat),
        vatPrice: parseInt(putForm.values.vatPrice),
        unitOfMeasurement: putForm.values.measurement,
        branchId: tokenData.branchId,
        clientUsersId: tokenData.clientId,
        companyId: tokenData.companyId,
        ledgerAccountId: selectedLedgerAccountId,
        store: putForm.values.store,
      }

      let result = await UpdateCLientProductApi(
        putForm.values.id,
        clientProductsData,
      )
      if (result.data == 'Client Products Updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        resetUpdateConstants()
        await updateClientProductHistorical(
          putForm.values.name,
          putForm.values.price,
        )
        await getClientproducts(tokenData.companyId, pg * rpg - rpg, rpg)
      } else {
        notify('warning', 'Error al actualizar producto.')
      }
    }
    setIsUpdateButtonDisabled(false)
  }

  const setProductCode = (value) => {
    if (value <= 0) {
      notify('warning', 'El codigo no puede ser menor o igual a 0.')
      form.setFieldValue('code', '')
    } else {
      form.setFieldValue('code', value)
    }
  }

  const setProductUnits = (value) => {
    if (value <= 0) {
      notify('warning', 'La unidad no puede ser menor o igual a 0.')
      form.setFieldValue('units', '')
    } else {
      form.setFieldValue('units', value)
    }
  }

  const setProductPrice = (value) => {
    if (value <= 0) {
      //notify('warning', 'LEl precio no puede ser menor o igual a 0.')
      form.setFieldValue('price', '')
    } else {
      form.setFieldValue('price', value)
      let vatPriceValue = (value / 100) * form.values.vat
      let newPrice = vatPriceValue + parseInt(value)
      form.setFieldValue('vatPrice', newPrice)
    }
  }

  const setProductMeasurement = (value) => {
    form.setFieldValue('measurement', value)
  }

  const setEditProductCode = (value) => {
    if (value <= 0) {
      notify('warning', 'El codigo no puede ser menor o igual a 0.')
      putForm.setFieldValue('code', '')
    } else {
      putForm.setFieldValue('code', value)
    }
  }

  const setEditProductUnits = (value) => {
    if (value <= 0) {
      notify('warning', 'La unidad no puede ser menor o igual a 0.')
      putForm.setFieldValue('units', '')
    } else {
      putForm.setFieldValue('units', value)
    }
  }

  const setEditProductPrice = (value) => {
    if (value <= 0) {
      //notify('warning', 'LEl precio no puede ser menor o igual a 0.')
      putForm.setFieldValue('price', '')
    } else {
      putForm.setFieldValue('price', value)
    }
  }

  const [selectedLedgerAccountId, setSelectedLedgerAccountId] = useState(0)
  const [selectedLedgerAccount, setSelectedLedgerAccount] = useState('')

  const handleLedgerAccountSelected = (ledgerAccount) => {
    setSelectedLedgerAccountId(ledgerAccount.value)
    setSelectedLedgerAccount(ledgerAccount.label)
  }

  //------productPriceHistorical

  const updateClientProductHistorical = async (productName, newPrice) => {
    try {
      let ProductHistoricalData = {
        clientUserId: parseInt(tokenData.clientId),
        companyId: parseInt(tokenData.companyId),
        branchId: parseInt(tokenData.branchId),
        modifiedBy: tokenData.clientName,
        newPrice: parseInt(newPrice),
        productName: productName,
        wasPrice: parseInt(wasPrice),
      }
      await fetch(`${apilUrl}/clientproductshistorical`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ProductHistoricalData),
      }).then((response) => response.json())
    } catch (error) {
      return error
    }
  }

  const [show, setShow] = useState(false)
  const handleCloseHistorical = () => setShow(false)
  const handleShowHistorical = async () => {
    setShow(true)
  }

  //-----------------Pagination-------------------

  const [pg, setpg] = useState(1)
  const [rpg, setrpg] = useState(10)
  const [totalProductsCount, setTotalProductsCount] = useState(0)

  async function handleChangePage(event, newpage) {
    setpg(newpage)
    let newOffset = (newpage - 1) * rpg
    await getClientproducts(tokenData.companyId, newOffset, rpg)
  }

  async function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value))
    setpg(1)
    await getClientproducts(tokenData.companyId, 0, event.target.value)
  }

  //-----------------------

  //-------

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <ConfigurationMenus buttonId={1} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
          >
            Nuevo Producto <CirclePlus size={20} color="#FFFFFF" />
          </Button>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              float: 'right',
              marginBottom: 10,
              marginRight: 5,
              isolation: 'isolate',
            }}
            onClick={handleShowUploadModal}
          >
            <Upload size={20} color="white" /> Carga Masiva
          </Button>
          <Button
            style={{
              backgroundColor: 'green',
              float: 'right',
              marginBottom: 10,
              marginRight: 5,
              isolation: 'isolate',
            }}
            onClick={() => {
              handleShowHistorical()
            }}
          >
            <History size={20} color="white" /> Historial de Precios
          </Button>
          <br></br>
          <br></br>

          <MDBTable small>
            <MDBTableHead>
              <tr
                style={{
                  textAlign: 'center',
                }}
              >
                <th>Código</th>
                <th>Nombre</th>
                <th>Unidades</th>
                <th>Precio</th>
                <th>Unid. Medida</th>
                <th>Iva</th>
                <th>Cuenta Contable</th>
                <th>Acciones</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {totalProductsCount == 0
                ? null
                : clientProducts.map((clientProducts, index) => {
                    return (
                      <tr key={index}>
                        <td>{clientProducts.code}</td>
                        <td>{clientProducts.name}</td>
                        <td>{clientProducts.units}</td>
                        <td>${setDecimal(clientProducts.price)}</td>
                        <td>
                          {clientProducts.unitOfMeasurement} -{' '}
                          {
                            productUnits[
                              getNameIdByUnits(clientProducts.unitOfMeasurement)
                            ]
                          }
                        </td>
                        <td>{clientProducts.vat}%</td>
                        <td>
                          {findLedgerAccount(clientProducts.ledgerAccountId)}
                        </td>
                        <td
                          style={{
                            width: '210px',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <ActionButton
                            description="Eliminar"
                            backgroundColor={bgColor}
                            onClick={() => deleteProduct(clientProducts.id)}
                          >
                            <Trash
                              size={30}
                              color="red"
                              style={{ isolation: 'isolate' }}
                            />
                          </ActionButton>
                          <ActionButton
                            description="Editar"
                            backgroundColor={bgColor}
                            onClick={() =>
                              setDatasToUpdate(
                                clientProducts.id,
                                clientProducts.code,
                                clientProducts.name,
                                clientProducts.units,
                                clientProducts.price,
                                clientProducts.unitOfMeasurement,
                                clientProducts.vat,
                                clientProducts.vatPrice,
                                clientProducts.clientUsersId,
                                clientProducts.ledgerAccountId,
                                clientProducts.store,
                              )
                            }
                          >
                            <Edit size={30} color={color} />
                          </ActionButton>
                        </td>
                      </tr>
                    )
                  })}
            </MDBTableBody>
          </MDBTable>
          <Container>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Filas
              </InputLabel>
              <SelectPages
                labelId="demo-simple-select-standard-label"
                autoWidth={true}
                value={rpg}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </SelectPages>
            </FormControl>
            <Pagination
              count={Math.ceil(totalProductsCount / rpg)}
              page={pg}
              onChange={handleChangePage}
              showFirstButton={true}
              showLastButton={true}
              style={{ display: 'inline-block' }}
            />
          </Container>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nuevo Producto
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group mb="md" mt="md" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Código"
                    placeholder="Código"
                    value={form.values.code}
                    onChange={(event) =>
                      setProductCode(event.currentTarget.value)
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Nombre"
                    placeholder="Nombre"
                    value={form.values.name}
                    onChange={(event) =>
                      form.setFieldValue('name', event.currentTarget.value)
                    }
                  />
                </Group>
                <Group mb="md" mt="md" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Unidades"
                    placeholder="Unidades"
                    type="number"
                    value={form.values.units}
                    onChange={(event) =>
                      setProductUnits(event.currentTarget.value)
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Precio"
                    placeholder="Precio"
                    type="number"
                    value={form.values.price}
                    onChange={(event) =>
                      setProductPrice(event.currentTarget.value)
                    }
                  />
                </Group>
                <Group mb="md" mt="md" grow>
                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Unidad de medida
                    </InputLabel>
                    <NativeSelect
                      label="Unidad de medida"
                      onChange={(e) => setProductMeasurement(e.target.value)}
                    >
                      <option value=""></option>
                      {productUnits.map((unit, index) => {
                        return (
                          <option key={index} value={units[index]}>
                            {unit}
                          </option>
                        )
                      })}
                    </NativeSelect>
                  </FormControl>
                  <Group grow>
                    <FormControl variant="standard">
                      <InputLabel id="demo-simple-select-standard-label">
                        IVA
                      </InputLabel>
                      <MuiSelect
                        label="IVA"
                        onChange={(e) => handleVat(e.target.value)}
                        defaultValue={0}
                      >
                        <MenuItem value={0}>0%</MenuItem>
                        <MenuItem value={5}>5%</MenuItem>
                        <MenuItem value={16}>16%</MenuItem>
                        <MenuItem value={19}>19%</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                  <TextField
                    variant="standard"
                    required
                    label="Precio con impuesto"
                    placeholder="Precio con impuesto"
                    disabled={true}
                    value={form.values.vatPrice}
                  />
                </Group>
                <Group mb="md" mt="md" grow>
                  <Group grow>
                    <TextField
                      variant="standard"
                      required
                      label="Bodega"
                      placeholder="Bodega"
                      value={form.values.store}
                      onChange={(event) =>
                        form.setFieldValue('store', event.currentTarget.value)
                      }
                    />
                    <CustomSelect
                      title="Cuenta contable"
                      placeholder="Cuenta contable"
                      value={{
                        value: selectedLedgerAccountId,
                        label: selectedLedgerAccount,
                      }}
                      onChange={(e) => handleLedgerAccountSelected(e)}
                      options={ledgerAccountsOption}
                      theme={isDarkModeActive ? 'black' : 'white'}
                      minWidth={300}
                    />
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createClientProduct()}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => {
                  setModalCreateShow(false)
                  resetConstants()
                }}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {showUploadModal ? (
        <ProductsUploadModal
          show={showUploadModal}
          onClose={handleCloseUpload}
          reloadTable={reloadTable}
        />
      ) : null}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando producto
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group mb="md" mt="md" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Código"
                    placeholder="Código"
                    value={putForm.values.code}
                    onChange={(event) =>
                      setEditProductCode(event.currentTarget.value)
                    }
                  />
                  <TextField
                    variant="standard"
                    required
                    label="Nombre"
                    placeholder="Nombre"
                    value={putForm.values.name}
                    onChange={(event) =>
                      putForm.setFieldValue('name', event.currentTarget.value)
                    }
                  />
                </Group>
                <Group mb="md" mt="md" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Unidades"
                    placeholder="Unidades"
                    value={putForm.values.units}
                    onChange={(event) =>
                      setEditProductUnits(event.currentTarget.value)
                    }
                  />

                  <TextField
                    variant="standard"
                    required
                    label="Precio"
                    placeholder="Precio"
                    type="number"
                    value={putForm.values.price}
                    onChange={(event) =>
                      setEditProductPrice(event.currentTarget.value)
                    }
                  />
                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Unidad de medida
                    </InputLabel>
                    <NativeSelect
                      label="Unidad de medida"
                      onChange={(e) =>
                        setEditProductMeasurement(e.target.value)
                      }
                      value={putForm.values.measurement}
                    >
                      {productUnits.map((unit, index) => {
                        return (
                          <option key={index} value={units[index]}>
                            {unit}
                          </option>
                        )
                      })}
                    </NativeSelect>
                  </FormControl>
                  <Group grow>
                    <FormControl variant="standard">
                      <InputLabel id="demo-simple-select-standard-label">
                        IVA
                      </InputLabel>
                      <MuiSelect
                        label="Iva"
                        onChange={(e) => handleVatEdit(e.target.value)}
                        value={putForm.values.vat}
                      >
                        <MenuItem>Selecciona impuesto</MenuItem>
                        <MenuItem value={0}>0%</MenuItem>
                        <MenuItem value={5}>5%</MenuItem>
                        <MenuItem value={16}>16%</MenuItem>
                        <MenuItem value={19}>19%</MenuItem>
                      </MuiSelect>
                    </FormControl>
                  </Group>
                  <TextField
                    variant="standard"
                    required
                    label="Precio con impuesto"
                    placeholder="Precio con impuesto"
                    disabled={true}
                    value={putForm.values.vatPrice}
                    onChange={(event) =>
                      putForm.setFieldValue(
                        'vatPrice',
                        event.currentTarget.value,
                      )
                    }
                  />
                </Group>
                <Group mb="md" mt="md" grow>
                  <Group grow>
                    <TextField
                      variant="standard"
                      required
                      label="Bodega"
                      placeholder="Bodega"
                      value={putForm.values.store}
                      onChange={(event) =>
                        putForm.setFieldValue(
                          'store',
                          event.currentTarget.value,
                        )
                      }
                    />
                    <CustomSelect
                      title="Cuenta contable"
                      placeholder="Cuenta contable"
                      value={{
                        value: selectedLedgerAccountId,
                        label: selectedLedgerAccount,
                      }}
                      onChange={(e) => handleLedgerAccountSelected(e)}
                      options={ledgerAccountsOption}
                      theme={isDarkModeActive ? 'black' : 'white'}
                      minWidth={300}
                    />
                  </Group>
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => {
                  updateClientProduct()
                }}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => {
                  setModalPutShow(false)
                  resetUpdateConstants()
                }}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {show ? (
        <ProductHistorical show={show} onClose={handleCloseHistorical} />
      ) : null}
    </div>
  )
}
