import React from 'react'
import companyLogo from '../../resources/images/companyLogo.png'
import agestionLogo from '../../resources/images/agestion.png'
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from 'mdb-react-ui-kit'

export default function Footer() {
  return (
    <MDBFooter
      color="white"
      style={{ backgroundColor: '#212121', isolation: 'isolate' }}
      className="text-center text-lg-start text-muted"
    >
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <br></br>
          <MDBRow className="mt-1">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-1">
              <img
                src={companyLogo}
                alt="company logo"
                style={{
                  width: '100px',
                }}
              />
              <p>
                <b
                  style={{
                    color: '#A9A9A9',
                  }}
                >
                  Avril App
                </b>{' '}
                es una solución web a las necesidades que se han identificado
                para la venta de combustible en las estaciones de servicio{' '}
                <b
                  style={{
                    color: '#A9A9A9',
                  }}
                >
                  (EDS) que no pertenecen a las grandes comercializadoras de
                  combustible del país.
                </b>
              </p>
            </MDBCol>
            {/* 
            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Servicios</h6>
              <p>
                <a href="#!" className="text-reset">
                  Facturación Electrónica
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Inventario de productos canastilla
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Multiples sucursales
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Venta Combustible
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Venta Productos
                </a>
              </p>
            </MDBCol> */}
            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Carrera 65, Gta. Terminal Del Sur #8b 91 piso 2 local 235,
                Medellín, Antioquia
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                Info@avrilapp.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> 6045906907 ext 1000
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <div
        className="text-center p-4"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
      >
        © 2022-2024 Copyright:
        <a className="text-reset fw-bold" href="https://agestion.net/">
          <img
            src={agestionLogo}
            alt="company logo"
            style={{
              width: '120px',
            }}
          />
        </a>
      </div>
    </MDBFooter>
  )
}
