import { apilUrl } from '../../services/url'
import { notify } from '../../services/utils'
import { apiErrorMessageHandler } from '../../services/utils'

export const getLastEndTimeApi = async (clientUserId) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/endtime/lastendtime/${clientUserId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error) {
      apiErrorMessageHandler(
        '"OTIME-1"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    }
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener la última hora de fin.')
  }
}

export const PostEndTimeApi = async (endtimeData) => {
  try {
    let rawResult = await fetch(`${apilUrl}/endtime`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(endtimeData),
    }).then((response) => response)
    let result = await rawResult.json()
    if (result.error) {
      apiErrorMessageHandler(
        '"OTIME-2"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    }
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al registrar la hora de fin.')
  }
}

export const getAllEndTimeByClientUserIdApi = async (
  clientUserId,
  offset,
  limit,
) => {
  try {
    let rawResult = await fetch(
      `${apilUrl}/endtime/all/${clientUserId}/${offset}/${limit}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response)
    let result = await rawResult.json()
    if (result.error) {
      apiErrorMessageHandler(
        '"OTIME-3"',
        rawResult.status,
        rawResult.statusText,
        result.error,
      )
    }
    if (result.data) return result
    else return { data: null }
  } catch (error) {
    notify('error', 'Error al obtener el histórico de horas cierre.')
  }
}
