import { Container, Row, Col } from 'react-bootstrap'
import { Group, Text, Paper } from '@mantine/core'
import { useEffect, useState } from 'react'
import useStore from '../../../zustand'
import { BusStop } from 'tabler-icons-react'
import { getClientIslandsApi } from '../../../../pages/api/clientIslands'
import { notify } from '../../../../services/utils'

const IslandState = (props) => {
  const { tanks, hoses } = props
  const { tokenData } = useStore()

  const [islands, setIslands] = useState([])

  const getIslands = async () => {
    let result = await getClientIslandsApi(
      tokenData.companyId,
      tokenData.branchId,
    )
    if (result.data != null) setIslands(result.data)
    else {
      setIslands([])
      notify('error', 'Error al obtener las islas.')
    }
  }

  useEffect(() => {
    getIslands()
  }, [])

  return (
    <Container>
      <Row xs={1} md={3}>
        {islands.map((island, index) => {
          return (
            <Col key={index}>
              <Paper withBorder radius="md" p="xs">
                <Group>
                  <BusStop
                    size={60}
                    color="green" //"#3e98c7"
                    style={{ marginRight: '10px', isolation: 'isolate' }}
                  />
                  <div>
                    <Text
                      color="dimmed"
                      size="xl"
                      transform="uppercase"
                      weight={700}
                    >
                      {island.name}
                    </Text>
                    <Text
                      color="dimmed"
                      size="md"
                      transform="uppercase"
                      weight={700}
                    >
                      Tanque :
                      <select
                        disabled={true}
                        defaultValue={island.tankId}
                        style={{ appearance: 'none', border: '0px' }}
                      >
                        {tanks.map((tank, index) => {
                          return (
                            <option
                              value={tank.id}
                              key={index}
                              style={{ display: 'none' }}
                            >
                              {tank.name}
                            </option>
                          )
                        })}
                      </select>
                    </Text>
                  </div>
                </Group>
                <br />
                <Row>
                  {hoses
                    .filter((hose) => hose.islandId === island.id)
                    .map((hose, index) => {
                      return (
                        <Col
                          key={index}
                          style={{ width: '100%', marginBottom: '20px' }}
                        >
                          <Paper
                            withBorder
                            radius="xs"
                            className="hose_state"
                            style={{
                              width: '100%',
                              border: `1px solid ${
                                hose.occupied ? 'red' : 'green'
                              }`,
                              borderTop: '0px',
                              isolation: 'isolate',
                              backgroundColor: 'transparent',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: hose.occupied
                                  ? 'red'
                                  : 'green',
                                isolation: 'isolate',
                              }}
                            >
                              <Text
                                color="white"
                                size="sm"
                                transform="uppercase"
                                weight={500}
                              >
                                {hose.occupied ? hose.clientName : 'DISPONIBLE'}
                              </Text>
                            </div>
                            <Group direction="row">
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Text
                                  color="dimmed"
                                  size="xs"
                                  p={5}
                                  transform="uppercase"
                                  weight={500}
                                >
                                  {hose.name}
                                </Text>
                              </div>
                            </Group>
                          </Paper>
                        </Col>
                      )
                    })}
                </Row>
              </Paper>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default IslandState
