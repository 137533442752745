import { Group, Paper, Text } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { CircularProgress, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { useNavigate } from 'react-router-dom'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import Menu from '../../components/client/menu'
import useStore from '../../components/zustand'

import { Select as MuiSelect } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import {
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { BusStop, CirclePlus, Edit } from 'tabler-icons-react'
import LoadingScreen from '../../components/main/loadingScreen'
import StationMenus from '../../components/client/shortcutMenus/stationMenus'
import { isEmpty } from '../../services/utils'
import {
  PostClientIslandApi,
  UpdateClientIslandApi,
  getClientIslandsApi,
} from '../api/clientIslands'
import { getClientTanksApi } from '../api/clientTanks'

export default function Islands() {
  const { tokenData, rolesData } = useStore()
  const navigate = useNavigate()
  //Clients Islands
  const [islands, setIslands] = useState([])

  //Clients Tanks
  const [tanks, setTanks] = useState([])

  //CREATE MODAL
  const [modalCreateShow, setModalCreateShow] = React.useState(false)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  //TANK ID
  const [tankId, setTankId] = React.useState(0)

  const form = useForm({
    initialValues: {
      islandName: '',
    },
  })

  const putForm = useForm({
    initialValues: {
      islandId: 0,
      islandName: '',
      tankId: 0,
    },
  })

  //GETS ISLAND FIELDS
  const getIslandData = async (islandId, islandName, tankId) => {
    putForm.setFieldValue('islandId', islandId)
    putForm.setFieldValue('islandName', islandName)
    putForm.setFieldValue('tankId', tankId)
    setModalPutShow(true)
  }

  const successCreation = async () => {
    notify('success', 'Creación Exitoso')
    await getClientIslands(tokenData.companyId, tokenData.branchId)
    setModalCreateShow(false)
    setIsCreateButtonDisabled(false)
  }

  const handleTankId = (tankId) => {
    setTankId(tankId)
  }

  const handleEditTankId = (tankId) => {
    setTankId(tankId)
    putForm.setFieldValue('tankId', tankId)
  }

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)

  const createIsland = async () => {
    if (form.values.islandName == '' || tankId == 0) {
      notify('warning', 'Por favor llena todos los campos.')
    } else {
      setIsCreateButtonDisabled(true)
      let islandData = {
        companyId: tokenData.companyId,
        branchId: tokenData.branchId,
        name: form.values.islandName,
        tankId: parseInt(tankId),
        deleted: false,
      }
      let result = await PostClientIslandApi(islandData)
      if (result.data != null) successCreation()
    }
  }

  const getClientTanks = async (companyId, branchId) => {
    let result = await getClientTanksApi(companyId, branchId)
    if (result.data != null) setTanks(result.data)
    else {
      notify('warning', 'No tienes tanques creados.')
      setTanks([])
    }
  }

  const getClientIslands = async (companyId, branchId) => {
    let result = await getClientIslandsApi(companyId, branchId)
    if (result.data != null) setIslands(result.data)
    else {
      notify('warning', 'No tienes islas creadas.')
      setIslands([])
    }
  }

  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.modules) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    }

    await getClientTanks(tokenData.companyId, tokenData.branchId)

    await getClientIslands(tokenData.companyId, tokenData.branchId)
  }

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)

  const updateClientIsland = async () => {
    if (putForm.values.islandName == '' || putForm.values.tankId == 0) {
      notify('warning', 'Por favor llena todos los campos.')
    } else {
      setIsUpdateButtonDisabled(true)
      let islandData = {
        name: putForm.values.islandName,
        tankId: parseInt(putForm.values.tankId),
      }
      let result = await UpdateClientIslandApi(islandData)
      if (result.data == 'ClientIslands updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await getClientIslands(tokenData.companyId, tokenData.branchId)
      } else {
        notify('warning', 'Error al actualizar')
      }
    }
    setIsUpdateButtonDisabled(false)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <StationMenus buttonId={1} />
          <br></br>
          <Button
            variant="primary"
            style={{
              backgroundColor: 'green',
              marginLeft: 5,
              marginBottom: 10,
              isolation: 'isolate',
            }}
            onClick={() => setModalCreateShow(true)}
          >
            Nueva Isla <CirclePlus size={20} color="#FFFFFF" />
          </Button>
          <br></br>
          <Row xs={1} md={3} className="g-4">
            {islands.map((island, index) => {
              return (
                <Col key={index}>
                  <Paper withBorder radius="md" p="xs">
                    <Button
                      style={{ float: 'right', backgroundColor: 'transparent' }}
                      onClick={() =>
                        getIslandData(island.id, island.name, island.tankId)
                      }
                    >
                      <Edit size={30} color="#000" />
                    </Button>

                    <Group>
                      <BusStop
                        size={60}
                        color="#3e98c7"
                        style={{ marginRight: '10px', isolation: 'isolate' }}
                      />
                      <div>
                        <Text
                          color="dimmed"
                          size="xl"
                          transform="uppercase"
                          weight={700}
                        >
                          {island.name}
                        </Text>
                        <Text
                          color="dimmed"
                          size="md"
                          transform="uppercase"
                          weight={700}
                        >
                          Pertenece :
                          <select
                            disabled={true}
                            defaultValue={island.tankId}
                            style={{ appearance: 'none', border: '0px' }}
                          >
                            {tanks.map((tank, index) => {
                              return (
                                <option
                                  value={tank.id}
                                  key={index}
                                  style={{ display: 'none' }}
                                >
                                  {tank.name}
                                </option>
                              )
                            })}
                          </select>
                        </Text>
                        <Text weight={700} size="md">
                          {island.createdAt}
                        </Text>
                      </div>
                    </Group>
                  </Paper>
                  {/* <Card> */}
                  {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                  {/* <Card.Body>
                                            <Card.Title>{island.name}</Card.Title>
                                            <Card.Text>
                                                Pertenece Tanque:

                                            </Card.Text>
                                            <Card.Text>
                                                Fecha de Creación: {island.createdAt}
                                            </Card.Text>
                                            <Card.Text>
                                                Fecha de Actualización: {island.updatedAt}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card> */}
                </Col>
              )
            })}
          </Row>
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalCreateShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog>
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Creando Nueva isla
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Nombre de la isla"
                    placeholder="Nombre de la isla"
                    value={form.values.islandName}
                    onChange={(event) =>
                      form.setFieldValue(
                        'islandName',
                        event.currentTarget.value,
                      )
                    }
                  />

                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Tanque
                    </InputLabel>
                    <MuiSelect
                      label="Tanque"
                      onChange={(e) => handleTankId(e.target.value)}
                    >
                      <MenuItem value={0}></MenuItem>
                      {tanks.map((tank, index) => {
                        return (
                          <MenuItem value={tank.id} key={index}>
                            {tank.name}
                          </MenuItem>
                        )
                      })}
                    </MuiSelect>
                  </FormControl>

                  {/* <select onChange={(e) => handleTankId(e.target.value)}>
                                {
                                    tanks.map((tank, index) => {
                                        return (
                                            <option value={tank.id} key={index} >{tank.name}</option>
                                        )
                                    })
                                }
                            </select> */}
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isCreateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => createIsland()}
              >
                {isCreateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Crear
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalCreateShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog>
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={form.onSubmit(() => {})}>
                <Group direction="column" grow>
                  <TextField
                    variant="standard"
                    required
                    label="Nombre de la isla"
                    placeholder="Nombre de la isla"
                    value={putForm.values.islandName}
                    onChange={(event) =>
                      putForm.setFieldValue(
                        'islandName',
                        event.currentTarget.value,
                      )
                    }
                  />

                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Tanque
                    </InputLabel>
                    <MuiSelect
                      label="Tanque"
                      onChange={(e) => handleEditTankId(e.target.value)}
                      value={putForm.values.tankId}
                    >
                      <MenuItem value={0}></MenuItem>
                      {tanks.map((tank, index) => {
                        return (
                          <MenuItem value={tank.id} key={index}>
                            {tank.name}
                          </MenuItem>
                        )
                      })}
                    </MuiSelect>
                  </FormControl>

                  {/* <select onChange={(e) => handleEditTankId(e.target.value)} value={putForm.values.tankId}>
                                {
                                    tanks.map((tank, index) => {
                                        return (
                                            <option value={tank.id} key={index}>{tank.name}</option>
                                        )
                                    })
                                }
                            </select> */}
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                disabled={isUpdateButtonDisabled}
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateClientIsland()}
              >
                {isUpdateButtonDisabled ? (
                  <CircularProgress size={15} color="inherit" />
                ) : null}{' '}
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
