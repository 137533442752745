import React from 'react'
import { useForm } from '@mantine/hooks'
import { Text, Group, Button, Anchor } from '@mantine/core'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import { TextField } from '@mui/material'
import { useState } from 'react'
import { validateEmail } from '../../services/utils'
import { useNavigate } from 'react-router-dom'
import { Input, InputLabel } from '@mui/material'
import { CircularProgress } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import companyLogoDark from '../../resources/images/companyLogoDark.png'
import companyLogo from '../../resources/images/companyLogo.png'
import useStore from '../../components/zustand'
import decodeToken from '../../services/decodeToken.service'
import ReCAPTCHA from 'react-google-recaptcha'
import { clientUserLoginApi, clientUserLogoutApi } from '../api/clientUsers'
import { GetExpirationDateApi } from '../api/expirationDate'
import { Col, Row } from 'react-bootstrap'

export default function Login() {
  const { rolesData, addTokenData, addRoles, isDarkModeActive } = useStore()

  const navigate = useNavigate()

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      company: '',
      nit: '',
      password: '',
      validatePassword: '',
      terms: false,
    },

    validationRules: {
      email: (val) => /^\S+@\S+$/.test(val),
      password: (val) => val.length >= 6,
    },
  })

  const [isCreateButtonDisabled, SetIsCreateButtonDisabled] = useState(false)

  const successLogin = async (data) => {
    if (data.error == 'invalid username or password') {
      return notify('warning', 'Usuario o contraseña inválidos.')
    }
    if (data.error == 'branch not active') {
      return notify(
        'warning',
        'la sucursal está inactiva, por favor comunícate con un administrador.',
      )
    }
    if (data.error == 'user not active') {
      return notify('warning', 'Tu cuenta no esta activa.')
    }

    if (data.error || data.data == null) {
      return notify('warning', 'Algo salió mal, intente de nuevo.')
    }

    let token = await decodeToken()
    let expirationDate = await GetExpirationDateApi(token.companyId)
    if (expirationDate.data != 'Invalid Token') {
      if (expirationDate.data[0].isExpired) {
        logOut(true)
      } else {
        // ZUSTAND
        addTokenData(token)
        addRoles(rolesData)

        notify('success', 'Bienvenido ' + token.clientName)

        if (token.getStarted) {
          navigate('/get-started')
        } else {
          navigate('/dashboard')
        }
      }
    } else {
      notify('warning', 'Error al validar su sesión.')
    }
  }

  const logIn = async () => {
    let readyToLogIn = true
    if (form.values.password.length < 6 || form.values.password == '') {
      notify('warning', 'Verifique los datos ingresados.')
      readyToLogIn = false
    }

    if (captchaState == false) {
      notify('warning', 'Complete el captcha.')
      readyToLogIn = false
    }

    if (readyToLogIn) {
      SetIsCreateButtonDisabled(true)

      let result = await clientUserLoginApi(
        form.values.email,
        form.values.password,
      )
      if (result != null) successLogin(result)
      else {
        notify('warning', 'Error al iniciar sesión.')
      }
    }
    SetIsCreateButtonDisabled(false)
  }

  const logOut = async (isExpired = false) => {
    localStorage.clear()
    if (isExpired)
      notify(
        'warning',
        'Su suscripción ha finalizado, por favor contacte a soporte técnico.',
      )
    try {
      let result = await clientUserLogoutApi()
      if (result.data == 'logged out') {
        navigate('/login')
      }
    } catch (error) {
      return error
    }
  }

  //-----------captcha-----------------

  const captcha = React.useRef(null)
  const [captchaState, setCaptchaState] = React.useState(false)
  const captchaHandler = () => {
    if (captcha.current.getValue()) {
      setCaptchaState(true)
    } else {
      setCaptchaState(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <Row
          style={{
            display: 'flex',
            borderRadius: '10px',
            backgroundColor: 'white',
            margin: '90px 0',
            width: 'fit-content',
            padding: '0px',
          }}
          className="g-3 hoses_display"
        >
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '100px',
              margin: 0,
              backgroundColor: '#212121',
              borderRadius: '10px 0px 0px 10px',
            }}
          >
            <img
              src={isDarkModeActive ? companyLogoDark : companyLogo}
              alt="AvrilLogo"
              width={150}
            />
            <Text size="lg" weight={500} color="white">
              Bienvenido a AvrilApp
            </Text>
            <br></br>
            <Anchor
              component="button"
              type="button"
              color="white"
              onClick={() => {
                navigate('/register')
              }}
              style={{
                textDecoration: 'underline',
              }}
              size="xs"
            >
              ¿No tienes una cuenta? Regístrate
            </Anchor>
          </Col>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '50px',
              margin: 0,
              borderRadius: '0px 10px 10px 0px',
              backgroundColor: 'white',
            }}
          >
            <Text
              style={{
                fontSize: '30px',
              }}
              weight={500}
              color="dimmed"
            >
              Ingresa a tu cuenta
            </Text>
            <br></br>
            <form onSubmit={form.onSubmit(() => {})}>
              <Group direction="column" grow>
                <Group grow mb="md">
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    required
                    label="Correo"
                    placeholder="Correo Electrónico"
                    type="email"
                    onChange={(event) => {
                      validateEmail(event.currentTarget.value)
                        ? form.setFieldValue('email', event.currentTarget.value)
                        : form.setFieldValue('email', '')
                    }}
                  />
                </Group>
                <FormControl variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    Contraseña *
                  </InputLabel>
                  <Input
                    label="Contraseña"
                    autoComplete="off"
                    required
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(event) =>
                      form.setFieldValue('password', event.currentTarget.value)
                    }
                  />
                </FormControl>
                <br></br>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  theme={isDarkModeActive ? 'dark' : 'light'}
                  onChange={captchaHandler}
                  ref={captcha}
                />
              </Group>
              <Group
                position="apart"
                mt="xl"
                style={{
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={() => navigate('/')}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '100px',
                    isolation: 'isolate',
                    color: 'black',
                    border: '1px solid black',
                  }}
                  disabled={isCreateButtonDisabled}
                >
                  Regresar
                </Button>
                <Button
                  id="login-btn"
                  type="submit"
                  onClick={() => logIn()}
                  style={{
                    backgroundColor: 'green',
                    borderRadius: '100px',
                    isolation: 'isolate',
                  }}
                  disabled={isCreateButtonDisabled}
                >
                  {isCreateButtonDisabled ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : null}{' '}
                  Ingresar
                </Button>
              </Group>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  )
}
