import React, { useEffect, useState } from 'react'
import useStore from '../zustand'

const Clock = () => {
  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  const [currentDate, setCurrentDate] = useState(new Date())

  const style = {
    position: 'fixed',
    top: '100px',
    right: '10px',
    color: color,
    fontSize: '30px',
    letterSpacing: '2px',
    userSelect: 'none',
  }

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'gray')
    let date = new Date()
    setCurrentDate(date)
    showTime(date)
  }, [isDarkModeActive])
  return (
    <div>
      <div id="MyClockDisplay" style={style} onLoad={showTime} />
      <div
        style={{
          position: 'fixed',
          top: '145px',
          right: '10px',
          color: color,
          fontSize: '20px',
          letterSpacing: '2px',
          userSelect: 'none',
        }}
      >
        {currentDate.toLocaleDateString("es-CO", options)}
      </div>
    </div>
  )
}

function showTime() {
  var date = new Date()
  var h = date.getHours() // 0 - 23
  var m = date.getMinutes() // 0 - 59
  var s = date.getSeconds() // 0 - 59
  var session = 'AM'

  if (h == 0) {
    h = 12
  }

  if (h >= 12) {
    session = 'PM'
  }

  h = h < 10 ? '0' + h : h
  m = m < 10 ? '0' + m : m
  s = s < 10 ? '0' + s : s

  var time = h + ':' + m + ':' + s + ' ' + session
  let clock = document.getElementById('MyClockDisplay')
  if (clock === null) return

  document.getElementById('MyClockDisplay').innerText = time
  document.getElementById('MyClockDisplay').textContent = time

  setTimeout(showTime, 1000)
}

export default Clock
