import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/client/menu'
import Container from 'react-bootstrap/Container'
import useStore from '../../components/zustand'
import Button from 'react-bootstrap/Button'
import { Group, Text, Paper } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import { notify } from '../../services/utils'
import 'react-toastify/dist/ReactToastify.css'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { apilUrl } from '../../services/url'
import { Edit } from 'tabler-icons-react'
import Alert from 'react-bootstrap/Alert'
import { isEmpty, setDecimal } from '../../services/utils'
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'

import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import { TextField } from '@mui/material'
import LoadingScreen from '../../components/main/loadingScreen'
import ConfigurationMenus from '../../components/client/shortcutMenus/configurationMenus'
import CustomSelect from '../../components/custom/customSelect'
import {
  getGallonPricesByFuelTypeApi,
  updateGallonPriceApi,
} from '../api/gallonPrice'
import { getLedgerAccountsApi } from '../api/iltda'

export default function GallonPrice() {
  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  const enumFuelType = {
    1: 'Diésel',
    2: 'Corriente',
    3: 'Extra',
    4: 'Premium',
    5: 'Urea',
    6: 'ACPM',
    7: 'Greenplus',
  }

  const [ledgerAccounts, setLedgerAccounts] = useState([])
  const [ledgerAccountsOptions, setLedgerAccountsOptions] = useState([])
  const [selectedLedgerAccount, setSelectedLedgerAccount] = useState(0)

  const getLedgerAccounts = async (companyId) => {
    let result = await getLedgerAccountsApi(companyId)
    if (result.data != null) {
      setLedgerAccounts(result.data)
      let options = []
      result.data.map((acc) => {
        options.push({
          value: acc.id,
          label: acc.ledgerAccountCode + ' - ' + acc.Detail,
        })
      })
      setLedgerAccountsOptions(options)
    } else {
      setLedgerAccounts([])
      setLedgerAccountsOptions([])
    }
  }

  const getLedgerAccountsById = (id) => {
    let ledgerAccount = ledgerAccounts.find((acc) => acc.id == id)
    return ledgerAccount
  }

  const handlSelectedLedgerAccount = (e) => {
    setSelectedLedgerAccount(e)
    putForm.setFieldValue('ledgerAccountId', e.value)
  }

  //Clients GALLON PRICE
  const [gallonPrice, setGallonPrice] = useState([])

  //Clients GALLON PRICE HISTORICAL
  const [gallonPriceHistorical, setGallonPriceHistorical] = useState([])

  //creating IP state
  //const [ip, setIP] = useState('')

  const [gallonPriceHistoricalData, setGallonPriceHistoricalData] =
    useState(false)

  //const [isThereGallonPrice, setIsThereGallonPrice] = useState(false)

  const [pmp, setPmp] = useState(0)
  const [pmpFixed, setPmpFixed] = useState(0)

  //PUT MODAL
  const [modalPutShow, setModalPutShow] = React.useState(false)

  const putForm = useForm({
    initialValues: {
      gallonPriceId: 0,
      gallon: 0,
      price: 0,
      fuelType: 10,
      ledgerAccountId: 0,
      reference: '',
      store: '',
    },
  })

  //GETS GALLON PRICE FIELDS
  const getGallonPriceData = async (
    gallonPriceId,
    gallon,
    price,
    fuelType,
    ledgerAccountId,
    reference,
    store,
  ) => {
    putForm.setFieldValue('gallonPriceId', gallonPriceId)
    putForm.setFieldValue('gallon', gallon)
    putForm.setFieldValue('price', price)
    putForm.setFieldValue('fuelType', fuelType)
    putForm.setFieldValue('ledgerAccountId', ledgerAccountId)
    putForm.setFieldValue('reference', reference)
    putForm.setFieldValue('store', store)
    const ledgerAccount = getLedgerAccountsById(ledgerAccountId)
    setSelectedLedgerAccount({
      value: ledgerAccountId,
      label: ledgerAccount.ledgerAccountCode + ' - ' + ledgerAccount.Detail,
    })
    setModalPutShow(true)
  }

  const getGallonPricesByFuelType = async (fuelType) => {
    let result = await getGallonPricesByFuelTypeApi(
      tokenData.companyId,
      fuelType,
    )
    if (result.data != null) {
      setGallonPrice(result.data[0])
    } else {
      notify(
        'warning',
        'No hay precios de galón para este tipo de combustible.',
      )
    }
  }

  const getGallonPricesHistorical = async (fuelType) => {
    try {
      let result = await fetch(
        `${apilUrl}/gallon-price-historical/all/by-fueltype/${tokenData.companyId}/${fuelType.value}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then((response) => response.json())
      if (result.data == null) {
        setGallonPriceHistoricalData(true)
        setPmp(0.0)
        setGallonPriceHistorical([])
      } else {
        setGallonPriceHistorical(result.data)

        let sumGallon = 0
        let multiplyGp = 0

        result.data.slice(0, 4).map((x) => {
          return (sumGallon += x.gallon)
        })

        result.data.slice(0, 4).map((x) => {
          return (multiplyGp += x.gallon * x.price)
        })

        let total = multiplyGp / sumGallon
        setPmpFixed(total.toFixed(2))
        setPmp(total.toFixed(2))
      }
    } catch (error) {
      return false
    }
  }

  const updateGallonPrice = async () => {
    let readyToUpdate = true
    if (parseFloat(putForm.values.price) <= 0 || putForm.values.price == '') {
      readyToUpdate = false
      notify('warning', 'Ingrese un precio de galón válido.')
    }
    if (putForm.values.reference == '') {
      readyToUpdate = false
      notify('warning', 'Ingrese una referencia válida.')
    }

    if (putForm.values.store == '') {
      readyToUpdate = false
      notify('warning', 'Ingrese una bodega válida.')
    }
    let gallonPriceData = [
      {
        id: parseInt(putForm.values.gallonPriceId),
        price: parseFloat(putForm.values.price),
        ledgerAccountId: parseInt(putForm.values.ledgerAccountId),
        reference: putForm.values.reference,
        store: putForm.values.store,
      },
    ]
    if (readyToUpdate) {
      let result = await updateGallonPriceApi(gallonPriceData)
      if (result.data == 'GallonPrice updated') {
        notify('success', 'Actualización Exitoso')
        setModalPutShow(false)
        await createGallonPriceHistorical()
        await getGallonPricesByFuelType(gasSelected)
      } else {
        notify('warning', 'Error al actualizar precio del galón.')
      }
    }
  }

  const createGallonPriceHistorical = async () => {
    let gallonPriceHistoricalData = {
      companyId: tokenData.companyId,
      branchId: tokenData.branchId,
      gallon: parseFloat(putForm.values.gallon),
      price: parseFloat(putForm.values.price),
      name: tokenData.clientName,
      ip: '000.0.0.0',
      fuelType: parseInt(putForm.values.fuelType),
    }
    try {
      let result = await fetch(`${apilUrl}/gallon-price-historical`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(gallonPriceHistoricalData),
      }).then((response) => response.json())

      if (result.data == 'GallonPriceHistorical created') {
        notify('success', 'Actualización Exitoso')
        await getGallonPricesHistorical(gasSelected)
        setGallonPriceHistoricalData(false)
      } else {
        notify('warning', 'Error al actualizar')
      }
    } catch (error) {
      return error
    }
  }

  const setGallonsPrice = (value) => {
    if (value <= 0) {
      notify('warning', 'El precio del galón no puede ser numeros negativos.')
      putForm.setFieldValue('price', '')
    } else {
      putForm.setFieldValue('price', value)
    }
  }

  const findLedgerAccount = (id) => {
    let account = 'NO DEFINIDA'
    if (id == 0) return account
    ledgerAccounts.map((acc) => {
      if (acc.id == id) {
        account = acc.ledgerAccountCode + '-' + acc.Detail
      }
    })
    return account
  }

  //------------Historico carga-------------
  const gasOptions = [
    { value: 0, label: ' ' },
    { value: 1, label: 'Diésel' },
    { value: 2, label: 'Corriente' },
    { value: 3, label: 'Extra' },
    { value: 4, label: 'Premium' },
    { value: 5, label: 'Urea' },
    { value: 6, label: 'ACPM' },
    { value: 7, label: 'Greenplus' },
  ]

  const [gasSelected, setGasSelected] = useState({ value: 0, label: ' ' })

  //----------
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      setLoading(false)
      tokenVerified()
    } else setLoading(true)
  }, [tokenData, rolesData])

  const handleGasSelected = async (gas) => {
    setGasSelected(gas)
    await getGallonPricesByFuelType(gas)
    await getGallonPricesHistorical(gas)
  }
  const tokenVerified = async () => {
    //CHECK ROLES
    if (!rolesData.configuration) {
      notify('warning', 'No tienes permiso para ver este modulo.')
      navigate('/dashboard')
    } else {
      await getLedgerAccounts(tokenData.companyId)
    }
  }

  const [color, setColor] = useState('black')
  const [bgColor, setBgColor] = useState('white')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
    setBgColor(isDarkModeActive ? '#121212' : 'white')
  }, [isDarkModeActive])

  return (
    <div>
      <Menu />
      {!loading ? (
        <Container>
          <ConfigurationMenus buttonId={0} />
          <br></br>
          <CustomSelect
            title="Tipo de Combustible"
            placeholder="Tipo de Combustible"
            onChange={(gas) => {
              handleGasSelected(gas)
            }}
            value={gasSelected}
            options={gasOptions}
            theme={isDarkModeActive ? 'black' : 'white'}
          />
          <br></br>
          {gasSelected.value != 0 ? (
            <Container>
              <Alert variant="success">
                <Alert.Heading>
                  Precio Medio Ponderado ${setDecimal(pmp)}
                </Alert.Heading>
              </Alert>
              <Row xs={1} md={3} className="g-4">
                <Col>
                  <Paper withBorder radius="md" p="xs">
                    <Button
                      style={{
                        float: 'right',
                        backgroundColor: 'transparent',
                      }}
                      onClick={() =>
                        getGallonPriceData(
                          gallonPrice.id,
                          gallonPrice.gallon,
                          gallonPrice.price,
                          gallonPrice.fuelType,
                          gallonPrice.ledgerAccountId,
                          gallonPrice.reference,
                          gallonPrice.store,
                        )
                      }
                    >
                      <Edit size={30} color="#000" />
                    </Button>

                    <Group>
                      <div>
                        <Text
                          color="dimmed"
                          size="xl"
                          transform="uppercase"
                          weight={700}
                        >
                          {enumFuelType[gallonPrice.fuelType]}
                        </Text>
                        <Text
                          color="dimmed"
                          size="xl"
                          transform="uppercase"
                          weight={700}
                        >
                          ${setDecimal(gallonPrice.price)}
                        </Text>
                        <br></br>
                        <Text weight={700} size="md">
                          {findLedgerAccount(gallonPrice.ledgerAccountId)}
                        </Text>
                        <Text weight={700} size="md">
                          {gallonPrice.updatedAt}
                        </Text>
                      </div>
                    </Group>
                  </Paper>
                </Col>
              </Row>
              <br></br>
              <Text style={{ textAlign: 'center', fontSize: 30 }}>
                Histórico
              </Text>
              <br></br>
              <MDBTable small>
                <MDBTableHead>
                  <tr>
                    <th>Precio</th>
                    <th>Quien modificó</th>
                    <th>Última Actualización</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {gallonPriceHistorical.map((gallonPriceHistorical, index) => {
                    return (
                      <tr key={index}>
                        <td>${setDecimal(gallonPriceHistorical.price)}</td>
                        <td>{gallonPriceHistorical.name}</td>
                        <td>{gallonPriceHistorical.createdAt}</td>
                      </tr>
                    )
                  })}
                </MDBTableBody>
              </MDBTable>
            </Container>
          ) : null}
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <MDBModal
        show={modalPutShow}
        staticBackdrop
        aria-labelledby="contained-modal-title-vcenter"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <MDBModalHeader>
              <MDBModalTitle id="contained-modal-title-vcenter">
                Modificando{' '}
                <Text style={{ color: 'green' }}>PMP ${pmpFixed}</Text>
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Group mb="md" mt="md" grow>
                  <TextField
                    variant="standard"
                    required
                    label={'Precio (PMP) $' + pmpFixed}
                    type="number"
                    placeholder="Precio"
                    value={putForm.values.price}
                    onChange={(event) =>
                      setGallonsPrice(event.currentTarget.value)
                    }
                  />
                  <div>
                    <CustomSelect
                      title="Cuenta contable"
                      placeholder="Cuenta contable"
                      options={ledgerAccountsOptions}
                      theme={isDarkModeActive ? 'black' : 'white'}
                      onChange={(e) => handlSelectedLedgerAccount(e)}
                      value={selectedLedgerAccount}
                      withBorder={false}
                    >
                      <MenuItem value={0}>Selecciona una cuenta</MenuItem>
                      {ledgerAccounts.map((acc, index) => {
                        return (
                          <MenuItem value={acc.id} key={index}>
                            {acc.ledgerAccountCode + '-' + acc.Detail}
                          </MenuItem>
                        )
                      })}
                    </CustomSelect>
                  </div>
                </Group>
                <Group grow>
                  <TextField
                    variant="standard"
                    required
                    label="Referencia"
                    type="number"
                    placeholder="Referencia"
                    value={putForm.values.reference}
                    onChange={(event) =>
                      putForm.setFieldValue('reference', event.target.value)
                    }
                  />
                  <TextField
                    variant="standard"
                    required
                    label="Bodega"
                    type="number"
                    placeholder="Bodega"
                    value={putForm.values.store}
                    onChange={(event) =>
                      putForm.setFieldValue('store', event.target.value)
                    }
                  />
                </Group>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button
                style={{
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  isolation: 'isolate',
                }}
                onClick={() => updateGallonPrice()}
              >
                Actualizar
              </Button>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  color: color,
                }}
                onClick={() => setModalPutShow(false)}
              >
                Cerrar
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}
