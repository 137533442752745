import React, { useState, useEffect } from 'react'
import { Group, Text } from '@mantine/core'
import 'react-toastify/dist/ReactToastify.css'
import { Alarm, AlarmOff } from 'tabler-icons-react'
import useStore from '../../zustand'
import Button from 'react-bootstrap/Button'
import { getReasonsApi } from '../../../pages/api/reason'
import { FormControl, InputLabel, MenuItem } from '@mui/material'
import { Select as MuiSelect } from '@mui/material'
import { PostEndTimeApi, getLastEndTimeApi } from '../../../pages/api/endtime'
import {
  PostEntryTimeApi,
  getLastEntryTimeApi,
} from '../../../pages/api/entrytime'
import { notify } from '../../../services/utils'
import dayjs from 'dayjs'
import { getScheduleApi } from '../../../pages/api/schedule'

const WorkShiftForm = () => {
  const { tokenData } = useStore()

  const [reasons, setReasons] = useState([])
  const [selectedReason, setSelectedReason] = useState('')

  const getReasons = async () => {
    const result = await getReasonsApi()
    if (result.data != null) setReasons(result.data)
    else setReasons([])
  }

  const getLastShiftRegisters = async (clientUserId) => {
    const lastEndTime = await getLastEndTimeApi(clientUserId)
    const lastEntryTime = await getLastEntryTimeApi(clientUserId)
    checkEntryTime(lastEntryTime.data, lastEndTime.data)
  }

  const checkTimeDifferenceInSeconds = (shiftTime, currentTime) => {
    let shiftDate =
      shiftTime.getHours() * 3600 +
      shiftTime.getMinutes() * 60 +
      shiftTime.getSeconds()
    let currentDate =
      currentTime.getHours() * 3600 +
      currentTime.getMinutes() * 60 +
      currentTime.getSeconds()
    let differenceInSeconds = currentDate - shiftDate
    return differenceInSeconds
  }

  const [isEntryTime, setIsEntryTime] = useState(true)

  const checkEntryTime = (lastEntryTime, lastEndTime) => {
    if (lastEntryTime && lastEndTime) {
      const lastEntry = lastEntryTime.date
      const lastEnd = lastEndTime.date
      const differenceInSeconds = checkTimeDifferenceInSeconds(
        dayjs(lastEntry).toDate(),
        dayjs(lastEnd).toDate(),
      )
      if (differenceInSeconds > 0) {
        setIsEntryTime(true)
      } else {
        setIsEntryTime(false)
      }
    } else if (lastEntryTime) {
      setIsEntryTime(false)
    } else {
      setIsEntryTime(true)
    }
  }

  const [schedules, setSchedules] = useState([])

  const getSchedules = async (companyId, branchId) => {
    let result = await getScheduleApi(companyId, branchId)
    if (result.data != null) setSchedules(result.data)
    else notify('warning', 'No hay horarios registrados')
  }

  const getClientUserSchedule = (clientUserId) => {
    let result = schedules.find(
      (schedule) => schedule.clientUserId == clientUserId,
    )
    if (result != undefined) {
      return result
    }
  }

  const [currentShift, setCurrentShift] = useState({
    start: '2024-01-01T05:00:00.000Z',
    end: '2024-01-01T05:00:00.000Z',
  })

  const checkIsShiftCurrentlyActive = (clientUserId) => {
    return getCurrentShift(clientUserId).title != undefined
  }

  const getCurrentShift = (clientUserId) => {
    let result = schedules.find(
      (schedule) => schedule.clientUserId == clientUserId,
    )
    if (result != undefined) {
      let scheduleList = JSON.parse(result.schedules)
      let today = new Date()
      let maxMinutesDifference = 15 // minutes of difference to consider the shift active
      let currentShift = scheduleList.events.find((event) => {
        if (dayjs(event.start).toDate().getDay() == today.getDay()) {
          // check same day of the week
          let start = dayjs(event.start).toDate()
          let end = dayjs(event.end).toDate()
          let currentHour = today.getHours() * 60 + today.getMinutes()
          let startHour =
            start.getHours() * 60 + start.getMinutes() - maxMinutesDifference
          let endHour =
            end.getHours() * 60 + end.getMinutes() + maxMinutesDifference

          if (currentHour >= startHour && currentHour <= endHour) {
            // check if current hour is between start and end of the event
            return true
          }
        }
        return false
      })
      if (currentShift != undefined) {
        return currentShift
      }
    }
    return {
      start: '2024-01-01T05:00:00.000Z',
      end: '2024-01-01T05:00:00.000Z',
    }
  }

  const getEventFromScheduleByDay = (clientUserSchedule, day) => {
    let scheduleList = JSON.parse(clientUserSchedule.schedules)
    return scheduleList.events.find(
      (event) =>
        event.id < 8 && dayjs(event.start).toDate().getDay() == day.getDay(),
    )
  }

  const createShiftRegister = async () => {
    if (selectedReason === '') {
      notify('warning', 'Debe seleccionar una razón.')
      return
    }

    if (!checkIsShiftCurrentlyActive(tokenData.clientId)) {
      notify('warning', 'No se encuentra en un horario activo.')
      return
    }

    let date = new Date()
    let clientUserSchedule = getClientUserSchedule(tokenData.clientId)
    let todayEventFromSchedule = getEventFromScheduleByDay(
      clientUserSchedule,
      date,
    )

    let differenceOfTime = checkTimeDifferenceInSeconds(
      isEntryTime
        ? dayjs(todayEventFromSchedule.start).toDate()
        : dayjs(todayEventFromSchedule.end).toDate(),
      date,
    )

    let shiftData = {
      afterTime: differenceOfTime > 0 ? differenceOfTime.toString() : '0',
      beforeTime:
        differenceOfTime < 0 ? (differenceOfTime * -1).toString() : '0',
      branchId: tokenData.branchId,
      clientUserId: tokenData.clientId,
      companyId: tokenData.companyId,
      date: date,
      reasonId: selectedReason,
      scheduleId: clientUserSchedule.id,
    }

    let result = { data: null }
    if (isEntryTime) result = await PostEntryTimeApi(shiftData)
    else result = await PostEndTimeApi(shiftData)
    if (result.data != null) {
      notify('success', 'Registro de turno creado.')
      getLastShiftRegisters(tokenData.clientId)
    } else {
      notify('warning', 'Error al crear el registro de turno.')
    }
  }

  useEffect(() => {
    getSchedules(tokenData.companyId, tokenData.branchId)
    getReasons()
    getLastShiftRegisters(tokenData.clientId)
  }, [])

  useEffect(() => {
    setCurrentShift(getCurrentShift(tokenData.clientId))
  }, [schedules])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
  }, [isDarkModeActive])

  return (
    <Group
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <b
          style={{
            color: color,
          }}
        >
          Hora actual:
        </b>

        <Reloj />
        {checkIsShiftCurrentlyActive(tokenData.clientId) ? (
          <span>
            {dayjs(currentShift.start).format('hh:mm A')} -{' '}
            {dayjs(currentShift.end).format('hh:mm A')}
          </span>
        ) : null}
      </div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Razón</InputLabel>
        <MuiSelect
          label="Razón"
          onChange={(e) => setSelectedReason(e.target.value)}
          value={selectedReason}
        >
          {reasons.map((reason, index) => {
            return isEntryTime ? (
              reason.type === true ? (
                <MenuItem key={index} value={reason.id}>
                  {reason.name}
                </MenuItem>
              ) : null
            ) : reason.type === false ? (
              <MenuItem key={index} value={reason.id}>
                {reason.name}
              </MenuItem>
            ) : null
          })}
        </MuiSelect>
      </FormControl>
      <Button
        style={{
          margin: '5px',
          padding: '10px',
          backgroundColor: checkIsShiftCurrentlyActive(tokenData.clientId)
            ? isEntryTime
              ? 'green'
              : 'red'
            : 'orange',
        }}
        disabled={!checkIsShiftCurrentlyActive(tokenData.clientId)}
        onClick={createShiftRegister}
      >
        {isEntryTime ? <Alarm size={25} /> : <AlarmOff size={25} />}
        <Text
          style={{
            fontSize: '12px',
          }}
        >
          {checkIsShiftCurrentlyActive(tokenData.clientId)
            ? isEntryTime
              ? 'Iniciar Turno'
              : 'Finalizar Turno'
            : 'Fuera de horario'}
        </Text>
      </Button>
    </Group>
  )
}

function Reloj() {
  const [hora, setHora] = useState(
    new Date().toLocaleTimeString('es-CO', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }),
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      setHora(
        new Date().toLocaleTimeString('es-CO', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        }),
      )
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const { isDarkModeActive } = useStore()
  const [color, setColor] = useState('black')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
  }, [isDarkModeActive])

  return (
    <div>
      <span
        style={{
          color: color,
        }}
      >
        {hora}
      </span>
    </div>
  )
}

export default WorkShiftForm
