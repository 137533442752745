import React, { useEffect, useState } from 'react'
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import useStore from '../../zustand'

const StationMenus = (props) => {
  const { isDarkModeActive } = useStore()
  const [buttonColor, setButtonColor] = useState('black')

  const setColorById = (id) => {
    if (id == props.buttonId) {
      return {
        backgroundColor: 'rgb(13, 71, 161)',
        borderRadius: '0%',
      }
    } else {
      return {
        backgroundColor: 'transparent',
        color: buttonColor,
        borderRadius: '0%',
      }
    }
  }

  useEffect(() => {
    setButtonColor(isDarkModeActive ? 'white' : 'black')
  }, [isDarkModeActive])

  const buttonsLinks = ['/tanks', '/islands', '/hoses']

  const buttonsNames = ['Tanques', 'Islas', 'Mangueras']

  return (
    <ButtonToolbar aria-label="Toolbar with button groups">
      <ButtonGroup className="me-2" aria-label="First group">
        {buttonsNames.map((button, index) => {
          return (
            <Button
              key={index}
              as={Link}
              to={buttonsLinks[index]}
              style={setColorById(index)}
            >
              {button}
            </Button>
          )
        })}
      </ButtonGroup>
    </ButtonToolbar>
  )
}

export default StationMenus
