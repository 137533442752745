import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Group } from '@mantine/core'
import { useForm } from '@mantine/hooks'
import useStore from '../../../components/zustand'
import { TextField } from '@mui/material'
import { dayjsDateToLocale, notify } from '../../../services/utils.js'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router'
import Button from 'react-bootstrap/Button'
import {
  CircleMinus,
  CirclePlus,
  Trash,
  QuestionMark,
} from 'tabler-icons-react'
import { CircularProgress, MenuItem } from '@mui/material'
import {
  isEmpty,
  setDecimal,
  BootstrapTooltip,
} from '../../../services/utils.js'
import { MDBTable, MDBTableBody } from 'mdb-react-ui-kit'
import { FormControl, InputLabel } from '@mui/material'
import { Select as MuiSelect } from '@mui/material'
import CustomSelect from '../../custom/customSelect'
import { getClientInvoiceByInvoiceNumberApi } from '../../../pages/api/clientinvoice.js'
import { PostCreditNoteApi } from '../../../pages/api/creditNote.js'
import Notes from '../../custom/notes.js'

export default function NewCreditNote(props) {
  const { setModalAnnulateShow, successCreation } = props

  const { tokenData, rolesData, isDarkModeActive } = useStore()
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      invoiceNumber: '',
    },
  })

  const [isClientSelected, setIsClientSelected] = useState(false) //if a client is selected

  //--------------------------------Anulación---------------------------------

  const [isAnnulationButtonDisabled, setIsAnnulationButtonDisabled] =
    useState(false)
  const [toAnnulateBillProducts, setToAnnulateBillProducts] = useState([])
  const [toAnnulateBill, setToAnnulateBill] = React.useState({
    invoiceId: 0,
    items: toAnnulateBillProducts,
    uuid: 0,
  })
  const [AnnulateReason, setAnnulateReason] = useState('')
  const [originalTextItems, setOriginalTextItems] = useState('')
  const [toAnnulateAdditionalPayments, setToAnnulateAdditionalPayments] =
    useState({
      5: { name: 'IVA(5%)', total: 0 },
      16: { name: 'IVA(16%)', total: 0 },
      19: { name: 'IVA(19%)', total: 0 },
      discount: { name: 'Descuento', total: 0 },
      rf: { name: 'Ret. FUENTE', total: 0 },
    })

  const calculateToAnnulateAdditionalPayments = (bills, reason) => {
    let iva5 = 0
    let iva16 = 0
    let iva19 = 0
    let rf = 0
    let discount = 0
    bills.map((product) => {
      let retentions =
        product.retentions != undefined ? product.retentions[0] : 0
      let iva = product.taxes != undefined ? product.taxes[0] : 0
      let disc = product.discount_rate != undefined ? product.discount_rate : 0
      if (parseInt(iva.tax_rate) == 5) {
        iva5 += (product.total * parseInt(iva.tax_rate)) / 100
      }
      if (parseInt(iva.tax_rate) == 16) {
        iva16 += (product.total * parseInt(iva.tax_rate)) / 100
      }
      if (parseInt(iva.tax_rate) == 19) {
        iva19 += (product.total * parseInt(iva.tax_rate)) / 100
      }
      rf += (product.total * parseFloat(retentions.tax_rate)) / 100
      discount +=
        reason != 'DESCUENTO'
          ? parseFloat(
              (product.price * product.quantity * parseInt(disc)) / 100,
            )
          : parseFloat((product.price * product.quantity * 100) / 100)
    })
    let ap = {
      5: { name: 'IVA(5%)', total: iva5 },
      16: { name: 'IVA(16%)', total: iva16 },
      19: { name: 'IVA(19%)', total: iva19 },
      discount: { name: 'Descuento', total: discount },
      rf: { name: 'Ret. FUENTE', total: rf },
    }
    setToAnnulateAdditionalPayments(ap)
  }

  const disableByReason = (field, reason) => {
    if (field == 'quantity') {
      switch (reason) {
        case 'ANULACION':
          return true
        case 'REBAJA':
          return true
        case 'DESCUENTO':
          return true
        case 'RECISION':
          return true
        default:
          return false
      }
    }
    if (field == 'discount') {
      switch (reason) {
        case 'ANULACION':
          return true
        case 'REBAJA':
          return true
        case 'DESCUENTO':
          return true
        case 'RECISION':
          return true
        default:
          return false
      }
    }

    if (field == 'iva') {
      switch (reason) {
        case 'DEVOLUCION':
          return true
        case 'ANULACION':
          return true
        case 'REBAJA':
          return true
        case 'DESCUENTO':
          return true
        case 'RECISION':
          return true
        default:
          return false
      }
    }
    if (field == 'rf') {
      switch (reason) {
        case 'DEVOLUCION':
          return true
        case 'ANULACION':
          return true
        case 'REBAJA':
          return true
        case 'DESCUENTO':
          return true
        case 'RECISION':
          return true
        default:
          return false
      }
    }

    if (field == 'delete') {
      switch (reason) {
        case 'ANULACION':
          return true
        case 'REBAJA':
          return true
        case 'DESCUENTO':
          return true
        case 'RECISION':
          return true
        default:
          return false
      }
    }
  }

  const changeToAnnulateBillProductsPayment = (payment, value, index) => {
    let products = [...toAnnulateBillProducts]
    if (payment == 'quantity') products[index].quantity = parseInt(value)
    if (payment == 'discount') products[index].discount_rate = parseInt(value)
    if (payment == 'iva')
      products[index].taxes = [
        {
          tax_category: 'IVA',
          tax_rate: parseInt(value),
        },
      ]
    if (payment == 'rf')
      products[index].retentions = [
        {
          tax_category: 'RET_FUENTE',
          tax_rate: parseFloat(value),
        },
      ]

    let total = getTotalPerProduct(products[index], false)
    products = setTotal(products, total, index)
    setToAnnulateBillProducts(products)
    calculateToAnnulateAdditionalPayments(products, AnnulateReason)
  }

  const calculateToAnnulateTotals = (clientProducts) => {
    let retentions =
      clientProducts.retentions == undefined
        ? 0
        : clientProducts.retentions[0].tax_rate
    let taxes =
      clientProducts.taxes == undefined ? 0 : clientProducts.taxes[0].tax_rate
    let discount =
      clientProducts.discount_rate == undefined
        ? 0
        : clientProducts.discount_rate
    return (
      (parseFloat(
        (clientProducts.price *
          clientProducts.quantity *
          (100 - parseInt(discount))) /
          100,
      ) *
        (100 + (taxes - retentions))) /
      100
    )
  }

  const toAnnulateDeleteBillProduct = (index) => {
    let products = [...toAnnulateBillProducts]
    products.splice(index, 1)
    calculateToAnnulateAdditionalPayments(products, AnnulateReason)
    setToAnnulateBillProducts(products)
  }

  const resetToAnnulateConstants = () => {
    setAnnulateReason('')
    setToAnnulateAdditionalPayments({
      5: { name: 'IVA(5%)', total: 0 },
      16: { name: 'IVA(16%)', total: 0 },
      19: { name: 'IVA(19%)', total: 0 },
      discount: { name: 'Descuento', total: 0 },
      rf: { name: 'Ret. FUENTE', total: 0 },
    })
  }

  const handleSelectReason = (reason) => {
    setAnnulateReason(reason)
    let products = JSON.parse(originalTextItems)
    products = setProductsTotalEach(products)
    calculateToAnnulateAdditionalPayments(products, reason)
    setToAnnulateBillProducts(products)
  }

  const handleRestablishment = () => {
    let products = JSON.parse(originalTextItems)
    let productsWithTotal = setProductsTotalEach(products)
    setToAnnulateBillProducts(productsWithTotal)
  }

  //CHECK ROLES
  const tokenVerified = async () => {
    if (!rolesData.invoice) {
      notify('warning', 'No tienes permiso para ver este módulo.')
      navigate('/dashboard')
    }
  }

  //-------------------GetClients------------------
  const [invoiceOptions, setInvoiceOptions] = useState([])
  const [invoiceData, setInvoiceData] = useState('')

  const filterInvoice = async (invoiceNumber, toCreate, bill = {}) => {
    if (invoiceNumber.length > 0) {
      let result = await getClientInvoiceByInvoiceNumberApi(
        tokenData.companyId,
        invoiceNumber,
      )
      if (result.data != null) {
        if (toCreate) {
          setInvoiceData(bill)
          setOriginalTextItems(result.data[0].items)
          setToAnnulateBill(result.data[0])
          let products = JSON.parse(result.data[0].items)
          setIsClientSelected(true)
          form.setFieldValue('invoiceNumber', invoiceNumber)
          let productsWithTotal = getTotalPerProduct(products)
          setToAnnulateBillProducts(productsWithTotal)
          setAnnulateNotes(JSON.parse(result.data[0].notes))
        } else {
          let invoiceSelect = []
          await result.data.map((x) => {
            if (
              x.invoiceDianStatus == 'DIAN_ACEPTADO' &&
              x.creditNoteDianStatus != 'DIAN_ACEPTADO'
            )
              invoiceSelect.push({
                value: x.id,
                label: x.invoiceNumber,
              })
          })
          setInvoiceOptions(invoiceSelect)
        }
      }
    }
  }

  const setProductsTotalEach = (products) => {
    let ap = {
      5: { name: 'IVA(5%)', total: 0 },
      16: { name: 'IVA(16%)', total: 0 },
      19: { name: 'IVA(19%)', total: 0 },
      discount: { name: 'Descuento', total: 0 },
      rf: { name: 'Ret. FUENTE', total: 0 },
    }
    products.map((product) => {
      product.total = getTotalPerProduct(product, false)
      if (product.taxes != undefined) {
        ap[parseInt(product.taxes[0].tax_rate)].total +=
          (product.total * parseInt(product.taxes[0].tax_rate)) / 100
      }
      if (product.retentions != undefined) {
        ap.rf.total +=
          (product.total * parseFloat(product.retentions[0].tax_rate)) / 100
      }
      if (product.discount_rate != undefined) {
        ap.discount.total +=
          (product.price * parseInt(product.discount_rate)) / 100
      }
    })
    setToAnnulateAdditionalPayments(ap)
    return products
  }

  //----------------------Bills methods---------------------------

  const getProductsTotalPrice = (products) => {
    let total = 0
    products.map((product) => {
      let iva =
        product.taxes == undefined ? 0 : parseInt(product.taxes[0].tax_rate)
      let rf =
        product.retentions == undefined
          ? 0
          : parseFloat(product.retentions[0].tax_rate)
      let discount =
        product.discount_rate == undefined ? 0 : parseInt(product.discount_rate)
      total +=
        (product.price *
          product.quantity *
          (1 - discount / 100) *
          (100 + (iva - rf))) /
        100
    })
    return total
  }

  const getProductsSubtotalPrice = (products) => {
    let subtotal = 0
    products.map((product) => {
      let discount =
        product.discount_rate != undefined ? product.discount_rate : 0
      subtotal += product.price * (1 - discount / 100) * product.quantity
    })

    return subtotal
  }

  const setTotal = (clientbillproducts, total, index) => {
    clientbillproducts[index].total = total
    return clientbillproducts
  }

  const getTotalPerProduct = (clientProducts, isInvoice) => {
    let discount = isInvoice
      ? clientProducts.discount
      : clientProducts.discount_rate != undefined
      ? clientProducts.discount_rate
      : 0
    return (
      (clientProducts.price *
        clientProducts.quantity *
        (100 - parseInt(discount))) /
      100
    )
  }

  //-----------------------SendCreditNotes---------------------

  const createCreditNote = async (bill) => {
    let readyForCreate = true

    if (AnnulateReason == '') {
      readyForCreate = false
      notify('warning', 'Por favor, seleccione una razón para la anulación.')
    }

    if (readyForCreate) {
      setIsAnnulationButtonDisabled(true)

      let data = {
        branchId: tokenData.branchId,
        client: bill.client,
        clientUserId: tokenData.clientId,
        companyId: tokenData.companyId,
        resolutionId: bill.resolutionId,
        id: 0,
        issueDate: dayjsDateToLocale(new Date()),
        invoice: bill.uuid,
        invoiceNumber: form.values.invoiceNumber,
        items: toAnnulateBillProducts,
        notes: annulateNotes,
        reason: AnnulateReason,
        saleId: bill.saleId,
        thirdClientId: bill.thirdClientId,
      }
      let result = await PostCreditNoteApi(data)
      if (result.data != null) {
        successCreation(result)
        resetToAnnulateConstants()
      } else {
        notify('error', 'Error al crear la nota crédito.')
      }
    }
  }

  //--------------------Notes---------------------------

  const [annulateNotes, setAnnulateNotes] = useState([])

  useEffect(() => {
    if (!isEmpty(tokenData) && !isEmpty(rolesData)) {
      tokenVerified()
    }
  }, [tokenData, rolesData])

  const [color, setColor] = useState('black')
  useEffect(() => {
    setColor(isDarkModeActive ? 'white' : 'black')
  }, [isDarkModeActive])

  return (
    <Container
      style={{
        border: '1px solid gray',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <h4>Creando Nota Crédito</h4>
      <Container>
        <Group mb="md" mt="md" direction="column" grow>
          <CustomSelect
            title="Factura"
            placeholder="FACTURA"
            onChange={(e) => {
              resetToAnnulateConstants()
              filterInvoice(e.label, true, e)
            }}
            onKeyDown={(e) => {
              filterInvoice(e.target.value, false)
            }}
            value={invoiceData}
            options={invoiceOptions}
            theme={isDarkModeActive ? 'black' : 'white'}
          />
        </Group>
        {isClientSelected ? (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Razón
            </InputLabel>
            <MuiSelect
              label="Razón"
              onChange={(e) => handleSelectReason(e.target.value)}
              value={AnnulateReason}
            >
              <MenuItem value={''}></MenuItem>
              <MenuItem value={'DEVOLUCION'}>
                Devolución de parte de los bienes; no aceptación de partes del
                servicio
              </MenuItem>
              <MenuItem value={'ANULACION'}>
                Anulación de factura electrónica
              </MenuItem>
              <MenuItem value={'REBAJA'}>Rebaja total aplicada</MenuItem>
              <MenuItem value={'DESCUENTO'}>Descuento total aplicado</MenuItem>
              <MenuItem value={'RECISION'}>
                Rescisión: nulidad por falta de requisitos
              </MenuItem>
              <MenuItem value={'OTROS'}>Otros</MenuItem>
            </MuiSelect>
          </FormControl>
        ) : null}
      </Container>
      <br></br>
      <Container>
        {AnnulateReason != '' ? (
          <table
            style={{
              borderSpacing: '10px',
              borderCollapse: 'separate',
            }}
          >
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>Descuento</th>
                <th>RET. FUENTE</th>
                <th>IVA</th>
                <th>Subtotal</th>
                <th>Total</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                //sale table here
                toAnnulateBillProducts.map((clientProducts, index) => {
                  return (
                    <tr key={index}>
                      <td>{clientProducts.description}</td>
                      <td>
                        <TextField
                          variant="standard"
                          disabled={disableByReason('quantity', AnnulateReason)}
                          onChange={(event) => {
                            if (
                              event.currentTarget.value >= 0 &&
                              event.currentTarget.value <=
                                JSON.parse(originalTextItems)[index].quantity
                            )
                              changeToAnnulateBillProductsPayment(
                                'quantity',
                                event.currentTarget.value,
                                index,
                              )
                          }}
                          onBlur={(event) => {
                            if (
                              event.currentTarget.value == '' ||
                              event.currentTarget.value == 0
                            ) {
                              changeToAnnulateBillProductsPayment(
                                'quantity',
                                JSON.parse(originalTextItems)[index].quantity,
                                index,
                              )
                            }
                          }}
                          type="number"
                          value={clientProducts.quantity}
                          style={{
                            width: '45px',
                            display: 'inline-block',
                          }}
                        />
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          disabled={disableByReason('discount', AnnulateReason)}
                          onChange={(event) => {
                            if (
                              event.currentTarget.value >= 0 &&
                              event.currentTarget.value <= 100
                            ) {
                              changeToAnnulateBillProductsPayment(
                                'discount',
                                event.currentTarget.value,
                                index,
                              )
                            }
                          }}
                          onBlur={(event) => {
                            if (!(event.currentTarget.value != ''))
                              changeToAnnulateBillProductsPayment(
                                'discount',
                                0,
                                index,
                              )
                          }}
                          type="number"
                          value={
                            clientProducts.discount_rate != undefined
                              ? clientProducts.discount_rate
                              : 0
                          }
                          style={{
                            width: '45px',
                            display: 'inline-block',
                          }}
                        />
                        %
                      </td>
                      <td>
                        <FormControl variant="standard" sx={{ m: 1 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            Ret. fuente
                          </InputLabel>
                          <MuiSelect
                            disabled={disableByReason('rf', AnnulateReason)}
                            label="Ret. fuente"
                            value={
                              clientProducts.retentions == undefined
                                ? 0
                                : clientProducts.retentions[0].tax_rate
                            }
                            onChange={(e) =>
                              changeToAnnulateBillProductsPayment(
                                'rf',
                                e.target.value,
                                index,
                              )
                            }
                          >
                            <MenuItem value={0}>0%</MenuItem>
                            <MenuItem value={0.1}>0.1%</MenuItem>
                            <MenuItem value={0.5}>0.5%</MenuItem>
                            <MenuItem value={1}>1%</MenuItem>
                            <MenuItem value={1.5}>1.5%</MenuItem>
                            <MenuItem value={2}>2%</MenuItem>
                            <MenuItem value={2.5}>2.5%</MenuItem>
                            <MenuItem value={3}>3%</MenuItem>
                            <MenuItem value={3.5}>3.5%</MenuItem>
                            <MenuItem value={4}>4%</MenuItem>
                            <MenuItem value={6}>6%</MenuItem>
                            <MenuItem value={7}>7%</MenuItem>
                            <MenuItem value={10}>10%</MenuItem>
                            <MenuItem value={11}>11%</MenuItem>
                            <MenuItem value={20}>20%</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl variant="standard" sx={{ m: 1 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            IVA
                          </InputLabel>
                          <MuiSelect
                            disabled={disableByReason('iva', AnnulateReason)}
                            label="IVA"
                            value={
                              clientProducts.taxes == undefined
                                ? 0
                                : parseInt(clientProducts.taxes[0].tax_rate)
                            }
                            onChange={(e) =>
                              changeToAnnulateBillProductsPayment(
                                'iva',
                                e.target.value,
                                index,
                              )
                            }
                          >
                            <MenuItem value={0}>IVA 0% (Exento)</MenuItem>
                            <MenuItem value={5}>IVA 5%</MenuItem>
                            <MenuItem value={16}>IVA 16%</MenuItem>
                            <MenuItem value={19}>IVA 19%</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </td>
                      <td>
                        $
                        {clientProducts.discount_rate == undefined
                          ? setDecimal(
                              clientProducts.price * clientProducts.quantity,
                            )
                          : setDecimal(
                              clientProducts.price *
                                clientProducts.quantity *
                                (1 - clientProducts.discount_rate / 100),
                            )}
                      </td>
                      <td>
                        ${setDecimal(calculateToAnnulateTotals(clientProducts))}
                      </td>
                      <td
                        style={{
                          margin: 'auto',
                          textAlign: 'center',
                        }}
                      >
                        <Trash
                          size={30}
                          color={
                            disableByReason('delete', AnnulateReason)
                              ? 'gray'
                              : 'red'
                          }
                          style={
                            disableByReason('delete', AnnulateReason)
                              ? null
                              : { cursor: 'pointer' }
                          }
                          onClick={() =>
                            disableByReason('delete', AnnulateReason)
                              ? null
                              : toAnnulateDeleteBillProduct(index)
                          }
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        ) : null}
        {AnnulateReason != '' ? (
          <div>
            <Button
              style={{
                width: 'fit-content',
                backgroundColor: 'green',
                border: 'none',
                borderRadius: '5%',
                isolation: 'isolate',
              }}
              onClick={() => {
                handleRestablishment()
              }}
            >
              Restablecer
            </Button>{' '}
            <BootstrapTooltip
              disableFocusListener
              title={reasonDescription(AnnulateReason)}
            >
              <div
                style={{
                  height: '40px',
                  width: '40px',
                  backgroundColor: 'transparent',
                  borderRadius: '50%',
                  display: 'inline-block',
                  textAlign: 'center',
                  border: `2px solid ${color}`,
                }}
              >
                <QuestionMark
                  size={25}
                  style={{ marginTop: '5px' }}
                  color={color}
                ></QuestionMark>
              </div>
            </BootstrapTooltip>
          </div>
        ) : null}
      </Container>
      <Container>
        {AnnulateReason != '' ? (
          <MDBTable>
            <MDBTableBody>
              <tr>
                <td>Subtotal:</td>
                <td>
                  $
                  {setDecimal(
                    parseFloat(
                      getProductsSubtotalPrice(toAnnulateBillProducts),
                    ),
                  )}
                </td>
              </tr>
              {Object.keys(toAnnulateAdditionalPayments).map((key, index) => {
                if (
                  toAnnulateAdditionalPayments[key].total > 0 &&
                  toAnnulateAdditionalPayments[key].name.startsWith('IVA')
                ) {
                  return (
                    <tr key={index}>
                      <td>{toAnnulateAdditionalPayments[key].name}</td>
                      <td>
                        + ${setDecimal(toAnnulateAdditionalPayments[key].total)}
                      </td>
                    </tr>
                  )
                }
              })}
              {toAnnulateAdditionalPayments.discount.total > 0 ? (
                <tr>
                  <td>Descuento: </td>
                  <td>
                    - ${setDecimal(toAnnulateAdditionalPayments.discount.total)}
                  </td>
                </tr>
              ) : null}
              {toAnnulateAdditionalPayments.rf.total > 0 ? (
                <tr>
                  <td>Ret. Fuente: </td>
                  <td>
                    - ${setDecimal(toAnnulateAdditionalPayments.rf.total)}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <b>Total:</b>
                </td>
                <td>
                  <b>
                    $
                    {AnnulateReason != 'REBAJA'
                      ? setDecimal(
                          getProductsTotalPrice(toAnnulateBillProducts, false),
                        )
                      : setDecimal(0)}
                  </b>
                </td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        ) : null}
      </Container>
      <Container>
        {AnnulateReason != '' ? (
          <Group mb="md" mt="md">
            <Notes
              notes={annulateNotes}
              onChange={(notes) => setAnnulateNotes(notes)}
            />
          </Group>
        ) : null}
      </Container>
      <hr></hr>
      <Button
        disabled={isAnnulationButtonDisabled}
        style={{
          backgroundColor: 'green',
          borderRadius: '100px',
          marginRight: '10px',
          isolation: 'isolate',
        }}
        onClick={() =>
          toAnnulateBill.length != 0
            ? createCreditNote(toAnnulateBill)
            : notify('warning', 'No hay factura seleccionada.')
        }
      >
        {isAnnulationButtonDisabled ? (
          <CircularProgress size={15} color="inherit" />
        ) : null}{' '}
        Crear
      </Button>
      <Button
        style={{
          backgroundColor: 'transparent',
          borderRadius: '100px',
          color: 'black',
        }}
        onClick={() => {
          resetToAnnulateConstants()
          setModalAnnulateShow(false)
        }}
      >
        Volver
      </Button>
    </Container>
  )
}

const reasonDescription = (reason) => {
  switch (reason) {
    case 'DEVOLUCION':
      return 'Devolución de parte de los bienes; no aceptación de partes del servicio: Es que se devuelve solo una parte, no toda la factura, porque solo se tiene inconsistencias con alguno o algunos de los ítems o parte de un ítem específico.'
    case 'ANULACION':
      return 'Anulación: Anula la factura en su totalidad.'
    case 'REBAJA':
      return 'Rebaja total aplicada: Se le concede rebaja del precio a 0.'
    case 'DESCUENTO':
      return 'Descuento total aplicado: Se le concede descuento del 100% y la factura también queda en ceros.'
    case 'RECISION':
      return 'Rescisión: nulidad por falta de requisitos: No se cumple con los requisitos del contrato o la venta y se debe anular.'
    case 'OTROS':
      return 'Otros: Lo que no esté anterior, en el concepto 1777 de la DIAN dice que ese otro puede ser para Resoluciones: Es cuando una de las dos partes incumple las cláusulas del contrato y se anula la venta.'
    default:
      return 'a'
  }
}
